import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm, useWatch } from "react-hook-form";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
    FormGroup, Label, Input, Modal,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Table
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_UPDATE_SELECTED_PURCHASEADBILL, VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID, VINEO_GETALL_POSITIVE_DEBITNOTE_AVAILABLE, VINEO_GETALL_POSITIVE_DEBITNOTE_AVAILABLEBYID } from '../../../constants/Urlforvineo';
import { getVineoAccountCustomerMasterMenu, getVineoFirmData, getVineoGodownData, getVineoCompanyData, getVineoSchemaMasterData, getVineoAccountVendorCustomerMasterMenu } from '../../../redux/features/services/serviceHelper';

import AreaToSalesman from '../mapping/AreaToSalesman';

import { useSelector, useDispatch } from 'react-redux'
import { AddPurchasetemAddline } from './AddPurchasetemAddline';
import http from '../../../constants/AxiosConfig';

let totalDiscountPercentagevalue = 0;
let debitnoteamountselected = 0;

export default function AddPurchase() {
    useEffect(() => {
        dispatch(getVineoFirmData())

        dispatch(getVineoAccountVendorCustomerMasterMenu())
        // dispatch(getVineoProductData());
    }, []);
    const dispatch = useDispatch()
    const [debitnotedebit, setDebitnotedebit] = useState()
    const [selectedDebitNote, setSelectedDebitNote] = useState([])
    const [billpaymentdate, setBillpaymentdate] = useState(new Date())
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const [selectedVendor, setSelectedVendor] = useState([])
    const [companySelections, setCompanySelections] = useState([]);
    const [godownSelections, setGodownSelections] = useState([]);
    const [selectedFirm, setSelectedFirm] = useState([])
    const [grossBillValue, setGrossBillValue] = useState(0);
    const [billdate, setBilldate] = useState(new Date())
    // const count = useSelector((state) => state.vineoData.value)

    const data = [];
    const vineoPartydata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();

    const onSubmit = (data) => {
        //  console.log(JSON.stringify(data));
        data.vineoAddPurchaseItemList.map((item, index) => {
            return (
                <>
                    <>{item.vineoProduct = item.vineoProduct[0]} </>
                    <>{item.vineoBatch = item.vineoBatch[0]}</>
                </>
            )
        })



        // here set value of items from product into o index array 
        console.log((data));
        submitForm(data)
    }


    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSchemeUniqeId": "Working", "vineoCompanySchemeNumber": "Click Add To Load Scheme" }])
    const isvineoschemamasterdataLoaded = useSelector((state) => state.vineoData.isvineoschemamasterdataLoaded)
    const vineoaccountvendorcustomerdata = useSelector((state) => state.vineoData.vineoaccountvendorcustomerdata)
    const isvineoaccountvendorcustomerdataLoaded = useSelector((state) => state.vineoData.isvineoaccountvendorcustomerdataLoaded)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const getdebitNoteavaialblefromserver = () => {

        console.log("Called in To get Debit Notes with positive Value ")
        http.post(BASEURL_JWTAUTH + VINEO_GETALL_POSITIVE_DEBITNOTE_AVAILABLE).then(
            (res) => {
                console.log(res.data)
                if (res.data?.[0]?.debitnoteAmount > '0') {
                    notify('Debit Note Amount is Available - ' + res?.data?.[0]?.debitnoteAmount)
                    setDebitnotedebit(res.data);
                    console.log(debitnotedebit);
                    console.log("DebitNote Above");

                }
                else
              {  notifyError("Debit Note Amount is Not Available For Selected Customer- ")}

            },
            (err) => {


                notifyError("Debit While Getting Credit amount Operation From Server ")
            }
        )

    }

    const getdatafromserver = () => {

        console.log("Called to get  Data ")

        dispatch(getVineoSchemaMasterData())



        console.log("Empty Data Received form Server")
    }

    const submitForm = (data) => {

        console.log("Connecting Server to Create Bill")
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_PURCHASEADBILL, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorMessage == 'SUCCESS') {
                    notify('Add Purchase Operation Successful with Deatils=' + res.data.errorCode)
                    reset();
                }
                else
                notifyError("Error While Add Purchase Operation From Server with Message as " + res.data.errorCode)
                console.log(res.data)
            },
            (err) => {
                notifyError("Error While Add Purchase Operation From Server ")
            }
        )

    }

    return (
        <div>

            <div>
                <div className="p-2 mb-2 bg-primary text-white">
                    <center>
                        <h5 className='justify-content-center m-1'>Add Purchase Billing Firm Name :-{(selectedFirm?.[0]?.vineoFirmFirmName)} GST:- {(selectedFirm?.[0]?.vineofirmGst?.vineoGstNumber)} </h5>
                    </center>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <div>
                            <Card className='p-4'>
                                <Row>
                                    <Col md={3}>
                                        <Form.Text >Select Firm*</Form.Text >
                                        <InputGroup>
                                            <Typeahead
                                                id="FirmNameTypehead"
                                                onChange={setSelectedFirm}
                                                options={vineofirmdata ? vineofirmdata : []}
                                                placeholder="Choose Firm Name..."
                                                selected={selectedFirm}
                                                labelKey={"vineoFirmFirmName"}
                                            />
                                            <Button variant="primary"
                                                onClick={() => { dispatch(getVineoFirmData()); }} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            {setValue("vineoAddPurchaseFirm", selectedFirm?.[0])}
                                        </InputGroup>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Text >Vendor Name GST:-</Form.Text >
                                        {(selectedVendor?.[0]?.vineoGst?.vineoGstNumber)}

                                        <InputGroup>
                                            <Typeahead
                                                disabled={selectedFirm[0] ? false : true}
                                                id="VendorNameTypehead"
                                                onChange={setSelectedVendor}
                                                options={vineoaccountvendorcustomerdata}
                                                placeholder="Choose Vendor Name..."
                                                selected={selectedVendor}
                                                labelKey={"vineoVendorAccountName"}
                                            />
                                            <Button variant="primary"
                                                onClick={() => { dispatch(getVineoAccountVendorCustomerMasterMenu()); }} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            {setValue("vineoVendorAccount", selectedVendor?.[0])}
                                        </InputGroup>
                                    </Col>

                                    {/* <Col md={2}>
                                    <FormGroup>
                                        <Form.Text >Godown Name</Form.Text >
                                        <Typeahead
                                            id="godownNameTypeahead"
                                            labelKey="vineoGoDownName"
                                            onChange={setGodownSelections}
                                            multiple
                                            options={vineogodowndataTypeahead}
                                            placeholder="Choose Godown."
                                            selected={godownSelections}
                                        />
                                        {setValue("vineoAddPurchaseGoDownName", godownSelections?.[0])}
                                    </FormGroup>
                                </Col> */}
                                    <Col md={2}>
                                        <Form.Text>
                                            Purchase Order
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("vineoAddPurchaseOrder")} />


                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Generate Receipt
                                        </Form.Text>

                                        <Form.Select className="form-control"  {...register("receiptIsGenerated")} required>
                                            <option  value="true">Yes</option>
                                            <option selected={true} value="false">No</option>
                                        </Form.Select>

                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Narr
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("vineoAddPurchaseNarr")} />


                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <Form.Text>
                                            salesman
                                        </Form.Text>

                                        <Form.Control defaultValue={selectedVendor?.[0]?.vineoVendorAccounthisSalesman} className="form-control"  {...register("smanName")} />

                                    </Col>

                                    <Col md={2}>
                                        <Form.Text>
                                            Area
                                        </Form.Text>
                                        <Form.Control defaultValue={selectedVendor?.[0]?.vineoVendorAccounthisArea} className="form-control"  {...register("areaName")} />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Invoice-BillNo-*
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("vineoaddUniquePurchaseNumberWithPrefix")} required />


                                    </Col>

                                    <Col md={2}>
                                        <FormGroup>
                                            <Form.Text >
                                                Bill  Date
                                            </Form.Text>
                                            <DatePicker className="form-control"
                                                selected={billdate}
                                                onChange={setBilldate}
                                                name="vineoAddPurchaseDate"
                                                dateFormat="dd-MM-yyyy"
                                                ref={setValue("vineoAddPurchaseDate", moment(billdate).format(DATEFORMAT))}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={2}>
                                        <Form.Text>
                                            Purchase Type
                                        </Form.Text>
                                        <Form.Select className="form-control"  {...register("vineoAddPurchaseType")} required>
                                            <option value="CREDIT">Credit</option>
                                            <option disabled={true} value="CASH">Cash</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                        <div>

                        </div>
                    </Row>
                    {/* {setTotalDiscountPercentage(totalDiscountPercentagevalue)} */}

                    {console.log(totalDiscountPercentagevalue + "From Parent ")}
                    <div><AddPurchasetemAddline
                        totalDiscountPercentage={totalDiscountPercentagevalue

                        }
                        setGrossBillValue={setGrossBillValue} register={register}
                        setValue={setValue} control={control} getValues={getValues}
                        watch={watch} dataforaddsaleBill={watch(`dataforaddsaleBill`)} /></div>
                    <center>
                        <div>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Net Amt -
                                        </Form.Text>
                                        : {grossBillValue}
                                    </FormGroup>


                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Star Disc
                                        </Form.Text>
                                        <Form.Control type="number" min="0" step="0.01" defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseStarDisc")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0"step="0.01"defaultValue={0} className="form-control"  {...register("vineoAddPurchaseStarDiscPercentage")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            cGST Amt
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchasecGSTAmt")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Surch
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseRetSurch")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0"step="0.01"defaultValue={0} className="form-control"  {...register("vineoAddPurchaseRetSurchPercentage")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Display
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseDisplay")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Display%
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseDisplayPercentage")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            SchmAmt
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseSchemeAmt")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            SchmAmt%
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseSchemeAmtPercentage")} />

                                    </FormGroup>
                                </Col>



                            </Row>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Rounding
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("vineoAddPurchaseRoundingAmt")} required />
                                    </FormGroup>
                                </Col>

                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Cash Disc
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseCashDiscount")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseCashDiscountPercentage")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            sGST Amt
                                        </Form.Text>

                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchasesGSTAmt")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Sch Disc
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseRetSchDisc")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseRetSchDiscPercentage")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Coupon
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseCoupon")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Coupon%
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseCouponPercentage")} />
                                    </FormGroup>
                                </Col>





                            </Row>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Sys.Adjust
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0"step="0.01"defaultValue={0} className="form-control"  {...register("vineoAddPurchaseSysAdjust")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add Other
                                        </Form.Text>
                                        <Form.Control size='sm' defaultValue={0} className="form-control"  {...register("vineoAddPurchaseAddOther")} required />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' defaultValue={0} className="form-control"  {...register("vineoAddPurchaseAddOtherPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Sales Return
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            defaultValue={0}
                                            className="form-control" />
                                        {/* {...register("vineoAddPurchasesReturn")} required */}
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Cd Disc
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseRetCdDisc")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseRetCdDiscPercentage")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add/Less
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseAddorLess")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add/Less%
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseAddorLessPercentage")} />

                                    </FormGroup>
                                </Col>
                                {/* <Col md={2}>
                                    <Button className="btn btn-primary m-2" onClick={() => console.log("Calcualting values ")} value="RE-Calcualte" >
                                        RE-Calculate
                                    </Button>

                                </Col> */}


                            </Row>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Bottom Amt
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01" size='sm' className="form-control" {...register("vineoAddPurchaseBottomAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Less Other
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01" defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseAddLess")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseAddorLessPercentage")} />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Return GST
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true} defaultValue={0} className="form-control" />
                                        {/* <Form.Control size='sm' disabled={true} defaultValue={0} className="form-control"  {...register("vineoAddPurchaseReturnGst")} required /> */}
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            TCS Amt
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseTcsAmt")} />


                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseTcsAmtPercentage")} />      </FormGroup>
                                </Col>

                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            TPR Amt
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseTprAmt")} />

                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %Tpr
                                        </Form.Text>
                                        <Form.Control type="number" min="0"step="0.01"defaultValue={0} size='sm' className="form-control" {...register("vineoAddPurchaseTprAmtPercentage")} />
                                    </FormGroup>
                                </Col>

                            </Row>
                            {/* calcualting total discount Percentage  */}
                            <Row>
                                <Col md={4}>
                                    <Row>
                                        <Col><FormGroup>
                                            <Form.Text >
                                                Payment  Date
                                            </Form.Text>
                                            <DatePicker className="form-control"
                                                selected={billpaymentdate}
                                                onChange={setBillpaymentdate}
                                                name="vineoPaymentDate"
                                                dateFormat="dd-MM-yyyy"
                                                ref={setValue("vineoPayment.vineoPaymentDate", moment(billpaymentdate).format(DATEFORMAT))}
                                            />
                                        </FormGroup></Col>
                                        <Col>
                                            <Form.Text >
                                                Payment  Mode
                                            </Form.Text>
                                            <Form.Select className="form-control"
                                                {...register("vineoPayment.paymentType")} required>
                                                <option disabled={true} value="CREDIT">Credit</option>
                                                <option value="CASH">Cash</option>
                                                <option value="CHEQUE">Cheque</option>
                                                <option value="ONLINE">Online</option>
                                            </Form.Select></Col>
                                    </Row>


                                    {watch(`vineoPayment.paymentType`) == 'CHEQUE' ? <>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Form.Text >
                                                        CHK Number
                                                    </Form.Text>
                                                    <Form.Control size='sm' className="form-control" {...register("vineoPayment.chequeNumber")} required />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Form.Text >
                                                        BankName
                                                    </Form.Text>
                                                    <Form.Control size='sm' className="form-control" {...register("vineoPayment.bankName")} required />
                                                </FormGroup></Col>
                                        </Row>

                                    </> : <></>}

                                    {watch(`vineoPayment.paymentType`) == 'ONLINE' ? <>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Form.Text >
                                                        UPI ID/ Bank Name
                                                    </Form.Text>
                                                    <Form.Control size='sm' className="form-control" {...register("vineoPayment.upiidName")} required />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Form.Text >
                                                        onlineTransactionNumber
                                                    </Form.Text>
                                                    <Form.Control size='sm' className="form-control" {...register("vineoPayment.onlineTransactionNumber")} required />
                                                </FormGroup></Col>
                                        </Row>

                                    </> : <></>}
                                    <FormGroup>
                                        <Form.Text >
                                            Actual Amount Paid =
                                        </Form.Text>
                                        {
                                            (watch('vineoAddPurchaseBottomAmt') ? (watch('vineoAddPurchaseBottomAmt')) : 0) - debitnoteamountselected}
                                         <Form.Text >
                                            Debitamnt =
                                        </Form.Text> {debitnoteamountselected = selectedDebitNote?.[0]?.debitnoteAmount ?
                                        selectedDebitNote?.[0]?.debitnoteAmount : 0 }
                                        {console.log(debitnoteamountselected + "Is Availabel Debit Note Amount")}
                                        <Form.Control size='sm' Value={
                                            (watch('vineoAddPurchaseBottomAmt') ? (watch('vineoAddPurchaseBottomAmt')) : 0) - debitnoteamountselected} className="form-control" {...register("vineoPayment.actualPaymentAmountPaid")} required />



                                        <Row> <center>
                                            {/* Addd Debite note here  */}

                                            <InputGroup>

                                                <Typeahead
                                                    id="DebitnoteTypehead"
                                                    onChange={setSelectedDebitNote}
                                                    disabled={debitnotedebit ? false : true}
                                                    options={debitnotedebit}
                                                    placeholder="Check DebitNote"
                                                    selected={selectedDebitNote}
                                                    labelKey={debitnotedebit => `${debitnotedebit.debitnoteAmount.toFixed(2)}`}

                                                    renderMenuItemChildren={(option) => (
                                                        <div key={option.id}>
                                                            <div>
                                                                <small>Amount:  {`${option?.debitnoteAmount.toFixed(2)}`}</small>

                                                                <small> -DRN: {`${option?.debitNoteIssuedonPurchaseBillNumber}`}</small>
                                                            </div>
                                                        </div>
                                                    )}
                                                    ref={() => {

                                                        { setValue(`vineoPayment.vineoDeditNote`, selectedDebitNote[0]) }
                                                    }}
                                                />
                                                <Button variant="primary"
                                                    onClick={() => { getdebitNoteavaialblefromserver() }} >
                                                    <FontAwesomeIcon icon={faRotate} />
                                                </Button>


                                            </InputGroup>


                                            <input className="btn btn-primary m-2" type="submit" value="Submit" />
                                            <input className="btn btn-primary m-2" type="button" value="Print" />
                                            <Button className="btn btn-danger m-2" type="button" onClick={() => { reset() }} value="Cancel">Cancel</Button>
                                        </center>
                                        </Row>
                                    </FormGroup>
                                </Col >
                                <Col md={3}>

                                    <center>
                                        <input className="btn btn-primary m-2" type="submit" value="Submit" />
                                        <input className="btn btn-primary m-2" type="button" value="Print" />
                                        <Button className="btn btn-danger m-2" type="button" onClick={() => { reset() }} value="Cancel">Cancel</Button>
                                    </center></Col>
                            </Row>

                        </div>

                        <Row>
                            {/* <div style={{ display: "none" }}>
                                {
                                    totalDiscountPercentagevalue =
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillStarDiscPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCashDiscountPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddOtherPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillLessOtherPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSurchPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSchDiscPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetCdDiscPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTcsAmtPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillDisplayPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCouponPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddLessPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTprAmtPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillSchemeAmtPercentage`))
                                }
                            </div> */}
                        </Row>
                    </center>

                </form>
            </div>


            <div>

            </div>
        </div >
    )

}