import React, { useRef } from "react";
import {
    FormGroup, Label, Input, InputGroup, Alert,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, Table
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { getVineoAccountCustomerMasterMenu, getVineoAreaData, getVineoCompanyData, getVineoRouteData, getVineoSalesmanUsers, getVineoUsers } from '../../../redux/features/services/serviceHelper';
import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { BASEURL_JWTAUTH, VINEO_MAP_AREATOPARTY, VINEO_MAP_AREATOPARTYMULTIPLE } from "../../../constants/Urlforvineo";
import Typetes from "./AdvanceMappingSetting";


export default function PartyToArea() {
    useEffect(() => {
        dispatch(getVineoCompanyData());
        dispatch(getVineoAreaData());
        dispatch(getVineoRouteData())
        dispatch(getVineoAccountCustomerMasterMenu())
    }, []);
    const dispatch = useDispatch()
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [tableData, setTableData] = useState([{ "vineoAreaName": "Click Refresh to Laod ", "vineoAreaName": "Click Refresh to Load " }]);
    const vineoareadataforMappingTable = [{ "vineoAreaName": "Click Refresh to Load ", "companyName": "Loading", "nameofUser": "Click Refresh to Load " }]
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [selectedParty, setSelectedParty] = useState([]);
    const selectedPartyUniqueid = []

    const [searchterm, setSearchterm] = useState("");
    const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    const vineooldCompnaydata = useSelector((state) => state.vineoData.vineocompany)
    const vineoroutedata = useSelector((state) => state.vineoData.vineoroutedata)
    const vineoareadata = useSelector((state) => state.vineoData.vineoareadata)
    const vineousersdata = useSelector((state) => state.vineoData.vineousers)
    const vineocustomeaccountdata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const isvineoaccountcustomerdataLoaded = useSelector((state) => state.vineoData.isvineoaccountcustomerdataLoaded)
    const [isLoading, setLoading] = useState(false);

    let { AreaName } = [{}];

    const { register, handleSubmit, reset, watch, formState: { errors }, setValue } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        if (data?.ultiVineoAreaUniqeId && selectedParty?.[0]?.ultiVineoCustomerAccountUniqeId) {
            {
                {
                    selectedParty?.map((item) => {
                        selectedPartyUniqueid.push(String(item?.ultiVineoCustomerAccountUniqeId))
                    })
                }
                data.ultiVineoCustomerAccountUniqeIdList = selectedPartyUniqueid
            }
            setLoading(true);
            submitForm(data)
        }
        else { notifyError("Please select both Area and Party ") }

    }
    const getdatafromserverforcompanyareaandsalesman = () => {

        dispatch(getVineoCompanyData())
        dispatch(getVineoAreaData())
        dispatch(getVineoRouteData())
        dispatch(getVineoAccountCustomerMasterMenu())
        console.log("Called to get Company Data Area Data and Customer Account/party Data ")
        console.log(isvineocompanyLoaded)
        console.log(isvineoaccountcustomerdataLoaded)

    }
    const getdatafromserverforsalesmanandarea = () => {
        dispatch(getVineoCompanyData());
        dispatch(getVineoAreaData());
        dispatch(getVineoRouteData())
        dispatch(getVineoAccountCustomerMasterMenu())
        console.log("Called to get Area and User Data Data ")

    }
    const typeaheadRef = useRef();

    const handleSelectAll = () => {
        const allValues = vineocustomeaccountdata.map((option) => option);
        setSelectedParty(allValues)
    }

    const submitForm = (data) => {
        // api call
        // http.post(BASEURL_JWTAUTH + VINEO_MAP_AREATOPARTY, data)
        http.post(BASEURL_JWTAUTH + VINEO_MAP_AREATOPARTYMULTIPLE, data)
            .then(
                (res) => {
                    console.log(res.data)
                    if (res.data.errorCode == "0000") {
                        setLoading(false);
                        notify('Success:- Area Mapped to Party Succesfully')

                    }
                    else {
                        setLoading(false);
                        notifyError("Error While Mapping Areato Party From Server " + res.data.additionalInfo)
                    }
                    //  console.log(res.data)

                },
                (err) => {
                    setLoading(false);
                    console.log(err.data)

                    notifyError("Error While Mapping Areato Party  " + err.data.additionalInfo)
                }
            )
    }

    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }
    const [areaoptions, setAreaoptions] = useState({ "value": "Workinginsidemap ", "label": "Getting Data" });

    const columns = [{
        dataField: 'vineoAreaName',
        text: 'Area Name',
        sort: true,
        editable: false
    },
    {
        dataField: 'companyName',
        text: 'MappedCompany',
        sort: true,
        formatter: rowClickedfunction,
    },
    {
        dataField: 'nameofUser',
        text: 'Party / Customer Name'

    }
    ];

    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '100', value: 100
        },]
    };
    const { SearchBar } = Search;

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)

        }

    };

    return (
        <div>

            <h1 align="center">Party To Area</h1>

            <Row>
                <form onSubmit={handleSubmit(onSubmit)}>



                    <Row>
                        <Col md={6}>

                            <FormGroup >

                                <Form.Text className="text-muted">
                                    Party Name / Customer Name*
                                </Form.Text>
                                <InputGroup className="mb-3">

                                    <Typeahead
                                        id="PartyNameTypehead"
                                        onChange={setSelectedParty}
                                        options={vineocustomeaccountdata}
                                        placeholder="Choose Party Name..."
                                        selected={selectedParty}
                                        multiple={true}
                                        labelKey={"vineoCustomerAccountName"}
                                    />
                                    {/* <button onClick={}>Select All</button> */}

                                    <Button variant="warning"
                                        onClick={handleSelectAll} >Select All

                                    </Button>
                                    {console.log("PArty", selectedParty)}
                                    <Button variant="primary"
                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup >
                                <Form.Text className="text-muted">
                                    Area Name*
                                </Form.Text>

                                <InputGroup className="mb-3">

                                    <Form.Control
                                        as="select"
                                        validated="true"
                                        onChange={e => {
                                            console.log("ultiVineoAreaUniqeId ", e.target.value);
                                            setValue("ultiVineoAreaUniqeId", e.target.value);
                                        }}
                                    >
                                        <option>Select Option </option>
                                        return (
                                        <>
                                            {(vineoareadata) ? (
                                                vineoareadata?.map((vineoareadata) =>

                                                    <option key={vineoareadata?.ultiVineoAreaUniqeId} value={vineoareadata?.ultiVineoAreaUniqeId}>{vineoareadata?.vineoAreaName}</option>
                                                )
                                            ) : (

                                                console.log("Click Refresh to load Data ")

                                            )}
                                        </>
                                        );

                                    </Form.Control>

                                    <Button variant="primary"
                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </Col>


                    </Row>





                    <input disabled={isLoading} className="btn btn-primary m-2" type="submit" value="Add Mapping " />

                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                </form>

                {/* <Multiselect buttonClass="btn btn-danger" data={this.state.list} multiple ></Multiselect> */}

            </Row>
            <div className="mt-5">
                {/* 
                < ToolkitProvider keyField='vineoAreaName'
                    data={tableData}
                    search   >
                    {
                        props => (
                            <div>
                                <div >

                                    <SearchBar className="m-1" {...props.searchProps} />
                                    <Button variant="primary" className="m-1"
                                        onClick={getdatafromserverforsalesmanandarea} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </div>
                                    <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                    {...props.baseProps}
                                    columns={columns}
                                    keyField='vineoAreaName'

                                    pagination={paginationFactory(options)}
                                // selectRow={selectRow}
                                // rowEvents={ rowEvents }
                                />

                            </div>
                        )
                    }
                </ToolkitProvider>
                 */}
            </div>
            <div className="col-md-12 text-center">
                <InputGroup>
                    <Form.Control className="m-1" type="text" placeholder="Search ...." onChange={(e) => setSearchterm(e.target.value)} />
                    <Button variant="primary" className="m-1"
                        onClick={getdatafromserverforsalesmanandarea} >
                        <FontAwesomeIcon icon={faRotate} />Load Mapping Data
                    </Button>
                </InputGroup>

                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr>
                            <td>Sr. No.</td>
                            <td>Party Name</td>
                            <td>Area</td>
                            <td>Company Name</td>
                            <td>Salesman Name</td>
                            <td>Route Name</td>
                        </tr>
                        {
                            vineocustomeaccountdata?.filter((item) => {
                                if (searchterm == "") {
                                    return item;
                                }
                                else if (item.vineoCustomerAccountName.toLowerCase().includes(searchterm.toLowerCase())) {
                                    return item;
                                }
                            }).map((item, i) =>
                                <tr key={i}>
                                    <td>{i + 1}</td> {/* Add this line for Sr. No. */}
                                    <td>{item?.vineoCustomerAccountName}</td>
                                    <td>{item?.vineoArea?.vineoAreaName}</td>
                                    <td>
                                        <>
                                            {
                                                (<Table striped bordered hover size="sm">
                                                    <tbody>
                                                        {
                                                            item?.vineoArea?.vineoCompanies?.map((data1, i1) =>
                                                                <tr key={i1}>
                                                                    <td>{data1.companyName}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>)
                                            }
                                        </>
                                    </td>
                                    <td>{item?.vineoArea?.salesman?.nameofUser}</td>
                                    <td>{item?.vineoArea?.salesman?.vineoRoute?.vineoRouteName}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

            </div>




        </div >
    )
}