import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ACCOUNTTYPE2_NAMEDDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNTTYPE2_NAMEDATA } from '../../../constants/Urlforvineo';
import { getVineoAccountType2Data } from '../../../redux/features/services/serviceHelper';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';



import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';

export default function TypeTwoInfo() {

    const dispatch = useDispatch()
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{"ultiVineoAccountType2UniqeId":"Working","vineoAccountType2Name":"Click Refresh To Load"}])
    const isaccounttype2dataLoaded = useSelector((state) => state.vineoData.isaccounttype2dataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoaccounttype2data)
    const { register, handleSubmit,setValue, reset, watch, formState: { errors } } = useForm();
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoAccountType2Name',
            text: 'Account Type 2 Name',
            sort: true,
            formatter: rowClickedfunction,
        }, 
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };
    const getdatafromserver = () => {

        console.log("Called to Account Type2 Data ")
        dispatch(getVineoAccountType2Data())
        if(Object.keys(vineodata).length > 0)
        {
            isaccounttype2dataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{"ultiVineoAccountType2UniqeId":"Working","vineoAccountType2Name":"Click Again Refresh To Load"}])
       
        }
        console.log("Empty Data Received form Server")
        
    }

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoAccountType2UniqeId", selectedRow.ultiVineoAccountType2UniqeId);
            setValue("vineoAccountType2Name", selectedRow.vineoAccountType2Name);
            
             setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
      
           
        }
        console.log("Update Firm ")
    }

    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
              var answer = window.confirm("Do You want to Delete Selected Type2 ");
              if (answer) {
                  submitFormForDeleteSelectedRow(selectedRow);
              }
              else {
                  notify("Delete Operation Canceled ")
              }
          }
  
    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ACCOUNTTYPE2_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Area  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoAccountType2Name )
                }
                else
                    notify("Error While Deleting FIRM  Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting FIRM Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNTTYPE2_NAMEDATA, data).then(
            (res)=>{
                console.log(res)
                if(res.data.errorCode== "0000"){
                notify('Success:- Type2  Created With Internate Id')
                reset()
                
            }
            else
            notify('Error While Registering Type2 Info From Server')
            //console.log(res.data)
        },
        (err)=>{
            notify('Error While Registering Type2 Info')
        }
        )
    }


    return (
        <div>
      <h3 className='justify-content-center m-1'>Add Type2 Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoAccountType2UniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >


                                        <fieldset disabled>
                                            <Form.Control className="form-control "
                                                placeholder="Type2 ID: This Firm Code Will Be Auto Generated"
                                                {...register("ultiVineoAccountType2UniqeId")} />
                                        </fieldset>
                                    </FormGroup>
                                    <FormGroup >

                                        <Form.Text >
                                            Type2 Code
                                        </Form.Text>
                                        <Form.Control  className="form-control "   {...register("vineoAccountType2Code")} required />

                                    </FormGroup>
                                  
                                    <FormGroup >

                                        <Form.Text >
                                            Type2 Name
                                        </Form.Text>
                                        <Form.Control className="form-control "   {...register("vineoAccountType2Name")} required />

                                    </FormGroup>
                                  

                                    <input className="btn btn-primary m-2" type="submit" value="Save" />
                                    <button type="button" onClick={() => reset()} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row>

            </div>
        </div>
    )

}
