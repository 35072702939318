import React from 'react'
import { useEffect, useState } from "react"
import moment from "moment";
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm, Controller } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATA } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import { getVineoAccountVendorCustomerMasterMenu, getVineoCustomerBankMasterData, getVineoGstData, getVineoProductData } from '../../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


import {
    FormGroup, Label, Input, Modal, InputGroup,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';
import CustomerBankInfo from './CustomerBankInfo';

export default function VendorAccount() {

    useEffect(() => {
        dispatch(getVineoGstData());
        dispatch(getVineoCustomerBankMasterData())
        // dispatch(getVineoProductData());
    }, []);

    const onBankRefresh = () => {
        dispatch(getVineoCustomerBankMasterData())
    }
    const [openAddBankAccnt, setOpenAddBankAccnt] = useState(false);


    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineovineoVendorAccountNameUniqeId": "Working", "vineoVendorAccountName": "Click Refresh To Load" }])
    const isvineoaccountothercustomerdataLoaded = useSelector((state) => state.vineoData.isvineoaccountvendorcustomerdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoaccountvendorcustomerdata)
    const isvineoBankdataLoaded = useSelector((state) => state.vineoData.isvineocustomerbankMasterdataLoaded)
    const vineobankdata = useSelector((state) => state.vineoData.vineocustomerbankMasterdata)
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const vineogstdata = useSelector((state) => state.vineoData.vineogstdata)
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedGST, setSelectedGST] = useState();
    const [selected, setSelected] = useState([])
    const [openingdate, setOpeningdate] = useState(new Date());
    const [dlexp1, setDlexp1] = useState();
    const [dlexp2, setDlexp2] = useState();

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log((data));
        submitForm(data)
    }

    const dispatch = useDispatch()

    const vineoproductdata = useSelector((state) => state.vineoData.vineoproductdata)
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoVendorAccountName',
            text: 'Vendor Account Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'vineoVendorAccountCode',
            text: 'VendorCode.',
            sort: true
        },{
            dataField: 'ultiVineovineoVendorAccountNameUniqeId',
            text: 'Unique Id.',
            sort: true
        },
        

    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };

    const getdatafromserver = () => {

        console.log("Called to get Other Account Data ")

        dispatch(getVineoAccountVendorCustomerMasterMenu())

        if (Object.keys(vineodata).length > 0) {
            isvineoaccountothercustomerdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineovineoVendorAccountNameUniqeId": "Working", "vineovineoCustomerOtherAccountName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }
    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineovineoVendorAccountNameUniqeId", selectedRow.ultiVineovineoVendorAccountNameUniqeId);
            setValue("vineoVendorAccountName", selectedRow.vineoVendorAccountName);
          

            setValue("vineoVendorGstNumber", selectedRow.vineoVendorGstNumber);
            setValue("vineoVendorAccountpincode", selectedRow.vineoVendorAccountpincode);
            setValue("vineoVendorAccountMobileNo", selectedRow.vineoVendorAccountMobileNo);
            setValue("vineoVendorAccountaddress", selectedRow.vineoVendorAccountaddress);
            setValue("vineoVendorAccountaddress2", selectedRow.vineoVendorAccountaddress2);
            setValue("vineoVendorAccountTown", selectedRow.vineoVendorAccountTown);
            setValue("vineoVendorAccountstate", selectedRow.vineoVendorAccountstate);
            setValue("vineoVendorAccountemail", selectedRow.vineoVendorAccountemail);
            setValue("vineoVendorAccountmob", selectedRow.vineoVendorAccountmob);
            setValue("vineoVendorAccountphoneNumber", selectedRow.vineoVendorAccountphoneNumber);
            setValue("vineoVendorAccounttinNumber", selectedRow.vineoVendorAccounttinNumber);
            setValue("vineoVendorAccountopeningbalance", selectedRow.vineoVendorAccountopeningbalance);
            setValue("vineoVendorAccountcreditAmt", selectedRow.vineoVendorAccountcreditAmt);
            setValue("vineoVendorAccountdlNumberOne", selectedRow.vineoVendorAccountdlNumberOne);
            setValue("vineoVendorAccountdlExpOne", selectedRow.vineoVendorAccountdlExpOne);
            setValue("vineoVendorAccountdlNumberTwo", selectedRow.vineoVendorAccountdlNumberTwo);
            setValue("vineoVendorAccountdlExpTwo", selectedRow.vineoVendorAccountdlExpTwo);
            setValue("vineoVendorAccountcommonCode", selectedRow.vineoVendorAccountcommonCode);
            setValue("vineoVendorAccountcommonName", selectedRow.vineoVendorAccountcommonName);
            setValue("vineoVendorAccounthisArea", selectedRow.vineoVendorAccounthisArea);
            setValue("vineoVendorAccounthisSalesman", selectedRow.vineoVendorAccounthisSalesman);
            setValue("vineoVendorAccountType", selectedRow.vineoVendorAccountType);
            setValue("vineoVendorAccountCode", selectedRow.vineoVendorAccountCode);
            setValue("vineoBankName", selectedRow?.vineoBankName);
            setValue("vineoBankAccountNumber", selectedRow?.vineoBankAccountNumber);
            setValue("vineoBankifscCode", selectedRow?.vineoBankifscCode);
            

            //  setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);


        }
        console.log("Update Other Account ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Other Account ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }
    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Other Account  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineovineoCustomerOtherAccountName)
                }
                else
                {notifyError("Error While Deleting Other Account  Or Login as ADMIN")}
            },
            (err) => {
                notifyError("Error While Deleting Other Account Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Other Account  Created With Internate Id')
                    reset();

                }
                else
              {  notifyError('Error While Registering Other Account Info From Server')}
                //console.log(res.data)
            },
            (err) => {
                notifyError('Error While Registering Other Account Info')
            }
        )
    }


    return (
        <div>

            <h3 className='justify-content-center m-1'>Vendor Account Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineovineoVendorAccountNameUniqeId'

                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>Vendor Account ID</Form.Text>
                                                <fieldset disabled>
                                                    <Form.Control className="form-control "
                                                        placeholder="Vendor Account ID: This Code Will Be Auto Generated"
                                                        {...register("ultiVineovineoVendorAccountNameUniqeId")} />
                                                </fieldset>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Opening Date
                                                </Form.Text>

                                                <DatePicker className="form-control"
                                                    selected={openingdate}
                                                    onChange={setOpeningdate}
                                                    name="VendorOpejing Date"
                                                    dateFormat="dd-MM-yyyy"

                                                />

                                                {setValue("vineoVendorAccountopeningDate", moment(openingdate).format(DATEFORMAT))}
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text >
                                                    Vendor Account Code*
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'  {...register("vineoVendorAccountCode")} required />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text >
                                                    Account Name*
                                                </Form.Text>
                                                <Form.Control className="form-control" size='sm'  {...register("vineoVendorAccountName")} required />

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>
                                                    Account Type
                                                </Form.Text>
                                                <Form.Select className="form-control" size='sm' {...register("vineovVndorAccountType")} required >
                                                    <option value="accounttype1">ADD LESS Account</option>
                                                    <option value="accounttype2">ADD LESS Account 2</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>
                                                    Product- Use Map Vendor to Product from Entries 
                                                </Form.Text>
                                                <Typeahead
                                                disabled={true}
                                                    id="ProductNameTypehead"
                                                    size='sm'
                                                    onChange={setSelected}
                                                    onClick={setValue("vineoProductset", selected)}
                                                    options={vineoproductdata}
                                                    multiple
                                                    placeholder="Choose Multiple Products"
                                                    selected={selected}
                                                    labelKey={"vineoProductName"}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    GstNumber*
                                                </Form.Text>
                                                <Form.Control className="form-control" size='sm' {...register("vineoVendorGstNumber")} required />

                                                {/* <Controller
                                    control={control}
                                    name={`vineofirmGstArray`}
                                    rules={{
                                        required: "Please, select at least one value"
                                    }}
                                    render={({ field, fieldState }) => (
                                        <div >
                                            <Typeahead
                                                {...field}
                                                id="selectedGSTtypeahead"
                                                labelKey={"vineoGstNumber"}
                                                options={vineogstdata ? vineogstdata : []}
                                                className={fieldState.invalid ? "is-invalid" : ""}
                                                ref={() => {
                                                    watch(`vineofirmGstArray`) ?
                                                        setValue("vineoGst", watch(`vineofirmGstArray`)?.[0]) :
                                                        <></>
                                                }}
                                            />
                                            <p id="typeaheadError" className="invalid-feedback">
                                                {fieldState.error?.message}
                                            </p>
                                        </div>
                                    )}
                                />
                                <p id="typeaheadproductError" className="invalid-feedback">
                                </p> */}


                                            </FormGroup></Col>
                                        <Col>
                                            <FormGroup >
                                                <InputGroup>
                                                    
                                                    <Form.Text className="text-muted">
                                                        Bank*
                                                    </Form.Text>
                                                   <Row>
                                                    <Col md ={3}><Form.Control className="form-control custom-form-field" placeholder='Bank Name' size='sm' {...register("vineoBankName")} required /></Col>
                                                    <Col md={3}><Form.Control className="form-control custom-form-field" placeholder='ifsc Code' size='sm' {...register("vineoBankifscCode")} required /></Col>
                                                    <Col>    <Form.Control className="form-control custom-form-field" placeholder='Accnt Number' size='sm' {...register("vineoBankAccountNumber")} required /></Col>
                                                   </Row>
                                                </InputGroup>
                                                {/* <Controller
                                                    control={control}
                                                    name={`vineofirmBankArray`}
                                                    rules={{
                                                        required: "Please, select at least one value"
                                                    }}

                                                    render={({ field, fieldState }) => (
                                                        <div >
                                                             <InputGroup>
                                                            <Typeahead
                                                                {...field}
                                                                size='sm'
                                                                id="selectedbanktypeahead"
                                                                labelKey={"vineoBankName"}
                                                                renderMenuItemChildren={(option) => (
                                                                    <div>
                                                                        <div>
                                                                            <small>Bank:  {option.vineoBankName}</small>
                                                                            <small> A/C: {option.vineoBankAccountNumber}</small>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                }
                                                                options={vineobankdata ? vineobankdata : []}
                                                                className={fieldState.invalid ? "is-invalid" : ""}
                                                                ref={() => {

                                                                    watch(`vineofirmBankArray`) ?
                                                                        setValue("vineoBank", watch(`vineofirmBankArray`)?.[0]) :
                                                                        <></>
                                                                }}
                                                            />
                                                                <Button variant="info"
                                                                    onClick={onBankRefresh} >
                                                                    <FontAwesomeIcon icon={faRotate} />
                                                                </Button>
                                                                <Button variant="primary"
                                                                   onClick={() => { setOpenAddBankAccnt(true) }}>
                                                                    +
                                                                </Button>
                                                            </InputGroup>
                                                            <p id="typeaheadError" className="invalid-feedback">
                                                                {fieldState.error?.message}
                                                            </p>
                                                        </div>
                                                    )}
                                                />
                                                <p id="typeaheadBankError" className="invalid-feedback">
                                                </p> */}

                                            </FormGroup></Col>

                                    
                                  
                                    </Row>
                                    <Row>
                                    <Col >
                                    <FormGroup >

                                        <Form.Text >
                                            Address
                                        </Form.Text>
                                        <Form.Control className="form-control " size='sm' {...register("vineoVendorAccountaddress")}  />

                                    </FormGroup>
                                    </Col>
                                    <Col >
                                    <FormGroup >

                                        <Form.Text >
                                            Address Line 2 
                                        </Form.Text>
                                        <Form.Control className="form-control " size='sm' {...register("vineoVendorAccountaddress2")}  />

                                    </FormGroup>
                                    </Col>
                                    <Col >
                                    <FormGroup >

                                        <Form.Text >
                                            Town
                                        </Form.Text>
                                        <Form.Control className="form-control " size='sm'  {...register("vineoVendorAccountTown")}  />
                                    </FormGroup>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col >
                                    <FormGroup >

                                        <Form.Text >
                                            State
                                        </Form.Text>
                                        <Form.Control className="form-control " size='sm'   {...register("vineoVendorAccountstate")}  />
                                    </FormGroup>
                                    </Col>
                                    <Col >
                                    <FormGroup >

                                        <Form.Text >
                                           Pincode 
                                        </Form.Text>
                                        <Form.Control className="form-control " size='sm'   {...register("vineoVendorAccountpincode")}  />
                                    </FormGroup>
                                    </Col>
                                    
                                    </Row>

                                    <Col>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Email ID *
                                        </Form.Text>
                                        {/* , { pattern: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$' } */}
                                        <Form.Control type="email" className="form-controll" size='sm'   {...register("vineoVendorAccountemail")} required />
                                    </FormGroup></Col>
                                    <Col >
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Account type 
                                                </Form.Text>
                                                <Form.Select className="form-control" size='sm'  {...register("vineoVendorAccountType")}>
                                                    <option value="RETAILER">RETAILER</option>
                                                    <option value="DISTRIBUTOR">DISTRIBUTOR</option>
                                                    <option value="NORMAL">NORMAL</option>
                                                    <option value="SUPPLIER">SUPPLIER</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                    <Row>
                                    <Col md={6}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                   Area Name of Vendor 
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("vineoVendorAccounthisArea")}  />
                                       
                                      
                                               
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                   Salesman From Vendor Side 
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("vineoVendorAccounthisSalesman")}  />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    10 Digit Mobile Number *
                                                </Form.Text>

                                                <input className="form-control " size='sm'  {...register("vineoVendorAccountMobileNo", { minLength: 10, maxLength: 10 })} required />
                                                {errors.mobilenumber && <span>Enter Mobile Number as 10  digit </span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Phone No.
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("vineoVendorAccountphoneNumber")}  />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Tin No
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("vineoVendorAccounttinNumber")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Opening Bal.
                                                </Form.Text>
                                                <Form.Control className="form-control " defaultValue="0.00" size='sm'  {...register("vineoVendorAccountopeningbalance")}  />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Text>
                                                Amt
                                            </Form.Text>
                                            <Form.Select className="form-control" size='sm'  {...register("vineoVendorAccountAmt")}  >
                                                <option value="accounttype1">Dr</option>
                                                <option value="accounttype2">Dr2</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    DL No.1
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'  {...register("vineoVendorAccountdlNumberOne")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    DL EXP 1
                                                </Form.Text>

                                                <DatePicker className="form-control"
                                                    selected={dlexp1}
                                                    onChange={setDlexp1}
                                                    name="Dl exp1  Date"
                                                    dateFormat="dd-MM-yyyy"

                                                />

                                                {setValue("vineoVendorAccountdlExpOne", moment(dlexp1).format(DATEFORMAT))}
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    DL No.2
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("vineoVendorAccountdlNumberTwo")} />

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Text className="text-muted">
                                                DL EXP 2
                                            </Form.Text>
                                            <DatePicker className="form-control"
                                                selected={dlexp2}
                                                onChange={setDlexp2}
                                                name="Dl exp2  Date"
                                                dateFormat="dd-MM-yyyy"

                                            />
                                            {setValue("vineoVendorAccountdlExpTwo", moment(dlexp2).format(DATEFORMAT))}

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Common Code
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'  {...register("vineoVendorAccountcommonCode")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Common Name
                                                </Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("vineoVendorAccountcommonName")} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <center>
                                        <input className="btn btn-primary m-2" type="submit" value="Add Account " />
                                        <button type="button" onClick={() => reset()} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                    </center>
                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row>

            </div>
            <div>
                <Modal show={openAddBankAccnt}
                    onHide={() => { setOpenAddBankAccnt(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <CustomerBankInfo />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { setOpenAddBankAccnt(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    )

}
