import { Routes, Route, Link } from 'react-router-dom';
import Home from '../components/dashboard/Home';
import Login from '../components/loginRegisterForms/Login';
import Register from "../components/loginRegisterForms/Register";
import {
  Nav, Navbar, NavDropdown, Form, FormControl, Container,
  Dropdown, Col, Row
} from 'react-bootstrap'
import { Component, useState } from 'react';
import RegisterClient from '../components/loginRegisterForms/RegisterClient';



function Guest() {

  const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");

  const changeStyle = () => {
    if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
    }
    else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    }
  };
  const changeStyle1 = () => {
    if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
    }
    else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    }
  };

  return (
    <div style={{ display: "flex" }}>


      <ul className={style} id="accordionSidebar">

        {/*  <!-- Sidebar - Brand --> */}
        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
          {/* <div className="sidebar-brand-icon rotate-n-15">
                                <i className="fas fa-laugh-wink"></i>
                            </div> */}
          <div className="sidebar-brand-text mx-3">SYNERGY</div>
          <div className="text-center d-none d-md-inline">
            <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
          </div>
        </a>

        {/*   <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        <Nav.Item className="nav-item active">
          <Nav.Link className="nav-link" as={Link} to={"/login"}>
            <i className="fas fa-fw fa-user"></i>
            <span>Login</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="nav-item active">
          <Nav.Link className="nav-link" as={Link} to={"/register"}>
            <i className="fas fa-fw fa-user-plus"></i>
            <span>Register</span>
          </Nav.Link>
        </Nav.Item>




        <div className="sidebar-card d-none d-lg-flex">
          <img className="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="..." />
          <p className="text-center mb-2"><strong>SYNERGY</strong> </p>
          <a className="btn btn-success btn-sm" href="https://vineotech.com">Contact Us </a>
        </div>

      </ul>
    
      <div style={{ flex: 1 }}>
        <Routes>

          <Route path=" /*" element={<Home />} />

          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />


        </Routes>

      </div>
    </div>
  );
}

export default Guest;
