import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from 'react-redux'
import { decrement } from '../../../redux/features/vineoData/vineoDataSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem,Card
} from 'react-bootstrap';

export default function SubBrandMasterAdvance() {

    const count = useSelector((state) => state.vineoData.value)
    const dispatch = useDispatch()

    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditUserSelected, setIsEditUserSelected] = useState(false);

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    // const [formDetails, setformDetails] = useState({});
    // useEffect(() => {
    //     setformDetails({ ...formDetails, gender: "F", enabled: "true", accountLocked: "false", role: "admin" })
    //     console.log(formDetails)
    // }, []);
//Table for firm Start 
const rowClickedfunction = (data, row) => {
    return <span onClick={() => console.log(row)}>{data}</span>
}


const { SearchBar } = Search;
const columns = [

    {
        dataField: 'nameofUser',
        text: 'Firm Name',
        sort: true,
        formatter: rowClickedfunction,
    }, {
        dataField: 'username',
        text: 'Firm Code',
        sort: true
    }, {
        dataField: 'role',
        text: 'GST Number',
        sort: true
    }];

// const rowEvents = {
//     onClick: (e, row, rowIndex) => {
//       console.log(`clicked on row with index: ${row}`);
//       return rowIndex;

//     }
//   };

const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    disablePageTitle: true,
    sizePerPageList: [{
        text: '20', value: 20
    }, {
        text: '50', value: 50
    },]
};

const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    bgColor: '#0D6EFD',
    classes: (row, rowIndex) => {
        setIsRowSelected(false);
        setSelectedRow(row)
    }
};

//Table for firm END 
 const dataofvineoUsers=()=>{} 
 const getUserdatafromserver=()=>{}
  const updateUserinFrom=()=>{} 
 const deleteUltimateUser=()=>{}


    const submitForm = (data) => {
        // api call
        http.post('/salesman/billingManager/admin', data).then(
            (res) => {
                if (res.data.id) {
                    notify('User Created with Internal Id')
                    reset()
                
                }
                else
                    notify("Error While registering Firm Info From Server ")
                //  console.log(res.data)

            },
            (err) => {


                notify("Error While Registering Firm ")
            }
        )
    }


    return (
        <div>
            {/* <div>
                <button
                    aria-label="Increment value"
                    onClick={() => dispatch(increment())}
                >
                    Increment
                </button>
                <span>{count}</span>
                <button
                    aria-label="Decrement value"
                    onClick={() => dispatch(decrement())}
                >
                    Decrement
                </button>
            </div> */}


            <h3 className='justify-content-center m-1'>This Page is for Advance Setting of SUB Brand Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <>
                            <Button variant="primary m-1"><FontAwesomeIcon icon={faRotate} /></Button>

                            <Button variant="warning m-1">Edit</Button>
                            <Button variant="danger m-1">Delete</Button>

                        </>
                        <ListGroup>
                            <ListGroup.Item disabled>Disabled Firm </ListGroup.Item>
                            <ListGroup.Item>SubBrand 1</ListGroup.Item>
                            <ListGroup.Item>SubBrand 1</ListGroup.Item>
                            <ListGroup.Item>SubBrand 1</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={8}>
                        <div >
                        <Card className='p-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup >
                                    <fieldset disabled>

                                        <Form.Control className="form-control"
                                            placeholder="SubBrand Code: This SubBrand Code Will Be Auto Generated"
                                            {...register("firmcode")} />
                                    </fieldset>
                                </FormGroup>

                                <FormGroup >

                                    <Form.Text className="text-muted">
                                        SubBrandCode
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("SubBrandCode")} required />

                                </FormGroup>



                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        SubBran dName
                                    </Form.Text>
                                    <Form.Control className="form-control"   {...register("SubBrandName")} required />

                                </FormGroup>
                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Brand Name
                                    </Form.Text>
                                    <Form.Control className="form-control" {...register("BrandName")} required />

                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                SubGroup Name
                                    
                                            </Form.Text>
                                            <Form.Control className="form-control" {...register("SubGroupName")} required />

                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Group Name
                                            </Form.Text>
                                            <Form.Control className="form-control"  {...register("Group Name")} required />

                                        </FormGroup>

                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                SubCategory Name
                                            </Form.Text>
                                            <Form.Control className="form-control"  {...register("SubCategory Name")} required />
                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Category Name
                                            </Form.Text>
                                            <Form.Control className="form-control"   {...register("Category Name")} required />

                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Company Name
                                            </Form.Text>
                                            <Form.Control className="form-control"   {...register("Company Name")} required />

                                        </FormGroup>


                                    </Col>
                                 

                                </Row>

                                <input className="btn btn-primary m-2" type="submit" value="Add Firm " />
                                <button type="button" onClick={() => reset()} className="btn btn-secondary m-2"><FontAwesomeIcon icon={faRotate} />Reset</button>

                            </form>
                            </Card>
                        </div>
                      

                    </Col>
                </Row>

            </div>
        </div>
    )

}