import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { getVineoRouteData } from '../../../redux/features/services/serviceHelper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/counter/counterSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem,Card
} from 'react-bootstrap';
import { VINEOFORM_UPDATE_SELECTED_ROUTE_NAMEDATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ROUTE_NAMEDDATA } from '../../../constants/Urlforvineo';

export default function BrandMaster() {
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{"ultiVineoAreaUniqeId":"Working","vineoAreaName":"Click Refresh To Load"}])
    const isvineoroutedataLoaded = useSelector((state) => state.vineoData.isvineoroutedataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoroutedata)
    const dispatch = useDispatch()
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});


    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    // const [formDetails, setformDetails] = useState({});
    // useEffect(() => {
    //     setformDetails({ ...formDetails, gender: "F", enabled: "true", accountLocked: "false", role: "admin" })
    //     console.log(formDetails)
    // }, []);

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoRouteName',
            text: 'Route Name',
            sort: true,
            formatter: rowClickedfunction,
        },
    
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };

    const getdatafromserver = () => {

        console.log("Called to get Route Data ")
 
        dispatch(getVineoRouteData())
        if(Object.keys(vineodata).length > 0)
        {
            isvineoroutedataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{"ultiVineoRouteUniqeId":"Working","vineoRouteName":"Click Again Refresh To Load"}])
       
        }
        console.log("Empty Data Received form Server")
       
       
    }
    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoRouteUniqeId", selectedRow.ultiVineoRouteUniqeId);
            setValue("vineoRouteName", selectedRow.vineoRouteName);
            
             setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
      
           
        }
        console.log("Update Route ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
              var answer = window.confirm("Do You want to Delete Selected Route ");
              if (answer) {
                  submitFormForDeleteSelectedRow(selectedRow);
              }
              else {
                  notify("Delete Operation Canceled ")
              }
          }
    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ROUTE_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Route  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoRouteName)
                }
                else
                    notify("Error While Deleting FIRM  Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting FIRM Try Again  Or Login as ADMIN")

            }
        )
    }
    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ROUTE_NAMEDATA , data).then(
            (res) => {
                console.log(res)
                if(res.data.errorCode== "0000"){
                    notify('Success:- Route  Created With Internate Id')
                    reset()
                }
                else
                notify('Error While Registering Route Info From Server')
                console.log(res.data)
            },
            (err)=>{
                notify('Error While Registering Route Info')
            }
            )
        }


    return (
        <div>
        <h3 className='justify-content-center m-1'>Add Route Information </h3>
              <div className="row justify-content-left ">
                  <Row>
                      <Col md={4}>
                          <ToolkitProvider keyField='ultiVineoRouteUniqeId'
                              data={dataofvineoTableData}
                              columns={columns}
                              search   >
                              {
                                  props => (
                                      <div>
                                          <div >
                                              <Button variant="primary"
                                                  onClick={getdatafromserver} >
                                                  <FontAwesomeIcon icon={faRotate} />
                                              </Button>
                                              <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                              <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                              <SearchBar className="m-1" {...props.searchProps} />
                                          </div>
                                              <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                              {...props.baseProps}
                                              pagination={paginationFactory(options)}
                                              selectRow={selectRow}
                                          // rowEvents={ rowEvents }
                                          />
  
                                      </div>
                                  )
                              }
                          </ToolkitProvider>
  
                      </Col>
                    <Col md={8}>
                        <div >
                        <Card className='p-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>


                                   <FormGroup >
                                   <fieldset disabled>
                                   <Form.Control className='form-control'    placeholder ='Route ID: This Route Code Is Auto Generated '  {...register('ultiVineoRouteUniqeId')}/>
                               </fieldset>
                                </FormGroup>
                                <FormGroup >
                                <Form.Text className="text-muted">
                                    Route Code
                                </Form.Text>
                                <Form.Control className="form-control "   {...register("vineoRouteCode")} required />
                            </FormGroup>
                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Route Name
                                    </Form.Text>
                                    <Form.Control className="form-control" {...register('vineoRouteName')} required />

                                </FormGroup>
                                <Row>
                                    <Col>
 
                                    </Col>

                                </Row>

                                <input className="btn btn-primary m-2" type="submit" value="Submit " />
                                <button type="button" onClick={() => reset()} className="btn btn-secondary m-2"><FontAwesomeIcon icon={faRotate} />Reset</button>

                            </form>
                            </Card>
                        </div>
                      

                    </Col>
                </Row>

            </div>
        </div>
    )

}