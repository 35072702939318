import React from 'react'
import { Component, useEffect, useState,} from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, DATEFORMAT } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import {
    FormGroup, Label, Input, 
    Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';

export default function SalesmanMasterInfo() {

    const count = useSelector((state) => state.vineoData.value)
    const dispatch = useDispatch()
    const [openingdate, setOpeningdate] = useState();    
    const [ dob, setDob] = useState();


    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    // const [formDetails, setformDetails] = useState({});
    // useEffect(() => {
    //     setformDetails({ ...formDetails, gender: "F", enabled: "true", accountLocked: "false", role: "admin" })
    //     console.log(formDetails)
    // }, []);

   
    const submitForm = (data) => {
        // api call
        http.post('BASEURL_JWTAUTH' + 'VINEOFORM_SUBCATEGORYINFO_ADDINFODATA', data).then(
            (res) => {
                if (res.data.id) {
                    notify('User Created with Internal Id')
                    reset()
                   
                }
                else
               { notifyError("Error While registering SubCategory Info From Server ")}
                //  console.log(res.data)
            },
            (err) => {


                notify("Error While Registering SubCategory Info ")
            }
        )
    }


    return (
        <div>
            {/* <div>
                <button
                    aria-label="Increment value"
                    onClick={() => dispatch(increment())}
                >
                    Increment
                </button>
                <span>{count}</span>
                <button
                    aria-label="Decrement value"
                    onClick={() => dispatch(decrement())}
                >
                    Decrement
                </button>
            </div> */}


            <h3 className='justify-content-center m-1'>Salesman Master Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <>
                            <Button variant="primary m-1"><FontAwesomeIcon icon={faRotate} /></Button>

                            <Button variant="warning m-1">Edit</Button>
                            <Button variant="danger m-1">Delete</Button>

                        </>
                        <ListGroup>
                            <ListGroup.Item disabled>Disabled SalesMan </ListGroup.Item>
                            <ListGroup.Item>SalesmanMaster Test 1</ListGroup.Item>
                            <ListGroup.Item>SalesmanMaster Test 1</ListGroup.Item>
                            <ListGroup.Item>SalesmanMaster Test 1</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >


                                        <fieldset disabled>
                                            <Form.Control className="form-control "
                                                placeholder="Salesman ID: This Firm Code Will Be Auto Generated"
                                                {...register("Salesman Master")} />
                                        </fieldset>
                                    </FormGroup>
                                    <FormGroup >
                                    <Form.Text className="text-muted">
                                        Saleman Code
                                    </Form.Text>
                                    <Form.Control  className="form-control "   {...register("vineoSalemanCode")} required />
                                </FormGroup>
                                    <FormGroup >

                                        <Form.Text >
                                            SalesMan Name
                                        </Form.Text>
                                        <Form.Control className="form-control "   {...register("SaleManName")} required />

                                    </FormGroup>

                                    <FormGroup >
                                       
                                        <Form.Text >
                                            SalesMan Type
                                        </Form.Text>
                                        <Form.Select className="form-control"  {...register("salesmantype")}>
                                        <option value="salesmantype1">salesmantype1</option>
                                        <option value="salesmantype2">salesmantype2</option>
                                        
                                    </Form.Select>
                                    </FormGroup>
                                    <FormGroup >
                        
                                        <Form.Text >
                                            Address
                                        </Form.Text>

                                        <Form.Control className="form-control "   {...register("Address")} required />

                                    </FormGroup>
                                    <FormGroup >
                        
                        <Form.Text >
                        Town
                        </Form.Text>

                        <Form.Control className="form-control "   {...register("Town")} required />

                    </FormGroup>
                    <FormGroup >
                        
                        <Form.Text >
                            Pin Code
                        </Form.Text>

                        <Form.Control className="form-control "   {...register("PinCode")} required />

                    </FormGroup>
                     <FormGroup >
                        
                        <Form.Text >
                        State
                        </Form.Text>

                        <Form.Control className="form-control "   {...register("State")} required />

                    </FormGroup> <FormGroup >
                        
                        <Form.Text >
                        Country
                        </Form.Text>

                        <Form.Control className="form-control "   {...register("Country")} required />

                    </FormGroup> 
                    <FormGroup >
                        
                        <Form.Text >
                            Phone Number
                        </Form.Text>

                        <Form.Control className="form-control "   {...register("Phone No")} required />

                    </FormGroup>

                    <FormGroup >
                                            <Form.Text>
                                                10 Digit Mobile Number
                                            </Form.Text>

                                            <Form.Control className="form-control "   {...register("mobilenumber", { minLength: 10, maxLength: 10 })} />
                                            {errors.mobilenumber && <span>Enter Mobile Number as 10 or 12 digit </span>}
                                        </FormGroup>
                                         <FormGroup >

                                            <Form.Text className="text-muted">
                                            Email ID
                                            </Form.Text>
                                            {/* , { pattern: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$' } */}
                                            <Form.Control type="email" className="form-controll"   {...register("emailid")} required />
                                        </FormGroup>

                                        <FormGroup>
                                        <Form.Text >
                                        Date Of Birth
                                    </Form.Text>
                                   
                                    <DatePicker className="form-control"
                                    selected={dob}
                                    onChange={setDob}
                                    name="CustomerBday Date"
                                    dateFormat="dd-MM-yyyy"

                                />

                            {setValue("vineoCustomerAccountdateOfBirth", moment(dob).format(DATEFORMAT))}
                        </FormGroup>
                   

                                        <FormGroup >
                        <Form.Text >
                        Qualification
                        </Form.Text>

                        <Form.Control className="form-control "   {...register("Qualification")} required />

                    </FormGroup>
                    <FormGroup >
                        
                        <Form.Text >
                        Reference
                        </Form.Text>

                        <Form.Control className="form-control "   {...register("Reference")} required />

                    </FormGroup>
                    <FormGroup >
                        
                        <Form.Text >
                            IMEI Number
                        </Form.Text>

                        <Form.Control className="form-control "   {...register("IMEI NO")} required />

                    </FormGroup>
                    <input className="btn btn-primary m-2" type="submit" value="Mapping " />
                                    <input className="btn btn-primary m-2" type="submit" value="Add Salesman Info " />
                                    <input className="btn btn-primary m-2" type="Cancel" value="Cancel " />
                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row>

            </div>
        </div>
    )

}