import { useState } from "react"
import AuthUser from "../../constants/AuthUser";
import SliderForLoginPage from './SliderForLoginPage'
import { useForm } from "react-hook-form";
import http from "../../constants/AxiosConfig";

import { BASEURL_JWTAUTH,INEO_REGISTER_CLIENTDATA, GET_JWT_TOKEN, HEALTHCHECK, VINEO_REGISTER_CLIENTDATA, VINEO_REGISTER_CLIENTDATA_PASSWORDCHANGE } from '../../constants/Urlforvineo'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import {
    FormGroup, Label, Input,
    Button, Form
} from 'react-bootstrap';
import { getVineoAreaData } from "../../redux/features/services/serviceHelper";



export default function PasswordChangeRegisterClient() {
    const { http, setToken } = AuthUser();
    const dispatch = useDispatch()
    const [userDetails, setUserDetails] = useState({});
    // const { register, handleSubmit } = useForm();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const { register, handleSubmit } = useForm({
        
    });

    const onSubmit = (data) => {

        submitForm(data);
    }


    const testserverdeployment = () => {
        http.post(BASEURL_JWTAUTH + "/billingmanager/test").then(
            (res) => {
                console.log(res.data)
                notify(res.data)

            }
            ,
            (errr) => {

                console.log(errr)
                notifyError("Your Not Billing  Manger or Admin   " + errr)
            }
        )

    }

    const testsalesman = () => {
        http.post(BASEURL_JWTAUTH + "/salesman/test").then(
            (res) => {
                console.log(res.data)
                notify(res.data)

            }
            ,
            (errr) => {

                console.log(errr)
                notifyError("Your Not Billing  Manger or Admin   " + errr)
            }
        )

    }
    const submitForm = (data) => {

        http.post(BASEURL_JWTAUTH + VINEO_REGISTER_CLIENTDATA_PASSWORDCHANGE, data).then(
            (res) => {
                // console.log(res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName)
                // setToken(res.data.username, res.data.token, res.data.email, res.data.uniqueid, res.data.role, res.data.vineoRegisteredClientDetails.vineoRegisteredClientName, res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName);
                console.log(res.status)
                console.log(res)
                if(res.status)
                notify("Password Change Success ")
                setTimeout(() => {
                    window.location.reload(true);
                    console.log("Refreshing Browser")
                }, 100);

            },
            (errr) => {

                console.log(errr)
                notifyError("Error During Client registration  & Error code is  " + errr)
            }
        )
    }

    return (
        <div className="row justify-content-center pt-5">

            <div className="col-sm-5">
                <div className="card p-4">
                    <h1 className="text-center mb-3">VineoTech</h1>
                    <div >
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                    UserName for Password Change:
                                </Form.Text>
                                <Form.Control className="form-control" placeholder="Client Name "
                                    {...register("username")} required

                                />
                            </FormGroup>
                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                    vineoRegisteredClientName for Password Change:
                                </Form.Text>
                                <Form.Control className="form-control" placeholder="Client Name "
                                    {...register("vineoRegisteredClientNameofPasswordchangeUser")} required

                                />
                            </FormGroup>
                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                motherCompanyVineoclientId:
                                </Form.Text>
                                <Form.Control className="form-control" placeholder="Client Mother Name " 
                                    {...register("motherCompanyVineoclientId")} required

                                />
                            </FormGroup>
                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                password:
                                </Form.Text>
                                <Form.Control className="form-control" placeholder="Enter Your Password"
                                    {...register("password")} required
                                />

                            </FormGroup>
                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                purchaseKey:
                                </Form.Text>
                                <Form.Control className="form-control" placeholder="Enter Your Username"
                                    {...register("purcahseKey")} required
                                />

                            </FormGroup>

                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                additionalInfo:
                                </Form.Text>
                                <Form.Control className="form-control" placeholder="additionalInfo " defaultValue={' '}
                                    {...register("additionalInfo")} required

                                />


                            </FormGroup>



                            <FormGroup className="text-center">
                                <input className=" btn btn-primary m-2 " type="submit" />
                                <Button className="btn btn-primary m-2" color="primary" onClick={testserverdeployment}>
                                Admin+Billing
                                </Button><Button className="btn btn-primary m-2" color="primary" onClick={testsalesman}>
                                Admin+Billing+Salesman
                                </Button>
                            </FormGroup>

                        </form>

                    </div>



                </div >
            </div>



            {/* <SliderForLoginPage /> */}
        </div>
    )


}