import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import '../../../printFormat.css';
import QRCode from 'qrcode.react';
import { Table, Button, Col, Row, Card } from "react-bootstrap";

var converter = require('number-to-words');


class ComponentToPrint extends React.Component {
    render() {
        return (
            <>
                <div className="">
                    <br />
                    <br />
                    <strong>Purcahse Details</strong>
                    <div id='first-row'>
                        <div className='col'>
                            SUPPLIER: <strong>  {this.props.data?.vineoVendorAccount?.vineoVendorAccountName}</strong>
                            <br />
                            Address: {this.props.data?.vineoVendorAccount?.vineoVendorAccountaddress?.substring(0, 80)}
                            <br />
                            Pin:  {this.props.data?.vineoVendorAccount?.vineoVendorAccountpincode}
                            <br />
                            Contact No: {this.props.data?.vineoVendorAccount?.vineoVendorAccountmob}
                            <br />
                            State Code:  {this.props.data?.vineoVendorAccount?.vineoGst?.vineoGstNumber?.substring(0, 2)}
                            <br />
                            GSTIN:  {this.props.data?.vineoVendorAccount?.vineoGst?.vineoGstNumber}
                        </div>
                        <div className='col'>
                            CUSTOMER:<strong>{this.props.data?.vineoAddPurchaseFirm?.vineoFirmFirmName}</strong>
                            <br />
                            Address: {this.props.data?.vineoAddPurchaseFirm?.vineoFirmFirmaddress1}
                            <br />

                            Pin:  {this.props.data?.vineoAddPurchaseFirm?.vineoFirmPincode}
                            <br />
                            Contact No: {this.props.data?.vineoAddPurchaseFirm?.vineoFirmMobilenumber}<br />
                            State Code:   {this.props.data?.vineoAddPurchaseFirm?.vineofirmGst?.vineoGstNumber?.substring(0, 2)}
                            <br />
                            GSTIN:  {this.props.data?.vineoAddPurchaseFirm?.vineofirmGst?.vineoGstNumber}
                        </div>
                        <div className='col'>
                            <img src={require("../../../images/dorfketallogo.png")} height='40' alt='Dorf Ketal logo' style={{ display: 'block' }} />
                            <strong> Purchase No:</strong>   {this.props.data?.vineoaddUniquePurchaseNumberWithPrefix}
                            <br />
                            Date: {this.props.data?.vineoAddPurchaseDate}
                            <br />
                            EinvAck: {this.props.data?.einvoiceAckNo}
                            <br />
                            Salesman: {this.props.data?.salesmanName}
                            <br />
                            Salesman No:{this.props.data?.salesmanMobNo}
                        </div>
                    </div>
                    <table id='main-table'>
                        <thead>
                            <tr>
                                <th rowSpan='2'>Sr No.</th>
                                <th rowSpan='2'>Product Name</th>
                                <th rowSpan='2'>HSN Code</th>
                                <th rowSpan='2'>MRP</th>
                                <th rowSpan='2'>Unit</th>
                                <th rowSpan='2'>Qty</th>
                                <th rowSpan='2'>Free</th>
                                <th rowSpan='2'>Rate</th>
                                {/* <th rowSpan='2'>Sch Dis</th> */}
                                <th rowSpan='2'>Taxable</th>
                                <th colSpan='2'>SGST</th>
                                <th colSpan='2'>CGST</th>
                                <th rowSpan='2'>Total</th>
                            </tr>
                            <tr>
                                <th scope='col'>SGST%</th>
                                <th scope='col'>SGST</th>
                                <th scope='col'>CGST%</th>
                                <th scope='col'>CGST</th>
                            </tr>
                            <tr></tr>
                        </thead>
                        <tbody>
                            {this.props.data?.vineoAddPurchaseItemList.map((items, index) => {
                                return (

                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{items?.vineoProduct?.vineoProductName}</td>
                                        <td>{items?.vineoProduct?.vineoProducthsnCod}</td>
                                        <td>{items?.vineoPurchaseItemUnit === 'PCS' ? items?.vineoBatch?.vineoBatchProductMRPperPcs : items?.vineoBatch?.vineoBatchProductMRP}</td>
                                        {/* <td >{items.vineoBatch.vineoBatchNumber}</td> */}
                                        <td>{items?.vineoPurchaseItemUnit}</td>
                                        <td>{items?.vineoAddPurchaseProductBatchQty}</td>
                                        <td>{items?.vineoAddPurchaseItemFreeProductQty}</td>
                                        <td>{items?.vineoItemEffectiverateAfterDsicount}</td>
                                        {/* <td>0.00</td> */}
                                        <td>{items?.vineoAddBilItemPurchasevaluewithoutgst}</td>
                                        <td>{items?.vineoProduct?.vineoProductStateGstPercentage}</td>
                                        <td>{items?.vineoAddPurchaseItemsGSTAmount}</td>
                                        <td> {items?.vineoProduct?.vineoProductCentralGstPercentage}%</td>
                                        <td>{items?.vineoAddPurchaseItemcGSTAmount}</td>
                                        <td>{items?.vineoAddPurchaseItemNetAmount}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <div id='totals'>
                        <span>{converter.toWords(this.props.data?.vineoAddPurchaseBottomAmt).toUpperCase()}</span>
                        {this.props.data?.isigsctpurchasebil ? <span>iGST:  {this.props.data?.vineoAddPurchasetotal_igstAmt}</span> :
                            <span>GST:  {this.props.data?.vineoAddPurchasetotalGSTAmt} </span>}

                        <span>BASIC:{this.props.data?.vineoAddPurchasewithoutgstAmt}</span>
                        <span>NET:{this.props.data?.vineoAddPurchaseBottomAmt}</span>
                    </div>
                    <div id='last-row'>
                        <div>
                            {/* <img src={`data:image/svg+xml;base64,${btoa(this.props.data?.ewaysignedQRCode)}`}  /> */}
                            {this.props.data?.ewaysignedQRCode ? <QRCode value={this.props.data?.ewaysignedQRCode} size={256}
                                includeMargin={true} /> : <></>}
                        </div>
                        <div>
                            * GST Summary*
                            <br />
                            <table>
                                <thead>
                                    <tr>
                                        {this.props.data?.isigsctpurchasebil ? <> <th>iGST AMT</th></> : <>
                                            <th>cGST  </th>
                                            <th>sGST  </th>
                                            <th>GST AMT</th>
                                        </>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {this.props.data?.isigsctpurchasebil ? <> <th>{this.props.data?.vineoAddPurchasetotal_igstAmt}</th></> : <>
                                            <th>{this.props.data?.vineoAddPurchasecGSTAmt}  </th>
                                            <th>{this.props.data?.vineoAddPurchasesGSTAmt}  </th>
                                            <th>{this.props.data?.vineoAddPurchasecGSTAmt +
                                                this.props.data?.vineoAddPurchasesGSTAmt} </th>
                                        </>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            Back Details:  {this.props.data?.vineoAddSaleBillFirm?.vineoBankName}
                            <br />
                            IFSC Code: {this.props.data?.vineoAddSaleBillFirm?.vineoBankifscCode}
                            <br />
                            AC/No: {this.props.data?.vineoAddSaleBillFirm?.vineoBankAccountNumber}
                            <br />

                        </div>

                        <div>
                            Cash Disc:
                            <br />
                            Schme Amt:
                            <br />
                            Star Disc:
                            <br />
                            Tcs:
                        </div>
                        <div>
                            {this.props.data?.vineoAddPurchaseCashDiscount}
                            <br />
                            {this.props.data?.vineoAddPurchaseSchemeAmt}
                            <br />
                            {this.props.data?.vineoAddPurchaseStarDisc}
                            <br />
                            {this.props.data?.vineoAddPurchaseTcsAmt}
                            <br />
                        </div>
                        <div>
                            Gross Amt
                            <br />
                            Total GST
                            <br />
                            Packaging
                            <br />
                            Rounding
                            <br />
                            Payble Amt
                        </div>
                        <div>
                            {this.props.data?.vineoAddPurchasewithoutgstAmt}
                            <br />
                            {this.props.data?.isigsctpurchasebil ? <>
                                {this.props.data?.vineoAddPurchasetotal_igstAmt} </> : <>
                                {this.props.data?.vineoAddPurchasesGSTAmt + this.props.data?.vineoAddPurchasecGSTAmt}</>}
                            <br />


                            {this.props.data?.vineoaddPurchasePackingCharges}
                            <br /> {parseFloat(this.props.data?.vineoAddPurchaseRoundingAmt).toFixed(3)}
                            <br />{this.props.data?.vineoAddPurchaseBottomAmt}

                        </div>

                    </div>
                    <div id='last-row'>
                        IRN: {this.props.data?.einvoiceIRN}  & EinvAck: {this.props.data?.einvoiceAckNo}</div>
                    <div id='footer'>

                        <span>
                            {this.props.data?.vineoAddPurchaseFirm?.vineofirmtermandconstionsforbill}
                            <br />

                        </span>
                        <span>
                            <br />
                            <br />
                            {this.props.data?.vineoVendorAccount?.vineoVendorAccountName}</span>
                    </div>
                </div >


            </>
        );
    }
}

class Purchaseprint extends React.Component {

    constructor() {
        super();
        this.state = {
            count: 0
        };

    }

    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => this.componentRef}
                    pageStyle={`
                    @page {
                      margin: 0.5cm 0.5cm;
                    }
                  `}
                />

                <ComponentToPrint data={this.props.data} ref={el => (this.componentRef = el)} />
            </div>
        );
    }
}

export default Purchaseprint;