import React, { useEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";
import { faClose, faRotate, faTrash, faUserEdit, faPrint, faShare, faFileText, faStar, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, ModalHeader, ModalBody, InputGroup
} from 'react-bootstrap';
import { getVineoAddBillData, getVineoAddBillDatawithdate } from "../../../redux/features/services/entriesHelperService";
import { useDispatch, useSelector } from "react-redux";
import { BASEURL_JWTAUTH, DATEFORMAT, GSTEWAYBILLGETAUTHTOKEN, VINEOFORM_DELETE_SELECTED_ADDBILL, VINEOFORM_DELETE_SELECTED_ADDBILLBYSUPERADMIN, VINEOFORM_GETJSON_OF_SELECTED_ADDBILL, VINEO_DELETE_GRN, VINEO_DELETE_GRN_BY_SUPERADMIN, VINEO_GETALL_ADDBILL_AVAILABLE, VINEO_GETBILLWITHID_ADDBILL_AVAILABLE, VINEO_GET_ALLGRNWITHID, VINEO_IMPORT_PURCHASE_FROMCSV_FILE } from "../../../constants/Urlforvineo";
import http from "../../../constants/AxiosConfig";
import Billprint from "./billing/Billprint";
import BillEdit from "./billing/BillEdit";
import { Typeahead } from "react-bootstrap-typeahead";
import { getVineoFirmData, getVineoGRNDataWithDate, getVineoGodownData, getVineoGodownDataofRefreeredClientforImport } from "../../../redux/features/services/serviceHelper";
import Loadprint from "./billing/Loadprint";
import { counter } from "@fortawesome/fontawesome-svg-core";
import EwaybillGenerate from "./billing/EwaybillGenerate";
import { addgstDeatilsFromUser } from "../../../redux/features/vineoData/vineoDataSlice";
import GetGstUserDetailsforEwayBill from "./billing/GetGstUserDetailsforEwayBill";
import BillprintFormat2 from "./billing/BillprintFormat2";
import axios from "axios";
import GoDownMaster from "../master/GoDownMaster";
import Purchaseprint from "./Purchaseprint";
function AddGrn() {
    let totalbillValue = 0;
    let billcounter = 0;
    let vineoaddbilldatawithdate = []
    let currentDate = new Date();
    const dispatch = useDispatch()
    const vineoGRNdatawithDate = useSelector((state) => state.vineoData.vineoGRNdatawithDate);
    const isUserHaveSuperadminAuthority = useSelector((state) => state.vineoData.isUserHaveSuperadminAuthority);

    const refreshcount = useSelector((state) => state.vineoData.refreshcount)


    const today = new Date();
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 10));
    const [todate, setTodate] = useState(new Date());
    const [todaysdate, setTodaysdate] = useState(new Date());


    const [selectedDate, setSelectedDate] = useState();
    const [totalbillValueState, setTotalbillValueState] = useState(0);
    const [billcounterstate, setBillcounterstate] = useState(0);
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const [openprint, setOpenprint] = React.useState(false);
    const [openpurchaseprint, setOpenpurchaseprint] = React.useState(false);
    const [opensendBilltoCustomer, setOpensendBilltoCustomer] = React.useState(false);
    const [openeditBill, setOpeneditBill] = React.useState(false);
    const [dataforprint, setDataforprint] = useState();
    const [dataforPurchaseprint, setDataforPurchaseprint] = useState();
    const [dataforBillimport, setDataforBillimport] = useState();
    const [selectedGodown, setSelectedGodown] = useState([])
    const [isEnabled, setIsEnabled] = useState(false);
    const [billnumber, setBillNumber] = useState()
    const [bill, setBill] = useState({})
    const [loaddetailsforprint, setLoaddetailsforprint] = useState([{}])
    const [loaddetailsUpdatedforprint, setLoaddetailsUpdatedforprint] = useState([{}])
    let tmpAllProductData = {}
    let selectedBillsArray = []
    const [numberofloadbillselected, setNumberofloadbillselected] = useState(0)
    const [totalloadBillValue, setTotalloadBillValue] = useState(0)
    const [summaryofload, setSummaryofload] = useState({})
    const [openloadprint, setOpenloadprint] = React.useState(false);
    const [tableData, setTableData] = useState([]);
    const [searchedbill, setSearchedbill] = useState({})
    const [openEwayJsonModal, setOpenEwayJsonModal] = useState(false);
    const [openAddGodown, setOpenAddGodown] = useState(false);

    const [openEwayBillGenerateJsonModal, setOpenEwayBillGenerateJsonModal] = useState(false);
    const [jsonValueforewaybill, setJsonValueforewaybill] = useState([]);
    const [showtextOnly, setShowtextOnly] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [paymentpaid, setPaymentpaid] = useState(false);

    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)

    const vineoGodowndataofrefferedclient = useSelector((state) => state.vineoData.vineogodowndataofAutoImportrefClient)
    const dataforAuth = {};



    const { register, handleSubmit, setValue } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        // submitForm(data)

    }
    useEffect(() => {

        dispatch(getVineoFirmData())

    }, []);


    useEffect(() => {

        console.log("Refreshing on state Change " + refreshcount)

        vineoGRNdatawithDate?.map((item) => {
            vineoaddbilldatawithdate.push(item?.vineoAddBill)
        })
        setTableData(vineoGRNdatawithDate)
        setLoading(false)

    }, [refreshcount]);

    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)

    const getbilldatafromserverwithdate = (reqdata) => {

        if (reqdata.startDate && reqdata.endDate) {
            // console.log(reqdata.startDate)
            // console.log(reqdata.endDate)
            // console.log(reqdata)
            var endDate = moment(reqdata.endDate, 'DD-MM-YYYY');
            var startDate = moment(reqdata.startDate, 'DD-MM-YYYY');
            if (endDate.diff(startDate, 'days') < 380) {
                setLoading(true)
                dispatch(getVineoGRNDataWithDate(reqdata));

            } else {
                setLoading(false)
                notifyError("Number Of Days Range for Bill Should Be Less than 1 year ")
            }
        } else {
            setLoading(false)
            notifyError("Please Enter Date for Bill Start and End ")
        }
    }



    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }

    const rowStyle = { background: 'white' };

    const getRowStyle = (params) => {
        // console.log("vlaue of ",parseInt(params.data.vineoCustomerAccountcreditDay))
        // console.log(params.data.vineoAddSaleBillDate)

        // console.log("Date Diffrence is ", diffInDays)
        if (params.data.vineoAddBill.grnAccepted)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'green' };
        }


    };

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }
    const onSelectBill = () => {
        selectedBillsArray = [];

        let tempvalue = 0;

        gridApi.api.getSelectedNodes().map((bill, index) => {
            return (
                <>
                    {selectedBillsArray[index] = bill.data}
                    {/* {console.log(bill.data)} */}
                    {tempvalue = tempvalue + parseFloat(bill.data.vineoAddSaleBillBottomAmt)}
                    {/* {console.log(totalloadBillValue)}
                    {console.log(selectedBillsArray.length)} */}
                </>
            )
        })


    }

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        editable: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }
    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedRows();
        var selectedRowsString = '';
        var maxToShow = 5;
        // console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }



    function onCellValueChanged(event) {
        // console.log(
        //     'onCellValueChanged: '
        // );
    }
    const columnDefs = [

        { headerName: "GRNNO.", field: "grnUniqeId", checkboxSelection: true },
        {
            headerName: "Date ", field: "vineoAddPurchaseDate",
            comparator: function (date1, date2) {
                var date1Obj = new Date(date1.split('-').reverse().join('-'));
                var date2Obj = new Date(date2.split('-').reverse().join('-'));
                if (date1Obj > date2Obj) {
                    return 1;
                } else if (date1Obj < date2Obj) {
                    return -1;
                } else {
                    return 0;
                }
            }
        },
        { headerName: "Vendor", valueGetter: 'data.vineoAddBill.vineoAddSaleBillFirm.vineoFirmFirmName', resizable: true },
        { headerName: "Party", valueGetter: 'data.vineoAddBill.vineoAddSaleBillCustomer.vineoCustomerAccountName', resizable: true },

        { headerName: "Amount", valueGetter: 'data.vineoAddBill.vineoAddSaleBillBottomAmt', resizable: true },

        {
            headerName: "Actions", field: "id", resizable: true, cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" size="sm" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>
                <Button variant="danger m-1" size="sm" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
                <Button variant="info m-1" size="sm" onClick={() => handleSendBill(params.data)}><FontAwesomeIcon icon={faUserCheck} /></Button>
                {isUserHaveSuperadminAuthority ?
                    <Button variant="danger m-1" size="sm" onClick={() => submitFormForDeleteSelectedRowBySuperadmin(params.data)}> <FontAwesomeIcon icon={faStar} /></Button>
                    :
                    <></>
                }
            </div>
            //   disabled ={ !(params?.value?.vineoAddSaleBillCustomer?.autoPurchaseImportActive)}
        },
        {
            headerName: "GRNaccept", valueGetter: `data.vineoAddBill.grnAccepted`,
            cellRenderer: params => {

                if (params.value) {
                    return `YES`
                }
                return `NO`;
            }
        }

    ]





    let data = {}



    const geteTotalBillValue = () => {
        console.log("Called to get Total Bill Value")
        totalbillValue = 0;
        billcounter = 0;
        if (vineoaddbilldatawithdate) {

            console.log("Bills are selected ")
            vineoaddbilldatawithdate.map((bill, index) => {
                return (
                    <>
                        {/* {console.log(bill)} */}
                        {totalbillValue = totalbillValue + parseFloat(bill?.vineoAddSaleBillBottomAmt || '0')}
                        {billcounter++}

                    </>
                )
            })

            setBillcounterstate(billcounter);
            setTotalbillValueState(totalbillValue);

            notify("Total Bill Value in Table is " + totalbillValue + "And Bills Are" + billcounter);
        }
        else {
            notifyError("Pls Select Bills")
        }
    }


    const submitFormForDeleteSelectedRow = (requestdata) => {
        // console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEO_DELETE_GRN, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- GRN  DELETED SUCCESSFULLY with Bill No as ' + res.data.additionalInfo)

                }
                else { notifyError("Error While Deleting GRN  " + res.data.additionalInfo) }
            },
            (err) => {
                notifyError("Error While Deleting GRN Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitFormForDeleteSelectedRowBySuperadmin = (requestdata) => {

        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            console.log(requestdata)
            http.post(BASEURL_JWTAUTH + VINEO_DELETE_GRN_BY_SUPERADMIN, requestdata).then(

                (res) => {
                    // console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

                    }
                    else { notifyError("Error While Deleting Bill  " + + res.data.errorMessage) }
                },
                (err) => {
                    notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

                }
            )
        }
    }

    const getBilldatafromServer = (billnumber) => {
        if (!billnumber) {
            notifyError("Please Enter Bill Number")
        }
        else if (billnumber.length > 0) {
            console.log("Calling Server to get data with billnumber = " + billnumber);

            http.post(BASEURL_JWTAUTH + VINEO_GET_ALLGRNWITHID, { "vineoaddUniqueBillNumberWithPrefix": billnumber }).then(
                (res) => {
                    console.log(res.data)
                    if (res.data.grnUniqeId) {
                        // console.log(res.data);
                        // setTableData(res?.data,...tableData)
                        setDataforprint(res.data?.vineoAddBill);
                        setOpenprint(!openprint)
                        setSearchedbill(res.data)


                    }
                    else {
                        notifyError("Empty Data Received from Server for Bill NUmber " + billnumber);
                        setBill({});
                    }

                },
                (err) => {
                    const stored = sessionStorage.getItem('uniqueidForVineoclientName')
                    const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
                    const stored2 = JSON.parse(sessionStorage.getItem("token"))
                    console.log("Error From Server with code = " + err.data)
                    notifyError("Empty Data Received from Server for Bill NUmber " + billnumber);
                }
            )

        }
        else {
            notifyError("Please Enter Bill Number It cant be Empty")
        }

    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    // Ag grid Work End

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handlePrint = (data) => {
        console.log(data)

        setDataforprint(data?.vineoAddBill);
        setOpenprint(!openprint)
    }
    const handleSendBill = (data) => {
        console.log("Handle send Bill Called with Data")
        // console.log(data)
        if (!data.active) {
            notify("Bill Is Cancelled Cant be Sent ")
        }

        else {
            setDataforprint(data.vineoAddBill);
            console.log("Data fro print")
            // console.log(dataforprint)
            setOpensendBilltoCustomer(!opensendBilltoCustomer)
        }
    }
    const acceptPurchaseGRN = () => {
        console.log("Sendign Bill to Customer as Purchase with data for print")
        console.log(dataforprint?.vineoAddSaleBillCustomer?.uniqueidofrefreedClientNameAutoImport)
        if (selectedGodown?.[0]) {
            console.log(selectedGodown?.[0]?.ultiVineoGoDownUniqeId)
            // call Auto Import CSV endpoint with details of voneoaddbill and gowdowndetails
            const formData = new FormData();
            // formData.append('vineofile', file);
            // formData.append('vineoFirm', (selectedFirm?.[0]?.ultiVineoFirmUniqeId))
            formData.append('ultiVineoGoDownUniqeId', (selectedGodown?.[0]?.ultiVineoGoDownUniqeId))
            formData.append('vineoAddBilluniqueid', (dataforprint?.ultiVineoAddBillUniqeId))
            formData.append('paymentpaid', paymentpaid)
            formData.append('clientNameofrefclientforautoimport', dataforprint?.vineoAddSaleBillCustomer?.uniqueidofrefreedClientNameAutoImport)
            console.log(formData)

            http.post(BASEURL_JWTAUTH + VINEO_IMPORT_PURCHASE_FROMCSV_FILE, formData)
                .then((res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify(' Success:-  Purchase Imported with Invoice ID as ' + res.data.additionalInfo)
                        setIsEnabled(false)
                        setDataforPurchaseprint(res?.data?.data);
                        setOpenpurchaseprint(!openpurchaseprint)
                    }
                    else {
                        notifyError("Error While Importing Purcahse Info From Server " + res.data.additionalInfo)
                    }
                    //  console.log(res.data)
                    setIsEnabled(false)

                },
                    (err) => {
                        console.log(err)

                        notify("Error While Importing Data" + err.data)
                        setIsEnabled(false)
                    })
                .catch((err) => {

                    notifyError("Error While Importing Data" + err)
                    setIsEnabled(true)
                });



        }
        else {
            notifyError("Pls Select Party Godown")
        }
    }


    return (
        <div>

            <div className='h5 mb-0 text-gray-800'>
                <center>
                    <h3 align="center">GRN , Total Bills = {billcounterstate}, Total Amount ={totalbillValueState}</h3>

                </center>



            </div>
            <div>
                <Row><Col md={3}>

                    <FormGroup className="m-2">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Text >
                                Bills From Date
                            </Form.Text>
                            <Form.Text onClick={() => {
                                setFromdate(currentDate.setDate(currentDate.getDate() - 3))
                            }} > -3Day</Form.Text>
                            <Form.Text onClick={() => {
                                setFromdate(currentDate.setDate(currentDate.getDate() - 60))
                            }} > -60Day</Form.Text>
                            <Form.Text onClick={() => {
                                setFromdate(currentDate.setDate(currentDate.getDate() - 365))
                            }} > -1Year</Form.Text>
                        </div>
                        <DatePicker className="form-control"
                            selected={fromdate}
                            onChange={setFromdate}
                            name="vineoCreateLoadBillFromDate"
                            dateFormat="dd-MM-yyyy"
                            ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
                        >
                        </DatePicker>
                    </FormGroup>

                </Col>
                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Bills To Date
                            </Form.Text>

                            <DatePicker className="form-control"
                                selected={todate}
                                onChange={setTodate}
                                name="vineoCreateLoadBillToDate"
                                dateFormat="dd-MM-yyyy"
                                ref={setValue("vineoCreateLoadBillToDate", moment(todate).format(DATEFORMAT))}
                            />

                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Enter Bill Number To search* -{searchedbill?.vineoaddUniqueBillNumberWithPrefix}
                            </Form.Text>
                            <InputGroup>
                                <Form.Control className="form-control"
                                    onChange={(e) => {
                                        setBillNumber(e.target.value)
                                    }} />

                                <Button variant="primary"
                                    onClick={(e) => { getBilldatafromServer(billnumber) }} >
                                    <FontAwesomeIcon icon={faRotate} />
                                </Button>
                                {searchedbill?.ultiVineoAddBillUniqeId ?
                                    <>
                                        {/* <Button variant="success m-1" onClick={() => { getBilldatafromServer(billnumber); handlePrint(searchedbill) }}><FontAwesomeIcon icon={faPrint} /></Button> */}
                                        <Button variant="danger m-1" size="sm" onClick={() => handleDelete(searchedbill)}> <FontAwesomeIcon icon={faTrash} /></Button>
                                        <Button variant="info m-1" size="sm" onClick={() => handleSendBill(searchedbill)}><FontAwesomeIcon icon={faUserCheck} /></Button></> :

                                    <></>}


                            </InputGroup>
                        </FormGroup>
                    </Col>

                </Row>


                <Button className="btn btn-warning m-1" disabled={isLoading}
                    onClick={() => { setLoading(!isLoading); dispatch(getVineoGRNDataWithDate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(todate).format(DATEFORMAT) })) }}> <FontAwesomeIcon icon={faRotate} /> {isLoading ? 'Loading…' : 'Load GRN'} </Button>

                {/* <button className="btn btn-primary m-2" type="button" onClick={onSelectBillButtonClick}>
                    SelectBills
                </button> */}
                <Button className="btn btn-primary m-1" onClick={onExportClick} value="ExportToCsv">Export To CSV
                </Button>

            </div>

            <div className="ag-theme-alpine" style={{ height: '400px' }}>

                <div className="ag-theme-alpine" style={{ height: '700px' }}>
                    <AgGridReact
                        id="AgGridforBillData"
                        rowData={tableData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        onRowSelected={onSelectBill}
                        rowSelection="multiple"
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                    />

                </div>
            </div>

            <Modal show={openprint}
                onHide={() => setOpenprint(!openprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {console.log("PrintForamt is ", vineofirmdata?.[0]?.vineoaddBillFormat)}

                    <Card className='p-4'>
                        <BillprintFormat2 data={dataforprint} />
                    </Card>


                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal show={opensendBilltoCustomer}
                onHide={() => setOpensendBilltoCustomer(!opensendBilltoCustomer)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Row>
                        <Col>
                            <Button className="btn btn-primary m-2" disabled={isEnabled} onClick={acceptPurchaseGRN} value="ACCEPT GRN">ACCEPT GRN
                            </Button>
                        </Col>
                        <Col>
                            {console.log(paymentpaid)}
                            <FormGroup >

                                <Form.Text className="text-muted">
                                    paymentpaid
                                </Form.Text>
                                <Form.Select className="form-control custom-form-field" onClick={(e) => { setPaymentpaid(e.target.value) }} >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </Form.Select>

                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <>Purchase Will Added to Client id {dataforprint?.vineoAddSaleBillCustomer?.uniqueidofrefreedClientNameAutoImport} & Name of Party -
                                {dataforprint?.vineoAddSaleBillCustomer?.vineoCustomerAccountName} This Cant Be reversed</>
                        </Col>
                        <Col md={3}>
                            <InputGroup>
                                <Typeahead

                                    id="VendorGodownTypehead"
                                    onChange={setSelectedGodown}
                                    options={vineoGodowndataofrefferedclient ? vineoGodowndataofrefferedclient : []}
                                    placeholder="Choose Godown of Party for this Purchase"
                                    selected={selectedGodown}
                                    labelKey={"vineoGoDownName"}
                                    renderMenuItemChildren={(option) => (
                                        <div>
                                            <div>
                                                <small>Name: {option?.vineoGoDownName} </small>
                                                <small>- City:{option?.vineoGoDownCity}</small>
                                            </div>
                                        </div>
                                    )}

                                />
                                <Button variant="primary"
                                    onClick={() => { dispatch(getVineoGodownDataofRefreeredClientforImport({ "clientNameofrefclientforautoimport": dataforprint?.vineoAddSaleBillCustomer?.uniqueidofrefreedClientNameAutoImport })); }} >
                                    <FontAwesomeIcon icon={faRotate} />
                                </Button>|
                                <Button variant="primary"
                                    onClick={() => { setOpenAddGodown(true) }} >
                                    +
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <BillprintFormat2 data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal show={openAddGodown}
                onHide={() => setOpenAddGodown(!openAddGodown)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Godown</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <GoDownMaster />
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={openpurchaseprint}
                onHide={() => setOpenpurchaseprint(!openpurchaseprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Purchase Bill Print</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <Purchaseprint data={dataforPurchaseprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>



        </div>
    );

}
export default AddGrn;

