import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm, useWatch } from "react-hook-form";
import { faClose, faPrint, faRotate, faTrash, faUserEdit, faFileText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from "moment";
import { v4 } from 'uuid';
import {
    FormGroup, Label, Input, Modal,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Table, FormText
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, DATEFORMAT, GSTEWAYBILLGETAUTHTOKEN, VINEOFORM_GETEWAYBILL_OF_SELECTED_ADDBILL, VINEOFORM_GETEWAYBILL_OF_SELECTED_CREDITNOTE, VINEOFORM_GETJSON_OF_SELECTED_CREDITNOTE, VINEOFORM_UPDATE_SELECTED_CREDITNOTE, VINEO_DELETE_CREDITNOTE_AVAILABLE, VINEO_GETBILLWITHID_ADDBILL_AVAILABLE } from '../../../constants/Urlforvineo';
import { getVineoAccountCustomerMasterMenu, getVineoFirmData, getVineoGodownData, getVineoCompanyData, getVineoSchemaMasterData, getVineoProductData, getVineoCreditNotePositiveData, getVineoCreditNoteDataWithDate } from '../../../redux/features/services/serviceHelper';



import { useSelector, useDispatch } from 'react-redux'
import http from '../../../constants/AxiosConfig';
import AllPartywiseSale from '../salesReport/AllPartywiseSale';
import CreditNoteprint from './CreditNoteprint';
import { addgstDeatilsFromUser } from '../../../redux/features/vineoData/vineoDataSlice';
import axios from 'axios';

let totalDiscountPercentagevalue = 0;

export default function AddCreditNote() {
  

    const dispatch = useDispatch()
    const [bill, setBill] = useState({})
    const [crn, setCrn] = useState({})
    const [showtextOnly, setShowtextOnly] = useState(false);
    const [creditNotedate, setCreditNote] = useState(new Date())
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
    const [todate, setTodate] = useState(new Date());
    const vineocreditnotedataWithdate = useSelector((state) => state.vineoData.vineocreditnotedataWithdate);
    const refreshcount = useSelector((state) => state.vineoData.refreshcount);
    const isvineocreditnotedataWithdateLoaded = useSelector((state) => state.vineoData.isvineocreditnotedataWithdateLoaded);
    const vineofirmdatafromredux = useSelector((state) => state.vineoData.vineofirmdata)
    const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();
    const [vineofirmdata, setVineofirmdata] = useState()
    const [billnumber, setBillNumber] = useState()
    const [issuedCCwithoutReceipt, setIssuedCCwithoutReceipt] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const [tableData, setTableData] = useState();
    const [openprint, setOpenprint] = React.useState(false);
    const [dataforprint, setDataforprint] = useState();
    const [openPartyWiseReport, SetOpenPartyWiseReport] = useState(false)
    const dataforAuth= {};
    const navigate = useNavigate();

    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSchemeUniqeId": "Working", "vineoCompanySchemeNumber": "Click Add To Load Scheme" }])
    const isvineoschemamasterdataLoaded = useSelector((state) => state.vineoData.isvineoschemamasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [jsonValueforewaybill, setJsonValueforewaybill] = useState([]);
    const [openEwayJsonModal, setOpenEwayJsonModal] = useState(false);
    const [gStApiloginSuccess, setGStApiloginSuccess] = useState(false);
    const gstDeatilsFromUserfromRedux = useSelector((state) => state.vineoData.gstDeatilsFromUser)
    const [gstDeatilsFromUser, setGstDeatilsFromUser] = useState(false);

    let selectedBillsArray = []
    const onSubmit = (data) => {
        // console.log(JSON.stringify(data));
        console.log((data));
        submitForm(data)
    }
    let TotalCreditAmount = 0;
    useEffect(() => {

        dispatch(getVineoFirmData())
        setGstDeatilsFromUser(gstDeatilsFromUserfromRedux)

    }, []);
   

    useEffect(() => {
        console.log("Calling Refresh ")
        setTableData(vineocreditnotedataWithdate)
        setLoading(false)
        setVineofirmdata(vineofirmdatafromredux)
        setGstDeatilsFromUser(gstDeatilsFromUserfromRedux)
    }, [ refreshcount,gStApiloginSuccess]);


    const getDatafromServer = (reqdata) => {
        if (reqdata.startDate && reqdata.endDate) {
            // console.log(reqdata.startDate)
            // console.log(reqdata.endDate)
            // console.log(reqdata)
            var endDate = moment(reqdata.endDate, 'DD-MM-YYYY');
            var startDate = moment(reqdata.startDate, 'DD-MM-YYYY');
            if (endDate.diff(startDate, 'days') < 380) {
                setLoading(true)
                dispatch(getVineoCreditNoteDataWithDate(reqdata));
                isvineocreditnotedataWithdateLoaded ? setTableData(vineocreditnotedataWithdate) : console.log("Empty data from Server for Debit Note")

            } else {
                setLoading(false)
                notify("Number Of Days Range for Debit Note Should Be Less than 1 year ")
            }

        }
        else {
            setLoading(false)
            notify("Please Enter Date for purchase Start and End ")
        }


    }



   
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const getBilldatafromServer = (billnumber) => {
        if (!billnumber) {
            notify("Please Enter Bill Number")
        }
        else if (billnumber.length > 0) {
            console.log("Calling Server to get data with billnumber = " + billnumber);

            http.post(BASEURL_JWTAUTH + VINEO_GETBILLWITHID_ADDBILL_AVAILABLE, { "vineoaddUniqueBillNumberWithPrefix": billnumber }).then(
                (res) => {

                    if (res.data.ultiVineoAddBillUniqeId) {
                        console.log(res.data);
                        if (issuedCCwithoutReceipt || res.data.receiptIsGenerated) {

                            // if (!res.data?.iscreditNoteGenerated)
                            if (true) {
                                console.log(res.data.iscreditNoteGenerated);
                                setBill(res.data);
                            }
                            else {
                                notifyError("Credit Note Already Generated, Pls Select Another Bill");
                                setBill({});
                            }
                        }
                        else {
                            notifyError("Credit Note Cant Be Generated ,As Receipt for Bill is Not Generated ");
                            setBill({});
                        }

                    }
                    else {
                        notifyError("Empty Data Received from Server for Bill NUmber " + billnumber);
                        setBill({});
                    }
                },
                (err) => {

                    const stored = sessionStorage.getItem('uniqueidForVineoclientName')
                    const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
                    const stored2 = JSON.parse(sessionStorage.getItem("token"))
                    console.log("Error From Server with code = " + err.data)

                }
            )

        }
        else {
            notify("Please Enter Bill Number It cant be Empty")
        }

    }


    const submitForm = (data) => {
        console.log("Connecting Server to Create CreditNote")
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_CREDITNOTE, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorMessage == 'SUCCESS') {
                    notify('Credit Note Operation Successful with Details =' + res.data.errorCode)
                    setBill({});
                    setVineofirmdata(vineofirmdatafromredux)
                    handlePrint(res?.data?.data)
                    reset();

                }
                else
                    notifyError("Error While Add Credit Operation From Server with Message as " + res.data.additionalInfo + res.data.errorCode)
                //  console.log(res.data)

            },
            (err) => {
                notifyError("Error While Add Credit Operation From Server ")
            }
        )
    }


    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)

    const onSelectBill = () => {
        selectedBillsArray = [];

        let tempvalue = 0;

        gridApi.api.getSelectedNodes().map((creditbill, index) => {
            return (
                <>
                    {selectedBillsArray[index] = creditbill.data}

                </>
            )
        })

    }

    const onExportClick = () => {
        gridApi.api.exportDataAsCsv();
    }

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        editable: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }

    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedNodes();
        var selectedRowsString = '';
        var maxToShow = 5;
        console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }


    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [
        { headerName: "ID", field: "ultiVineoCreditNoteUniqeId", checkboxSelection: true },
        { headerName: "CRN No.", field: "creditNoteNumberwithprefix",resizable: true  },
        { headerName: "BillNo.", valueGetter: 'data.creditNoteIssuedonBillNumberwithprefix' ,resizable: true  },
        { headerName: "Date ", field: "vineoCreditNoteDate" ,resizable: true  },

        { headerName: "Amount", field: 'creditnoteAmount' ,resizable: true  },
        { headerName: "Party", valueGetter: 'data.vineoCustomerAccountName',resizable: true  },

        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>
                <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
            </div>
        }
    ]


    const handleUpdate = (data) => {
        console.log(data)

        // console.log("This will editqty by this amount "+updatedProductBatchQty);
        // submitForm(data)

    }

    const handlePrint = (data) => {
        console.log(data)
        setDataforprint(data);
        setOpenprint(!openprint)
    }
    //deleting a user
    const geteinvoiceJson = () => {
        // console.log("Called to get JSON ", selectedBillsArray)
        if (selectedBillsArray?.[0]?.ultiVineoCreditNoteUniqeId) {
            console.log("CRN are selected ")
            getJsoneinvoicebillString(selectedBillsArray);
        }
        else {
            notifyError("Pls Select Credit Note")
        }
    }

    const getJsoneinvoicebillString = (data) => {

        http.post(BASEURL_JWTAUTH + VINEOFORM_GETJSON_OF_SELECTED_CREDITNOTE, data).then(
            (res) => {
                // console.log(res.data)
                // console.log(JSON.stringify(res.data))
                setJsonValueforewaybill(res.data)
                setOpenEwayJsonModal(true);

            },
            (err) => {
                notifyError('Error While Generatign JSON ')

            }
        )
    }

    
    const generateEwayBill = () => {

        console.log(gstDeatilsFromUser.datastoredinStatefromgstewaybill)

        // console.log("Toekn Expiry",gstDeatilsFromUser.TokenExpiry);
        let tokenExpiry = null;
        let expiryTime = null;
        tokenExpiry = gstDeatilsFromUser.TokenExpiry;
        if (tokenExpiry) {
            expiryTime = new Date(tokenExpiry);
        }
        const now = new Date();
        if (gstDeatilsFromUser.datastoredinStatefromgstewaybill) {
            if (!(expiryTime < now)) {
                console.log("Gst data is set in Redux ");
                // console.log("Called to get JSON ", selectedBillsArray)

                if (selectedBillsArray?.[0]?.ultiVineoCreditNoteUniqeId) {

                    if (selectedBillsArray?.[0]?.einviceCRNIsGenerated) {
                        notifyError("Einv CRN Already Generated")
                    }
                    else {
                        console.log("CR are selected ")
                        setCrn(selectedBillsArray?.[0])
                        generateEwayBillfromServer(crn);
                    }
                }
                else {
                    notifyError("Pls Select CR")
                }

            }
            else {
               
                // setOpenGstEwayBillGetDetails(true)
            }
        }
        else {
           
            console.log("Calling get Auth token")
                dataforAuth.gstin=vineofirmdata?.[0]?.vineoFirmGstNumber;
                dataforAuth.MVApiKey= vineofirmdata?.[0]?.vineoFirm_GSTMVApiKey;
                dataforAuth.eInvoiceUserName= vineofirmdata?.[0]?.vineoFirm_GSTAPIUSERNAME;
                dataforAuth.eInvoicePassword= vineofirmdata?.[0]?.vineoFirm_GSTAPIPASSWORD;
                dataforAuth.MVSecretKey = vineofirmdata?.[0]?.vineoFirm_GSTMVSecretKey;
                // call gst api to get token 
                getGstEayBillToken(dataforAuth)
        }
        // dispatch(addgstDeatilsFromUser({'eInvoiceUserName':'Atik', 'eInvoicePassword':'Shaikh'}));

    }

    const generateEwayBillfromServer = (data) => {
       // directly call api to save in IRN with CRN
     
       {
        // api call


        const GSTIN = vineofirmdata?.[0]?.vineoFirmGstNumber;
        const eInvoiceUserName = gstDeatilsFromUser.eInvoiceUserName;
        const eInvoicePassword = gstDeatilsFromUser.eInvoicePassword;
        const authToken = gstDeatilsFromUser.AuthToken;
        const MVApiKey = vineofirmdata?.[0]?.vineoFirm_GSTMVApiKey;
        const MVSecretKey = vineofirmdata?.[0]?.vineoFirm_GSTMVSecretKey;
      
        // Get the current month and year in the format "MM-yyyy"
        const currentDate = new Date();
        const monthYear = `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
        const headers = {
            MVApiKey,
            MVSecretKey,
            GSTIN,
            eInvoiceUserName,
            eInvoicePassword,
            authToken,
            MonthYear: monthYear,
            'Content-Type': 'application/json',
            'X-uniqueidForVineoclientName': `${JSON.parse(sessionStorage.getItem("uniqueidForVineoclientName"))}`,
            'X-vineoRegisteredClientName': `${JSON.parse(sessionStorage.getItem("vineoRegisteredClientName"))}`,
            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
            "x-appCorrelationid": v4()
        };

        axios.post(BASEURL_JWTAUTH + VINEOFORM_GETEWAYBILL_OF_SELECTED_CREDITNOTE, data, { headers }).then(
            (res) => {
                console.log(res.data)
                if ( (res.data.errorCode == "0000")) {
                    notify('Success:- E Invoice of  Credit Note Generated Created with Internal Id')
                    console.log(res.data)
                    reset()
                }
                else {
                    notifyError("Error While Creating  E Invoice of Credit Note From Server " + res.data.additionalInfo)
                }

            },
            (err) => {
                notifyError("Error While Creating  E Invoice of Credit Note")
                console.log(err.data)
            }
        )
    }
    }

    const getGstEayBillToken = (data) => {
        // console.log(JSON.stringify(data));
        // api call
        axios.post(GSTEWAYBILLGETAUTHTOKEN, JSON.stringify(data), {
            headers: {
              'Content-Type': 'application/json',
             
            }}).then(
            (res) => {
                if (res.data.Status == "1") {
                    setGStApiloginSuccess(true);
                    dispatch(addgstDeatilsFromUser({
                        'eInvoiceUserName': data.eInvoiceUserName, 'eInvoicePassword': data.eInvoicePassword, 'datastoredinStatefromgstewaybill': true,
                        'AuthToken': res.data.AuthToken,
                        'Sek': res.data.Sek,
                        'gstin': vineofirmdata?.[0]?.vineoFirmGstNumber,
                        'TokenExpiry': res.data.TokenExpiry
                    }));

                    // comment part after this for prod creds
                    notify('Success:- Log In Success For Next 5 Hr ')

                }
                else {
                    console.log("Call Failed ");
                    // dispatch(addgstDeatilsFromUser({'eInvoiceUserName':data.eInvoiceUserName, 'eInvoicePassword':data.eInvoicePassword,'datastoredinStatefromgstewaybill': true, 
                    // 'AuthToken': 'Fa8x1pAOyIWFPfS9NHJqbI8EY',
                    // 'Sek': '/N9/jW1l6FMmASR0VuJJeu7rg3/CNfj8P2i0mLVEV0xVl5cz4fBprTZIh0DA9Vjr',
                    // 'TokenExpiry':'2023-05-11T17:24:52'}));
                    notifyError(res.data?.ErrorMessage)
                }
            },
            (err) => {
                notifyError("Error While Loggin IN GST for E Invoice   ");

         
            }
        )
    }

    const exportToJson = (dataObjToWrite) => {
        const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
        const link = document.createElement("a");

        link.download = "einvoce.json";
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        link.dispatchEvent(evt);
        link.remove()
    };



    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEO_DELETE_CREDITNOTE_AVAILABLE, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Credit Note  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)
                    reset();
                }
                else
                    notifyError("Error While Deleting Credit Note  " + res.data.errorMessage)
            },
            (err) => {
                notifyError("Error While Deleting Credit Note Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    // AG work ends here 

    return (
        <div>

            <div>
            <div className='h5 mb-0 text-gray-800'>
                    <center>
                        <h5 className='justify-content-center m-1'>Add Credit Note :</h5>
                    </center>
                </div>
                <Row>

                    <Form.Group controlId="issuedCCwithoutReceipt" className="m-2">
                        <Form.Check
                            onChange={(e) => { setIssuedCCwithoutReceipt(!issuedCCwithoutReceipt); setBill({}); }}
                            label='Issue Credit Note Without Receipt'
                            id="Checkbox"
                        />
                    </Form.Group>
                    {setValue("issuedCCwithoutReceipt", issuedCCwithoutReceipt)}

                </Row>
                <Row>

                    <Col md={4}>


                        <Form.Text >Enter Bill Number With Prefix*</Form.Text >
                        <InputGroup>
                            <Form.Control className="form-control"
                                onChange={(e) => {
                                    setBillNumber(e.target.value)
                                }} />

                            <Button variant="primary"
                                onClick={(e) => { getBilldatafromServer(billnumber) }} >
                                <FontAwesomeIcon icon={faRotate} />
                            </Button>


                        </InputGroup>
                        <Form.Text >Bill Details for Credit Note:</Form.Text >

                    </Col>

                    <Col>
                        <Form.Text >Search Bill Number </Form.Text >
                        <InputGroup><Button className="btn btn-primary "
                            onClick={(e) => { SetOpenPartyWiseReport(true) }} >Find Bill

                        </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <div>
                            <Card className='p-4'>
                                <Row>
                                    <Col md={2}>
                                        <FormGroup>
                                            {setValue("vineoAddBill", bill)}
                                            <Form.Text >
                                                Credit Note Date
                                            </Form.Text>
                                            <DatePicker className="form-control"
                                                selected={creditNotedate}
                                                onChange={setCreditNote}
                                                name="creditNotedate"
                                                dateFormat="dd-MM-yyyy"
                                                ref={setValue("vineoCreditNoteDate", moment(creditNotedate).format(DATEFORMAT))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={1}>
                                        <Form.Text >Bill No</Form.Text >
                                        <InputGroup>
                                            <Form.Text >{bill?.vineoaddUniqueBillNumberWithPrefix}</Form.Text>
                                            <>  {setValue("creditNoteIssuedonBillNumber", bill?.ultiVineoAddBillUniqeId)}</>
                                            {/* this is unique id of bill  */}
                                        </InputGroup>
                                    </Col>
                                    <Col md={1}>
                                        <Form.Text >Total Bill Value-</Form.Text >
                                        <InputGroup>
                                            <Form.Text >{bill?.vineoAddSaleBillBottomAmt}</Form.Text>
                                        </InputGroup>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Text >Party Name</Form.Text >
                                        <InputGroup>
                                            <InputGroup>
                                                <Form.Text >{bill?.vineoAddSaleBillCustomer?.vineoCustomerAccountName}</Form.Text >
                                                <>  {setValue("ultiVineoCustomerAccountUniqeId", bill?.vineoAddSaleBillCustomer?.ultiVineoCustomerAccountUniqeId)}</>
                                            </InputGroup>
                                        </InputGroup>
                                    </Col>




                                    <Col md={2}>
                                        <Form.Text>
                                            salesman
                                        </Form.Text>

                                        <InputGroup>

                                            <Form.Text >{bill?.vineoAddSaleBillCustomer?.vineoArea?.salesman?.nameofUser}</Form.Text >
                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Area
                                        </Form.Text>
                                        <InputGroup>

                                            <Form.Text >{bill?.vineoAddSaleBillCustomer?.vineoArea?.vineoAreaName}</Form.Text >
                                        </InputGroup></Col>
                                </Row>
                                <Row>
                                    {vineofirmdata?.[0]?.allowCreditNoteNumberedit ?
                                        <>
                                            <Col md={2}>
                                                <Form.Text>
                                                    CRN Prefix : {vineofirmdata?.[0]?.vineocreditNotePrefix}
                                                </Form.Text>
                                                <Form.Control className="form-control"  {...register("vineocreditNotePrefix")} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Text>
                                                    CRN Number : {vineofirmdata?.[0]?.vineocreditNoteNumberStartSetting}
                                                </Form.Text>
                                                <Form.Control type="number" className="form-control"  {...register("creditNoteNumber")} required />
                                            </Col> </>
                                        : <Col md={2}>

                                            <Form.Text>
                                                CRN With Prefix
                                            </Form.Text>
                                            <br />
                                            {vineofirmdata?.[0]?.vineocreditNotePrefix}{vineofirmdata?.[0]?.vineocreditNoteNumberStartSetting}
                                        </Col>
                                    }
                                    <Col md={8}>
                                        <Form.Text>
                                            Narration *
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("creditnotenarr")} required />
                                    </Col>

                                </Row>
                            </Card>
                        </div>
                    </Row>



                    <div>
                        <Card className='p-4'>

                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">S.N. </th>
                                        <th scope="col">Item Name </th>
                                        <th scope="col">Unit </th>
                                        <th scope="col">Unit price</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Damaged</th>
                                        <th scope="col">Sellable</th>
                                        <th scope="col">Rate</th>
                                        <th scope="col">Amt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bill?.vineoAddBillItemList?.map((item, i) => {
                                        return (
                                            <tr key={item.id} >

                                                <td>
                                                    <Form.Text>{i + 1}</Form.Text >
                                                    {setValue(`vineoCreditNoteitemsList[${i}].vineoAddBillItem.ultiVineoAddBillItemUniqeId`, item?.ultiVineoAddBillItemUniqeId)}
                                                    {setValue(`vineoCreditNoteitemsList[${i}].itemeffectiveratefrombill`, item?.vineoItemEffectiverateAfterDsicount)}

                                                </td>
                                                <td>
                                                    <Form.Text> {item.vineoProduct.vineoProductName}</Form.Text >
                                                </td>
                                                <td>
                                                    <Form.Text> {item.vineoAddBillItemUnit}</Form.Text >
                                                </td>
                                                <td>
                                                    {item.vineoProduct.vineoAddBillItemUnit === "BOX" ?
                                                        <Form.Text> {item.vineoBatch.vineoBatchProductSaleRate}</Form.Text > :
                                                        <Form.Text> {item.vineoBatch.vineoBatchProductSaleRateperPcs}</Form.Text >
                                                    }

                                                </td>
                                                <td>
                                                    <Form.Text> {item.vineoAddBillProductBatchQty}</Form.Text >
                                                </td>
                                                <td>
                                                    <Form.Control type="number" min="0" defaultValue="0" placeholder='Damaged Product'  {...register(`vineoCreditNoteitemsList[${i}].damagedproductreturnqtyinpcs`)} />


                                                </td>
                                                <td>
                                                    <Form.Control type="number" min="0" defaultValue="0" placeholder='Sellable Product' {...register(`vineoCreditNoteitemsList[${i}].sellableproductreturnqtyinpcs`)} />
                                                </td>
                                                <td>
                                                    <Form.Text>{item?.vineoItemEffectiverateAfterDsicount.toFixed(2)}</Form.Text >
                                                </td>
                                                <td>
                                                    <div style={{ display: 'none' }}>
                                                        {TotalCreditAmount = TotalCreditAmount + parseFloat(
                                                            parseFloat((item?.vineoItemEffectiverateAfterDsicount) *
                                                                ((1 + ((parseFloat(item?.vineoProduct?.vineoProductCentralGstPercentage) + parseFloat(item?.vineoProduct?.vineoProductStateGstPercentage)) / 100)))
                                                            ) *
                                                            (parseFloat(watch(`vineoCreditNoteitemsList[${i}].damagedproductreturnqtyinpcs`))
                                                                + parseFloat(watch(`vineoCreditNoteitemsList[${i}].sellableproductreturnqtyinpcs`))))
                                                        }
                                                    </div>

                                                    {parseFloat(
                                                        parseFloat((item?.vineoItemEffectiverateAfterDsicount)) *
                                                        ((1 + ((parseFloat(item?.vineoProduct?.vineoProductCentralGstPercentage) + parseFloat(item?.vineoProduct?.vineoProductStateGstPercentage)) / 100))) *
                                                        (parseFloat(watch(`vineoCreditNoteitemsList[${i}].damagedproductreturnqtyinpcs`))
                                                            + parseFloat(watch(`vineoCreditNoteitemsList[${i}].sellableproductreturnqtyinpcs`)))).toFixed(2)
                                                    }
                                                    {/* <Form.Control  type="number" min="0" placeholder='Total Item Amount' {...register(`vineoDebitNoteitemsList[${i}].totalitemamount`)}  /> */}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    <th scope="row" align="right"></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <Form.Text>Total Credit Adjust</Form.Text>
                                        <Form.Control type="number" min="0" defaultValue="0" placeholder='Total Adjust Amount' {...register(`adjustamount`)} required />
                                    </td>
                                    <td>
                                        <Form.Text>Total Credit AdjustExtra</Form.Text>
                                        <Form.Control type="number" min="0" defaultValue="0" placeholder='Total Adjust Amount' {...register(`adjustamountExtra`)} required />
                                    </td>
                                    <td>


                                        <Form.Text> Total Credit Amount= {parseFloat(parseFloat(TotalCreditAmount) - parseFloat(watch(`adjustamount`)) + parseFloat(watch(`adjustamountExtra`))).toFixed(2)}</Form.Text >
                                        {/* <Form.Control type="number" min="0" placeholder='Total Credit Amount'  {...register("creditnoteAmount")} required /> */}
                                    </td>


                                </tbody>
                            </table>

                        </Card>
                    </div>

                    <div>
                        <center>
                            <input className="btn btn-primary m-2" type="submit" value="Submit" />
                        </center>
                    </div>
                </form>
            </div>

            <div>

                <Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup className="m-2">
                                <Form.Text >
                                    Credit Note From Date
                                </Form.Text>
                                <DatePicker className="form-control"
                                    selected={fromdate}
                                    onChange={setFromdate}
                                    name="vineoFromDate"
                                    dateFormat="dd-MM-yyyy"
                                    ref={setValue("vineoFromDate", moment(fromdate).format(DATEFORMAT))}
                                />
                                {/* <Controller
                                name="vineoPurchaseFromDateref"
                                //
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <DatePicker className="form-control"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        dateFormat="dd-MM-yyyy"


                                    />

                                )}
                            />
                            {errors.vineoPurchaseFromDateref && <span>This field is required</span>} */}

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup className="m-2">
                                <Form.Text >
                                    Credit Note To Date
                                </Form.Text>

                                <DatePicker className="form-control"
                                    selected={todate}
                                    onChange={setTodate}
                                    name="vineoToDate"
                                    dateFormat="dd-MM-yyyy"
                                    ref={setValue("vineoToDate", moment(todate).format(DATEFORMAT))}
                                />

                            </FormGroup>
                        </Col>

                    </Row>
                    <Col>



                        <Button className="btn btn-warning m-2" disabled={isLoading} onClick={() => {
                            setLoading(!isLoading);
                            getDatafromServer({
                                startDate: moment(fromdate).format(DATEFORMAT),
                                endDate: moment(todate).format(DATEFORMAT)
                            })
                        }}> <FontAwesomeIcon icon={faRotate} /> {isLoading ? 'Loading…' : 'Load CreditNote'}</Button>

                        {/* <button className="btn btn-primary m-2" type="button" onClick={onSelectBillButtonClick}>
                    SelectBills
                </button> */}
                        <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">Export To CSV
                        </Button>
                        <Button className="btn btn-primary m-1" onClick={() => { geteinvoiceJson() }} value="E-INVjson">E-INVjson
                        </Button>
                        <Button className={gStApiloginSuccess ? "btn btn-success m-1" : "btn btn-secondary m-1"} onClick={() => { generateEwayBill() }} value="E-WAYBill">E-CR GEN.IRN
                        </Button>
                    </Col>
                </Row>
                <div className="ag-theme-alpine" style={{ height: '400px' }}>
                    <div className="ag-theme-alpine" style={{ height: '700px' }}>
                        <AgGridReact
                            id="AgGridforBillData"
                            rowSelection="multiple"
                            rowData={tableData}
                            columnDefs={columnDefs}
                            onRowSelected={onSelectBill}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                        />
                    </div>
                </div>
            </div>
            <div>
                <Modal show={openPartyWiseReport}
                    onHide={() => { SetOpenPartyWiseReport(false) }}
                    backdrop="static"
                    size="lg" 
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        {/* <AddBatch /> */}
                        <AllPartywiseSale />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { SetOpenPartyWiseReport(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={openprint}
                    onHide={() => setOpenprint(!openprint)}
                    backdrop="static"
                    fullscreen={true}
                    size="lg"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Credit Note Print</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card className='p-4'>
                            <CreditNoteprint data={dataforprint} />
                        </Card>

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>
                <Modal show={openEwayJsonModal}
                    onHide={() => { setOpenEwayJsonModal(!openEwayJsonModal) }}
                    backdrop="static"
                    fullscreen={true}
                    size="lg"
                    keyboard={false}>

                    <Modal.Header closeButton>
                        <Modal.Title>Copy JSON From Here </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Button variant="primary m-1" onClick={() => setShowtextOnly(!showtextOnly)}><FontAwesomeIcon icon={faFileText} /></Button>
                            <Button variant="primary m-1" onClick={() => exportToJson(jsonValueforewaybill)}><FontAwesomeIcon icon={faFileText} />Export TO .JSON</Button>
                        </div>
                        <Card className='p-4'>
                            {showtextOnly ?
                                <pre>{JSON.stringify(jsonValueforewaybill, null, 2)}</pre> :
                                <>{JSON.stringify(jsonValueforewaybill, null, 2)}</>}
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>

    )

}