import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';

// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_AUTOIMPORT_NAMEDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDATA } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { getAutoImportVineoFirmData, getVineoAccountCustomerMasterMenu, getVineoAreaData, getVineoCustomerBankMasterData, getVineoFirmData, getVineoGstData } from '../../../redux/features/services/serviceHelper';
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import DatePicker from "react-datepicker";

import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup
} from 'react-bootstrap';
import { useRef } from 'react';

export default function CustomerAccountInfowithAutoimport() {


    const areainputref = useRef()

    const dispatch = useDispatch()
    const isvineoaccountcustomerdataLoaded = useSelector((state) => state.vineoData.isvineoaccountcustomerdataLoaded)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const vineodata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const isvineoBankdataLoaded = useSelector((state) => state.vineoData.isvineocustomerbankMasterdataLoaded)
    const vineoareadata = useSelector((state) => state.vineoData.vineoareadata)
    const vineofirmdatafromredux = useSelector((state) => state.vineoData.vineofirmdata)
    const [vineofirmdata, setVineofirmdata] = useState();

    const vineofirmAutoimportdatafromredux = useSelector((state) => state.vineoData.AutoImportVineoFirmData)
    const [vineofirmAutoimportdata, setVineofirmAutoimportdata] = useState()
    const vineobankdata = useSelector((state) => state.vineoData.vineocustomerbankMasterdata)
    const vineogstdata = useSelector((state) => state.vineoData.vineogstdata)
    const [openingdate, setOpeningdate] = useState();
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoCustomerAccountUniqeId": "Working", "vineoCustomerAccountName": "Click Refresh To Load" }])
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [selectedArea, setSelectedArea] = useState();
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedFirm, setSelectedFirm] = useState([])
    const [firmName, setFirmName] = useState("");
    const formData = new FormData();

     useEffect(() => {
      dispatch(getVineoFirmData())
    }, []);

    useEffect(() => {
        console.log("Refreshing Area")
        setVineofirmdata(vineofirmdatafromredux);
        setVineofirmAutoimportdata(vineofirmAutoimportdatafromredux)
    }, [refreshcount]);


    const { register, handleSubmit, setValue, reset, watch, formState: { errors }, control } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        if (!data?.vineoFirmType) {
            notifyError("Select Firm Type");
        }
        else if (!selectedArea?.[0]?.ultiVineoAreaUniqeId) {
            notifyError("Select Area")
        }
        else {
            submitForm(data)
        }
    }
    const getdatafromserverforarea = () => {
        dispatch(getVineoAreaData())

    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoCustomerAccountName',
            text: 'Account Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'ultiVineoCustomerAccountUniqeId',
            text: 'Unique Id.',
            sort: true
        },

    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };


    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoCustomerAccountUniqeId", selectedRow.ultiVineoCustomerAccountUniqeId);
            setValue("vineoCustomerAccountName", selectedRow.vineoCustomerAccountName);
            setValue("vineoCustomerAccountaddress", selectedRow.vineoCustomerGstNumber);
            setValue("vineoCustomerGstNumber", selectedRow.vineoCustomerAccountaddress);
            setValue("vineoCustomerAccountopeningDate", selectedRow.vineoCustomerAccountopeningDate);
            setValue("vineoCustomerAccountTown", selectedRow.vineoCustomerAccountTown);
            setValue("vineoCustomerAccountphoneNumber", selectedRow.vineoCustomerAccountphoneNumber);
            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);


        }
        console.log("Update Firm ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Customer Account ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }
    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Customer Account  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoCustomerAccountName)
                }
                else { notifyError("Error While Deleting Account  Or Login as ADMIN") }
            },
            (err) => {
                notifyError("Error While Deleting Account Try Again  Or Login as ADMIN")

            }
        )
    }
    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_AUTOIMPORT_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Customer Account  Imported With Internal Id' + res.data?.errorMessage)
                    reset()
                }
                else {
                    notifyError('Error While Registering Customer Account  Import From Server' + res.data?.errorMessage)
                } //console.log(res.data)
            },
            (err) => {
                console.log(err)
                notifyError('Error While Registering Customer Account  Import')
            }
        )
    }

    const getFirmDataFromServer = () => {
        console.log(firmName)
        if (firmName.length < 3) {
            notifyWarning("Enter More Characters ")
        }
        else {
            formData.append('firmName', firmName)
            dispatch(getAutoImportVineoFirmData(formData));
        }
    }

    const getdatafromserver = () => {

        console.log("Called to get Customer Account Data ")

        dispatch(getVineoAccountCustomerMasterMenu())

        if (Object.keys(vineodata).length > 0) {
            isvineoaccountcustomerdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoCustomerAccountUniqeId": "Working", "vineoCustomerAccountName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }

    return (
        <div>

            <h3 className='justify-content-center m-1'>Customer Account AutoImport</h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoCustomerAccountUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>Account ID</Form.Text>
                                                <fieldset disabled>

                                                    <Form.Control className="form-control "
                                                        placeholder="Account ID: This Code Will Be Auto Generated"
                                                        {...register("ultiVineoCustomerAccountUniqeId")} />
                                                </fieldset>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>  <FormGroup >

                                            <Form.Text >
                                                Search Party With Name
                                            </Form.Text>
                                            <InputGroup>
                                                <Form.Control
                                                    className="form-control"
                                                    value={firmName}
                                                    onChange={(e) => { setFirmName(e.target.value); }}
                                                />
                                                <Button variant="primary"
                                                    onClick={getFirmDataFromServer
                                                    }>
                                                    <FontAwesomeIcon icon={faRotate} />
                                                </Button>
                                            </InputGroup>
                                        </FormGroup></Col>
                                        <Col> <FormGroup>
                                            <Form.Text >Select Party *  GST:- {(selectedFirm?.[0]?.vineofirmGst?.vineoGstNumber)}  -ClientID :{(selectedFirm?.[0]?.vineoRegisteredClientDetails?.vineoRegisteredClientName)}  </Form.Text >

                                            <Controller
                                                control={control}
                                                name={`vineofirmArray`}
                                                // rules={{
                                                //     required: "Please, select at least one value"
                                                // }}

                                                render={({ field, fieldState }) => (
                                                    <div >
                                                        <InputGroup>
                                                            <Typeahead
                                                                {...field}
                                                                id="FirmNameTypehead"
                                                                size='sm'
                                                                onChange={setSelectedFirm}
                                                                labelKey={"vineoFirmFirmName"}
                                                                selected={selectedFirm}
                                                                // multiple  // for future
                                                                options={vineofirmAutoimportdata ? vineofirmAutoimportdata : []}
                                                                className={fieldState.invalid ? "is-invalid" : ""}
                                                              
                                                            />

                                                            <p id="typeaheadError" className="invalid-feedback">
                                                                {fieldState.error?.message}
                                                            </p>

                                                        </InputGroup>

                                                    </div>
                                                )}
                                            />

                                        </FormGroup></Col>
                                    </Row>


                                    <FormGroup >
                                        <Form.Text >
                                            Registered Client Id of Distributor/Party
                                        </Form.Text>
                                        <Form.Control type="Number" className="form-control "   {...register("vineoRegisteredClientName")} required />
                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text >
                                            OTP of Party Firm
                                        </Form.Text>
                                        <Form.Control type="Number" className="form-control "   {...register("otpofFirmtobeRegistered")} required />
                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text >
                                            Unique Id of Firm of Party - Enter 0000 for DorfKetal
                                        </Form.Text>
                                        <Form.Control className="form-control "   {...register("uniqueofFirmtobeRegistered")} required />
                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Text >
                                            Firm Type
                                        </Form.Text>
                                        <Form.Select className="form-control" size='sm' {...register("vineoFirmType")} required>
                                            <option value="DIST">DISTRIBUTOR</option>
                                            <option value="RET">RETAILER</option>
                                            <option value="SUP">SUPERSTOCKIST</option>
                                        </Form.Select>

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text >

                                            Unique Id of Your Firm - {vineofirmdata?.[0]?.ultiVineoFirmUniqeId}
                                        </Form.Text>
                                        <Form.Control className="form-control " type="number" {...register("uniqueofUserFirm")} required />
                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text >
                                            Area of Party
                                        </Form.Text>
                                        <InputGroup>
                                            <Typeahead
                                                id="areaTypehead"
                                                onChange={setSelectedArea}
                                                options={vineoareadata ? vineoareadata : []}
                                                placeholder="Choose Area Name..."
                                                selected={selectedArea}
                                                labelKey={"vineoAreaName"}

                                            />

                                            <Button variant="info"
                                                onClick={getdatafromserverforarea} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>

                                            {setValue("areauniqueid", (selectedArea?.[0]?.ultiVineoAreaUniqeId)?.toString())}

                                        </InputGroup>

                                    </FormGroup>

                                    <input className="btn btn-primary m-2" type="submit" value="Submit" />
                                    <button type="button" onClick={() => reset()} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row>

            </div>
        </div>
    )

}