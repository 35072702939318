import React, { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup, FormText } from "react-bootstrap";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import VendorToProduct from '../../mapping/VendorToProduct';
import { useSelector, useDispatch } from 'react-redux'
import AddBatch from '../stockadjustment/AddBatch';
import { getVineoProductData, getVineoProductDatawithPositiveQtyinBatch } from '../../../../redux/features/services/serviceHelper';
import { DATEFORMAT } from '../../../../constants/Urlforvineo';
import moment from 'moment';
import { render } from '@testing-library/react';
import AddStock from '../stockadjustment/AddStock';
import AddPurchase from '../AddPurchase';

let renderCount = 0;
let nameoffreeproduct;
let discountamouteffectivepercentage;
let itemschemdiscountpercentageis = [];
let itemindividauldiscountpercentageis = [];

let dataforproductbatch = []

export const AddGONItem = (props) => {

  useEffect(() => {
    console.log("Rendering  =  " + renderCount)
    renderCount++;
    append({})
    addBillTotalValue = 0
    dispatch(getVineoProductData());
    dispatch(getVineoProductDatawithPositiveQtyinBatch())
  }, [])

  useEffect(() => {
    console.log("Rendering  =  " + renderCount)
    renderCount++;
    addBillTotalValue = 0

  }, [props.totalDiscountPercentage])


  let schemeisActive;


  let addBillTotalValue = 0
  const dispatch = useDispatch()
  let selectedBatch;
  let indexofproduct = 0;
  const [dataforbatch, setDataforbatch] = useState()
  const [productSelected, setProductSelected] = useState([{}])
  const [batchSelected, setBatchSelected] = useState()


  const { register, totalDiscountPercentage, setGrossBillValue, dataforaddsaleBill, setValue, control, getValues, watch } = props
  const vineoproductdata = useSelector((state) => state.vineoData.vineoproductdata)
  const isvineoproductdataLoaded = useSelector((state) => state.vineoData.isvineoproductdataLoaded)
  const vineoproductdatawithpositiveqty = useSelector((state) => state.vineoData.vineoproductddatawithpositiveqty)
  const isvineoproductddatawithpositiveqtyLoaded = useSelector((state) => state.vineoData.isvineoproductddatawithpositiveqtyLoaded)
  const [tableData, setTableData] = useState(vineoproductdatawithpositiveqty);
  const refreshcount = useSelector((state) => state.vineoData.refreshcount)
  const [openAddDiscount, SetOpenAddDiscount] = useState(true)

  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message)
  const notifyWarning = (message) => toast.warning(message)
  useEffect(() => {

    console.log("Refreshing on state Change ")
    setTableData(vineoproductdatawithpositiveqty)
    // Note this will load only Product where bacth qty is greater than 0 

  }, [isvineoproductddatawithpositiveqtyLoaded, refreshcount, isvineoproductdataLoaded]);

  // const { register, control, handleSubmit, reset, watch } = useForm({
  //     defaultValues: {
  //         vineoDataforAddBillItem: [{ firstName: ""}]
  //     }
  //   });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "vineoDataforAddBillItem"
    }
  );


  // const onSubmit = (data) => console.log("data", data);

  const [openMapVendortoProduct, setOpenMapVendortoProduct] = useState(false);
  const [openAddStock, SetOpenAddStock] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([])
  const [selectedProductBatch, setSelectedProductBatch] = useState([])
  // const [voucherTotalValue, setVoucherTotalValue] = useState(0)

  let addBillGstTotalValue = 0
  const valueofBatchtname = [{}]

  const handlerefresh = () => {
    // dispatch(getVineoProductData());
    dispatch(getVineoProductDatawithPositiveQtyinBatch())
  }

  // if you want to control your fields with watch
  // const watchResult = watch("test");
  // console.log(watchResult);

  // The following is useWatch example
  // console.log(useWatch({ name: "test", control }));


  const inputref = React.createRef([]);

  const addtoinputref = (e) => {
    if (e?.props?.value) {
      dataforproductbatch.push(e?.props?.value)
      console.log(dataforproductbatch)
      console.log(e?.props?.value)

    }
  }

  return (
    <div>
      <div>
        <div>
          <Card className='p-1'>
            <table className="table" >
              <thead className="thead-dark" cellSpacing="0" cellPadding="0">
                <tr>
                  <th scope="col" width="1%"></th>
                  <th scope="col" width="18%">Item Name </th>
                  <th scope="col" width="23%">Batch</th>
                  <th scope="col" width="5%">Unit</th>
                  <th scope="col" width="2%">Disc.%</th>
                  <th scope="col" width="5%">Qty</th>
                  {/* <th scope="col" width="2%">Dis.</th> */}
                  <th scope="col" width="4%">Rate</th>
                  <th scope="col" width="4%">Basic</th>
                  <th scope="col" width="5%">GST(C+S)</th>
                  <th scope="col" width="4%">Basic</th>
                  <th scope="col" width="4%">Net</th>
                  <th scope="col" width="1%"> </th>
                  {/* Use Tab or shift tab */}
                </tr>
              </thead>
              <tbody >
                {fields.map((item, index) => {
                  return (

                    <tr key={item.id} >
                      <td >{index + 1}</td>

                      <td >
                        {console.log(vineoproductdatawithpositiveqty + "positive qty ")}

                        <Controller
                          control={control}
                          name={`vineoDataforAddBillItem[${index}].vineoProduct`}
                          rules={{
                            required: "Please, select at least one value"
                          }}
                          render={({ field, fieldState }) => (
                            <div >
                              <InputGroup >
                                <Typeahead

                                  {...field}
                                  id="selectedproducttypeahead"
                                  disabled={tableData ? false : true}
                                  className={fieldState.invalid ? "is-invalid" : ""}
                                  labelKey={"vineoProductName"}
                                  options={tableData ? tableData : []}
                                  renderMenuItemChildren={(option) => (
                                    <div>
                                      <div>
                                        <small>{option.vineoProductName}</small>
                                        <small>-/{option.vineoProductCode}</small>
                                      </div>
                                    </div>
                                  )}
                                  ref={() => {
                                    // const values = getValues();
                                    setProductSelected(...selectedProduct, watch(`vineoDataforAddBillItem[${index}].vineoProduct`))

                                    // dataforproductbatch.push(singleValue)
                                    // console.log("The Product Bathch is  =" + index + "  index is " + JSON.stringify(singleValue))
                                    // console.log("Full Value is ="+index+"  index is "+JSON.stringify(values) )
                                    //  console.log("Vlaue fof Product is "+ productSelected)
                                  }}
                                />
                                <Button variant="primary"
                                  onClick={() => {
                                    handlerefresh()
                                  }} >
                                  <FontAwesomeIcon icon={faRotate} />
                                </Button>
                                <p id="typeaheadproductError" className="invalid-feedback">
                                  {fieldState.error?.message}
                                </p>
                              </InputGroup>
                              <>HSN: {watch(`vineoDataforAddBillItem[${index}].vineoProduct[${indexofproduct}].vineoProducthsnCod`)}</>
                            </div>
                          )}
                        />

                      </td>

                      <td>
                        <Controller
                          control={control}
                          name={`vineoDataforAddBillItem[${index}].vineoBatchSet`}
                          rules={{
                            required: "Please, select at least one value"
                          }}
                          render={({ field, fieldState }) => (
                            <div >
                              <InputGroup>
                                <Typeahead
                                  {...field}
                                  id="VendorBatchTypehead"
                                  className={fieldState.invalid ? "is-invalid" : ""}
                                  disabled={watch(`vineoDataforAddBillItem[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`)?.length > 0 ? false : true}
                                  labelKey={"vineoBatchName"}
                                  renderMenuItemChildren={(option) => (
                                    <div>
                                      <div>
                                        <small>Name:{option.vineoBatchName}</small>
                                        <small>-Qty:{option.vineoBatchProductQtyinPCS}</small>
                                      </div>
                                    </div>
                                  )}
                                  options={watch(`vineoDataforAddBillItem[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`) ? watch(`vineoDataforAddBillItem[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`)?.filter((item) => {
                                    let tempvalue = (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`))
                                    if (tempvalue) {
                                      valueofBatchtname.push(tempvalue[0]);
                                    }
                                    //  console.log( "Item expired "+item.vineoBatchExpDate >  moment(new Date()).format(DATEFORMAT))
                                    console.log(moment(item.vineoBatchExpDate) + "Item Expiry Date is after " + moment(item.vineoBatchExpDate).isBefore(new Date()))
                                    //  console.log( new Date())
                                    //  console.log( item.vineoBatchExpDate)
                                    // console.log(valueofBatchtname ? valueofBatchtname[0]?.vineoBatchName : <></>)
                                    //  console.log(item.vineoProductName )
                                    // change if condition here durign prod 
                                    if (!valueofBatchtname.includes(item) && item.vineoBatchProductQtyinPCS >= 1) { return item; }

                                  }) : []}

                                  ref={() => {
                                    // const values = getValues(); 
                                    setBatchSelected(watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`))
                                    // setValue(`vineoDataforAddBillItem[${index}].vineoAddstockVoucherDate`, dataforaddsaleBill[0].vineoAddstockVoucherDate)
                                    // setValue(`vineoDataforAddBillItem[${index}].vineoAddstockVoucherNarr`, dataforaddsaleBill[0].vineoAddstockVoucherNarr)
                                    // setValue(`vineoDataforAddBillItem[${index}].vineoAddstockVoucherNumber`, dataforaddsaleBill[0].vineoAddstockVoucherNumber)
                                    // setValue(`vineoDataforAddBillItem[${index}].vineoVendorAccount`, dataforaddsaleBill[0].vineoVendorAccount)
                                    // setValue(`vineoDataforAddBillItem[${index}].vineoAddstockVoucherTotal`, addBillTotalValue)

                                    // dataforproductbatch.push(singleValue)
                                    // console.log("The Product Bathch is  =" + index + "  index is " + JSON.stringify(singleValue))
                                    // console.log("Full Value is ="+index+"  index is "+JSON.stringify(values) )
                                    //  console.log("Vlaue fof Product is "+ batchSelected)
                                  }}

                                />
                                <Button variant="info" onClick={() => { SetOpenAddStock(true) }}>
                                  +
                                </Button>

                                <p id="typeaheadBatchError" className="invalid-feedback">
                                  {fieldState.error?.message}
                                </p>
                              </InputGroup>
                              B.N.-{(watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchNumber)}<br />
                              S.D -{((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeDiscountPercentageBudget) <
                                (
                                  parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate * (1 - totalDiscountPercentage * 0.01)) *
                                  parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))
                                )
                              ) && (parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate * (1 - totalDiscountPercentage * 0.01)) <
                                watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeDiscountPercentageBudget) ?
                                (itemschemdiscountpercentageis[index] = watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeDiscountPercentage)
                                : (itemschemdiscountpercentageis[index] = 0)
                              }%

                            </div>

                          )}
                        />
                      </td>
                      <td>
                        <Form.Select className="form-control custom-form-field" size="sm" {...register(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`)}>
                          <option value="PCS">PCS</option>
                          <option value="BOX">BOX</option>



                        </Form.Select>
                        {watch(`vineoDataforAddBillItem[${index}].vineoProduct`) ? (watch(`vineoDataforAddBillItem[${index}].vineoProduct`)[0]?.vineoProductinBoxPacking) : <>NA</>} <>in Box</>
                      </td>

                      <td>
                        <div>
                          {/* calcualting individual discount Percentage */}
                          {openAddDiscount ?
                            <Form.Control placeholder='% Discount' min="0" className="form-control custom-form-field"  {...register(`vineoDataforAddBillItem[${index}].vineoDataforAddBillItemIndiDiscountPercent`, {
                              pattern: /^[0-9\b]+$/, max: 100
                            })} />
                            : <></>
                          }
                          {/* {console.log("Individual Discount Percentage = " + watch(`vineoDataforAddBillItem[${index}].vineoDataforAddBillItemIndiDiscountPercent`))} */}

                          <div style={{ display: 'none' }}>
                            {
                              watch(`vineoDataforAddBillItem[${index}].vineoDataforAddBillItemIndiDiscountPercent`) ?
                                (itemindividauldiscountpercentageis[index] = parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoDataforAddBillItemIndiDiscountPercent`)))
                                : (itemindividauldiscountpercentageis[index] = 0)
                            }

                          </div>
                          {/* //doing scheme calcualtion */}
                          free:-  {((schemeisActive = watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.schemeisActive)) ?
                            (

                              ((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeQtyLimitExceed > watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`) ||
                                ((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeAmountLimitExceed <
                                  (
                                    parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate * (1 - totalDiscountPercentage * 0.01)) *
                                    parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))
                                  ))))) && (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeQtyLimitExceed >= parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)
                                    / (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeQtyLimitExceed))) ?
                                (
                                  parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)
                                    / (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeQtyLimitExceed))
                                  // vineoSchemeFreeQty Itll will be reduced from DB 
                                ) :
                                // doing for amount type 
                                <>0</>
                            )
                            : <>0</>
                          }


                        </div>
                        {console.log((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeQtyLimitExceed > parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)
                          / (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeQtyLimitExceed))))}
                        {console.log((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeQtyLimitExceed))}
                        <div>
                          {(parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)
                            / (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.vineoSchemeFreeQtyLimitExceed)) > `0`) ?
                            (nameoffreeproduct = (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoScheme?.[0]?.nameofFreeProduct)) : <></>}
                        </div>
                      </td>
                      <td>
                        <Form.Control  min="0" onKeyDown={(e) => {
                          if (e.key === "Tab") {
                            if (e.shiftKey) {
                              remove(index)
                            } else {
                              append({});
                            }


                          }
                        }} style={watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) === "PCS" ? (watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`) > watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductQtyinPCS) ?
                          { background: `red` } : {} :
                          (watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`) > watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductQty) ?
                            { background: `red` } : {}} className="form-control custom-form-field "  {...register(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`, {
                              pattern: /^[0-9\b]+$/, min: 1
                            })} required />


                      </td>
                      {/* <td>Discount Individual</td> */}
                      <td>
                        {/* Selling Rate  */}

                        {watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) ?
                          ((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX") ?
                            (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)[0]?.vineoBatchProductSaleRate) :
                            (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)[0]?.vineoBatchProductSaleRateperPcs)) :
                          <>NA</>}
                      </td>

                      <td>
                        {/* Calcualte Discounted rate  */}
                        {/* <Form.Control className="form-control" Value=  /> */}
                        {/* please note Discoun tis on basic value not on gst value  */}


                        {watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) ?

                          (watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX") ?
                            parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)[0]?.vineoBatchProductSaleRateperboxwithoutgst) *
                              (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]] + itemindividauldiscountpercentageis[[index]]) * 0.01)
                            ).toFixed(2)
                            :
                            parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)[0]?.vineoBatchProductSaleRateperPcswithoutgst) *
                              (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]+ itemindividauldiscountpercentageis[[index]]) * 0.01)).toFixed(2)

                          :
                          <>NA</>
                        } ,<br />

                        {watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) ?

                          (watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX") ?
                            parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)[0]?.vineoBatchProductSaleRate) *
                              ((totalDiscountPercentage + itemschemdiscountpercentageis[[index]] + itemindividauldiscountpercentageis[[index]]) * 0.01)).toFixed(2)
                            :
                            parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)[0]?.vineoBatchProductSaleRateperPcs) *
                              ((totalDiscountPercentage + itemschemdiscountpercentageis[[index]] + itemindividauldiscountpercentageis[[index]]) * 0.01)).toFixed(2)
                          :
                          <>NA</>
                        }
                        @{totalDiscountPercentage + itemschemdiscountpercentageis[index] + itemindividauldiscountpercentageis[[index]]}%




                      </td>
                      <td>
                        {console.log(watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0])}
                        {watch(`vineoDataforAddBillItem[${index}].vineoProduct`) ?
                          (watch(`vineoDataforAddBillItem[${index}].vineoProduct`)[0]?.vineoProductCentralGstPercentage) :
                          <>NA</>}
                        %={
                            (
                              (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) &&
                                watch(`vineoDataforAddBillItem[${index}].vineoProduct`) &&
                                !isNaN(parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)))) ?

                                parseFloat(
                                  ((parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
                                    watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
                                    watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs))
                                    *
                                    (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01))
                                    / ((
                                      parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) +
                                      parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
                                    ) * 0.01 + 1)) *
                                  parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))
                                  *
                                  ((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) * 0.01)
                                ).toFixed(2)
                                :
                                <>0</>)}<br />
                        {watch(`vineoDataforAddBillItem[${index}].vineoProduct`) ? (watch(`vineoDataforAddBillItem[${index}].vineoProduct`)[0]?.vineoProductStateGstPercentage) : <>NA</>}
                        %={
                            (
                              (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) &&
                                watch(`vineoDataforAddBillItem[${index}].vineoProduct`) &&
                                !isNaN(parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)))) ?

                                parseFloat(
                                  ((parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
                                    watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
                                    watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs))
                                    *
                                    (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01))
                                    / ((
                                      parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) +
                                      parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
                                    ) * 0.01 + 1)) *
                                  parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))
                                  *
                                  ((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage) * 0.01)
                                ).toFixed(2)
                                :
                                <>0</>)}
                      </td>
                      {/* This GST CAlculatiaon I have added in % = Value */}
                     
                      <td>
                        {/* Basic Amount without GST */}
                        {
                          (
                            (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) &&
                              watch(`vineoDataforAddBillItem[${index}].vineoProduct`) &&
                              !isNaN(parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)))) ?

                              parseFloat(
                                ((parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
                                  watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
                                  watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs))
                                  *
                                  (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01))
                                  / ((
                                    parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) +
                                    parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
                                  ) * 0.01 + 1)) *
                                parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))

                              ).toFixed(2)
                              :
                              <>0</>)}
                      </td>
                      <td >
                        <FormGroup  >
                          {
                            (
                              (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) && watch(`vineoDataforAddBillItem[${index}].vineoProduct`) && (parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))))
                                ?
                                parseFloat(
                                  parseFloat(((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
                                    watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
                                    watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs))
                                    * (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01)) *
                                  parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))
                                ).toFixed(2) : (0)
                            )
                          }
                        </FormGroup>

                        <div style={{ display: 'none' }}>

                          {
                            addBillTotalValue = parseFloat(
                              parseFloat(addBillTotalValue) + parseFloat(

                                (
                                  (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) && watch(`vineoDataforAddBillItem[${index}].vineoProduct`) && (parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))))
                                    ?
                                    parseFloat(
                                      parseFloat(((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
                                        watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
                                        watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs)) *
                                        (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01)) *
                                      parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))
                                    ).toFixed(2) : (0)
                                )
                              )
                            ).toFixed(2)
                          }
                          <Form.Control className="form-control" value={watch(`addBillTotalValue`)} {...register(`vineoAddBillGrossAmount`)} />

                          {
                            addBillGstTotalValue = parseFloat(
                              parseFloat(addBillGstTotalValue) + parseFloat(

                                (
                                  (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) && watch(`vineoDataforAddBillItem[${index}].vineoProduct`) && (parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))))
                                    ?
                                    parseFloat(
                                      (
                                        (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) &&
                                          watch(`vineoDataforAddBillItem[${index}].vineoProduct`) &&
                                          !isNaN(parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)))) ?

                                          (parseFloat(parseFloat(((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
                                            watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
                                            watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs)) *
                                            (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[index]) * 0.01))
                                            * parseFloat(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)))
                                            / (((parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
                                              + parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage))
                                            ) * (0.01)) + 1)) : (0)
                                      )

                                      *
                                      (((parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)[0]?.vineoProductCentralGstPercentage))
                                        +
                                        parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)[0]?.vineoProductStateGstPercentage))
                                      ) * 0.01))
                                    ).toFixed(2) : (0)
                                )
                              )
                            ).toFixed(2)
                          }
                        </div>

                      </td>

                      <td>
                        <div>
                          <InputGroup>
                            {/* <Button type="button" size="sm" onClick={() => {
                              append({});
                            }} variant="primary">
                              +
                            </Button> */}
                            <Button type="button" size="sm" onClick={() => { remove(index) }} variant="danger">
                              x
                            </Button>

                          </InputGroup>

                        </div>
                      </td>

                    </tr>

                  );
                })}



              </tbody>
            </table>


            <section>
              <Row>


                <Col md={6}>
                  <Button
                    type="button" variant="info"
                    onClick={() => {
                      append({});
                    }}
                  >
                    Add
                  </Button>


                  <Button
                    type="button" variant="danger" className='m-1'
                    onClick={() => {
                      notify("Press Shift + TAB ");
                    }}
                  >
                    REMV
                  </Button>

                </Col>
                {/* <Col md={1}>
                  Amount
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Form.Control disabled={true} className="form-control" value={addBillGstTotalValue} />
                  </FormGroup>
                </Col> */}
                <Col md={1}>
                  GST Total
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Form.Control disabled={true} className="form-control" value={addBillGstTotalValue} />
                  </FormGroup>
                </Col>
                <Col md={1}>
                  Bill Total
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Form.Control disabled={true} className="form-control" value={addBillTotalValue} />
                  </FormGroup>
                  {setGrossBillValue(addBillTotalValue)}
                  {console.log("Value from totalDiscountPercentage from child comp  " + totalDiscountPercentage)}
                </Col>
              </Row>

            </section>

          </Card>


        </div>
      </div>
      <div>
        <Modal show={openMapVendortoProduct}
          onHide={() => { setOpenMapVendortoProduct(false) }}
          backdrop="static"
          keyboard={false}
          fullscreen={true}>
          <Modal.Header closeButton />
          {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}

          <Modal.Body>
            <VendorToProduct />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary m-2" onClick={() => { setOpenMapVendortoProduct(false) }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal show={openAddStock}
          onHide={() => { SetOpenAddStock(false) }}
          backdrop="static"
          keyboard={false}
          fullscreen={true}>
          <Modal.Header closeButton />
          {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
          <Modal.Body>
            {/* <AddBatch /> */}
            {/* <AddStock /> */}
            <AddPurchase />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary m-2" onClick={() => { SetOpenAddStock(false) }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div >



  )
}


// <td >
// <FormGroup>
//   {/* GST CAlculatiaon  */}
//   {/* Basic value =   parseFloat(
//           ((parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
//                 watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
//                 watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs) )
//                 *
//                 (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01))
//                 /((
//                   parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) +
//                    parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
//                 )*0.01+1)) */}

//   {
//     (
//       (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) &&
//         watch(`vineoDataforAddBillItem[${index}].vineoProduct`) &&
//         !isNaN(parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)))) ?

//         parseFloat(
//           ((parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
//             watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
//             watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs))
//             *
//             (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01))
//             / ((
//               parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) +
//               parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
//             ) * 0.01 + 1)) *
//           parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))
//           *
//           ((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) * 0.01)
//         ).toFixed(2)
//         :
//         <>0</>)}
//   +<br />

//   {
//     (
//       (watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`) &&
//         watch(`vineoDataforAddBillItem[${index}].vineoProduct`) &&
//         !isNaN(parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`)))) ?

//         parseFloat(
//           ((parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoAddBillItemUnit`) == "BOX" ?
//             watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate :
//             watch(`vineoDataforAddBillItem[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRateperPcs))
//             *
//             (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01))
//             / ((
//               parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) +
//               parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
//             ) * 0.01 + 1)) *
//           parseInt(watch(`vineoDataforAddBillItem[${index}].vineoAddBillProductBatchQty`))
//           *
//           ((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage) * 0.01)
//         ).toFixed(2)
//         :
//         <>0</>)}


// </FormGroup>
// {/* {parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))} */}
// {/* {parseFloat((watch(`vineoDataforAddBillItem[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage))} */}
// {/* GST amountTotal */}
// </td>