import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit,faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddBillData, getVineoAddBillDatawithdate, getVineoShopVisitdatawithdate, getVineoShopVisitdatawithdateforMotherclientid } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { getVineoAccountCustomerMasterMenu } from '../../../redux/features/services/serviceHelper';
import Billprint from '../entries/billing/Billprint';
import ShopVisitprint from '../printFormat/ShopVisitprint';
import ShopVisitMap from './ShopVisitMap';
import AuthUser from '../../../constants/AuthUser';

export default function ShopVisitiReport() {
	const { setValue } = useForm();
	useEffect(() => {
		console.log("Use Effect called ");
		dispatch(getVineoShopVisitdatawithdate());
	}, []);

	const { token, logout, getUser, getRole } = AuthUser();
	let currentDate = new Date();
	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const vineoShopVisitdata = useSelector((state) => state.vineoData.shopvisitdatawithdate)
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
	const [toDate, setToDate] = useState(new Date());
	const [openprint, setOpenprint] = React.useState(false);
	const [dataforprint, setDataforprint] = useState();
	const [tableData, setTableData] = useState();
	const [showmap, setShowmap] = useState(false);
	const [selectedSalesman, setSelectedSalesman] = useState([])
	const [selectedParty, setSelectedParty] = useState([])

	const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
	const [isLoading, setLoading] = useState(false);
	const [selectedFirm, setSelectedFirm] = useState([])
	const filteredTableData = (
		selectedParty?.[0]?.label &&
		selectedSalesman?.[0]?.label &&
		selectedFirm?.[0]?.label
	  )
		? tableData
		  ?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
		  ?.filter(item => item?.partyName === selectedParty?.[0]?.label)
		  ?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname === selectedFirm?.[0]?.label)
		: (
		  selectedParty?.[0]?.label
			? (selectedSalesman?.[0]?.label
			  ? tableData?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
			  : tableData
			)?.filter(item => item?.partyName === selectedParty?.[0]?.label)
			: (selectedSalesman?.[0]?.label
			  ? tableData?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
			  : (selectedFirm?.[0]?.label
				? tableData?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname === selectedFirm?.[0]?.label)
				: tableData
			  )
			)
		);
	  

	useEffect(() => {
		
        console.log("Refreshing on state Change "+refreshcount)
        setLoading(false)
		setTableData(vineoShopVisitdata)
    }, [refreshcount]);
	
	
	// Ag grid Work Start 
	const [gridApi, setGridApi] = useState(null)

	const onExportClick = () => {
		gridApi.api.exportDataAsCsv();
	}

	const onGridReady = (params) => {
		setGridApi(params);
	}

	const defaultColDef = {
		sortable: true,
		editable: true,
		flex: 1,
		filter: true,
		floatingFilter: true,
        resizable: true,
        minWidth: 100,
	}

	const onPaginationChange = (pageSize) => {
		gridApi.api.paginationSetPageSize(Number(pageSize))
	}

	function onCellValueChanged(event) {
		console.log(
			'onCellValueChanged: '
		);
	}

	const columnDefs = [
		{ headerName: "id.", field: "shopvisituniqueid" ,resizable: true  },
		{ headerName: "SalesmanName", field: 'nameofUser' ,resizable: true  },
		{ headerName: "SaleID", field: "userName" ,resizable: true  },
		{ headerName: "Date ", field: "visitDate" ,resizable: true  },
		{ headerName: "Order", field: "visitOrder" ,resizable: true  },
		{ headerName: "Remark", field: 'visitRemark' ,resizable: true  },
		{ headerName: "TS", field: 'shopVisitpunchTime' ,resizable: true  },
		{ headerName: "Lat", field: 'lati' ,resizable: true  },
		{ headerName: "Long", field: 'longi' ,resizable: true  },
		{ headerName: "PartyName", field: 'partyName' ,resizable: true  },
		
		{ headerName: "VisitAdd", field: 'address' ,resizable: true  },
		{ headerName: "orderRecv", field: 'orderRecv' ,resizable: true  },
		{ headerName: "TargetAmt", field: 'dailyOrderTarget' ,resizable: true  },
		{ headerName: "dailyShopVisitTarget", field: 'dailyShopVisitTarget' ,resizable: true  },
	
        { headerName: "ClientID.", valueGetter: 'data.vineoRegisteredClientDetails.vineoRegisteredClientName' ,resizable: true  },

		
		{
			headerName: "Actions", field: "id", cellRendererFramework: (params) => (
				<div>
                <Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>
					<Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
					<Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>

				</div>
			)
		}
	]

	const handleUpdate = (data) => {
		console.log(data)
	}

	const handlePrint = (data) => {
        setDataforprint(data);
        setOpenprint(!openprint)
    }
	const handleDelete = (data) => {
		const confirm = window.confirm("Are you sure, you want to delete this row", data)

		if (confirm) {
			http.post(BASEURL_JWTAUTH + "Add delete URL once deltign is approved ", data).then(

				(res) => {
					console.log(res)
					if (res.data.errorCode == "0000") {
						notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

					}
					else
					notifyError("Error While Deleting Bill  " + + res.data.errorMessage)
				},
				(err) => {
					notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

				}
			)
		}
	}

	const getdatafromserverwithdate = (reqdata) => {
		const rolename = getRole("");
		console.log("Role is "+ rolename)
		
		
		if (reqdata.startDate && reqdata.endDate) {
		
			rolename==='mothercompanyadmin'?
			dispatch(getVineoShopVisitdatawithdateforMotherclientid(reqdata)):
			dispatch(getVineoShopVisitdatawithdate(reqdata))
			

		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}

	// needed below useEffect to solve issue which needed Load Bills to be clicked twice for initial page load only
	// re-write this logic to fetch existing data from redux for specified dates on initial load only
	// or it will keep fetching the data irrespective of the date selected on initial load only ( current behavior )
	// Solution: on navigation resetting the isvineoaddbilldatawithdateLoaded state variable in redux might solve the issue
	

	return (
		<div className="row justify-content-left ">
			  <div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">Salesman WIse Shop Visit Report</h4>
				</center>
			</div>

			<Row>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
						<div style={{ display: 'flex', alignItems: 'center' }}>
							Visit From Date
                        
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 1)); console.log("Date selected is ", fromdate)
                        }} > -1Day</div>
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 10))
                        }} > -10Day</div>
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 30))
                        }} > -30</div>
                        </div>
							
						</Form.Text>

						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setFromdate}
							name="vineoCreateLoadBillFromDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							 To Date
						</Form.Text>

						<DatePicker className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillToDate", moment(toDate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup  className="m-2">
					<Form.Text >Salesman Name  Name:-</Form.Text >
					

					<InputGroup>
						<Typeahead
							disabled={ false  }
							id="label"
							onChange={setSelectedSalesman}
							options={Array.from(
								new Set(
								  tableData
									?.filter((item) => item?.nameofUser !== null)
									?.map((item) => item?.nameofUser)
								)
							  ).map((nameofUser) => ({ label: nameofUser }))}
							// options={[]}
							 
							placeholder="Choose Salesman Name..."
							selected={selectedSalesman}
							labelKey={"label"}
						/>
							<Button className="btn btn-primary"  onClick={() => {selectedSalesman?.[0]?.label? setShowmap(true) : notifyWarning("Select Salesman ")}}>
						<FontAwesomeIcon icon={faRotate} />ShoWMap
						
					
					{console.log("Salesmannameis", selectedSalesman?.[0]?.label)}
					</Button>
					
						{console.log("Options are ", tableData
								?.filter((item) => item?.nameofUser !== null)
								?.map((item) => ({ label: item?.nameofUser })))}
						{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
					</InputGroup>
					</FormGroup>
					
				</Col>
				<Col md={3}>
					<FormGroup  className="m-2">
					<Form.Text >Party Name:-</Form.Text >
					

					<InputGroup>
						<Typeahead
							disabled={ false  }
							id="label"
							onChange={setSelectedParty}
							options={Array.from(
								new Set(
								  tableData
									?.filter((item) => item?.partyName !== null)
									?.map((item) => item?.partyName)
								)
							  ).map((partyName) => ({ label: partyName }))}
							// options={[]}
							 
							placeholder="Choose Party Name..."
							selected={selectedParty}
							labelKey={"label"}
						/>
							{console.log("Value of selected party is "+ selectedParty?.[0]?.label)}
						
					
					
						
						{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
					</InputGroup>
					</FormGroup>
					
				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Firm Name:-</Form.Text >
						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedFirm}
								options={Array.from(
									 new Set(
										tableData
										  ?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname !== null)
										  ?.map(item => item.vineoRegisteredClientDetails.clientfirmname)
									  )
								).map((FirmName) => ({ label: FirmName }))}
								// options={[]}

								placeholder="Choose Firm Name..."
								selected={selectedFirm}
								labelKey={"label"}
							/>

							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
			</Row>

			<Row>
				<Col>
					<Button className="btn btn-primary m-2" disabled = {isLoading} onClick={() => {setLoading(true);getdatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) })}}>
						<FontAwesomeIcon icon={faRotate} />
						&nbsp;Load Data
					</Button>
					<Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
						Export To CSV
					</Button>
				</Col>
			</Row>

			<div className="ag-theme-alpine"  style={{ height: '500px', overflowX: 'auto' }}>
				<div className="ag-theme-alpine" style={{ height: '570px' }}>
					<AgGridReact
						id="AgGridforData"
						rowData={ selectedParty?.[0]?.label? 
							(selectedSalesman?.[0]?.label? 
							tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
							:tableData)?.filter((item) => item?.partyName === selectedParty?.[0]?.label) :  
							 (selectedSalesman?.[0]?.label? 
							tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
							:tableData)}

						
						// .filter((item) => item?.nameofUser === null)
						// rowData={ selectedSalesman? tableData?.filter((item)=>{if((item?.nameofUser===selectedSalesman)) {return item}}) :
						// tableData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
						alwaysShowHorizontalScroll={true}

					/>
					
					{/* <AgGridColumn
						headerName="BQty"
						valueGetter={(params) => {
							return params.data.vineoBatchProductQty;
						}}
						valueSetter={(params) => {
							let newValInt = parseInt(params.newValue);
							let valueChanged = params.data.vineoBatchProductQty !== newValInt;

							if (valueChanged) {
								params.data.vineoBatchProductQty = newValInt;
							}

							return valueChanged;
						}}
					/> */}
				</div>
			</div>

			<Modal show={openprint}
                onHide={() => setOpenprint(!openprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Shop Visit Memo </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <ShopVisitprint data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

			<Modal show={showmap}
                onHide={() => setShowmap(!showmap)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Map of Visit </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <ShopVisitMap dataArray={selectedSalesman?.[0]?.label? 
							tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
							:tableData} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

		</div>
	)
}