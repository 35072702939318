import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm, useWatch } from "react-hook-form";
import { faClose, faPrint, faRotate, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from "moment";
import {
    FormGroup, Label, Input, Modal,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Table
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_UPDATE_SELECTED_CREDITNOTE, VINEOFORM_UPDATE_SELECTED_DEBITNOTE, VINEO_DELETE_CREDITNOTE_AVAILABLE, VINEO_DELETE_DEBITNOTE_AVAILABLE, VINEO_GETBILLWITHID_ADDBILL_AVAILABLE, VINEO_GETBILLWITHID_ADDPURCHASEBILL_AVAILABLE } from '../../../constants/Urlforvineo';
import { getVineoAccountCustomerMasterMenu, getVineoFirmData, getVineoGodownData, getVineoCompanyData, getVineoSchemaMasterData, getVineoProductData, getVineoCreditNotePositiveData, getVineoDebitNoteDataWithDate } from '../../../redux/features/services/serviceHelper';

import AreaToSalesman from '../mapping/AreaToSalesman';

import { useSelector, useDispatch } from 'react-redux'
import http from '../../../constants/AxiosConfig';
import Purchase from './Purchases';
import DebitNoteprint from './DebitNoteprint';

let totalDiscountPercentagevalue = 0;

export default function AddDebitNote() {
    useEffect(() => {
        // dispatch(getVineoCompanyData());
        // dispatch(getVineoGodownData());
        // dispatch(getVineoAccountCustomerMasterMenu());
        // dispatch(getVineoFirmData())
        // dispatch(getVineoProductData());
    }, []);

    const dispatch = useDispatch()

    const [invoice, setInvoice] = useState({})
    let Totaldebitamount = 0;
    const [debitNotedate, setDebitNote] = useState(new Date())
    const [openPurchaseReport, SetOpenPurchaseReport] = useState(false)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount);
    const [isLoading, setLoading] = useState(false);
    const vineodebittnotedata = useSelector((state) => state.vineoData.vineoDebitnotedatawithDate);
    const isvineoDebitnotedatawithDateLoaded = useSelector((state) => state.vineoData.isvineoDebitnotedatawithDateLoaded);
    const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();
    const [inovoiceNumber, setInvoiceNumber] = useState()
    const [tableData, setTableData] = useState();
    const [openprint, setOpenprint] = React.useState(false);
    const [dataforprint, setDataforprint] = useState();
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
    const [todate, setTodate] = useState(new Date());
    const onSubmit = (data) => {
        // console.log(JSON.stringify(data));
        console.log((data));
        submitForm(data)
    }

    useEffect(() => {
        setTableData(vineodebittnotedata)
        setLoading(false)
    }, [refreshcount]);


    const getDatafromServer = (reqdata) => {
        if (reqdata.startDate && reqdata.endDate) {
            // console.log(reqdata.startDate)
            // console.log(reqdata.endDate)
            // console.log(reqdata)
            var endDate = moment(reqdata.endDate, 'DD-MM-YYYY');
            var startDate = moment(reqdata.startDate, 'DD-MM-YYYY');
            if (endDate.diff(startDate, 'days') < 380) {
                setLoading(true)
                dispatch(getVineoDebitNoteDataWithDate(reqdata));
                isvineoDebitnotedatawithDateLoaded ? setTableData(vineodebittnotedata) : console.log("Empty data from Server for Debit Note")

            } else {
                setLoading(false)
                notify("Number Of Days Range for Debit Note Should Be Less than 1 Year ")
            }

        }
        else {
            setLoading(false)
            notify("Please Enter Date for purchase Start and End ")
        }


    }


    const navigate = useNavigate();
    const [issuedDebitNotewithoutReceipt, setIssuedDebitNotewithoutReceipt] = useState(false)
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSchemeUniqeId": "Working", "vineoCompanySchemeNumber": "Click Add To Load Scheme" }])
    const isvineoschemamasterdataLoaded = useSelector((state) => state.vineoData.isvineoschemamasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [openMapAreaToSalesMan, SetOpenMapAreaToSalesMan] = useState(false);

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const getinvoicedatafromServer = (billnumber) => {
        if (billnumber.length > 0) {
            console.log("Calling Server to get data with Invoice ID  = " + billnumber);

            http.post(BASEURL_JWTAUTH + VINEO_GETBILLWITHID_ADDPURCHASEBILL_AVAILABLE, { "vineoaddUniquePurchaseNumberWithPrefix": billnumber }).then(
                (res) => {

                    if ( res.data.ultiVineoAddPurchaseUniqeId) {
                        console.log(res.data);
                       
                        if (issuedDebitNotewithoutReceipt || res.data?.receiptIsGenerated )
                        // if (!res.data.receiptIsGenerated) // this line is for testing uncomment it in prod
                        {

                            if (!res.data?.isdebittNoteGenerated) {
                                console.log(res.data.isdebittNoteGenerated);
                                setInvoice(res.data);
                            }
                            else {
                                notify("Debit Note Already Generated, Pls Select Another Bill");
                                setInvoice({});
                            }
                        }
                        else {
                            notify("Debit Note Cant Be Generated ,As Payment is Still Credit ");
                            setInvoice({});
                        }

                    }
                    else {
                        notify("Empty Data Received from Server for Bill NUmber " + billnumber);
                        setInvoice({});
                    }


                },
                (err) => {

                    const stored = sessionStorage.getItem('uniqueidForVineoclientName')
                    const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
                    const stored2 = JSON.parse(sessionStorage.getItem("token"))
                    console.log("Error From Server with code = " + err.data)

                }
            )

        }
        else {
            notify("Please Enter Bill Number It cant be Empty")
        }

    }


    const submitForm = (data) => {
        console.log("Connecting Server to Create Debit Note")
        console.log(data)
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_DEBITNOTE, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorMessage == 'SUCCESS') {
                    notify('Debit Note Operation Successful with Details =' + res.data.errorCode)
                    setInvoice({});
                    handlePrint(res?.data?.data)
                    reset();

                }
                else
                    notifyError("Error While Add Debit Operation From Server with Message as " + res.data.errorCode)
                //  console.log(res.data)

            },
            (err) => {


                notify("Error While Add Bill Operation From Server ")
            }
        )
    }


    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)

    const onSelectBill = () => {
        // selectedBillsArray = [];

        // let tempvalue = 0;

        // gridApi.api.getSelectedNodes().map((bill, index) => {
        //     return (
        //         <>
        //             {selectedBillsArray[index] = bill.data}
        //             {/* {console.log(bill.data)} */}
        //             {tempvalue = tempvalue + parseFloat(bill.data.vineoAddSaleBillBottomAmt)}
        //             {/* {console.log(totalloadBillValue)}
        //             {console.log(selectedBillsArray.length)} */}
        //         </>
        //     )
        // })
        // { setValue("vineoAddBillSet", selectedBillsArray) }
        // { setTotalloadBillValue(tempvalue) }
        // { setNumberofloadbillselected(selectedBillsArray.length) }
        // { setValue("numberofBillsIncreateLaod", numberofloadbillselected) }
        // { setValue("totalLaodValue", totalloadBillValue) }

    }

    const onExportClick = () => {
        gridApi.api.exportDataAsCsv();
    }

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        // editable: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }

    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedNodes();
        var selectedRowsString = '';
        var maxToShow = 5;
        console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }


    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [
        { headerName: "DB Prefix.", field: "vineodebitNotePrefix", checkboxSelection: true },
        { headerName: "DB No.", field: "debitNoteNumber" ,resizable: true  },
        { headerName: "BillNo.", valueGetter: 'data.debitNoteIssuedonPurchaseBillNumberwithprefix' ,resizable: true  },
        { headerName: "Date ", field: "vineoDebitNoteDate",resizable: true  },

        { headerName: "Amount", field: 'debitnoteAmount',resizable: true  },
        { headerName: "Vendor", field: 'vineoVendorAccountName' ,resizable: true  },

        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>

                <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
            </div>
        }
    ]


    const handlePrint = (data) => {
        console.log(data)
        setDataforprint(data);
        setOpenprint(!openprint)
    }
    const handleUpdate = (data) => {
        console.log(data)

        // console.log("This will editqty by this amount "+updatedProductBatchQty);
        // submitForm(data)

    }
    //deleting a user


    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEO_DELETE_DEBITNOTE_AVAILABLE, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Debit Note  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

                }
                else
                    notify("Error While Deleting Debit Note  " + + res.data.errorMessage)
            },
            (err) => {
                notify("Error While Deleting Debit Note Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    // AG work ends here 

    return (
        <div>

            <div>
            <div className='h5 mb-0 text-gray-800'>
                    <center>
                        <h5 className='justify-content-center m-1'>Add Debit Note :</h5>
                    </center>
                </div>
                <Row>

                    <Form.Group controlId="issuedDBRwithoutReceipt" className="m-2">
                        <Form.Check
                            onChange={(e) => { setIssuedDebitNotewithoutReceipt(!issuedDebitNotewithoutReceipt);  }}
                            label='Issue Debit Note Without Receipt'
                            id="Checkbox"
                        />
                    </Form.Group>
                    {setValue("issuedDebitNotewithoutReceipt", issuedDebitNotewithoutReceipt)}

                </Row>

                <Row>
                    <Col md={4}>


                        <Form.Text >Enter Invoice ID Number With Prefix*</Form.Text >
                        <InputGroup>
                            <Form.Control className="form-control"
                                onChange={(e) => {
                                    setInvoiceNumber(e.target.value)
                                }} />

                            <Button variant="primary"
                                onClick={(e) => { getinvoicedatafromServer(inovoiceNumber) }} >
                                <FontAwesomeIcon icon={faRotate} />
                            </Button>


                        </InputGroup>
                    </Col>
                    <Col>
                        <Form.Text >Search Purchase Bill Number </Form.Text >
                        <InputGroup><Button className="btn btn-primary "
                            onClick={(e) => { SetOpenPurchaseReport(true) }} >Find Purchase

                        </Button>
                        </InputGroup>
                    </Col>
                    <Form.Text >Invoice Details for Debit Note:</Form.Text >


                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <div>
                            <Card className='p-4'>
                                <Row>
                                    <Col md={2}>
                                        <FormGroup>
                                            {setValue("vineoAddBill", invoice)}
                                            <Form.Text >
                                                Debit Note Date
                                            </Form.Text>
                                            <DatePicker className="form-control"
                                                selected={debitNotedate}
                                                onChange={setDebitNote}
                                                name="debitNotedate"
                                                dateFormat="dd-MM-yyyy"
                                                ref={setValue("vineoDebitNoteDate", moment(debitNotedate).format(DATEFORMAT))}
                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col md={1}>
                                        <Form.Text >Invoice No</Form.Text >
                                        <InputGroup>
                                            <Form.Text >{invoice?.vineoaddUniquePurchaseNumberWithPrefix}</Form.Text>
                                            <>  {setValue("debitNoteIssuedonPurchaseBillNumber", invoice?.ultiVineoAddPurchaseUniqeId)}</>
                                            {/* please note this is id Not prefix Number  */}
                                        </InputGroup>
                                    </Col>
                                    <Col md={1}>
                                        <Form.Text >Botm Amt</Form.Text >
                                        <InputGroup>
                                            <Form.Text >{invoice?.vineoAddPurchaseBottomAmt}</Form.Text>
                                        </InputGroup>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Text >Vendor Name</Form.Text >
                                        <InputGroup>
                                            <InputGroup>
                                                <Form.Text >{invoice?.vineoVendorAccount?.vineoVendorAccountName}</Form.Text >
                                                <>  {setValue("ultiVineoVendorAccountUniqeId", invoice?.vineoVendorAccount?.ultiVineovineoVendorAccountNameUniqeId)}</>
                                            </InputGroup>
                                        </InputGroup>
                                    </Col>




                                    <Col md={2}>
                                        <Form.Text>
                                            salesman
                                        </Form.Text>

                                        <InputGroup>

                                            <Form.Text >{invoice?.vineoVendorAccount?.vineoArea?.salesman?.nameofUser}</Form.Text >
                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Area
                                        </Form.Text>
                                        <InputGroup>

                                            <Form.Text >{invoice?.vineoVendorAccount?.vineoArea?.vineoAreaName}</Form.Text >
                                        </InputGroup></Col>

                                   <Row>
                                   <Col >
                                        <Form.Text>
                                            Narr *
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("debitnotenarr")} required />

                                    </Col>
                                    <Col >
                                        <Form.Text>
                                            Address for Return of Products *
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("shipingAddress")} required />

                                    </Col>

                                   </Row>

                                </Row>


                            </Card>
                        </div>
                    </Row>



                    <div>
                        <Card className='p-4'>

                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">S.N. </th>
                                        <th scope="col">Item Name </th>
                                        <th scope="col">Unit price</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Damaged</th>
                                        <th scope="col">Sellable</th>
                                        <th scope="col">Rate</th>
                                        <th scope="col">Amt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice?.vineoAddPurchaseItemList?.map((item, i) => {
                                        return (
                                            <tr key={item.id} >

                                                <td>
                                                    <Form.Text>{i + 1}</Form.Text >
                                                    {setValue(`vineoDebitNoteitemsList[${i}].vineoAddPurchaseItem.ultiVineoAddPurchaseItemUniqeId`, item?.ultiVineoAddPurchaseItemUniqeId)}
                                                    {setValue(`vineoDebitNoteitemsList[${i}].itemeffectiveratefrombill`, item?.vineoItemEffectiverateAfterDsicount)}

                                                </td>
                                                <td>
                                                    <Form.Text> {item.vineoProduct.vineoProductName}</Form.Text >
                                                </td>
                                                <td>
                                                    <Form.Text> {item.vineoBatch.vineoItemEffectiverateAfterDsicount}</Form.Text >
                                                </td>
                                                <td>
                                                    <Form.Text> {item.vineoAddPurchaseProductBatchQty}</Form.Text >
                                                </td>
                                                <td>
                                                    <Form.Control defaultValue="0" type="number" min="0" placeholder='Damaged Product'  {...register(`vineoDebitNoteitemsList[${i}].damagedproductreturnqtyinpcs`)} />


                                                </td>
                                                <td>
                                                    <Form.Control defaultValue="0" type="number" min="0" placeholder='Sellable Product' {...register(`vineoDebitNoteitemsList[${i}].sellableproductreturnqtyinpcs`)} />
                                                </td>
                                                <td>
                                                    <Form.Text>{item?.vineoItemEffectiverateAfterDsicount.toFixed(2)}</Form.Text >


                                                </td>
                                                <td>
                                                    <div style={{ display: 'none' }}>
                                                        {Totaldebitamount = Totaldebitamount - parseFloat(watch('adjustamount')) +  parseFloat(watch('adjustamountExtra')) + parseFloat(
                                                            parseFloat((item?.vineoItemEffectiverateAfterDsicount)) *
                                                            ( (1+((parseFloat(item?.vineoProduct?.vineoProductCentralGstPercentage)+ parseFloat(item?.vineoProduct?.vineoProductStateGstPercentage))/100)))*
                                                            (parseFloat(watch(`vineoDebitNoteitemsList[${i}].damagedproductreturnqtyinpcs`))
                                                                + parseFloat(watch(`vineoDebitNoteitemsList[${i}].sellableproductreturnqtyinpcs`))))
                                                        }
                                                    </div>


                                                    {parseFloat(
                                                        parseFloat((item?.vineoItemEffectiverateAfterDsicount)) *
                                                        ( (1+((parseFloat(item?.vineoProduct?.vineoProductCentralGstPercentage)+ parseFloat(item?.vineoProduct?.vineoProductStateGstPercentage))/100)))*
                                                        (parseFloat(watch(`vineoDebitNoteitemsList[${i}].damagedproductreturnqtyinpcs`))
                                                            + parseFloat(watch(`vineoDebitNoteitemsList[${i}].sellableproductreturnqtyinpcs`)))).toFixed(2)
                                                    }

                                                    {/* <Form.Control  type="number" min="0" placeholder='Total Item Amount' {...register(`vineoDebitNoteitemsList[${i}].totalitemamount`)}  /> */}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    <th scope="row" align="right"></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <Form.Text>Total Debit Adjust</Form.Text>
                                        <Form.Control defaultValue="0" type="number" min="0" placeholder='Total Adjust Amount' {...register(`adjustamount`)} required />
                                    </td>
                                    <td>
                                        <Form.Text>Total Debit Adjust Extra</Form.Text>
                                        <Form.Control defaultValue="0" type="number" min="0" placeholder='Total Adjust Amount Extra' {...register(`adjustamountExtra`)} required />
                                    </td>
                                    <td>
                                        <Form.Text> Total DebitNote Amount ={Totaldebitamount}</Form.Text >
                                        {/* <Form.Control type="text" placeholder='Total Debit Amount'  {...register("debitnoteAmount")} required /> */}
                                    </td>


                                </tbody>
                            </table>

                        </Card>
                    </div>

                    <div>
                        <center>
                            <input className="btn btn-primary m-2" type="submit" value="Submit" />
                        </center>
                    </div>
                </form>
            </div>

            <div>

                <Row>
                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Debit Note From Date
                            </Form.Text>
                            <DatePicker className="form-control"
                                selected={fromdate}
                                onChange={setFromdate}
                                name="vineoFromDate"
                                dateFormat="dd-MM-yyyy"
                                ref={setValue("vineoFromDate", moment(fromdate).format(DATEFORMAT))}
                            />
                            {/* <Controller
                                name="vineoPurchaseFromDateref"
                                //
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <DatePicker className="form-control"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        dateFormat="dd-MM-yyyy"


                                    />

                                )}
                            />
                            {errors.vineoPurchaseFromDateref && <span>This field is required</span>} */}

                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Debit Note To Date
                            </Form.Text>

                            <DatePicker className="form-control"
                                selected={todate}
                                onChange={setTodate}
                                name="vineoToDate"
                                dateFormat="dd-MM-yyyy"
                                ref={setValue("vineoToDate", moment(todate).format(DATEFORMAT))}
                            />

                        </FormGroup>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <Button className="btn btn-warning m-2" disabled={isLoading} onClick={() => {
                            setLoading(!isLoading); getDatafromServer({
                                startDate: moment(fromdate).format(DATEFORMAT),
                                endDate: moment(todate).format(DATEFORMAT)
                            })
                        }}> <FontAwesomeIcon icon={faRotate} /> {isLoading ? 'Loading…' : 'Load DebitNote'}</Button>

                        {/* <button className="btn btn-primary m-2" type="button" onClick={onSelectBillButtonClick}>
                    SelectBills
                </button> */}
                        <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">Export To CSV
                        </Button>
                    </Col>
                </Row>
                <div className="ag-theme-alpine" style={{ height: '400px' }}>
                    <div className="ag-theme-alpine" style={{ height: '700px' }}>
                        <AgGridReact
                            id="AgGridforBillData"
                            rowSelection="multiple"
                            rowData={tableData}
                            columnDefs={columnDefs}
                            onRowSelected={onSelectBill}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                        />
                    </div>
                </div>
            </div>
            <Modal show={openPurchaseReport}
                onHide={() => { SetOpenPurchaseReport(false) }}
                backdrop="static"
                keyboard={false}
                fullscreen={true}>
                <Modal.Header closeButton />
                {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                <Modal.Body>
                    {/* <AddBatch /> */}
                    <Purchase />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary m-2" onClick={() => { SetOpenPurchaseReport(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={openprint}
                onHide={() => setOpenprint(!openprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Debit Note Print</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <DebitNoteprint data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
        </div>

    )

}