import { Routes, Route, Link, useResolvedPath } from 'react-router-dom';
import Home from '../components/dashboard/Home';
import Dashboard from '../components/dashboard/Dashboard';
import AuthUser from '../constants/AuthUser';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { toast } from 'react-toastify';
import {
  Nav, Navbar, NavDropdown, Form, FormControl,
  Dropdown, Col, Row,
} from 'react-bootstrap'

import Register from '../components/loginRegisterForms/Register';
import { useEffect, useState } from 'react';
import { clearreduxstore } from '../redux/features/vineoData/vineoDataSlice';
import { useDispatch } from 'react-redux';
import Deleteallreduxstore from '../components/loginRegisterForms/Deleteallreduxstore';




function Auth() {

  const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");

  const changeStyle = () => {
    if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
    }
    else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    }
  };
  const changeStyle1 = () => {
    if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
    }
    else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    }
  };
  const dropdownStyle = {
    color: 'black', // Set the color to black
  };
  const [clearRedux, setClearRedux] = useState(false)
  const dispatch = useDispatch();
  const { token, logout, getUser, getRole } = AuthUser();

  const logoutUser = async () => {
    try {
      if (token != undefined) {
        logout();
      }
      await dispatch(clearreduxstore());

    } catch (error) {
      console.log("Error During clearing Data")
    }
  };


  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message)
  const notifyWarning = (message) => toast.warning(message)



  const rolename = getRole("");

  const username = getUser("");


  console.log("Current logged in user is " + getRole())
  return (
    <div style={{ display: "flex" }}>


      <ul className={style} id="accordionSidebar">

        {/*  <!-- Sidebar - Brand --> */}
        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
          {/* <div className="sidebar-brand-icon rotate-n-15">
                              <i className="fas fa-laugh-wink"></i>
                          </div> */}
          <div className="sidebar-brand-text mx-3">SYNERGY</div>
          <div className="text-center d-none d-md-inline">
            <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
          </div>
        </a>

        {/*   <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />
        <li className="nav-item">

          {(() => {
            if (rolename === "admin" || rolename === 'salesman') {
              return (

                <NavDropdown title="Master" className='m-1' >

                  <NavDropdown.Item as={Link} to={"/dashboard/addfirminfo"}>FIRM</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/register"}>USER</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/CustomerAccountInfo"}>PARTY</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/AutoImport"}>AutoImportParty</NavDropdown.Item>

                  {/* {console.log((window.location.href).includes("localhost"))} */}
                  {((rolename === "superadmin") || ((window.location.href).includes("localhost")) || (!(window.location.href).includes("dkdmsys"))) ?
                    <><NavDropdown.Item as={Link} to={"/dashboard/CompanyMaster"}>Company Master</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/dashboard/CategoryMaster"}>Category Master</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"dashboard/SubCategoryInfo"}>Sub Category Master</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/dashboard/GroupMaster"}>Group Master</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/dashboard/SubGroupMaster"}>Sub Group Master</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/dashboard/BrandMaster"}>Brand Master</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/dashboard/SubBrandMaster"}>SubBrand Master</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/dashboard/AddPurchase"}>Add Purchase</NavDropdown.Item>

                      {/*  <NavDropdown.Item as={Link} to={"/dashboard/SubBrandMasterAdvance"}>SubBrandMasterAdvance</NavDropdown.Item>
                 */}
                      <NavDropdown.Item as={Link} to={"/dashboard/GstMaster"}>GST Master</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/dashboard/Transport"}>Transport</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/dashboard/ProductMasterInfo"}>Product Master</NavDropdown.Item>
                    </>
                    : <></>}

                  {/* <NavDropdown.Item as={Link} to={"/dashboard/SalesmanMasterInfo"}>Salesman Master</NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to={"/dashboard/AreaMaster"}>Area Master</NavDropdown.Item>

                  {/* <NavDropdownMenu className='nav-link collapsed' title="Account Type">
                        <NavDropdown.Item as={Link} to={"/dashboard/TypeOneInfo"}>Type1 </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/TypeTwoInfo"}>Type2  </NavDropdown.Item>
                      </NavDropdownMenu> */}
                  <NavDropdown.Item as={Link} to={"/dashboard/AccountGroupMaster"}>Account Group</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/GstMaster"}>GST Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/Transport"}>Transport</NavDropdown.Item>


                  <NavDropdown title="Account" style={{ backgroundColor: 'gray' }}>
                    <NavDropdown.Item as={Link} to={"/dashboard/CustomerAccountInfo"}>Customer-Party</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/dashboard/SalesmanPartyAccountInfo"}>Salesman-Party</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/dashboard/VendorAccount"}>Vendor Account</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/dashboard/AutoImport"}>AutoImportParty</NavDropdown.Item>

                  </NavDropdown>




                  <NavDropdown.Item as={Link} to={"/dashboard/CustomerBankInfo"}>Customer Bank Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SchemeMaster"}>Scheme Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/ApplyScheme"}>Apply Scheme</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/GoDownMaster"}>Godown Master</NavDropdown.Item>

                  <NavDropdown.Item as={Link} to={"/dashboard/VanMaster"}> Van master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SpecialGroupMaster"}> Special Group Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/ServiceMaster"}> Service Account</NavDropdown.Item>

                </NavDropdown>

              )
            } else if (rolename === "superadmin" ) {
              return (
                <NavDropdown title="Master" className='m-1' >
                  <NavDropdown.Item as={Link} to={"/dashboard/addfirminfo"}>Firm</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/register"}>User</NavDropdown.Item>

                  <NavDropdown.Item as={Link} to={"/dashboard/CompanyMaster"}>Company Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/CategoryMaster"}>Category Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"dashboard/SubCategoryInfo"}>Sub Category Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/GroupMaster"}>Group Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SubGroupMaster"}>Sub Group Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/BrandMaster"}>Brand Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SubBrandMaster"}>SubBrand Master</NavDropdown.Item>

                  {/*  <NavDropdown.Item as={Link} to={"/dashboard/SubBrandMasterAdvance"}>SubBrandMasterAdvance</NavDropdown.Item>
                  */}
                  <NavDropdown.Item as={Link} to={"/dashboard/GstMaster"}>GST Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/ProductMasterInfo"}>Product Master</NavDropdown.Item>


                  {/* <NavDropdown.Item as={Link} to={"/dashboard/SalesmanMasterInfo"}>Salesman Master</NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to={"/dashboard/AreaMaster"}>Area Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/RouteMaster"}>Route Master</NavDropdown.Item>

                  {/* <NavDropdownMenu title="Account Type">
                        <NavDropdown.Item as={Link} to={"/dashboard/TypeOneInfo"}>Type1  </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/TypeTwoInfo"}>Type2  </NavDropdown.Item>
                      </NavDropdownMenu> */}
                  <NavDropdown.Item as={Link} to={"/dashboard/AccountGroupMaster"}>Account Group</NavDropdown.Item>
                  <NavDropdown title="Account" style={{ backgroundColor: 'gray' }}>
                    <NavDropdown.Item as={Link} to={"/dashboard/CustomerAccountInfo"}>Customer</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/dashboard/VendorAccount"}>Vendor Account</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/dashboard/AutoImport"}>AutoImportParty</NavDropdown.Item>

                  </NavDropdown>



                  <NavDropdown.Item as={Link} to={"/dashboard/CustomerBankInfo"}>Customer Bank Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SchemeMaster"}>Scheme Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/GoDownMaster"}>Godown Master</NavDropdown.Item>

                  <NavDropdown.Item as={Link} to={"/dashboard/VanMaster"}> Van master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SpecialGroupMaster"}> Special Group Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/ServiceMaster"}> Service Account</NavDropdown.Item>

                </NavDropdown>
              )
            } else {
              return (
                <NavDropdown title="Master" className='m-1' >

                  <NavDropdown.Item as={Link} to={"/dashboard/addfirminfo"}>Firm</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/register"}>User</NavDropdown.Item>

                  <NavDropdown.Item as={Link} to={"/dashboard/CompanyMaster"}>Company Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/CategoryMaster"}>Category Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"dashboard/SubCategoryInfo"}>Sub Category Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/GroupMaster"}>Group Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SubGroupMaster"}>Sub Group Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/BrandMaster"}>Brand Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SubBrandMaster"}>SubBrand Master</NavDropdown.Item>

                  {/*  <NavDropdown.Item as={Link} to={"/dashboard/SubBrandMasterAdvance"}>SubBrandMasterAdvance</NavDropdown.Item>
                  */}
                  <NavDropdown.Item as={Link} to={"/dashboard/ProductMasterInfo"}>Product Master</NavDropdown.Item>


                  {/* <NavDropdown.Item as={Link} to={"/dashboard/SalesmanMasterInfo"}>Salesman Master</NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to={"/dashboard/AreaMaster"}>Area Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/RouteMaster"}>Route Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/GstMaster"}>GST Master</NavDropdown.Item>

                  {/* <NavDropdownMenu className='rounded-circle border-0' title="Account Type">
                        <NavDropdown.Item as={Link} to={"/dashboard/TypeOneInfo"}>Type1  </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/TypeTwoInfo"}>Type2  </NavDropdown.Item>
                      </NavDropdownMenu> */}
                  <NavDropdown.Item as={Link} to={"/dashboard/AccountGroupMaster"}>Account Group</NavDropdown.Item>
                  <NavDropdown title="Account" style={{ backgroundColor: 'gray' }}>
                    <NavDropdown.Item as={Link} to={"/dashboard/CustomerAccountInfo"}>Customer</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/dashboard/VendorAccount"}>Vendor Account</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/dashboard/AutoImport"}>AutoImportParty</NavDropdown.Item>

                  </NavDropdown>



                  <NavDropdown.Item as={Link} to={"/dashboard/CustomerBankInfo"}>Customer Bank Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SchemeMaster"}>Scheme Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/GoDownMaster"}>Godown Master</NavDropdown.Item>

                  <NavDropdown.Item as={Link} to={"/dashboard/VanMaster"}> Van master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/SpecialGroupMaster"}> Special Group Master</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/dashboard/ServiceMaster"}> Service Account</NavDropdown.Item>

                </NavDropdown>

              )
            }
          })()}
          <>
            <NavDropdown title="Map" className='m-1'>
              <NavDropdown.Item as={Link} to={"/dashboard/Areatosalesman"}>Area To Salesman</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/PartytoArea"}>Party to Area</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/AdvanceMappingSetting"}>Advance Mapping Setting</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/VendorToProduct"}>Vendor To Product</NavDropdown.Item>

            </NavDropdown>


            <NavDropdown title="Entries" className='m-1'>
              {/* <NavDropdownMenu title="Sales">
                    <NavDropdown.Item as={Link} to={"/dashboard/Billing"}>Billing</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/dashboard/AddSalesBilling"}>Add Bill</NavDropdown.Item>

                  </NavDropdownMenu> */}
              <NavDropdown.Item as={Link} to={"/dashboard/Billing"}>Billing</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/AddSalesBilling"}>Add Bill</NavDropdown.Item>

              {/* <NavDropdown.Item as={Link} to={"/dashboard/CounterSales"}>Counter Sales</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to={"/dashboard/Purchases"}>Purchases</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/addgrnote"}>AddGRN</NavDropdown.Item>

              <NavDropdown.Item as={Link} to={"/dashboard/addgonote"}>AddGON</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/approvegonote"}>ApproveGON</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/approveparty"}>ApproveParty</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to={"/dashboard/PurchaseService"}>Purchases Service</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to={"/dashboard/AddCreditNote"}>Credit Notes</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/addDebitNote"}>Debit Notes</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/ImportPurchaseIntoSystem"}>ImportPurchase</NavDropdown.Item>

              <NavDropdown title="Stock Adjustment" style={{ backgroundColor: 'gray' }}>
                <NavDropdown.Item as={Link} to={"/dashboard/AddStock"}>Add Stock</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/Addbatch"}>Add Batch</NavDropdown.Item>
              </NavDropdown>
            </NavDropdown>


            <NavDropdown title="Report" className='m-1'>
              <NavDropdown.Item as={Link} to={"/dashboard/BillingReport"}>BillingReport</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/AllProductwiseSale"}>All Product Wise Sales</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/AllPartywiseSale"}>All Party Wise Sales</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/PurchaseReport"}>PurchaseReport</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/ItemWisePurchase"}>Item Wise Purchase</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/stockReport"}>Stock Report</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/AllPartyOutstandingReport"}>Party outstanding</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/gstr1report"}>gstr1report</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/shopvisitreport"}>shopVisit</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="SFA" className='m-1'>
              <NavDropdown.Item as={Link} to={"/dashboard/shopvisitreport"}>ShopVisit</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/approveparty"}>ApproveParty</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/visitsummary"}>VisitSummary</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/attendance"}>Attendance</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/userAttendance"}>UserAtt</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/visitReport"}>VisitReport</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/datewisevisitReport"}>DateWiseVisit</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/gonsummary"}>GonSummary</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/gonreport"}>GONReport</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/itemgonreport"}>ItemWiseGON</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/AllPartyOutstandingReport"}>Party outstanding</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/monthlyTarget"}>MonthlyTarget</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/firmPartyTransfer"}>FirmPartyTransfer</NavDropdown.Item>
            </NavDropdown>



            <NavDropdown title="Tools" className='m-1'>
              <NavDropdown.Item as={Link} to={"/dashboard/importParty"}>ImportParty</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/importproductForUser"}>ImportProduct</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/importBills"}>ImportBills</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/ImportPurchaseIntoSystem"}>ImportPurchase</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/ImportBatchIntoSystem"}>ImportBatch</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/ImportUsersIntoSystem"}>ImportUsers</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/ImportClientIntoSystem"}>ImportClient</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/converteinvoicejsonuniversal"}>E-INV JSON</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/getsuperadminAuthoritylogin"}>LoginAs SuperAdmin</NavDropdown.Item>

            </NavDropdown>


            <NavDropdown title="Help" className='m-1'>
              <NavDropdown.Item as={Link} to={"/dashboard/checkjwt"}>Check JWT TOken</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard/Calculator"}>Calculator</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard"}>Notepad</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/dashboard"}>Software Help Manual</NavDropdown.Item>


            </NavDropdown>




            {((rolename === "superadmin" || rolename==="mothercompanyadmin")) ?


              <NavDropdown title="Admin" className='m-1'>
                <NavDropdown.Item as={Link} to={"/dashboard/checkjwt"}>Check JWT TOken</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard"}>Calculator</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard"}>Notepad</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard"}>Software Help Manual</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/clientregister"}>Register Client</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/registerclientadmin"}>Register User</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/Areatosalesman"}>MAP Salesman</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/importproduct"}>ImportProduct</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/importproductviajson"}>ImportProductViaJSON</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/importVendor"}>ImportVendor</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/importFirm"}>ImportFirm</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/dashboard/deleteBills"}>deleteBills</NavDropdown.Item>

                <NavDropdown.Item as={Link} to={"/dashboard/changepasswordofadminclient"}>ChangePassword</NavDropdown.Item>

              </NavDropdown>
              :
              <></>}



          </>


        </li>



        <a className="m-2 btn btn-danger btn-sm" type="submit" onClick={logoutUser}>Logout</a>





        {/* <div className="sidebar-card d-none d-lg-flex">
          <img className="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="..." />
          <p className="text-center mb-2"><strong>DKDMS</strong> is packed with premium features, components, and more!</p>
          <a className="btn btn-success btn-sm" href="https://vineotech.com">Contact Us </a>
        </div> */}

      </ul>

      <div style={{ flex: 1 }}>
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/dashboard/*" element={<Dashboard />} />


        </Routes>

      </div>
    </div>

  );
}

export default Auth;
