import React from "react";
import ReactToPrint from "react-to-print";
import '../../../../printFormat.css';
import QRCode from 'qrcode.react';
import PropTypes from "prop-types";
import { Table, Button, Col, Row, Card } from "react-bootstrap";

var converter = require('number-to-words');

class ComponentToPrint extends React.Component {

    render() {
        return (
            <>
                <div className="">
              
                    <br />
                    <div id='first-row'>

                        <div className='col'>
                            SUPPLIER: <strong>  {this.props.data?.vineoAddSaleBillFirm?.vineoFirmFirmName}</strong>
                            <br />
                            Address: {this.props.data?.vineoAddSaleBillFirm?.vineoFirmFirmaddress1?.substring(0, 80)}, {this.props.data?.vineoAddSaleBillFirm?.vineoFirmFirmaddress2?.substring(0, 80)}
                            <br />
                            Pin:  {this.props.data?.vineoAddSaleBillFirm?.vineoFirmPincode}
                            <br />
                            Contact No: {this.props.data?.vineoAddSaleBillFirm?.vineoFirmMobilenumber}
                            <br />
                            State Code:  {this.props.data?.vineoAddSaleBillFirm?.vineofirmGst?.vineoGstNumber?.substring(0, 2)}
                            <br />
                            GSTIN:  {this.props.data?.vineoAddSaleBillFirm?.vineofirmGst?.vineoGstNumber}
                            <br />
                            PAN:  {this.props.data?.vineoAddSaleBillFirm?.vineoFirmFirmpancard}


                        </div>
                        <div className='col'>
                            CUSTOMER:<strong> {this.props.data?.vineoAddSaleBillCustomer?.vineoCustomerAccountName}</strong>
                            <br />
                            Address: {this.props.data?.vineoAddSaleBillCustomer?.vineoCustomerAccountaddress},
                            {this.props.data?.vineoAddSaleBillCustomer?.vineoCustomerAccountaddress2}
                            <br />
                            Pin:  {this.props.data?.vineoAddSaleBillCustomer?.vineoCustomerAccountpincode}
                            <br />
                            Contact No: {this.props.data?.vineoAddSaleBillCustomer?.vineoCustomerAccountmob}<br />
                            State Code:   {this.props.data?.vineoAddSaleBillCustomer?.vineoGst?.vineoGstNumber?.substring(0, 2)}
                            <br />
                            GSTIN:  {this.props.data?.vineoAddSaleBillCustomer?.vineoGst?.vineoGstNumber}
                        </div>
                        <div className='col'>
                            <img src={require("../../../../images/dklogofrontpage.png")} height='40' alt='Dorf Ketal logo' style={{ display: 'block' }} />
                            <strong> TAX INVOICE No:</strong>   {this.props.data?.vineoaddUniqueBillNumberWithPrefix}
                            <br />
                            Date: {this.props.data?.vineoAddSaleBillDate}
                            <br />
                            EinvAck: {this.props.data?.einvoiceAckNo}
                            <br />
                            EWayBillNo: {this.props.data?.ewayBillNo}
                            <br />
                            Salesman: {
                                this.props.data?.vineoAddSaleBillCustomer?.vineoArea?.salesman
                                    ?.nameofUser
                            }
                            <br />
                            Salesman No / Code : {this.props.data?.salesmanMobileNumber} /{this.props.data?.salesmanCode}
                        </div>
                    </div>
                    <table id='main-table'>
                        <thead>
                            <tr>
                                <th rowSpan='2'>Sr No.</th>
                                <th rowSpan='2'>Product Name</th>
                                <th rowSpan='2'>HSN Code</th>
                                <th rowSpan='2'>MRP</th>
                                <th rowSpan='2'>Qty</th>
                                <th rowSpan='2'>Unit</th>
                                <th rowSpan='2'>Free</th>
                                <th rowSpan='2'>Disc.</th>
                                <th rowSpan='2'>Rate</th>
                                {/* <th rowSpan='2'>Sch Dis</th> */}
                                <th rowSpan='2'>Taxable</th>
                                <th colSpan='2'>SGST</th>
                                <th colSpan='2'>CGST</th>
                                <th rowSpan='2'>Total</th>
                            </tr>
                            <tr>
                                <th scope='col'>SGST%</th>
                                <th scope='col'>SGST</th>
                                <th scope='col'>CGST%</th>
                                <th scope='col'>CGST</th>
                            </tr>
                            <tr></tr>
                        </thead>
                        <tbody>
                            {this.props.data?.vineoAddBillItemList.map((items, index) => {
                                return (

                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{items.vineoProduct.vineoProductName} </td>
                                        <td>{items.vineoProduct.vineoProducthsnCod}</td>
                                        <td>{items.vineoAddBillItemUnit === 'BOX' ? items.vineoBatch.vineoBatchProductMRP : items.vineoBatch.vineoBatchProductMRPperPcs}</td>
                                        <td>{items.vineoAddBillProductBatchQty}</td>
                                        <td>{items.vineoAddBillItemUnit}</td>
                                        <td>{items.vineoAddBillItemFreeProductQty}</td>
                                        <td>{items.vineoItemEffectiveTotalDiscountPercentage} %</td>
                                        <td>{items.vineoItemEffectiverateAfterDsicount}</td>
                                        {/* <td>0.00</td> */}
                                        <td>{items.vineoAddBilItembillvaluewithoutgst}</td>
                                        <td>{items.vineoProduct.vineoProductStateGstPercentage}</td>
                                        <td>{items.vineoAddBillItemsGSTAmount}</td>
                                        <td>{items.vineoProduct.vineoProductCentralGstPercentage}</td>
                                        <td>{items.vineoAddBillItemcGSTAmount}</td>
                                        <td>{items.vineoAddBillItemNetAmount}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <div id='totals'>
                        <span>{converter.toWords(this.props.data?.vineoAddSaleBillBottomAmt).toUpperCase()}</span>
                        {this.props.data?.isigsctbil ? <span>iGST:  {this.props.data?.totaligstvalue} </span> :
                            <span>GST:  {this.props.data?.vineoAddSaleBillcGSTAmt +
                                this.props.data?.vineoAddSaleBillsGSTAmt} </span>}

                        <span>BASIC:{this.props.data?.vineoAddSaleBillwithoutgstAmt}</span>
                        <span>NET:{this.props.data?.vineoAddSaleBillBottomAmt}</span>
                    </div>
                    <div id='last-row'>
                        <div>
                            {/* <img src={`data:image/svg+xml;base64,${btoa(this.props.data?.ewaysignedQRCode)}`}  /> */}
                            {this.props.data?.ewaysignedQRCode ? <QRCode value={this.props.data?.ewaysignedQRCode} size={256}
                                includeMargin={true} /> : <></>}
                        </div>
                        <div>
                            * GST Summary*
                            <br />
                            <table>
                                <thead>
                                    <tr>
                                        {this.props.data?.isigsctbil ? <> <th>iGST AMT</th></> : <>
                                            <th>cGST  </th>
                                            <th>sGST  </th>
                                            <th>GST AMT</th>
                                        </>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {this.props.data?.isigsctbil ? <> <th>{this.props.data?.totaligstvalue}</th></> : <>
                                            <th>{this.props.data?.vineoAddSaleBillcGSTAmt}  </th>
                                            <th>{this.props.data?.vineoAddSaleBillsGSTAmt}  </th>
                                            <th>{this.props.data?.vineoAddSaleBillcGSTAmt +
                                                this.props.data?.vineoAddSaleBillsGSTAmt} </th>
                                        </>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            Back Details:  {this.props.data?.vineoAddSaleBillFirm?.vineoBankName}
                            <br />
                            IFSC Code: {this.props.data?.vineoAddSaleBillFirm?.vineoBankifscCode}
                            <br />
                            AC/No: {this.props.data?.vineoAddSaleBillFirm?.vineoBankAccountNumber}
                            <br />

                        </div>

                        <div>
                            Cash Disc:
                            <br />
                            Schme Amt:
                            <br />
                            Star Disc:
                            <br />
                            Tcs:
                            <br />
                            Loyalty:
                        </div>
                        <div>
                            {this.props.data?.vineoAddSaleBillCashDiscount}
                            <br />
                            {this.props.data?.vineoAddSaleBillSchemeAmt}
                            <br />
                            {this.props.data?.vineoAddSaleBillStarDisc}
                            <br />
                            {this.props.data?.vineoAddSaleBillTcsAmt}
                            <br />
                            {this.props.data?.vineoPayment?.actualCreditAmountApplied}
                            <br />
                        </div>
                        <div>
                            Gross Amt
                            <br />
                            Total GST
                            <br />
                            Packaging
                            <br />
                            Rounding
                            <br />
                            Payble Amt
                        </div>
                        <div>
                            {this.props.data?.vineoAddSaleBillwithoutgstAmt}
                            <br />
                            {this.props.data?.isigsctbil ? <>
                                {this.props.data?.totaligstvalue} </> : <>
                                {this.props.data?.vineoAddSaleBillcGSTAmt + this.props.data?.vineoAddSaleBillsGSTAmt}</>}
                            <br />


                            {this.props.data?.vineoAddSalePackagingCharges}
                            <br /> {parseFloat(this.props.data?.vineoAddSaleBillRoundingAmt).toFixed(3)}
                            <br />{this.props.data?.vineoAddSaleBillBottomAmt} - {this.props.data?.vineoPayment?.actualCreditAmountApplied}
<br />{this.props.data?.vineoAddSaleBillBottomAmt - this.props.data?.vineoPayment?.actualCreditAmountApplied}
                        </div>

                    </div>
                    <div id='last-row'>
                        IRN: {this.props.data?.einvoiceIRN}  & EinvAck: {this.props.data?.einvoiceAckNo}</div>
                    <div id='footer'>

                        <span>
                            {this.props.data?.vineoAddSaleBillFirm?.vineofirmtermandconstionsforbill}
                            <br />

                        </span>
                        <span>
                            <br />
                            <br />
                            {this.props.data?.vineoAddSaleBillFirm?.vineoFirmFirmName}</span>
                    </div>
                </div >


            </>
        );
    }
}

class BillprintFormat2 extends React.Component {

    constructor() {
        super();
        this.state = {
            count: 0
        };

    }

    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => this.componentRef}
                    pageStyle={`
                                @page {
                        margin: 0.5cm 0.5cm;
                    }
                
                `}

                />

                <ComponentToPrint data={this.props.data} ref={el => (this.componentRef = el)} />
            </div>
        );
    }
}

export default BillprintFormat2;