import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';

// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import { faCheck, faCircleCheck, faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASEURL_JWTAUTH, DATEFORMAT, GSTVALIDATEAPIURLWITHKEY, IMAGE_UPLOAD, VINEOFORM_DELETE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDDATA, VINEOFORM_DELETE_SELECTED__UNAPPROVEDACCOUNTCUSTACCNTINFO, VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_BILLINGMANAGER, VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDATA, VINEO_CHECKPARTYCODEVALID_AVAILABLE, VINEO_GETALL_ACCOUNTCUSTACCNTINFO_CHECKMOBILENUMBER_AVAILABLE } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { getVineoAccountCustomerMasterMenu, getVineoAreaData, getVineoCustomerBankMasterData, getVineoGstData } from '../../../redux/features/services/serviceHelper';
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import DatePicker from "react-datepicker";

import {
    FormGroup, Label, Input, Modal,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup
} from 'react-bootstrap';
import CustomerBankInfo from './CustomerBankInfo';
import axios from 'axios';
import AreaMaster from './AreaMaster';
import { stateOptions } from '../../../constants/GstStatewithcode';
import { getVineounapprovedPartydatawithdate } from '../../../redux/features/services/entriesHelperService';
import httpwithmultipartdata from '../../../constants/AxiosConfigWithmultipartdata';

export default function SalesmanPartyAccount() {
    let currentDate = new Date();
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 10));

    const [toDate, setToDate] = useState(new Date());
    const [isLoading, setLoading] = useState(false);


    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch()
    const isvineoaccountcustomerdataLoaded = useSelector((state) => state.vineoData.isvineoaccountcustomerdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineounapprovedpartydatawithdate)
    const isvineoBankdataLoaded = useSelector((state) => state.vineoData.isvineocustomerbankMasterdataLoaded)
    const vineobankdata = useSelector((state) => state.vineoData.vineocustomerbankMasterdata)
    const [vineobankdataLoaded, setVineobankdataLoaded] = useState({});
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const [openingdate, setOpeningdate] = useState(new Date());
    const [loyalityopeningdate, setLoyalitopeningdate] = useState(new Date());
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoCustomerAccountUniqeId": "Working", "vineoCustomerAccountName": "Click Refresh To Load" }])
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [readyToSubmit, setReadyToSubmit] = useState(false)
    const [gstAPIreqClicked, setGstAPIreqClicked] = useState(false)
    const vineoareadata = useSelector((state) => state.vineoData.vineoareadata)
    const [openAddBankAccnt, setOpenAddBankAccnt] = useState(false);
    const [openAddArea, setOpenAddArea] = useState(false);
    const [gstVerified, setGstVerified] = useState(false);
    const [samecodefound, setSamecodefound] = useState(true);
    const [codeEntered, setCodeEntered] = useState();
    const [selectedBank, setSelectedBank] = useState([]);
    const [validpartycode, setValidpartycode] = useState(false);
    const [shopImage, setShopImage] = useState([]);  
    const [disablephotoupload, setDisablephotoupload] = useState(false);  

    const reader = new FileReader()
    const [dobdate, setDobdate] = useState(new Date());

    const { register, handleSubmit, setValue, reset, watch, formState: { errors }, control, getValues } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }
    
    useEffect(() => {

        console.log("Refreshing on state Change " + refreshcount)
        setLoading(false)


    }, [refreshcount]);

    const onBankRefresh = () => {
        dispatch(getVineoCustomerBankMasterData())
    }
    useEffect(() => {
        dispatch(getVineoCustomerBankMasterData())
        dispatch(getVineoAccountCustomerMasterMenu())
        dispatch(getVineoAreaData())
    }, [])
    useEffect(() => {
        console.log("Refresh Count Changed ")
        setDataofvineoTableData(vineodata)
        setTableData(vineodata)
        setVineobankdataLoaded(vineobankdata)

    }, [refreshcount])


    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)


    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }

    const rowStyle = { background: 'white' };

    const getbilldatafromserverwithdate = (reqdata) => {
        if (reqdata.startDate && reqdata.endDate) {
            dispatch(getVineounapprovedPartydatawithdate(reqdata))
        } else {
            notifyError("Please Enter Date for Party Start and End ")
        }
    }


    const getRowStyle = (params) => {
        // console.log("vlaue of ",parseInt(params.data.vineoCustomerAccountcreditDay))
        // console.log(params.data.vineoAddSaleBillDate)

        // console.log("Date Diffrence is ", diffInDays)
        if (false)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'orange' };
        }
        if (false)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'gray' };
        }

    };

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }


    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        editable: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }
    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedRows();
        var selectedRowsString = '';
        var maxToShow = 5;
        console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }



    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [

        { headerName: "PartyCode.", field: "vineoCustomerAccountCode", checkboxSelection: true },
        { headerName: "Party", field: 'vineoCustomerAccountName' },
        { headerName: "LglNM ", field: "vineoCustomerAccount_legalName" },
        { headerName: "GST", field: "vineoCustomerGstNumber" },
        { headerName: "Add", field: 'vineoCustomerAccountaddress' },
        { headerName: "Add", field: 'vineoCustomerAccountaddress2' },
        { headerName: "Pin", field: 'vineoCustomerAccountpincode' },
        { headerName: "Mob", field: 'vineoCustomerAccountmob' },
    ]

    //

    const handleUpdate = () => {
        console.log("handle Update called ")
    }
    const handleDelete = () => {
        console.log("handle Update called ")
    }



    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoCustomerAccountName',
            text: 'Account Name',
            sort: true,
            formatter: rowClickedfunction,
        },
        {
            dataField: 'vineoCustomerAccountCode',
            text: 'Account Code ',
            sort: true
        },
        // {
        //     dataField: 'ultiVineoCustomerAccountUniqeId',
        //     text: 'Unique Id.',
        //     sort: true
        // },

    ];


    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };




    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoCustomerAccountUniqeId", selectedRow.ultiVineoCustomerAccountUniqeId);
            setValue("vineoCustomerAccountName", selectedRow.vineoCustomerAccountName);
            setValue("vineoCustomerAccount_legalName", selectedRow.vineoCustomerAccount_legalName);
            setValue("vineoCustomerAccountCode", selectedRow.vineoCustomerAccountCode);
            setValue("autoPurchaseImportActive", selectedRow.autoPurchaseImportActive);
            setValue("vineoCustomerAccountcontactPerson", selectedRow.vineoCustomerAccountcontactPerson);
            setValue("vineoCustomerAccountmob", selectedRow.vineoCustomerAccountmob);
            setValue("vineoCustomerAccountstate", selectedRow.vineoCustomerAccountstate);
            setValue("vineoCustomerAccountaddress", selectedRow.vineoCustomerAccountaddress);
            setValue("vineoCustomerAccountaddress2", selectedRow.vineoCustomerAccountaddress2);
            setValue("vineoCustomerAccountemail", selectedRow.vineoCustomerAccountemail);
            setValue("vineoCustomerGstNumber", selectedRow.vineoCustomerGstNumber);
            setValue("loyalityProgramType", selectedRow.loyalityProgramType);
            setValue("uniqueidofrefreedClientNameAutoImport", selectedRow.uniqueidofrefreedClientNameAutoImport);
            setValue("vineoCustomerAccountopeningDate", selectedRow.vineoCustomerAccountopeningDate);
            setValue("vineoCustomerAccountTown", selectedRow.vineoCustomerAccountTown);
            setValue("vineoCustomerAccountphoneNumber", selectedRow.vineoCustomerAccountphoneNumber);
            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
            setValue("vineoCustomerAccountType", selectedRow.vineoCustomerAccountType);
            setValue("vineoCustomerAccountType", selectedRow.vineoCustomerAccountType);
            setValue("creditissuedforReceipt", selectedRow.creditissuedforReceipt);
            setValue("vineoBankName", selectedRow?.vineoBankName);
            setValue("vineoBankAccountNumber", selectedRow?.vineoBankAccountNumber);
            setValue("vineoBankifscCode", selectedRow?.vineoBankifscCode);
            setValue("vineoCustomerAccountpincode", selectedRow.vineoCustomerAccountpincode);
            setValue("loyalityProgramType", selectedRow.loyalityProgramType);
            setValue("vineoCustomerAccountcreditDayIntegerValue", selectedRow.vineoCustomerAccountcreditDayIntegerValue);
            setValue("partyGstVerified", selectedRow.partyGstVerified);
            setValue("vineoArea.ultiVineoAreaUniqeId", selectedRow?.vineoArea?.ultiVineoAreaUniqeId);
            setValue("totalpartypendingbalance", selectedRow?.totalpartypendingbalance);
            setValue("gstregAsReversecharge", selectedRow?.gstregAsReversecharge);
            setValue("igstApplicableonIntraState", selectedRow?.igstApplicableonIntraState);
            setValue("shopphotourl", selectedRow?.shopphotourl);
            setValue("longitude", selectedRow?.longitude);
            setValue("latitude", selectedRow?.latitude);
            setValue("address", selectedRow?.address);

            setGstVerified(selectedRow.partyGstVerified)

        }
        console.log("Update Party ")
    }

    const getGSTDetailsfromNumber = (gstnumber) => {
        //call gst to get details

        let pattern = /^([0][1-9]|[1-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
        let result = pattern.test(gstnumber);

        if (result) {
            console.log("gstNumber", gstnumber)
            console.log("result", result)
            setGstAPIreqClicked(true)
            {

                http.post(BASEURL_JWTAUTH + GSTVALIDATEAPIURLWITHKEY + gstnumber).then(
                    (res) => {
                        // console.log(res)
                        // console.log("JSON VAlue is ")
                        // console.log(JSON.stringify(res.data))
                        // console.log((res.data.flag))

                        if (res.data.flag) {

                            console.log(res.data?.data?.tradeNam);
                            setValue("vineoCustomerAccountName", res.data?.data?.tradeNam);
                            setValue("vineoCustomerAccount_legalName", res.data?.data?.lgnm);
                            setValue("vineoCustomerAccountstate", res.data?.data?.pradr?.addr?.stcd.trim());
                            setValue("vineoCustomerAccountaddress", res.data?.data?.pradr?.addr?.flno + res.data?.data?.pradr?.addr?.bno + "," + res.data?.data?.pradr?.addr?.bnm);
                            setValue("vineoCustomerAccountaddress2", res.data?.data?.pradr?.addr?.st + "," + res.data?.data?.pradr?.addr?.loc);
                            setValue("vineoCustomerAccountTown", res.data?.data?.pradr?.addr?.dst.trim());
                            setValue("vineoCustomerAccountpincode", (res.data?.data?.pradr?.addr?.pncd).trim());
                            setGstVerified(true)

                            notify('Success:- Api Details Fetched from API ')
                            setGstAPIreqClicked(true);

                        }
                        else { notifyError('Error While GST Account Info From Server') }
                        //console.log(res.data)
                        setGstAPIreqClicked(false);
                    },
                    (err) => {
                        setGstAPIreqClicked(false)
                        notifyError('Error While Getting Account Info')

                    }
                )
            }
        }
        else {
            setGstAPIreqClicked(false);
            notifyError("GST Number is Incorrect ")
        }




    }

    const checkPartyCodeValid = (partycode) => {
        //call gst to get details

        let pattern = /^\S+$/;
        let result = pattern.test(partycode);

        if (result) {

            let partycodeobject = { 'partcode': partycode }
            console.log("partycode", partycodeobject)

            http.post(BASEURL_JWTAUTH + VINEO_CHECKPARTYCODEVALID_AVAILABLE, partycodeobject).then(
                (res) => {
                    if (res.data.errorCode === '0000') {
                        notify("Congratulations Party Code Available")
                        setValidpartycode(true);
                    }
                    else {
                        notifyError("Party Code already Present")
                        setValidpartycode(false);
                    }
                    // se Code allowed to be used    
                    console.log(res.data)


                },
                (err) => {
                    setValidpartycode(false)
                    notifyError('Error Checking Party Code')
                }
            )
        }
        else {

            notifyError("Selecte Another party Code , Code already Present")
        }

    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Customer Account ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }
    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED__UNAPPROVEDACCOUNTCUSTACCNTINFO, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Customer Account  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoCustomerAccountName)
                }
                else { notifyError("Error While Deleting Account  Or Login as ADMIN") }
            },
            (err) => {
                notifyError("Error While Deleting Account Try Again  Or Login as ADMIN")

            }
        )
    }
    const submitForm = (data) => {
        // console.log(JSON.stringify(data))
        setReadyToSubmit(true)
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Customer Account  Created With Internate Id' + res.data.additionalInfo)
                    reset()
                    setReadyToSubmit(false)
                }
                else { notifyError('Error While Registering Customer Account Info From Server' + res.data.errorMessage) }
                //console.log(res.data)
                setReadyToSubmit(false)
            },
            (err) => {
                notifyError('Error While Registering Customer Account Info'+ err.data.errorMessage)
                setReadyToSubmit(false)
            }
        )
    }

    const getdatafromserverforcompanyareaandsalesman = () => {
        dispatch(getVineoAreaData())

    }
    const getdatafromserver = () => {

        console.log("Called to get Customer Account Data ")

        dispatch(getVineoAccountCustomerMasterMenu())

        if (Object.keys(vineodata).length > 0) {
            isvineoaccountcustomerdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoCustomerAccountUniqeId": "Working", "vineoCustomerAccountName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size > 512 * 1024) {
            // File size exceeds the limit, display an error message to the user
            alert("Please select a file with a maximum size of 500KB.");
            // Optionally, you can reset the file input to clear the selected file
            e.target.value = null;
          } 
        else{
         
            if (selectedFile) {
                const formData = new FormData();
                formData.append('image', selectedFile);
                console.log("uplaoding file to server ");
                http.post(IMAGE_UPLOAD, formData).then(
                    (res) => {
                        console.log(res.data.name)
                        console.log("Response received from upload api ")
                        setValue("shopphotourl", res.data.name);
                        setDisablephotoupload(true);
                    
                    },
                    (err) => {
                        notifyError('Error While Uploading Image to Server ')
                      
                    }
                )
              }
        }
      };

      function convertImageToBytes(imageFile) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
    
          reader.onload = () => {
            resolve(reader.result); // The result will be an ArrayBuffer representing the image as bytes
          };
    
          reader.onerror = () => {
            reject(new Error("Error reading the image file."));
          };
    
          reader.readAsArrayBuffer(imageFile);
        });
      }  


      const checkMobileNumber = (mobilenumber) => {
        if (mobilenumber && mobilenumber.trim().length > 9) {
            // Perform the HTTP call only if the mobile number is not empty
            http.post(BASEURL_JWTAUTH + VINEO_GETALL_ACCOUNTCUSTACCNTINFO_CHECKMOBILENUMBER_AVAILABLE + "/" + mobilenumber).then(
                (res) => {
                    console.log(res);
                    if (res.data.errorCode === "0000") {
                        notify('Success: Mobile Number is Available');
                    } else {
                        notifyError("Invalid Mobile Number");
                    }
                },
                (err) => {
                    notifyError("Error -- Try Again or Login as ADMIN");
                }
            );
        } else {
            // Handle the case where the mobile number is empty or has length 0
            notifyError("Invalid Mobile Number: Mobile number is empty or length is not 10 digit");
        }
    };

    return (
        <div>

            <h3 className='justify-content-center m-1'>Customer Account Information - Billing Manager Approval Required </h3>
            <div className="row justify-content-left ">

                <Col md={3}>
                    <ToolkitProvider keyField='ultiVineoCustomerAccountUniqeId'
                        data={dataofvineoTableData ? dataofvineoTableData : []}
                        columns={columns}
                        search   >
                        {
                            props => (
                                <div>
                                    <div >
                                        <Button variant="primary"
                                            onClick={() => { setLoading(true); getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) }) }} >
                                            <FontAwesomeIcon icon={faRotate} />
                                        </Button>
                                        <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                        <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                        <SearchBar className="m-1" {...props.searchProps} />
                                    </div>
                                        <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                        {...props.baseProps}
                                        pagination={paginationFactory(options)}
                                        selectRow={selectRow}
                                    // rowEvents={ rowEvents }
                                    />

                                </div>
                            )
                        }
                    </ToolkitProvider>


                    <Row>


                        <Row>

                            <FormGroup className="m-2">
                                <Form.Text >
                                    Party From Date
                                </Form.Text>
                                <Form.Text onClick={() => {
                                    setFromdate(currentDate.setDate(currentDate.getDate() - 3)); console.log("Date selected is ", fromdate)
                                }} > -3Day</Form.Text>
                                <Form.Text onClick={() => {
                                    setFromdate(currentDate.setDate(currentDate.getDate() - 60))
                                }} > -60Day</Form.Text>
                                <Form.Text onClick={() => {
                                    setFromdate(currentDate.setDate(currentDate.getDate() - 365))
                                }} > -1Year</Form.Text>

                                <DatePicker className="form-control"
                                    selected={fromdate}
                                    onChange={setFromdate}
                                    name="vineoCreateLoadBillFromDate"
                                    dateFormat="dd-MM-yyyy"
                                    ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
                                />
                            </FormGroup>
                        </Row>

                        <Row >
                            <FormGroup className="m-2">
                                <Form.Text >
                                    Party To Date
                                </Form.Text>

                                <DatePicker className="form-control"
                                    selected={toDate}
                                    onChange={setToDate}
                                    name="vineoCreateLoadBillToDate"
                                    dateFormat="dd-MM-yyyy"
                                    ref={setValue("vineoCreateLoadBillToDate", moment(toDate).format(DATEFORMAT))}
                                />
                            </FormGroup>
                        </Row>
                        <Row>
                            <Button className="btn btn-primary m-2" disabled={isLoading} onClick={() => { setLoading(true); getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) }) }}>
                                <FontAwesomeIcon icon={faRotate} />
                                &nbsp;Load Party
                            </Button>

                        </Row>



                    </Row>
                </Col>



                <Col md={9}>
                    <div >
                        <Card className="p-4">
                            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                <div>
                                    <input className="btn btn-primary" disabled={readyToSubmit} type="submit" value="Save" />
                                    <button type="button" onClick={() => reset()} className="btn btn-secondary m-2"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                </div>
                                <Row>
                                    
                                    {setValue("partyGstVerified", gstVerified)}
                                    <Col md={4}>
                                        <FormGroup >
                                            <Form.Text className={(isEditDataSelected && selectedRow?.partyGstVerified) ? "text-primary" : "text-muted"}>
                                                GstNumber {((isEditDataSelected && selectedRow?.partyGstVerified) || gstVerified) ? "Verified" : "Unverified"}
                                            </Form.Text>
                                            <InputGroup>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoCustomerGstNumber")} />
                                                <Button variant="info" disabled={gstAPIreqClicked}
                                                    onClick={() => { setGstAPIreqClicked(true); getGSTDetailsfromNumber(watch('vineoCustomerGstNumber')) }} >
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Form.Text >
                                                Opening Date
                                            </Form.Text>

                                            <DatePicker className="form-control"
                                                selected={openingdate}
                                                onChange={setOpeningdate}
                                                name="CustomerOpejing Date"
                                                dateFormat="dd-MM-yyyy"

                                            />
                                            {setValue("vineoCustomerAccountopeningDate", moment(openingdate).format(DATEFORMAT))}
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Form.Text>Account ID</Form.Text>
                                            <fieldset disabled>

                                                <Form.Control className="form-control "
                                                    placeholder="Account ID: This Code Will Be Auto Generated"
                                                    {...register("ultiVineoCustomerAccountUniqeId")} />
                                            </fieldset>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Form.Text >
                                                DOB Date
                                            </Form.Text>

                                            <DatePicker className="form-control"
                                                selected={dobdate}
                                                onChange={setDobdate}
                                                name="Cust DOB Date"
                                                dateFormat="dd-MM-yyyy"

                                            />
                                            {setValue("vineoCustomerAccountdateOfBirth", moment(dobdate).format(DATEFORMAT))}
                                        </FormGroup>
                                    </Col>


                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text >
                                                Account Name*
                                            </Form.Text>
                                            <Form.Control className="form-control  custom-form-field" size='sm'   {...register("vineoCustomerAccountName")} required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                10 Digit Mobile Number*
                                            </Form.Text>

                                            <InputGroup>
                                                <Form.Control className="form-control  custom-form-field" size='sm'  {...register("vineoCustomerAccountmob", { minLength: 10, maxLength: 10 })} required />
                                                {errors.mobilenumber && <span>Enter Mobile Number as 10  digit </span>}<Button variant="info" 
                                                    onClick={() => { checkMobileNumber(watch('vineoCustomerAccountmob')) }} >
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </Button></InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text >
                                                Account Legal Name*
                                            </Form.Text>
                                            <Form.Control defaultValue={watch('vineoCustomerAccount_legalName')} className="form-control  custom-form-field" size='sm'   {...register("vineoCustomerAccount_legalName")} required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Area Name -{selectedRow?.vineoArea?.vineoAreaName}
                                            </Form.Text>

                                            <InputGroup className="mb-3">

                                                <Form.Control
                                                    className="form-control custom-form-field"
                                                    as="select"
                                                    validated="true"
                                                    onChangeCapture={e => {
                                                        console.log("ultiVineoAreaUniqeId ", e.target.value);
                                                        setValue("vineoArea.ultiVineoAreaUniqeId", e.target.value);
                                                    }}

                                                >
                                                    <option>Select Option</option>
                                                    return (
                                                    <>
                                                        {(vineoareadata) ? (
                                                            vineoareadata?.map((vineoareadata) =>

                                                                <option key={vineoareadata?.ultiVineoAreaUniqeId} value={vineoareadata?.ultiVineoAreaUniqeId}>{vineoareadata?.vineoAreaName}</option>
                                                            )
                                                        ) : (

                                                            console.log("Click Refresh to load Data ")

                                                        )}
                                                    </>
                                                    );

                                                </Form.Control>

                                                <Button variant="primary"
                                                    onClick={getdatafromserverforcompanyareaandsalesman} >
                                                    <FontAwesomeIcon icon={faRotate} />
                                                </Button>
                                                <Button variant="primary"
                                                    onClick={() => { setOpenAddArea(true) }}>
                                                    +
                                                </Button>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>



                                    <Col md={6}>
                                        <FormGroup >

                                            <Form.Text >
                                                Address
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoCustomerAccountaddress")} />

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text >
                                                Town/City
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field " size='sm'   {...register("vineoCustomerAccountTown")} />
                                        </FormGroup>
                                    </Col>


                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text >
                                                State
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field " size='sm'   {...register("vineoCustomerAccountstate")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text>
                                                Pin Code
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field " type="number" size='sm'  {...register("vineoCustomerAccountpincode")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Email ID
                                            </Form.Text>
                                            {/* , { pattern: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$' } */}
                                            <Form.Control type="email" className="form-controll custom-form-field" size='sm'   {...register("vineoCustomerAccountemail")} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text>
                                                Tele Phone Number
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" size='sm'   {...register("vineoCustomerAccountphoneNumber")} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>

                                    <Col md={3} >
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Account type
                                            </Form.Text>
                                            <Form.Select className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountType")}>
                                                <option value="RETAIL4_WHEELER_AUTOSHOP"> RETAIL4 WHEELER AUTO SHOP</option>
                                                <option value="IWS_2WHEELER">IWS 2 WHEELER</option>
                                                <option value="RETAIL_CV">RETAIL CV</option>
                                                <option value="RETAIL2WHEELER_AUTOSHOP">RETAIL 2 WHEELER AUTO SHOP</option>
                                                <option value="IWS4_WHEELER">IWS4_WHEELER</option>
                                                <option value="FLEET_CUSTOMER">FLEET CUSTOMER</option>
                                                <option value="MULTILUBE_SHOP">MULTILUBE SHOP</option>
                                                <option value="TRANSPORTER">TRANSPORTER</option>
                                                <option value="MARINE">MARINE</option>
                                                <option value="OTHERS">OTHERS</option>
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup >
                                            <Form.Text >
                                                AutoImport ID
                                            </Form.Text>
                                            <Form.Control disabled={true} className="form-control  custom-form-field" defaultValue={selectedRow?.uniqueidofrefreedClientNameAutoImport} size='sm' />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup >

                                            <Form.Text className="text-muted">
                                                Bank
                                            </Form.Text>
                                            <Row>
                                                    <Col md ={3}><Form.Control className="form-control custom-form-field" placeholder='Bank Name' size='sm' {...register("vineoBankName")}  /></Col>
                                                    <Col md={3}><Form.Control className="form-control custom-form-field" placeholder='ifsc Code' size='sm' {...register("vineoBankifscCode")}  /></Col>
                                                    <Col>    <Form.Control className="form-control custom-form-field" placeholder='Accnt Number' size='sm' {...register("vineoBankAccountNumber")}  /></Col>
                                                   </Row>
                                            

                                        </FormGroup></Col>


                                    <Col md={3} >
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                AutoPurcahse Active
                                            </Form.Text>
                                            <Form.Select disabled={(!isEditDataSelected)} className="form-control custom-form-field" size='sm'  {...register("autoPurchaseImportActive")}>
                                                <option value={true}>YES</option>
                                                <option value={false}>No</option>

                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Credit Payment Applicable 
                                            </Form.Text>
                                            <Form.Select className="form- custom-form-field " size='sm'  {...register("creditPaymentApplicable")}>
                                                <option value="false">NO</option>
                                                {/* <option value="true">YES</option> */}
                                                
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>

                                    <Col >
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Loyality Account Type
                                            </Form.Text>
                                            {/* <Form.Select disabled={true} className="form- custom-form-field " size='sm'  {...register("loyalityProgramType")}>
                                                <option value="BRONZE">BRONZE</option>
                                                <option value="SILVER">SILVER</option>
                                                <option value="GOLD">GOLD</option>
                                                <option value="STAR">STAR</option>
                                            </Form.Select> */}
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Opening Bal.
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field " defaultValue="0.00" size='sm'   {...register("vineoCustomerAccountopeningbalance")} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text >
                                                Opening Date
                                            </Form.Text>

                                            <DatePicker className="form-control"
                                                selected={loyalityopeningdate}
                                                onChange={setLoyalitopeningdate}
                                                name="CustomerOpejing Date"
                                                dateFormat="dd-MM-yyyy"

                                            />

                                            {setValue("loyalityProgramOpeningDate", moment(loyalityopeningdate).format(DATEFORMAT))}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                GST AS Rev. Charge
                                            </Form.Text>
                                            <Form.Select className="form-control custom-form-field" size='sm' {...register("gstregAsReversecharge")}  >
                                                <option value="N">NO</option>
                                                <option value="Y">YES</option>
                                            </Form.Select>

                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                igstApplicableonIntraState
                                            </Form.Text>
                                            <Form.Select className="form-control custom-form-field" size='sm' {...register("igstApplicableonIntraState")}  >
                                                <option value="N">NO</option>
                                                <option value="Y">YES</option>
                                            </Form.Select>

                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Rupess
                                            </Form.Text>
                                            <Form.Select className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountrupess")}>
                                                <option value="Dr1">Dr1</option>
                                                <option value="Dr2">Dr2</option>
                                                <option value="Dr3">Dr3</option>
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Credit Day
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field " size='sm' defaultValue="45"  {...register("vineoCustomerAccountcreditDayIntegerValue")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Credit Bill
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field " type="number" step="0.01" defaultValue="0.00" size='sm'  {...register("vineoCustomerAccountcreditBill")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Pending From Party
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field " type="number" step="0.01" defaultValue="0" size='sm'  {...register("totalpartypendingbalance")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Receipt Credit
                                            </Form.Text>
                                            <Form.Control type="number" className="form-control custom-form-field " defaultValue="0" size='sm'  {...register("creditissuedforReceipt")} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                DL EX 1
                                            </Form.Text>
                                            <DatePicker className="form-control custom-form-field"
                                                selected={openingdate}
                                                onChange={setOpeningdate}
                                                name="CustomerdlEx Date"
                                                dateFormat="dd-MM-yyyy"

                                            />

                                            {setValue("vineoCustomerAccountdlExpOne", moment(openingdate).format(DATEFORMAT))}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Weekly Off
                                            </Form.Text>
                                            <Form.Select className="form-control custom-form-field" size='sm' {...register("vineoCustomerAccountweeklyOff")}>
                                                <option value="vineoCustomerAccountweeklyOff1">NONE</option>
                                                <option value="vineoCustomerAccountweeklyOff2">YES</option>

                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Black Listed
                                            </Form.Text>
                                            <Form.Select className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountblackListed")}>
                                                <option value="No">NO</option>
                                                <option value="YES">YES</option>

                                            </Form.Select>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Dist. Km
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" defaultValue="0.00" size='sm'  {...register("vineoCustomerAccountdistKm")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Close Time
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountcloseTime")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Seq No
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" defaultValue="0.00" size='sm'  {...register("vineoCustomerAccountseqNumber")} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Contact Person
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field " size='sm'  {...register("vineoCustomerAccountcontactPerson")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Common Name
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountcommonName")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Common Code
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountcommonCode")} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted" >
                                                Shop Photo URL
                                            </Form.Text>
                                            <Form.Control readOnly className="form-control custom-form-field" size='sm'  {...register("shopphotourl")} />
                                        </FormGroup>
                                    </Col>
                                    <Col > 
                                        <Form.Group controlId="formFileSm" className="m-1">
                                        <Form.Control disabled={disablephotoupload} type="file" size="sm" onChange={(e) => {handleImageChange(e)}}/>
                                        </Form.Group>
                                        
                                    </Col>
                                  
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Longitude
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" size='sm'  {...register("longitude")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Latitude
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" size='sm'  {...register("latitude")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Address
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" size='sm'  {...register("address")} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <div>
                                    <input className="btn btn-primary m-2" disabled={readyToSubmit} type="submit" value="Save" />
                                    <button type="button" onClick={() => reset()} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                </div>
                            </form>
                        </Card>
                    </div>

                </Col>

            </div>

            <Button className="btn btn-primary mt-5" onClick={onExportClick} value="ExportToCsv">Export To CSV
            </Button>
            <div className="ag-theme-alpine" style={{ height: '400px' }}>
                <div className="ag-theme-alpine" style={{ height: '700px' }}>
                    <AgGridReact
                        id="AgGridforPartyData"
                        rowData={tableData ? tableData : []}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        // onRowSelected={onSelectBill}
                        rowSelection="multiple"
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                    />

                </div>
            </div>
            <div>
                <Modal show={openAddBankAccnt}
                    onHide={() => { setOpenAddBankAccnt(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <CustomerBankInfo />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { setOpenAddBankAccnt(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={openAddArea}
                    onHide={() => { setOpenAddArea(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <AreaMaster />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { setOpenAddBankAccnt(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div>
        </div>
    )

}