import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import QRCode from 'qrcode.react';
var converter = require('number-to-words');

class ComponentToPrint extends React.Component {
    render() {
        return (
            <div className="">
                <strong>Debit Note- Nar. {this.props.data?.debitnotenarr}</strong>
                <div id='first-row'>
                    <div className='col'>
                        Debit Note Number <strong> {this.props.data?.debitNoteNumber}</strong>
                        <br />
                        Debit Note NumberWithPrefix = {this.props.data?.debitNoteNumberwithprefix}
                        <br />
                        Debit Note Date = {this.props.data?.vineoDebitNoteDate}
                        <br />
                        Issued On Bill Number  = {this.props.data?.debitNoteIssuedonPurchaseBillNumberwithprefix}
                        <br />
                        Applied On Bill Number = {this.props.data?.debitNoteAppliedonPurchaseBillNumberwithPrefix}
                    </div>

                    <div className='col'>
                        Customer Details:
                        {this.props.data?.firmName}
                        <br />
                        Address: {this.props.data?.firmAddress}
                        <br />
                        Pincode:{this.props.data?.firmAddresspincode}
                        <br />
                        city: {this.props.data?.firmAddressCity}
                        <br />
                        Contact NO: {this.props.data?.firmPhone}<br />
                        GST No - {this.props.data?.firmGSTNumber}<br />
                    </div>
                    <div className='col'>
                        Vendor Details: {this.props.data?.vineoVendorAccountName}<br />
                        Address: {this.props.data?.vineoVendorAccountAddress}<br />
                        City: {this.props.data?.vineoVendorAccountCity}<br />
                        Contact No : {this.props.data?.vineoVendorAccountPhone}<br />
                        GST No :{this.props.data?.vineoVendorAccountGSTNumber}<br />
                        Ship To:- {this.props.data?.shipingAddress}<br />

                    </div>
                </div>







                <table id='main-table' >
                    <thead>
                        <tr>
                            <th rowSpan='2'></th>
                            <th rowSpan='2'>Item Name</th>
                            <th rowSpan='2'>HSNCode</th>
                            <th rowSpan='2'>Batch</th>
                            <th rowSpan='2'>Unit</th>
                            <th rowSpan='2'>free</th>
                            <th rowSpan='2'>Damag RET</th>
                            <th rowSpan='2'>Slbl RET</th>
                            <th rowSpan='2'>Rate</th>
                            <th rowSpan='2'>%</th>
                            {this.props.data?.igstDebitNote ?<th> </th>:
                            <th colSpan='2'>CGST%</th>}
                              {this.props.data?.igstDebitNote ?<></>:
                            <th colSpan='2'>SGST</th>}
                            <th rowSpan='2'>Gross</th>
                            <th rowSpan='2'>Net</th>
                        </tr>
                        {this.props.data?.igstDebitNote ? <th>IGST</th> : <tr>
                            <th scope='col'>CGST</th>
                            <th scope='col'>CGST%</th>
                            <th scope='col'>SGST</th>
                            <th scope='col'>SGST%</th>
                        </tr>}

                    </thead>
                    <tbody >
                        {this.props.data?.vineoDebitNoteitemsList.map((items, index) => {
                            // console.log(items);
                            return (
                                <>
                                    {(items?.totalitemamount > 0) ? <tr>
                                        <td >{index + 1}</td>
                                        <td >{items?.productName} </td>
                                        <td >{items?.vineoAddPurchaseItem?.vineoProduct?.vineoProducthsnCod} </td>
                                        <td >{items?.vineoAddPurchaseItem?.vineoBatch?.vineoBatchNumber}</td>
                                        <td >{items?.vineoAddPurchaseItem?.vineoPurchaselItemUnitDuringeditBill}</td>
                                        <td >{items?.vineoAddPurchaseItem?.vineoAddPurchaseItemFreeProductQty}</td>
                                        <td >{items?.damagedproductreturnqtyinpcs}</td>
                                        <td >{items?.sellableproductreturnqtyinpcs}</td>
                                        <td >{items?.itemeffectiveratefrombill}</td>
                                        <td >{(parseInt(items?.totalitemSgstpercentage) + parseInt(items?.totalitemCgstpercentage))}</td>
                                        {this.props.data?.igstDebitNote ? 
                                        <td>{items?.totalitemIgst}</td> :

                                            <> <td >{items?.totalitemCgst}</td>
                                                <td>{items?.totalitemCgstpercentage}</td>
                                                <td >{items?.totalitemSgst}</td>
                                                <td>{items?.totalitemSgstpercentage}</td></>
                                        }

                                        <td >{items?.totalitemamount}</td>
                                        <td >{items?.totaliteNetAmount}</td>

                                    </tr> : <></>}

                                </>
                            )
                        })}
                    </tbody>
                </table>
                <div id='totals'>
                    <span>{converter.toWords(this.props.data?.debitnoteAmount).toUpperCase()}</span>
                    <span>GST = {this.props.data?.totalGst}</span>


                    
                    {this.props.data?.igstDebitNote ? <> <span>iGST = {this.props.data?.totalIgst}</span></>
                        : <> <span>cGST = {this.props.data?.totalCgst}</span>
                            <span>sGST = {this.props.data?.totalSgst}</span></>}

                    <span>Rounding = {this.props.data?.roundingOff}</span>
                </div>
                <div id='last-row'>

                    <div>
                        {/* <img src={`data:image/svg+xml;base64,${btoa(this.props.data?.ewaysignedQRCode)}`}  /> */}
                        {this.props.data?.ewaysignedQRCode ? <QRCode value={this.props.data?.ewaysignedQRCode} size={256}
                            level={'L'}
                            includeMargin={true} /> : <></>}
                    </div>
                    <div>
                        * GST Summary*
                        <br />
                        <table>
                            <thead>
                                <tr>
                                    {this.props.data?.igstDebitNote ? <> <th>iGST AMT</th></> : <>
                                        <th>cGST  </th>
                                        <th>sGST  </th>
                                        <th>GST AMT</th>
                                    </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {this.props.data?.igstDebitNote ? <> <th>{this.props.data?.totalIgst}</th></> : <>
                                        <th>{this.props.data?.totalCgst}  </th>
                                        <th>{this.props.data?.totalSgst}  </th>
                                        <th>{this.props.data?.totalGst} </th>
                                    </>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        Total debit Note Amount Used : {this.props.data?.debitnoteamountusedup}
                        <br />
                        Is debit Note from Loyality : {this.props.data?.isdebitnotefromloyality ? <>YES</> : <>NO</>}
                        <br />

                        <br />

                    </div>


                    <div>
                        Adjust Amt
                        <br />
                        Adjust Amt Extra
                        <br />
                        Total GST
                        <br />
                        Packaging
                        <br />
                        Rounding
                        <br />
                        debit Amt
                    </div>
                    <div>
                        {this.props.data?.adjustamount}
                        <br />
                        {this.props.data?.adjustamountExtra}
                        <br />
                        {(this.props.data?.igstDebitNote > 0) ? <>
                            {this.props.data?.totalIgst} </> : <>
                            {this.props.data?.totalCgst + this.props.data?.totalSgst}</>}
                        <br />


                        {this.props.data?.vineoAddSalePackagingCharges}
                        <br /> {this.props.data?.roundingOff}
                        <br />{this.props.data?.debitnoteAmount}

                    </div>

                </div>
                <div id='last-row'>
                    IRN: {this.props.data?.einvoiceIRN}  & EinvAck: {this.props.data?.einvoiceAckNo}</div>


                <div id='footer'>

                    <span>
                        {this.props.data?.vineoAddSaleBillFirm?.vineofirmtermandconstionsforbill}
                        <br />

                    </span>
                    <span>
                        <br />
                        <br />
                        {this.props.data?.firmName}</span>
                </div>
            </div>
        );
    }
}

class DebitNoteprint extends React.Component {

    constructor() {
        super();
        this.state = {
            count: 0
        };

    }

    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => this.componentRef}
                    pageStyle={`
                    @page {
            margin: 0.5cm 0.5cm;
        }
    
    `}
                />

                <ComponentToPrint data={this.props.data} ref={el => (this.componentRef = el)} />
            </div>
        );
    }
}

export default DebitNoteprint;