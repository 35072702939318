import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../constants/AuthUser';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem
} from 'react-bootstrap';

export default function TemplateHookForm() {



    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => 
    {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    const { http, setToken } = AuthUser();

    const [formDetails, setformDetails] = useState({});
  
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

 
    useEffect(() => {
        setformDetails({ ...formDetails, gender: "F", enabled: "true", accountLocked: "false", role: "admin" })
        console.log(formDetails)
    }, []);



    const submitForm = (data) => {
        // api call
        http.post('/salesman/billingManager/admin', data).then(
            (res) => {
                if (res.data.id) {
                    notify('User Created with Internal Id')
                    navigate('/login')
                }
                else
                    notify("Error While registering User try with another username or Use Purchase key as test")
                //  console.log(res.data)

            },
            (err) => {


                notify("Error While registering User try with another username or Use Purchase key as test")
            }
        )
    }


    return (
        <div>
            <h2>Add Firm Information </h2>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ListGroup>
                            <ListGroupItem>
                                <Row>
                                    <Col className='block-example border-left border-dark'>
                                        First
                                    </Col>
                                    <Col>
                                        Second
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                Dapibus ac facilisis in
                            </ListGroupItem>
                            <ListGroupItem>
                                Morbi leo risus
                            </ListGroupItem>
                            <ListGroupItem>
                                Porta ac consectetur ac
                            </ListGroupItem>
                            <ListGroupItem>
                                Vestibulum at eros
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col md={8}>
                        <div className="card p-3">Hi this is react form
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                <fieldset disabled>
                                        <Form.Control className="form-control" placeholder="Firm Code: This Filed Will Be  Auto Generated"  {...register("firstName")} required />
                                   </fieldset>
                                </FormGroup>
                                <FormGroup>
                                <Form.Control className="form-control" placeholder="Enter email"  {...register("firstName")} required />
                                  
                                </FormGroup>
                                <input className="btn btn-primary" type="submit" />
                            </form>
                        </div>

                    </Col>
                </Row>

            </div>
        </div>
    )

}
