import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { getVineoFirmData, getVineoGodownData, getVineoVanMasterData } from '../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEO_IMPORT_BILLS_FROM_CSV_FILE, VINEO_IMPORT_PARTY_FROM_CSV_FILE } from '../../constants/Urlforvineo';
import { Typeahead } from 'react-bootstrap-typeahead';
import http from '../../constants/AxiosConfig';

export default function ImportBillIntoSystem() {
    useEffect(() => {
        dispatch(getVineoFirmData())
    }, []);
    const dispatch = useDispatch();
    const [string, setString] = useState('');
    const [file, setFile] = useState(null);
    const [selectedFirm, setSelectedFirm] = useState([])
    const [updateEntity, setUpdateEntity] = useState(false)
    const [paymentpaid, setPaymentpaid] = useState(false);
    const [batchconfirm, setBatchconfirm] = useState(false);
    const [generateReceipt, setGenerateReceipt] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);

    const [message, setMessage] = useState('');
    const [selectedGodown, setSelectedGodown] = useState([])
    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {

        console.log(data.vineofile[0]);
        // submitForm(data.vineofile[0])
    }
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const vineoGodowndata = useSelector((state) => state.vineoData.vineogodowndata)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)

    useEffect(() => {

    }, [refreshcount]);
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)


    const handleFileChange = (e) => {
        console.log("FIles Selected")
        setFile(e.target.files[0]);
    }

    const handleUpload = (e) => {

        setIsLoaded(true);

        e.preventDefault();
        const formData = new FormData();
        formData.append('vineofile', file);
        if (!selectedFirm?.[0]?.ultiVineoFirmUniqeId) {
            toast("Please Select Firm ");
        }
        formData.append('vineoFirm', (selectedFirm?.[0]?.ultiVineoFirmUniqeId))
        formData.append('updatereferences', (updateEntity))
        formData.append('paymentpaid', (paymentpaid))
        formData.append('batchconfirm', (batchconfirm))
        formData.append('generateReceipt', (generateReceipt))
        console.log("formData", formData)
        console.log("file size ", (file?.size < 4000000))

        if (file?.size < 4000000) {
            http.post(BASEURL_JWTAUTH + VINEO_IMPORT_BILLS_FROM_CSV_FILE, formData)
                .then((res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify(' Success:-  Bills Imported with Invoice ID as ' + res.data.additionalInfo)
                        setIsLoaded(false)
                    }
                    else
                        notifyError("Error While Importing Data  From Server " + res.data.additionalInfo)
                    //  console.log(res.data)
                    setIsLoaded(false)

                },
                    (err) => {
                        console.log(err)

                        notifyError("Error While Importing Data" + err.data)
                        setIsLoaded(false)
                    })
                .catch((err) => {

                    notifyError("Error While Importing Data" + err)
                    setIsLoaded(false)
                });

        }
        else {
            notifyError("File SIze is more than 4 MB Please Select Lower Size File");
            setIsLoaded(false)
        }

    }


    return (
        <div>
            <h3 className='justify-content-center m-1'>Upload CSV File For Bill Import</h3>
            <div className="row justify-content-left ">
                <Row>

                    <Row>
                        <Col md={6}>
                            <div >
                                <Card className='p-4 mt-4'>


                                    <div>
                                        <form onSubmit={handleUpload}>

                                            <Col >
                                                <Form.Text >Select Firm *</Form.Text >
                                                <InputGroup>
                                                    <Typeahead
                                                        id="FirmNameTypehead"
                                                        onChange={setSelectedFirm}
                                                        options={vineofirmdata ? vineofirmdata : []}
                                                        placeholder="Choose Firm Name..."
                                                        selected={selectedFirm}
                                                        labelKey={"vineoFirmFirmName"}
                                                    />
                                                    <Button variant="primary"
                                                        onClick={() => { dispatch(getVineoFirmData()); }} >
                                                        <FontAwesomeIcon icon={faRotate} />
                                                    </Button>
                                                    {console.log(selectedFirm?.[0]?.ultiVineoFirmUniqeId)}
                                                    {/* {setValue("vineoAddPurchaseFirm", selectedFirm?.[0])} */}
                                                </InputGroup>
                                            </Col>
                                            <Row>
                                                <Col >
                                                    <Form.Text>
                                                        PAYMENT PAID- CASH Mode
                                                    </Form.Text>

                                                    <Form.Select className="form-control" onChange={(e) => { setPaymentpaid(e.target.value) }} required>
                                                        <option value="true">Yes</option>
                                                        <option selected={true} value="false">No</option>
                                                    </Form.Select>

                                                </Col>
                                                <Col>
                                                    <Form.Text>
                                                        Batch Constraint
                                                    </Form.Text>

                                                    <Form.Select className="form-control" onChange={(e) => { setBatchconfirm(e.target.value) }} required>
                                                        <option value="true">Yes</option>
                                                        <option selected={true} value="false">No</option>
                                                    </Form.Select>

                                                </Col>
                                                {paymentpaid ? <Col>
                                                    <Form.Text>
                                                        Genereate Receipt
                                                    </Form.Text>

                                                    <Form.Select className="form-control" onChange={(e) => { setGenerateReceipt(e.target.value) }} required>
                                                        <option value="true">Yes</option>
                                                        <option selected={true} value="false">No</option>
                                                    </Form.Select>

                                                </Col> : <></>}
                                            </Row>

                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>Select CSV File AS per Format * </Form.Label>
                                                <Form.Control type="file" onChange={handleFileChange} />
                                            </Form.Group>
                                            <Form.Group controlId="UpdateOtherEntityt" className="mb-3">
                                                <Form.Check
                                                    onChange={(e) => setUpdateEntity(!updateEntity)}
                                                    label='Update Reference'
                                                    id="Checkbox"
                                                />
                                            </Form.Group>

                                            {/* <input type="file" onChange={handleFileChange} /> */}
                                            <Button disabled={isLoaded} type="submit">  {isLoaded ? 'Uploading' : 'Upload'}</Button>
                                        </form>
                                        <p>{message}</p>
                                    </div>

                                </Card>
                            </div>


                        </Col>
                    </Row>

                </Row>

            </div>
        </div>
    )

}