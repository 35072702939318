import React from "react";
import {useCallback, useEffect, useState } from "react";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup } from "react-bootstrap";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from 'react-redux'
import { getVineoAccountVendorCustomerMasterMenu, getVineoGodownData, getVineoCompanyData, getVineoProductData, getVineoSchemaMasterData, getVineoFirmData } from "../../../../redux/features/services/serviceHelper";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH } from "../../../../constants/Urlforvineo";
import http from "../../../../constants/AxiosConfig";
import moment from "moment";
import { getVineoBatchData } from "../../../../redux/features/services/entriesHelperService";
import VendorToProduct from "../../mapping/VendorToProduct";
import EditBatch from "./EditBatch";
export default function AddBatch() {
    useEffect(() => {
        dispatch(getVineoAccountVendorCustomerMasterMenu());
        dispatch(getVineoGodownData());
        dispatch(getVineoSchemaMasterData());
        dispatch(getVineoFirmData())
      
    }, []);

    const dispatch = useDispatch()
    const vineovendordata = useSelector((state) => state.vineoData.vineoaccountvendorcustomerdata)
    const isvineovendordataLoaded = useSelector((state) => state.vineoData.isvineoaccountvendorcustomerdataLoaded)
    const vineoGodowndata = useSelector((state) => state.vineoData.vineogodowndata)
    const vineobatchData = useSelector((state) => state.vineoData.vineobatchdata)
    const isvineobatchdataloaded = useSelector((state) => state.vineoData.isvineobatchdataLoaded)
    const vineoproductdata = useSelector((state) => state.vineoData.vineoproductdata)
    const vineoschemedata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [openMapVendortoProduct, setOpenMapVendortoProduct] = useState(false);
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const [isLoading, setLoading] = useState(false);
    const ref = React.createRef();


    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const { control, register, handleSubmit, setValue, reset, watch } = useForm();
    const [vdate, setVdate] = useState();
    const [allRateIncludingGST, setAllRateIncludingGST] = useState(true)
    const [mfgdate, setMfgdate] = useState();
    const [expdate, setExpdate] = useState();
    const [selected, setSelected] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [selectedGodown, setSelectedGodown] = useState([])
    const [selectedScheme, setSelectedScheme] = useState([])
    const [updatedProductBatchQty, setUpdatedProductBatchQty] = useState()
    const [openEditBatch, setOpenEditBatch] = useState(false)
    const [dataforeditbatch, setDataforeditbatch] = useState({})
    


    const loaddatafromserver = () => {

        dispatch(getVineoAccountVendorCustomerMasterMenu())
        dispatch(getVineoBatchData())
        isvineobatchdataloaded ? setTableData(vineobatchData) : console.log("Empty data from Server for Batch")

    }

    useEffect(() => {

        console.log("Refreshing on state Change " + refreshcount)
        setLoading(false)
        setTableData(vineobatchData)
    }, [isvineobatchdataloaded, vineobatchData, refreshcount]);
    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)


    const [tableData, setTableData] = useState(vineobatchData)

    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }



    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);
   


    }

    const defaultColDef = {
        editable: true,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
        floatingFilter: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }



    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }

   
    const columnDefs = [
        { headerName: "BatchName", field: "vineoBatchName",resizable: true },
        { headerName: "BatchNo ", field: "vineoBatchNumber",resizable: true },
        { headerName: "Product", field: "vineoproductNameinBatch",resizable: true },
        { headerName: "Used", field: 'batchisUsedinAddStock',resizable: true },
        { headerName: "PurcahseRate", field: "vineoBatchPurchaseRateperPcs",resizable: true },
        { headerName: "SaleRate", field: "vineoBatchProductSaleRateperPcs",resizable: true },
        // { headerName: "Qty", field: "vineoBatchProductQty" },
        {
            headerName: "QtyBox",
            editable: true,
            valueGetter: params => {

                return params.data.vineoBatchProductQty;
            }
            ,resizable: true},
        { headerName: "PCSQty", field: 'vineoBatchProductQtyinPCS', editable: true,resizable: true },
        { headerName: "Damg", field: 'vineoDamagedProductQtyPCS', editable: true,resizable: true },
        { headerName: "SlbRET", field: 'creditnoteqtyPCS', editable: true,resizable: true },
        { headerName: "PurRet", field: 'debitnoteqtyPCS', editable: true,resizable: true },

        { headerName: "free", field: 'vineoBatchFreeProductQty' , editable: true,resizable: true},

        { headerName: "MRP", field: 'vineoBatchProductMRPperPcs' ,resizable: true},
        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
            </div>, editable: false,resizable: true
        },
        { headerName: "Scheme", valueGetter:  'data.vineoScheme[0]?.vineoSchemeName',resizable: true },
        { headerName: "IsSchAct", field: 'schemeisActive' },

        // need to laod schem from DB 
        { headerName: "Godown", valueGetter: 'data.vineoGoDown.vineoGoDownName' }


     
    ]


    const onCellValueChanged = useCallback((event) => {
        console.log(
          'onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue
        );
      }, []);

    const onRowValueChanged = useCallback((event) => {
        var data = event.data;
        console.log(data);
    }, []);

    const handleUpdate = (data) => {
        console.log(data)

        console.log("This will editqty by this amount " + updatedProductBatchQty);
        setOpenEditBatch(true);
        setDataforeditbatch(data);
        

    }
    //deleting a user

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Batch  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoBatchName)

                }
                else { notifyError("Error While Deleting Batch  Or Login as ADMIN") }
            },
            (err) => {
                notifyError("Error While Deleting Batch Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    // Ag grid Work Start 

    const onSubmit = (data) => {


        if (selectedGodown?.[0]) {

            if (selected?.[0]) {
                console.log(data)
                submitForm(data)
            }
            else {
                notifyError("Please Select Vendor")
            }
        }
        else {
            notifyError("Please Select Godown  ")
        }

    }


    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Batch Created With Internal Id')
                    dispatch(getVineoAccountVendorCustomerMasterMenu())
                    reset();

                }
                else { notifyError('Error While Registering  Batch  Info From Server') }
                //console.log(res.data)
            },
            (err) => {
                notifyError('Error While Registering  Batch  Info')
            }
        )
    }

    return (

        <div>
               <div className='h5 mb-0 text-gray-800'>
                <center>
                    <h5 className='justify-content-center m-1'>Add Batch for Particular Product </h5>
                </center>
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card className='p-4'>

                        <Row>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text >
                                        VDate
                                    </Form.Text>
                                    {/* <Form.Control  type="date"  required /> */}
                                    <DatePicker className="form-control"
                                        selected={vdate}
                                        onChange={setVdate}
                                        name="vineoBatchVoucherDate"
                                        dateFormat="dd-MM-yyyy"

                                    />
                                </FormGroup>

                                {setValue("vineoBatchSet.vineoBatchVoucherDate", moment(vdate).format(DATEFORMAT))}

                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text>
                                        V No
                                    </Form.Text>
                                    <Form.Control className="form-control" {...register("vineoBatchSet.vineoBatchVoucherNumber")} required />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <Form.Text>
                                    Vendor Name
                                </Form.Text>
                                <InputGroup>

                                    <Typeahead
                                        id="VendorNameTypehead"
                                        onChange={setSelected}
                                        // multiple Will provide functionlaity in later release
                                        // options={vineovendordata? vineovendordata : [{"vineoVendorAccountName":"VendorAccount1"}]}
                                        options={vineovendordata ? vineovendordata : [{ "vineoVendorAccountName": "Loading" }]}
                                        placeholder="Choose Vendor Name..."
                                        selected={selected}
                                        labelKey={'vineoVendorAccountName'}
                                    />
                                    <Button variant="primary"
                                        onClick={() => { dispatch(getVineoAccountVendorCustomerMasterMenu()) }} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>
                                {/* {console.log("Vendor data Loaded " + isvineovendordataLoaded)} */}
                                {setValue("vineoVendorAccount", selected[0])}
                            </Col>

                            <Col md={4} >


                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Product
                                    </Form.Text>
                                    <InputGroup>
                                        <Typeahead

                                            disabled={selected[0] ? false : true}
                                            id="VendorProductTypehead"
                                            onChange={setSelectedProduct}
                                            // multiple Will provide functionlaity in later release
                                            options={selected[0]?.vineoProductset ? selected[0]?.vineoProductset : []}
                                            placeholder="Choose Product Received in Batch"
                                            selected={selectedProduct}

                                            labelKey={"vineoProductName"}

                                        />
                                        {/* {setValue("vineoProductset", selectedProduct)} */}

                                        {/* {(selectedProduct?.map((vineoproductfromselected,i) => 

                                        {setValue("vineoBatchSet[i].vineoproductNameinBatch", vineoproductfromselected?.vineoProductName)}

                                   )) } */}
                                        {setValue("vineoBatchSet.vineoProductinBatch", selectedProduct)}                                        {setValue("vineoBatchSet.vineoproductNameinBatch", selectedProduct[0]?.vineoProductName)}

                                        {setValue("vineoBatchSet.productinBatchProductUniqueid", selectedProduct[0]?.ultiVineoProductUniqeId)}
                                        <Button variant="info" onClick={() => { setOpenMapVendortoProduct(true) }}>
                                            +
                                        </Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={2}>
                                <FormGroup>


                                    <Form.Text >
                                        Godown
                                    </Form.Text>
                                    <InputGroup>
                                        <Typeahead

                                            id="VendorGodownTypehead"
                                            onChange={setSelectedGodown}
                                            options={vineoGodowndata ? vineoGodowndata : []}
                                            placeholder="Choose Godown for this Batch"
                                            selected={selectedGodown}
                                            labelKey={"vineoGoDownName"}

                                        />

                                        {setValue("vineoBatchSet.vineoGoDown", selectedGodown[0])}



                                        <Button variant="primary"
                                            onClick={() => { dispatch(getVineoGodownData()) }} >
                                            <FontAwesomeIcon icon={faRotate} />
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={2}>

                            </Col>
                        </Row>

                    </Card>



                    <Card className='p-4'>


                        <Row>


                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Batch Name
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("vineoBatchSet.vineoBatchName")} required />

                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Batch No
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("vineoBatchSet.vineoBatchNumber")} required />

                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Mfg Date
                                    </Form.Text>

                                    <DatePicker className="form-control"
                                        selected={mfgdate}
                                        onChange={setMfgdate}
                                        name="mfgDate"
                                        dateFormat="dd-MM-yyyy"
                                    />
                                    {setValue("vineoBatchSet.vineoBatchMfgDate", moment(mfgdate).format(DATEFORMAT))}
                                    {/* <Form.Control type="date" className="form-control "   {...register("mfgDate")} required /> */}
                                </FormGroup>
                            </Col><Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        EXP Date
                                    </Form.Text>

                                    <DatePicker className="form-control"
                                        selected={expdate}
                                        onChange={setExpdate}
                                        name="expDate"
                                        dateFormat="dd-MM-yyyy"

                                    />
                                    {setValue("vineoBatchSet.vineoBatchExpDate", moment(expdate).format(DATEFORMAT))}

                                </FormGroup>
                            </Col>

                        </Row>



                        <Row>

                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Batch Free Product Qty in PCS
                                    </Form.Text>
                                    <Form.Control type="Number" className="form-control "   {...register("vineoBatchSet.vineoBatchFreeProductQty")} />

                                </FormGroup>
                            </Col>



                            <Col>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Batch Product Qty in PCS
                                    </Form.Text>
                                    <Form.Control type="Number" className="form-control"  {...register("vineoBatchSet.vineoBatchProductQty")} />
                                </FormGroup>
                            </Col>
                            {/* {setTableData(vineobatchData)}  */}
                            {!isvineobatchdataloaded ? console.log(isvineobatchdataloaded) : <></>}
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Scheme
                                    </Form.Text>
                                    <div>
                                        <InputGroup>
                                            <Typeahead

                                                id="VendorSchemeTypehead"
                                                onChange={setSelectedScheme}
                                                // multiple for future
                                                options={vineoschemedata}
                                                placeholder="Choose Scheme to Apply to this Batch"
                                                selected={selectedScheme}
                                                labelKey={"vineoSchemeName"}
                                                ref={setValue("vineoBatchSet.vineoScheme", selectedScheme)}
                                            />
                                            <Button variant="primary"
                                                onClick={() => { dispatch(getVineoSchemaMasterData()) }} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                        </InputGroup>
                                    </div>

                                    {setValue("vineoBatchSet.vineoSchemeName", selectedScheme[0]?.vineoSchemeName)}

                                </FormGroup>
                            </Col>


                            <Col>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Narr
                                    </Form.Text>
                                    <Form.Control className="form-control"  {...register("vineoBatchSet.vineoBatchNarr")} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted" defaultValue={1}>
                                        MRP - Including GST and Per PCS
                                    </Form.Text>
                                    <Form.Control type="Number" step="0.01" className="form-control "   {...register("vineoBatchSet.vineoBatchProductMRPperPcs")} required />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Purchase Rate on Margin of- {selectedProduct?.[0]?.distMargin} % GST @ {selectedProduct?.[0]?.vineoProductStateGstPercentage}+{selectedProduct?.[0]?.vineoProductCentralGstPercentage}
                                    </Form.Text>
                                    <Form.Control className="form-control " value={
                                        parseFloat(parseFloat(watch('vineoBatchSet.vineoBatchProductMRPperPcs')) /
                                            (1 + (0.01 * selectedProduct?.[0]?.distMargin)) /
                                            (1 + 0.01 * selectedProduct?.[0]?.retailerMargin)
                                        ).toFixed(2) || 0
                                    }{...register("vineoBatchSet.vineoBatchPurchaseRateperPcs")} required />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Sales Rate On Margin of- {selectedProduct?.[0]?.retailerMargin} % GST @ {selectedProduct?.[0]?.vineoProductStateGstPercentage}+{selectedProduct?.[0]?.vineoProductCentralGstPercentage}
                                    </Form.Text>
                                    <Form.Control className="form-control " value={
                                        parseFloat(parseFloat(watch('vineoBatchSet.vineoBatchProductMRPperPcs')) /
                                            (1 + 0.01 * selectedProduct?.[0]?.retailerMargin)

                                        ).toFixed(2)

                                    }
                                        {...register("vineoBatchSet.vineoBatchProductSaleRateperPcs")} required />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        SuperStokist Sales Rate On Margin of- {selectedProduct?.[0]?.superStockistMargin} % GST @ {selectedProduct?.[0]?.vineoProductStateGstPercentage}+{selectedProduct?.[0]?.vineoProductCentralGstPercentage}
                                    </Form.Text>
                                    <Form.Control className="form-control " readOnly={true}
                                    />
                                </FormGroup>
                            </Col>

                            {/* Calcualte later  */}
                        </Row>
                        <Row>
                            <Col md={3}>

                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Calculated Amount
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("vineoBatchSet.vineoBatchCalcualtedAmount")} />

                                </FormGroup>
                            </Col>
                            <Col md={3}>

                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        PurchaseAutoImport
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("vineoBatchSet.vineoBatchProductSaleRateforPurchaseAutoImport")} />

                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Form.Text className="text-muted" defaultValue={1}>
                                        Batch Opening Stock in PCS
                                    </Form.Text>
                                    <Form.Control type="Number" className="form-control "   {...register("vineoBatchSet.openingstockqtyPCS")} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup style={{ marginTop: '20px' }}>
                                    <Form.Label>By Default Rates are Including GST </Form.Label>
                                    {/* <Form.Check
                                    readOnly
                                    onChange={(e) => { setAllRateIncludingGST(!allRateIncludingGST) }}
                                    label='Rates Including GST' 
                                    checked={allRateIncludingGST}
                                    id="Checkbox"
                                /> */}
                                </FormGroup>
                                {console.log(allRateIncludingGST)}
                                {setValue("vineoBatchSet.allRateIncludingGST", allRateIncludingGST)}
                            </Col>
                        </Row>
                        {/* vineoBatchProductSaleRatefromVendorAutoImport */}


                    </Card>

                    <Button className="btn btn-primary m-2" type="submit" value="Add Batch ">
                        Add Batch
                    </Button>

                </form>
            </div>

            <Row>
                <Col md={8}>
                    <InputGroup>

                        <Form.Text className="text-muted m-2">
                            Page Size
                        </Form.Text>
                        <Col md={2}>
                            <Form.Select className="m-2" onChange={(e) => onPaginationChange(e.target.value)} size="sm">
                                <option value='50'>50</option>
                                <option value="100">100</option>
                                <option value='200'>200</option>
                                <option value='300'>300</option>
                            </Form.Select >
                        </Col>
                        <Button className="btn btn-warning m-2" disabled={isLoading} onClick={()=>{setLoading(true);loaddatafromserver();}} value="Add Batch ">
                            Load Batch Data
                        </Button>
                        <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
                            Export To CSV
                        </Button>
                    </InputGroup>
                </Col>
                <Col md = {2}>  Total Number of Batch : {tableData ? tableData.length : 0}</Col>
              
              
                <div className="ag-theme-alpine"  style={{ height: '700px', overflowX: 'auto' }}>
                    <AgGridReact
                        id="AgGridforBathcData"
                        rowData={tableData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        editType={'fullRow'}
                        onCellValueChanged={onCellValueChanged}
                        onRowValueChanged={onRowValueChanged}
                        alwaysShowHorizontalScroll={true}
                    />
                    <AgGridColumn
                        headerName="BQty"
                        valueGetter={(params) => {
                            return params.data.vineoBatchProductQty;
                        }}
                        valueSetter={(params) => {
                            var newValInt = parseInt(params.newValue);
                            var valueChanged = params.data.vineoBatchProductQty !== newValInt;
                            if (valueChanged) {
                                params.data.vineoBatchProductQty = newValInt;
                            }
                            return valueChanged;
                        }}
                    />
                </div>
            </Row>

            <div>
                <div>
                    <Modal show={openMapVendortoProduct}
                        onHide={() => { setOpenMapVendortoProduct(false) }}
                        backdrop="static"
                        keyboard={false}
                        fullscreen={true}>
                        <Modal.Header closeButton />
                        {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}

                        <Modal.Body>

                            <VendorToProduct />

                        </Modal.Body>
                        <Modal.Footer>


                            <Button className="btn btn-primary m-2" onClick={() => { setOpenMapVendortoProduct(false) }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={openEditBatch}
                        onHide={() => { setOpenEditBatch(false) }}
                        backdrop="static"
                        keyboard={false}
                        fullscreen={true}>
                        <Modal.Header closeButton />
                        {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}

                        <Modal.Body>

                            <EditBatch batch={dataforeditbatch}/>

                        </Modal.Body>
                        <Modal.Footer>


                            <Button className="btn btn-primary m-2" onClick={() => { setOpenEditBatch(false) }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </div >
    )
}