import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import { addMonths } from 'date-fns';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm, useWatch } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT, VINEO_GSTR1_REPORT_WITH_DATE } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddBillData, getVineoAddBillDatawithdate } from "../../../redux/features/services/entriesHelperService";
import {
    FormGroup, Label, Input,
    Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';



export default function Gstr1report() {
    const { setValue, reset ,watch} = useForm();

    const count = useSelector((state) => state.vineoData.value)
    const dispatch = useDispatch()
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const vineoaddbilldatawithdate = useSelector((state) => state.vineoData.vineoaddbilldatawithdate);
    const isvineoaddbilldatawithdateLoaded = useSelector((state) => state.vineoData.isvineoaddbilldatawithdateLoaded);
    const [fromDate, setFromDate] = useState((new Date()).setDate((new Date()).getDate() - 3));
    const [toDate, setToDate] = useState(new Date());
    let preparedData;
    const [tableData, setTableData] = useState();
    const [isLoading, setLoading] = useState(false);
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const [readyToSubmit, setReadyToSubmit] = useState(false)


    useEffect(() => {

        console.log("Refreshing on state Change " + refreshcount)
        setLoading(false)
        setTableData(preparedData)
    }, [refreshcount, isvineoaddbilldatawithdateLoaded]);

    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)

    const onExportClick = () => {
        gridApi.api.exportDataAsCsv();
    }

    const onGridReady = (params) => {
        setGridApi(params);
    }

    const defaultColDef = {
        sortable: true,
        editable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
    }

    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }

    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }

    const columnDefs = [
        { headerName: "Product", field: 'productName' ,resizable: true  },
        { headerName: "BillNo.", field: "billNoWithPrefix" ,resizable: true  },
        { headerName: "Date ", field: "date",resizable: true  },
        { headerName: "Party", field: 'partyName',resizable: true  },
        { headerName: "Type", field: "billType" ,resizable: true  },
        { headerName: "Qty", field: 'batchQty',resizable: true  },
        { headerName: "Amount", field: 'grossAmount' ,resizable: true  },
        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => (
                <div>
                    <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                    <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
                </div>
            )
        }
    ]

    const handleUpdate = (data) => {
        // add corresponsding update API logic over here
        console.log('product updated')
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)

        if (confirm) {
            console.log('product deleted from the bill')
            // add corresponsding delete API logic over here
        }
    }

    const getgstr1fromserverwithdate = (reqdata) => {
        if (reqdata.startDate && reqdata.endDate) {

            // call new gstr1 api to get detaisl 
            submitForm(reqdata)


        } else {
            notifyError("Please Enter Date for Bill Start and End ")
        }
    }


    const submitForm = (data) => {
        setReadyToSubmit(true)
        http.post(BASEURL_JWTAUTH + VINEO_GSTR1_REPORT_WITH_DATE, data, { responseType: 'blob' }).then(
            (res) => {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', moment(addMonths(fromDate, 1)).format(DATEFORMAT)+'gstr1.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setReadyToSubmit(true);
                setLoading(false);
            },
            (err) => {
                notifyError('Error While Creating GSTR1 Report')
                setReadyToSubmit(false)
                setLoading(false);
            }
        )
    }

    // needed below useEffect to solve issue which needed Load Bills to be clicked twice for initial page load only
    // re-write this logic to fetch existing data from redux for specified dates on initial load only
    // or it will keep fetching the data irrespective of the date selected on initial load only ( current behavior )
    // Solution: on navigation resetting the isvineoaddbilldatawithdateLoaded state variable in redux might solve the issue
    useEffect(() => {

    }, [isvineoaddbilldatawithdateLoaded, vineoaddbilldatawithdate])

    return (
        <div className="row justify-content-left ">
             <div className='h5 mb-0 text-gray-800'>
                <center>
                    <h4 align="center">GSTR1 Report</h4>
                </center>
            </div>

            <Row>
                <Col md={3}>
                    <FormGroup className="m-2">
                        <Form.Text >
                            Bills From Date
                        </Form.Text>
                        <DatePicker className="form-control"
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            ref={setValue("vineoCreateLoadBillFromDate", moment(fromDate).format(DATEFORMAT))}
                        />
                      
                    </FormGroup>
                </Col>
                {setValue("vineoCreateLoadBillToDate", moment(addMonths(fromDate, 1)).format(DATEFORMAT))}
                {console.log("dates are", moment(fromDate).format(DATEFORMAT) ,"and ",moment(addMonths(fromDate, 1)).format(DATEFORMAT))}
            </Row>

            <Row>
                <Col>
                    <Button className="btn btn-primary m-2" disabled={isLoading} onClick={() => {
                        setLoading(true);
                        getgstr1fromserverwithdate({ startDate: moment(fromDate).format(DATEFORMAT), endDate: moment(addMonths(fromDate, 1)).format(DATEFORMAT) })
                    }}>
                        <FontAwesomeIcon icon={faRotate} />
                        Load GSTR1
                    </Button>

                </Col>
            </Row>


        </div>
    )
}