import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';

import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import http from "../../../../constants/AxiosConfig";

import { useForm } from "react-hook-form";
import { faCalendarPlus, faCartPlus, faCirclePlus, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  BASEURL_JWTAUTH, VINEOFORM_CANCELEINVOICE_EWAYBILL_OF_SELECTED_ADDBILL, VINEOFORM_GETEWAYBILL_OF_SELECTED_ADDBILL } from "../../../../constants/Urlforvineo";
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup,
    Button, Form, Row, Col, Card, InputGroup, Modal
} from 'react-bootstrap';
import { v4 } from 'uuid';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { getVineoTransportMasterData } from "../../../../redux/features/services/serviceHelper";
import TransportEntry from "../../master/TransportEntry";
import { addgstDeatilsFromUser } from "../../../../redux/features/vineoData/vineoDataSlice";
import axios from "axios";


export default function CancelEwaybillandeinv(props) {

    const { bill } = props;
    const {authTokenCancelEwayBill}= props;

    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSubBrandUniqeId": "Working", "vineoSubBrandName": "Click Refresh To Load" }])

    const dispatch = useDispatch()
    const [selected, setSelected] = useState([]);

    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [addtransporter, setAddtransporter] = useState(false);
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const count = useSelector((state) => state.vineoData.value)

    const [datafortransporttypehead, setDatafortransporttypehead] = useState({});

    const vineoData = useSelector((state) => state.vineoData.vineotransportmasterdata)
    const gstDeatilsFromUser = useSelector((state) => state.vineoData.gstDeatilsFromUser)
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)

    const isDataLoaded = useSelector((state) => state.vineoData.isvineotransportmasterdataLoaded)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)


    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getVineoTransportMasterData());


    }, []);

    useEffect(() => {
        setDatafortransporttypehead(vineoData)
        console.log("Refreshing");
    }, [refreshcount]);

    const onSubmit = (data) => {
        console.log(data);
     
            submitForm(data)
       

    }
    // const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)

    const getdatafromserver = () => {

        console.log("Called in to get Transport Data")
        dispatch(getVineoTransportMasterData())

        console.log("Empty Data Received form Server")

    }


    const submitForm = (dataArrayforArray) => {
        // api call

        const data = [dataArrayforArray];

        const GSTIN = vineofirmdata?.[0]?.vineoFirmGstNumber;
        const eInvoiceUserName = gstDeatilsFromUser.eInvoiceUserName;
        const eInvoicePassword = gstDeatilsFromUser.eInvoicePassword;
        const authToken = gstDeatilsFromUser.AuthToken;
        const MVApiKey = vineofirmdata?.[0]?.vineoFirm_GSTMVApiKey;
        const MVSecretKey = vineofirmdata?.[0]?.vineoFirm_GSTMVSecretKey;
      
        // Get the current month and year in the format "MM-yyyy"
        const currentDate = new Date();
        const monthYear = `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
        const headers = {
            MVApiKey,
            MVSecretKey,
            GSTIN,
            eInvoiceUserName,
            eInvoicePassword,
            authToken,
            MonthYear: monthYear,
            authTokenCancelEwayBill: authTokenCancelEwayBill,
            'Content-Type': 'application/json',
            'X-uniqueidForVineoclientName': `${JSON.parse(sessionStorage.getItem("uniqueidForVineoclientName"))}`,
            'X-vineoRegisteredClientName': `${JSON.parse(sessionStorage.getItem("vineoRegisteredClientName"))}`,
            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
            "x-appCorrelationid": v4()
        };

        axios.post(BASEURL_JWTAUTH + VINEOFORM_CANCELEINVOICE_EWAYBILL_OF_SELECTED_ADDBILL, data, { headers }).then(
            (res) => {
                console.log(res.data)
                if ( (res.data.errorCode == "0000")) {
                    notify('Success:- Eway Bill Cancelled  with Internal Id')
                    console.log(res.data)
                    reset()
                }
                else {
                    notifyError("Error While Cancelled Eway Bill From Server " + res.data.additionalInfo)
                }

            },
            (err) => {
                notifyError("Error While Cancelled EWay Bill  ")
                console.log(err.data)
            }
        )
    }

    return (
        <div>
            <h3 className='justify-content-center m-1'>Cancel E-Invoice OR Eway Bill </h3>
            <div className="row justify-content-left ">
                <Row>

                    <Col >
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {console.log("token is "+ authTokenCancelEwayBill)}
                                       <Row> <Col>
                                       IRN: - {bill?.einvoiceIRN}
                                       </Col></Row>
                                       <Row> <Col>
                                       E-WayBill: - {bill?.ewayBillNo}
                                       </Col></Row>
                                       
                                    <Row>
                                        <Col>Supplier :- {bill?.vineoAddSaleBillFirm?.vineoFirmFirmName} GST:-{bill?.vineoAddSaleBillFirm?.vineoFirmGstNumber} </Col>
                                        <Col>Customer :-{bill?.vineoAddSaleBillCustomer?.vineoCustomerAccountName} GST:-{bill?.vineoAddSaleBillCustomer?.vineoCustomerGstNumber} </Col>
                                        <Col>Bill Amount:- {bill?.vineoAddSaleBillBottomAmt} </Col>
                                        <Col>Date:- {bill?.vineoAddSaleBillDate}</Col>
                                        <Col></Col>
                                    </Row>

                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Bill Number
                                        </Form.Text>
                                        {console.log(bill, "----")}
                                        <Form.Control className="form-control " value={bill?.vineoaddUniqueBillNumberWithPrefix} 
                                        {...register("vineoaddUniqueBillNumberWithPrefix")} required />
                                    </FormGroup>
                                    
                                    <FormGroup >

                                        <Form.Text className="text-muted">
                                            Cancel Reason 
                                        </Form.Text>
                                        <Form.Select className="form-control" size='sm'
                                         {...register("einvoiceIRNCancelCode")}>
                                              <option value="3">Data Entry mistake</option>
                                            <option value="1">Duplicate</option>
                                            <option value="2">Order Cancelled</option>
                                            <option value="4">Others</option>
                                        </Form.Select></FormGroup>
                                   
                                    <input className="btn btn-danger m-2" type="submit" value="Cancel EInvoice/ Eway Bill" />
                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row >

            </div >

            <Modal show={addtransporter}
                onHide={() => { setAddtransporter(!addtransporter) }}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Add Transporter </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <TransportEntry />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

        </div >
    )

}