import React from "react";
import {useCallback, useEffect, useState } from "react";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup } from "react-bootstrap";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from 'react-redux'
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH } from "../../../constants/Urlforvineo";
import http from "../../../constants/AxiosConfig";
import moment from "moment";

import VendorToProduct from "../mapping/VendorToProduct";
import EditBatch from "../entries/stockadjustment/EditBatch";
import { getVineoBatchData } from "../../../redux/features/services/entriesHelperService";
import { getVineoAccountVendorCustomerMasterMenu,getVineoGodownData, getVineoCompanyData, getVineoProductData, getVineoSchemaMasterData, getVineoFirmData } from "../../../redux/features/services/serviceHelper";
import EditBatchForApplySchme from "../entries/stockadjustment/EditBatchForApplySchme";
export default function SchemeMasterApply() {
    useEffect(() => {
        dispatch(getVineoAccountVendorCustomerMasterMenu());
        dispatch(getVineoGodownData());
        dispatch(getVineoSchemaMasterData());
        dispatch(getVineoFirmData())
      
    }, []);

    const dispatch = useDispatch()
    const vineovendordata = useSelector((state) => state.vineoData.vineoaccountvendorcustomerdata)
    const isvineovendordataLoaded = useSelector((state) => state.vineoData.isvineoaccountvendorcustomerdataLoaded)
    const vineoGodowndata = useSelector((state) => state.vineoData.vineogodowndata)
    const vineobatchData = useSelector((state) => state.vineoData.vineobatchdata)
    const isvineobatchdataloaded = useSelector((state) => state.vineoData.isvineobatchdataLoaded)
    const vineoproductdata = useSelector((state) => state.vineoData.vineoproductdata)
    const vineoschemedata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [openMapVendortoProduct, setOpenMapVendortoProduct] = useState(false);
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const [isLoading, setLoading] = useState(false);
    const ref = React.createRef();


    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const { control, register, handleSubmit, setValue, reset, watch } = useForm();
    const [vdate, setVdate] = useState();
    const [allRateIncludingGST, setAllRateIncludingGST] = useState(true)
    const [mfgdate, setMfgdate] = useState();
    const [expdate, setExpdate] = useState();
    const [selected, setSelected] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [selectedGodown, setSelectedGodown] = useState([])
    const [selectedScheme, setSelectedScheme] = useState([])
    const [updatedProductBatchQty, setUpdatedProductBatchQty] = useState()
    const [openEditBatch, setOpenEditBatch] = useState(false)
    const [dataforeditbatch, setDataforeditbatch] = useState({})
    


    const loaddatafromserver = () => {

        dispatch(getVineoAccountVendorCustomerMasterMenu())
        dispatch(getVineoBatchData())
        isvineobatchdataloaded ? setTableData(vineobatchData) : console.log("Empty data from Server for Batch")

    }

    useEffect(() => {

        console.log("Refreshing on state Change " + refreshcount)
        setLoading(false)
        setTableData(vineobatchData)
    }, [isvineobatchdataloaded, vineobatchData, refreshcount]);
    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)


    const [tableData, setTableData] = useState(vineobatchData)

    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }



    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
        floatingFilter: true,
        editable: true,
        // onCellValueChanged: onCellValueChanged

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }

   
    const columnDefs = [
        { headerName: "BatchName", field: "vineoBatchName",resizable: true },
        { headerName: "BatchNo ", field: "vineoBatchNumber",resizable: true },
        { headerName: "Product", field: "vineoproductNameinBatch",resizable: true },
        { headerName: "PurcahseRate", field: "vineoBatchPurchaseRateperPcs",resizable: true },
        { headerName: "SaleRate", field: "vineoBatchProductSaleRateperPcs",resizable: true },
        // { headerName: "Qty", field: "vineoBatchProductQty" },
        {
            headerName: "QtyBox",
            editable: true,
            valueGetter: params => {

                return params.data.vineoBatchProductQty;
            }
            ,resizable: true},
            {
                headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                    <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                    {/* <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button> */}
                </div>, editable: false
            },
        { headerName: "PCSQty", field: 'vineoBatchProductQtyinPCS', editable: true,resizable: true },

        { headerName: "free", field: 'vineoBatchFreeProductQty' , editable: true,resizable: true},

        { headerName: "MRP", field: 'vineoBatchProductMRPperPcs' ,resizable: true},
        { headerName: "Scheme", valueGetter:  'data.vineoScheme[0]?.vineoSchemeName',resizable: true },
        { headerName: "IsSchAct", field: 'schemeisActive' },

        // need to laod schem from DB 
      
    ]


    const onCellValueChanged = useCallback((event) => {
        console.log(
          'onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue
        );
      }, []);

    const onRowValueChanged = useCallback((event) => {
        var data = event.data;
        console.log(data);
    }, []);

    const handleUpdate = (data) => {
        console.log(data)

        console.log("This will editqty by this amount " + updatedProductBatchQty);
        setOpenEditBatch(true);
        setDataforeditbatch(data);
        

    }
    //deleting a user

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Batch  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoBatchName)

                }
                else { notifyError("Error While Deleting Batch  Or Login as ADMIN") }
            },
            (err) => {
                notifyError("Error While Deleting Batch Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    // Ag grid Work Start 

    const onSubmit = (data) => {


        if (selectedGodown?.[0]) {

            if (selected?.[0]) {
                console.log(data)
                submitForm(data)
            }
            else {
                notifyError("Please Select Vendor")
            }
        }
        else {
            notifyError("Please Select Godown  ")
        }

    }


    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Batch Created With Internal Id')
                    dispatch(getVineoAccountVendorCustomerMasterMenu())
                    reset();

                }
                else { notifyError('Error While Registering  Batch  Info From Server') }
                //console.log(res.data)
            },
            (err) => {
                notifyError('Error While Registering  Batch  Info')
            }
        )
    }

    return (

        <div>
            <div className="p-2 mb-2 bg-primary text-white">
                <center>
                    <h5 className='justify-content-center m-1'>Add Scheme to Batch for Particular Product </h5>
                </center>
            </div>
            

            <Row>
                <Col md={8}>
                    <InputGroup>

                        <Form.Text className="text-muted m-2">
                            Page Size
                        </Form.Text>
                        <Col md={2}>
                            <Form.Select className="m-2" onChange={(e) => onPaginationChange(e.target.value)} size="sm">
                                <option value='50'>50</option>
                                <option value="100">100</option>
                                <option value='200'>200</option>
                                <option value='300'>300</option>
                            </Form.Select >
                        </Col>
                        <Button className="btn btn-warning m-2" disabled={isLoading} onClick={()=>{setLoading(true);loaddatafromserver();}} value="Add Batch ">
                            Load Batch Data
                        </Button>
                        <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
                            Export To CSV
                        </Button>
                    </InputGroup>
                </Col>
                <div className="ag-theme-alpine" style={{ height: '700px' }}>
                    <AgGridReact
                        id="AgGridforBathcData"
                        rowData={tableData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        editType={'fullRow'}
                        onCellValueChanged={onCellValueChanged}
                        onRowValueChanged={onRowValueChanged}
                    />
                    <AgGridColumn
                        headerName="BQty"
                        valueGetter={(params) => {
                            return params.data.vineoBatchProductQty;
                        }}
                        valueSetter={(params) => {
                            var newValInt = parseInt(params.newValue);
                            var valueChanged = params.data.vineoBatchProductQty !== newValInt;
                            if (valueChanged) {
                                params.data.vineoBatchProductQty = newValInt;
                            }
                            return valueChanged;
                        }}
                    />
                </div>
            </Row>

            <div>
                <div>
                    <Modal show={openMapVendortoProduct}
                        onHide={() => { setOpenMapVendortoProduct(false) }}
                        backdrop="static"
                        keyboard={false}
                        fullscreen={true}>
                        <Modal.Header closeButton />
                        {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}

                        <Modal.Body>

                            <VendorToProduct />

                        </Modal.Body>
                        <Modal.Footer>


                            <Button className="btn btn-primary m-2" onClick={() => { setOpenMapVendortoProduct(false) }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={openEditBatch}
                        onHide={() => { setOpenEditBatch(false) }}
                        backdrop="static"
                        keyboard={false}
                        fullscreen={true}>
                        <Modal.Header closeButton />
                        {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}

                        <Modal.Body>

                            <EditBatchForApplySchme batch={dataforeditbatch}/>

                        </Modal.Body>
                        <Modal.Footer>


                            <Button className="btn btn-primary m-2" onClick={() => { setOpenEditBatch(false) }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </div >
    )
}