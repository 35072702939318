import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import { addMonths } from 'date-fns';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddBillData, getVineoattendancedatawithdate   } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { getVineoAccountCustomerMasterMenu } from '../../../redux/features/services/serviceHelper';
import Billprint from '../entries/billing/Billprint';
import ShopVisitprint from '../printFormat/ShopVisitprint';
import ShopVisitMap from './ShopVisitMap';
import AuthUser from '../../../constants/AuthUser';

export default function AttendancefromUser() {
	const { setValue } = useForm();
	useEffect(() => {
		console.log("Use Effect called ");
	}, []);

	const { token, logout, getUser, getRole } = AuthUser();
	let currentDate = new Date();
	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const attendancedatawithdate = useSelector((state) => state.vineoData.attendancedatawithdate)
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const [fromdate, setfromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
	const [toDate, setToDate] = useState(new Date());
	const [openprint, setOpenprint] = React.useState(false);
	const [dataforprint, setDataforprint] = useState();
	const [tableData, setTableData] = useState();
	const [showmap, setShowmap] = useState(false);
	const [selectedSalesman, setSelectedSalesman] = useState([])
	const [selectedParty, setSelectedParty] = useState([])
	const [satHoliday, setSatHoliday] = useState(false)
	const [sunHoliday, setSunHoliday] = useState(true)
	const [selectedWeeklyOff, setSelectedWeeklyOff] = useState("false");
	const reportData = [];
	const notify = (message) => toast.success(message);
	const notifyError = (message) => toast.error(message)
	const notifyWarning = (message) => toast.warning(message)
	const [isLoading, setLoading] = useState(false);

	const [selectedFirm, setSelectedFirm] = useState([])
	const filteredTableData = (
		selectedParty?.[0]?.label &&
		selectedSalesman?.[0]?.label &&
		selectedFirm?.[0]?.label
	)
		? tableData
			?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
			?.filter(item => item?.partyName === selectedParty?.[0]?.label)
			?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname === selectedFirm?.[0]?.label)
		: (
			selectedParty?.[0]?.label
				? (selectedSalesman?.[0]?.label
					? tableData?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
					: tableData
				)?.filter(item => item?.partyName === selectedParty?.[0]?.label)
				: (selectedSalesman?.[0]?.label
					? tableData?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
					: (selectedFirm?.[0]?.label
						? tableData?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname === selectedFirm?.[0]?.label)
						: tableData
					)
				)
		);


	useEffect(() => {

		console.log("Refreshing on state Change " + refreshcount)
		setLoading(false)
		setTableData(attendancedatawithdate)

	}, [refreshcount]);


	useEffect(() => {
		// Initialize "From Date" to the current date
		const currentDate = new Date();
		const firstDayofMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

		setfromdate(firstDayofMonth)

		// Initialize "To Date" to the last day of the current month
		const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
		setToDate(lastDayOfMonth);
	}, []);


	

	const handleFromDateChange = (date) => {
		setfromdate(date);

		if (true) {
			const newToDate = new Date(date);
			newToDate.setMonth(newToDate.getMonth() + 1);
			newToDate.setDate(0); // Set to last day of previous month
			setToDate(newToDate);
		}
	}

	const getdatafromserverwithdate = (reqdata) => {
		const rolename = getRole("");
		console.log("Role is " + rolename)


		if (reqdata.startDate && reqdata.endDate) {

			dispatch(getVineoattendancedatawithdate(reqdata))


		} else {
			notifyError("Please Enter Date  Start and End ")
		}
	}
	

	// start of ag grid 

	// Ag grid Work Start 
	const [gridApi, setGridApi] = useState(null)

	const onExportClick = () => {
		gridApi.api.exportDataAsCsv();
	}

	const onGridReady = (params) => {
		setGridApi(params);
	}

	const defaultColDef = {
		sortable: true,
		// editable: true,
		flex: 1,
		filter: true,
		floatingFilter: true,
		resizable: true,
		minWidth: 100,
	}

	const onPaginationChange = (pageSize) => {
		gridApi.api.paginationSetPageSize(Number(pageSize))
	}

	function onCellValueChanged(event) {
		console.log(
			'onCellValueChanged: '
		);
	}

	const columnDefs = [
		{ headerName: 'nameofUser', field: 'nameofUser' },
		{ headerName: 'username', field: 'username' },
		{ headerName: 'Date', field: 'date' },
		{ headerName: 'Status', field: 'status' },
		{ headerName: 'Punch Location', field: 'punchlocation' },
		{ headerName: 'EOD', field: 'eod' },
		{ headerName: 'Punch Time', field: 'punchTime' },
		{ headerName: 'Latitude', field: 'lati' },
		{ headerName: 'Longitude', field: 'longi' },
		{ headerName: 'leaveReason', field: 'leaveReason' },
	  ];

	
	  

	const handleSelectHolidayChange = (event) => {
		const selectedValue = event.target.value;
		setSelectedWeeklyOff(selectedValue);

	};
	// Function to generate date columns between startDate and endDate
	// Function to generate date columns between startDate and endDate

	const uniqueUsernames = [...new Set(filteredTableData?.map((item) => item.userName))];
	

	for (const username of uniqueUsernames) {
		// Create an object for the report
		const reportEntry = {
		  username: username,
		  dateWiseData: [],
		};
	  
		// Iterate through each date within the specified date range
		const currentDate = moment(fromdate);
		const endDate = moment(toDate);
	  
		while (currentDate.isSameOrBefore(endDate, "day")) {
		  const formattedDate = currentDate.format(DATEFORMAT);
	  
		  // Find the data for the current date and username
		  const userData = filteredTableData?.find(
			(item) =>
			  item.userName === username && item.attendaceDate === formattedDate
		  );
	  
		  // Create an object for the date-wise data
		  const dateData = {
			date: formattedDate,
			status: userData ? userData.status : "NA",
			nameofUser: userData ? userData.nameofUser : "NA",
			punchlocation: userData ? userData.punchaddress : "NA", // Assuming location is the same as address
			eod: userData ? userData.eod : "NA",
			punchTime: userData ? userData.punchTime : "NA",
			lati: userData ? userData.lati : 0,
			longi: userData ? userData.longi : 0,
			leaveReason: userData ? userData.leaveReason : 'NA',
		  };
	  
		  // Push the date-wise data to the report entry
		  reportEntry.dateWiseData.push(dateData);
	  
		  currentDate.add(1, "day");
		}
	  
		// Push the report entry to the report data array
		reportData.push(reportEntry);
	  }
	  console.log("Value of report is ",(reportData));


	  const rowData = reportData?.flatMap((item) =>
	  item.dateWiseData.map((dateData) => ({
		username: item.username,
		
		...dateData,
	  }))
	);

	console.log("Value of rowdata is ",(rowData));

	


	const handleUpdate = (data) => {
		console.log(data)
	}


	const handlePrint = (data) => {
		setDataforprint(data);
		setOpenprint(!openprint)
	}
	const handleDelete = (data) => {
		const confirm = window.confirm("Are you sure, you want to delete this row", data)

		if (confirm) {
			http.post(BASEURL_JWTAUTH + "Add delete URL once deltign is approved ", data).then(

				(res) => {
					console.log(res)
					if (res.data.errorCode == "0000") {
						notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

					}
					else
						notifyError("Error While Deleting Bill  " + + res.data.errorMessage)
				},
				(err) => {
					notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

				}
			)
		}
	}

	return (
		<div className="row justify-content-left ">
			<div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">Attendance Report Based on User Input P:Present, A:Absent ,V: Visit</h4>
				</center>
			</div>

			<Row>


				<Col md={1}>
					<FormGroup className="m-2">
						<Form.Text >
							Month
						</Form.Text>
						<DatePicker className="form-control"
							selected={fromdate}
							// onChange={(date) => {setfromdate(date);setMonthSelected(true)}}
							dateFormat="MM/yyyy"
							showMonthYearPicker
							showFullMonthYearPicker
							onChange={handleFromDateChange}


						/>

					</FormGroup>
				</Col>

				{console.log("dates are", moment(fromdate).format(DATEFORMAT), "and ", moment(addMonths(fromdate, 1)).format(DATEFORMAT))}



				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							<div style={{ display: 'flex', alignItems: 'center' }}>
								Visit From Date

								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 1)); console.log("Date selected is ", fromdate)
								}} > -1Day</div>
								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 10))
								}} > -10Day</div>
								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 30))
								}} > -30</div>
							</div>

						</Form.Text>

						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setfromdate}
							name="vineoCreateLoadBillfromdate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillfromdate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text>To Date</Form.Text>
						<DatePicker
							className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
						/>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup className="m-2">
						<Form.Text>SiteVisit Attendance</Form.Text>
						<Button className="btn btn-primary" disabled={isLoading} onClick={() => { setLoading(true); getdatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) }) }}>
							<FontAwesomeIcon icon={faRotate} />
							Load
						</Button>
					</FormGroup>

				</Col>

			</Row>

			<Row>
			<Col md={2}>
					<FormGroup className="m-2">
						<Form.Text className="text-muted">Holiday</Form.Text>
						<Form.Select className="form-control" onChange={handleSelectHolidayChange}>
							<option value="false">No</option>
							<option value="true-sun">Sunday</option>
							<option value="true-mon">Monday</option>
							<option value="true-tue">Tuesday</option>
							<option value="true-wed">Wednesday</option>
							<option value="true-thu">Thursday</option>
							<option value="true-fri">Friday</option>
							<option value="true-sat">Saturday</option>
							<option value="true">SAT-SUN</option>

						</Form.Select>


					</FormGroup>
				</Col>
				
				<Col md={2}>
					<FormGroup className="m-2">
						<Form.Text className="text-muted">Export</Form.Text>
						<Button className="btn btn-primary " onClick={onExportClick} value="ExportToCsv">
							Export To CSV
						</Button>

					</FormGroup>

				</Col>

			</Row>


			<div className="ag-theme-alpine" style={{ height: '500px', overflowX: 'auto' }}>
				<div className="ag-theme-alpine" style={{ height: '570px' }}>
					<AgGridReact
						id="AgGridforData"
						rowData={rowData || []}
						// .filter((item) => item?.nameofUser === null)
						// rowData={ selectedSalesman? tableData?.filter((item)=>{if((item?.nameofUser===selectedSalesman)) {return item}}) :
						// tableData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
						alwaysShowHorizontalScroll={true}

					/>


				</div>
			</div>




			<Modal show={openprint}
				onHide={() => setOpenprint(!openprint)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print Shop Visit Memo </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className='p-4'>
						<ShopVisitprint data={dataforprint} />
					</Card>

				</Modal.Body>
				<Modal.Footer>


				</Modal.Footer>
			</Modal>

			<Modal show={showmap}
				onHide={() => setShowmap(!showmap)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Map of Visit </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className='p-4'>
						<ShopVisitMap dataArray={selectedSalesman?.[0]?.label ?
							tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
							: tableData} />
					</Card>

				</Modal.Body>
				<Modal.Footer>


				</Modal.Footer>
			</Modal>

		</div>
	)
}