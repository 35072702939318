import React from 'react'
import { useEffect, useState, useRef } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import { faCircleCheck, faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from 'react-redux'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Typeahead } from 'react-bootstrap-typeahead';
import CustomerBankInfo from './CustomerBankInfo';
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal,
} from 'react-bootstrap';
import { getVineoCustomerBankMasterData, getVineoFirmData, getVineoGstData } from '../../../redux/features/services/serviceHelper';
import { BASEURL_JWTAUTH, VINEOFORM_UPDATE_SELECTEDFIRMDATA, VINEOFORM_DELETE_SELECTEDFIRMDATA, GSTVALIDATEAPIURLWITHKEY, DATEFORMAT } from '../../../constants/Urlforvineo';
import axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import moment from "moment";
import { stateOptions } from '../../../constants/GstStatewithcode';


export default function AddFirmInfo() {
    useEffect(() => {
        // dispatch(getVineoFirmData());
        dispatch(getVineoGstData())
        dispatch(getVineoCustomerBankMasterData())

    }, []);


    const count = useSelector((state) => state.vineoData.value)
    const dispatch = useDispatch()
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedGST, setSelectedGST] = useState();
    const [openingdate, setOpeningdate] = useState(new Date());
    const [bitrhdate, setBirthdate] = useState(new Date());
    const [exitdate, setExitdate] = useState();
    const isvineofirmdataLoaded = useSelector((state) => state.vineoData.isvineofirmdataLoaded)
    const isvineoBankdataLoaded = useSelector((state) => state.vineoData.isvineocustomerbankMasterdataLoaded)
    const vineobankdata = useSelector((state) => state.vineoData.vineocustomerbankMasterdata)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const vineogstdata = useSelector((state) => state.vineoData.vineogstdata)
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const [dataofvineofirm, setDataofvineofirm] = useState([{ "vineoFirmFirmName": "Click Refresh To Load" }])
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoFirmUniqeId": "Working", "vineoFirmFirmName": "Click Refresh To Load" }])
    const [openAddBankAccnt, setOpenAddBankAccnt] = useState(false);
    const { control, register, handleSubmit, setValue, reset, getValues, watch, formState: { errors } } = useForm();
    const [file, setFile] = useState(null);
    const isUserHaveSuperadminAuthority = useSelector((state) => state.vineoData.isUserHaveSuperadminAuthority)
    const [flag, setFlag] = useState(false);
    const [selected, setSelected] = useState([]);
    const [gstAPIreqClicked, setGstAPIreqClicked] = useState(false)
    const [gstVerified, setGstVerified] = useState(false);
    const [selectedBank, setSelectedBank] = useState([]);



    useEffect(() => {
        //  dispatch(getVineoFirmData());
        console.log("Refreshing on state Change ")

        setDataofvineoTableData(vineofirmdata)
    }, [isvineofirmdataLoaded, refreshcount]);
    function handleSelect(s) {
        console.log(JSON.stringify(s?.[0]) + ' selected');
        setSelected(s);
    }

    const onSubmit = (data) => {
        console.log(data);
        if (data?.firmlogofiletoconvert?.[0]?.size > 20001) {
            notify("Please Upload Logo Image less than 20 KB ")
        }
        else {
                submitForm(data)
            }
    }


    const onBankRefresh = () => {
        dispatch(getVineoCustomerBankMasterData())
    }

    // const { http, setToken } = AuthUser();

    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)


    //Table for firm Start 
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoFirmFirmName',
            text: 'Firm Name',
            sort: true,
            formatter: rowClickedfunction,
        },
        {
            dataField: 'vineoFirmCode',
            text: 'Firm Code.',
            sort: true
        },


        // {
        //     dataField: 'vineoFirmRegNumber',
        //     text: 'Firm Reg.',
        //     sort: true
        // }, {
        //     dataField: 'vineofirmGst.vineoGstNumber',
        //     text: 'GST',
        //     sort: true
        // }, {
        //     dataField: 'vineoBank.vineoBankName',
        //     text: 'Bank',
        //     sort: true
        // }, {
        //     dataField: 'vineoFirmFirmaddress1',
        //     text: 'Address',
        //     sort: true
        // }, {
        //     dataField: 'vineoFirmCity',
        //     text: 'City',
        //     sort: true
        // }, {
        //     dataField: 'vineoFirmPhonenumber',
        //     text: 'Phone',
        //     sort: true
        // }

    ];

    // const rowEvents = {
    //     onClick: (e, row, rowIndex) => {
    //       console.log(`clicked on row with index: ${row}`);
    //       return rowIndex;

    //     }
    //   };

    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '20', value: 20
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };

    //Table for firm END 

    const getdatafromserver = () => {

        console.log("Called to get Firm Data ")
        dispatch(getVineoFirmData())
        if (Object.keys(vineofirmdata).length > 0) {
            isvineofirmdataLoaded ? setDataofvineoTableData(vineofirmdata) : setDataofvineoTableData([{ "ultiVineoFirmUniqeId": "Working", "vineoFirmFirmName": "Click Refresh To Load" }])

        }

        console.log("Empty Data Received form Server & isvineofirmdataLoaded" + isvineofirmdataLoaded)
    }


    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoFirmUniqeId", selectedRow.ultiVineoFirmUniqeId);
            setValue("vineoFirmCode", selectedRow.vineoFirmCode);
            setValue("autoPurchaseImportActive", selectedRow.autoPurchaseImportActive);
            setValue("vineoFirmUniqueOTP", selectedRow.vineoFirmUniqueOTP);
            setValue("vineoFirmCode", selectedRow.vineoFirmCode);
            setValue("vineoFirmUniqueOTP", selectedRow.vineoFirmUniqueOTP);

            setValue("vineoFirmCity", selectedRow.vineoFirmCity);
            setValue("vineoFirmCountry", selectedRow.vineoFirmCountry);
            setValue("vineoFirmDLNumber", selectedRow.vineoFirmDLNumber);
            setValue("vineoFirmEmailid", selectedRow.vineoFirmEmailid);
            setValue("vineoFirmFasi", selectedRow.vineoFirmFasi);
            setValue("vineoFirmFirmName", selectedRow.vineoFirmFirmName);
            setValue("vineoFirm_legalName", selectedRow.vineoFirm_legalName);
            setValue("vineoFirmFirmaddress1", selectedRow.vineoFirmFirmaddress1);
            setValue("vineoFirmFirmaddress2", selectedRow.vineoFirmFirmaddress2);
            setValue("vineoAllowMinusStock", selectedRow.vineoAllowMinusStock);
            setValue("vineoFirmMobilenumber", selectedRow.vineoFirmMobilenumber);
            setValue("vineoFirmGstNumber", selectedRow.vineoFirmGstNumber);
            setValue("vineoaddBillPrefix", selectedRow.vineoaddBillPrefix);
            // setSelectedGST([selectedRow.vineofirmGst])
            setValue("vineoFirmPhonenumber", selectedRow.vineoFirmPhonenumber);
            setValue("vineoFirmPincode", selectedRow.vineoFirmPincode);
            setValue("vineoFirmRegNumber", selectedRow.vineoFirmRegNumber);
          
            setValue("vineoFirmState", selectedRow.vineoFirmState);
            setValue("vineoFirmeEailid_cc", selectedRow.vineoFirmeEailid_cc);
            setValue("vineoaddBillPrefix", selectedRow.vineoaddBillPrefix);
            setValue("vineoaddBillUserBillNumberStartSetting", selectedRow.vineoaddBillUserBillNumberStartSetting);
            setValue("vineoaddCurrentBillUserBillNumber", selectedRow.vineoaddCurrentBillUserBillNumber);
            setValue("vineoaddBillUserBillNumber", selectedRow.vineoaddBillUserBillNumber);
            setValue("vineoaddLastBillUserBillNumber", selectedRow.vineoaddLastBillUserBillNumber);
            setValue("allowBillNumberedit", selectedRow.allowBillNumberedit);
            setValue("allowCreditNoteNumberedit", selectedRow.allowCreditNoteNumberedit);
            setValue("vineocreditNotePrefix", selectedRow.vineocreditNotePrefix);
            setValue("vineocreditNoteNumberStartSetting", selectedRow.vineocreditNoteNumberStartSetting);
            setValue("allowDebitNoteNumberedit", selectedRow.allowDebitNoteNumberedit);
            setValue("vineodebitNotePrefix", selectedRow.vineodebitNotePrefix);
            setValue("vineoDebitNoteNumberStartSetting", selectedRow.vineoDebitNoteNumberStartSetting);
            setValue("vineoFirmAccountopeningDate", selectedRow.vineoFirmAccountopeningDate);
            setValue("vineoFirmBirthDate", moment(selectedRow?.vineoFirmBirthDate).format(DATEFORMAT));
            // selectedRow?.vineoFirmBirthDate ?setBirthdate((moment(selectedRow?.vineoFirmBirthDate, 'DD-MM-YYYY'))) : <></>
            setValue("vineoFirmExitDate", selectedRow.vineoFirmExitDate);
            setValue("weeklyoffDays", selectedRow.weeklyoffDays);

            setOpeningdate(moment(selectedRow.vineoFirmAccountopeningDate, 'DD-MM-YYYY').toDate());
            // these values cant be null

            setValue("vineoaddBillPrintPageSize", selectedRow.vineoaddBillPrintPageSize);
            setValue("gstTaxScheme", selectedRow.gstTaxScheme);
            setValue("gstregAsReversecharge", selectedRow.gstregAsReversecharge);
            setValue("gstregAsEcommerce", selectedRow.gstregAsEcommerce);
            setValue("vineoaddBillFormat", selectedRow.vineoaddBillFormat);
            setValue("vineoFirmState", selectedRow.vineoFirmState);
            // setValue("vineoFirmState", selectedRow.vineoFirmState, { shouldValidate: true, shouldDirty: true });

            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
            setValue("vineofirmtermandconstionsforbill", selectedRow.vineofirmtermandconstionsforbill);
            setValue("vineoFirm_GSTMVApiKey", selectedRow.vineoFirm_GSTMVApiKey);
            setValue("vineoFirm_GSTMVSecretKey", selectedRow.vineoFirm_GSTMVSecretKey);
            setValue("vineoFirm_GSTAPIPASSWORD", selectedRow.vineoFirm_GSTAPIPASSWORD);
            setValue("vineoFirm_GSTAPIUSERNAME", selectedRow.vineoFirm_GSTAPIUSERNAME);
            setValue("vineoFirmType", selectedRow.vineoFirmType);
            setValue("rateroundinglogicValue", selectedRow.rateroundinglogicValue);
            setValue("firmGstVerified", selectedRow.firmGstVerified);
            setGstVerified(selectedRow.firmGstVerified);
            setValue("igstApplicableonIntraState", selectedRow?.igstApplicableonIntraState);
            setValue("vineoFirmMobilenumber", selectedRow?.vineoFirmMobilenumber);
            setValue("vineoFirmFirmpancard", selectedRow?.vineoFirmFirmpancard);
            setValue("paytm_merchantId", selectedRow?.paytm_merchantId);
            setValue("paytm_merchantKey", selectedRow?.paytm_merchantKey);
            setValue("federalmENCPKEY", selectedRow?.federalmENCPKEY);
            setValue("federalMID", selectedRow?.federalMID);
            setValue("vineoBankName", selectedRow?.vineoBankName);
            setValue("vineoBankAccountNumber", selectedRow?.vineoBankAccountNumber);
            setValue("vineoBankifscCode", selectedRow?.vineoBankifscCode);
            if (selectedRow?.vineoFirmAccountopeningDate) {
                const openingDate = new Date(selectedRow.vineoFirmAccountopeningDate);
        
                if (!isNaN(openingDate.getTime())) {
                    console.log("Parsed DOB Date:", openingDate);
                    setOpeningdate (openingDate);
                } else {
                    console.log("Invalid Opening Date:", selectedRow.vineoFirmAccountopeningDate);
                }
            } else {
                console.log("Date is empty or undefined");
            }
        }
        if (selectedRow?.vineoFirmBirthDate) {
            const birthDate1 = new Date(selectedRow.vineoFirmBirthDate);
            if (!isNaN(birthDate1.getTime())) {
                console.log("Parsed DOB Date:", birthDate1);
                setBirthdate(birthDate1);
            } else {
                console.log("Invalid DOB Date:", selectedRow.vineoFirmBirthDate);
            }
        } else {
            console.log("DOB Date is empty or undefined");
        }
        console.log("Update Firm ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Firm ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTEDFIRMDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- FIRM  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoFirmFirmName)
                    reset();
                }
                else { notifyError("Error While Deleting FIRM  Or Login as ADMIN") }
            },
            (err) => {
                notifyError("Error While Deleting FIRM Try Again  Or Login as ADMIN")

            }
        )
    }

    const getGSTDetailsfromNumber = (gstnumber) => {
        //call gst to get details

        gstnumber = gstnumber.toUpperCase();
        let pattern = /^([0][1-9]|[1-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
        let result = pattern.test(gstnumber);

        if (result) {
            console.log("gstNumber", gstnumber)
            console.log("result", result)
            setGstAPIreqClicked(true)
            {

                http.post(BASEURL_JWTAUTH + GSTVALIDATEAPIURLWITHKEY + gstnumber).then(
                    (res) => {
                        console.log(res)
                        console.log("JSON VAlue is ")
                        console.log(JSON.stringify(res.data))
                        console.log((res.data.flag))

                        if (res.data.flag) {

                            console.log(res.data?.data?.tradeNam);
                            setValue("vineoFirmFirmName", res.data?.data?.tradeNam);
                            setValue("vineoFirm_legalName", res.data?.data?.lgnm);
                            setValue("vineoFirmState", res.data?.data?.pradr?.addr?.stcd.trim());
                            setValue("vineoFirmFirmaddress1", res.data?.data?.pradr?.addr?.flno + res.data?.data?.pradr?.addr?.bno + "," + res.data?.data?.pradr?.addr?.bnm);
                            setValue("vineoFirmFirmaddress2", res.data?.data?.pradr?.addr?.st + "," + res.data?.data?.pradr?.addr?.loc);
                            setValue("vineoFirmCity", res.data?.data?.pradr?.addr?.dst.trim());
                            setValue("vineoFirmPincode", (res.data?.data?.pradr?.addr?.pncd.trim()));
                            setGstVerified(true)
                            notify('Success:- Api Details Fetched from API ')
                            setGstAPIreqClicked(true);



                        }
                        else { notifyError('Error While GST Account Info From Server') }
                        //console.log(res.data)
                        setGstAPIreqClicked(false);
                    },
                    (err) => {
                        setGstAPIreqClicked(false)
                        notifyError('Error While Getting Account Info')

                    }
                )
            }
        }
        else {
            setGstAPIreqClicked(false);
            notifyError("GST Number is Incorrect ")
        }




    }

    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTEDFIRMDATA, data).then(
            (res) => {
                if (res.data.errorCode == "0000") {
                    notify('Firm Created with Internal Id')
                    reset();
                }
                else {
                    notifyError("Error While registering Firm Info From Server As Response = " + res.data.errorMessage)
                }  //  console.log(res.data)

            },
            (err) => {


                notifyError("Error While Registering Firm ")
            }
        )
    }

    const submitForm1 = () => {
        // api call
        http.get('http://localhost:9595/jwtauth/healthcheck/JsonofUrl').then(
            (res) => {
                console.log(res.data)

            },
            (err) => {

                notifyError("Error While Registering Firm ")
            }
        )
    }


    return (
        <div>

            <h3 className='justify-content-center m-1'>Add Firm Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={3}>
                        <Row>
                            <ToolkitProvider keyField='ultiVineoFirmUniqeId'
                                data={dataofvineoTableData ? dataofvineoTableData : []}
                                columns={columns}
                                search   >
                                {
                                    props => (
                                        <div>
                                            <div >
                                                <Button variant="primary"
                                                    onClick={getdatafromserver} >
                                                    <FontAwesomeIcon icon={faRotate} />
                                                </Button>
                                                {/* <Button variant="primary"
                                                    onClick={submitForm1} >
                                                    <FontAwesomeIcon icon={faRotate} />
                                                </Button> */}
                                                <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                                <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                                <SearchBar className="m-1" {...props.searchProps} />
                                            </div>
                                                 
                                                    <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4 
                                                {...props.baseProps}
                                                pagination={paginationFactory(options)}
                                                selectRow={selectRow}
                                            // rowEvents={ rowEvents }
                                            />
                                           

                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        </Row>
                        <Row>

                            <ListGroup>
                                <h2>Some Firm Stats</h2>
                                <ListGroup.Item>PendingfromParty :{selectedRow?.vineofirmGst?.totalbillamountrequiredforCustomer}</ListGroup.Item>
                                <ListGroup.Item>Total Sale :{selectedRow?.totalsale}</ListGroup.Item>
                                <ListGroup.Item>Total Bills :{selectedRow?.totalNumberofbills}</ListGroup.Item>
                                <ListGroup.Item>Cancel Bills :{selectedRow?.canceledbills}</ListGroup.Item>
                                <ListGroup.Item>Credit Issued :{selectedRow?.creditissued}</ListGroup.Item>
                                <ListGroup.Item>Receipt Credit Issued :{selectedRow?.creditissuedforReceipt}</ListGroup.Item>
                                <ListGroup.Item>Credit Used :{selectedRow?.creditapplied}</ListGroup.Item>
                            </ListGroup>
                        </Row>

                        {/* <BootstrapTable className="p-2" keyField='username'
                            data={vineoUsers}
                            columns={columns}
                           
                            selectRow={selectRow}
                             /> */}

                    </Col>

                    <Col md={9}>
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        {console.log(BASEURL_JWTAUTH)}
                                        <Col md={3}>
                                            <FormGroup >

                                                <Form.Text className="form-outline">
                                                    Firm Code
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoFirmCode")}  />

                                            </FormGroup>
                                        </Col>
                                        {setValue("firmGstVerified", gstVerified)}
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Firm Type
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("vineoFirmType")}>
                                                    <option value="DIST">DISTRIBUTOR</option>
                                                    <option value="RET">RETAILER</option>
                                                    <option value="SUP">SUPERSTOCKIST</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>
                                        <Col md={4}> <FormGroup ><Form.Text className={(isEditDataSelected && selectedRow?.firmGstVerified) ? "text-primary" : "text-muted"} >
                                            GstNumber* -{((isEditDataSelected && selectedRow?.firmGstVerified) || gstVerified) ? "Verified" : "UnVerified"}
                                        </Form.Text>
                                            <InputGroup>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirmGstNumber")} required />


                                                <Button variant="info" disabled={gstAPIreqClicked}
                                                    onClick={() => { setGstAPIreqClicked(true); getGSTDetailsfromNumber(watch('vineoFirmGstNumber')) }} >
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </Button>
                                            </InputGroup>
                                        </FormGroup>

                                        </Col>


                                        <Col md={2}>
                                            <FormGroup >
                                                <Form.Text className="form-outline">
                                                    Firm Unique ID
                                                </Form.Text>
                                                <fieldset disabled>

                                                    <Form.Control className="form-control "
                                                        placeholder="Firm ID: This Firm Code Will Be Auto Generated"
                                                        {...register("ultiVineoFirmUniqeId")} />
                                                </fieldset>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    Firm Name*
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoFirmFirmName")} required />

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    Firm Legal Name*
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoFirm_legalName")} required />

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    AutoPurchaseImportActive*
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" {...register("autoPurchaseImportActive")} required >
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    Firm Unique OTP*
                                                </Form.Text>
                                                <Form.Control type="Number" className="form-control custom-form-field" size='sm'  {...register("vineoFirmUniqueOTP")} required />

                                            </FormGroup></Col>


                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Firm Address Line-1
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoFirmFirmaddress1", { minLength: 10 })} />

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Firm Address Line-2
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoFirmFirmaddress2", { minLength: 4 })} />

                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Firm PAN CARD No
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoFirmFirmpancard", { minLength: 4 })} required />

                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    AllowMinusStock
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("vineoAllowMinusStock")}  >

                                                    <option value="false">No</option>
                                                    <option value="true">Yes</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    City
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirmCity")} />

                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    State
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirmState")} />

                                            </FormGroup>
                                        </Col>
                                        {/* <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    LandLineNumber/Mobile
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" type='phone' size='sm' {...register("vineoFirmPhonenumber") } required/>
                                            </FormGroup>
                                        </Col> */}

                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    10 Digit Mobile Number*
                                                </Form.Text>

                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoFirmMobilenumber", { minLength: 10, maxLength: 10 })} required />
                                                {errors.mobilenumber && <span>Enter Mobile Number as 10 digit </span>}
                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Emailid*
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" type="email" size='sm'  {...register("vineoFirmEmailid")} required />

                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Pincode*
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" type="number" size='sm'  {...register("vineoFirmPincode")} required />

                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Country
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" defaultValue="INDIA" size='sm' {...register("vineoFirmCountry")} />

                                            </FormGroup>
                                        </Col>


                                        <Col md={3}>

                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    Firm Registration Number
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirmRegNumber")} />

                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    GST Tax Scheme
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("gstTaxScheme")}  >
                                                    <option value="GST">GST</option>
                                                    <option value="Composition">Composition</option>
                                                    <option value="Other">Other</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    GST AS Rev. Charge
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("gstregAsReversecharge")}  >
                                                    <option value="N">NO</option>
                                                    <option value="Y">YES</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col><Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    GSTAS Ecomm Compnay
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("gstregAsEcommerce")}  >

                                                    <option value="null">NO</option>
                                                    <option value="Y">YES</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>


                                    </Row>

                                    <Row>
                                        <Col md={3}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    GSTAPIUSERNAME
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirm_GSTAPIUSERNAME")} />
                                            </FormGroup>
                                        </Col> <Col md={3}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    GSTAPIPASSWORD
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirm_GSTAPIPASSWORD")} />
                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    GSTAPIKEY
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirm_GSTMVApiKey")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    GSTAPISECRET
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirm_GSTMVSecretKey")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    paytm_merchantId
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("paytm_merchantId")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    paytm_merchantKey
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("paytm_merchantKey")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    FederalBank_merchantId
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("federalMID")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    FederalBank_merchantKey
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("federalmENCPKEY")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                             
                                                    <Form.Text className="text-muted">
                                                        Bank*
                                                    </Form.Text>
                                                   <Row>
                                                    <Col md ={3}><Form.Control className="form-control custom-form-field" placeholder='Bank Name' size='sm' {...register("vineoBankName")} required /></Col>
                                                    <Col md={3}><Form.Control className="form-control custom-form-field" placeholder='ifsc Code' size='sm' {...register("vineoBankifscCode")} required /></Col>
                                                    <Col>    <Form.Control className="form-control custom-form-field" placeholder='Accnt Number' size='sm' {...register("vineoBankAccountNumber")} required /></Col>
                                                   </Row>
                                                


                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    Drug Lic. No
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirmDLNumber")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    Food License Number i.e Fasai
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoFirmFasi")} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text>
                                                    Bill Prefix Curr-{selectedRow?.vineoaddBillPrefix}
                                                </Form.Text>
                                                <Form.Control pattern="^\S*$" className="form-control custom-form-field" defaultValue={""} size='sm' {...register("vineoaddBillPrefix", {})} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    BillNO.Series Curr. Bill No -{selectedRow?.vineoaddBillUserBillNumberStartSetting}
                                                </Form.Text>
                                                <Form.Control type="Number" pattern="[0-9]*" className="form-control custom-form-field" size='sm' {...register("vineoaddBillUserBillNumberStartSetting", {})} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Allow Bill Number Edit
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("allowBillNumberedit")}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        {/* credit Note Details */}
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text>
                                                    CreditNote Prefix Curr-{selectedRow?.vineocreditNotePrefix}
                                                </Form.Text>
                                                <Form.Control pattern="^\S*$" className="form-control custom-form-field" defaultValue={""} size='sm' {...register("vineocreditNotePrefix", {})} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    CCNO.Series Curr. CC No -{selectedRow?.vineocreditNoteNumberStartSetting}
                                                </Form.Text>
                                                <Form.Control type="Number" pattern="[0-9]*" className="form-control custom-form-field" size='sm' {...register("vineocreditNoteNumberStartSetting", {})} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Allow Credit Note Number Edit
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("allowCreditNoteNumberedit")}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>

                                        {/* Debit Note Setting */}
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text>
                                                    DebitNote Prefix Curr-{selectedRow?.vineodebitNotePrefix}
                                                </Form.Text>
                                                <Form.Control pattern="^\S*$" className="form-control custom-form-field" defaultValue={""} size='sm' {...register("vineodebitNotePrefix", {})} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    DDNO.Series Curr. DD No -{selectedRow?.vineoDebitNoteNumberStartSetting}
                                                </Form.Text>
                                                <Form.Control type="Number" pattern="[0-9]*" className="form-control custom-form-field" size='sm' {...register("vineoDebitNoteNumberStartSetting", {})} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Allow Debit Note Number Edit
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("allowDebitNoteNumberedit")}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        {/* Load Setting  */}
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text>
                                                    Load Prefix Curr-{selectedRow?.vineoaddLoadPrefix}
                                                </Form.Text>
                                                <Form.Control pattern="^\S*$" className="form-control custom-form-field" defaultValue={selectedRow?.vineoaddLoadPrefix} size='sm' {...register("vineoaddLoadPrefix", {})} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    LoadNO.Series Curr. Load No -{selectedRow?.vineoaddLoadUserLoadNumberStartSetting}
                                                </Form.Text>
                                                <Form.Control type="Number" pattern="[0-9]*" defaultValue={selectedRow?.vineoaddLoadUserLoadNumberStartSetting} className="form-control custom-form-field" size='sm' {...register("vineoaddLoadUserLoadNumberStartSetting", {})} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Allow Load Note Number Edit
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("allowLoadNumberedit")}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Allow GST Reverse Charge
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("gstRevChargeisY")}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col> */}
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Bill Formate
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("vineoaddBillFormat")}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Bill Page Print
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("vineoaddBillPrintPageSize")}>
                                                    <option value="vineoaddBillPrintPageSizeA4">A4</option>
                                                    <option value="vineoaddBillPrintPageSizeA5">A5</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Opening Date
                                                </Form.Text>

                                                <ReactDatePicker className="form-control"
                                                    selected={openingdate}
                                                    onChange={setOpeningdate}
                                                    name="CustomerOpejing Date"
                                                    dateFormat="dd-MM-yyyy"
                                                    disabled

                                                />
                                                {setValue("vineoFirmAccountopeningDate", moment(openingdate).format(DATEFORMAT))}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted custom-form-field">
                                                    Rounding Logic Value
                                                </Form.Text>
                                                <Form.Control disabled={!isUserHaveSuperadminAuthority} size='sm' {...register("rateroundinglogicValue")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Birth Date
                                                </Form.Text>

                                                <ReactDatePicker className="form-control"
                                                    selected={bitrhdate}
                                                    onChange={setBirthdate}
                                                    name="Birth Date"
                                                    dateFormat="dd-MM-yyyy"
                                                   
                                                />
                                                {setValue("vineoFirmBirthDate", moment(bitrhdate).format(DATEFORMAT))}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Exit Date
                                                </Form.Text>

                                                <ReactDatePicker className="form-control"
                                                    selected={exitdate}
                                                    onChange={setExitdate}
                                                    name="Exit Date"
                                                    dateFormat="dd-MM-yyyy"
                                                    disabled

                                                />
                                                {/* {setValue("vineoFirmExitDate", moment(exitdate).format(DATEFORMAT))} */}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group controlId="logoformFile" className="mb-3">
                                                <Form.Text>Default Firm Logo</Form.Text>
                                                <Form.Control type="file" {...register("firmlogofiletoconvert")} accept=".bmp,.jpeg,.png,.jpg" />
                                                {/* <input type="file" onChange={handleFileChange} /> */}
                                                {/* <Form.Control type="file" onClick={(e)=> {e.currentTarget.value = null}}  onChange={(e)=>{console.log(e.target.file)}}  multiple={true} accept=".bmp,.jpeg,.png, .docx,.ppt, .pptx,.txt,.pdf"/> */}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>

                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    igstApplicableonIntraState
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("igstApplicableonIntraState")}>
                                                    <option value="N">NO</option>
                                                    <option value="Y">YES</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col >
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                weeklyoffDays : 1=Sund,2=mon..7=Sat
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("weeklyoffDays")}/>
                                                   
                                               
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <FormGroup>
                                            <Form.Text className="text-muted">
                                                Terms and Conditions for Bill

                                            </Form.Text>

                                            <Form.Control as="textarea" defaultValue='Cheque Return Charges : 500/-
                                                Please Check Items Before Taking Delivery
                                                Subject To state Jurisdiction Only...'  rows={3} size='sm' {...register("vineofirmtermandconstionsforbill")} />
                                        </FormGroup>
                                    </Row>

                                    <center>
                                        {!isEditDataSelected ?
                                            <input className="btn btn-primary m-2" type="submit" value="Add Firm " />
                                            : <input className="btn btn-primary m-2" type="submit" value="Update Firm" />}
                                        <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                    </center>
                                </form>
                            </Card>
                        </div>


                    </Col>
                </Row>

            </div>
            <div>
                <Modal show={openAddBankAccnt}
                    onHide={() => { setOpenAddBankAccnt(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <CustomerBankInfo />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { setOpenAddBankAccnt(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    )

}