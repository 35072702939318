import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow, Polyline } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../../constants/Urlforvineo";

const ShopVisitMap = ({ dataArray }) => {
  // Set the default center of the map (you can modify this as needed)
  const defaultCenter = { lat: 18.6860691, lng: 73.8353013 };

  // State to manage the currently selected marker for InfoWindow
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Handle InfoWindow visibility
  const handleToggleInfoWindow = (marker) => {
    setSelectedMarker(marker);
  };

  // Get unique visitOrders from the dataArray
  const visitOrders = dataArray
    .map((item) => item.visitOrder)
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <GoogleMap mapContainerStyle={{ height: "800px", width: "100%" }} center={defaultCenter} zoom={12}>
        {/* Draw Polylines based on visitOrder */}
        {visitOrders.map((order, index) => {
          const markersWithOrder = dataArray.filter((item) => item.visitOrder === order);
          const path = markersWithOrder.map((item) => ({ lat: parseFloat(item.lati), lng: parseFloat(item.longi) }));
          return <Polyline key={index} path={path} options={{ strokeColor: "blue", strokeWeight: 2 }} />;
        })}

        {/* Markers */}
        {dataArray.map((item, index) => (
          <Marker
            key={index}
            position={{ lat: parseFloat(item?.lati), lng: parseFloat(item?.longi) }}
            title={item?.partyName || "Unknown Party"}
            onClick={() => handleToggleInfoWindow(item)} // Pass the marker object to the handler
          />
        ))}

        {selectedMarker && (
          <InfoWindow
            position={{ lat: parseFloat(selectedMarker?.lati), lng: parseFloat(selectedMarker?.longi) }}
            onCloseClick={() => setSelectedMarker(null)} // Reset selected marker on InfoWindow close
          >
            {/* The content inside the InfoWindow */}
            <div>
              <p>Nameofuser: {selectedMarker?.nameofUser}</p>
              <p>Party: {selectedMarker?.partyName}</p>
              <p>Address: {selectedMarker?.address}</p>
              {/* Add other properties you want to display in the InfoWindow */}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default ShopVisitMap;
