import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import { Typeahead } from 'react-bootstrap-typeahead';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_UPDATE_SELECTEDSUBCATEGORYDATA, VINEOFORM_DELETE_SELECTEDSUBCATEGORYDATA } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';
import { getVineoCompanyCategoryData, getVineoCompanyData, getVineoCompanySubCategoryData } from '../../../redux/features/services/serviceHelper';

export default function SubCategoryInfo() {
    useEffect(() => {

        // dispatch(getVineoCompanyData());
        dispatch(getVineoCompanyCategoryData())
    }, []);

    // const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    // const vineooldCompnaydata = useSelector((state) => state.vineoData.vineocompany)
    const vineooldCategorydata = useSelector((state) => state.vineoData.vineocategory)

    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSubCategoryUniqeId": "Working", "companySubCategoryName": "Click Refresh To Load" }])
    const [selected, setSelected] = useState([]);
    const dispatch = useDispatch()
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const count = useSelector((state) => state.vineoData.value)

    const vineosubcategory = useSelector((state) => state.vineoData.vineosubcategory)
    const isvineosubcategoryLoaded = useSelector((state) => state.vineoData.isvineosubcategoryLoaded)


    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        if(selected[0])
        {
            submitForm(data)
        }
        else{
            notify("Pls Select Category")
        }
     
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)

        }

    };
    const { SearchBar } = Search;
    const columns = [
        {
            dataField: 'companySubCategoryName',
            text: 'SubCAT.',
            sort: true,
            formatter: rowClickedfunction,
        },

        {
            dataField: 'vineoCategory.companyCategoryName',
            text: 'CAT.',
            sort: true,

        }, {
            dataField: 'vineoCategory.vineoCompany.companyName',
            text: 'Company Name',
            sort: true
        },
    ];


    const numbers = [1, 2, 3, 4, 5];
    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const updateDatainForm = () => {
        console.log(selectedRow);
        setIsEditDataSelected(true);
        setValue("companySubCategoryName", selectedRow.companySubCategoryName);
        setValue("ultiVineoSubCategoryUniqeId", selectedRow.ultiVineoSubCategoryUniqeId);
        setValue("vineoCategory.ultiVineoCategoryUniqeId", selectedRow.vineoCategory.ultiVineoCategoryUniqeId);
        setValue("vineoCategory.companyCategoryName", selectedRow.vineoCategory.companyCategoryName);

        console.log("Selected Category is " + selectedRow.vineoCategory.companyCategoryName)
        // setValue("vineoCategory.companyCategoryName", selectedRow.vineoCategory.companyCategoryName);
        // setValue("vineoCategory.vineoCompany", selectedRow.vineoCategory.vineoCompany);





    }


    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Area ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTEDSUBCATEGORYDATA, requestdata).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorCode == "0000") {
                    notify('Success:- SUB CAT  DELETED SUCCESSFULLY with NAME is = ' + requestdata.companySubCategoryName)
                }
                else
                    notify("Error While Deleting SUBCAT  Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting SUBCAT Try Again  Or Login as ADMIN")

            }
        )
    }

    const getdatafromserver = () => {
        console.log("Called to get SubCategory Data ")
        dispatch(getVineoCompanySubCategoryData())
        console.log(isvineosubcategoryLoaded)
        if ((typeof vineosubcategory != 'undefined') && Object.keys(vineosubcategory).length > 0) {
            isvineosubcategoryLoaded ? setDataofvineoTableData(vineosubcategory) : setDataofvineoTableData([{ "ultiVineoSubCategoryUniqeId": "Working", "companySubCategoryName": "Click Refresh To Load" }])
        }
        console.log("Empty Data Received form Server")


    }


    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTEDSUBCATEGORYDATA, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorCode == "0000") {
                    notify('Success:- SubCategory  Created with Internal Id')
                    console.log(res.data)
                    reset()
                }
                else
                    notify("Error While Creating SubCategory From Server ")


            },
            (err) => {
                notify("Error While Creating SubCategory  ")
            }
        )
    }



    return (
        <div>

            <h3 className='justify-content-center m-1'>SubCategory Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoSubCategoryUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >


                                        <fieldset disabled>
                                            <Form.Control className="form-control "
                                                placeholder="SubCategory Code: This Firm Code Will Be Auto Generated"
                                                {...register("ultiVineoSubCategoryUniqeId")} />
                                        </fieldset>
                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            SubCategory Code
                                        </Form.Text>
                                        <Form.Control  className="form-control "   {...register("vineoSubcategoryCode")} required />
                                    </FormGroup>
                                    <FormGroup >

                                        <Form.Text >
                                            SubCategoryName
                                        </Form.Text>
                                        <Form.Control className="form-control "   {...register('companySubCategoryName')} required />

                                    </FormGroup>

                                    <Form.Group controlId="formBasicSelect">
                                        <Form.Text >
                                            Category Name || Company Name
                                        </Form.Text>
                                        
                                    </Form.Group>
                                        <Typeahead
                                            id="SubgroupSlected"
                                            onChange={setSelected}
                                            onClick={console.log(selected)}
                                            options={vineooldCategorydata}
                                            placeholder="Choose Category Type Name..."
                                            selected={selected}
                                            labelKey={"companyCategoryName"}
                                            renderMenuItemChildren={(option) => (
                                                <div>
                                                  <div>
                                                    <small>Category:  {option.companyCategoryName}</small>
                                                    <small> Company: {option.vineoCompany.companyName}</small>
                                                  </div>
                                                </div>
                                              )}

                                        />
                                        {console.log("Selected" + selected)}
                                        {setValue("vineoCategory.ultiVineoCategoryUniqeId", selected?.[0]?.ultiVineoCategoryUniqeId)}
                                  
                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add SUB Category " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update SUB Category" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>



                            </Card>
                        </div>

                    </Col>
                </Row>

            </div>
        </div >
    )

}
