import { useState } from "react";
import AuthUser from "../../constants/AuthUser";
import SliderForLoginPage from "./SliderForLoginPage";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  BASEURL_JWTAUTH,
  GET_JWT_TOKEN,
  HEALTHCHECK,
} from "../../constants/Urlforvineo";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import backgroundImage from "../../images/3.jpg";
import { FormGroup, Label, Input, Button, Form } from "react-bootstrap";
import {  getVineoFirmData } from "../../redux/features/services/serviceHelper";
import { isUserHaveSuperadminAuthority } from "../../redux/features/vineoData/vineoDataSlice";

export default function LogingetSuperadminAuthority() {
  const { http, setToken } = AuthUser();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  // const { register, handleSubmit } = useForm();
  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const notifyWarning = (message) => toast.warning(message);

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      vineoRegisteredClientName: "",
      password: "",
      username: "",
      //   isDeveloper: true
    },
  });

  const onSubmit = (data) => {
    submitForm(data);
  };

  const testserverdeployment = () => {
    axios.get(BASEURL_JWTAUTH + HEALTHCHECK).then(
      (res) => {
        console.log(res.data);
        notify(res.data);
      },
      (errr) => {
        console.log(errr);
        notifyError("Error During login & Error code is - " + errr);
      }
    );
  };
  const submitForm = (data) => {
    axios.post(BASEURL_JWTAUTH + GET_JWT_TOKEN, data).then(
      (res) => {
        // console.log(res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName)
       
         if( res.data.role==='superadmin')
         {
          console.log(res.data.role)
          dispatch(isUserHaveSuperadminAuthority())
         }
         
        notify("Login Success "+ res.data.role);

      },
      (errr) => {
        console.log(errr);
        notifyError("Error During login & Error code is:- Bad Credentials");
      }
    );
  };
  const styles = {
    backgroundImage: `url(${backgroundImage})`,
    height: "88vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    
  
  };

  return (
    <div>
     {/* <a className="Nav-brand-logo justify-content-end " href="www.google.com">

Instagram

</a> */}
      <div style={styles} className="row justify-content-end pt-5">
        <div className="col-sm-4 ">
          <div className="mt-1 p-5">
            <div>
            
              <p class="text-center fst-italic">
                Innovation isn,t just what we do
              </p>

              {/* <p class="text-center ">
                Don't have an account?{" "}
                <button class="btn default">Sign Up</button>
              </p> */}

              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup className="mt-1">
                  <Form.Control
                    className="form-control"
                    placeholder="Client Name "
                    {...register("vineoRegisteredClientName")}
                    required
                  />
                </FormGroup>
                <FormGroup className="mt-2">
                  <Form.Control
                    className="form-control"
                    placeholder="Enter Your Username"
                    {...register("username")}
                    required
                  />
                </FormGroup>

                <FormGroup className="mt-2  ">
                  <Form.Control
                    className="form-control"
                    type="password"
                    placeholder="Enter Your Password "
                    {...register("password")}
                    required
                  />
                </FormGroup>

                <FormGroup className="text-center">
                  <input className=" btn btn-success m-2 " type="submit" />
                  {/* <Button className="btn btn-primary m-2" color="primary" onClick={testserverdeployment}>
                                Test Server
                                </Button> */}
                </FormGroup>
                {watch(`vineoRegisteredClientName`) === "2222" ? (
                  <FormGroup className="mt-3">
                    <Form.Text className="text-muted"></Form.Text>
                    <Form.Control
                      className="form-control"
                      placeholder="Enter Your TOPT "
                      {...register("usertotp")}
                    />
                  </FormGroup>
                ) : (
                  <></>
                )}

                <FormGroup className="text-center">
                  {/* <Button className="btn btn-primary m-2" color="primary" onClick={()=>{
                                  setValue("username", "user");
                                  setValue("password", "user");
                                  setValue("vineoRegisteredClientName", "9090");

                            }}>
                                DemoLogin
                                </Button> */}
                                <br></br>
              <img src="milex.png" width={300} alt="logo" />

                </FormGroup>
              </form>
            </div>
          </div>
        </div>
        {/* <SliderForLoginPage /> */}
      </div>
    </div>
  );
}