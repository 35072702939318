import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import { getVineoTransportMasterData, getVineoVanMasterData } from '../../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEOFORM_UPDATE_SELECTED_VAN_NAMEDATA, VINEOFORM_DELETE_SELECTED_VAN_NAMEDDATA, GSTVALIDATEAPIURLWITHKEY, VINEOFORM_UPDATE_SELECTED_TRANSPORT_NAMEDATA, VINEOFORM_DELETE_SELECTED_TRANSPORT_NAMEDDATA } from '../../../constants/Urlforvineo';
import axios from 'axios';

export default function TransportEntry() {

    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoVanUniqeId": "Working", "vineoVanName": "Click Refresh To Load" }])
    const isvineotransportmasterdataLoaded = useSelector((state) => state.vineoData.isvineotransportmasterdataLoaded)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const vineodata = useSelector((state) => state.vineoData.vineotransportmasterdata)
    
    const dispatch = useDispatch()
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [gstVerified, setGstVerified] = useState(false);
    const [gstAPIreqClicked, setGstAPIreqClicked] = useState(false)

    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }

    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)


    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'transName',
            text: 'Name',
            sort: true,
            formatter: rowClickedfunction,
        },
        {
            dataField: 'transGSTIN',
            text: 'GST',
            sort: true,
        },

        {
            dataField: 'transRtoNumber',
            text: 'RTO No',
            sort: true,
        }
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };

    const getdatafromserver = () => {

        console.log("Called to get Van Data ")
        dispatch(getVineoTransportMasterData())

    }
    useEffect(() => {
        // dispatch(getVineoFirmData());
     
    }, []);

    useEffect(() => {
        // dispatch(getVineoFirmData());
        console.log("Refreshing on state Change ")
        setDataofvineoTableData(vineodata)
    }, [refreshcount]);

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoTransportUniqeId", selectedRow.ultiVineoTransportUniqeId);
            setValue("transName", selectedRow.transName);
            setValue("transCode", selectedRow.transCode);
            setValue("translgal_Name", selectedRow.translgal_Name);
            setValue("transRtoNumber", selectedRow.transRtoNumber);
            setValue("transGSTIN", selectedRow.transGSTIN);
            setValue("transaddress", selectedRow.transaddress);
            setValue("transCity", selectedRow.transCity);
            setValue("transState", selectedRow.transState);
            setValue("transpincode", selectedRow.transpincode);
            setValue("transgstVerfied", selectedRow.transgstVerfied);
            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);


        }
        console.log("Update VAN DATA ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Transport DATA ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_TRANSPORT_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Tranport DATA  DELETED SUCCESSFULLY with NAME is = ' + requestdata.tradeNam)
                }
                else
               { notifyError("Error While Deleting Transport DATA  Or Login as ADMIN")}
            },
            (err) => {
                notifyError("Error While Deleting Transport DATA Try Again  Or Login as ADMIN")

            }
        )
    }


    const getGSTDetailsfromNumber = (gstnumber) => {
        //call gst to get details

        let pattern = /^([0][1-9]|[1-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
        let result = pattern.test(gstnumber);

        if (result) {
            console.log("gstNumber", gstnumber)
            console.log("result", result)
            setGstAPIreqClicked(true)
            {

                axios.get(GSTVALIDATEAPIURLWITHKEY + gstnumber).then(
                    (res) => {
                        console.log(res)
                        console.log("JSON VAlue is ")
                        console.log(JSON.stringify(res.data))
                        console.log((res.data.flag))

                        if (res.data.flag) {

                            console.log(res.data?.data?.tradeNam);
                            setValue("transName", res.data?.data?.tradeNam);
                            setValue("translgal_Name", res.data?.data?.lgnm);
                            setValue("vineoCustomerAccountstate", res.data?.data?.pradr?.addr?.stcd.trim());
                            setValue("transaddress", res.data?.data?.pradr?.adr+ res.data?.data?.pradr?.addr?.bnm + res.data?.data?.pradr?.addr?.st.trim() );
                            setValue("transCity", res.data?.data?.pradr?.addr?.dst.trim());
                            setValue("transpincode",( res.data?.data?.pradr?.addr?.pncd).trim());
                            setGstVerified(true)

                            notify('Success:- Api Details Fetched from API ')
                            setGstAPIreqClicked(true);

                        }
                        else { notifyError('Error While GST Account Info From Server') }
                        //console.log(res.data)
                        setGstAPIreqClicked(false);
                    },
                    (err) => {
                        setGstAPIreqClicked(false)
                        notifyError('Error While Getting Account Info')

                    }
                )
            }
        }
        else {
            setGstAPIreqClicked(false);
            notifyError("GST Number is Incorrect ")
        }




    }

    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_TRANSPORT_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify(' Success:-  VAN Created with Internal Id')
                    reset()
                }
                else
               { notifyError("Error While registering VAN Info From Server ")}
                //  console.log(res.data)

            },
            (err) => {


                notifyError("Error While Registering VAN ")
            }
        )
    }


    return (
        <div>
            <h3 className='justify-content-center m-1'>Add TRANSPORTER Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={3}>
                        <ToolkitProvider keyField='ultiVineoVanUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={9}>
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >

                                        <fieldset disabled>

                                            <Form.Control className="form-control"
                                                placeholder="Transport ID: This Code Will Be Auto Generated"
                                                {...register('ultiVineoTransportUniqeId')} />
                                        </fieldset>
                                    </FormGroup>

                                    <Col md={4}>
                                        <FormGroup >
                                            <Form.Text className={(isEditDataSelected && selectedRow?.transgstVerfied)?"text-primary":"text-muted"}>
                                                GstNumber* {((isEditDataSelected && selectedRow?.transgstVerfied) || gstVerified)? "Verified" : "Unverified"}
                                            </Form.Text>
                                            <InputGroup>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("transGSTIN")} />
                                                <Button variant="info" disabled={gstAPIreqClicked}
                                                    onClick={() => { setGstAPIreqClicked(true); getGSTDetailsfromNumber(watch('transGSTIN')) }} >
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                        {setValue("transgstVerfied", gstVerified)}
                                    </Col>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Transporter Code 
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('transCode')}  />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                          Default Vehical RTO Number
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('transRtoNumber')}  />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            State*
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('transState')} required />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Transporter Name
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('transName')} required />

                                    </FormGroup>
                                    
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Transporter Legal Name
                                        </Form.Text>
                                        <Form.Control  className="form-control custom-form-field"   {...register('translgal_Name')} required />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Address
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('transaddress')} required />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            City
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('transCity')} required />

                                    </FormGroup>
                                    
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                          Pincode
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('transpincode')} required />

                                    </FormGroup>
                                   
                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add" />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>
                        </div>


                    </Col>
                </Row>

            </div>
        </div>
    )

}