import React from 'react'
import { useEffect, useState, useRef } from "react"
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller, useForm, useWatch } from "react-hook-form";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getVineoGodownData, getVineoAccountCustomerMasterMenu, getVineoProductData, getVineoSchemaMasterData, getVineoCompanyData, getVineoFirmData, getVineoProductDatawithPositiveQtyinBatch, getLastBillNumberData } from '../../../../redux/features/services/serviceHelper';
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import {
    FormGroup, Label, Input, Modal,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Table
} from 'react-bootstrap';
import { stateOptions } from  '../../../../constants/GstStatewithcode';
import { BASEURL_JWTAUTH, BILLCRDITDAYS, DATEFORMAT, VINEOFORM_UPDATE_SELECTED_ADDBILL, VINEOFORM_UPDATE_SELECTED_GON, VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID } from '../../../../constants/Urlforvineo';
import AreaToSalesman from '../../mapping/AreaToSalesman';
import { AddBillItemAddline } from './AddBillItemAddline';
import http from '../../../../constants/AxiosConfig';
import PartyToArea from '../../mapping/PartyToArea';
import CustomerAccountInfo from '../../master/CustomerAccountInfo';
import { AddGONItem } from './AddGONItem';


let totalDiscountPercentagevalue = 0;


export default function AddSalesGON() {
    let currentDate = new Date();
    const partyinputref = useRef()

    const dispatch = useDispatch()
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)

    let lastBillNumberfromreduxfirm = 0;
    // const [open, setOpen] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const [selectedFirm, setSelectedFirm] = useState([])
    const [companySelections, setCompanySelections] = useState([]);
    const [godownSelections, setGodownSelections] = useState([]);
    const [grossBillValue, setGrossBillValue] = useState(0);
    const [billdate, setBilldate] = useState(new Date())
    const [billduedate, setBillduedate] = useState(new Date(currentDate.getTime() + BILLCRDITDAYS * 24 * 60 * 60 * 1000));
    const [selectedVendor, setSelectedVendor] = useState(new Date())
    const [productdamaged, setProductdamaged] = useState(true);
    const [credittabpressed, setCredittabpressed] = useState(false);
    const [creditnotecredit, setCreditnotecredit] = useState()
    const [selectedCreditNote, setSelectedCreditNote] = useState([])
    const [totalDiscountPercentage, setTotalDiscountPercentage] = useState(totalDiscountPercentagevalue)
    const [readyToSubmit, setReadyToSubmit] = useState(false)
    const [billprefix, setBillprefix] = useState()
    const [lastBillNumber, setLastBillNumber] = useState()
    const [billsubmited, setBillsubmited] = useState(1)
    const [lastBillNumberEdited, setLastBillNumberEdited] = useState()
    const [diffrentShipingaddress, setDiffrentShipingaddress] = useState(false)
    const [diffrentDispatchaddress, setDiffrentDispatchaddress] = useState(false)
    // const count = useSelector((state) => state.vineoData.value)
    const vineogodowndataTypeahead = useSelector((state) => state.vineoData.vineogodowndata)
    const vineocompany = useSelector((state) => state.vineoData.vineocompany)
    const vineoPartydata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)

    const vineofirmdatafromredux = useSelector((state) => state.vineoData.vineofirmdata)
    const [vineofirmdata, setVineofirmdata] = useState()


    const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();


    const navigate = useNavigate();

    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)

    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSchemeUniqeId": "Working", "vineoCompanySchemeNumber": "Click Add To Load Scheme" }])
    const isvineoschemamasterdataLoaded = useSelector((state) => state.vineoData.lastBillNumberisvineoschemamasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [openMapAreaToSalesMan, SetOpenMapAreaToSalesMan] = useState(false);
    const [openMapAreaToParty, SetOpenMapAreaToParty] = useState(false);
    const [editbillnumberalowed, SetEditbillnumberalowed] = useState(false);
    const [openAddParty, SetOpenAddParty] = useState(false);


    useEffect(() => {
        // dispatch(getVineoCompanyData());
        // dispatch(getVineoGodownData());
        dispatch(getVineoAccountCustomerMasterMenu());
        dispatch(getVineoFirmData())
        // dispatch(getLastBillNumberData())
        setVineofirmdata(vineofirmdatafromredux)
        partyinputref.current.focus();
        // dispatch(getVineoProductData());
    }, []);

    useEffect(() => {
        if (vineofirmdata?.[0]) {
            setSelectedFirm([vineofirmdata?.[0]])
        }
        else {
            dispatch(getVineoFirmData())
        }
        setVineofirmdata(vineofirmdatafromredux)
        console.log("selected firm is fro redux is  ", vineofirmdata?.[0])
        SetEditbillnumberalowed(vineofirmdata?.[0]?.allowBillNumberedit);
        lastBillNumberfromreduxfirm = vineofirmdatafromredux?.[0]?.vineoaddBillUserBillNumberStartSetting;
        setBillprefix(vineofirmdata?.[0]?.vineoaddBillPrefix)
        console.log("Last Bill Number is " + lastBillNumberfromreduxfirm)
        setLastBillNumber(parseInt(lastBillNumberfromreduxfirm) + 1)



    }
        , [refreshcount, billsubmited]);


    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        setReadyToSubmit(!readyToSubmit)
        console.log((data));
        if (selectedFirm[0]) {
            if (selectedCustomer[0]?.vineoArea?.salesman?.nameofUser && selectedCustomer[0]?.vineoArea?.vineoAreaName)
            // check for items in bill
            {
                if (data?.vineoDataforAddBillItem?.length > 0) { submitForm(data) }
                else {
                    notifyError("Please Add Items ")
                }

            }
            else {
                notifyError("Error Please Select Salesman or Do the Area to Party to Salesman Mapping ")
            }
        }
        else { notifyError("Please Select Firm ") }
    }


    const getcreditavaialblefromserver = () => {
        if (!selectedCustomer?.[0]) {
            notifyWarning("Please Select Party ")
        }
        else {
            http.post(BASEURL_JWTAUTH + VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID +
                selectedCustomer?.[0]?.ultiVineoCustomerAccountUniqeId).then(
                    (res) => {
                        console.log(res.data)
                        if (res.data?.[0]?.creditnoteAmount > '0') {
                            notify('Credit Amount is Available - ' + res?.data?.[0]?.creditnoteAmount)
                            setCreditnotecredit(res.data);
                            console.log(creditnotecredit);
                            console.log("CreditNote Above");

                        }
                        else
                            notifyError("Credit Note Amount is Not Available For Selected Customer- ")

                    },
                    (err) => {


                        notifyError("Error While Getting Credit amount Operation From Server ")
                    }
                )
        }



    }

    const getdatafromserver = () => {

        console.log("Called to get  Data ")

        dispatch(getVineoSchemaMasterData());
        // setTimeout(500);

        if (Object.keys(vineodata).length > 0) {
            isvineoschemamasterdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoSchemeUniqeId": "Working", "vineoSchemeName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }

    const submitForm = (data) => {
        console.log(data)
        console.log("Connecting Server to Create Bill")
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_GON, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorMessage == 'SUCCESS') {
                    notify('Add GON Operation Successful with Deatils = ' + res.data.errorCode)
                    dispatch(getVineoProductDatawithPositiveQtyinBatch())

                    setSelectedCustomer([])
                    reset();
                    partyinputref.current.focus();

                    // dispatch(getLastBillNumberData())
                    dispatch(getVineoFirmData())

                }
                else if (res.data.errorCode == '0062') {
                    notifyError('Failed Pls Change GON Number It Already Exists == Add Bill Operation Failed with Deatils = ' + res.data.errorCode)

                }
                else
                    notifyError("Error While Add GON Operation From Server with Message as " + res.data.errorCode)
                //  console.log(res.data)

            },
            (err) => {


                notifyError("Error While Add Bill Operation From Server ")
            }
        )
    }


    return (
        <div>

            <div>
            <div className='h5 mb-0 text-gray-800'>
                    <center>
                        <h5 className='justify-content-center m-1'>Add GON Firm Name :-{(selectedFirm?.[0]?.vineoFirmFirmName)}</h5>
                    </center>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <div>
                                <Row>
                                <Col md={3}>
                                        <Form.Text >Select Firm *  GST:- {(selectedFirm?.[0]?.vineofirmGst?.vineoGstNumber)}  -\Code :{(selectedFirm?.[0]?.vineofirmGst?.vineoGstCode)}  </Form.Text >
                                        {console.log("vineofirmdata", vineofirmdata?.[0])}
                                        <Controller
                                            control={control}
                                            name={`vineofirmBankArray`}
                                            // rules={{
                                            //     required: "Please, select at least one value"
                                            // }}

                                            render={({ field, fieldState }) => (
                                                <div >
                                                    <InputGroup>
                                                        <Typeahead
                                                            {...field}
                                                            id="FirmNameTypehead"
                                                            size='sm'
                                                            onChange={setSelectedFirm}
                                                            labelKey={"vineoFirmFirmName"}
                                                            selected={selectedFirm}
                                                            // multiple  // for future
                                                            options={vineofirmdata ? vineofirmdata : []}
                                                            className={fieldState.invalid ? "is-invalid" : ""}
                                                            ref={() => {
                                                                selectedFirm ?
                                                                    setValue("dataforaddsaleBill[0].vineoAddSaleBillFirm", selectedFirm) :
                                                                    <></>
                                                            }}
                                                        />

                                                        <p id="typeaheadError" className="invalid-feedback">
                                                            {fieldState.error?.message}
                                                        </p>
                                                        <Button variant="primary"
                                                            onClick={() => { dispatch(getVineoFirmData()); }} >
                                                            <FontAwesomeIcon icon={faRotate} />
                                                        </Button>
                                                    </InputGroup>


                                                </div>
                                            )}
                                        />
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                onChange={(e) => setDiffrentDispatchaddress(!diffrentDispatchaddress)}
                                                label='Different Dispatch Add'
                                                id="Checkbox1"
                                            />
                                        </Form.Group>
                                        {setValue("dataforaddsaleBill[0].diffrentDispatchaddress", diffrentDispatchaddress)}

                                    </Col>
                                    <Col md={3}>
                                        <Form.Text >Party Name* GST:-
                                        {(selectedCustomer?.[0]?.vineoGst?.vineoGstNumber)}</Form.Text >
                                        {/* -\Code :{(selectedCustomer?.[0]?.vineoGst?.vineoGstCode)} */}

                                        <InputGroup>
                                            <Typeahead
                                                // disabled={selectedFirm[0] ? false : true}
                                                id="PartyNameTypehead"
                                                onChange={setSelectedCustomer}
                                                options={vineoPartydata}
                                                placeholder="Choose Party Name..."
                                                selected={selectedCustomer}
                                                labelKey={"vineoCustomerAccountName"}
                                                ref={partyinputref}
                                            />
                                            <Button variant="info" onClick={() => { SetOpenAddParty(true) }}>
                                                +
                                            </Button>
                                            <Button variant="primary"
                                                onClick={() => { dispatch(getVineoAccountCustomerMasterMenu()); }} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)}

                                        </InputGroup>
                                        <Form.Group controlId="diffrentbilingaddress" className="mb-3">
                                            <Form.Check
                                                onChange={(e) => setDiffrentShipingaddress(!diffrentShipingaddress)}
                                                label='Different Shiping Add'
                                                id="Checkbox"
                                            />
                                        </Form.Group>
                                        {setValue("dataforaddsaleBill[0].diffrentShipingaddress", diffrentShipingaddress)}
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Form.Text >
                                                GON Date
                                            </Form.Text>
                                            <DatePicker className="form-control"
                                                selected={billdate}
                                                onChange={setBilldate}
                                                name="vineoBillDate"
                                                dateFormat="dd-MM-yyyy"
                                                ref={setValue("dataforaddsaleBill[0].vineoAddSaleBillDate", moment(billdate).format(DATEFORMAT))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={1}>
                                        <Form.Text>
                                            salesman
                                        </Form.Text>

                                        <InputGroup>
                                            <Form.Control readOnly className="form-control" required
                                                defaultValue={selectedCustomer[0]?.vineoArea?.salesman?.nameofUser ? selectedCustomer[0]?.vineoArea?.salesman?.nameofUser : (``)} />
                                            <Button variant="info" onClick={() => { SetOpenMapAreaToSalesMan(true) }}>
                                                +
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                    <Col md={1}>
                                        <Form.Text>
                                            Area
                                        </Form.Text>
                                        <InputGroup>
                                            <Form.Control readOnly className="form-control" required
                                                defaultValue={selectedCustomer[0]?.vineoArea?.vineoAreaName ? selectedCustomer[0]?.vineoArea?.vineoAreaName : (``)} />
                                            <Button variant="info" onClick={() => { SetOpenMapAreaToParty(true) }}>
                                                +
                                            </Button>
                                        </InputGroup>
                                    </Col>
  
                                    
                                    <Col md={2}>
                                        <Form.Text>
                                            Narr
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleNarr")} />

                                    </Col>




                                </Row>
                                {
                                    diffrentShipingaddress ?
                                        <Row>
                                            <Col md={4}>BillTo:-{(selectedCustomer?.[0]?.vineoCustomerAccountaddress)},{(selectedCustomer?.[0]?.vineoCustomerAccountpincode)}</Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Shiping AddLine1' {...register("dataforaddsaleBill[0].shipingaddline1")} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Shiping AddLine2' {...register("dataforaddsaleBill[0].shipingaddline2")} />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Shiping City' {...register("dataforaddsaleBill[0].shipingaddCity")} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" type="number" placeholder='Shiping AddPinCode' {...register("dataforaddsaleBill[0].shipingaddpincode")} required />
                                            </Col>
                                        </Row> :
                                        <></>
                                }
                                {
                                    diffrentDispatchaddress ?
                                        <Row>
                                            {/* <Col md={2}>BillTo:-{(selectedCustomer?.[0]?.vineoCustomerAccountaddress)},{(selectedCustomer?.[0]?.vineoCustomerAccountpincode)}</Col> */}
                                            <Col md={4}>
                                                <Form.Control className="form-control" placeholder='Disp. AddLine1' {...register("dataforaddsaleBill[0].dispatchaddline1")} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Disp. AddLine2' {...register("dataforaddsaleBill[0].dispatchaddline2")} />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Disp. City' {...register("dataforaddsaleBill[0].dispatchaddCity")} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" type="number" placeholder='Disp. AddPinCode' {...register("dataforaddsaleBill[0].dispatchaddpincode", { minLength: 6, maxLength: 6 })} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Select className="form-control" placeholder='Select State' {...register("dataforaddsaleBill[0].dispatchstatecode")} required>
                                                    {stateOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row> :
                                        <></>
                                }
                                 {setValue("dataforaddsaleBill[0].longitude", 0)}
                                {setValue("dataforaddsaleBill[0].latitude", 0)}
                                {setValue("dataforaddsaleBill[0].addressFromMobileApp", "sentfromdesktopapp")}
                                {/* <Row>
                                    <Col md={2}>
                                        <Form.Text>
                                            Sale Type
                                        </Form.Text>
                                        <Form.Select className="form-control" onSelect={() =>
                                            watch(`dataforaddsaleBill[0].transactionIsReturnType`) ?
                                                setProductdamaged(true) : setProductdamaged(false)
                                        }
                                            {...register("dataforaddsaleBill[0].transactionIsReturnType")} required>
                                            <option value="false">Sale</option>
                                            <option value="true">Return</option>
                                        </Form.Select>
                                    </Col>


                                    {
                                        productdamaged ?
                                            <Col md={2}>
                                                <Form.Text>
                                                    Product
                                                </Form.Text>
                                                <Form.Select className="form-control" disabled={false} {...register("dataforaddsaleBill[0].ReturnProductsareDamaged")} required>
                                                    <option value="false">Sellable</option>
                                                    <option value="true">Damaged</option>
                                                </Form.Select>
                                            </Col> :
                                            <></>
                                    }

                                </Row> */}

                            
                        </div>
                        <div>

                        </div>
                    </Row>
                    {/* {setTotalDiscountPercentage(totalDiscountPercentagevalue)} */}

                    {console.log(totalDiscountPercentagevalue + "From Parent ")}
                    <div><AddGONItem
                        totalDiscountPercentage={totalDiscountPercentage}
                        credittabpressed={credittabpressed}
                        setGrossBillValue={setGrossBillValue} register={register}
                        setValue={setValue} control={control} getValues={getValues}
                        watch={watch} dataforaddsaleBill={watch(`dataforaddsaleBill`)} /></div>
                    <center>
                   
                        <div>
                            <Row>
                                <center>

                                    <Button className="btn btn-primary m-2" onClick={() => { setTotalDiscountPercentage(totalDiscountPercentagevalue); setReadyToSubmit(true) }} value="Calcualte Discount" >
                                        Calculate
                                    </Button>

                                    <input className="btn btn-primary m-2" disabled={!readyToSubmit} type="submit" value="Submit" />

                                    <Button className="btn btn-danger m-2" type="button" onClick={() => { reset() }} value="Cancle">Cancel</Button>
                                    <Link to="/dashboard/Billing" className="btn btn-warning m-2">Billing</Link>
                                </center>
                               
                                </Row>
                                </div>
                        
                    </center>

                </form>
            </div>


            <div>
                <Modal show={openMapAreaToSalesMan}
                    onHide={() => { SetOpenMapAreaToSalesMan(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <AreaToSalesman />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { SetOpenMapAreaToSalesMan(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal show={openMapAreaToParty}
                    onHide={() => { SetOpenMapAreaToParty(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <PartyToArea />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { SetOpenMapAreaToSalesMan(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal show={openAddParty}
                    onHide={() => { SetOpenAddParty(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <CustomerAccountInfo />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { SetOpenAddParty(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    )

}