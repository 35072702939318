import React , { useState }from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';


import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, ModalHeader, ModalBody
} from 'react-bootstrap';
function PurchaseService() {
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState();

    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        // submitForm(data)
    }



    const columnDefs = [
        { headerName: "Date", field: "date" },
        { headerName: "Series", field: "series", },
        { headerName: "Bill No", field: "billNo", },
        { headerName: "Bill Type", field: "billType" },
        { headerName: "Party Name", field: "partyName" },
        { headerName: "Branch Name", field: "branchName" },
        { headerName: "Amount", field: "amount" },
        { headerName: "Load", field: "load" },
        { headerName: "Company Name", field: "companyName" },
        { headerName: "Salesman", field: "salesman" }
    ]
    const rowData = [
        { name: "Rahul", age: 19, phoneNumber: 888881234, birthYear: 2001 },
        { name: "Rah", age: 20, phoneNumber: 887781234, birthYear: 2002 },
        { name: " Jay", age: 22, phoneNumber: 9876543210, birthYear: 2003 },
    ]

    const defaultColDef = {
        sortable: false,
        editable: true,
        flex: 1, filter: false,
        floatingFilter: false
    }
    const onGridReady = (params) => {
        console.log("grid is ready")
        fetch("https://jsonplaceholder.typicode.com/comments").then(resp => resp.json())
            .then(resp => {
                console.log(resp)
                params.api.applyTransaction({ add: resp })

            })

    }



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <div class="p-2 mb-2 bg-primary text-white">
                <center>
                    <h3 align="center">Purchase Service</h3>
                </center>
            </div>
            <div className="ag-theme-alpine" style={{ height: '400px' }}>
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}>
                </AgGridReact>
            </div>
            <div>
                <center>
                    <Button className="btn btn-primary m-2" onClick={handleClickOpen}>
                        Record View
                    </Button>
                </center>
                <a class="btn btn-primary m-3" href="">Add</a>
                <input className="btn btn-primary m-3" type="button" value="Edit " />
                <input className="btn btn-primary m-3" type="button" value="Delete " />
                <input className="btn btn-primary m-3" type="button" value="View " />
                <input className="btn btn-primary m-3" type="button" value="Print " />
                <input className="btn btn-primary m-3" type="button" value="Create Load " />
                <input className="btn btn-primary m-3" type="button" value="Delete Load " />
                <input className="btn btn-primary m-3" type="button" value="Settle Load " />
                <input className="btn btn-primary m-3" type="button" value="Print Load " />
                <input className="btn btn-primary m-3" type="button" value="Close " />

            </div>
            <Modal show={open}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>View Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Card className='p-4'>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        Enter Days
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("enterDays")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        From Date
                                    </Form.Text>
                                    <Form.Control type="date" className="form-control "   {...register("fromDate")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        TO Date
                                    </Form.Text>
                                   
                                    <Form.Control type="date" className="form-control " placeholder="dd-mm-yyyy"  {...register("toDate")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Card>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                        ok
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    );

}
export default PurchaseService;

