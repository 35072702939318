import React from "react";
import { useCallback, useEffect, useState } from "react";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup } from "react-bootstrap";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from 'react-redux'
import { getVineoAccountVendorCustomerMasterMenu, getVineoGodownData, getVineoCompanyData, getVineoProductData, getVineoSchemaMasterData, getVineoFirmData } from "../../../../redux/features/services/serviceHelper";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_APPLYSCHEME_SELECTED_WITHBATCH_BYADMIN, VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, VINEOFORM_EDIT_SELECTED_WITHBATCH_BYSUPERADMIN, VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH } from "../../../../constants/Urlforvineo";
import http from "../../../../constants/AxiosConfig";
import moment from "moment";
import { getVineoBatchData } from "../../../../redux/features/services/entriesHelperService";
import VendorToProduct from "../../mapping/VendorToProduct";
export default function EditBatchForApplySchme(props) {
    const isUserHaveSuperadminAuthority = useSelector((state) => state.vineoData.isUserHaveSuperadminAuthority)
    var batch = props.batch;
    console.log(batch)
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    useEffect(() => {
      {
            notifyWarning("Please Note your applying Scheme Here ")
        }
    }, []);

    const dispatch = useDispatch()
    const vineovendordata = useSelector((state) => state.vineoData.vineoaccountvendorcustomerdata)
    const isvineovendordataLoaded = useSelector((state) => state.vineoData.isvineoaccountvendorcustomerdataLoaded)
    const vineoGodowndata = useSelector((state) => state.vineoData.vineogodowndata)
    const vineobatchData = useSelector((state) => state.vineoData.vineobatchdata)
    const isvineobatchdataloaded = useSelector((state) => state.vineoData.isvineobatchdataLoaded)
    const vineoproductdata = useSelector((state) => state.vineoData.vineoproductdata)
    const vineoschemedata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [openMapVendortoProduct, setOpenMapVendortoProduct] = useState(false);

    const ref = React.createRef();
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const { control, register, handleSubmit, setValue, reset, watch } = useForm();
    const [selected, setSelected] = useState([])
    const [selectedScheme, setSelectedScheme] = useState([])
    const [updatedProductBatchQty, setUpdatedProductBatchQty] = useState()

    useEffect(() => {

        console.log("Refreshingpage")
    }, [refreshcount]);


    const onSubmit = (data) => {

        console.log(data)
        dispatch(getVineoFirmData());
       
            console.log(data)
            submitForm(data);
            // make call to update batch 
       

    }


    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_APPLYSCHEME_SELECTED_WITHBATCH_BYADMIN, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Batch Updated With Internal Id')

                }
                else { notifyError('Error While Updating  Batch  Info From Server' + res.data.additionalInfo) }
                //console.log(res.data)
            },
            (err) => {
                notifyError('Error While Registering  Batch  Info')
            }
        )
    }

    return (

        <div>
            <div className="p-2 mb-2 bg-primary text-white">
                <center>
                    <h5 className='justify-content-center m-1'>Apply Scheme to Batch :{batch?.vineoBatchName}</h5>
                </center>
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card className='p-4'>
                        

                        <Col md={6}>
                            <FormGroup>
                                <Form.Text className="text-muted">
                                    Scheme
                                </Form.Text>
                                {setValue('ultiVineoBatchUniqeId', batch?.ultiVineoBatchUniqeId)}
                                <div>
                                    <InputGroup>
                                        <Typeahead
                                            id="VendorSchemeTypehead"
                                            onChange={setSelectedScheme}
                                            // multiple for future
                                            options={vineoschemedata? vineoschemedata : []}
                                            placeholder="Choose Scheme to Apply to this Batch"
                                            selected={selectedScheme}
                                            labelKey={"vineoSchemeName"}
                                            ref={setValue("vineoBatchSet.vineoScheme", selectedScheme)}
                                        />
                                        <Button variant="primary"
                                            onClick={() => { dispatch(getVineoSchemaMasterData()) }} >
                                            <FontAwesomeIcon icon={faRotate} />
                                        </Button>
                                    </InputGroup>
                                </div>

                                {setValue("vineoScheme", selectedScheme)}
<>Load Scheme Data Here </>
                            </FormGroup>
                        </Col>

                        

                    </Card>

                    <Button className="btn btn-primary m-2" type="submit" value="Apply Scheme">
                    Apply Scheme
                    </Button>

                </form>
            </div>


        </div >
    )
}