function checkURL() {
    var url = window.location.href;
    if (url.includes("15.207.217.61")) {
      return "http://65.0.49.225:9595/jwtauth";
    } else if (url.includes("dkdmsys.dorfketal.com")) {
      return "https://dkdmsys.dorfketal.com/getdata";
    }
    else if (url.includes("65.1.239.36")) {
      //this is for RND Beauty a New Client
      return "http://65.1.239.36/getdata";
    } else {
      return "http://localhost:9595/jwtauth";
    }
  }

// export const BASEURL_JWTAUTH= checkURL()
// export const BASEURL_JWTAUTH = "http://localhost:9595/jwtauth"
export const BASEURL_JWTAUTH = "http://65.0.49.225:9595/jwtauth"   // aws
// export const BASEURL_JWTAUTH = "http://65.1.239.36/getdata"   // RNDBEauty


// export const BASEURL_JWTAUTH = "https://dkdmsys.dorfketal.com/getdata"   //DKDMS PROD

export const GET_JWT_TOKEN = '/getjwttoken'
export const FORGOT_PASSWORD_TOKEN = '/authorisedchanges/resetpassword'
export const FORGOT_PASSWORD_GENERATEOTPTOKEN = '/authorisedchanges/generatepasswordotp'
export const CHANGE_PASSWORD = '/authorisedchanges/changePassword'
export const HEALTHCHECK = '/healthcheck'
export const CHECK_JWT_TOKEN = '/checkjwttoken'
export const RESET_PASSWORD = '/authorisedchanges/resetpassword'
export const DATEFORMAT = 'DD-MM-YYYY'
export const BILLCRDITDAYS = '45'
export const DEFAULT_UNIT_OPTIONS = [
  { value: 'PCS', label: 'PCS' },
  { value: 'BOX', label: 'BOX' },
  { value: 'KG', label: 'KG' },
  { value: 'LTR', label: 'LTR' },
];
export const GOOGLE_MAPS_API_KEY='AIzaSyCHSw5tjnFOTATMDa02A5AnAqlmjkPTmlY'
export const GSTVALIDATEAPIURLWITHKEY = '/salesman/check/4e6d0668e58247156a6a97a7848c6d62/'
export const GSTVALIDATEAPIURLWITHKEY1 = 'https://sheet.gstincheck.co.in/check/4e6d0668e58247156a6a97a7848c6d62/'
export const GSTVALIDATEAPIURLWITHKEYCHECKBALANCE = 'https://sheet.gstincheck.co.in/balance/4e6d0668e58247156a6a97a7848c6d62'
export const IMAGE_RETRIVE = 'https://vineotech.com/imageuploadfordkdms/imageupload.php/'

// this we are refereing form vienotechdev is issue then debug thi sfiel in imageupaod folder in vineotech 
export const IMAGE_UPLOAD = 'http://13.49.24.49:9595/dkdmsfileupload/973048a3-0a99-4801-870a-8cb642ff9b91/upload'
export const IMAGE_UPLOAD_SHOPVISIT = 'http://13.49.24.49:9595/dkdmsfileupload/973048a3-0a99-4801-870a-8cb642ff9b91/shopvisit'

export const GSTEWAYBILLGETAUTHTOKEN = 'https://www.ewaybills.com/MVEINVAuthenticate/EINVAuthentication'
export const GSTEWAYBILLCANCELAUTHTOKEN = 'https://www.ewaybills.com/MVEWBAuthenticate/MVAuthentication'
export const GSTEWAYBILLGENERATEEWAYBILL = 'https://www.ewaybills.com/MVEINVAuthenticate/EINVGeneration'


export const VINEO_CONVERT_UNIVERSALEINVOICE_FROMCSV = '/billingmanager/ConvertUniversalEinvoceJson'


export const VINEO_USER_FEEDBACKFORM = '/salesman/feedbackform'
export const VINEO_REGISTER_USERINFODATA = '/admin/registerultimateuser'
export const VINEO_REGISTER_USERMONTHLYTARGET = '/admin/registerultimateuserMonthlyTarget'
export const VINEO_REGISTER_CLIENTDATA = '/superadmin/registervineoclient'
export const VINEO_REGISTER_CLIENTDATA_PASSWORDCHANGE = '/superadmin/changepasswordofRegisteredUserswithMotherClientDetails'
export const VINEO_GETALLUSEROFMOTHR_CLIENTDATA = '/superadmin/getRegisteredUserswithMotherClientDetails'
export const VINEO_REGISTER_CLIENTADMINUSER = '/superadmin/registeradminuser'


export const VINEO_CHECKUSERNAME_AVAILABLE = '/salesman/checkusernameavailable'
export const VINEO_UPDATE_SELECTEDUSER_USERDATA = '/admin/updateultimateuser'
export const VINEO_DELETE_SELECTEDUSER_USERDATA = '/admin/deleteultimateuser'
export const VINEO_GETALL_SALESMAN_USERDATA = '/admin/getRegisteredSalesman'

export const VINEO_GETALLUSERS_AVAILABLE = '/admin/getRegisteredUsers'

export const VINEOFORM_UPDATE_SELECTEDFIRMDATA = '/admin/updateultimatefirm'
export const VINEOFORM_DELETE_SELECTEDFIRMDATA = '/admin/deleteultimatefirm'
export const VINEOFORM_DEACTIVATE_SELECTEDFIRMDATA = '/mothercompanyadmin/deactivateRegisteredFirmDetails'
export const VINEOFORM_DEACTIVATE_SELECTEDPARTYATA = '/mothercompanyadmin/deactivateRegisteredPartyDetails'
export const VINEOFORM_TRANSFER_SELECTEDPARTYATA = '/mothercompanyadmin/transferParty'
export const VINEO_GETALLFIRM_AVAILABLE = '/salesman/getRegisteredFirmDetails'
export const VINEO_GETALLFIRM_AVAILABLE_FOR_AUTOIMPORT = '/billingmanager/getAutoImportRegisteredFirmDetails'

export const VINEOFORM_UPDATE_SELECTEDCOMPANYODATA = '/admin/updateRegisteredCompanyName'
export const VINEOFORM_DELETE_SELECTEDCOMPANYODATA = '/admin/deleteRegisteredCompanyName'
export const VINEO_GETALLCOMPANY_AVAILABLE = '/admin/getRegisteredCompanyDetails'

export const VINEOFORM_UPDATE_SELECTEDCATEGORYDATA = '/admin/updateRegisteredCompanyCategory'
export const VINEOFORM_DELETE_SELECTEDCATEGORYDATA = '/admin/deleteRegisteredCompanyCategory'
export const VINEO_GETALLCATEGORY_AVAILABLE = '/admin/getRegisteredCompanyCategory'

export const VINEOFORM_UPDATE_SELECTEDSUBCATEGORYDATA = '/admin/updateRegisteredCompanySubCategory'
export const VINEOFORM_DELETE_SELECTEDSUBCATEGORYDATA = '/admin/deleteRegisteredCompanySubCategory'
export const VINEO_GETALLSUBCATEGORY_AVAILABLE = '/admin/getRegisteredCompanySubCategory'

export const VINEOFORM_UPDATE_SELECTEDGROUPDATA = '/admin/updateRegisteredGroupName'
export const VINEOFORM_DELETE_SELECTEDGROUPDATA = '/admin/deleteRegisteredGroupName'
export const VINEO_GETALLGROUP_AVAILABLE = '/admin/getRegisteredGroupDetails'


export const VINEOFORM_UPDATE_SELECTEDSUBGROUPDATA = '/admin/updateRegisteredSubGroupName'
export const VINEOFORM_DELETE_SELECTEDSUBGROUPDATA = '/admin/deleteRegisteredSubGroupName'
export const VINEO_GETALLSUBGROUP_AVAILABLE = '/admin/getRegisteredSubGroupDetails'

export const VINEOFORM_UPDATE_SELECTED_BRAND_DATA = '/admin/updateRegisteredBrandName'
export const VINEOFORM_DELETE_SELECTED_BRAND_DATA = '/admin/deleteRegisteredBrandName'
export const VINEO_GETALL_BRAND_AVAILABLE = '/admin/getRegisteredbrandDetails'

export const VINEOFORM_UPDATE_SELECTED_SUBBRAND_DATA = '/admin/updateRegisteredSubBrandName'
export const VINEOFORM_DELETE_SELECTED_SUBBRAND_DATA = '/admin/deleteRegisteredSubBrandName'
export const VINEO_GETALL_SUBBRAND_AVAILABLE = '/admin/getRegisteredSubbrandDetails'

export const VINEOFORM_UPDATE_SELECTED_GST_NAMEDATA = '/admin/updateRegisteredgstName'
export const VINEOFORM_DELETE_SELECTED_GST_NAMEDDATA = '/admin/deleteRegisteredgstName'
export const VINEO_GETALL_GST_NAME_AVAILABLE = '/admin/getRegisteredgstDetails'

export const VINEOFORM_UPDATE_SELECTED_AREA_NAMEDATA = '/admin/updateRegisteredAreaName'
export const VINEOFORM_DELETE_SELECTED_AREA_NAMEDDATA = '/admin/deleteRegisteredAreaName'
export const VINEO_GETALL_AREA_NAME_AVAILABLE = '/salesman/getRegisteredAreaDetails'

export const VINEOFORM_UPDATE_SELECTED_ROUTE_NAMEDATA = '/admin/updateRegisteredRouteName'
export const VINEOFORM_DELETE_SELECTED_ROUTE_NAMEDDATA = '/admin/deleteRegisteredRouteName'
export const VINEO_GETALL_ROUTE_NAME_AVAILABLE = '/admin/getRegisteredRouteDetails'

export const VINEOFORM_UPDATE_SELECTED_ACCOUNTTYPE1_NAMEDATA = '/admin/updateRegisteredAccountType1Name'
export const VINEOFORM_DELETE_SELECTED_ACCOUNTTYPE1_NAMEDDATA = '/admin/deleteRegisteredAccountType1Name'
export const VINEO_GETALL_ACCOUNTTYPE1_NAME_AVAILABLE = '/admin/getRegisteredAccountType1Details'

export const VINEOFORM_UPDATE_SELECTED_ACCOUNTTYPE2_NAMEDATA = '/admin/updateRegisteredAccountType2Name'
export const VINEOFORM_DELETE_SELECTED_ACCOUNTTYPE2_NAMEDDATA = '/admin/deleteRegisteredAccountType2Name'
export const VINEO_GETALL_ACCOUNTTYPE2_NAME_AVAILABLE = '/admin/getRegisteredAccountType2Details'

export const VINEOFORM_UPDATE_SELECTED_ACCOUNTGROUP_NAMEDATA = '/admin/updateRegisteredAccountGroupName'
export const VINEOFORM_DELETE_SELECTED_ACCOUNTGROUP_NAMEDDATA = '/admin/deleteRegisteredAccountGroupName'
export const VINEO_GETALL_ACCOUNTGROUP_NAME_AVAILABLE = '/admin/getRegisteredAccountGroupDetails'

export const VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDATA = '/salesman/updateRegisteredAccountCustAccntInfo'
export const VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_BILLINGMANAGER = '/billingmanager/updateRegisteredAccountCustAccntInfo'
export const VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_AUTOIMPORT_NAMEDATA = '/billingmanager/updateRegisteredAccountCustAccntInfoAutoImport'
export const VINEOFORM_DELETE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDDATA = '/billingmanager/deleteRegisteredAccountCustAccntInfo'
export const VINEO_GETALL_ACCOUNTCUSTACCNTINFO_NAME_AVAILABLE = '/salesman/getRegisteredAccountCustAccntInfoDetails'
export const VINEO_GETALL_ACCOUNTCUSTACCNTINFO_FOR_PARTY_TRANSFER = '/mothercompanyadmin/getPartyDetailsforTransfer'
export const VINEO_GETALL_ACCOUNTCUSTACCNTINFO_CHECKMOBILENUMBER_AVAILABLE = '/salesman/checkmobileNumber'
export const VINEO_USER_CHECKMOBILENUMBER_AVAILABLE = '/salesman/checkmobileNumberofuser'
export const VINEO_GETALL_UNAPPROVEDACCOUNTCUSTACCNTINFO_NAME_AVAILABLE = '/salesman/getRegisteredUnapprovedAccountCustAccntInfoDetails'
export const VINEO_GETALL_UNAPPROVEDACCOUNTCUSTACCNTINFO_NAME_AVAILABLE_WITHDATE = '/salesman/getRegisteredUnapprovedAccountCustAccntInfoDetailswithdate'
export const VINEOFORM_DELETE_SELECTED__UNAPPROVEDACCOUNTCUSTACCNTINFO = '/salesman/deleteRegisteredUnapprovedAccountCustAccntInfo'
export const VINEOFORM_CONVERT_SELECTED__UNAPPROVEDACCOUNTCUSTACCNTINFOTOPARTY = '/billingmanager/convertunapprovedtoparty'


export const VINEO_CHECKPARTYCODEVALID_AVAILABLE = '/salesman/checkPartycodeValidity'


export const VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATA = '/admin/updateRegisteredVendorAccount'
export const VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDDATA = '/admin/deleteRegisteredVendorAccount'
export const VINEO_GETALL_ACCOUNT_VENDOR_ACCNTINFO_NAME_AVAILABLE = '/admin/getRegisteredVendorAccountDetails'
//Add Vendor With Batch 
export const VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH = '/billingmanager/updateRegisteredVendorAccountwithBatch'
export const VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH = '/billingmanager/deleteRegisteredVendorAccountwithBatch'
export const VINEOFORM_EDIT_SELECTED_WITHBATCH_BYSUPERADMIN = '/admin/updatebacthqtybysuperadmin'
export const VINEOFORM_APPLYSCHEME_SELECTED_WITHBATCH_BYADMIN = '/admin/updatebacthforapplyingscheme'


export const VINEOFORM_UPDATE_SELECTED_GODOWN_NAMEDATA = '/admin/updateRegisteredgoDownName'
export const VINEOFORM_DELETE_SELECTED_GODOWN_NAMEDDATA = '/admin/deleteRegisteredgoDownName'
export const VINEO_GETALL_GODOWN_NAME_AVAILABLE = '/admin/getRegisteredgoDownDetails'
export const VINEO_GETALL_GODOWN_NAME_AVAILABLE_REFEREEDCLIENT_AUTOIMPORT = '/admin/getRegisteredgoDownDetailsofrefClientforautoimport'

export const VINEOFORM_UPDATE_SELECTED_VAN_NAMEDATA = '/billingmanager/updateRegisteredVanName'
export const VINEOFORM_DELETE_SELECTED_VAN_NAMEDDATA = '/billingmanager/deleteRegisteredVanName'
export const VINEO_GETALL_VAN_NAME_AVAILABLE = '/billingmanager/getRegisteredVanDetails'

export const VINEOFORM_UPDATE_SELECTED_TRANSPORT_NAMEDATA = '/billingmanager/updateRegisteredTransporterName'
export const VINEOFORM_DELETE_SELECTED_TRANSPORT_NAMEDDATA = '/billingmanager/deleteRegisteredTransporterName'
export const VINEO_GETALL_TRANSPORT_NAME_AVAILABLE = '/billingmanager/getRegisteredTransporterDetails'

export const VINEOFORM_UPDATE_SELECTED_SPECIALGROUP_NAMEDATA = '/admin/updateRegisteredSpecialGroupName'
export const VINEOFORM_DELETE_SELECTED_SPECIALGROUP_NAMEDDATA = '/admin/deleteRegisteredSpecialGroupName'
export const VINEO_GETALL_SPECIALGROUP_NAME_AVAILABLE = '/admin/getRegisteredSpecialGroupDetails'

export const VINEOFORM_UPDATE_SELECTED_SERVICEACCOUNT_NAMEDATA = '/admin/updateRegisteredSServiceAccountName'
export const VINEOFORM_DELETE_SELECTED_SERVICEACCOUNT_NAMEDDATA = '/admin/deleteRegisteredServiceAccountName'
export const VINEO_GETALL_SERVICEACCOUNT_NAME_AVAILABLE = '/admin/getRegisteredServiceAccountDetails'

export const VINEOFORM_UPDATE_SELECTED_PRODUCT_NAMEDATA = '/admin/updateRegisteredProductName'
export const VINEOFORM_DELETE_SELECTED_PRODUCT_NAMEDDATA = '/admin/deleteRegisteredProductName'
export const VINEO_GETALL_PRODUCT_NAME_AVAILABLE = '/salesman/getRegisteredProductDetails'
export const VINEO_GET__ALL_PRODUCT_NAME_AVAILABLE_FOREDIT = '/salesman/getRegisteredProductDetailsforEditingProduct'
export const VINEO_GETALL_PRODUCTWITHPOSITIVEQTY_NAME_AVAILABLE = '/salesman/getRegisteredProductDetailswithpositivebatchqty'

export const VINEOFORM_UPDATE_SELECTED_SCHEME_NAMEDATA = '/admin/updateRegisteredSchemeName'
export const VINEOFORM_DELETE_SELECTED_SCHEME_NAMEDDATA = '/admin/deleteRegisteredSchemeName'
export const VINEO_GETALL_SCHEME_NAME_AVAILABLE = '/salesman/getRegisteredSchemeDetails'

export const VINEOFORM_UPDATE_SELECTED_CUSTBANK_NAMEDATA = '/salesman/updateRegisteredCustBankName'
export const VINEOFORM_DELETE_SELECTED_CUSTBANK_NAMEDDATA = '/admin/deleteRegisteredCustBankName'
export const VINEO_GETALL_CUSTBANK_NAME_AVAILABLE = '/salesman/getRegisteredCustBankDetails'

//entries URL 
export const VINEOFORM_UPDATE_SELECTED_BATCH_NAMEDATA = '/billingmanager/updateRegisteredBatchName'  //Depricated use /billingmanager/updateRegisteredVendorAccountwithBatch"
export const VINEOFORM_DELETE_SELECTED_BATCH_NAMEDDATA = '/billingmanager/deleteRegisteredBatchName'  // //Depricated use /billingmanager/updateRegisteredVendorAccountwithBatch"
export const VINEO_GETALL_BATCH_NAME_AVAILABLE = '/salesman/getRegisteredBatchDetails'

export const VINEOFORM_UPDATE_SELECTED_ADDINCREASESTOCK = '/billingmanager/updateRegisteredAddIncreaseStock'
export const VINEOFORM_DELETE_SELECTED_ADDINCREASESTOCK = '/billingmanager/deleteRegisteredAddIncreaseStock'
export const VINEO_GETALL_ADDINCREASESTOCK_AVAILABLE = '/billingmanager/getRegisteredAddIncreaseStockDetails'

export const VINEOFORM_UPDATE_SELECTED_ADDBILL = '/billingmanager/updateRegisteredBill'
export const VINEOFORM_UPDATE_SELECTED_GON = '/salesman/updateRegisteredGON'
export const VINEOFORM_UPDATE_SELECTED_LASTBILLNUMBER = '/billingmanager/getLastBillNumber'
export const VINEOFORM_EDIT_SELECTED_ADDBILL = '/billingmanager/editRegisteredBillV1'
export const VINEOFORM_SETTLE_SELECTED_ADDBILL = '/billingmanager/settleRegisteredBillV1'
export const VINEOFORM_PAYTM_PAY_ADDBILL = '/payment/initiatepaytmtransaction'
export const VINEOFORM_FEDERALBANK_PAY_ADDBILL = '/payment/initiateJPMpaytransaction'
export const VINEOFORM_EDIT_SELECTED_ADDBILL_PAYMENT = '/billingmanager/settleRegisteredBillPaymentV1'
export const VINEOFORM_EDIT_SELECTED_ADDBILLold = '/billingmanager/editRegisteredBill'
export const VINEOFORM_DELETE_SELECTED_ADDBILL = '/billingmanager/deleteRegisteredABill'
export const VINEOFORM_DELIVER_SELECTED_ADDBILL = '/salesman/initiateDelivery'
export const VINEOFORM_DELETE_SELECTED_ADDBILLBYSUPERADMIN = '/admin/deleteRegisteredABillbysuperadmin'
export const VINEOFORM_DELETE_SELECTED_ADDBILL_FROMSUPERADMIN = '/superadmin/deleteRegisteredABill'
export const VINEOFORM_GETJSON_OF_SELECTED_ADDBILL = '/billingmanager/geteinvoicegstbillinjson'
export const VINEOFORM_GETEWAYBILL_OF_SELECTED_ADDBILL = '/billingmanager/generateewaybill'
export const VINEOFORM_CANCELEINVOICE_EWAYBILL_OF_SELECTED_ADDBILL = '/billingmanager/cancelewaybillandEInv'
export const VINEOFORM_GETEINVOICE_OF_SELECTED_ADDBILL = '/billingmanager/generateeinvoiceonly'

export const VINEOFORM_UPDATE_SELECTED_ADDBILL_WITHAUTOIMPORT = '/billingmanager/updateRegisteredBillwithAutoImport'


export const VINEO_GETALL_ADDBILL_AVAILABLE = '/billingmanager/getRegisteredAddBillDetails'
export const VINEO_GETBILLWITHID_ADDBILL_AVAILABLE = '/salesman/getRegisteredAddBillDetailswithid'
// /billingmanager/getRegisteredAddPurchaseDetailswithid
// /billingmanager/getRegisteredAddBillDetailswithid/{id}
export const VINEO_GETALL_ADDBILL_AVAILABLE_WITHDATE = '/billingmanager/getRegisteredAddBillDetailswithdate'
export const VINEO_GETALL_ADDBILL_AVAILABLE_WITHDATEFORMOTHERCL = '/mothercompanyadmin/getRegisteredAddBillDetailswithdateformothercl'
export const VINEO_GETALL_ADDGON_AVAILABLE_WITHDATE = '/salesman/getRegisteredAddGONDetailswithdate'
export const VINEO_DELETE_SELECTED_ADDGON = '/salesman/delteRegisteredAddGON'
export const VINEO_CONVERT_SELECTED_ADDGON_TOINVOICE = '/billingmanager/convertAddGONtoinvocie'


export const VINEOFORM_UPDATE_SELECTED_CREDITNOTE = '/billingmanager/updateRegisteredCreditNote'
export const VINEOFORM_GETJSON_OF_SELECTED_CREDITNOTE = '/billingmanager/getewaycreditNoteinjson'
export const VINEOFORM_UPDATE_SELECTED_CREDITNOTE_FOR_LEGACY = '/mothercompanyadmin/updateRegisteredCreditNoteforlegacysystem'
export const VINEO_GETALL_CREDITNOTE_AVAILABLE = '/billingmanager/getRegisteredCreditNoteDetails'
export const VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLE = '/billingmanager/getRegisteredCreditNoteDetailswithPositivecreditamount'
export const VINEO_GETALL_CREDITNOTE_AVAILABLEWITHDATE = '/billingmanager/getRegisteredCreditNoteDetailswithdate'
export const VINEO_DELETE_CREDITNOTE_AVAILABLE = '/billingmanager/deleteRegisteredCreditNote'
export const VINEOFORM_GETEWAYBILL_OF_SELECTED_CREDITNOTE = '/billingmanager/geteinvoiceofcreditnnotewithjson'

export const VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID = '/billingmanager/getRegisteredCreditNotebyCustomerid/'

export const VINEOFORM_UPDATE_SELECTED_DEBITNOTE = '/billingmanager/updateRegisteredDebitNote'
export const VINEO_GETALL_DEBITNOTE_AVAILABLE = '/billingmanager/getRegisteredDebitNoteDetails'
export const VINEO_GETALL_DEBITNOTE_AVAILABLEWITHDATE = '/billingmanager/getRegisteredDebitNoteDetailswithdate'
export const VINEO_GETALL_POSITIVE_DEBITNOTE_AVAILABLE = '/billingmanager/getRegisteredDebitNoteDetailswithPositivedebitamount'
export const VINEO_DELETE_DEBITNOTE_AVAILABLE = '/billingmanager/deleteRegisteredDebitNote'
export const VINEO_GETALL_POSITIVE_DEBITNOTE_AVAILABLEBYID = '/billingmanager/getRegisteredDebitNotebyCustomerid/'

export const VINEOFORM_UPDATE_SELECTED_CREATELOADBILL = '/billingmanager/updateRegisteredCreateLoad'
export const VINEOFORM_DELETE_SELECTED_CREATELOADBILL = '/billingmanager/deleteRegisteredCreateLoad'
export const VINEO_GETALL_CREATELOADBILL_AVAILABLE_WITHDATE = '/billingmanager/getRegisteredCreateLoadDetails'
export const VINEO_GETALL_SETTLELOADBILL_AVAILABLE_WITHDATE = '/billingmanager/settleRegisteredCreateLoad'

export const VINEOFORM_UPDATE_SELECTED_PURCHASEADBILL = '/billingmanager/updateRegisteredPurchase'
export const VINEOFORM_EDITPAYMENT_SELECTED_PURCHASEADBILL = '/billingmanager/updateRegisteredPurchasePayment'
export const VINEOFORM_DELETE_SELECTED_PURCHASEBILL = '/billingmanager/deleteRegisteredPurchase'
export const VINEOFORM_DELETE_SELECTED_PURCHASEBILLBYSUPERADMIN = '/admin/deleteRegisteredPurchasebySuperadmin'
export const VINEO_GETALL_PURCHASEBILL_AVAILABLE_WITHDATE = '/billingmanager/getRegisteredPurchaseDetailswithDate'
export const VINEO_GETBILLWITHID_ADDPURCHASEBILL_AVAILABLE = '/billingmanager/getRegisteredAddPurchaseDetailswithid'


export const VINEO_IMPORT_PRODUCT_TO_ANOTHER_CLIENT_VIA_JSON = '/superadmin/ImportProductfromJson/'
export const VINEO_IMPORT_PRODUCT_TO_ANOTHER_CLIENT = '/superadmin/ImportProductfromClient1toClient2'
export const VINEO_IMPORT_VENDOR_TO_ANOTHER_CLIENT = '/superadmin/ImportVendorfromClient1toClient2'
export const VINEO_IMPORT_USERS = '/superadmin/ImportUsersfromCsvFile'
export const VINEO_IMPORT_CLIENT = '/superadmin/ImportClientfromCsvFile'

// used to import Product from client from one to another 

// Mapping URLS
export const VINEO_MAP_AREATOSALESMAN = '/admin/mapAreatoSalesmanRegisteredAreaName'
export const VINEO_MAP_AREATOPARTY = '/admin/mapPartytoAreaRegisteredAreaName'
export const VINEO_MAP_AREATOPARTYMULTIPLE = '/admin/mapPartytoAreaRegisteredAreaNameMultiple'

export const VINEO_MAP_VENDORTOPRODUCT = '/admin/MapVendortoProductRegisteredVendorAccount'

export const VINEO_MAPMULTIPLE_AREATOPARTY = '/admin/mapmultipleAreatoPartyRegisteredAreaName'

//Dashboard URL 
export const VINEO_GET_TOP_FIVEDATAFORDASHBOARD = '/billingmanager/getTopfiveDatafordashboard'

// Tools

export const VINEO_IMPORT_PARTY_FROM_CSV_FILE = '/billingmanager/ImportPartyfromCsvFile'
export const VINEO_IMPORT_PARTY_FROM_CSV_FILE_BYSUPERADMIN = '/superadmin/ImportPartyfromCsvFile'
export const VINEO_IMPORT_BILLS_FROM_CSV_FILE = '/billingmanager/ImportBillsfromCsvFile'
export const VINEO_IMPORT_PRODUCT_FROM_CSV_FILE = '/billingmanager/ImportProductfromCsvFile'
export const VINEO_IMPORT_PURCHASE_FROMCSV_FILE = '/billingmanager/ImportPurchasefromCsvFile'
export const VINEO_SEND_GRN_TOPARTY = '/billingmanager/sendgrntoParty'
export const VINEO_IMPORT_BATCH_FROMCSV_FILE = '/billingmanager/ImportBatchfromCsvFile'
//grn
export const VINEO_GET_ALLGRNWITHDATE = '/billingmanager/getGRNDetailswithDate'
export const VINEO_GET_ALLGRNWITHID = '/billingmanager/getRegisteredAddGRNDetailswithid'
export const VINEO_DELETE_GRN = '/billingmanager/deletegrn'
export const VINEO_DELETE_GRN_BY_SUPERADMIN = '/superadmin/deletegrnbysuperadmin'


//gst repport
export const VINEO_GSTR1_REPORT_WITH_DATE = '/billingmanager/gstr1report'

export const VINEO_UPLOADSHOPPHOTO = '/salesman/uploadphotos'
export const VINEO_MARKATTENDANCE = '/salesman/updateattendance'
export const VINEO_REMOVEEOD = '/salesman/deleteeodattendance'
export const VINEO_GETATTENDANCEBYUSER = '/salesman/getallattendancereport'
export const VINEO_GETATTENDANCEREPORTFORALLUSERS = '/billingmanager/getallattendancereport'
export const VINEO_UPLOADSHOPVISITPHOTO = '/salesman/uploadshopvisitphotos'
export const VINEO_GETALLSHOPVISITDATA = '/salesman/getShopVisitData'
export const VINEO_GETALLSHOPVISITDATAFORMOTHERCLIENTID = '/mothercompanyadmin/getShopVisitData'
export const VINEO_GETALL_ADDGONDATAFORMOTHERCLIENTID_AVAILABLE_WITHDATE = '/mothercompanyadmin/getRegisteredAddGONDetailswithdate'

