import React from "react";
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, ModalHeader, ModalBody
} from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ADDBILL, VINEOFORM_DELETE_SELECTED_CREATELOADBILL, VINEO_GETALL_SETTLELOADBILL_AVAILABLE_WITHDATE } from '../../../../constants/Urlforvineo';
import moment from "moment";
import { faClose, faRotate, faTrash, faUserEdit, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import { getVineoVanMasterData } from '../../../../redux/features/services/serviceHelper';
import { getVineoAddBillData, getVineoAddBillDatawithdate, getVineoCreateLoadDatawithdate } from '../../../../redux/features/services/entriesHelperService';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector, useDispatch } from 'react-redux'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from "../../../../constants/AxiosConfig";
import { Link } from "react-router-dom";
import Billprint from "./Billprint";
import BillSettle from "./BillSettle";
import Loadprint from "./Loadprint";


export default function SettleLoad(props) {

    const { data } = props

    useEffect(() => {
        console.log("Data for Load Settle")
        console.log(data?.vineoAddBillSet)
        setVineoaddbilldata(data?.vineoAddBillSet)
        dispatch(getVineoAddBillData())
    }, []);

    const dispatch = useDispatch()
    const vineoaddbilldatafromServer = useSelector((state) => state.vineoData.vineoaddbilldata);
    const [vineoaddbilldata, setVineoaddbilldata] = useState(data?.vineoAddBillSet);

    const isvineoaddbilldataLoaded = useSelector((state) => state.vineoData.isvineoaddbilldataLoaded);
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    const [openprint, setOpenprint] = React.useState(false);
    const [openloadprint, setOpenloadprint] = React.useState(false);
   
    const [openeditBill, setOpeneditBill] = React.useState(false);
    const [dataforprint, setDataforprint] = useState();


    const [tableData, setTableData] = useState(vineoaddbilldata);

    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        // submitForm(data)
    }
 

    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)


    const loaddatafromserver = () => {

        dispatch(getVineoAddBillData())
        isvineoaddbilldataLoaded ? setTableData(vineoaddbilldata) : console.log("Empty data from Server for Batch")

    }

    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }



    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
        floatingFilter: true,
        editable: true,
        // onCellValueChanged: onCellValueChanged

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }



    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [
        { headerName: "BillNo.", field: "vineoaddUniqueBillNumberWithPrefix" },
        { headerName: "Party", valueGetter: 'data.vineoAddSaleBillCustomer.vineoCustomerAccountName' },
        { headerName: "Date ", field: "vineoAddSaleBillDate" },
        { headerName: "Type", field: "vineoAddSaleBillType" },
        { headerName: "Amount", field: 'vineoAddSaleBillBottomAmt' },
        { headerName: "Salesman", field: 'salesmanNameActual' },
        { headerName: "Area", field: 'areaName' },
        {
            headerName: "Receipt", field: `receiptIsGenerated`,
            cellRenderer: params => {

                if (params.value) {
                    return `YES`
                }
                return `NO`;
            }
        },
        {
            headerName: "Paid", field: `paymentsubmited`,
            cellRenderer: params => {

                if (params.value) {
                    return `YES`
                }
                return `NO`;
            }
        },



        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>
                <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>

            </div>
        }
    ]


    const handleUpdate = (data) => {
        console.log(data)
        setDataforprint(data);
        setOpeneditBill(!openeditBill)

        // console.log("This will editqty by this amount "+updatedProductBatchQty);
        // submitForm(data)

    }
    //deleting a user

    const settleloadfromServer = (data) => {
        console.log(data)

        http.post(BASEURL_JWTAUTH + VINEO_GETALL_SETTLELOADBILL_AVAILABLE_WITHDATE, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify(res.data.errorMessage)

                }
                else
                notifyError('Error While Settling CreateLoad Info From Server'+res.data.errorMessage)
                //console.log(res.data)
            },
            (err) => {
                notifyError('Error While Registering Settle Load Info')
            }
        )

    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ADDBILL, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

                }
                else
                notifyError("Error While Deleting Bill  " + + res.data.errorMessage)
            },
            (err) => {
                notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    // Ag grid Work End

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const printLoad =(data)=>
    {
        console.log(data)
        setDataforprint(data);
        setOpenloadprint(!openloadprint)
    }

    const handlePrint = (data) => {
        setDataforprint(data);
        setOpenprint(!openprint)
    }

    return (
        <div>
           <div className='h5 mb-0 text-gray-800'>
                <center>
                    <h3 align="center">Load Settle Load No - {data?.ultiVineoCreateLoadUniqeId}</h3>
                    <p align="center">Load Amount = {data?.totalLaodValue}  Load cancel Amount = {data?.totalLaodCanceledValue} </p>

                </center>



            </div>
            <div>
                <Button className="btn btn-success m-2" type="submit" onClick={()=>settleloadfromServer(data)}> Submit</Button>
                <Button className="btn btn-danger m-2" onClick={printLoad} > Print Load</Button>

            </div>
            <div className="ag-theme-alpine" style={{ height: '400px' }}>
                <div className="ag-theme-alpine" style={{ height: '700px' }}>
                    <AgGridReact
                        id="AgGridforBillData"
                        rowData={tableData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                    />
                    <AgGridColumn
                        headerName="BQty"
                        valueGetter={(params) => {
                            return params.data.vineoBatchProductQty;
                        }}
                        valueSetter={(params) => {
                            var newValInt = parseInt(params.newValue);
                            var valueChanged = params.data.vineoBatchProductQty !== newValInt;
                            if (valueChanged) {
                                params.data.vineoBatchProductQty = newValInt;
                            }
                            return valueChanged;
                        }}
                    />
                </div>
            </div>

            <Modal show={openprint}
                onHide={() => setOpenprint(!openprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <Billprint data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
            <Modal show={openloadprint}
                onHide={() => setOpenloadprint(!openloadprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <Loadprint data={data} />
                    </Card>
                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal show={openeditBill}
                onHide={() => setOpeneditBill(!openeditBill)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Settle Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <BillSettle data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>


        </div>
    );

}