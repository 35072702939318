import React from "react";
import ReactToPrint from "react-to-print";
import '../../../printFormat.css';
import QRCode from 'qrcode.react';
import PropTypes from "prop-types";
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import { GOOGLE_MAPS_API_KEY, IMAGE_RETRIVE } from "../../../constants/Urlforvineo";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

var converter = require('number-to-words');

class ComponentToPrint extends React.Component {

    render() {
        return (
            <>
                <div className="">

                    <br />
                    <div id='first-row'>

                        
                        <div className='col'>
                             Nameofuser:<strong> {this.props.data?.nameofUser}</strong>
                             <br />
                            CUSTOMER:<strong> {this.props.data?.partyName}</strong>
                            <br />
                            Address: {this.props.data?.address}
                            <br />
                            Lat :  {this.props.data?.lati}
                            <br />
                            long: {this.props.data?.longi}<br />
                            Remark: {this.props.data?.visitRemark}<br />
                            Visit Order: {this.props.data?.visitOrder}<br />
                           
                        </div>
                        <div className='col'>
                            {console.log("URL for image ",IMAGE_RETRIVE+this.props.data?.shopvisitphotourl)}
                            Shop Photo:-
                            {this.props.data?.shopvisitphotourl?
                            <img src={IMAGE_RETRIVE+this.props.data?.shopvisitphotourl} height='200' alt='Shop Photo image' style={{ display: 'block' }} />
                           :<>Not Available</>}
                        </div>
                        <div className='col'>
                            {console.log("URL for image ",IMAGE_RETRIVE+this.props.data?.shopvisitphotourl)}
                            Sign Photo:-
                            {this.props.data?.shopvisitsignphotourl?
                            <img src={IMAGE_RETRIVE+this.props.data?.shopvisitsignphotourl} height='200' alt='Shop Sign image' style={{ display: 'block' }} />
                           :<>Not Available</>}
                        </div>
                    </div>
               
                    <div>
                        {/* Show map here */}
                        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                            <GoogleMap
                                mapContainerStyle={{ height: "300px", width: "100%" }}
                                center={{
                                    lat: parseFloat(this.props.data?.lati),
                                    lng: parseFloat(this.props.data?.longi)
                                }}
                                zoom={14}
                            >
                                <Marker
                                    position={{
                                        lat: parseFloat(this.props.data?.lati),
                                        lng: parseFloat(this.props.data?.longi)
                                    }}
                                />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    
                </div >


            </>
        );
    }
}

class ShopVisitprint extends React.Component {

    constructor() {
        super();
        this.state = {
            count: 0
        };

    }

    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => this.componentRef}
                    pageStyle={`
                                @page {
                        margin: 0.5cm 0.5cm;
                    }
                
                `}

                />

                <ComponentToPrint data={this.props.data} ref={el => (this.componentRef = el)} />
            </div>
        );
    }
}

export default ShopVisitprint;