import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit, faPrint, faMoneyBill, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT, VINEO_DELETE_SELECTED_ADDGON, VINEO_CONVERT_SELECTED_ADDGON_TOINVOICE, BILLCRDITDAYS, VINEO_GETBILLWITHID_ADDBILL_AVAILABLE } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddGONDatawithdate, getVineoAddGONDatawithdateMotherCompanyID } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { getVineoAccountCustomerMasterMenu, getVineoFirmData } from '../../../redux/features/services/serviceHelper';
import Billprint from './billing/Billprint';
import GONprint from './GONprint';
import EditGON from './EditGON'
import BillprintFormat2 from './billing/BillprintFormat2';
import AuthUser from '../../../constants/AuthUser';



export default function ApproveGON() {
	const { setValue, register } = useForm();
	useEffect(() => {
		console.log("Use Effect called ");
		dispatch(getVineoAccountCustomerMasterMenu());
	}, []);
	let currentDate = new Date();
	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const vineoPartydata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const vineoaddgondatawithdate = useSelector((state) => state.vineoData.vineoaddgondatawithdate);
	const isvineoaddgondatawithdateLoaded = useSelector((state) => state.vineoData.isvineoaddgondatawithdateLoaded);
	const [toDate, setToDate] = useState(new Date());
	const [openprint, setOpenprint] = React.useState(false);
	const [openBillprint, setOpenBillprint] = React.useState(false);
	const [dataforprint, setDataforprint] = useState();
	const [tableData, setTableData] = useState();
	const [billdate, setBilldate] = useState(new Date())
	const [billduedate, setBillduedate] = useState(new Date(currentDate.getTime() + BILLCRDITDAYS * 24 * 60 * 60 * 1000));
	const [selectedCustomer, setSelectedCustomer] = useState([])
	const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 10));
	const notify = (message) => toast.success(message);
	const notifyError = (message) => toast.error(message)
	const notifyWarning = (message) => toast.warning(message)
	const [isLoading, setLoading] = useState(false);
	const [openeditGON, setOpeneditGON] = useState(false);
	const vineofirmdatafromredux = useSelector((state) => state.vineoData.vineofirmdata)
	const [vineofirmdata, setVineofirmdata] = useState()
	const [billnumber, setBillNumber] = useState()
	const [billprefix, setBillprefix] = useState()
	const [editbillnumberalowed, SetEditbillnumberalowed] = useState(false);
	const [lastBillNumber, setLastBillNumber] = useState()
	const [vineoAddSaleBillType, setVineoAddSaleBillType] = useState("CREDIT")

	let lastBillNumberfromreduxfirm = 0;

	useEffect(() => {
		dispatch(getVineoFirmData())

	}, []);

	useEffect(() => {

		console.log("Refreshing on state Change " + refreshcount)
		setLoading(false)
		setTableData(vineoaddgondatawithdate)
		setVineofirmdata(vineofirmdatafromredux)
		setBillprefix(vineofirmdata?.[0]?.vineoaddBillPrefix)
		SetEditbillnumberalowed(vineofirmdata?.[0]?.allowBillNumberedit);
		lastBillNumberfromreduxfirm = vineofirmdatafromredux?.[0]?.vineoaddBillUserBillNumberStartSetting;
		setLastBillNumber(parseInt(lastBillNumberfromreduxfirm) + 1)

	}, [isvineoaddgondatawithdateLoaded, refreshcount]);

	// Ag grid Work Start 
	const [gridApi, setGridApi] = useState(null)
	const { getRole } = AuthUser();
	const onExportClick = () => {
		gridApi.api.exportDataAsCsv();
	}

	const onGridReady = (params) => {
		setGridApi(params);
	}

	const defaultColDef = {
		sortable: true,
		editable: true,
		flex: 1,
		filter: true,
		floatingFilter: true,
	}

	const onPaginationChange = (pageSize) => {
		gridApi.api.paginationSetPageSize(Number(pageSize))
	}

	function onCellValueChanged(event) {
		console.log(
			'onCellValueChanged: '
		);
	}

	const columnDefs = [
		{ headerName: "GONNo.", field: "ultiVineoGoNUniqeId", resizable: true },
		{ headerName: "Party", valueGetter: 'data.vineoAddSaleBillCustomer.vineoCustomerAccountName', resizable: true },
		{ headerName: "Date ", field: "vineoAddGonDate", resizable: true },
		{ headerName: "Amount", field: 'vineoAddSaleBillBottomAmt', resizable: true },
		{ headerName: "Active", field: 'active', resizable: true },
		{
			headerName: "Invoice", field: `invoiceIsGenerated`,
			cellRenderer: params => {
				if (params.value) {
					return `YES`
				}

				return 'NO';
			}
			, resizable: true
		},
		{
			headerName: "Actions", field: "id", cellRendererFramework: (params) => (
				<div>
					<Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>
					<Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
					<Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>

				</div>
			)
		}
	]

	const handleUpdate = (data) => {
		console.log(data)
		setDataforprint(data);
		setOpeneditGON(true);
	}


	const handleConvertToInvocie = (data) => {
		console.log('COnvertign this to invoice ', data);

		const updatedData = {
			...data, // Copy all the properties from the original data object
			vineoAddSaleBillDate: moment(billdate).format(DATEFORMAT),
			vineoAddSaleBillduedate: moment(billduedate).format(DATEFORMAT),
			vineoAddSaleUserBillNumber: lastBillNumber,
			vineoAddSaleBillType: vineoAddSaleBillType,
		};


		http.post(BASEURL_JWTAUTH + VINEO_CONVERT_SELECTED_ADDGON_TOINVOICE, updatedData).then(

			(res) => {
				console.log(res.data)
				if (res.data.errorCode == "0000") {
					notify('Success:- GON  Converted  SUCCESSFULLY with Bill No as ' + res.data.additionalInfo)
					handlePrintBill(res.data.data)
					dispatch(getVineoFirmData())
				}
				else
					notifyError("Error While Converting  GON to Invocie  " + res.data.additionalInfo)
			},
			(err) => {
				notifyError("Error While Convertign GON Try Again  Or Login as Billing Manger or Admin" + err.data.errorMessage)

			}
		)

	}
	const handlePrint = (data) => {
		setDataforprint(data);
		setOpenprint(!openprint)
	}

	const handlePrintBill = (data) => {
		setDataforprint(data);
		setOpenBillprint(!openBillprint)
	}
	const handleDelete = (data) => {
		const confirm = window.confirm("Are you sure, you want to delete this row", data)

		if (confirm) {
			http.post(BASEURL_JWTAUTH + VINEO_DELETE_SELECTED_ADDGON, data).then(

				(res) => {
					console.log(res)
					if (res.data.errorCode == "0000") {
						notify('Success:- GON  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

					}
					else
						notifyError("Error While Deleting GON  " + + res.data.errorMessage)
				},
				(err) => {
					notifyError("Error While Deleting GON Try Again  Or Login as ADMIN")

				}
			)
		}
	}

	const getBilldatafromServer = (billnumber) => {
        if (!billnumber) {
            notifyError("Please Enter Bill Number")
        }
        else if (billnumber.length > 0) {
            console.log("Calling Server to get data with billnumber = " + billnumber);

            http.post(BASEURL_JWTAUTH + VINEO_GETBILLWITHID_ADDBILL_AVAILABLE, { "vineoaddUniqueBillNumberWithPrefix": billnumber }).then(
                (res) => {

                    if (res.data.ultiVineoAddBillUniqeId) {
                        // console.log(res.data);
                        // setTableData(res?.data,...tableData)
                        setDataforprint(res.data);
                        setOpenBillprint(!openBillprint)
                        

                    }
                    else {
                        notifyError("Empty Data Received from Server for Bill NUmber " + billnumber);
                    
                    }

                },
                (err) => {
                    const stored = sessionStorage.getItem('uniqueidForVineoclientName')
                    const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
                    const stored2 = JSON.parse(sessionStorage.getItem("token"))
                    console.log("Error From Server with code = " + err.data)
                    notifyError("Empty Data Received from Server for Bill NUmber " + billnumber);
                }
            )

        }
        else {
            notifyError("Please Enter Bill Number It cant be Empty")
        }

    }

	const getbilldatafromserverwithdate = (reqdata) => {

		const rolename = getRole("");
		console.log("Role is " + rolename)
		if (reqdata.startDate && reqdata.endDate) {
			rolename === 'mothercompanyadmin' ?
				dispatch(getVineoAddGONDatawithdateMotherCompanyID(reqdata)) :
				dispatch(getVineoAddGONDatawithdate(reqdata))
		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}

	// needed below useEffect to solve issue which needed Load Bills to be clicked twice for initial page load only
	// re-write this logic to fetch existing data from redux for specified dates on initial load only
	// or it will keep fetching the data irrespective of the date selected on initial load only ( current behavior )
	// Solution: on navigation resetting the isvineoaddgondatawithdateLoaded state variable in redux might solve the issue
	useEffect(() => {
		if (isvineoaddgondatawithdateLoaded) {
			setTableData(vineoaddgondatawithdate)
		}
	}, [isvineoaddgondatawithdateLoaded, vineoPartydata, vineoaddgondatawithdate])

	return (
		<div className="row justify-content-left ">
			<div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">Accept GON and Convert to Invoice </h4>
				</center>
			</div>

			<Row>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							<div style={{ display: 'flex', alignItems: 'center' }}>
								GON From

								<div onClick={() => {
									setFromdate(currentDate.setDate(currentDate.getDate() - 3)); console.log("Date selected is ", fromdate)
								}} > -3Day</div>
								<div onClick={() => {
									setFromdate(currentDate.setDate(currentDate.getDate() - 60))
								}} > -60Day</div>
								<div onClick={() => {
									setFromdate(currentDate.setDate(currentDate.getDate() - 365))
								}} > -1Year</div>
							</div>

						</Form.Text>
						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setFromdate}
							name="vineoCreateLoadBillFromDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							Bills To Date
						</Form.Text>

						<DatePicker className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillToDate", moment(toDate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Party Name* GST:-{(selectedCustomer?.[0]?.vineoGst?.vineoGstNumber)}</Form.Text >
						<InputGroup>
							<Typeahead
								disabled={false}
								id="PartyNameTypehead"
								onChange={setSelectedCustomer}
								options={vineoPartydata ? vineoPartydata : []}

								placeholder="Choose Party Name..."
								selected={selectedCustomer}
								labelKey={"vineoCustomerAccountName"}
							/>
							<Button variant="primary"
								onClick={() => { dispatch(getVineoAccountCustomerMasterMenu()); }} >
								<FontAwesomeIcon icon={faRotate} />
							</Button>
							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							Enter Bill Number To search* -
						</Form.Text>
						<InputGroup>
					
							<Form.Control className="form-control"
								onChange={(e) => {
									setBillNumber(e.target.value)
								}} />

							<Button size="sm" variant="primary"
								onClick={(e) => { getBilldatafromServer(billnumber) }} >
								<FontAwesomeIcon icon={faRotate} />
							</Button>
							</InputGroup>
							</FormGroup>
					

				</Col>
			</Row>

			<Row>
				<Col>
					<Button className="btn btn-primary m-2" disabled={isLoading} onClick={() => { setLoading(true); getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) }) }}>
						<FontAwesomeIcon icon={faRotate} />
						&nbsp;Load GON
					</Button>
					<Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
						Export To CSV
					</Button>
				</Col>
			</Row>

			<div className="ag-theme-alpine" style={{ height: '500px' }}>
				<div className="ag-theme-alpine" style={{ height: '570px' }}>
					<AgGridReact
						id="AgGridforBillData"
						// rowData={tableData}
						rowData={selectedCustomer?.[0]?.vineoCustomerAccountName ? tableData?.filter((item) => { if ((item.vineoAddSaleBillCustomer?.vineoCustomerAccountName === selectedCustomer?.[0]?.vineoCustomerAccountName)) { return item } }) :
							tableData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
					/>


				</div>
			</div>

			<Modal show={openprint}
				onHide={() => setOpenprint(!openprint)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print GON</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Button variant="warning m-1" onClick={() => { handleConvertToInvocie(dataforprint); getVineoFirmData() }}>Convert To Invoice -  <FontAwesomeIcon icon={faMoneyBillTransfer} /></Button>
					{
						dataforprint?.invoiceIsGenerated ? "GON Already Converted to Invoice It cant be submitted again " : <></>
					}
					<Row>
						<Col md={3}>
							<FormGroup>
								<Form.Text >
									Bill Date
								</Form.Text>
								<DatePicker className="form-control"
									selected={billdate}
									onChange={setBilldate}
									name="vineoBillDate"
									dateFormat="dd-MM-yyyy"
									ref={setValue("vineoAddSaleBillDate", moment(billdate).format(DATEFORMAT))}
								/>
							</FormGroup>
						</Col>
						<Col md={3}>
							<Form.Text>
								Due Date
							</Form.Text>

							<DatePicker className="form-control"
								selected={billduedate}
								onChange={setBillduedate}
								name="vineoBillDate"
								dateFormat="dd-MM-yyyy"
								ref={setValue("vineoAddSaleBillduedate", moment(billduedate).format(DATEFORMAT))}
							/>
						</Col>
						<Col md={2}>
							<Form.Text>
								LastBillNo-{(billprefix)}-{

									vineofirmdata?.[0]?.vineoaddBillUserBillNumberStartSetting
								}
							</Form.Text>

							<Form.Control readOnly={!editbillnumberalowed} className="form-control" defaultValue={lastBillNumber} onChange={(e) => { lastBillNumberfromreduxfirm = e.target.value; setLastBillNumber(e.target.value); }} />
						</Col>
						{console.log("Last Bill Number is " + lastBillNumber)}
						{setValue(`vineoAddSaleUserBillNumber`, lastBillNumber)}
						<Col>
							<Form.Text>
								Bill Type
							</Form.Text>
							<Form.Select className="form-control" value={vineoAddSaleBillType} onChange={(event) => {
								setVineoAddSaleBillType(event.target.value);
							}}>
								<option value="CREDIT">Credit</option>
								<option value="CASH">Cash</option>
							</Form.Select>
						</Col>
					</Row>

					<Card className='p-4'>
						<GONprint data={dataforprint} />
					</Card>

				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
			<Modal show={openeditGON}
				onHide={() => setOpeneditGON(!openeditGON)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print GON</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					{
						dataforprint?.invoiceIsGenerated ? "GON Already Converted to Invoice It cant be Edited again " : <></>
					}

					<Card className='p-4'>
						<EditGON data={dataforprint} />
					</Card>

				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>

			<Modal show={openBillprint}
				onHide={() => setOpenBillprint(!openBillprint)}
				// backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print Bill</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{console.log("PrintForamt is ", vineofirmdata?.[0]?.vineoaddBillFormat)}
					{(vineofirmdata?.[0]?.vineoaddBillFormat === '1') ?
						<Card className='p-4'>
							<Billprint data={dataforprint} />
						</Card>
						:
						<Card className='p-4'>
							<BillprintFormat2 data={dataforprint} />
						</Card>
					}

				</Modal.Body>
				<Modal.Footer>


				</Modal.Footer>
			</Modal>


		</div>
	)
}