import { useState } from "react"
import AuthUser from "../../constants/AuthUser";
import SliderForLoginPage from './SliderForLoginPage'
import { useForm } from "react-hook-form";
import http from "../../constants/AxiosConfig";

import { BASEURL_JWTAUTH, INEO_REGISTER_CLIENTDATA, GET_JWT_TOKEN, HEALTHCHECK, VINEO_REGISTER_CLIENTDATA, VINEO_IMPORT_PRODUCT_TO_ANOTHER_CLIENT } from '../../constants/Urlforvineo'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import {
    FormGroup, Label, Input,
    Button, Form
} from 'react-bootstrap';
import { getVineoAreaData } from "../../redux/features/services/serviceHelper";



export default function ImportProductIntoSystem() {
    const { http, setToken } = AuthUser();
    const dispatch = useDispatch()
    const [userDetails, setUserDetails] = useState({});
    // const { register, handleSubmit } = useForm();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const { register, handleSubmit } = useForm({
       
    });

    const onSubmit = (data) => {
        if (data.uniqueOTP === '12345') {
            submitForm(data);
        }
        else { notifyError("Enter Correct OTP ") }
    }


    const testserverdeployment = () => {
        http.post(BASEURL_JWTAUTH + "/billingmanager/test").then(
            (res) => {
                console.log(res.data)
                notify(res.data)

            }
            ,
            (errr) => {

                console.log(errr)
                notifyError("Your Not Billing  Manger or Admin   " + errr)
            }
        )

    }

    const testsalesman = () => {
        http.post(BASEURL_JWTAUTH + "/salesman/test").then(
            (res) => {
                console.log(res.data)
                notify(res.data)

            }
            ,
            (errr) => {

                console.log(errr)
                notifyError("Your Not Billing  Manger or Admin   " + errr)
            }
        )

    }
    const submitForm = (data) => {

        http.post(BASEURL_JWTAUTH + VINEO_IMPORT_PRODUCT_TO_ANOTHER_CLIENT, data).then(
            (res) => {
                // console.log(res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName)
                // setToken(res.data.username, res.data.token, res.data.email, res.data.uniqueid, res.data.role, res.data.vineoRegisteredClientDetails.vineoRegisteredClientName, res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName);
                console.log(res.status)
                console.log(res)
                if (res.data.errorCode === "0000")
                    notify("Product Saved and Imported Success ")


            },
            (errr) => {

                console.log(errr)
                notifyError("Error During Client registration  & Error code is  " + errr.data.errorCode)
            }
        )
    }

    return (
        <div className="row justify-content-center pt-5">

            <div className="col-sm-5">
                <div className="card p-4">
                    <h1 className="text-center mb-3">VineoTechProduct Import Tool </h1>
                    <div >
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                    vineoRegisteredClientNameTo Copy Product From :
                                </Form.Text>
                                <Form.Control defaultValue={'2222'} className="form-control" placeholder="Client Name "
                                    {...register("clientwithProductAvailable")} required

                                />
                            </FormGroup>
                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                    vineoRegisteredClientNameTo Copy Product Into:Mutiple 8102,8323, format
                                </Form.Text>
                                <Form.Control className="form-control" placeholder="Client Name "
                                    {...register("clienttoCopyProduct")} required

                                />

                            </FormGroup>


                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                    additionalInfo:
                                </Form.Text>
                                <Form.Control className="form-control" placeholder="additionalInfo " defaultValue={' '}
                                    {...register("additionalInfo")} required

                                />


                            </FormGroup>
                            <FormGroup className="mt-2">
                                <Form.Text className="text-muted">
                                    Unique OTP:
                                </Form.Text>
                                <Form.Control value={'12345'} className="form-control" placeholder="additionalInfo " defaultValue={' '}
                                    {...register("uniqueOTP")} required

                                />


                            </FormGroup>



                            <FormGroup className="text-center">
                                <input className=" btn btn-primary m-2 " type="submit" />
                                <Button className="btn btn-primary m-2" color="primary" onClick={testserverdeployment}>
                                    Admin+Billing
                                </Button><Button className="btn btn-primary m-2" color="primary" onClick={testsalesman}>
                                    Admin+Billing+Salesman
                                </Button>
                            </FormGroup>

                        </form>

                    </div>



                </div >
            </div>



            {/* <SliderForLoginPage /> */}
        </div>
    )


}