import React, { useEffect, useState } from 'react'

import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup } from "react-bootstrap";
import ReactDOM from "react-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import AddBatch from './AddBatch';
import VendorToProduct from '../../mapping/VendorToProduct';

let renderCount = 0;

let dataforproductbatch = []

export const AddStockAddline = (props) => {

  useEffect(() => {
    console.log("Rendering  =  " + renderCount)
    renderCount++;
    voucherTotalValue = 0

  }, [])


  let selectedBatch;
  let indexofproduct = 0;
  const [dataforbatch, setDataforbatch] = useState()
  const [productSelected, setProductSelected] = useState([{}])
  const [batchSelected, setBatchSelected] = useState()



  const { register, selectedVendor, dataforaddstock, setValue, control, getValues, watch } = props

  // const { register, control, handleSubmit, reset, watch } = useForm({
  //     defaultValues: {
  //         vineoDataforAddStock: [{ firstName: ""}]
  //     }
  //   });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "vineoDataforAddStock"
    }
  );


  // const onSubmit = (data) => console.log("data", data);

  const [openMapVendortoProduct, setOpenMapVendortoProduct] = useState(false);
  const [openAddBatch, SetOpenAddBatch] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([])
  const [selectedProductBatch, setSelectedProductBatch] = useState([])
  // const [voucherTotalValue, setVoucherTotalValue] = useState(0)
  let voucherTotalValue = 0
  const valueofBatchtname = [{}]

  // if you want to control your fields with watch
  // const watchResult = watch("test");
  // console.log(watchResult);

  // The following is useWatch example
  // console.log(useWatch({ name: "test", control }));


  const inputref = React.createRef([]);

  const addtoinputref = (e) => {
    if (e?.props?.value) {
      dataforproductbatch.push(e?.props?.value)
      console.log(dataforproductbatch)
      console.log(e?.props?.value)

    }
  }

  return (
    <div>
      <div>
        <div>
          <Card className='p-4'>
            <table className="table" >
              <thead className="thead-dark" cellSpacing="0" cellPadding="0">
                <tr>
                  <th scope="col" width="1%"></th>
                  <th scope="col" width="22%">Item Name </th>
                  <th scope="col" width="22%">Select Batch</th>
                  <th scope="col" width="5%">Unit</th>
                  <th scope="col" width="5%">Qty</th>
                  <th scope="col" width="10%">Add Qty</th>
                  <th scope="col" >P.Rate</th>
                  <th scope="col" >S.Rate</th>
                  <th scope="col" width="5%">GrossAmnt</th>
                  <th scope="col" width="8%"> </th>
                </tr>
              </thead>


              {fields.map((item, index) => {
                return (

                  <tbody key={item.id}>

                    <tr key={item.id} >
                      <td>{index + 1}</td>

                      <td >

                        <Controller
                          control={control}
                          name={`vineoDataforAddStock[${index}].vineoProduct`}
                          rules={{
                            required: "Please, select at least one value"
                          }}
                          render={({ field, fieldState }) => (
                            <div >
                              <InputGroup >
                                <Typeahead
                                  {...field}
                                  id="selectedproducttypeahead"
                                  disabled={selectedVendor ? false : true}
                                  labelKey={"vineoProductName"}
                                  className={fieldState.invalid ? "is-invalid" : ""}
                                  options={selectedVendor?.vineoProductset ? selectedVendor?.vineoProductset : []}
                                  ref={() => {
                                    // const values = getValues();
                                    setProductSelected(...selectedProduct, watch(`vineoDataforAddStock[${index}].vineoProduct`))

                                    // dataforproductbatch.push(singleValue)
                                    // console.log("The Product Bathch is  =" + index + "  index is " + JSON.stringify(singleValue))
                                    // console.log("Full Value is ="+index+"  index is "+JSON.stringify(values) )
                                    //  console.log("Vlaue fof Product is "+ productSelected)
                                  }}
                                />

                                <Button
                                  onClick={() => setOpenMapVendortoProduct(true)}

                                  variant="info">
                                  +
                                </Button>
                                <p id="typeaheadproductError" className="invalid-feedback">
                                  {fieldState.error?.message}
                                </p>
                              </InputGroup>

                            </div>

                          )}
                        />


                      </td>

                      <td>
                        <Col >


                          <Controller
                            control={control}
                            name={`vineoDataforAddStock[${index}].vineoBatchSet`}
                            rules={{
                              required: "Please, select at least one value"
                            }}
                            render={({ field, fieldState }) => (
                              <div >
                                <InputGroup>
                                  <Typeahead
                                    {...field}
                                    id="VendorBatchTypehead"
                                    disabled={watch(`vineoDataforAddStock[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`)?.length > 0 ? false : true}
                                    labelKey={"vineoBatchName"}
                                    className={fieldState.invalid ? "is-invalid" : ""}
                                    options={watch(`vineoDataforAddStock[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`) ? watch(`vineoDataforAddStock[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`).filter((item) => {
                                      let tempvalue = (watch(`vineoDataforAddStock[${index}].vineoBatchSet`))
                                      if (tempvalue) {
                                        valueofBatchtname.push(tempvalue[0]);

                                      }
                                      // console.log(valueofBatchtname)
                                      // console.log(valueofBatchtname ? valueofBatchtname[0]?.vineoBatchName : <></>)
                                      //  console.log(item.vineoProductName )
                                      if (!valueofBatchtname.includes(item)) { return item; }
                                    }) : []}

                                    ref={() => {
                                      // const values = getValues(); 
                                      setBatchSelected(watch(`vineoDataforAddStock[${index}].vineoBatchSet`))
                                      // setValue(`vineoDataforAddStock[${index}].vineoAddstockVoucherDate`, dataforaddstock.vineoAddstockVoucherDate)
                                      // setValue(`vineoDataforAddStock[${index}].vineoAddstockVoucherNarr`, dataforaddstock.vineoAddstockVoucherNarr)
                                      // setValue(`vineoDataforAddStock[${index}].vineoAddstockVoucherNumber`, dataforaddstock.vineoAddstockVoucherNumber)
                                      // setValue(`vineoDataforAddStock[${index}].vineoVendorAccount`, dataforaddstock.vineoVendorAccount)
                                      // setValue(`vineoDataforAddStock[${index}].vineoAddstockVoucherTotal`, voucherTotalValue)

                                      // dataforproductbatch.push(singleValue)
                                      // console.log("The Product Bathch is  =" + index + "  index is " + JSON.stringify(singleValue))
                                      // console.log("Full Value is ="+index+"  index is "+JSON.stringify(values) )
                                      //  console.log("Vlaue fof Product is "+ batchSelected)
                                    }}

                                  />
                                  <Button variant="info" onClick={() => { SetOpenAddBatch(true) }}>
                                    +
                                  </Button>
                                  <p id="typeaheadBatchError" className="invalid-feedback">
                                    {fieldState.error?.message}
                                  </p>
                                </InputGroup>

                              </div>
                            )}
                          />

                        </Col>
                      </td>
                      <td>
                        {(watch(`vineoDataforAddStock[${index}].vineoProduct`)?.[0]?.vineoProductbasicUnit) || `0`}
                      </td>
                      <td>
                        {(watch(`vineoDataforAddStock[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductQty) || `0` }

                      </td>
                      <td>
                        <Form.Control className="form-control" defaultValue={0}  {...register(`vineoDataforAddStock[${index}].vineoAddStockUpdatedBatchQty`)} />


                      </td>
                      {/* <td>
                        {watch(`vineoDataforAddStock[${index}].vineoBatchSet`) ? (watch(`vineoDataforAddStock[${index}].vineoBatchSet`)[0]?.vineoBatchFreeProductQty) : <>NA</>}
                      </td> */}
                      <td>
                        { (watch(`vineoDataforAddStock[${index}].vineoBatchSet`)?.[0]?.vineoBatchPurcahseRate) || `0` }
                      </td>
                      <td>
                        {(watch(`vineoDataforAddStock[${index}].vineoBatchSet`)?.[0]?.vineoBatchProductSaleRate) || `0` }
                      </td>

                      <td >
                        <FormGroup  >
                          <Form.Control disabled={false} value={
                            
                            ((watch(`vineoDataforAddStock[${index}].vineoBatchSet`)) && 
                            (watch(`vineoDataforAddStock[${index}].vineoAddStockUpdatedBatchQty`))) ?
                              parseFloat(watch(`vineoDataforAddStock[${index}].vineoBatchSet`)?.[0]?.vineoBatchPurcahseRate) 
                              * parseFloat( parseFloat(watch(`vineoDataforAddStock[${index}].vineoAddStockUpdatedBatchQty`))) 
                              : `0`
                          } className="form-control" {...register(`vineoDataforAddStock[${index}].vineoAddStockGrossAmount`)} />
                        </FormGroup>

                        <div style={{ display: 'none' }}>

                          {
                            (voucherTotalValue = parseFloat(voucherTotalValue) + parseFloat(((watch(`vineoDataforAddStock[${index}].vineoBatchSet`) ) && (watch(`vineoDataforAddStock[${index}].vineoAddStockUpdatedBatchQty`))) ?
                              (parseFloat(watch(`vineoDataforAddStock[${index}].vineoBatchSet`)?.[0]?.vineoBatchPurcahseRate) * parseFloat(watch(`vineoDataforAddStock[${index}].vineoAddStockUpdatedBatchQty`))) : `0`))
                          }
                        </div>

                      </td>
                      <td>
                        <div>
                          <InputGroup>
                            <Button type="button" onClick={() => {
                              append({});
                            }} variant="primary">
                              +
                            </Button>
                            <Button type="button" onClick={() => { remove(index) }} variant="danger">
                              x
                            </Button>

                          </InputGroup>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                );
              })}

            </table>

            <section>
              <Row>


                <Col md={8}>
                  <Button
                    type="button" variant="info"
                    onClick={() => {
                      append({});
                    }}
                  >
                    Add
                  </Button>

                </Col>
                <Col md={2}>
                  Voucher Total
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Form.Control disabled={true} className="form-control" value={voucherTotalValue} />
                  </FormGroup>
                </Col>
              </Row>

            </section>

          </Card>


        </div>
      </div>
      <div>
        <Modal show={openMapVendortoProduct}
          onHide={() => { setOpenMapVendortoProduct(false) }}
          backdrop="static"
          keyboard={false}
          fullscreen={true}>
          <Modal.Header closeButton />
          {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}

          <Modal.Body>
            <VendorToProduct />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary m-2" onClick={() => { setOpenMapVendortoProduct(false) }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal show={openAddBatch}
          onHide={() => { SetOpenAddBatch(false) }}
          backdrop="static"
          keyboard={false}
          fullscreen={true}>
          <Modal.Header closeButton />
          {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
          <Modal.Body>
            <AddBatch />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary m-2" onClick={() => { SetOpenAddBatch(false) }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div >



  )
}
