import React from "react";
import { Col, Row, Form, FormGroup, Button, InputGroup, Card } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ADDBILL, VINEOFORM_DELETE_SELECTED_PURCHASEBILL, VINEOFORM_DELETE_SELECTED_PURCHASEBILLBYSUPERADMIN, VINEOFORM_UPDATE_SELECTED_CREATELOADBILL, VINEO_GETBILLWITHID_ADDPURCHASEBILL_AVAILABLE } from '../../../constants/Urlforvineo';
import moment from "moment";
import { faClose, faPrint, faRotate, faStar, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import { getVineoAddPurchasedatawithdate } from '../../../redux/features/services/entriesHelperService';
import { useSelector, useDispatch } from 'react-redux'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from "../../../constants/AxiosConfig";
import Purchaseprint from "../entries/Purchaseprint";


export default function ItemWisePurchasesReport() {
    useEffect(() => {
        console.log("Purchase")
    }, []);
    const dispatch = useDispatch()

    const [tableData, setTableData] = useState();
    const vineoaddpurchasedatawithdate = useSelector((state) => state.vineoData.vineoAddPurchasedatawithdate);
    const isvineoaddpurchasedatawithdateLoaded = useSelector((state) => state.vineoData.isAddPurchasedatawithdateLoaded);
    const refreshcount = useSelector((state) => state.vineoData.refreshcount);
    const [inovoiceNumber, setInvoiceNumber] = useState()
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
    const [todate, setTodate] = useState(new Date());
    const [purchaseData, setPurchaseData] = useState([]);
    let currentDate = new Date();
    let selectedBillsArray = []
    const { register, handleSubmit, setValue, reset, watch, formState: { errors }, control } = useForm();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const [openprint, setOpenprint] = React.useState(false);
    const [isLoading, setLoading] = useState(false);
    const [openeditBill, setOpeneditBill] = React.useState(false);
    const [dataforprint, setDataforprint] = useState();
    const isUserHaveSuperadminAuthority = useSelector((state) => state.vineoData.isUserHaveSuperadminAuthority);

    useEffect(() => {

        console.log("Refreshing on state Change ")
        setPurchaseData(vineoaddpurchasedatawithdate)
        setLoading(false)
    }, [refreshcount]);
    const onSubmit = (data) => {

        submitForm(data)
        console.log(data);
    }
    const submitForm = (data) => {


        console.log("Submit Called ");
    }
    const getinvoicedatafromServer = (billnumber) => {
        if (billnumber.length > 0) {
            console.log("Calling Server to get data with Invoice ID  = " + billnumber);

            http.post(BASEURL_JWTAUTH + VINEO_GETBILLWITHID_ADDPURCHASEBILL_AVAILABLE, { "vineoaddUniquePurchaseNumberWithPrefix": billnumber }).then(
                (res) => {

                    if (res.data.ultiVineoAddPurchaseUniqeId) {
                        console.log(res.data);
                        setDataforprint(res.data);
                        setOpenprint(!openprint)
                    }
                    else {
                        notify("Empty Data Received from Server for Purcahse Bill NUmber " + billnumber);

                    }


                },
                (err) => {

                    const stored = sessionStorage.getItem('uniqueidForVineoclientName')
                    const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
                    const stored2 = JSON.parse(sessionStorage.getItem("token"))
                    console.log("Error From Server with code = " + err.data)

                }
            )

        }
        else {
            notifyError("Please Enter Bill Number It cant be Empty")
        }

    }

    useEffect(() => {
        const rearrangedData = reArrangePurchaseDataProductWise(purchaseData);
        console.log("Purchase data is ", rearrangedData);
        setTableData(rearrangedData);
      }, [purchaseData]);

    const reArrangePurchaseDataProductWise = (purchaseData = []) => {

        let productData = [];
      
        if (purchaseData?.length) {
          for (let i = 0; i < purchaseData.length; i++) {
            const purchaseItem = purchaseData[i];
            const itemList = purchaseItem.vineoAddPurchaseItemList || [];
      
            for (let j = 0; j < itemList.length; j++) {
              const purchaseProduct = itemList[j];
              const tmpProductObj = {
                productName: purchaseProduct.vineoProduct?.vineoProductName || '',
                totalQty: purchaseProduct.vineoAddPurchaseProductBatchQty || 0,
                unit: purchaseProduct.vineoPurchaseItemUnit || '',
                totalAmount: purchaseProduct.vineoAddPurchaseItemNetAmount || 0,
              };
      
              const existingProduct = productData.find(
                (product) => product.productName === tmpProductObj.productName
              );
      
              if (existingProduct) {
                // If the product already exists in the result array, update its quantities and amounts
                existingProduct.totalQty += tmpProductObj.totalQty;
                existingProduct.totalAmount += tmpProductObj.totalAmount;
              } else {
                productData.push(tmpProductObj);
              }
            }
          }
        }
        console.log("Purcahse data is "+ productData)
      
        return productData;
      };


    const getpurchasedatafromserverwithdate = (reqdata) => {
        if (reqdata.startDate && reqdata.endDate) {
            // console.log(reqdata.startDate)
            // console.log(reqdata.endDate)
            // console.log(reqdata)
            var endDate = moment(reqdata.endDate, 'DD-MM-YYYY');
            var startDate = moment(reqdata.startDate, 'DD-MM-YYYY');
            if (endDate.diff(startDate, 'days') < 380) {
                console.log(reqdata)
                setLoading(true)
                dispatch(getVineoAddPurchasedatawithdate(reqdata))
                isvineoaddpurchasedatawithdateLoaded ? setTableData(vineoaddpurchasedatawithdate) : console.log("Empty data from Server for Purchase")

            } else {
                setLoading(false)
                notifyError("Number Of Days Range for purchase Should Be Less than 1 year ")
            }

        }
        else {
            setLoading(false)
            notifyError("Please Enter Date for purchase Start and End ")
        }


    }

    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)

    const onSelectPurchase = () => {
        // selectedBillsArray = [];

        // let tempvalue = 0;

        // gridApi.api.getSelectedNodes().map((bill, index) => {
        //     return (
        //         <>
        //             {selectedBillsArray[index] = bill.data}
        //             {/* {console.log(bill.data)} */}
        //             {tempvalue = tempvalue + parseFloat(bill.data.vineoAddSaleBillBottomAmt)}
        //             {/* {console.log(totalloadBillValue)}
        //             {console.log(selectedBillsArray.length)} */}
        //         </>
        //     )
        // })
        // { setValue("vineoAddBillSet", selectedBillsArray) }

        // { setNumberofloadbillselected(selectedBillsArray.length) }
        // { setValue("numberofBillsIncreateLaod", numberofloadbillselected) }
        // { setValue("totalLaodValue", totalloadBillValue) }

    }

    const onExportClick = () => {
        gridApi.api.exportDataAsCsv();
    }

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const defaultColDef = {
        sortable: true,
        flex: 1,
        editable: true,
        filter: true,
        floatingFilter: true,
        // editable: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }

    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedNodes();
        var selectedRowsString = '';
        var maxToShow = 5;
        console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }


    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }

    const columnDefs = [
        { headerName: "Product Name.", field: "productName", checkboxSelection: true },
        {
          headerName: "Amount",
          field: 'totalAmount',
          valueGetter: (params) => {
            // Format the "totalAmount" to two decimal places
            const formattedAmount = parseFloat(params.data.totalAmount).toFixed(2);
            return formattedAmount;
          },
        },
        { headerName: "Qty ", field: "totalQty" },
        { headerName: "Unit", field: "unit" },
      ];


    const handleUpdate = (data) => {
        console.log(data)
        setDataforprint(data);
        setOpeneditBill(!openeditBill)

        // console.log("This will editqty by this amount "+updatedProductBatchQty);
        // submitForm(data)

    }

    //deleting a user
    const submitFormForDeleteSelectedRowBySuperadmin = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_PURCHASEBILLBYSUPERADMIN, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

                }
                else { notifyError("Error While Deleting Bill  " + + res.data.errorMessage) }
            },
            (err) => {
                notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

            }
        )
    }


    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_PURCHASEBILL, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Purchase  DELETED SUCCESSFULLY with PID No as')

                }
                else { notifyError("Error While Deleting Purchase  " + res.data.additionalInfo) }
            },
            (err) => {
                notifyError("Error While Deleting Purchase Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this Purchase , It'll Change Batch Stock , If Batch Stock Used Itll Not delete it", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    // AG work ends here 

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handlePrint = (data) => {
        console.log(data)
        setDataforprint(data);
        setOpenprint(!openprint)
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div>
                    {/* <a class="btn btn-primary m-3" href="AddPurchase" >Add Purchase</a> */}
                    {/* <input className="btn btn-primary m-3" type="button" value="Edit " />
                    <input className="btn btn-primary m-3" type="button" value="Delete " />
                    <input className="btn btn-primary m-3" type="button" value="View " />
                    <button className="btn btn-primary m-3" onClick={handlePrint}> Print</button>
                    <input className="btn btn-primary m-3" type="button" value="Close " />
                    <input className="btn btn-primary m-3" type="button" value="Check Margins" /> */}

                </div>

            </form>
            <div>
                <Row><Col md={3}>
                    <FormGroup className="m-2">
                        <Form.Text >

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Purchase From Date
                                <div onClick={() => {
                                    setFromdate(currentDate.setDate(currentDate.getDate() - 3)); console.log("Date selected is ", fromdate)
                                }} > -3Day</div>
                                <div onClick={() => {
                                    setFromdate(currentDate.setDate(currentDate.getDate() - 60))
                                }} > -60Day</div>
                                <div onClick={() => {
                                    setFromdate(currentDate.setDate(currentDate.getDate() - 365))
                                }} > -1Year</div>
                            </div>
                        </Form.Text>
                        <DatePicker className="form-control"
                            selected={fromdate}
                            onChange={setFromdate}
                            name="vineoPurchaseFromDate"
                            dateFormat="dd-MM-yyyy"
                            ref={setValue("vineoPurchaseFromDate", moment(fromdate).format(DATEFORMAT))}
                        />
                        {/* <Controller
                                name="vineoPurchaseFromDateref"
                                //
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <DatePicker className="form-control"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        dateFormat="dd-MM-yyyy"


                                    />

                                )}
                            />
                            {errors.vineoPurchaseFromDateref && <span>This field is required</span>} */}

                    </FormGroup>
                </Col>
                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Purchase To Date
                            </Form.Text>

                            <DatePicker className="form-control"
                                selected={todate}
                                onChange={setTodate}
                                name="vineoPurchaseToDate"
                                dateFormat="dd-MM-yyyy"
                                ref={setValue("vineoPurchaseToDate", moment(todate).format(DATEFORMAT))}
                            />

                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Purchase Invoice Number* - {dataforprint?.vineoaddUniquePurchaseNumberWithPrefix}
                            </Form.Text>

                            <InputGroup>
                                <Form.Control className="form-control"
                                    onChange={(e) => {
                                        setInvoiceNumber(e.target.value)
                                    }} />

                                <Button variant="primary"
                                    onClick={(e) => { getinvoicedatafromServer(inovoiceNumber) }} >
                                    <FontAwesomeIcon icon={faRotate} />
                                </Button>
                                {dataforprint?.ultiVineoAddPurchaseUniqeId ?
                                    <><Button variant="success m-1" size="sm" onClick={() => handlePrint(dataforprint)}><FontAwesomeIcon icon={faPrint} /></Button>
                                        <Button variant="warning m-1" size="sm" onClick={() => handleUpdate(dataforprint)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                        <Button variant="danger m-1" size="sm" onClick={() => handleDelete(dataforprint)}> <FontAwesomeIcon icon={faTrash} /></Button></> : <></>}





                            </InputGroup>

                        </FormGroup>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <Button className="btn btn-warning m-2" disabled={isLoading} onClick={() => {
                            setLoading(!isLoading);
                            getpurchasedatafromserverwithdate({
                                startDate: moment(fromdate).format(DATEFORMAT),
                                endDate: moment(todate).format(DATEFORMAT)
                            })
                        }}> <FontAwesomeIcon icon={faRotate} />
                            {isLoading ? 'Loading…' : 'Load Purchase'}</Button>

                        {/* <button className="btn btn-primary m-2" type="button" onClick={onSelectBillButtonClick}>
                    SelectBills
                </button> */}
                        <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">Export To CSV
                        </Button>

                        {/* <a class="btn btn-primary m-3" href="AddPurchase" >Add Purchase</a> */}
                    </Col>
                </Row>
                <div className="ag-theme-alpine" style={{ height: '400px' }}>
                    <div className="ag-theme-alpine" style={{ height: '700px' }}>
                        <AgGridReact
                            id="AgGridforPurchaseData"
                            rowSelection="multiple"
                            rowData={tableData}
                            columnDefs={columnDefs}
                            onRowSelected={onSelectPurchase}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                        />
                    </div>
                    {console.log(isvineoaddpurchasedatawithdateLoaded + "Value of isvineoaddpurchasedatawithdateLoaded")}
                </div>
            </div>
            <Modal show={openprint}
                onHide={() => setOpenprint(!openprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Purchase Bill Print</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <Purchaseprint data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>




        </div >

    )
}
