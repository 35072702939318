import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import { getVineoVanMasterData } from '../../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEOFORM_UPDATE_SELECTED_VAN_NAMEDATA, VINEOFORM_DELETE_SELECTED_VAN_NAMEDDATA } from '../../../constants/Urlforvineo';

export default function VanMaster() {

    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoVanUniqeId": "Working", "vineoVanName": "Click Refresh To Load" }])
    const isvineovanmasterdataLoaded = useSelector((state) => state.vineoData.isvineovanmasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineovanmasterdata)
    const dispatch = useDispatch()
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);

    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }

    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)


    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoVanName',
            text: 'Van Name',
            sort: true,
            formatter: rowClickedfunction,
        },
        {
            dataField: 'vineoVanCode',
            text: 'Van Code',
            sort: true,
        },

        {
            dataField: 'vineoVanRtoNumber',
            text: 'Van RTO No',
            sort: true,
        }
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };

    const getdatafromserver = () => {

        console.log("Called to get Van Data ")
        dispatch(getVineoVanMasterData())
        console.log(isvineovanmasterdataLoaded);
        if (Object.keys(vineodata).length > 0) {
            isvineovanmasterdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoVanUniqeId": "Working", "vineoVanName": "Click Refresh To Load" }])
        }
        console.log("Empty Data Received form Server")


    }

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoVanUniqeId", selectedRow.ultiVineoVanUniqeId);
            setValue("vineoVanName", selectedRow.vineoVanName);
            setValue("vineoVanRtoNumber", selectedRow.vineoVanRtoNumber);
            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);


        }
        console.log("Update VAN DATA ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected VAN DATA ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_VAN_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- VAN DATA  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoAreaName)
                }
                else
               { notifyError("Error While Deleting VAN DATA  Or Login as ADMIN")}
            },
            (err) => {
                notifyError("Error While Deleting VAN DATA Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_VAN_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify(' Success:-  VAN Created with Internal Id')
                    reset()
                }
                else
               { notifyError("Error While registering VAN Info From Server ")}
                //  console.log(res.data)

            },
            (err) => {


                notifyError("Error While Registering VAN ")
            }
        )
    }


    return (
        <div>
            <h3 className='justify-content-center m-1'>Add Van Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={5}>
                        <ToolkitProvider keyField='ultiVineoVanUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={7}>
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >

                                        <fieldset disabled>

                                            <Form.Control className="form-control"
                                                placeholder="Van ID: This Van Code Will Be Auto Generated"
                                                {...register('ultiVineoVanUniqeId')} />
                                        </fieldset>
                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Van Code
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('vineoVanCode')} required />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Van Name
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('vineoVanName')} required />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Van RTO Number
                                        </Form.Text>
                                        <Form.Control className="form-control custom-form-field"   {...register('vineoVanRtoNumber')} required />

                                    </FormGroup>
                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add VAN DATA " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update VAN DATA" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>
                        </div>


                    </Col>
                </Row>

            </div>
        </div>
    )

}