import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice';
import GridLayout from "react-grid-layout";


import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';


export default function ChildProductScheme() {

    const count = useSelector((state) => state.vineoData.value)
    const dispatch = useDispatch()



    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    // const [formDetails, setformDetails] = useState({});
    // useEffect(() => {
    //     setformDetails({ ...formDetails, gender: "F", enabled: "true", accountLocked: "false", role: "admin" })
    //     console.log(formDetails)
    // }, []);



    const submitForm = (data) => {
        // api call
        http.post('BASEURL_JWTAUTH' + 'VINEOFORM_SUBCATEGORYINFO_ADDINFODATA', data).then(
            (res) => {
                if (res.data.id) {
                    notify('User Created with Internal Id')
                    reset()
                    // navigate('/login')
                }
                else
                    notify("Error While registering SubCategory Info From Server ")
                //  console.log(res.data)

            },
            (err) => {


                notify("Error While Registering SubCategory Info ")
            }
        )
    }
   
    return (
        <div>
            {/* <div>
                <button
                    aria-label="Increment value"
                    onClick={() => dispatch(increment())}
                >
                    Increment
                </button>
                <span>{count}</span>
                <button
                    aria-label="Decrement value"
                    onClick={() => dispatch(decrement())}
                >
                    Decrement
                </button>
            </div> */}


            <h3 className='justify-content-center m-1'>Scheme Master Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <>
                            <Button variant="primary m-1"><FontAwesomeIcon icon={faRotate} /></Button>

                            <Button variant="warning m-1">Edit</Button>
                            <Button variant="danger m-1">Delete</Button>

                        </>
                        <ListGroup>
                            <ListGroup.Item disabled>Disabled Scheme Master </ListGroup.Item>
                            <ListGroup.Item>Scheme Master Test 1</ListGroup.Item>
                            <ListGroup.Item>Scheme Master Test 1</ListGroup.Item>
                            <ListGroup.Item>Scheme Master Test 1</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>Scheme No</Form.Text>
                                                <Form.Control className="form-control "

                                                    {...register("schemeNumber")} />

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Scheme Type
                                                </Form.Text>
                                                <Form.Select className="form-control" {...register("schemeType")} required >
                                                    <option value="accounttype1">Primary Scheme</option>
                                                    <option value="accounttype2">secondary Scheme</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup >

                                        <Form.Text >
                                            Scheme Name
                                        </Form.Text>
                                        <Form.Control className="form-control"   {...register("schemeName")} required />

                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Text>
                                            Company Name
                                        </Form.Text>
                                        <Form.Select className="form-control" {...register("companyName")} required >
                                            <option value="compName">DORF KETAL</option>
                                            <option value="compName">DORF KETAL(pop)</option>
                                        </Form.Select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Text>
                                            Scheme Level
                                        </Form.Text>
                                        <Form.Select className="form-control" {...register("schemeLevel")} required >
                                            <option value="schemeLevel">CHILD PRODUCT LEVEL</option>

                                        </Form.Select>
                                    </FormGroup>
                                    <FormGroup >

                                        <Form.Text >
                                            Select On
                                        </Form.Text>
                                        <Form.Control className="form-control " {...register("selectOn")} required />

                                    </FormGroup>

                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Slab On
                                                </Form.Text>
                                                <Form.Select className="form-control "   {...register("slabOn")} required >
                                                    <option value="unit">UNIT</option>
                                                    <option value="amt">AMOUNT</option>
                                                    <option value="weight">WEIGHT</option>
                                                    <option value="box">BOX</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    From Date
                                                </Form.Text>
                                                <Form.Control type="Date"  {...register("fromDate")} required />
                                            </FormGroup>
                                        </Col>

                                        <Col md={2}>
                                            <FormGroup>
                                                <Form.Text>
                                                    BUDGET  (UNIT)
                                                </Form.Text>
                                                <Form.Control className="form-control " placeholder='0' {...register("total")} required />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Form.Text>
                                                    DISC
                                                </Form.Text>
                                                <Form.Control className="form-control " placeholder='0.0' {...register("total")} required />
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Scheme Type
                                                </Form.Text>
                                                <Form.Select className="form-control "  {...register("schemeType")} required >
                                                    <option value="billWise">BILL Wise</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    To Date
                                                </Form.Text>
                                                <Form.Control type="Date"  {...register("toDate")} required />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Text>
                                                Utilized (Unit)
                                            </Form.Text>
                                            <Form.Control className="form-control " placeholder='0.0' {...register("utilizedUnit")} required />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Text>
                                                Utilized (Disc)
                                            </Form.Text>
                                            <Form.Control className="form-control " placeholder='0.0' {...register("utilizedDisc")} required />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Active
                                                </Form.Text>
                                                <Form.Select className="form-control "  {...register("active")} required >
                                                    <option value="billWise">YES</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Bill Type
                                                </Form.Text>
                                                <Form.Select className="form-control "  {...register("billType")} required >
                                                    <option value="billType">ALL</option>
                                                    <option value="billType">CASH</option>
                                                    <option value="billType">CREDIT</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Text>
                                                Pending (Unit)
                                            </Form.Text>
                                            <Form.Control className="form-control " placeholder='0.0' {...register("pendingUnit")} required />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Text>
                                                Pending (Disc)
                                            </Form.Text>
                                            <Form.Control className="form-control " placeholder='0.0' {...register("pendingDisc")} required />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Lock On Free
                                                </Form.Text>
                                                <Form.Select className="form-control "  {...register("billType")} required >
                                                    <option value="billType">YES</option>
                                                    <option value="billType">NO</option>

                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Lock On Disc
                                                </Form.Text>
                                                <Form.Select className="form-control "  {...register("billType")} required >
                                                    <option value="billType">YES</option>
                                                    <option value="billType">NO</option>

                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Apply in One Bill
                                                </Form.Text>
                                                <Form.Select className="form-control "  {...register("billType")} required >
                                                    <option value="billType">YES</option>
                                                    <option value="billType">NO</option>

                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <GridLayout className="layout" cols={12} rowHeight={30} width={1200}>
                                    <div key="Slb No" data-grid={{ x: 0, y: 0, w: 1, h: 2, static: true }}>
                                    Slb No
                                    </div>
                                    <div key="Scheme On (UNIT)" data-grid={{ x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 }}>
                                    Scheme On (UNIT)
                                    </div>
                                    <div key="FrQty" data-grid={{ x: 4, y: 0, w: 1, h: 2 }}>
                                    FrQty
                                    </div>
                                  </GridLayout>
                                  <GridLayout className="layout" cols={12} rowHeight={10} width={1200}>
                                  <div key="1" data-grid={{ x: 0, y: 0, w: 1, h: 2, static: true }}>
                                  1
                                  </div>
                                  <div key="0.0" data-grid={{ x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 }}>
                                  0.0
                                  </div>
                                  <div key="0" data-grid={{ x: 4, y: 0, w: 1, h: 2 }}>
                                  0.0
                                  </div>
                                </GridLayout>
                                  
                                    <input className="btn btn-primary m-2" type="submit" value="Add Scheme " />
                                    <button type="button" onClick={() => reset()} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row>

            </div>
        </div>
    )

}

