import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { getVineoGodownData } from '../../../redux/features/services/serviceHelper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'



import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem,Card
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_GODOWN_NAMEDDATA, VINEOFORM_UPDATE_SELECTED_GODOWN_NAMEDATA } from '../../../constants/Urlforvineo';

export default function GoDownMaster() {
    const dispatch = useDispatch()
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{"ultiVineoGoDownUniqeId":"Working","vineoGoDownName":"Click Refresh To Load"}])
    const isvineogodowndataLoaded = useSelector((state) => state.vineoData.isvineogodowndataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineogodowndata)
    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    // const navigate = useNavigate();
    // const { http } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoGoDownName',
            text: 'Godown Name',
            sort: true,
            formatter: rowClickedfunction,
        },
        {
            dataField: 'vineoGoDownCode',
            text: 'Godown Id.',
            sort: true
        },  {
            dataField: 'ultiVineoGoDownUniqeId',
            text: 'Unique Id.',
            sort: true
        }, 
    
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };
    const getdatafromserver = () => {

        console.log("Called to get Godown Data ")
        dispatch(getVineoGodownData())

        if(Object.keys(vineodata).length > 0)
        {
                isvineogodowndataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{"ultiVineoGoDownUniqeId":"Working","vineoGoDownName":"Click Refresh To Load"}])
        }
         
        console.log("Empty Data Received form Server")
      
    }

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoGoDownUniqeId", selectedRow.ultiVineoGoDownUniqeId);
            setValue("vineoGoDownCode", selectedRow.vineoGoDownCode);
            setValue("vineoGoDownName", selectedRow.vineoGoDownName);
            setValue("vineoGoDownAddress1", selectedRow.vineoGoDownAddress1);
            setValue("vineoGoDownAddress2", selectedRow.vineoGoDownAddress2);
            setValue("vineoGoDownCity", selectedRow.vineoGoDownCity);
            setValue("vineoGoDownPinCode", selectedRow.vineoGoDownPinCode);
            setValue("vineoGoDownPhoneNumber", selectedRow.vineoGoDownPhoneNumber);
           
        }
        console.log("Update Godown ")
    }

    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
              var answer = window.confirm("Do You want to Delete Selected ROW ");
              if (answer) {
                  submitFormForDeleteSelectedRow(selectedRow);
              }
              else {
                  notify("Delete Operation Canceled ")
              }
          }
    }

  const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_GODOWN_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Godown  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoGoDownName )
                }
                else
                {notifyError("Error While Deleting FIRM  Or Login as ADMIN")}
            },
            (err) => {
                notifyError("Error While Deleting FIRM Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_GODOWN_NAMEDATA, data).then(
            (res)=>{
                console.log(res)
                if(res.data.errorCode== "0000"){
                notify('Success:- Godown  Created With Internate Id')
                reset()
            }
            else
          {  notifyError('Error While Registering Godown Info From Server')}
            //console.log(res.data)
        },
        (err)=>{
            notifyError('Error While Registering Godown Info')
        }
        )
    }


    return (
        <div>
         
            <h3 className='justify-content-center m-1'>Add GoDown Information </h3>
            <div className="row justify-content-left ">
            <Row>
            <Col md={5}>
                <ToolkitProvider keyField='ultiVineoGoDownUniqeId'
                    data={dataofvineoTableData}
                    columns={columns}
                    search   >
                    {
                        props => (
                            <div>
                                <div >
                                    <Button variant="primary"
                                        onClick={getdatafromserver} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                    <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                    <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                    <SearchBar className="m-1" {...props.searchProps} />
                                </div>
                                    <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                    {...props.baseProps}
                                    pagination={paginationFactory(options)}
                                    selectRow={selectRow}
                                // rowEvents={ rowEvents }
                                />

                            </div>
                        )
                    }
                </ToolkitProvider>

            </Col>
                    <Col md={7}>
                        <div >
                        <Card className='p-4'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup >
                                    <fieldset disabled>

                                        <Form.Control className="form-control"
                                            placeholder="GoDown ID: This Brand Code Will Be Auto Generated"
                                            {...register("ultiVineoGoDownUniqeId")} />
                                    </fieldset>
                                </FormGroup> 
                                <FormGroup >
                                <Form.Text className="text-muted">
                                    Godown Code
                                </Form.Text>
                                <Form.Control className="form-control custom-form-field" {...register("vineoGoDownCode")} required />

                            </FormGroup>
                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Godown Name
                                    </Form.Text>
                                    <Form.Control className="form-control custom-form-field" {...register("vineoGoDownName")} required />

                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Address
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" {...register("vineoGoDownAddress1")} required />

                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Address Line 2
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field" {...register("vineoGoDownAddress2")}  />

                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                City
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field"  {...register("vineoGoDownCity")} required />

                                        </FormGroup>
                                      

                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                pin Code
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field"  {...register("vineoGoDownPinCode")} required />
                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Phone No
                                            </Form.Text>
                                            <Form.Control className="form-control custom-form-field"   {...register("vineoGoDownPhoneNumber")} required />

                                        </FormGroup>
                                        
                                       
                                    </Col>

                                </Row>

                                <input className="btn btn-primary m-2" type="submit" value="Submit " />
                                <button type="button" onClick={() => reset()} className="btn btn-secondary m-2"><FontAwesomeIcon icon={faRotate} />Reset</button>

                            </Form>
                            </Card>
                        </div>
                      

                    </Col>
                </Row>

            </div>
        </div>
    )

}