import React from "react";
import { useEffect, useState } from "react";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup } from "react-bootstrap";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from 'react-redux'
import moment from "moment";
import VendorToProduct from "../mapping/VendorToProduct";
import { getVineoBatchData }
    from "../../../redux/features/services/entriesHelperService";
import http from "../../../constants/AxiosConfig";
import {
    BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH,
    VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH
} from "../../../constants/Urlforvineo";
export default function StockReport() {

    const [showproductwise, setShowproductwise] = useState(true);
    useEffect(() => {
        // dispatch(getVineoAccountVendorCustomerMasterMenu());
        // dispatch(getVineoGodownData());
        // dispatch(getVineoSchemaMasterData());
      
        dispatch(getVineoBatchData())
    }, []);

    const dispatch = useDispatch()
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const vineovendordata = useSelector((state) => state.vineoData.vineoaccountvendorcustomerdata)
    const isvineovendordataLoaded = useSelector((state) => state.vineoData.isvineoaccountvendorcustomerdataLoaded)
    const vineoGodowndata = useSelector((state) => state.vineoData.vineogodowndata)
    const vineobatchData = useSelector((state) => state.vineoData.vineobatchdata)
    const isvineobatchdataloaded = useSelector((state) => state.vineoData.isvineobatchdataLoaded)
    const vineoproductdata = useSelector((state) => state.vineoData.vineoproductdata)
    const vineoschemedata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [openMapVendortoProduct, setOpenMapVendortoProduct] = useState(false);
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
    const [todate, setTodate] = useState(new Date());

    const ref = React.createRef();

    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const { control, register, handleSubmit, setValue, reset, watch } = useForm();
    const [vdate, setVdate] = useState();
    const [allRateIncludingGST, setAllRateIncludingGST] = useState(true)
    const [mfgdate, setMfgdate] = useState();
    const [expdate, setExpdate] = useState();
    const [selected, setSelected] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [selectedGodown, setSelectedGodown] = useState([])
    const [selectedScheme, setSelectedScheme] = useState([])
    const [updatedProductBatchQty, setUpdatedProductBatchQty] = useState()
    const [isLoading, setLoading] = useState(false);
   


    useEffect(() => {

        console.log("Refreshing on state Change " + refreshcount)
        setLoading(false)

        setTableData(vineobatchData)
    }, [vineobatchData, isvineobatchdataloaded, refreshcount]);



    const loaddatafromserver = () => {
        setLoading(true)
        dispatch(getVineoBatchData())

    }

    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)


    const [tableData, setTableData] = useState(vineobatchData)

    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }



    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
        floatingFilter: true,
        editable: true,
        // onCellValueChanged: onCellValueChanged

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }



    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [
        { headerName: "BatchName", field: "vineoBatchName", resizable: true },
        { headerName: "BatchNo ", field: "vineoBatchNumber", resizable: true },
        { headerName: "Product", field: "vineoproductNameinBatch", resizable: true },

        // { headerName: "PurcahseRate", field: "vineoBatchPurchaseRateperPcs", },
        { headerName: "SaleRate", field: "vineoBatchProductSaleRateperPcs", resizable: true },
        { headerName: "MRP", field: 'vineoBatchProductMRPperPcs', resizable: true },
        // { headerName: "Qty", field: "vineoBatchProductQty" },
        {
            headerName: "QtyBox",
            editable: true,
            valueGetter: params => {

                return params.data.vineoBatchProductQty;
            },
            valueSetter: params => {
                setUpdatedProductBatchQty(params.newValue);
                return true;
            }
            , resizable: true
        },
        { headerName: "PCSQty", field: 'vineoBatchProductQtyinPCS', resizable: true },
        { headerName: "Opening", field: 'openingstockqtyPCS', resizable: true },

        { headerName: "free", field: 'vineoBatchFreeProductQty', resizable: true },
        { headerName: "Debit", field: 'debitnoteqtyPCS', resizable: true },
        { headerName: "Credit", field: 'creditnoteqtyPCS', resizable: true },
        { headerName: "Damage", field: 'vineoDamagedProductQtyPCS', resizable: true },



        // need to laod schem from DB 
        { headerName: "Godown", valueGetter: 'data.vineoGoDown.vineoGoDownName', resizable: true },


    ]


    const handleUpdate = (data) => {
        console.log(data)

        console.log("This will editqty by this amount " + updatedProductBatchQty);
        submitForm(data)

    }
    //deleting a user

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Batch  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoBatchName)

                }
                else { notifyError("Error While Deleting Batch  Or Login as ADMIN") }
            },
            (err) => {
                notifyError("Error While Deleting Batch Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    const createaproductwiseArray = (data) => {

        if (showproductwise) {
            const combinedArray = [];

            // Group objects based on vineoproductNameinBatch
            const groupedData = data.reduce((acc, obj) => {
                const key = obj.vineoproductNameinBatch;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            // Combine values for each group
            for (const key in groupedData) {
                const group = groupedData[key];
                const combinedObj = {
                    vineoproductNameinBatch: key,
                    vineoBatchProductQty: 0,
                    vineoBatchProductQtyinPCS: 0,
                    openingstockqtyPCS: 0,
                    vineoBatchFreeProductQty: 0,
                    debitnoteqtyPCS: 0,
                    creditnoteqtyPCS: 0,
                    vineoDamagedProductQtyPCS: 0
                };

                group.forEach(obj => {
                    combinedObj.vineoBatchProductQty += obj.vineoBatchProductQty;
                    combinedObj.vineoBatchProductQtyinPCS += obj.vineoBatchProductQtyinPCS;
                    combinedObj.openingstockqtyPCS += obj.openingstockqtyPCS;
                    combinedObj.vineoBatchFreeProductQty += obj.vineoBatchFreeProductQty;
                    combinedObj.debitnoteqtyPCS += obj.debitnoteqtyPCS;
                    combinedObj.creditnoteqtyPCS += obj.creditnoteqtyPCS;
                    combinedObj.vineoDamagedProductQtyPCS += obj.vineoDamagedProductQtyPCS;
                });

                combinedArray.push(combinedObj);
            }
            console.log(combinedArray)
            setTableData(combinedArray)
        }
        else {
            setTableData(vineobatchData)
        }

    }
    // Ag grid Work Start 

    const onSubmit = (data) => {

        console.log(data)
        // submitForm(data)
    }


    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNT_VENDOR_ACCNTINFO_NAMEDATAWITHBATCH, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Batch Created With Internal Id')
                    // dispatch(getVineoAccountVendorCustomerMasterMenu())
                    reset();

                }
                else { notifyError('Error While Registering  Batch  Info From Server') }
                //console.log(res.data)
            },
            (err) => {
                notifyError('Error While Registering  Batch  Info')
            }
        )
    }

    return (

        <div>
             <div className='h5 mb-0 text-gray-800'>
                <center>
                    <h5 className='justify-content-center m-1'>Stock Report</h5>
                </center>
            </div>

            <Row>
                <Col md={2}>
                    <Button className="btn btn-primary" disabled={isLoading} onClick={loaddatafromserver} value="Add Batch ">
                        Load Batch Data
                    </Button>
                </Col>


            </Row>
            <Row>

                <Col md={4}>
                    <FormGroup className="m-2">
                        <Form.Text >Product Name* </Form.Text >

                        <InputGroup>
                            <Typeahead
                                disabled={false}
                                id="ProductType"
                                onChange={setSelectedProduct}
                                options={tableData}

                                placeholder="Choose product Name..."
                                selected={selectedProduct}
                                labelKey={"vineoproductNameinBatch"}
                            />
                            <Button variant="primary"
                                onClick={() => { loaddatafromserver(); console.log(selectedProduct?.[0]) }} >
                                <FontAwesomeIcon icon={faRotate} />
                            </Button>
                            {/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
                            <Form.Group controlId="showproductwise" className="m-2">
                                <Form.Check
                                    onChange={(e) => { 
                                        setShowproductwise(!showproductwise); createaproductwiseArray(tableData) }}
                                    label='Show Product Wise '
                                    id="Checkbox"

                                  
                                />
                            </Form.Group>

                        </InputGroup>
                    </FormGroup>
                </Col>

                <Col md={2}>

                    <Form.Text className="text-muted m-2">
                        Page Size
                    </Form.Text>

                    <Form.Select className="m-2" onChange={(e) => onPaginationChange(e.target.value)} size="sm">
                        <option value='50'>50</option>
                        <option value="100">100</option>
                        <option value='200'>200</option>
                        <option value='300'>300</option>
                    </Form.Select >

                </Col>
                <Col>

                    <Button className="btn btn-primary m-4" onClick={onExportClick} value="ExportToCsv">
                        Export To CSV

                    </Button></Col>



            </Row>

            <div className="ag-theme-alpine" style={{ height: '700px' }}>
                <AgGridReact
                    id="AgGridforBathcData"
                    rowData={  tableData?.filter((item) => {
                        if (selectedProduct?.[0] ? (item.productinBatchProductUniqueid === selectedProduct?.[0].productinBatchProductUniqueid) : (true)) { return item }
                    })
                        
                    }
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                />
                <AgGridColumn
                    headerName="BQty"
                    valueGetter={(params) => {
                        return params.data.vineoBatchProductQty;
                    }}
                    valueSetter={(params) => {
                        var newValInt = parseInt(params.newValue);
                        var valueChanged = params.data.vineoBatchProductQty !== newValInt;
                        if (valueChanged) {
                            params.data.vineoBatchProductQty = newValInt;
                        }
                        return valueChanged;
                    }}
                />
            </div>



        </div >
    )
}