import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from "../../constants/AuthUser";

import { toast } from 'react-toastify';
import { useForm, Controller, useWatch } from "react-hook-form";
import { faCircleCheck, faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useGetRegisteredUsersByPostMutation } from "../../redux/features/services/postApi";
import { decrement } from "../../redux/features/vineoData/vineoDataSlice";
import { useSelector, useDispatch } from 'react-redux'
import { getVineoUsers, getUser, getVineoCompanyData, getVineoCompanyCategoryData, getVineoFirmData, getVineoRouteData, getVineoMotherUserswithmotherclient } from "../../redux/features/services/serviceHelper";
import {
    FormGroup, Label, Input, Tab, Table, Container,
    Button, Form, Row, Col, ListGroup, ListGroupItem, FloatingLabel, Card, InputGroup
} from 'react-bootstrap';

import {
    BASEURL_JWTAUTH,
    VINEO_REGISTER_USERINFODATA,
    VINEO_REGISTER_CLIENTADMINUSER,
    VINEO_CHECKUSERNAME_AVAILABLE,
    VINEO_DELETE_SELECTEDUSER_USERDATA,
    VINEO_UPDATE_SELECTEDUSER_USERDATA,
    VINEO_USER_CHECKMOBILENUMBER_AVAILABLE
} from "../../constants/Urlforvineo";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import http from "../../constants/AxiosConfig";
import { Typeahead } from "react-bootstrap-typeahead";
import { useRef } from "react";

export default function RegisterAdmin() {
    const [uniqueClientsArray, setUniqueClientsArray] = useState([]);
    const vineoUsers = useSelector((state) => state.vineoData.vineouserswithmotherclient)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const isvineousersLoaded = useSelector((state) => state.vineoData.isvineouserswithmotherclientLoaded)
    const vineoroutedata = useSelector((state) => state.vineoData.vineoroutedata)
    const dispatch = useDispatch()
    const typeaheadRef = useRef(null);

    useEffect(() => {
        console.log("Component Mounted")
        dispatch(getVineoRouteData())

    }, []);


    useEffect(() => {
        console.log("Running Refresh Count")
        const clientDetailsArray = (vineoUsers || []).map(item => item?.vineoRegisteredClientDetails);

        // Create a Map to store unique elements based on 'vineoRegisteredClientName'
        const uniqueClientsMap = new Map();

        // Iterate through the 'vineoRegisteredClientDetails' array and store unique elements in the Map
        clientDetailsArray?.forEach(clientDetails => {
            if (!uniqueClientsMap.has(clientDetails?.vineoRegisteredClientName)) {
                uniqueClientsMap.set(clientDetails?.vineoRegisteredClientName, clientDetails);
            }
        });

        // Convert the Map values back to an array to get the unique elements
        const uniqueClients = Array.from(uniqueClientsMap?.values());

        // Set the uniqueClientsArray state with the updated data
        setUniqueClientsArray(uniqueClients);
    }, [refreshcount]);





    const requestBody = "RequestBody"

    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    
    const { register, control, handleSubmit, reset, watch, formState: { errors }, setValue } = useForm();
    const { getUser } = AuthUser();
    const [selectedClientDetails, setSelectedClientDetails] = useState([]);
    const [dataofvineoUsers, setDataofVineoUsers] = useState([{ "username": "Working", "nameofUser": "Click Refresh To Load" }])
    const [userName, setUserName] = useState({});
    const [usernameAvailable, setUsernameAvailable] = useState(false);
    const [username, setUsername] = useState();
    const [motherclientid, setMotherclientid] = useState();
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'nameofUser',
            text: 'Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'username',
            text: 'UserName',
            sort: true
        }, {
            dataField: 'role',
            text: 'Role',
            sort: true
        },
        {
            dataField: 'vineoRegisteredClientDetails.vineoRegisteredClientName',
            text: 'ClientID',
            sort: true
        }, {
            dataField: 'vineoRoute.vineoRouteName',
            text: 'Route',
            sort: true
        },
        {
            dataField: 'pancardnumber',
            text: 'PAN',
            sort: true
        },
        {
            dataField: 'mobilenumber',
            text: 'Mob/Tel'
        }];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };


    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)


        }

    };
    const deleteDatainForm = () => {
        console.log(selectedRow);
        console.log(vineoUsers?.indexOf(selectedRow))
        if (selectedRow.username == getUser()) {
            notifyError("You cant Delete Current Login User ")
        }
        else {
            var answer = window.confirm("Do You want to Delete User");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notifyError("Delete Operation Canceled ")
            }

        }

    }
    useEffect(() => {
        // dispatch(getVineoFirmData());
        console.log("Refreshing on state Change ")
        const timer = setTimeout(() => console.log('Initial timeout!'), 300);
        setDataofVineoUsers(vineoUsers)
    }, [refreshcount]);

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEO_DELETE_SELECTEDUSER_USERDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('USER DELETED SUCCESSFULLY with Username is = ' + requestdata.username + '  & Name Of User is = ' + requestdata.nameofUser)

                    // try to reduce server hit by removeing arrray from here only 
                }
                else
                    notifyError("Error While Deleting USER Try Again  Or Login as ADMIN")
            },
            (err) => {
                notifyError("Error While Deleting USER Try Again  Or Login as ADMIN")

            }
        )
    }
    const updateDatainForm = () => {
        console.log(selectedRow);
        setIsEditDataSelected(true);
        setValue("username", selectedRow.username);
        setValue("nameofUser", selectedRow.nameofUser);
        setValue("email", selectedRow.email);
        setValue("purchaseKey", selectedRow.purchaseKey);
        setValue("role", selectedRow.role);
        setValue("gender", selectedRow.gender);
        setValue("enabled", selectedRow.enabled);
        setValue("accountLocked", selectedRow.accountLocked);
        setValue("mobilenumber", selectedRow.mobilenumber);
        setValue("pancardnumber", selectedRow.pancardnumber);
        setValue("ultimateUserUniqueid", selectedRow.ultimateUserUniqueid);
        setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
        setValue("allowedCategory", selectedRow.allowedCategory);
        setValue("userRestrictedtocategoty", selectedRow.userRestrictedtocategoty);
        setValue("dailyShopVisitTarget", selectedRow.dailyShopVisitTarget);
        setValue("reportingtoMotherClientsfa", selectedRow.reportingtoMotherClientsfa);
        setValue("dailyOrderTarget", selectedRow.dailyOrderTarget);
        setValue("allowedPartiesUniqueID", selectedRow.allowedPartiesUniqueID);
        setValue("multipleClient", selectedRow.multipleClient);
        setValue("clientDetailsList", selectedRow.clientDetailsList);
        typeaheadRef.current?.setState({ selected: selectedRow.clientDetailsList });
    }

    const getdatafromserver = () => {

        if (!motherclientid) {
            notifyError("Pls Select Mother cleint ID ")
        }
        else {
            const formData = new FormData();
            formData.append('motherclientid', motherclientid);
            console.log("Called to get User state With Mother client ")
            dispatch(getVineoMotherUserswithmotherclient(formData));
        }
    }

    const submitForm = (requestdata) => {

        {
            http.post(BASEURL_JWTAUTH + VINEO_REGISTER_CLIENTADMINUSER, requestdata).then(
                (res) => {
                    console.log("Res " + res.data)
                    notify("User registered " + res.data);
                    reset();
                    isEditDataSelected(false)
                },
                (err) => {
                    console.log("Err Response" + err.data)
                    notifyError("Error While registering User try with another username or Use Purchase key as test" + err.data)
                }
            )
        }

    }

    const getUserNameandCodeChedk = () => {
        if (!(watch(`username`))) {
            notifyError("ENTER USERNAME ")
        }
        else {
            submitFormForUsernamecheck();
        }

    }
    const submitFormForUsernamecheck = async () => {
        try {
            const response = await http.post(BASEURL_JWTAUTH + VINEO_CHECKUSERNAME_AVAILABLE, {

                username: watch('username')
            });

            const { username, code } = response.data;
            const isUsernameAvailable = username ? '-OK' : '-Not Available';

            if (username) {
                notify(`Username is ${isUsernameAvailable}`);

            }
            else {
                notifyError(`Username is ${isUsernameAvailable}`);

            }


            setUsernameAvailable(Boolean(response.data));
        } catch (error) {
            notifyError(`Error From Server with code = ${error}`);
        }
    };





    const checkMobileNumber = (mobilenumber) => {
        if (mobilenumber && mobilenumber.trim().length > 9) {
            // Perform the HTTP call only if the mobile number is not empty
            http.post(BASEURL_JWTAUTH + VINEO_USER_CHECKMOBILENUMBER_AVAILABLE + "/" + mobilenumber).then(
                (res) => {
                    console.log(res);
                    if (res.data.errorCode === "0000") {
                        notify('Success: Mobile Number is Available');
                    } else {
                        notifyError("Invalid Mobile Number");
                    }
                },
                (err) => {
                    notifyError("Error -- Try Again or Login as ADMIN");
                }
            );
        } else {
            // Handle the case where the mobile number is empty or has length 0
            notifyError("Invalid Mobile Number: Mobile number is empty or length is not 10 digit");
        }
    };
    return (
        <div>

            <h3 className='justify-content-center m-1'>Add User Dashboard For SuperAdmin </h3>
            <div className="row justify-content-center ">
                <Row className="justify-content-center mt-3">

                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >
                                        <Row>
                                            <Col md={6}>
                                                <Form.Text className="text-muted">
                                                    Unique UserName
                                                </Form.Text>
                                                <InputGroup>
                                                    <Form.Control disabled={isEditDataSelected}
                                                        placeholder="Unique UserName"
                                                        {...register('username')} required

                                                    />
                                                    <Button variant="info" disabled={false}
                                                        onClick={() => { getUserNameandCodeChedk() }} >
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    </Button>
                                                </InputGroup>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Text className="text-muted">
                                                    password
                                                </Form.Text>
                                                <Form.Control placeholder="No Change"  {...register("password")}  />

                                            </Col>
                                            {/* <Col md={3}><Button><FontAwesomeIcon icon={faRotate} onClick={checkusername} />Check UserName</Button></Col> */}
                                        </Row>
                                    </FormGroup>

                                    <FormGroup>
                                        <Form.Text className="text-muted">
                                            Name Of User
                                        </Form.Text>
                                        <Form.Control  {...register("nameofUser")} required />
                                    </FormGroup>

                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    email
                                                </Form.Text>
                                                <Form.Control   {...register("email")} required />

                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    PurchaseKey
                                                </Form.Text>
                                                <Form.Control className="form-control "  {...register("purchaseKey")} required />

                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Gender
                                                </Form.Text>


                                                <Form.Select className="form-control"  {...register("gender")}>
                                                    <option value="F">male</option>
                                                    <option value="M">female</option>

                                                    <option value="other">other</option>
                                                </Form.Select>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    User Role
                                                </Form.Text>
                                                <Form.Select className="form-control"  {...register("role")}>
                                                    <option value="admin">Admin</option>
                                                    <option value="salesman">Salesman</option>
                                                    <option value="billingmanager">Billing Manager</option>
                                                    <option value="mothercompanyadmin">Mothercompany Admin</option>
                                                    <option value="superadmin">SuperAdmin</option>

                                                </Form.Select>
                                            </FormGroup>

                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    ADD CategoryRestriction
                                                </Form.Text>

                                                <Form.Select className="form-control "  {...register("userRestrictedtocategoty")}>
                                                    <option value="false">No</option>
                                                    <option value="true">Yes</option>

                                                </Form.Select >
                                            </FormGroup>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Select Allowed Party Category
                                                </Form.Text>
                                                <Form.Select className="form-control "  {...register("allowedCategory")}>
                                                    <option value="ALL">ALL</option>
                                                    <option value="NAYARA">NAYARA</option>
                                                </Form.Select >
                                            </FormGroup>


                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Reporting to MotherClient SFA
                                                </Form.Text>

                                                <Form.Select className="form-control "  {...register("reportingtoMotherClient")}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Form.Select >
                                            </FormGroup>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Allow Multiple Dist Work
                                                </Form.Text>

                                                <Form.Select className="form-control "  {...register("multipleClient")}>
                                                    <option value="false">No</option>
                                                    <option value="true">Yes</option>
                                                </Form.Select >
                                            </FormGroup>
                                        </Col>


                                        <Col>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    10 Digit Mobile Number
                                                </Form.Text>
                                                <InputGroup>
                                                    <Form.Control className="form-control"   {...register("mobilenumber", { minLength: 10, maxLength: 10 })} />
                                                    {errors.mobilenumber && <span>Enter Mobile Number 10  digit </span>}
                                                    <Button variant="info"
                                                        onClick={() => { checkMobileNumber(watch('mobilenumber')) }} >
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    </Button></InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    PAN CARD Number
                                                </Form.Text>
                                                <Form.Control className="form-control "   {...register("pancardnumber", { pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/ })} required />
                                                {errors.pancardnum && <span>Enter Proper Pan Card Number  </span>}
                                            </FormGroup>

                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    IsEnabled
                                                </Form.Text>

                                                <Form.Select className="form-control"  {...register("enabled")}>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Form.Select >

                                            </FormGroup>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    IsAccountLocked
                                                </Form.Text>

                                                <Form.Select className="form-control" placeholder="accountLocked "  {...register("accountLocked")}>
                                                    <option value="false">False</option>
                                                    <option value="true">True</option>
                                                </Form.Select >

                                            </FormGroup>

                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    vineoRegisteredClientDetails
                                                </Form.Text>
                                                <Form.Control className="form-control " disabled={isEditDataSelected}  {...register("vineoRegisteredClientDetails.vineoRegisteredClientName")} required />

                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Daily Shop Visit Target
                                                </Form.Text>
                                                <Form.Control className="form-control "
                                                    {...register("dailyShopVisitTarget")} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Daily Order Target
                                                </Form.Text>
                                                <Form.Control className="form-control "
                                                    {...register("dailyOrderTarget")} />
                                            </FormGroup>
                                            
                                            <FormGroup>
                                            {console.log(watch(`multipleClient`))}
                                            <Form.Text className="text-muted">
                                                   Multiple Client Details
                                                </Form.Text>
                                                <Controller
                                                    control={control}
                                                    name={`clientDetailsList`}
                                                   
                                                    render={({ field, fieldState }) => (
                                                        <div >
                                                            <InputGroup >
                                                                <Typeahead
                                                                    {...field}
                                                                    id="selectclientdetails"
                                                                    multiple
                                                                    disabled={(watch(`multipleClient`)==`false`)} 
                                                                    className={fieldState.invalid ? "is-invalid" : ""}
                                                                    labelKey={"vineoRegisteredClientName"}
                                                                    options={uniqueClientsArray ? uniqueClientsArray : []}
                                                                    renderMenuItemChildren={(option) => (
                                                                        <div>
                                                                            <div>
                                                                                <small>{option?.vineoRegisteredClientName}</small>
                                                                                <small> / {option?.clientfirmname}</small>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    ref={typeaheadRef}
                                                                />
                                                                <p id="typeaheadproductError" className="invalid-feedback">
                                                                    {fieldState.error?.message}
                                                                </p>
                                                            </InputGroup>

                                                        </div>
                                                    )}
                                                />
                                            </FormGroup>

                                        </Col>


                                    </Row>

                                    <input className="btn btn-primary m-2" type="submit" value="Add User " />

                                    <button type="button" onClick={() => { reset(); }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>

                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col >
                        <ToolkitProvider keyField='username'
                            data={dataofvineoUsers ? dataofvineoUsers : []}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                            <Form.Control type="text" placeholder="Mother Client ID " onChange={(e) => { setMotherclientid(e.target.value) }} />
                                        </div>
                                        <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                            rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                        {/* <BootstrapTable className="p-2" keyField='username'
                            data={vineoUsers}
                            columns={columns}
                           
                            selectRow={selectRow}
                             /> */}

                    </Col>
                </Row>
            </div>
        </div >
    )
}