import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'

import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem,Card
} from 'react-bootstrap';
import { BASEURL_JWTAUTH,  VINEOFORM_DELETE_SELECTED_CUSTBANK_NAMEDDATA,  VINEOFORM_UPDATE_SELECTED_CUSTBANK_NAMEDATA } from '../../../constants/Urlforvineo';
import { getVineoCustomerBankMasterData } from '../../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


export default function CustomerBankInfo() {

    const dispatch = useDispatch()
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{"ultiVineoBankUniqeId":"Working","vineoBankName":"Click Refresh To Load"}])
    const isvineocustomerbankMasterdataLoaded = useSelector((state) => state.vineoData.isvineocustomerbankMasterdataLoaded)
    const vineoData = useSelector((state) => state.vineoData.vineocustomerbankMasterdata)
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});


    const { register, handleSubmit,setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
     
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

   
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoBankName',
            text: 'Bank Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'vineoBankCode',
            text: 'Bank Code',
            sort: true
        }, {
            dataField:'vineoBankphoneNumber',
            text: 'Phone Number',
            sort:true,
        },
    
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };
    const getdatafromserver = () => {

        console.log("Called to get Customer Bank Data ")
        dispatch(getVineoCustomerBankMasterData())
        if ((typeof vineoData != 'undefined') && Object.keys(vineoData).length > 0)
        {
            isvineocustomerbankMasterdataLoaded ? setDataofvineoTableData(vineoData) : setDataofvineoTableData([{"ultiVineoBankUniqeId":"Working","vineoBankName":"Click Refresh To Load"}])
       
        }
        console.log("Empty Data Received form Server")
        
    }

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoBankUniqeId", selectedRow.ultiVineoBankUniqeId);
            setValue("vineoBankName", selectedRow.vineoBankName);
            setValue("vineoBankAccountNumber", selectedRow.vineoBankAccountNumber);
            setValue("vineoBankCode", selectedRow.vineoBankCode);
            
            setValue("vineoBankfirmAddress1", selectedRow.vineoBankfirmAddress1);
            setValue("vineoBankfirmAddress2", selectedRow.vineoBankfirmAddress2);
            setValue("vineoBankCity", selectedRow.vineoBankCity);
            setValue("vineoBankstate", selectedRow.vineoBankstate);
            setValue("vineoBankphoneNumber", selectedRow.vineoBankphoneNumber);
            setValue("vineoBankpinCode", selectedRow.vineoBankpinCode);
            setValue("vineoBankmobileNumber", selectedRow.vineoBankmobileNumber);
            setValue("vineoBankifscCode", selectedRow.vineoBankifscCode);
            setValue("vineoBankmicrCode", selectedRow.vineoBankmicrCode);
            setValue("vineoBankcityclearingType", selectedRow.vineoBankcityclearingType);
            setValue("accountOpeningBalance", selectedRow.accountOpeningBalance);
            setValue("vineoBankappclearDay", selectedRow.vineoBankappclearDay);
            setValue("vineoBankfirmName", selectedRow.vineoBankfirmName);
            
             setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
      
           
        }
        console.log("Update Bank Info ")
    }

    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
              var answer = window.confirm("Do You want to Delete Selected Bank Info ");
              if (answer) {
                  submitFormForDeleteSelectedRow(selectedRow);
              }
              else {
                  notify("Delete Operation Canceled ")
              }
          }
    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_CUSTBANK_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Bank Info  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoBankName )
                }
                else
                {notifyError("Error While Deleting FIRM  Or Login as ADMIN")}
            },
            (err) => {
                notifyError("Error While Deleting FIRM Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_CUSTBANK_NAMEDATA, data).then(
            (res)=>{
                console.log(res)
                if(res.data.errorCode== "0000"){
                notify('Success:- Customer Bank  Created With Internate Id')
                reset()
            }
            else
           { notifyError('Error While Registering Customer Bank Info From Server')}
            //console.log(res.data)
        },
        (err)=>{
            notifyError('Error While Registering Customer Bank Info')
        }
        )
    }


    return (
        <div>
         
            <h3 className='justify-content-center m-1'>Bank Information </h3>
            <div className="row justify-content-left ">
            <Row>
            <Col md={4}>
                <ToolkitProvider keyField='ultiVineoBankUniqeId'
                    data={dataofvineoTableData}
                    columns={columns}
                    search   >
                    {
                        props => (
                            <div>
                                <div >
                                    <Button variant="primary"
                                        onClick={getdatafromserver} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                    <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                    <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                    <SearchBar className="m-1" {...props.searchProps} />
                                </div>
                                    <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                    {...props.baseProps}
                                    pagination={paginationFactory(options)}
                                    selectRow={selectRow}
                                // rowEvents={ rowEvents }
                                />

                            </div>
                        )
                    }
                </ToolkitProvider>

            </Col>
                    <Col md={8}>
                        <div >
                        <Card  className='p-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup >
                                    <fieldset disabled>

                                        <Form.Control className="form-control"
                                            placeholder="Customer Bank ID: This Bank Code Will Be Auto Generated"
                                            {...register("ultiVineoBankUniqeId")} />
                                    </fieldset>
                                </FormGroup>
                                <FormGroup >
                                <Form.Text className="text-muted">
                                    Customer Bank Code *
                                </Form.Text>
                                <Form.Control className="form-control "   {...register("vineoBankCode")} required />

                            </FormGroup>
                            <FormGroup >
                                    <Form.Text className="text-muted">
                                        Firm Name in Bank / Account Name *
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control "   {...register("vineoBankfirmName")} required />

                                </FormGroup>
                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Bank Name *
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control "   {...register("vineoBankName")} required />

                                </FormGroup>
                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Bank Account Number *
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control "   {...register("vineoBankAccountNumber")} required />

                                </FormGroup>
                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Bank Account Type :-Current / Saving
                                    </Form.Text>
                                    <Form.Control size='sm' disabled={true} defaultValue="CRNT" className="form-control "   {...register("vineoBankAccntType")} required />

                                </FormGroup>
                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Bank Address Line -1
                                    </Form.Text>
                                    <Form.Control size='sm'  className="form-control"   {...register("vineoBankfirmAddress1")}  />

                                </FormGroup>
                                <FormGroup >
                                    <Form.Text className="text-muted">
                                        Bank Address Line -2
                                    </Form.Text>
                                    <Form.Control size='sm'  className="form-control" {...register("vineoBankfirmAddress2")}  />

                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                City *
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control" {...register("vineoBankCity")} required />

                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                State *
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control"  {...register("vineoBankstate")} required />

                                        </FormGroup>

                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Phone Number
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control"  {...register("vineoBankphoneNumber")}  />
                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Pincode*
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control"   {...register("vineoBankpinCode")} required />

                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Country
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control"  defaultValue="INDIA" {...register("vineoBankcountry")}  />

                                        </FormGroup>

                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                10 Digit Mobile Number*
                                            </Form.Text>

                                            <input size='sm'  className="form-control "   {...register("vineoBankmobileNumber", { minLength: 10, maxLength: 10 })} required/>
                                            {errors.mobilenumber && <span>Enter Mobile Number as 10 digit </span>}
                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                IFSC Code*
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control"   {...register("vineoBankifscCode")} required />

                                        </FormGroup>
                                       
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                MICR Code/IFSC Code
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control"   {...register("vineoBankmicrCode")}  />

                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Clearing Type
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control"   {...register("vineoBankcityclearingType")}  />

                                        </FormGroup>
                                     
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                            Account Opening Balance (It Cant Be changed Later)
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control" defaultValue="0"  {...register("accountOpeningBalance")}  />

                                        </FormGroup>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                               Approximate Clearing Days
                                            </Form.Text>
                                            <Form.Control size='sm'  className="form-control" defaultValue="0"  {...register("vineoBankappclearDay")}  />

                                        </FormGroup>
                                        
                                    </Col>            

                                </Row>

                                <input className="btn btn-primary m-2" type="submit" value="Add Customer Bank " />
                                <button type="button" onClick={() => reset()} className="btn btn-secondary m-2"><FontAwesomeIcon icon={faRotate} />Reset</button>

                            </form>
                            </Card>
                        </div>
                      

                    </Col>
                </Row>
              

            </div>
        </div>
    )

}
