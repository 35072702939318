import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_DELETE_SELECTED_BRAND_DATA, VINEOFORM_UPDATE_SELECTED_BRAND_DATA, BASEURL_JWTAUTH } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';
import { getVineoBrandData, getVineoGroupyData, getVineoSubGroupyData } from '../../../redux/features/services/serviceHelper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

export default function BrandMaster() {



    useEffect(() => {
        // dispatch(getVineoBrandData());
        dispatch(getVineoSubGroupyData())
        setIsRowSelected(true)

    }, []);



    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoBrandUniqeId": "Working", "vineoBrandName": "Click Refresh To Load" }])

    const dispatch = useDispatch()
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const count = useSelector((state) => state.vineoData.value)
    const [selected, setSelected] = useState([]);


    const tabledataLoaded = useSelector((state) => state.vineoData.vineobranddata)
    const dataLoaded = useSelector((state) => state.vineoData.vineosubgroupdata)
    const isDataLoaded = useSelector((state) => state.vineoData.isvineobranddataLoaded)


    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        if(selected[0])
        {
            submitForm(data)
        }
        else{
            notify("Pls Select SubGroup")
        }
    
    }
    const navigate = useNavigate();
    const { setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)

        }

    };
    const { SearchBar } = Search;
    const columns = [
        {
            dataField: 'vineoBrandName',
            text: 'Brand Name',
            sort: true,
            formatter: rowClickedfunction,
        },

        {
            dataField: 'vineoSubGroup.vineoSubGroupName',
            text: 'SUB GRP NM.',
            sort: true,

        }, {
            dataField: 'vineoSubGroup.vineoGroup.vineoGroupName',
            text: 'GRP',
            sort: true
        }, {
            dataField: 'vineoSubGroup.vineoGroup.vineoSubCategory.companySubCategoryName',
            text: 'SUB.CAT',
            sort: true
        }, {
            dataField: 'vineoSubGroup.vineoGroup.vineoSubCategory.vineoCategory.companyCategoryName',
            text: 'CAT ',
            sort: true
        }, {
            dataField: 'vineoSubGroup.vineoGroup.vineoSubCategory.vineoCategory.vineoCompany.companyName',
            text: 'CAT ',
            sort: true
        }
    ];


    const numbers = [1, 2, 3, 4, 5];
    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const updateDatainForm = () => {
        console.log(selectedRow);
        setIsEditDataSelected(true);

        setValue("vineoBrandName", selectedRow.vineoBrandName);
        setValue("ultiVineoBrandUniqeId", selectedRow.ultiVineoBrandUniqeId);
        setValue("vineoSubGroup", selectedRow.vineoSubGroup);
        setValue("vineoSubGroup.ultiVineoSubGroupUniqeId", selectedRow.vineoSubGroup.ultiVineoSubGroupUniqeId);


        // setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
        // setValue("companySubCategoryName", selectedRow.companySubCategoryName);

    }


    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected ROW ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_BRAND_DATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- BRAND DATA  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoSubGroupName)
                }
                else
                    notify("Error While Deleting BRAND DATA  Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting BRAND Try Again  Or Login as ADMIN")

            }
        )
    }

    const getdatafromserver = () => {
        console.log("Called to get Brand Data ")
        console.log(isDataLoaded)
        dispatch(getVineoBrandData())

        if ((typeof tabledataLoaded != 'undefined') && Object.keys(tabledataLoaded).length > 0) {
            isDataLoaded ? setDataofvineoTableData(tabledataLoaded) : setDataofvineoTableData([{ "ultiVineoBrandUniqeId": "Working", "vineoBrandName": "Click Refresh To Load" }])
        }
        console.log("Empty Data Received form Server")

    }


    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_BRAND_DATA, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorCode == "0000") {
                    notify('Success:- BRAND  Created with Internal Id')
                    console.log(res.data)
                    reset()
                }
                else
                    notify("Error While Creating BRAND From Server ")


            },
            (err) => {
                notify("Error While Creating BRAND  ")
            }
        )
    }

    return (
        <div>
            <h3 className='justify-content-center m-1'>Brand Master </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoBrandUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >
                                        <fieldset disabled>

                                            <Form.Control className="form-control"
                                                placeholder="Brand ID: This Brand Code Will Be Auto Generated"
                                                {...register("ultiVineoBrandUniqeId")} />
                                        </fieldset>
                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Brand Code
                                        </Form.Text>
                                        <Form.Control  className="form-control "   {...register("vineoBrandCode")} required />
                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Brand Name
                                        </Form.Text>
                                        <Form.Control className="form-control"   {...register("vineoBrandName")} required />
                                    </FormGroup>

                                    <FormGroup >
                                        <Form.Text >
                                            SUB GRP || GRP || SUB CAT||CAT Name||Company Name'
                                        </Form.Text>
                                    </FormGroup>
                                    <Typeahead
                                        id="basic-example"
                                        onChange={setSelected}
                                        onClick={console.log(selected)}
                                        options={dataLoaded}
                                        placeholder="Choose SubGroup  Name..."
                                        selected={selected}
                                        labelKey={"vineoSubGroupName"}
                                        renderMenuItemChildren={(option) => (
                                            <div>
                                              <div>
                                                <small>SubGroup:  {option.vineoSubGroupName}</small>
                                                <small> Group: {option.vineoGroup.vineoGroupName}</small>
                                                <small> SubCat: {option.vineoGroup.vineoSubCategory.companySubCategoryName}</small>
                                                <small> Cat: {option.vineoGroup.vineoSubCategory.vineoCategory.companyCategoryName}</small>
                                              </div>
                                            </div>
                                          )}

                                    />
                                    {console.log("Selected" + selected)}
                                    {setValue("vineoSubGroup.ultiVineoSubGroupUniqeId", selected?.[0]?.ultiVineoSubGroupUniqeId)}
                                    
                                    <Form.Text >
                                        Sub Group Unique ID
                                    </Form.Text>
                                    <fieldset disabled>
                                        <FormGroup >
                                            <Form.Control className="form-control"  {...register('vineoSubGroup.ultiVineoSubGroupUniqeId')} required />

                                        </FormGroup>

                                    </fieldset>



                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add SUBGroup " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update SUBGroup" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row >

            </div >
        </div >
    )

}