import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import { addMonths } from 'date-fns';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddBillData, getVineoAddBillDatawithdate, getVineoShopVisitdatawithdate, getVineoShopVisitdatawithdateforMotherclientid } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { getVineoAccountCustomerMasterMenu } from '../../../redux/features/services/serviceHelper';
import Billprint from '../entries/billing/Billprint';
import ShopVisitprint from '../printFormat/ShopVisitprint';
import ShopVisitMap from './ShopVisitMap';
import AuthUser from '../../../constants/AuthUser';

export default function VisitSummary() {
	const { setValue } = useForm();
	useEffect(() => {
		console.log("Use Effect called ");
		dispatch(getVineoShopVisitdatawithdate());
	}, []);

	const { token, logout, getUser, getRole } = AuthUser();
	let currentDate = new Date();
	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const vineoShopVisitdata = useSelector((state) => state.vineoData.shopvisitdatawithdate)
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const [fromdate, setfromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
	const [toDate, setToDate] = useState(new Date());
	const [openprint, setOpenprint] = React.useState(false);
	const [dataforprint, setDataforprint] = useState();
	const [tableData, setTableData] = useState();
	const [showmap, setShowmap] = useState(false);
	const [selectedSalesman, setSelectedSalesman] = useState([])
	const [selectedParty, setSelectedParty] = useState([])
	const [satHoliday, setSatHoliday] = useState(false)
	const [sunHoliday, setSunHoliday] = useState(true)


	const notify = (message) => toast.success(message);
	const notifyError = (message) => toast.error(message)
	const notifyWarning = (message) => toast.warning(message)
	const [isLoading, setLoading] = useState(false);

	const [selectedFirm, setSelectedFirm] = useState([])
	const filteredTableData = (
		selectedParty?.[0]?.label &&
		selectedSalesman?.[0]?.label &&
		selectedFirm?.[0]?.label
	  )
		? tableData
		  ?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
		  ?.filter(item => item?.partyName === selectedParty?.[0]?.label)
		  ?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname === selectedFirm?.[0]?.label)
		: (
		  selectedParty?.[0]?.label
			? (selectedSalesman?.[0]?.label
			  ? tableData?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
			  : tableData
			)?.filter(item => item?.partyName === selectedParty?.[0]?.label)
			: (selectedSalesman?.[0]?.label
			  ? tableData?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
			  : (selectedFirm?.[0]?.label
				? tableData?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname === selectedFirm?.[0]?.label)
				: tableData
			  )
			)
		);
	  


	const totalVisits = filteredTableData?.length;

	const productiveVisits = filteredTableData?.filter(item => item.orderRecv).length;

	const nonProductiveVisits = filteredTableData?.filter(item => !item.orderRecv).length;

	const totalDailyShopVisitTarget = filteredTableData?.reduce((total, entry) => total + entry.dailyShopVisitTarget, 0);

	const uniqueSalesmen = [...new Set(filteredTableData?.map(item => item.nameofUser))].filter(Boolean).length;

	const uniqueParties = [...new Set(filteredTableData?.map(item => item.partyName))].filter(Boolean).length;

	const salesmenWithNoOrders = [...new Set(filteredTableData?.filter(item => !item.orderRecv).map(item => item.nameofUser))].filter(Boolean).length;

	const uniqueVisitDates = [...new Set(filteredTableData?.map(item => item.visitDate))].filter(Boolean);
	const numberOfDaysWorked = uniqueVisitDates.length;
	const nonProductiveVisitDates = [...new Set(filteredTableData?.filter(item => !item.orderRecv).map(item => item.visitDate))].filter(Boolean);
	const numberOfNonProductiveDays = nonProductiveVisitDates.length;

	function haversineDistance(lat1, lon1, lat2, lon2) {
		const R = 6371; // Radius of the Earth in km
		const dLat = (lat2 - lat1) * (Math.PI / 180);
		const dLon = (lon2 - lon1) * (Math.PI / 180);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const distance = R * c;
		return distance;
	}

	// Calculate total distance traveled by a salesman
	function calculateTotalDistance(travelCoordinates) {
		let totalDistance = 0;
		for (let i = 1; i < travelCoordinates.length; i++) {
			const prevPoint = travelCoordinates[i - 1];
			const currentPoint = travelCoordinates[i];
			totalDistance += haversineDistance(prevPoint.lat, prevPoint.lng, currentPoint.lat, currentPoint.lng);
		}
		return totalDistance;
	}

	const calculateTotalTarget = (data) => {
		const targetMap = new Map(); // To store unique dates and their total targets
	  
		data.forEach((entry) => {
		  const { nameofUser, visitDate, dailyShopVisitTarget } = entry;
	  
		  if (nameofUser && visitDate) {
			const existingTarget = targetMap.get(visitDate) || 0;
			targetMap.set(visitDate, existingTarget + dailyShopVisitTarget);
		  }
		});
	  
		let totalTarget = 0;
		targetMap.forEach((target) => {
		  totalTarget += target;
		});
	  
		return totalTarget;
	  };

	// Assuming `tableData` is your JSON data
	const salesmanTravelData = {};
	for (const item of (filteredTableData ? filteredTableData : [])) {
		const salesman = item?.nameofUser;
		if (salesman) {
			const lat = parseFloat(item?.lati);
			const lng = parseFloat(item?.longi);

			if (!isNaN(lat) && !isNaN(lng)) {
				if (!salesmanTravelData[salesman]) {
					salesmanTravelData[salesman] = [];
				}
				salesmanTravelData[salesman].push({ lat: lat, lng: lng });
			}
		}
	}

	// Calculate and store the total distance traveled by each salesman
	const salesmanDistanceData = {};

	for (const salesman in salesmanTravelData) {
		const travelCoordinates = salesmanTravelData[salesman];
		const totalDistance = calculateTotalDistance(travelCoordinates);
		salesmanDistanceData[salesman] = totalDistance;
		// console.log("Total Distance traveled is", salesmanDistanceData)

	}



	useEffect(() => {
		console.log("SalesmanData", salesmanDistanceData);
		console.log("Refreshing on state Change " + refreshcount)
		setLoading(false)
		setTableData(vineoShopVisitdata)

	}, [refreshcount]);


	useEffect(() => {
		// Initialize "From Date" to the current date
		const currentDate = new Date();
		const firstDayofMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

		setfromdate(firstDayofMonth)

		// Initialize "To Date" to the last day of the current month
		const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
		setToDate(lastDayOfMonth);
	}, []);


	function calculateWorkingDays(fromDate, toDate, satHoliday, sunHoliday) {
		const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

		let totalWorkingDays = 0;
		let currentDate = new Date(fromDate);

		while (currentDate <= toDate) {
			const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

			// Check if the current day is a weekend holiday
			if ((dayOfWeek === 0 && sunHoliday) || (dayOfWeek === 6 && satHoliday)) {
				// It's a weekend holiday, do nothing
			} else {
				totalWorkingDays++;
			}

			// Move to the next day
			currentDate = new Date(currentDate.getTime() + oneDay);
		}

		return totalWorkingDays;
	}

	const handleSelectHolidayChange = (event) => {
		const selectedValue = event.target.value;
	
		// Update satHoliday and sunHoliday based on the selected value
		if (selectedValue === "true") {
		  setSatHoliday(true);
		  setSunHoliday(true);
		} else if (selectedValue === "false") {
		  setSatHoliday(false);
		  setSunHoliday(false);
		} else if (selectedValue === "true-sat") {
		  setSatHoliday(true);
		  setSunHoliday(false);
		} else if (selectedValue === "true-sun") {
		  setSatHoliday(false);
		  setSunHoliday(true);
		}
	  };

	const handleFromDateChange = (date) => {
		setfromdate(date);

		if (true) {
			const newToDate = new Date(date);
			newToDate.setMonth(newToDate.getMonth() + 1);
			newToDate.setDate(0); // Set to last day of previous month
			setToDate(newToDate);
		}
	}

	const getdatafromserverwithdate = (reqdata) => {
		const rolename = getRole("");
		console.log("Role is " + rolename)


		if (reqdata.startDate && reqdata.endDate) {

			rolename === 'mothercompanyadmin' ?
				dispatch(getVineoShopVisitdatawithdateforMotherclientid(reqdata)) :
				dispatch(getVineoShopVisitdatawithdate(reqdata))


		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}
	const getAttendanceReport = (reqdata) => {
		const rolename = getRole("");
		console.log("Role is " + rolename)


		if (reqdata.startDate && reqdata.endDate) {

			rolename === 'mothercompanyadmin' ?
				dispatch(getVineoShopVisitdatawithdateforMotherclientid(reqdata)) :
				dispatch(getVineoShopVisitdatawithdate(reqdata))


		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}


	// needed below useEffect to solve issue which needed Load Bills to be clicked twice for initial page load only
	// re-write this logic to fetch existing data from redux for specified dates on initial load only
	// or it will keep fetching the data irrespective of the date selected on initial load only ( current behavior )
	// Solution: on navigation resetting the isvineoaddbilldatawithdateLoaded state variable in redux might solve the issue


	return (
		<div className="row justify-content-left ">
			<div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">Welcome to SFA Dashboard - Shop Visit Summary and Report</h4>
				</center>
			</div>

			<Row>


				<Col md={1}>
					<FormGroup className="m-2">
						<Form.Text >
							Month
						</Form.Text>
						<DatePicker className="form-control"
							selected={fromdate}
							// onChange={(date) => {setfromdate(date);setMonthSelected(true)}}
							dateFormat="MM/yyyy"
							showMonthYearPicker
							showFullMonthYearPicker
							onChange={handleFromDateChange}


						/>

					</FormGroup>
				</Col>

				{console.log("dates are", moment(fromdate).format(DATEFORMAT), "and ", moment(addMonths(fromdate, 1)).format(DATEFORMAT))}



				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							<div style={{ display: 'flex', alignItems: 'center' }}>
								Visit From Date

								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 1)); console.log("Date selected is ", fromdate)
								}} > -1Day</div>
								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 10))
								}} > -10Day</div>
								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 30))
								}} > -30</div>
							</div>

						</Form.Text>

						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setfromdate}
							name="vineoCreateLoadBillfromdate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillfromdate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text>To Date</Form.Text>
						<DatePicker
							className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
						/>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup className="m-2">
						<Form.Text>Load</Form.Text>
						<Button className="btn btn-primary" disabled={isLoading} onClick={() => { setLoading(true); getdatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) }) }}>
							<FontAwesomeIcon icon={faRotate} />
							&nbsp;Load Data
						</Button>
					</FormGroup>

				</Col>
				
			</Row>
			<Row>
				<Col md={1}>
					<FormGroup className="m-2">

						<Form.Text className="text-muted">
							Order
						</Form.Text>
						<Form.Select className="form-control "  >
							<option value="true">ALL</option>
							<option value="true">Yes</option>
							<option value="false">No</option>
						</Form.Select>

					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Salesman Name  Name:-</Form.Text >


						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedSalesman}
								options={Array.from(
									new Set(
										tableData
											?.filter((item) => item?.nameofUser !== null)
											?.map((item) => item?.nameofUser)
									)
								).map((nameofUser) => ({ label: nameofUser }))}
								// options={[]}

								placeholder="Choose Salesman Name..."
								selected={selectedSalesman}
								labelKey={"label"}
							/>
							<Button className="btn btn-primary" onClick={() => { selectedSalesman?.[0]?.label ? setShowmap(true) : notifyWarning("Select Salesman ") }}>
								<FontAwesomeIcon icon={faRotate} />ShoWMap


								{console.log("Salesmannameis", selectedSalesman?.[0]?.label)}
							</Button>

							
							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Party Name:-</Form.Text >


						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedParty}
								options={Array.from(
									new Set(
										tableData
											?.filter((item) => item?.partyName !== null)
											?.map((item) => item?.partyName)
									)
								).map((partyName) => ({ label: partyName }))}
								// options={[]}

								placeholder="Choose Party Name..."
								selected={selectedParty}
								labelKey={"label"}
							/>
							{console.log("Value of selected party is " + selectedParty?.[0]?.label)}




							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Firm Name:-</Form.Text >
						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedFirm}
								options={Array.from(
									 new Set(
										tableData
										  ?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname !== null)
										  ?.map(item => item.vineoRegisteredClientDetails.clientfirmname)
									  )
								).map((FirmName) => ({ label: FirmName }))}
								// options={[]}

								placeholder="Choose Firm Name..."
								selected={selectedFirm}
								labelKey={"label"}
							/>

							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={1}>
					<FormGroup className="m-2">
						<Form.Text className="text-muted">Holiday</Form.Text>
						<Form.Select className="form-control" onChange={handleSelectHolidayChange}>
							<option value="true-sun">Sunday</option>
							<option value="true-sat">Saturday</option>
							<option value="true">SAT-SUN</option>
							<option value="false">No</option>
						</Form.Select>
					</FormGroup>
				</Col>

			</Row>


			{/* rowData={selectedParty?.[0]?.label ?
							(selectedSalesman?.[0]?.label ?
								tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
								: tableData)?.filter((item) => item?.partyName === selectedParty?.[0]?.label) :
							(selectedSalesman?.[0]?.label ?
								tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
								: tableData)} */}


			<div className="row mt-4">
				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Visit (Total)
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{totalVisits}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-calendar fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-success shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
										Productive
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{productiveVisits}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>



				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-warning shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
										Non-Productive
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{nonProductiveVisits}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Convertion Rate
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{((productiveVisits / totalVisits) * 100).toFixed(2)} %</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row mt-4">
				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Working Salesman
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{uniqueSalesmen}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-calendar fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-success shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
										Party Visited
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{uniqueParties}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>



				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-warning shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
										Salesman with No Order
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{salesmenWithNoOrders}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Party Per Salesman
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{((uniqueParties / uniqueSalesmen)).toFixed(2)} </div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row mt-4">
				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Distance traveled
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{Object.values(salesmanDistanceData).reduce((sum, distance) => sum + distance, 0).toFixed(2)} km</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-calendar fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-success shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
										Working Days
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{numberOfDaysWorked}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>



				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-warning shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
										Non Productive Days
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{numberOfNonProductiveDays}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Party Per Days
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{((uniqueParties / numberOfDaysWorked)).toFixed(2)} </div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="row mt-4">
				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Total Target For { calculateWorkingDays(fromdate,toDate,satHoliday,sunHoliday)} Days 
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800"> {calculateTotalTarget(filteredTableData || [])} </div>
								</div>
								<div className="col-auto">
									<i className="fas fa-calendar fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-success shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
										Target Achieved 
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{totalVisits}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>



				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-warning shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
										Visit / Day 
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{(totalVisits/calculateWorkingDays(fromdate,toDate,satHoliday,sunHoliday)).toFixed(2)}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Target required Visit/Day
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{((calculateTotalTarget(filteredTableData || []) /calculateWorkingDays(fromdate,toDate,satHoliday,sunHoliday) )).toFixed(2)} </div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<Modal show={openprint}
				onHide={() => setOpenprint(!openprint)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print Shop Visit Memo </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className='p-4'>
						<ShopVisitprint data={dataforprint} />
					</Card>

				</Modal.Body>
				<Modal.Footer>


				</Modal.Footer>
			</Modal>

			<Modal show={showmap}
				onHide={() => setShowmap(!showmap)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Map of Visit </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className='p-4'>
						<ShopVisitMap dataArray={selectedSalesman?.[0]?.label ?
							tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
							: tableData} />
					</Card>

				</Modal.Body>
				<Modal.Footer>


				</Modal.Footer>
			</Modal>

		</div>
	)
}