import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from 'react-bootstrap-typeahead';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';


import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'



import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';
import { VINEOFORM_DELETE_SELECTEDCATEGORYDATA, VINEOFORM_UPDATE_SELECTEDCATEGORYDATA, BASEURL_JWTAUTH } from '../../../constants/Urlforvineo';
import { getVineoCompanyCategoryData, getVineoCompanyData } from '../../../redux/features/services/serviceHelper';

export default function CategoryMaster() {

    useEffect(() => {
        dispatch(getVineoCompanyData());
        // dispatch(getVineoCompanyCategoryData())
    }, []);

    const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    const [selected, setSelected] = useState([]);

    const vineooldCompnaydata = useSelector((state) => state.vineoData.vineocompany)


    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoCategoryUniqeId": "Working", "companyCategoryName": "Click Refresh To Load" }])

    const dispatch = useDispatch()
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const vineoCategory = useSelector((state) => state.vineoData.vineocategory)
    const isvineocategoryLoaded = useSelector((state) => state.vineoData.isvineocategoryLoaded)

    const { register, handleSubmit, reset, watch, formState: { errors }, setValue } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        if(selected[0])
        {
            submitForm(data)
        }
        else{
            notify("Pls Select Company")
        }
       
      
    }

    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)

        }

    };
    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'companyCategoryName',
            text: 'CAT. Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'vineoCompany.companyName',
            text: 'Company Name',
            sort: true
        },
    ];


    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };


    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("companyCategoryName", selectedRow.companyCategoryName);
            setValue("ultiVineoCategoryUniqeId", selectedRow.ultiVineoCategoryUniqeId);
            setValue("vineoCompany", selectedRow.vineoCompany);
            // setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
        }
        console.log("Update category ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Category ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTEDCATEGORYDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- CATEGORY  DELETED SUCCESSFULLY with NAME is = ' + requestdata.companyCategoryName)
                    getdatafromserver();
                    // try to reduce server hit by removeing arrray from here only 
                }
                else
                    notify("Error While Deleting CATEGORY (it May Have Associated Sub Category) Delete Sub Cat First Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting CATEGORY Try Again  Or Login as ADMIN")

            }
        )
    }
    const getdatafromserver = () => {

        dispatch(getVineoCompanyCategoryData())
        console.log("Called to get Category Data ")
        console.log(isvineocategoryLoaded)
        if (typeof vineoCategory != 'undefined' && Object.keys(vineoCategory).length > 0) {
            isvineocategoryLoaded ? setDataofvineoTableData(vineoCategory) : setDataofvineoTableData([{ "ultiVineoCategoryUniqeId": "Working", "companyCategoryName": "Click Refresh To Load" }])
        }
        console.log("Empty Data Received form Server")

    }


    const submitForm = (data) => {
        // api call
        console.log(data)
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTEDCATEGORYDATA, data).then(
            (res) => {
                if (res.data.errorCode == "0000") {
                    notify('Success:- Category  Created with Internal Id')
                    console.log(res.data)
                    reset()
                }
                else
                    notify("Error While Creating Category From Server ")


            },
            (err) => {
                notify("Error While Creating Category  ")
            }
        )
    }


    return (
        <div>

            <h3 className='justify-content-center m-1'>Add Category Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoCategoryUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >
                                        <fieldset disabled>
                                            <Form.Control className="form-control"
                                                placeholder="Category ID: This Category Code Will Be Auto Generated"
                                                {...register("ultiVineoCategoryUniqeId")} />
                                        </fieldset>
                                    </FormGroup>

                                    <FormGroup >
                                    <Form.Text className="text-muted">
                                        Category Code
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("vineoCategoryCode")} required />
                                </FormGroup>
                                
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Category Name
                                        </Form.Text>
                                        <Form.Control className="form-control"   {...register("companyCategoryName")} required />

                                    </FormGroup>


                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Company Name
                                        </Form.Text>

                                        <Typeahead
                                        id="basic-example"
                                        onChange={setSelected}
                                        onClick={console.log(selected)}
                                        options={vineooldCompnaydata}
                                        placeholder="Choose Category  Name..."
                                        selected={selected}
                                        labelKey={"companyName"}
                                       

                                    />
                                    {console.log("Selected" + selected)}
                                    {setValue("vineoCompany.ultiVineoCompanyUniqeId", selected?.[0]?.ultiVineoCompanyUniqeId)}


                                    </FormGroup>

                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add Category " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update Category" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>
                        </div>


                    </Col>
                </Row>


            </div>
        </div>
    )

}