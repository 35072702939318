import React from "react";
import {
    FormGroup, Label, Input, InputGroup, Alert,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, Table
} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { getVineoAreaData, getVineoCompanyData, getVineoGstData, getVineoRouteData, getVineoSalesmanUsers, getVineoUsers } from '../../../redux/features/services/serviceHelper';
import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { BASEURL_JWTAUTH, VINEO_MAP_AREATOSALESMAN } from "../../../constants/Urlforvineo";


export default function AreaToSalesman() {
    useEffect(() => {
        dispatch(getVineoCompanyData());
        dispatch(getVineoAreaData());
        dispatch(getVineoSalesmanUsers())
        dispatch(getVineoRouteData())

        // dispatch(getVineoCompanyCategoryData())
    }, []);
    const dispatch = useDispatch()

    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedArea, setSelectedArea] = useState([]);
    const [tableData, setTableData] = useState([{ "vineoAreaName": "Click Refresh to Laod ", "vineoAreaName": "Click Refresh to Load " }]);
    const [searchterm, setSearchterm] = useState("");
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const vineoareadataforMappingTable = [{ "vineoAreaName": "Click Refresh to Load ", "companyName": "Loading", "nameofUser": "Click Refresh to Load " }]
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    const vineoCompnaydata = useSelector((state) => state.vineoData.vineocompany)
    let routedatafortable = null;
    const selectedAreaUniqueid = [];
    const vineoareadata = useSelector((state) => state.vineoData.vineoareadata)
    const vineousersdata = useSelector((state) => state.vineoData.vineousers)
    const vineosalesmanusersdata = useSelector((state) => state.vineoData.vineosalesmanusers)
    const isvineosalesmanusersLoaded = useSelector((state) => state.vineoData.isvineosalesmanusersLoaded)
    const { register, handleSubmit, reset, watch, formState: { errors }, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = (data) => {
        console.log(data);
        if (data.ultiVineoCompanyUniqeId &&
            selectedArea?.[0]?.ultiVineoAreaUniqeId) {
            {
                {
                    selectedArea?.map((item) => {
                        selectedAreaUniqueid.push(String(item?.ultiVineoAreaUniqeId))
                    })
                }
                data.ultiAreatUniqeIdList = selectedAreaUniqueid
            }
            setIsLoading(true);
            submitForm(data)
        }

        else { notifyError("Please Select Company Salesman And Area All ") }


    }
    const getdatafromserverforcompanyareaandsalesman = () => {

        dispatch(getVineoCompanyData())
        dispatch(getVineoAreaData())
        dispatch(getVineoSalesmanUsers())
        console.log("Called to get Company Data Area Data and Salesman Data ")
        console.log(isvineocompanyLoaded)
    }
    const getdatafromserverforsalesmanandarea = () => {

        setIsLoading(true)
        dispatch(getVineoAreaData())

        console.log("Called to get Area and User Data")

    }

    useEffect(() => {

        console.log("Loading on Refresh count")
        setIsLoading(false)


    }, [refreshcount]);

    const submitForm = (data) => {
        // api call

        http.post(BASEURL_JWTAUTH + VINEO_MAP_AREATOSALESMAN, data).then(
            (res) => {
                // console.log(res.data)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Area Mapped to Salesman Succesfully')
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                    notifyError("Error While Mapping Area to Salesman From Server")
                }
                //  console.log(res.data)

            },
            (err) => {
                setIsLoading(false);
                console.log(err.data)
                notifyError("Error While Mapping Area to Salesman")
            }
        )
    }


    const handleSelectAll = () => {
        const allValues = vineoareadata.map((option) => option);
        setSelectedArea(allValues)
    }


    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }
    const [areaoptions, setAreaoptions] = useState({ "value": "Workinginsidemap ", "label": "Getting Data" });

    const compdata = vineoareadata?.vineoCompanies;


    const columns = [{
        dataField: 'vineoAreaName',
        text: 'Area Name',
        sort: true,
        editable: false
    },
    {
        dataField: 'companyName',
        text: 'MappedCompany',
        dataFormat: {},
        sort: true,
        formatter: rowClickedfunction,
    },
    {
        dataField: 'nameofUser',
        text: 'SalesManName'

    }
    ];

    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '100', value: 100
        },]
    };
    const { SearchBar } = Search;

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)

        }

    };

    return (
        <div>

            <h1 align="center">Salesman To Area</h1>
            <Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={6}>

                            <FormGroup >
                                <Form.Text className="text-muted">
                                    Company Name*
                                </Form.Text>

                                <InputGroup>
                                    <Form.Control
                                        as="select"
                                        validated="true"
                                        onChange={e => {
                                            console.log("company Unique Id ", e.target.value);
                                            setValue("ultiVineoCompanyUniqeId", e.target.value);
                                        }}

                                    >
                                        <option>Select Option </option>

                                        return (
                                        <>
                                            {
                                                vineoCompnaydata?.map((data) =>

                                                    <option key={data?.ultiVineoCompanyUniqeId} value={data?.ultiVineoCompanyUniqeId}>{data?.companyName}</option>
                                                )
                                            }
                                        </>
                                        );

                                    </Form.Control>
                                    <Button variant="primary"
                                        onClick={() => { dispatch(getVineoCompanyData()) }} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>

                                </InputGroup>
                            </FormGroup>


                        </Col>

                    </Row>
                    <Row>
                        <Col md={6}>

                            <FormGroup >

                                <Form.Text className="text-muted">
                                    Area Name*
                                </Form.Text>

                                {/* {console.log("value is" ,JSON.stringify(vineoareadata))} */}
                                <InputGroup className="mb-3">

                                    <Typeahead
                                        id="AreaNameTypehead"
                                        onChange={setSelectedArea}
                                        options={vineoareadata || []}
                                        placeholder="Choose Area Name..."
                                        selected={selectedArea}
                                        multiple={true}
                                        labelKey={(option) => `Name: ${option.vineoAreaName},  CID: ${option.vineoRegisteredClientDetails?.vineoRegisteredClientName}`}
                                        renderMenuItemChildren={(option) => (
                                            <div>
                                            <div>
                                                <small>Name: {option?.vineoAreaName} </small>
                                                <small>ID:{option?.vineoRegisteredClientDetails?.vineoRegisteredClientName}</small>
                                            </div>
                                        </div>
                                        )}
                                    />
                                    <Button variant="warning"
                                        onClick={handleSelectAll} >Select All

                                    </Button>
                                    <Button variant="primary"
                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup >
                                <Form.Text className="text-muted">
                                    SalesManName*
                                </Form.Text>
                                <InputGroup>

                                    <Typeahead
                                        id="ultimateUserUniqueidTypehead"
                                        onChange={(selected) => {
                                            if (selected.length > 0) {
                                                console.log("ultimateUserUniqueid ", selected?.[0]);
                                                setValue("ultimateUserUniqueid", selected?.[0]?.ultimateUserUniqueid);
                                            }
                                        }}
                                        options={vineosalesmanusersdata || []}
                                        placeholder="Select Option"
                                        labelKey={(option) => `Name: ${option.nameofUser}, UN: ${option.username}, Map: ${option.vineoisSalesmanMapped ? 'YES' : 'NO'}, CID: ${option.vineoRegisteredClientDetails?.vineoRegisteredClientName}`}
                                        renderMenuItemChildren={(option) => (
                                            <div>
                                            <div>
                                                <small>Name: {option?.nameofUser} </small>
                                                <small>- UserName:{option?.username}</small>
                                                <small>- ID:{option?.vineoRegisteredClientDetails?.vineoRegisteredClientName}</small>
                                            </div>
                                        </div>
                                        )}
                                    />

                                    <Button variant="primary"
                                        onClick={() => { dispatch(getVineoSalesmanUsers()) }} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        {/* <Col md={4}>
                            <FormGroup >
                                <Form.Text className="text-muted">
                                    Route Name
                                </Form.Text>

                                <InputGroup className="mb-3">

                                    <Form.Control
                                        as="select"
                                        validated="true"
                                        onChange={e => {
                                            console.log("ultiVineoRouteUniqeId ", e.target.value);
                                            setValue("ultiVineoRouteUniqeId", e.target.value);
                                        }}
                                    >
                                        <option>Select Option </option>
                                        return (
                                        <>
                                            {(vineoroutedata) ? (
                                                vineoroutedata?.map((vineoroutedata) =>

                                                    <option key={vineoroutedata.ultiVineoRouteUniqeId} value={vineoroutedata.ultiVineoRouteUniqeId}>{vineoroutedata?.vineoRouteName}</option>
                                                )
                                            ) : (

                                                console.log("Click Refresh to load Data ")

                                            )}
                                        </>
                                        );

                                    </Form.Control>

                                    <Button variant="primary"
                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>
                            </FormGroup></Col> */}

                        <Col md={4}>
                            {/* <Form.Text className="text-muted">
                                Company Code
                            </Form.Text> */}

                            <FormGroup style={{ display: 'none' }} >
                                <fieldset disabled>
                                    <Form.Control
                                        className="form-control"
                                        placeholder="Company Code: This Category Code Will Be Auto Generated"
                                        {...register("ultiVineoCompanyUniqeId")} />
                                </fieldset>
                            </FormGroup>

                        </Col>
                    </Row>





                    <input disabled={isLoading} className="btn btn-primary m-2" type="submit" value="Add Mapping " />


                </form>

                {/* <Multiselect buttonClass="btn btn-danger" data={this.state.list} multiple ></Multiselect> */}

            </Row>
            <div className="mt-5">
                <div className="mt-5">
                    {/* 
                    < ToolkitProvider keyField='vineoAreaName'
                        data={tableData}
                        search   >
                        {
                            props => (
                                <div>
                                    <div >

                                        <SearchBar className="m-1" {...props.searchProps} />
                                        <Button variant="primary" className="m-1"
                                            onClick={getdatafromserverforsalesmanandarea} >
                                            <FontAwesomeIcon icon={faRotate} />
                                        </Button>
                                    </div>
                                        <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                        {...props.baseProps}
                                        columns={columns}
                                        keyField='vineoAreaName'

                                        pagination={paginationFactory(options)}
                                    // selectRow={selectRow}
                                    // rowEvents={ rowEvents }
                                    />

                                </div>
                            )
                        }
                    </ToolkitProvider>
                     */}
                </div>
            </div>

            <div className="col-md-12 text-center">
                <InputGroup>
                    <Form.Control className="m-1" type="text" placeholder="Search ...." onChange={(e) => setSearchterm(e.target.value)} />
                    <Button variant="primary" className="m-1" disabled={isLoading} onClick={getdatafromserverforsalesmanandarea}>
                        <FontAwesomeIcon icon={faRotate} /> {isLoading ? <>Loading</> : <>Load Mapping Data</>}
                    </Button>
                </InputGroup>
                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr>
                            <td>Sr. No.</td>
                            <td>Area</td>
                            <td>Company Name</td>
                            <td>Salesman Name</td>
                            <td>Route Name</td>
                        </tr>
                        {
                            vineoareadata?.filter((item) => {
                                if (searchterm === "") {
                                    return item;
                                } else if (item.vineoAreaName.toLowerCase().includes(searchterm.toLowerCase())) {
                                    return item;
                                }
                            }).map((item, i) =>
                                <tr key={i}>
                                    <td>{i + 1}</td> {/* Add this line for Sr. No. */}
                                    <td>{item?.vineoAreaName}</td>
                                    <td>
                                        <>
                                            <Table striped bordered hover size="sm">
                                                <tbody>
                                                    {
                                                        item?.vineoCompanies?.map((data1, i1) =>
                                                            <tr key={i1}>
                                                                <td>{data1.companyName}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </>
                                    </td>
                                    <td>{item?.salesman?.nameofUser}</td>
                                    <td>{item?.salesman?.vineoRoute?.vineoRouteName}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>

        </div >
    )
}