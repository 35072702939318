import React from 'react';
import ControlledCarousel from './ControlledCarousel'
import {
    Carousel,
    CarouselIndicators,
    CarouselItem,
    CarouselCaption,
    CarouselControl,

} from 'react-bootstrap';

function SliderForLoginPage(props) {
    return (
        <div>
           <ControlledCarousel/>
        </div>
    );
}

export default SliderForLoginPage;