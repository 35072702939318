import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { getVineoFirmData, getVineoGodownData, getVineoVanMasterData } from '../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEO_IMPORT_PARTY_FROM_CSV_FILE, VINEO_IMPORT_PARTY_FROM_CSV_FILE_BYSUPERADMIN } from '../../constants/Urlforvineo';
import { Typeahead } from 'react-bootstrap-typeahead';
import http from '../../constants/AxiosConfig';
import AuthUser from '../../constants/AuthUser';

export default function ImportPartyAreaIntoSystem() {
    useEffect(() => {

    }, []);
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const [selectedGodown, setSelectedGodown] = useState([])
    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {

        console.log(data.vineofile[0]);
        // submitForm(data.vineofile[0])
    }


   
    const { getRole } = AuthUser();
    const rolename = getRole("");
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)


    const handleFileChange = (e) => {
        console.log("FIles Selected")
        setFile(e.target.files[0]);
    }

    const handleUpload = (e) => {

        setIsLoaded(true);

        e.preventDefault();
        const formData = new FormData();
        formData.append('vineofile', file);
        let url;
        console.log("formData", formData)
       
        if(rolename === 'superadmin')
        {
            url= VINEO_IMPORT_PARTY_FROM_CSV_FILE_BYSUPERADMIN;
        }
        else{
            url=VINEO_IMPORT_PARTY_FROM_CSV_FILE ;
        }
        
        console.log("file size ", (file?.size < 4000000))
       
        if (file?.size < 4000000) {
            http.post(BASEURL_JWTAUTH + url, formData)
                .then((res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify(' Success:-  Party Imported with ID as ' + res.data.additionalInfo)
                        setIsLoaded(false)
                    }
                    else
                        notifyError("Error While Importing Data  From Server " + res.data.additionalInfo)
                    //  console.log(res.data)
                    setIsLoaded(false)
                },
                    (err) => {
                        console.log(err)

                        notifyError("Error While Importing Data" + err.data)
                        setIsLoaded(false)
                    })
                .catch((err) => {

                    notifyError("Error While Importing Data" + err)
                    setIsLoaded(false)
                });

        }
        else {
            notifyError("File SIze is more than 4 MB Please Select Lower Size File");
            setIsLoaded(false)
        }

    }


    return (
        <div>
            <h3 className='justify-content-center m-1'>Upload CSV File For Party Import with Area</h3>
            <div className="row justify-content-left ">
                <Row>

                    <Row>
                        <Col md={6}>
                            <div >
                                <Card className='p-4 mt-4'>


                                    <div>
                                        <form onSubmit={handleUpload}>

                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>Select CSV File AS per Format * </Form.Label>
                                                <Form.Control type="file" onChange={handleFileChange} />
                                            </Form.Group>

                                            {/* <input type="file" onChange={handleFileChange} /> */}
                                            <Button disabled={isLoaded} type="submit">  {isLoaded ? 'Uploading' : 'Upload'}</Button>                                        </form>
                                       
                                    </div>

                                </Card>
                            </div>


                        </Col>
                    </Row>

                </Row>

            </div>
        </div>
    )

}