import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import http from '../../../constants/AxiosConfig';

import {
	BASEURL_JWTAUTH, VINEO_GETALLCATEGORY_AVAILABLE,
	VINEO_GETALLCOMPANY_AVAILABLE,
	VINEO_GETALLSUBCATEGORY_AVAILABLE,
	VINEO_GETALLUSERS_AVAILABLE, VINEO_GETALLFIRM_AVAILABLE, VINEO_GETALLGROUP_AVAILABLE, VINEO_GETALLSUBGROUP_AVAILABLE,
	VINEO_GETALL_BRAND_AVAILABLE, VINEO_GETALL_GST_NAME_AVAILABLE, VINEO_GETALL_PRODUCT_NAME_AVAILABLE, VINEO_GETALL_AREA_NAME_AVAILABLE,
	VINEO_GETALL_ROUTE_NAME_AVAILABLE, VINEO_GETALL_ACCOUNTTYPE1_NAME_AVAILABLE, VINEO_GETALL_ACCOUNTTYPE2_NAME_AVAILABLE, VINEO_GETALL_ACCOUNTGROUP_NAME_AVAILABLE,
	VINEO_GETALL_CUSTBANK_NAME_AVAILABLE, VINEO_GETALL_SERVICEACCOUNT_NAME_AVAILABLE,
	VINEO_GETALL_SCHEME_NAME_AVAILABLE,
	VINEO_GETALL_GODOWN_NAME_AVAILABLE,
	VINEO_GETALL_VAN_NAME_AVAILABLE,
	VINEO_GETALL_SPECIALGROUP_NAME_AVAILABLE,
	VINEO_GETALL_SUBBRAND_AVAILABLE,
	VINEO_GETALL_BATCH_NAME_AVAILABLE,
	VINEO_GETALL_ADDINCREASESTOCK_AVAILABLE,
	VINEO_GETALL_ADDBILL_AVAILABLE,
	VINEO_GETALL_ADDBILL_AVAILABLE_WITHDATE,
	VINEOFORM_DELETE_SELECTED_CREATELOADBILL,
	VINEO_GETALL_CREATELOADBILL_AVAILABLE_WITHDATE,
	VINEO_GETALL_PURCHASEBILL_AVAILABLE_WITHDATE,
	VINEO_GETALL_ADDGON_AVAILABLE_WITHDATE,
	VINEO_GETALL_UNAPPROVEDACCOUNTCUSTACCNTINFO_NAME_AVAILABLE,
	VINEO_GETALL_UNAPPROVEDACCOUNTCUSTACCNTINFO_NAME_AVAILABLE_WITHDATE,
	VINEO_GETALLSHOPVISITDATA,
	VINEO_GETALLSHOPVISITDATAFORMOTHERCLIENTID,
	VINEO_GETALL_ADDGONDATAFORMOTHERCLIENTID_AVAILABLE_WITHDATE,
	VINEO_GETALL_ADDBILL_AVAILABLE_WITHDATEFORMOTHERCL,
	VINEO_GETATTENDANCEREPORTFORALLUSERS
} from '../../../constants/Urlforvineo';




export const getVineoBatchData = createAsyncThunk('VineoBatchData', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_BATCH_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);


export const getVineoAddIncreaseStockData = createAsyncThunk('vineoaddincreasestockdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ADDINCREASESTOCK_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAddBillData = createAsyncThunk('vineoaddbilldata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ADDBILL_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAddBillDatawithdate =createAsyncThunk('vineoaddbilldatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ADDBILL_AVAILABLE_WITHDATE , reqdata).then(
		(res) => {
			console.log(res)
			// console.log(JSON.stringify(res.data))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAddBillDatawithdateForMotherClient =createAsyncThunk('vineoaddbilldatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ADDBILL_AVAILABLE_WITHDATEFORMOTHERCL , reqdata).then(
		(res) => {
			console.log(res)
			console.log(JSON.stringify(res.data))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);


export const getVineoAddGONDatawithdate =createAsyncThunk('vineoaddgondatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ADDGON_AVAILABLE_WITHDATE , reqdata).then(
		(res) => {
			console.log(res)
			console.log(JSON.stringify(res.data))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAddGONDatawithdateMotherCompanyID =createAsyncThunk('vineoaddgondatawithdate', async (reqdata) => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ADDGONDATAFORMOTHERCLIENTID_AVAILABLE_WITHDATE , reqdata).then(
		(res) => {
			console.log(res)
			console.log(JSON.stringify(res.data))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineounapprovedPartydatawithdate =createAsyncThunk('vineounapprovedpartydatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_UNAPPROVEDACCOUNTCUSTACCNTINFO_NAME_AVAILABLE_WITHDATE , reqdata).then(
		(res) => {
			console.log(res)
			// console.log(JSON.stringify(res.data))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoCreateLoadDatawithdate =createAsyncThunk('vineocreateloaddatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_CREATELOADBILL_AVAILABLE_WITHDATE , reqdata).then(
		(res) => {
			console.log(res)
			// console.log(JSON.stringify(reqdata))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAddPurchasedatawithdate =createAsyncThunk('vineoAddPurchasedatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_PURCHASEBILL_AVAILABLE_WITHDATE , reqdata).then(
		(res) => {
			console.log(res)
			// console.log(JSON.stringify(reqdata))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoShopVisitdatawithdate =createAsyncThunk('shopvisitdatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLSHOPVISITDATA , reqdata).then(
		(res) => {
			console.log(res)
		
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoattendancedatawithdate =createAsyncThunk('attendancedatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETATTENDANCEREPORTFORALLUSERS , reqdata).then(
		(res) => {
			console.log(res)
			console.log("Beofre"+JSON.stringify(res.data))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoShopVisitdatawithdateforMotherclientid =createAsyncThunk('shopvisitdatawithdate', async (reqdata) => {
	// console.log("Beofre"+JSON.stringify(reqdata))

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLSHOPVISITDATAFORMOTHERCLIENTID , reqdata).then(
		(res) => {
			console.log(res)
			// console.log(JSON.stringify(res.data))
			
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log(reqdata)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);



export const getUsertest = createAsyncThunk('user', async (id) => {
	const response = await axios.get(
		`https://jsonplaceholder.typicode.com/users/${id}`
	);
	return response.data;
});