import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';

// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import { faCheck, faCircleCheck, faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASEURL_JWTAUTH, DATEFORMAT, GSTVALIDATEAPIURLWITHKEY, VINEOFORM_DELETE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_BILLINGMANAGER, VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDATA, VINEO_CHECKPARTYCODEVALID_AVAILABLE, VINEO_GETALL_ACCOUNTCUSTACCNTINFO_CHECKMOBILENUMBER_AVAILABLE } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { getVineoAccountCustomerMasterMenu, getVineoAreaData, getVineoCustomerBankMasterData, getVineoGstData } from '../../../redux/features/services/serviceHelper';
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import DatePicker from "react-datepicker";

import {
    FormGroup, Label, Input, Modal,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup
} from 'react-bootstrap';
import CustomerBankInfo from './CustomerBankInfo';
import axios from 'axios';
import AreaMaster from './AreaMaster';
import { stateOptions } from '../../../constants/GstStatewithcode';

export default function CustomerAccountInfo() {

    const [tableData, setTableData] = useState([]);

    const dispatch = useDispatch()
    const isvineoaccountcustomerdataLoaded = useSelector((state) => state.vineoData.isvineoaccountcustomerdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const isvineoBankdataLoaded = useSelector((state) => state.vineoData.isvineocustomerbankMasterdataLoaded)
    const vineobankdata = useSelector((state) => state.vineoData.vineocustomerbankMasterdata)
    const [vineobankdataLoaded, setVineobankdataLoaded] = useState({});
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const [openingdate, setOpeningdate] = useState(new Date());
    const [loyalityopeningdate, setLoyalitopeningdate] = useState(new Date());
    const [dobdate, setDobdate] = useState(new Date());
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoCustomerAccountUniqeId": "Working", "vineoCustomerAccountName": "Click Refresh To Load" }])
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [readyToSubmit, setReadyToSubmit] = useState(false)
    const [gstAPIreqClicked, setGstAPIreqClicked] = useState(false)
    const vineoareadata = useSelector((state) => state.vineoData.vineoareadata)
    const [openAddBankAccnt, setOpenAddBankAccnt] = useState(false);
    const [openAddArea, setOpenAddArea] = useState(false);
    const [gstVerified, setGstVerified] = useState(false);
    const [nogstParty, setNogstParty] = useState(false);
    const [samecodefound, setSamecodefound] = useState(true);
    const [codeEntered, setCodeEntered] = useState();
    const [selectedBank, setSelectedBank] = useState([]);
    const [validpartycode, setValidpartycode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let maxNumericValue = -Infinity;
    let pattern = /^([0][1-9]|[1-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;

    const { register, handleSubmit, setValue, reset, watch, formState: { errors }, control, getValues } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }
  
    useEffect(() => {
        // dispatch(getVineoCustomerBankMasterData())
        dispatch(getVineoAccountCustomerMasterMenu())
        dispatch(getVineoAreaData())
    }, [])
    useEffect(() => {
        console.log("Refresh Count Changed ")
        setDataofvineoTableData(vineodata)
        setTableData(vineodata)
        setVineobankdataLoaded(vineobankdata)
        setIsLoading(false)
    }, [refreshcount])


    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)


    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }

    const rowStyle = { background: 'white' };

    const getRowStyle = (params) => {
        // console.log("vlaue of ",parseInt(params.data.vineoCustomerAccountcreditDay))
        // console.log(params.data.vineoAddSaleBillDate)

        // console.log("Date Diffrence is ", diffInDays)
        if (false)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'orange' };
        }
        if (false)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'gray' };
        }

    };

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }


    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        editable: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }
    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedRows();
        var selectedRowsString = '';
        var maxToShow = 5;
        console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }



    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [

        { headerName: "PartyCode.", field: "vineoCustomerAccountCode", checkboxSelection: true },
        { headerName: "Party", field: 'vineoCustomerAccountName' },
        { headerName: "LglNM ", field: "vineoCustomerAccount_legalName" },
        { headerName: "GST", field: "vineoCustomerGstNumber" },
        { headerName: "Add", field: 'vineoCustomerAccountaddress' },
        { headerName: "Add", field: 'vineoCustomerAccountaddress2' },
        { headerName: "Pin", field: 'vineoCustomerAccountpincode' },
        { headerName: "Mob", field: 'vineoCustomerAccountmob' },
    ]

    //

    const handleUpdate = () => {
        console.log("handle Update called ")
    }
    const handleDelete = () => {
        console.log("handle Update called ")
    }



    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoCustomerAccountName',
            text: 'Account Name',
            sort: true,
            formatter: rowClickedfunction,
        },
        {
            dataField: 'vineoCustomerAccountCode',
            text: 'Account Code ',
            sort: true
        },
        // {
        //     dataField: 'ultiVineoCustomerAccountUniqeId',
        //     text: 'Unique Id.',
        //     sort: true
        // },

    ];


    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };




    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoCustomerAccountUniqeId", selectedRow.ultiVineoCustomerAccountUniqeId);
            setValue("vineoCustomerAccountName", selectedRow.vineoCustomerAccountName);
            setValue("vineoCustomerAccount_legalName", selectedRow.vineoCustomerAccount_legalName);
            setValue("vineoCustomerAccountCode", selectedRow.vineoCustomerAccountCode);
            setValue("autoPurchaseImportActive", selectedRow.autoPurchaseImportActive);
            setValue("vineoCustomerAccountcontactPerson", selectedRow.vineoCustomerAccountcontactPerson);
            setValue("vineoCustomerAccountmob", selectedRow.vineoCustomerAccountmob);
            setValue("vineoCustomerAccountstate", selectedRow.vineoCustomerAccountstate);
            setValue("vineoCustomerAccountaddress", selectedRow.vineoCustomerAccountaddress);
            setValue("vineoCustomerAccountaddress2", selectedRow.vineoCustomerAccountaddress2);
            setValue("vineoCustomerAccountemail", selectedRow.vineoCustomerAccountemail);
            setValue("vineoCustomerGstNumber", selectedRow.vineoCustomerGstNumber);
            setValue("loyalityProgramType", selectedRow.loyalityProgramType);
            setValue("uniqueidofrefreedClientNameAutoImport", selectedRow.uniqueidofrefreedClientNameAutoImport);
            setValue("vineoCustomerAccountopeningDate", selectedRow.vineoCustomerAccountopeningDate);
            setValue("vineoCustomerAccountTown", selectedRow.vineoCustomerAccountTown);
            setValue("vineoCustomerAccountphoneNumber", selectedRow.vineoCustomerAccountphoneNumber);
            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
            setValue("vineoCustomerAccountType", selectedRow.vineoCustomerAccountType);
            setValue("vineoCustomerAccountType", selectedRow.vineoCustomerAccountType);
            setValue("creditissuedforReceipt", selectedRow.creditissuedforReceipt);
            setValue("vineoBankName", selectedRow?.vineoBankName);
            setValue("vineoBankAccountNumber", selectedRow?.vineoBankAccountNumber);
            setValue("vineoBankifscCode", selectedRow?.vineoBankifscCode);
            setValue("vineoCustomerAccountpincode", selectedRow.vineoCustomerAccountpincode);
            setValue("loyalityProgramType", selectedRow.loyalityProgramType);
            setValue("vineoCustomerAccountcreditDayIntegerValue", selectedRow.vineoCustomerAccountcreditDayIntegerValue);
            setValue("partyGstVerified", selectedRow.partyGstVerified);
            setValue("vineoArea.ultiVineoAreaUniqeId", selectedRow?.vineoArea?.ultiVineoAreaUniqeId);
            setValue("totalpartypendingbalance", selectedRow?.totalpartypendingbalance);
            setValue("gstregAsReversecharge", selectedRow?.gstregAsReversecharge);
            setValue("igstApplicableonIntraState", selectedRow?.igstApplicableonIntraState);
            setValue("shopphotourl", selectedRow?.shopphotourl);
            setValue("longitude", selectedRow?.longitude);
            setValue("latitude", selectedRow?.latitude);
            setValue("address", selectedRow?.address);
            setValue("creditPaymentApplicable", selectedRow?.creditPaymentApplicable);
            setValue("partyCategory", selectedRow?.partyCategory);
            if (selectedRow?.vineoCustomerAccountdateOfBirth) {
                const dobDate = new Date(selectedRow.vineoCustomerAccountdateOfBirth);

                if (!isNaN(dobDate.getTime())) {
                    console.log("Parsed DOB Date:", dobDate);
                    setDobdate(dobDate);
                } else {
                    console.log("Invalid DOB Date:", selectedRow.vineoCustomerAccountdateOfBirth);
                }
            } else {
                console.log("DOB Date is empty or undefined");
            }

            if (selectedRow?.vineoCustomerAccountopeningDate) {
                const openingDate = new Date(selectedRow.vineoCustomerAccountopeningDate);

                if (!isNaN(openingDate.getTime())) {
                    console.log("Parsed DOB Date:", openingDate);
                    setOpeningdate(openingDate);
                } else {
                    console.log("Invalid Opening Date:", selectedRow.vineoCustomerAccountopeningDate);
                }
            } else {
                console.log("Date is empty or undefined");
            }

            if (selectedRow?.loyalityProgramOpeningDate) {
                const loyalityDate = new Date(selectedRow.loyalityProgramOpeningDate);

                if (!isNaN(loyalityDate.getTime())) {
                    console.log("Parsed DOB Date:", loyalityDate);
                    setLoyalitopeningdate(loyalityDate);
                } else {
                    console.log("Invalid Opening Date:", selectedRow.loyalityProgramOpeningDate);
                }
            } else {
                console.log("Date is empty or undefined");
            }

            setGstVerified(selectedRow.partyGstVerified)

        }
        console.log("Update Party ")
    }

    const checkMobileNumber = (mobilenumber) => {
        if (mobilenumber && mobilenumber.trim().length > 9) {
            // Perform the HTTP call only if the mobile number is not empty
            http.post(BASEURL_JWTAUTH + VINEO_GETALL_ACCOUNTCUSTACCNTINFO_CHECKMOBILENUMBER_AVAILABLE + "/" + mobilenumber).then(
                (res) => {
                    console.log(res);
                    if (res.data.errorCode === "0000") {
                        notify('Success: Mobile Number is Available');
                    } else {
                        notifyError("Invalid Mobile Number");
                    }
                },
                (err) => {
                    notifyError("Error -- Try Again or Login as ADMIN");
                }
            );
        } else {
            // Handle the case where the mobile number is empty or has length 0
            notifyError("Invalid Mobile Number: Mobile number is empty or length is not 10 digit");
        }
    };
    const getGSTDetailsfromNumber = (gstnumber) => {
        //call gst to get details
        gstnumber = gstnumber.toUpperCase();
        let result = pattern.test(gstnumber);

        if (result) {
            console.log("gstNumber", gstnumber)
            console.log("result", result)
            setGstAPIreqClicked(true)
            {

                http.post(BASEURL_JWTAUTH + GSTVALIDATEAPIURLWITHKEY + gstnumber).then(
                    (res) => {
                        // console.log(res)
                        // console.log("JSON VAlue is ")
                        // console.log(JSON.stringify(res.data))
                        // console.log((res.data.flag))

                        if (res.data.flag) {
                            console.log(res.data)
                            console.log(res.data?.data?.tradeNam);
                            setValue("vineoCustomerAccountName", res.data?.data?.tradeNam);
                            setValue("vineoCustomerAccount_legalName", res.data?.data?.lgnm);
                            setValue("vineoCustomerAccountstate", res.data?.data?.pradr?.addr?.stcd.trim());
                            setValue("vineoCustomerAccountaddress", res.data?.data?.pradr?.addr?.flno + res.data?.data?.pradr?.addr?.bno + "," + res.data?.data?.pradr?.addr?.bnm);
                            setValue("vineoCustomerAccountaddress2", res.data?.data?.pradr?.addr?.st + "," + res.data?.data?.pradr?.addr?.loc);
                            setValue("vineoCustomerAccountTown", res.data?.data?.pradr?.addr?.dst.trim());
                            setValue("vineoCustomerAccountpincode", (res.data?.data?.pradr?.addr?.pncd).trim());
                            setGstVerified(true)

                            notify('Success:- Api Details Fetched from API ')
                            setGstAPIreqClicked(true);

                        }
                        else { notifyError('Error While GST Account Info From Server') }
                        //console.log(res.data)
                        setGstAPIreqClicked(false);
                    },
                    (err) => {
                        setGstAPIreqClicked(false)
                        notifyError('Error While Getting Account Info')

                    }
                )
            }
        }
        else {
            setGstAPIreqClicked(false);
            notifyError("GST Number is Incorrect ")
        }

    }

    const checkPartyCodeValid = (partycode) => {
        //call gst to get details

        let pattern = /^\S+$/;
        let result = pattern.test(partycode);

        if (result) {

            let partycodeobject = { 'partcode': partycode }
            console.log("partycode", partycodeobject)

            http.post(BASEURL_JWTAUTH + VINEO_CHECKPARTYCODEVALID_AVAILABLE, partycodeobject).then(
                (res) => {
                    if (res.data.errorCode === '0000') {
                        notify("Congratulations Party Code Available")
                        setValidpartycode(true);
                    }
                    else {
                        notifyError("Party Code already Present")
                        setValidpartycode(false);
                    }
                    // se Code allowed to be used    
                    console.log(res.data)


                },
                (err) => {
                    setValidpartycode(false)
                    notifyError('Error Checking Party Code')
                }
            )
        }
        else {

            notifyError("Selecte Another party Code , Code already Present")
        }

    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Customer Account ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }
    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Customer Account  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoCustomerAccountName)
                }
                else { notifyError("Error While Deleting Account  Or Login as ADMIN") }
            },
            (err) => {
                notifyError("Error While Deleting Account Try Again  Or Login as ADMIN")

            }
        )
    }
    const submitForm = (data) => {
        // console.log(JSON.stringify(data))
        setReadyToSubmit(true)
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_BILLINGMANAGER, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Customer Account  Created With Internate Id')
                    reset()
                    setReadyToSubmit(false)
                }
                else { notifyError('Error While Registering Customer Account Info From Server' + res.data.errorMessage) }
                //console.log(res.data)
                setReadyToSubmit(false)
            },
            (err) => {
                notifyError('Error While Registering Customer Account Info' + err)
                setReadyToSubmit(false)
            }
        )
    }

    const getdatafromserverforcompanyareaandsalesman = () => {
        dispatch(getVineoAreaData())

    }
    const getdatafromserver = () => {
        setIsLoading(true);
        console.log("Called to get Customer Account Data ")

        dispatch(getVineoAccountCustomerMasterMenu())

        if (Object.keys(vineodata).length > 0) {
            isvineoaccountcustomerdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoCustomerAccountUniqeId": "Working", "vineoCustomerAccountName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }

    return (
        <div>

            <h3 className='justify-content-center m-1'>Customer Account Information </h3>
            <div className="row justify-content-left ">

                <Col md={3}>
                    <ToolkitProvider keyField='ultiVineoCustomerAccountUniqeId'
                        data={dataofvineoTableData ? dataofvineoTableData : []}
                        columns={columns}
                        search   >
                        {
                            props => (
                                <div>
                                    <div >
                                        <Button variant="primary" disabled={isLoading}
                                            onClick={getdatafromserver} >
                                            <FontAwesomeIcon icon={faRotate} />{isLoading ? <>Loading</> : <></>}
                                        </Button>
                                        <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                        <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                        <SearchBar className="m-1" {...props.searchProps} />
                                    </div>
                                    <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                        rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                        {...props.baseProps}
                                        pagination={paginationFactory(options)}
                                        selectRow={selectRow}
                                    // rowEvents={ rowEvents }
                                    />

                                </div>
                            )
                        }
                    </ToolkitProvider>


                    <Row>


                        <ListGroup>
                            <h2> Some Party Stats </h2>
                            <ListGroup.Item>PendingfromParty :{selectedRow?.totalpartypendingbalance}</ListGroup.Item>
                            <ListGroup.Item>Total Sale :{selectedRow?.totalsale}</ListGroup.Item>
                            <ListGroup.Item>Total Bills :{selectedRow?.totalNumberofbills}</ListGroup.Item>
                            <ListGroup.Item>Cancel Bills :{selectedRow?.canceledbills}</ListGroup.Item>
                            <ListGroup.Item>Credit Issued :{selectedRow?.creditissued}</ListGroup.Item>
                            <ListGroup.Item>Reciept Credit  :{selectedRow?.creditissuedforReceipt}</ListGroup.Item>
                            <ListGroup.Item>Credit Used :{selectedRow?.creditapplied}</ListGroup.Item>
                            <ListGroup.Item>Last Bill AMT. :{selectedRow?.lastBillAmount}</ListGroup.Item>
                            <ListGroup.Item>Last Bill Date. :{selectedRow?.lastBillDate}</ListGroup.Item>
                            <ListGroup.Item>Last Visit Date :{selectedRow?.lastVisitDate}</ListGroup.Item>
                        </ListGroup>
                        {/* <p>totalGstoncollectedfromparty :{selectedRow?.vineofirmGst?.totalGstoncollectedfromparty}</p>
                        <p>totalGstonsaleside :{selectedRow?.vineofirmGst?.totalGstonsaleside}</p>
                        <p>PaymentfromParty :{selectedRow?.vineofirmGst?.totalbillamountpaidforCustomer}</p> */}


                    </Row>
                </Col>



                <Col md={9}>
                    <div >
                        <Card className="p-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <Row>
                                        <Col md ={4}>

                                            <input className="btn btn-primary" disabled={readyToSubmit} type="submit" value="Save" />
                                            <button type="button" onClick={() => reset()} className="btn btn-secondary m-2"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                            <Button
                                            variant={nogstParty ? "success" : "danger"}
                                            onClick={() => setNogstParty(!nogstParty)}
                                        >
                                            {nogstParty ? "NoGST" : "NoGST"}
                                        </Button>
                                        </Col>
                                        <Col md={6}></Col>
                                        <Col>
                                            <FormGroup>
                                                <fieldset disabled>
                                                    <Form.Control className="form-control "
                                                        placeholder="Account ID: This Code Will Be Auto Generated"
                                                        {...register("ultiVineoCustomerAccountUniqeId")} />
                                                </fieldset>
                                            </FormGroup>
                                        </Col>


                                    </Row>
                                </div>
                                <Row>
                                    {/*<Col md={3}>
                                        

                                        <FormGroup >

                                            <Form.Text >
                                                Account Code* -
                                            </Form.Text>
                                            <InputGroup>
                                                <Form.Control
                                                    style={!samecodefound ? { color: "#ff0000", } : { color: "#008000", }}
                                                    onChangeCapture={(e) => {
                                                        setSamecodefound(true);
                                                        dataofvineoTableData?.map((Item) => {
                                                            if (Item?.vineoCustomerAccountCode === e.target.value) { setSamecodefound(false) }
                                                        });
                                                        // dataofvineoTableData?.forEach((Item) => {
                                                        //     // Extract the numeric part from the vineoCustomerAccountCode
                                                        //     const numericValue = Item?.vineoCustomerAccountCode
                                                        //         ? parseInt(Item.vineoCustomerAccountCode.replace(/\D/g, ''), 10)
                                                        //         : null;

                                                        //     // Check if the extracted numeric value is a valid number
                                                        //     if (!isNaN(numericValue)) {
                                                        //         // Update the maximum if the current numeric value is greater
                                                        //         if (numericValue > maxNumericValue) {
                                                        //             maxNumericValue = numericValue;
                                                        //         }
                                                        //     }})

                                                        //     console.log("Valeis"+ maxNumericValue+1)
                                                    }}

                                                    className="form-control custom-form-field " size='sm'  {...register("vineoCustomerAccountCode")} />

                                                <Button variant="info"
                                                    onClick={() => { checkPartyCodeValid(watch('vineoCustomerAccountCode')) }} >

                                                    {validpartycode ? <FontAwesomeIcon icon={faCheck} style={{ color: "#2e7a05", }} />
                                                        :
                                                        <FontAwesomeIcon icon={faCheck} style={{ color: "#ff0000", }} />}

                                                </Button>
                                            </InputGroup>
                                        </FormGroup> 

                                    </Col>*/}
                                    {setValue("partyGstVerified", gstVerified)}
                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text className={(isEditDataSelected && selectedRow?.partyGstVerified) ? "text-primary" : "text-muted"}>
                                                GstNumber {((isEditDataSelected && selectedRow?.partyGstVerified) || gstVerified) ? "Verified" : "Unverified"}
                                            </Form.Text>
                                            <InputGroup>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoCustomerGstNumber")} />
                                                <Button variant="info" disabled={gstAPIreqClicked}
                                                    onClick={() => { setGstAPIreqClicked(true); getGSTDetailsfromNumber(watch('vineoCustomerGstNumber')) }} >
                                                    <FontAwesomeIcon icon={faCircleCheck} /> GetDetails
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                10 Digit Mobile Number*
                                            </Form.Text>
                                            <InputGroup>
                                                <Form.Control className="form-control  custom-form-field" size='sm'  {...register("vineoCustomerAccountmob", { minLength: 10, maxLength: 10 })} required />
                                                {errors.mobilenumber && <span>Enter Mobile Number as 10  digit </span>}<Button variant="info"
                                                    onClick={() => { checkMobileNumber(watch('vineoCustomerAccountmob')) }} >
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </Button></InputGroup>

                                        </FormGroup>
                                    </Col>

                                </Row>

                                {(gstVerified || isEditDataSelected || nogstParty) ?
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text >
                                                    Account Name*
                                                </Form.Text>
                                                <Form.Control className="form-control  custom-form-field" size='sm'   {...register("vineoCustomerAccountName")} required />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text >
                                                    Account Legal Name*
                                                </Form.Text>
                                                <Form.Control defaultValue={watch('vineoCustomerAccount_legalName')} className="form-control  custom-form-field" size='sm'   {...register("vineoCustomerAccount_legalName")} required />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Opening Date
                                                </Form.Text>

                                                <DatePicker className="form-control"
                                                    selected={openingdate}
                                                    onChange={setOpeningdate}
                                                    name="CustomerOpejing Date"
                                                    dateFormat="dd-MM-yyyy"

                                                />
                                                {setValue("vineoCustomerAccountopeningDate", moment(openingdate).format(DATEFORMAT))}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text >
                                                    DOB Date
                                                </Form.Text>

                                                <DatePicker className="form-control"
                                                    selected={dobdate}
                                                    onChange={setDobdate}
                                                    name="Cust DOB Date"
                                                    dateFormat="dd-MM-yyyy"

                                                />
                                                {setValue("vineoCustomerAccountdateOfBirth", moment(dobdate).format(DATEFORMAT))}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Area Name -{selectedRow?.vineoArea?.vineoAreaName}
                                                </Form.Text>

                                                <InputGroup className="mb-3">

                                                    <Form.Control
                                                        className="form-control custom-form-field"
                                                        as="select"
                                                        validated="true"
                                                        onChangeCapture={e => {
                                                            console.log("ultiVineoAreaUniqeId ", e.target.value);
                                                            setValue("vineoArea.ultiVineoAreaUniqeId", e.target.value);
                                                        }}

                                                    >
                                                        <option>Select Option</option>
                                                        return (
                                                        <>
                                                            {(vineoareadata) ? (
                                                                vineoareadata?.map((vineoareadata) =>

                                                                    <option key={vineoareadata?.ultiVineoAreaUniqeId} value={vineoareadata?.ultiVineoAreaUniqeId}>{vineoareadata?.vineoAreaName}</option>
                                                                )
                                                            ) : (

                                                                console.log("Click Refresh to load Data ")

                                                            )}
                                                        </>
                                                        );

                                                    </Form.Control>

                                                    <Button variant="primary"
                                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                                        <FontAwesomeIcon icon={faRotate} />
                                                    </Button>
                                                    <Button variant="primary"
                                                        onClick={() => { setOpenAddArea(true) }}>
                                                        +
                                                    </Button>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>



                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text >
                                                    Address
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoCustomerAccountaddress", { minLength: 10 })} />

                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text >
                                                    Address Line 2
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm' {...register("vineoCustomerAccountaddress2", { minLength: 5 })} />

                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text >
                                                    Town/City
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field " size='sm'   {...register("vineoCustomerAccountTown")} />
                                            </FormGroup>
                                        </Col>


                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text >
                                                    State
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field " size='sm'   {...register("vineoCustomerAccountstate")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>
                                                    Pin Code
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field " type="number" size='sm'  {...register("vineoCustomerAccountpincode")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Email ID
                                                </Form.Text>
                                                {/* , { pattern: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$' } */}
                                                <Form.Control type="email" className="form-controll custom-form-field" size='sm'   {...register("vineoCustomerAccountemail")} />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>
                                                    Tele Phone Number
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'   {...register("vineoCustomerAccountphoneNumber")} />
                                            </FormGroup>
                                        </Col>


                                        <Col md={3} >
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Party Category
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm'  {...register("partyCategory")}>
                                                    <option value="ALL"> ALL</option>
                                                    <option value="NAYARA">NAYARA</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>

                                        <Col md={3} >
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Account type
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountType")}>
                                                    <option value="RETAIL4_WHEELER_AUTOSHOP"> RETAIL4 WHEELER AUTO SHOP</option>
                                                    <option value="IWS_2WHEELER">IWS 2 WHEELER</option>
                                                    <option value="RETAIL_CV">RETAIL CV</option>
                                                    <option value="RETAIL2WHEELER_AUTOSHOP">RETAIL 2 WHEELER AUTO SHOP</option>
                                                    <option value="IWS4_WHEELER">IWS4_WHEELER</option>
                                                    <option value="FLEET_CUSTOMER">FLEET CUSTOMER</option>
                                                    <option value="MULTILUBE_SHOP">MULTILUBE SHOP</option>
                                                    <option value="TRANSPORTER">TRANSPORTER</option>
                                                    <option value="MARINE">MARINE</option>
                                                    <option value="OTHERS">OTHERS</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text >
                                                    AutoImport ID
                                                </Form.Text>
                                                <Form.Control disabled={true} className="form-control  custom-form-field" defaultValue={selectedRow?.uniqueidofrefreedClientNameAutoImport} size='sm' />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    Bank
                                                </Form.Text>
                                                <Row>
                                                    <Col md ={3}><Form.Control className="form-control custom-form-field" placeholder='Bank Name' size='sm' {...register("vineoBankName")}  /></Col>
                                                    <Col md={3}><Form.Control className="form-control custom-form-field" placeholder='ifsc Code' size='sm' {...register("vineoBankifscCode")}  /></Col>
                                                    <Col>    <Form.Control className="form-control custom-form-field" placeholder='Accnt Number' size='sm' {...register("vineoBankAccountNumber")}  /></Col>
                                                   </Row>
                                                
                                                {/* <Form.Text variant="info" onClick={() => { setOpenAddBankAccnt(true) }}>
                                             + Add Bank
                                         </Form.Text>
                                         <Form.Text variant="info" onClick={onBankRefresh}>
                                             + REF
                                         </Form.Text> */}
                                                {/* <Controller
                                             control={control}
                                             name={`vineofirmBankArray`}
                                             rules={{
                                                 // required: "Please, select at least one value"
                                             }}

                                             render={({ field, fieldState }) => (
                                                 <div >
                                                     <InputGroup>
                                                         <Typeahead
                                                             {...field}
                                                             id="selectedbanktypeahead"
                                                             size='sm'
                                                             labelKey={"vineoBankName"}
                                                             onChange={setSelectedBank}

                                                             selected={selectedBank}
                                                             renderMenuItemChildren={(option) => (
                                                                 <div>
                                                                     <div>
                                                                         <small>Bank:  {option.vineoBankName}</small>
                                                                         <small>A/C: {option.vineoBankAccountNumber}</small>
                                                                     </div>
                                                                 </div>
                                                             )
                                                             }
                                                             options={vineobankdataLoaded ? vineobankdataLoaded : []}
                                                             className={fieldState.invalid ? "is-invalid" : ""}
                                                             ref={() => {

                                                                 selectedBank ?
                                                                     setValue("vineoBank", selectedBank?.[0]) :
                                                                     <></>
                                                             }}
                                                         />
                                                         <Button variant="info"
                                                             onClick={onBankRefresh} >
                                                             <FontAwesomeIcon icon={faRotate} />
                                                         </Button>
                                                         <Button variant="primary"
                                                             onClick={() => { setOpenAddBankAccnt(true) }}>
                                                             +
                                                         </Button>
                                                     </InputGroup>
                                                     <p id="typeaheadError" className="invalid-feedback">
                                                         {fieldState.error?.message}
                                                     </p>
                                                     <Form.Text className="text-muted">
                                                         {isEditDataSelected ? <>Old Bank:{getValues(`vineoBank`)?.vineoBankName} + Acc. {getValues(`vineoBank`)?.vineoBankAccountNumber} </> : <></>}
                                                     </Form.Text>
                                                 </div>
                                             )}
                                         /> */}
                                          

                                            </FormGroup></Col>


                                        <Col md={3} >
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    AutoPurcahse Active
                                                </Form.Text>
                                                <Form.Select disabled={(!isEditDataSelected)} className="form-control custom-form-field" size='sm'  {...register("autoPurchaseImportActive")}>
                                                    <option value={true}>YES</option>
                                                    <option value={false}>No</option>

                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Credit Payment Applicable
                                                </Form.Text>
                                                <Form.Select className="form- custom-form-field " size='sm'  {...register("creditPaymentApplicable")}>
                                                    <option value="false">NO</option>
                                                    <option value="true">YES</option>

                                                </Form.Select>
                                            </FormGroup>
                                        </Col>

                                        <Col >
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Loyality Account Type
                                                </Form.Text>
                                                <Form.Select disabled={true} className="form- custom-form-field " size='sm'  {...register("loyalityProgramType")}>
                                                    <option value="BRONZE">BRONZE</option>
                                                    <option value="SILVER">SILVER</option>
                                                    <option value="GOLD">GOLD</option>
                                                    <option value="STAR">STAR</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>


                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Opening Bal.
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field " defaultValue="0.00" size='sm'   {...register("vineoCustomerAccountopeningbalance")} />
                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text >
                                                    Loyality Opening Date
                                                </Form.Text>

                                                <DatePicker className="form-control"
                                                    selected={loyalityopeningdate}
                                                    onChange={setLoyalitopeningdate}
                                                    name="CustomerOpejing Date"
                                                    dateFormat="dd-MM-yyyy"

                                                />

                                                {setValue("loyalityProgramOpeningDate", moment(loyalityopeningdate).format(DATEFORMAT))}
                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    GST AS Rev. Charge
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("gstregAsReversecharge")}  >
                                                    <option value="N">NO</option>
                                                    <option value="Y">YES</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    igstApplicableonIntraState
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("igstApplicableonIntraState")}  >
                                                    <option value="N">NO</option>
                                                    <option value="Y">YES</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Rupess
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountrupess")}>
                                                    <option value="Dr1">Dr1</option>
                                                    <option value="Dr2">Dr2</option>
                                                    <option value="Dr3">Dr3</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Credit Day
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field " size='sm' defaultValue="45"  {...register("vineoCustomerAccountcreditDayIntegerValue")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Credit Bill
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field " type="number" step="0.01" defaultValue="0.00" size='sm'  {...register("vineoCustomerAccountcreditBill")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Pending From Party
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field " type="number" step="0.01" defaultValue="0" size='sm'  {...register("totalpartypendingbalance")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Receipt Credit
                                                </Form.Text>
                                                <Form.Control type="number" className="form-control custom-form-field " defaultValue="0" size='sm'  {...register("creditissuedforReceipt")} />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    DL EX 1
                                                </Form.Text>
                                                <DatePicker className="form-control custom-form-field"
                                                    selected={openingdate}
                                                    onChange={setOpeningdate}
                                                    name="CustomerdlEx Date"
                                                    dateFormat="dd-MM-yyyy"

                                                />

                                                {setValue("vineoCustomerAccountdlExpOne", moment(openingdate).format(DATEFORMAT))}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Weekly Off
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm' {...register("vineoCustomerAccountweeklyOff")}>
                                                    <option value="vineoCustomerAccountweeklyOff1">NONE</option>
                                                    <option value="vineoCustomerAccountweeklyOff2">YES</option>

                                                </Form.Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Black Listed
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountblackListed")}>
                                                    <option value="No">NO</option>
                                                    <option value="YES">YES</option>

                                                </Form.Select>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Dist. Km
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" defaultValue="0.00" size='sm'  {...register("vineoCustomerAccountdistKm")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Close Time
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountcloseTime")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Seq No
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" defaultValue="0.00" size='sm'  {...register("vineoCustomerAccountseqNumber")} />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Contact Person
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field " size='sm'  {...register("vineoCustomerAccountcontactPerson")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Common Name
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountcommonName")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Common Code
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("vineoCustomerAccountcommonCode")} />
                                            </FormGroup>
                                        </Col>
                                        <Col >
                                            <Controller
                                                control={control}
                                                name="shopimage"
                                                render={({ field }) => (
                                                    <div>
                                                        <input
                                                            type="file"
                                                            onChange={(e) => {
                                                                field.onChange(e.target.files[0]);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            /></Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Shop Photo URL
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("shopphotourl")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Longitude
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("longitude")} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Latitude
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" size='sm'  {...register("latitude")} />
                                            </FormGroup>
                                        </Col>


                                    </Row>
                                    : <></>}


                                <div>
                                    <input className="btn btn-primary m-2" disabled={readyToSubmit} type="submit" value="Save" />
                                    <button type="button" onClick={() => reset()} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                </div>
                            </form>
                        </Card>
                    </div>

                </Col>

            </div>
            <Col md={2}><Button className="btn btn-primary mt-5" onClick={onExportClick} value="ExportToCsv">Export To CSV
            </Button></Col>
            <Col > <>Total Number of Customer : {tableData ? tableData.length : 0}</></Col>



            <div className="ag-theme-alpine" style={{ height: '400px' }}>
                <div className="ag-theme-alpine" style={{ height: '700px' }}>
                    <AgGridReact
                        id="AgGridforPartyData"
                        rowData={tableData ? tableData : []}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        // onRowSelected={onSelectBill}
                        rowSelection="multiple"
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                    />

                </div>
            </div>
            <div>
                <Modal show={openAddBankAccnt}
                    onHide={() => { setOpenAddBankAccnt(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <CustomerBankInfo />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { setOpenAddBankAccnt(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={openAddArea}
                    onHide={() => { setOpenAddArea(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <AreaMaster />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { setOpenAddBankAccnt(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div>
        </div>
    )

}