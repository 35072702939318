import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faCircleCheck, faClose, faL, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useGetRegisteredUsersByPostMutation } from "../../redux/features/services/postApi";
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup, Label, Input, Tab, Table, Container,
    Button, Form, Row, Col, ListGroup, ListGroupItem, FloatingLabel, Card, InputGroup
} from 'react-bootstrap';

import {
    VINEO_REGISTER_USERINFODATA,
    VINEO_CHECKUSERNAME_AVAILABLE,
    VINEO_DELETE_SELECTEDUSER_USERDATA,
    VINEO_UPDATE_SELECTEDUSER_USERDATA,
    VINEO_USER_CHECKMOBILENUMBER_AVAILABLE,
    BASEURL_JWTAUTH,
    VINEO_REGISTER_USERMONTHLYTARGET
} from "../../../constants/Urlforvineo";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { getVineoUsers } from "../../../redux/features/services/serviceHelper";
import AuthUser from "../../../constants/AuthUser";
import http from "../../../constants/AxiosConfig";

export default function MonthlyTarget() {
    const monthMap = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
  
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [dataofvineoUsers, setDataofVineoUsers] = useState([]);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [monthlyTargets, setMonthlyTargets] = useState(() =>
    Array(12).fill({
      month: "",
      targetValue: "",
    }).map((_, index) => ({
      month: Object.keys(monthMap)[index],
      targetValue: "",
    }))
  );
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);
  
    const vineoUsers = useSelector((state) => state.vineoData.vineouserswithmotherclient);
    const refreshcount = useSelector((state) => state.vineoData.refreshcount);
    const dispatch = useDispatch();
  
    useEffect(() => {
      console.log("Component Mounted");
      setDataofVineoUsers(vineoUsers);
    }, [refreshcount, vineoUsers]);
  
    const onSubmit = (data) => {
      const transformedMonthlyTargets = monthlyTargets.map((monthData, index) => ({
        id: 0, // You may set this ID as needed
        month: monthMap[monthData.month],
        targetValue: parseFloat(monthData.targetValue), // Parse targetValue as float
        ultimateUser: {
          ultimateUserUniqueid: selectedRow?.ultimateUserUniqueid,
        },
        year: parseInt(data.year),
      }));
  
      console.log(transformedMonthlyTargets);
      submitForm(transformedMonthlyTargets);
    };
  
    const handleMonthTargetChange = (index, value) => {
      const updatedTargets = [...monthlyTargets];
      updatedTargets[index].targetValue = value;
      setMonthlyTargets(updatedTargets);
    };
  
    const updateDatainForm = () => {
      console.log(selectedRow);
      setIsEditDataSelected(true);
      // You can set other form values here using setValue
    };
  
    const getdatafromserver = () => {
      console.log("Called to get User state ");
      dispatch(getVineoUsers());
    };
  
    const rowClickedfunction = (data, row) => {
      return <span onClick={() => console.log(row)}>{data}</span>;
    };
  
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: 'nameofUser',
        text: 'Name',
        sort: true,
        formatter: rowClickedfunction,
      },
      {
        dataField: 'username',
        text: 'UserName',
        sort: true,
      },
      {
        dataField: 'role',
        text: 'Role',
        sort: true,
      },
      {
        dataField: 'userCode',
        text: 'Code',
        sort: true,
      },
      {
        dataField: 'pancardnumber',
        text: 'PAN',
        sort: true,
      },
      {
        dataField: 'FirmName',
        text: 'Firm Name',
        sort: true,
        formatter: (cell, row) => row.vineoRegisteredClientDetails.clientfirmname,
      },
      {
        dataField: 'mobilenumber',
        text: 'Mob/Tel',
      },
    ];
  
    const options = {
      paginationSize: 1,
      pageStartIndex: 1,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '50',
          value: 50,
        },
      ],
    };
  
    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      bgColor: '#0D6EFD',
      classes: (row, rowIndex) => {
        setIsRowSelected(false);
        setSelectedRow(row);
      },
    };
  
    const submitForm = (requestdata) => {
      console.log(JSON.stringify(requestdata));
  
      http
        .post(BASEURL_JWTAUTH + VINEO_REGISTER_USERMONTHLYTARGET, requestdata)
        .then(
          (res) => {
            console.log(res);
            if (res.data.errorCode === "0000") {
              notify('Target Updated  SUCCESSFULLY');
              setSelectedRow({});
              setIsRowSelected(false);
              reset();
              // try to reduce server hit by removing array from here only
            } else {
              notifyError("Error While Operation Try Again  Or Login as ADMIN");
            }
          },
          (err) => {
            console.log(err);
            notifyError("Error While Operation  try with another username or Login as ADMIN");
          }
        );
    };
  
    const monthOptions = Object.keys(monthMap).map((month) => (
      <option key={month} value={month}>
        {month}
      </option>
    ));
  
   
  
    return (
      <div>
        <h3 className='justify-content-center m-1'>Add User</h3>
        <div className="row justify-content-left">
          <Row>
            <Col md={4}>
              <ToolkitProvider
                keyField='username'
                data={dataofvineoUsers ? dataofvineoUsers : []}
                columns={columns}
                search
              >
                {(props) => (
                  <div>
                    <div>
                      <Button variant="primary" onClick={getdatafromserver}>
                        <FontAwesomeIcon icon={faRotate} />
                      </Button>
                      <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}>
                        <FontAwesomeIcon icon={faUserEdit} />
                      </Button>
                      <SearchBar className="m-1" {...props.searchProps} />
                    </div>
                    <BootstrapTable
                      wrapperClasses="table-responsive masterform-custom-table-class"
                      rowClasses="table-striped masterform-custom-row-class"
                      bootstrap4
                      className="p-2"
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      selectRow={selectRow}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
            <Col md={8}>
              <div>
                <Card className="p-3">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Form.Text className="text-muted">
                              <h3> Name Of User - {selectedRow?.nameofUser}</h3>
                            </Form.Text>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Form.Text>Firm Name - {selectedRow?.vineoRegisteredClientDetails?.clientfirmname}</Form.Text>
                          </FormGroup>
                          <Form.Text className="text-muted">
                            Unique UserID - {selectedRow?.ultimateUserUniqueid}
                          </Form.Text>
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Form.Text className="text-muted">
                            Mobile Number - {selectedRow?.mobilenumber}
                          </Form.Text>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Form.Text className="text-muted">
                            vineoRegisteredClientDetails - {selectedRow?.vineoRegisteredClientDetails?.vineoRegisteredClientName}
                          </Form.Text>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group>
                        <Form.Text className="text-muted">Year</Form.Text>
                        <Form.Control
                          as="select"
                          className="form-control custom-form-field"
                          {...register('year', { required: true })}
                        >
                          <option value="">Select Year</option>
                          {[2023, 2024].map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </Form.Control>
                        {errors.year && <span className="text-danger">Year is required</span>}
                      </Form.Group>
                      <Row>
                        {monthlyTargets.map((monthData, index) => (
                          <Row key={index}>
                            <Col md={2}>
                              <Form.Text className="text-muted">
                                Month: {monthData.month}
                              </Form.Text>
                            </Col>
                            <Col md={10}>
                              <FormGroup>
                                <Form.Control
                                  className="form-control custom-form-field"
                                  value={monthData.targetValue}
                                  onChange={(e) => handleMonthTargetChange(index, e.target.value)}
                                  required
                                  min="0"
                                  placeholder={`Enter target for ${monthData.month}`}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        ))}
                      </Row>
                    </Row>
                    {!isEditDataSelected ? (
                      <input className="btn btn-primary m-2" type="submit" value="Add User" />
                    ) : (
                      <input className="btn btn-primary m-2" type="submit" value="Update User" />
                    )}
                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary">
                      <FontAwesomeIcon icon={faRotate} /> Reset
                    </button>
                  </form>
                </Card>
                ~
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }