import { useState } from "react";
import '../../sb-admin-2.min.css'
import AuthUser from "../../constants/AuthUser";
import SliderForLoginPage from "./SliderForLoginPage";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  BASEURL_JWTAUTH,
  FORGOT_PASSWORD_TOKEN,
  GET_JWT_TOKEN,
  HEALTHCHECK,
} from "../../constants/Urlforvineo";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import backgroundImage from "../../images/dffrontpagebanner1.jpg";
import { FormGroup, Label, Input, Button, Form, Modal } from "react-bootstrap";
import { getVineoFirmData } from "../../redux/features/services/serviceHelper";
import { Link } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";

export default function Login() {
  const { http, setToken } = AuthUser();
  const [openforgotpassword, setOpenforgotpassword] = useState(false);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  // const { register, handleSubmit } = useForm();
  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const notifyWarning = (message) => toast.warning(message);
  
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      vineoRegisteredClientName: "",
      password: "",
      username: "",
      //   isDeveloper: true
    },
  });

  const onSubmit = (data) => {
    submitForm(data);
  };
  const toggleForgotPasswordModal=()=>{
    setOpenforgotpassword(false);
  }

  // const testserverdeployment = () => {
  //   axios.get(BASEURL_JWTAUTH + HEALTHCHECK).then(
  //     (res) => {
  //       console.log(res.data);
  //       notify(res.data);
  //     },
  //     (errr) => {
  //       console.log(errr);
  //       notifyError("Error During login & Error code is - " + errr);
  //     }
  //   );
  // };
  const submitForm = (data) => {
    axios.post(BASEURL_JWTAUTH + GET_JWT_TOKEN, data).then(
      (res) => {
        // console.log(res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName)
        setToken(
          res.data.username,
          res.data.token,
          res.data.email,
          res.data.uniqueid,
          res.data.role,
          res.data.lastLoginTS,
          res.data.vineoRegisteredClientDetails.vineoRegisteredClientName,
          res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName
        );
        notify("Login Success ");
        setTimeout(() => {
          window.location.reload(true);
          console.log("Refreshing Browser");
        }, 100);

        dispatch(getVineoFirmData());

      },
      (errr) => {
        console.log(errr);
        notifyError("Error During login & Error code is:- Bad Credentials");
      }
    );
  };
  const styles = {
    backgroundImage: `url(${backgroundImage})`,
    height: "88vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",


  };
  const forgotPassword = (data) => {
  
    if (!data || (data?.length < 4)) {
      notifyError("Enter Your Username In Username Field ")
    }
    else{
     
      axios.post(BASEURL_JWTAUTH + FORGOT_PASSWORD_TOKEN + "/"+ data).then(
        (res) => {
          // console.log(res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName)
          console.log(res);
          console.log(res?.data?.data?.allowed);
          (res?.data?.errorCode ==="0000")?
            notify("Password sent to Registered Phone Number ")
         :notifyError("Error During Password Reset pls connect with us on 080-62182173")
          
        },
        (errr) => {
          console.log(errr);
          notifyError("Error During Reset Password Pls connect on 080-62182173");
        }
      );
    }
  }

  return (
    <div>
      {/* <a className="Nav-brand-logo justify-content-end " href="www.google.com">

Instagram

</a> */}


      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image" style={styles}></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <img className=" mb-2" width={60} src="img/undraw_rocket.svg" alt="..." /></div>
                      <div className="text-center m-4">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <FormGroup className="mt-1">
                                                <Form.Control
                                                    className="form-control form-control-user"
                                                    placeholder="Enter Registered Client ID "
                                                    {...register("vineoRegisteredClientName")}
                                                    required
                                                />
                                            </FormGroup> */}
                        <FormGroup className="mt-2">
                          <Form.Control
                            className="form-control form-control-user"
                            placeholder="Enter Your Username"
                            {...register("username")}
                            required
                          />
                        </FormGroup>

                        <FormGroup className="mt-2">
                          <Form.Control
                            className="form-control form-control-user"
                            type="password"
                            placeholder="Enter Your Password"
                            {...register("password")}
                            required
                          />
                        </FormGroup>

                        <FormGroup className="mt-2 text-center">
                          <input className="btn btn-primary btn-user btn-block" type="submit" name="Login" />
                        </FormGroup>

                        {watch("username").includes("2222") ? (
                          <FormGroup className="mt-3">
                            <Form.Control
                              className="form-control"
                              placeholder="Enter Your TOPT"
                              {...register("usertotp")}
                            />
                          </FormGroup>
                        ) : (
                          <></>
                        )}


                        <FormGroup className="mt-2 text-center">
                          {/* "Forgot Password" button */}
                          <Button variant="link" onClick={() => setOpenforgotpassword(true)}>
                            Forgot Password?
                          </Button>
                        </FormGroup>

                        <FormGroup className="text-center">
                          <br />
                          <img src="bannerlogin.jpg" width={300} alt="logo" />
                        </FormGroup>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={openforgotpassword}
                onHide={() => { setOpenforgotpassword(!openforgotpassword) }}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ForgotPassword   closeModal={() => setOpenforgotpassword(false)}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>


      </div>

      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Powered By Vishnu Provision Stores</span>
          </div>
        </div>
      </footer>


    </div>
  );
}