import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../../constants/AuthUser';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getVineoCompanyData } from '../../../redux/features/services/serviceHelper';
import { getVineoGodownData, getVineoAccountCustomerMasterMenu, getVineoProductData, getVineoSchemaMasterData } from '../../../redux/features/services/serviceHelper';
import { useSelector, useDispatch } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';

import { text } from '@fortawesome/fontawesome-svg-core';

import { DATEFORMAT } from '../../../constants/Urlforvineo';



export default function AddCountersale() {
    useEffect(() => {
        dispatch(getVineoCompanyData());
        dispatch(getVineoGodownData());
        dispatch(getVineoAccountCustomerMasterMenu());
        dispatch(getVineoProductData());
    }, []);
    const dispatch = useDispatch()
    const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    const itemCodeloaded = useSelector((state) => state.vineoData.vineoproductdata)
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = useState([])

    const [billdate, setBilldate] = useState(new Date())
    const [billduedate, setBillduedate] = useState(new Date())


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    // const count = useSelector((state) => state.vineoData.value)

    const vineooldGodowndata = useSelector((state) => state.vineoData.vineogodowndata)
    const vineooldCompnaydata = useSelector((state) => state.vineoData.vineocompany)
    const vineoPartydata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSchemeUniqeId": "Working", "vineoCompanySchemeNumber": "Click Add To Load Scheme" }])
    const isvineoschemamasterdataLoaded = useSelector((state) => state.vineoData.isvineoschemamasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoschemamasterdata )
    // const [formDetails, setformDetails] = useState({});
    // useEffect(() => {
    //     setformDetails({ ...formDetails, gender: "F", enabled: "true", accountLocked: "false", role: "admin" })
    //     console.log(formDetails)
    // }, []);
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

   
    const columns = [

        {
             dataField: 'vineoCompanySchemeNumber',
            text: 'Scheme No',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'vineoSchemeschemeType',
            text: 'Scheme Type',
            sort: true
        }, {
            dataField: 'vineoSchemeName',
            text: 'Scheme name',
            sort: true
        },{
            dataField: 'vineoSchemeactive',
            text: 'Active',
            sort: true
        },{
            dataField:'ProductCost',
            text: 'Product Cost',
            sort: true
        },{
            dataField:'FreeProduct',
            text: 'Free Product',
            sort: true
        },{
            dataField:'ProductCost',
            text: 'Qty',
            sort: true
        },{
            dataField:'ProductCost',
            text: 'Batch',
            sort: true
        
        
        }];


    const options = {
        paginationSize: false,
        pageStartIndex: false,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '1', value: 1
        }, {
            text: '1', value: 1
        },]
    };

    const getdatafromserver = () => {

        console.log("Called to get Scheme Data ")

        dispatch(getVineoSchemaMasterData())

        if ( Object.keys(vineodata).length > 0) {
            isvineoschemamasterdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoSchemeUniqeId": "Working", "vineoSchemeName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }



    const submitForm = (data) => {
        // api call
        http.post('/salesman/billingManager/admin', data).then(
            (res) => {
                if (res.data.id) {
                    notify('User Created with Internal Id')
                    navigate('/login')
                }
                else
                    notify("Error While registering Firm Info From Server ")
                //  console.log(res.data)

            },
            (err) => {


                notify("Error While Registering Firm ")
            }
        )
    }


    return (
        <div>

            <div>
                <div className="p-2 mb-2 bg-primary text-white">
                    <center>
                        <h5 className='justify-content-center m-1'>Add Counter Sales Billing </h5>
                    </center>
                </div>
                <Row>

                    <div>
                        <Card className='p-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md={3}>
                                        <Form.Text>
                                            Party Name
                                        </Form.Text>
                                        <Typeahead
                                            id="PartyNameTypehead"
                                            onChange={setSelected}
                                            onClick={console.log(selected[0])}
                                            options={vineoPartydata}
                                            placeholder="Choose Party Name..."
                                            selected={selected}
                                            labelKey={"vineoCustomerAccountName"}
                                        />
                                        {/*                                         
                                        <Form.Control
                                            as="select"
                                            onChange={e => {
                                                console.log("partyName", e.target.value);
                                                setValue("vineoaccountcustomerdata.vineoCustomerAccountName", e.target.value);
                                            }}
                                        >
                                            {
                                                vineoPartydata.map((vineoPartydata) =>
                                                    <option key={vineoPartydata.ultiVineoCustomerAccountUniqeId} value={vineoPartydata.ultiVineoCustomerAccountUniqeId}>{vineoPartydata.vineoCustomerAccountName}</option>
                                                )
                                            }

                                        </Form.Control> */}

                                    </Col>
                                    <Col md={2}>
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Company Name
                                            </Form.Text>

                                            <Form.Control
                                                as="select"
                                                validated="true"
                                                onChange={e => {
                                                    console.log("company", e.target.value);
                                                    setValue("vineoCompany.ultiVineoCompanyUniqeId", e.target.value);
                                                }}
                                            >
                                                {
                                                    selected[0]?.vineocompany?.vineoCompanies?.map((data) =>
                                                        <option key={data.ultiVineoCompanyUniqeId} value={data}>{data.companyName}</option>
                                                    )
                                                }
                                            </Form.Control>
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Form.Text >
                                                Godown
                                            </Form.Text>
                                            <Form.Control
                                                as="select"
                                                validated="true"
                                                onChange={e => {
                                                    console.log("godown", e.target.value);
                                                    setValue("vineogodowndata.ultiVineoGoDownUniqeId", e.target.value);
                                                }}
                                            >
                                                {
                                                    vineooldGodowndata.map((vineooldGodowndata) =>
                                                        <option key={vineooldGodowndata.ultiVineoGoDownUniqeId} value={vineooldGodowndata.ultiVineoGoDownUniqeId}>{vineooldGodowndata.vineoGoDownName}</option>
                                                    )
                                                }
                                            </Form.Control>
                                        </FormGroup>
                                    </Col>


                                    {/* 
                                    <Col md={1}>
                                        <FormGroup>
                                            <Form.Text>
                                                Party No
                                            </Form.Text>
                                            <fieldset disabled>
                                                <FormGroup >
                                                    <Form.Control className="form-control"  {...register("vineoaccountcustomerdata.ultiVineoCustomerAccountUniqeId")} required />

                                                </FormGroup>
                                            </fieldset>
                                        </FormGroup>
                                    </Col>
                                     */}
                                    <Col md={2}>
                                        <FormGroup>
                                            <Form.Text >
                                                Bill  Date
                                            </Form.Text>

                                            <DatePicker className="form-control"
                                                selected={billdate}
                                                onChange={setBilldate}
                                                name="vineoBillDate"
                                                dateFormat="dd-MM-yyyy"

                                            />
                                               {setValue("vineoBilldate", moment(billdate).format(DATEFORMAT))}
                                        </FormGroup>
                                    </Col>

                                    <Col md={2}>
                                        <Form.Text>
                                            Bill Type
                                        </Form.Text>
                                        <Form.Select className="form-control" >
                                            <option value="comp">Credit</option>
                                            <option value="comp1">Cash</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <Form.Text>
                                            salesman
                                        </Form.Text>
                                        <Form.Control
                                            as="select"
                                            validated="true"
                                            onChange={e => {
                                                console.log("Salesman", e.target.value);
                                                setValue("selected[0].vineoArea.salesman.ultimateUserUniqueid", e.target.value);
                                            }}
                                        >
                                            {
                                                <option key={selected[0]?.vineoArea?.salesman?.ultimateUserUniqueid} value={selected[0]?.vineoArea?.salesman}>{selected[0]?.vineoArea?.salesman?.nameofUser}</option>
                                            }

                                        </Form.Control>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Area
                                        </Form.Text>
                                        <Form.Control
                                            as="select"
                                            validated="true"

                                            onChange={e => {
                                                console.log("Area", e.target.value);
                                                setValue("selected[0].vineoArea.ultiVineoAreaUniqeId", e.target.value);
                                            }}
                                        >
                                            {
                                                <option key={selected[0]?.vineoArea?.ultiVineoAreaUniqeId} value={selected[0]?.vineoArea}>{selected[0]?.vineoArea?.vineoAreaName}</option>

                                            }
                                        </Form.Control>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Bill No
                                        </Form.Text>
                                        <Form.Control type="text" />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Due Date
                                        </Form.Text>
                                      
                                        <DatePicker className="form-control"
                                                selected={billduedate}
                                                onChange={setBillduedate}
                                                name="vineoBillDate"
                                                dateFormat="dd-MM-yyyy"

                                            />
                                               {setValue("vineoBillduedate", moment(billduedate).format(DATEFORMAT))}
                                    </Col>
                                    <Col md={3}>
                                        <Form.Text>
                                            Narr
                                        </Form.Text>
                                        <Form.Control type="text" />
                                    </Col>
                                </Row>
                            </form>
                        </Card>
                    </div>
                    <div>
                        <Card className='p-4'>

                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">SrNo</th>
                                        <th scope="col">Trn</th>
                                        <th scope="col">Item Name || Item Code</th>
                                        <th scope="col">Unit</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Free</th>
                                        <th scope="col">MRP</th>
                                        <th scope="col">Rate</th>
                                        <th scope="col">Rate GST</th>
                                        <th scope="col">Net Ann</th>
                                        <th scope="col">Tpr/Ur</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Sale</td>
                                        <td>
                                            <Form.Control
                                                as="select"
                                                validated="true"
                                                onChange={e => {
                                                    console.log("itemCode", e.target.value);
                                                    setValue("vineoproductdata.ultiVineoProductUniqeId", e.target.value);
                                                }}
                                            >
                                                return(
                                                <>
                                                    {(typeof itemCodeloaded != 'undefined') ? (
                                                        itemCodeloaded.map((itemCodeloaded) =>
                                                            <option key={itemCodeloaded.ultiVineoProductUniqeId} value={itemCodeloaded.ultiVineoProductUniqeId}>
                                                                {itemCodeloaded.vineoProductName}
                                                                || {itemCodeloaded.ultiVineoProductUniqeId}
                                                            </option>
                                                        )
                                                    ) : (
                                                        getVineoProductData()
                                                    )}


                                                </>
                                                )

                                            </Form.Control>
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tbody>
                            </table>

                        </Card>
                    </div>

                   <div>
                   
                   <Col>
                   <ToolkitProvider keyField='ultiVineoSchemeUniqeId'
                       data={dataofvineoTableData}
                       columns={columns}
                       search
                   >
                       {
                           props => (
                               <div>
                                   <div >
                                   <center> Scheme Table
                                       <Button variant="primary"
                                           onClick={getdatafromserver} >
                                           <FontAwesomeIcon icon={faRotate} />
                                       </Button>
                                      </center>
                                   </div>
                                       <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                       {...props.baseProps}
                                       pagination={paginationFactory(options)}
                                      
                                   // rowEvents={ rowEvents }
                                   />

                               </div>
                           )
                       }
                   </ToolkitProvider>

               </Col>
                   </div>

                </Row>
                <center>
                    <div>
                        <Row>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Net Amt
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("grossAmt")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Star Disc
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("starDisc")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        %
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("per")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        GST Amt
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("gstAmt")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Ret Surch
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("retSurch")} required />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text >
                                        Display
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("display")} required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        TPR Amt
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("tprAmt")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Cash Disc
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("cashDisc")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        %
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("per")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Cess Amt
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("cessAmt")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Ret Sch Disc
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("retSchDisc")} required />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text >
                                        Coupon
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("coupon")} required />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Scheme Amt
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("schemeAmt")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Add Other
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("addOther")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        %
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("per")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Sales Return
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("salesReturn")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Ret Cd Disc
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("retCdDisc")} required />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text >
                                        Add/Less
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("addLess")} required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Bottom Amt
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("bottomAmt")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Less Other
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("lessOther")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        %
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("per")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        Return GST
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("returnGst")} required />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text >
                                        TCS Amt
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("tcsAmt")} required />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text >
                                        Rounding
                                    </Form.Text>
                                    <Form.Control size='sm' className="form-control" placeholder='0.00' {...register("rounding")} required />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </center>
            </div>
            <center>
                <input className="btn btn-primary m-2" type="submit" value="Submit" />
                <input className="btn btn-primary m-2" type="button" value="Save & Print" />
                <Button onClick={handleClose}>Cancel</Button>
            </center>
        </div>
    )

}