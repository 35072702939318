import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux'
import { BASEURL_JWTAUTH,  VINEO_GETALLCOMPANY_AVAILABLE, VINEOFORM_UPDATE_SELECTEDCOMPANYODATA, VINEOFORM_DELETE_SELECTEDCOMPANYODATA } from '../../../constants/Urlforvineo';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { faClose, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";


import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, Card, ListGroupItem
} from 'react-bootstrap';
import { getVineoCompanyData } from '../../../redux/features/services/serviceHelper';


export default function CompanyMaster() {

    const dispatch = useDispatch()

    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [dataofvineoCompany, setDataofvineoCompany] = useState([{ "ultiVineoCompanyUniqeId":"Working","companyName": "Click Refresh To Load" }])
    const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    const vineocompany = useSelector((state) => state.vineoData.vineocompany)
    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const {  setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)


    useEffect(() => {
        dispatch(getVineoCompanyData());
    }, []);

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'companyName',
            text: 'Company Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'companyHeadOfficeAddress',
            text: 'Comp.HO',
            sort: true
        }, {
            dataField: 'companySupplierCode',
            text: 'Supp.Code',
            sort: true
        },
        {
            dataField: 'companyArea',
            text: 'Area',
            sort: true,

        }
    ];


    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '15', value: 15
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };


    //Table for firm END 

    const getdatafromserver = () => {

        console.log("Called to get Company Data state ")
        dispatch(getVineoCompanyData());
        if(typeof vineocompany != 'undefined' && Object.keys(vineocompany).length > 0)
        {
            isvineocompanyLoaded ? setDataofvineoCompany(vineocompany) : setDataofvineoCompany([{ "ultiVineoCompanyUniqeId":"Working","companyName": "Click Refresh To Load" }])

        }
        console.log("Empty Data Received form Server")
        // setDataofvineoCompany(vineocompany)
       


    }

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoCompanyUniqeId", selectedRow.ultiVineoCompanyUniqeId);
            setValue("companyName", selectedRow.companyName);
            setValue("vineoCompanyCode", selectedRow.vineoCompanyCode);
            setValue("companyArea", selectedRow.companyArea);
            setValue("companyCapital", selectedRow.companyCapital);
            setValue("companyDepoAddress", selectedRow.companyDepoAddress);
            setValue("companyExpencesPerMonth", selectedRow.companyExpencesPerMonth);
            setValue("companyHeadOfficeAddress", selectedRow.companyHeadOfficeAddress);
            setValue("companyExpencesPerMonth", selectedRow.companyExpencesPerMonth);
            setValue("companyNoOfArea", selectedRow.companyNoOfArea);
            setValue("companyNoOfEmployee", selectedRow.companyNoOfEmployee);
            setValue("companyNoOfVehicle", selectedRow.companyNoOfVehicle);
            setValue("companyStkCode", selectedRow.companyStkCode);
            setValue("companySupplierCode", selectedRow.companySupplierCode);
            setValue("companybranch0ffAdd", selectedRow.companybranch0ffAdd);

            // setValue("ultiVineoCategoryUniqeId", selectedRow.ultiVineoCategoryUniqeId);
            // setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
          
        }
        console.log("Update Company ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
      {
            var answer = window.confirm("Do You want to Delete Selected Category ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTEDCOMPANYODATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Sucess:- COMPANY  DELETED SUCCESSFULLY with NAME is = ' + requestdata.companyName )
                 
                    
                }
                else
                    notify("Error While Deleting COMPANY (it May Have Associated Sub Category) Delete Sub Cat First Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting CATEGORY Try Again  Or Login as ADMIN")

            }
        )
    }
    const submitForm = (data) => {

        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTEDCOMPANYODATA, data).then(
            (res) => {
                if (res.data.errorCode == "0000") {
                    notify('Success:- Company Created  with Internate Id')
                    console.log(res.data)
                    reset()
                }
                else
                    notify('Error while Registering CompanyData')
                console.log(res.data)

            },
            (err) => {
                notify("Error While Registering CompanyMaster")
            }
        )
    }



    return (
        <div>

            <h3 className='justify-content-center m-1'>Add Company Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoCompanyUniqeId'
                            data={dataofvineoCompany}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>



                    </Col>

                    <Col md={8}>
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    
                                    <FormGroup >

                                    <Form.Text className="text-muted">
                                      Company Code
                                    </Form.Text>
                                    <Form.Control className="form-control " size='sm'   {...register("vineoCompanyCode")} required />

                                </FormGroup>
                                    <FormGroup>
                                        <Form.Text>Company Name</Form.Text>
                                        <Form.Control className="form-control "  size='sm'   {...register("companyName")} required />

                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Text>Head Office Address</Form.Text>
                                        <Form.Control className="form-control "   size='sm'   {...register("companyHeadOfficeAddress")}  />

                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Text>Branch Office Address</Form.Text>
                                        <Form.Control className="form-control "  size='sm'     {...register("companybranch0ffAdd")}  />

                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Text>Depo Address</Form.Text>
                                        <Form.Control className="form-control "  size='sm'    {...register("companyDepoAddress")}  />

                                    </FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>Area in Sq.Mtr.</Form.Text>
                                                <Form.Control className="form-control "   size='sm'    {...register("companyArea")}  />

                                            </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>No Of Employees.</Form.Text>
                                                <Form.Control className="form-control "  size='sm'   {...register("companyNoOfEmployee")}  />

                                            </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>No Of Area</Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("companyNoOfArea")}  />

                                            </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>Supplier Code</Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("companySupplierCode")}  />

                                            </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>Stockist Code</Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("companyStkCode")}  />

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>Investment Rs In Lack</Form.Text>
                                                <Form.Control className="form-control "  size='sm'   {...register("companyCapital")}  />

                                            </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>No Of Vehicle</Form.Text>
                                                <Form.Control className="form-control "  size='sm'   {...register("companyNoOfVehicle")}  />

                                            </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Form.Text>Expences per Month(Rs.)</Form.Text>
                                                <Form.Control className="form-control " size='sm'   {...register("companyExpencesPerMonth")}  />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <center>
                                    {!isEditDataSelected ?
                                    <input className="btn btn-primary m-2" type="submit" value="Add CompanyInfo " /> 
                                   :<input className="btn btn-primary m-2" type="submit" value="Update CompanyInfo" /> }
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                    <input className='btn btn-primary m-2' type="button" value="Synch Data" />
                                    </center>
                                </form>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )

}
