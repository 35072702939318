import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getVineoServiceAccountData } from '../../../redux/features/services/serviceHelper';
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_SERVICEACCOUNT_NAMEDDATA, VINEOFORM_UPDATE_SELECTED_SERVICEACCOUNT_NAMEDATA } from '../../../constants/Urlforvineo';

export default function ServiceMaster() {

    
    // useEffect(() => {
    //     dispatch(getvineoSe());
    // }, []);
    const dispatch = useDispatch()
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{"ultiVineoServiceAccountUniqeId":"Working","vineoServiceAccountName":"Click Refresh To Load"}])
    const isvineoserviceaccountdataLoaded = useSelector((state) => state.vineoData.isvineoserviceaccountdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoserviceaccountdata)
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoServiceAccountName',
            text: 'Service Account Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'vineoServiceAccountGSTPercentage',
            text: 'GST %',
            sort: true
        }, {
            dataField: 'vineoServiceAccountHSNCode',
            text: 'HSN Code',
            sort: true,
        },
    
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };
    const getdatafromserver = () => {

        console.log("Called in to get Service Account Data")
        dispatch(getVineoServiceAccountData())
        if(Object.keys(vineodata).length > 0)
        {
            isvineoserviceaccountdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{"ultiVineoServiceAccountUniqeId":"Working","vineoServiceAccountName":"Click Again Refresh To Load"}])
       
        }
        console.log("Empty Data Received form Server")
    }


 const updateDatainForm = () => {
    {
        console.log(selectedRow);
        setIsEditDataSelected(true);
        setValue("ultiVineoServiceAccountUniqeId", selectedRow.ultiVineoServiceAccountUniqeId);
        setValue("vineoServiceAccountName", selectedRow.vineoServiceAccountName);
        setValue("vineoServiceAccountGSTPercentage",selectedRow.vineoServiceAccountGSTPercentage);
        setValue("vineoServiceAccountHSNCode", selectedRow.vineoServiceAccountHSNCode);
        setValue("vineoServiceAccountDescription",selectedRow.vineoServiceAccountDescription);
         setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
  
       
    }
    console.log("Update Service Account ")
 }

 const deleteDatainForm = (requestdata) => {
    console.log(selectedRow);
    {
          var answer = window.confirm("Do You want to Delete Selected Service Account ");
          if (answer) {
              submitFormForDeleteSelectedRow(selectedRow);
          }
          else {
              notify("Delete Operation Canceled ")
          }
      }
 }

 const submitFormForDeleteSelectedRow = (requestdata) => {
    console.log(requestdata)
    http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_SERVICEACCOUNT_NAMEDDATA, requestdata).then(
        (res) => {
            console.log(res)
            if (res.data.errorCode == "0000") {
                notify('Success:- Area  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoServiceAccountName )
            }
            else
       {     notifyError("Error While Deleting FIRM  Or Login as ADMIN")}
        },
        (err) => {
            notifyError("Error While Deleting FIRM Try Again  Or Login as ADMIN")

        }
    )
}

    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_SERVICEACCOUNT_NAMEDATA , data).then(
            (res)=>{
                console.log(res)
                if(res.data.errorCode== "0000"){
                notify('Success:- Service Account  Created With Internate Id')
                reset()
            }
            else
           { notifyError('Error While Registering Service Account Info From Server')}
            //console.log(res.data)
        },
        (err)=>{
            notifyError('Error While Registering Service Account Info')
        }
        )
    }


    return (
        <div>
           
            <h3 className='justify-content-center m-1'>Add Service Information </h3>
            <div className="row justify-content-left ">
            <Row>
            <Col md={4}>
                <ToolkitProvider keyField='ultiVineoServiceAccountUniqeId'
                    data={dataofvineoTableData}
                    columns={columns}
                    search   >
                    {
                        props => (
                            <div>
                                <div >
                                    <Button variant="primary"
                                        onClick={getdatafromserver} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                    <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                    <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                    <SearchBar className="m-1" {...props.searchProps} />
                                </div>
                                    <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                    {...props.baseProps}
                                    pagination={paginationFactory(options)}
                                    selectRow={selectRow}
                                // rowEvents={ rowEvents }
                                />

                            </div>
                        )
                    }
                </ToolkitProvider>

            </Col>
                    <Col md={8}>
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >
                                        <fieldset disabled>

                                            <Form.Control className="form-control"
                                                placeholder="Service ID: This Service Code Will Be Auto Generated"
                                                {...register("ultiVineoServiceAccountUniqeId")} />
                                        </fieldset>
                                    </FormGroup>
                                    <FormGroup >
                                    <Form.Text className="text-muted">
                                        Service Code
                                    </Form.Text>
                                    <Form.Control  className="form-control"   {...register("vineoServiceAccountCode")} required />

                                </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            Service Name
                                        </Form.Text>
                                        <Form.Control className="form-control"   {...register("vineoServiceAccountName")} required />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            GST %
                                        </Form.Text>
                                        <Form.Control className="form-control" {...register("vineoServiceAccountGSTPercentage")} required />

                                    </FormGroup>
                                    <Row>
                                        <Col>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    HSN Code
                                                </Form.Text>
                                                <Form.Control className="form-control" {...register("vineoServiceAccountHSNCode")} required />

                                            </FormGroup>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Description
                                                </Form.Text>
                                                <Form.Control className="form-control"  {...register("vineoServiceAccountDescription")} required />

                                            </FormGroup>


                                        </Col>

                                    </Row>

                                    <input className="btn btn-primary m-2" type="submit" value="Save " />
                                    <input className="btn btn-primary m-2" type="Cancel" value="Cancel " />


                                </form>
                            </Card>
                        </div>


                    </Col>
                </Row>

            </div>
        </div>
    )

}