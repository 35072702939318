import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import { addMonths } from 'date-fns';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddBillData, getVineoAddBillDatawithdate, getVineoShopVisitdatawithdate, getVineoShopVisitdatawithdateforMotherclientid } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { getVineoAccountCustomerMasterMenu } from '../../../redux/features/services/serviceHelper';
import Billprint from '../entries/billing/Billprint';
import ShopVisitprint from '../printFormat/ShopVisitprint';
import ShopVisitMap from './ShopVisitMap';
import AuthUser from '../../../constants/AuthUser';

export default function Attendance() {
	const { setValue } = useForm();
	useEffect(() => {
		console.log("Use Effect called ");
	}, []);

	const { token, logout, getUser, getRole } = AuthUser();
	let currentDate = new Date();
	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const vineoShopVisitdata = useSelector((state) => state.vineoData.shopvisitdatawithdate)
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const [fromdate, setfromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
	const [toDate, setToDate] = useState(new Date());
	const [openprint, setOpenprint] = React.useState(false);
	const [dataforprint, setDataforprint] = useState();
	const [tableData, setTableData] = useState();
	const [showmap, setShowmap] = useState(false);
	const [selectedSalesman, setSelectedSalesman] = useState([])
	const [selectedParty, setSelectedParty] = useState([])
	const [satHoliday, setSatHoliday] = useState(false)
	const [sunHoliday, setSunHoliday] = useState(true)
	const [selectedWeeklyOff, setSelectedWeeklyOff] = useState("false");

	const notify = (message) => toast.success(message);
	const notifyError = (message) => toast.error(message)
	const notifyWarning = (message) => toast.warning(message)
	const [isLoading, setLoading] = useState(false);

	const [selectedFirm, setSelectedFirm] = useState([])
	const filteredTableData = (
		selectedParty?.[0]?.label &&
		selectedSalesman?.[0]?.label &&
		selectedFirm?.[0]?.label
	)
		? tableData
			?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
			?.filter(item => item?.partyName === selectedParty?.[0]?.label)
			?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname === selectedFirm?.[0]?.label)
		: (
			selectedParty?.[0]?.label
				? (selectedSalesman?.[0]?.label
					? tableData?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
					: tableData
				)?.filter(item => item?.partyName === selectedParty?.[0]?.label)
				: (selectedSalesman?.[0]?.label
					? tableData?.filter(item => item?.nameofUser === selectedSalesman?.[0]?.label)
					: (selectedFirm?.[0]?.label
						? tableData?.filter(item => item?.vineoRegisteredClientDetails?.clientfirmname === selectedFirm?.[0]?.label)
						: tableData
					)
				)
		);


	useEffect(() => {

		console.log("Refreshing on state Change " + refreshcount)
		setLoading(false)
		setTableData(vineoShopVisitdata)

	}, [refreshcount]);


	useEffect(() => {
		// Initialize "From Date" to the current date
		const currentDate = new Date();
		const firstDayofMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

		setfromdate(firstDayofMonth)

		// Initialize "To Date" to the last day of the current month
		const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
		setToDate(lastDayOfMonth);
	}, []);


	const handleSelectHolidayChange = (event) => {
		const selectedValue = event.target.value;
		setSelectedWeeklyOff(selectedValue);

	};

	const handleFromDateChange = (date) => {
		setfromdate(date);

		if (true) {
			const newToDate = new Date(date);
			newToDate.setMonth(newToDate.getMonth() + 1);
			newToDate.setDate(0); // Set to last day of previous month
			setToDate(newToDate);
		}
	}

	const getdatafromserverwithdate = (reqdata) => {
		const rolename = getRole("");
		console.log("Role is " + rolename)


		if (reqdata.startDate && reqdata.endDate) {

			rolename === 'mothercompanyadmin' ?
				dispatch(getVineoShopVisitdatawithdateforMotherclientid(reqdata)) :
				dispatch(getVineoShopVisitdatawithdate(reqdata))


		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}
	const getAttendanceReport = (reqdata) => {
		const rolename = getRole("");
		console.log("Role is " + rolename)


		if (reqdata.startDate && reqdata.endDate) {

			rolename === 'mothercompanyadmin' ?
				dispatch(getVineoShopVisitdatawithdateforMotherclientid(reqdata)) :
				dispatch(getVineoShopVisitdatawithdate(reqdata))


		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}

	// start of ag grid 

	// Ag grid Work Start 
	const [gridApi, setGridApi] = useState(null)

	const onExportClick = () => {
		gridApi.api.exportDataAsCsv();
	}

	const onGridReady = (params) => {
		setGridApi(params);
	}

	const defaultColDef = {
		sortable: true,
		// editable: true,
		flex: 1,
		filter: true,
		floatingFilter: true,
		resizable: true,
		minWidth: 100,
	}

	const onPaginationChange = (pageSize) => {
		gridApi.api.paginationSetPageSize(Number(pageSize))
	}

	function onCellValueChanged(event) {
		console.log(
			'onCellValueChanged: '
		);
	}

	const columnDefs = [
		{headerName: "SalesmanName", field: "nameofUser", resizable: true},
		{ headerName: "ID", field: "userUniqueid", resizable: true },
		{ headerName: "EOD", field: "eod", resizable: true },
		
		{
			headerName: "WorkingDays",
			valueGetter: function (params) {
				// Calculate the count of working days (P days except weekly off)
				const salesmanName = params.data.nameofUser;
				return dateColumns.reduce((count, column) => {
					const formattedDate = column.field;
					const visitDate = formattedDate;

					const dayOfWeek = moment(visitDate, DATEFORMAT).day();

					if (
						(selectedWeeklyOff === "true-sun" && dayOfWeek === 0) ||
						(selectedWeeklyOff === "true-mon" && dayOfWeek === 1) ||
						(selectedWeeklyOff === "true-tue" && dayOfWeek === 2) ||
						(selectedWeeklyOff === "true-wed" && dayOfWeek === 3) ||
						(selectedWeeklyOff === "true-thu" && dayOfWeek === 4) ||
						(selectedWeeklyOff === "true-fri" && dayOfWeek === 5) ||
						(selectedWeeklyOff === "true-sat" && dayOfWeek === 6)
					) {
						return count; // Skip weekly off days
					} else {
						const visitExists = filteredTableData?.some(
							(item) =>
								item.nameofUser === salesmanName && item.visitDate === visitDate
						);
						return visitExists ? count + 1 : count;
					}
				}, 0);
			}
		},
		{
			headerName: "TotalDays",
			valueGetter: function (params) {
				// Calculate the count of total days
				return dateColumns.length;
			},
		},
		{
			headerName: "Leaves",
			valueGetter: function (params) {
				// Calculate the number of days when the salesman was absent
				const salesmanName = params.data.nameofUser;
				return dateColumns.reduce((count, column) => {
					const formattedDate = column.field;
					const visitDate = formattedDate;

					const dayOfWeek = moment(visitDate, DATEFORMAT).day();

					if (
						(selectedWeeklyOff === "true-sun" && dayOfWeek === 0) ||
						(selectedWeeklyOff === "true-mon" && dayOfWeek === 1) ||
						(selectedWeeklyOff === "true-tue" && dayOfWeek === 2) ||
						(selectedWeeklyOff === "true-wed" && dayOfWeek === 3) ||
						(selectedWeeklyOff === "true-thu" && dayOfWeek === 4) ||
						(selectedWeeklyOff === "true-fri" && dayOfWeek === 5) ||
						(selectedWeeklyOff === "true-sat" && dayOfWeek === 6)
					) {
						return count; // Skip weekly off days
					} else {
						const visitExists = filteredTableData?.some(
							(item) =>
								item.nameofUser === salesmanName && item.visitDate === visitDate
						);
						return visitExists ? count : count + 1; // Count as leave if absent
					}
				}, 0);
			},
		},
		{
			headerName: "Holiday",
			valueGetter: function () {
				// Calculate the count of weekly off days (holidays)
				return dateColumns.reduce((count, column) => {
					const formattedDate = column.field;
					const visitDate = formattedDate;

					const dayOfWeek = moment(visitDate, DATEFORMAT).day();

					if (
						(selectedWeeklyOff === "true-sun" && dayOfWeek === 0) ||
						(selectedWeeklyOff === "true-mon" && dayOfWeek === 1) ||
						(selectedWeeklyOff === "true-tue" && dayOfWeek === 2) ||
						(selectedWeeklyOff === "true-wed" && dayOfWeek === 3) ||
						(selectedWeeklyOff === "true-thu" && dayOfWeek === 4) ||
						(selectedWeeklyOff === "true-fri" && dayOfWeek === 5) ||
						(selectedWeeklyOff === "true-sat" && dayOfWeek === 6)
					) {
						return count + 1; // Count as holiday
					} else {
						return count;
					}
				}, 0);
			},
		},
		{
			headerName: "Total Working Hours",
			valueGetter: function (params) {
				const salesmanName = params.data.nameofUser;
				const shopVisits = filteredTableData?.filter(
					(item) => item.nameofUser === salesmanName
				);

				if (!shopVisits || shopVisits.length === 0) {
					return "0 H"; // No visits recorded
				}

				// Initialize total working hours
				let totalWorkingHours = 0;

				// Group shop visits by date
				const groupedVisits = {};
				shopVisits.forEach((visit) => {
					const visitDate = moment(visit?.shopVisitpunchTime, 'DD-MM-YYYY').format('DD-MM-YYYY');
					if (!groupedVisits[visitDate]) {
						groupedVisits[visitDate] = [];
					}
					groupedVisits[visitDate].push(visit);
				});

				// Calculate working hours for each day and accumulate the total
				Object.keys(groupedVisits).forEach((visitDate) => {
					const visitsForDay = groupedVisits[visitDate];
					if (visitsForDay.length === 1) {
						// Handle the case with only one visit (0.1 hours)
						totalWorkingHours += 0.1;
					} else {
						// Calculate working hours based on the first and last timestamps
						const firstTS = moment(visitsForDay[0]?.shopVisitpunchTime, 'DD-MM-YYYY HH:mm:ss');
						const lastTS = moment(visitsForDay[visitsForDay.length - 1]?.shopVisitpunchTime, 'DD-MM-YYYY HH:mm:ss');
						const workingHoursForDay = lastTS.diff(firstTS, 'hours', true);
						totalWorkingHours += workingHoursForDay;
					}
				});

				return `${totalWorkingHours.toFixed(1)} H`;
			},
		},

		{
			headerName: "Average Working Hours",
			valueGetter: function (params) {
				const salesmanName = params.data.nameofUser;
				const shopVisits = filteredTableData?.filter(
					(item) => item.nameofUser === salesmanName
				);

				if (!shopVisits || shopVisits.length === 0) {
					return "0 H"; // No visits recorded
				}

				// Initialize total working hours and total working days
				let totalWorkingHours = 0;
				let totalWorkingDays = 0;

				// Group shop visits by date
				const groupedVisits = {};
				shopVisits.forEach((visit) => {
					const visitDate = moment(visit?.shopVisitpunchTime, 'DD-MM-YYYY').format('DD-MM-YYYY');
					if (!groupedVisits[visitDate]) {
						groupedVisits[visitDate] = [];
					}
					groupedVisits[visitDate].push(visit);
				});

				// Calculate working hours for each day and accumulate the total
				Object.keys(groupedVisits).forEach((visitDate) => {
					const visitsForDay = groupedVisits[visitDate];
					if (visitsForDay.length === 1) {
						// Handle the case with only one visit (0.1 hours)
						totalWorkingHours += 0.1;
					} else {
						// Calculate working hours based on the first and last timestamps
						const firstTS = moment(visitsForDay[0]?.shopVisitpunchTime, 'DD-MM-YYYY HH:mm:ss');
						const lastTS = moment(visitsForDay[visitsForDay.length - 1]?.shopVisitpunchTime, 'DD-MM-YYYY HH:mm:ss');
						const workingHoursForDay = lastTS.diff(firstTS, 'hours', true);
						totalWorkingHours += workingHoursForDay;
					}
					totalWorkingDays++; // Increment total working days for each day with visits
				});

				if (totalWorkingDays === 0) {
					return "0 H"; // Avoid division by zero
				}

				// Calculate the average working hours
				const averageWorkingHours = totalWorkingHours / totalWorkingDays;

				return `${averageWorkingHours.toFixed(1)} H`;
			},
		},

		...generateDateColumns(fromdate, toDate), // Dynamically generated date columns
		{
			headerName: "Actions",
			field: "id",
			cellRendererFramework: (params) => (
				<div>
					<Button
						variant="warning m-1"
						onClick={() => handleUpdate(params.data)}
					>
						<FontAwesomeIcon icon={faUserEdit} />
					</Button>
					<Button
						variant="warning m-1"
						onClick={() => notify("Print Works in Visit Report Only ")}
					>
						<FontAwesomeIcon icon={faPrint} />
					</Button>
				</div>
			),
		},
	];

	// Function to generate date columns between startDate and endDate
	// Function to generate date columns between startDate and endDate
	function generateDateColumns(startDate, endDate) {
		const columns = [];
		const currentDate = moment(startDate);

		while (currentDate.isSameOrBefore(endDate, "day")) {
			const formattedDate = currentDate.format(DATEFORMAT); // Make sure this format matches your data
			const dayOfWeek = currentDate.day();
			const today = moment(); // Get the current date for each iteration

			if (
				(selectedWeeklyOff === "true-sun" && dayOfWeek === 0) ||
				(selectedWeeklyOff === "true-mon" && dayOfWeek === 1) ||
				(selectedWeeklyOff === "true-tue" && dayOfWeek === 2) ||
				(selectedWeeklyOff === "true-wed" && dayOfWeek === 3) ||
				(selectedWeeklyOff === "true-thu" && dayOfWeek === 4) ||
				(selectedWeeklyOff === "true-fri" && dayOfWeek === 5) ||
				(selectedWeeklyOff === "true-sat" && dayOfWeek === 6)
			) {
				columns.push({
					headerName: formattedDate,
					field: formattedDate,

					valueFormatter: (params) => {
						const salesmanName = params.data.nameofUser;
						const visitDate = formattedDate;
						const visitExists = filteredTableData?.some(
							(item) =>
								item.nameofUser === salesmanName && item.visitDate === visitDate
						);

						return visitExists ? "V" : "WO"; // Mark as "V" if a visit exists, else mark as "WO"
					},
					cellStyle: {
						backgroundColor: 'skyblue', // Set the background color to skyblue
					},
					resizable: true,
				});
			} else if (currentDate.isAfter(today, "day")) {
				columns.push({
					headerName: formattedDate,
					field: formattedDate,
					valueFormatter: () => "", // Empty for future dates
					resizable: true,
				});
			} else {
				columns.push({
					headerName: formattedDate,
					field: formattedDate,
					valueFormatter: (params) => {
						const salesmanName = params.data.nameofUser;
						const visitDate = formattedDate;
						const visitExists = filteredTableData?.some(
							(item) =>
								item.nameofUser === salesmanName && item.visitDate === visitDate
						);

						return visitExists ? "P" : "A"; // Present or Absent
					},

					resizable: true,
				});
			}

			currentDate.add(1, "day");
		}

		return columns;
	}





	const handleUpdate = (data) => {
		console.log(data)
	}

	// Extract unique usernames
	const uniqueUsernames = Array.from(
		new Set(
			filteredTableData
				?.filter((item) => item?.nameofUser !== null)
				.map((item) => item?.nameofUser)
		)
	).map((username) => ({
		username,
		userUniqueid: filteredTableData?.find((item) => item.nameofUser === username)?.userUniqueid,
	}));


	// Call the function to generate the date columns
	const dateColumns = generateDateColumns(fromdate, toDate);


	// Now use the dateColumns to create the agGridData
	const agGridData = uniqueUsernames.map((item) => {
		const rowData = {
			nameofUser: item.username,
			userUniqueid: item.userUniqueid,
		};

		dateColumns.forEach((column) => {
			const formattedDate = column.field;
			const salesmanName = item.username;
			const visitDate = formattedDate;

			const dayOfWeek = moment(visitDate, DATEFORMAT).day();
			const today = moment();

			if (
				(selectedWeeklyOff === "true-sun" && dayOfWeek === 0) ||
				(selectedWeeklyOff === "true-mon" && dayOfWeek === 1) ||
				(selectedWeeklyOff === "true-tue" && dayOfWeek === 2) ||
				(selectedWeeklyOff === "true-wed" && dayOfWeek === 3) ||
				(selectedWeeklyOff === "true-thu" && dayOfWeek === 4) ||
				(selectedWeeklyOff === "true-fri" && dayOfWeek === 5) ||
				(selectedWeeklyOff === "true-sat" && dayOfWeek === 6)
			) {
				const visitExists = filteredTableData?.some(
					(item) =>
						item.nameofUser === salesmanName && item.visitDate === visitDate
				);

				rowData[formattedDate] = visitExists ? "V" : "WO";
			} else if (moment(visitDate, DATEFORMAT).isAfter(today, "day")) {
				rowData[formattedDate] = "";
			} else {
				const visitExists = filteredTableData?.some(
					(item) =>
						item.nameofUser === salesmanName && item.visitDate === visitDate
				);

				rowData[formattedDate] = visitExists ? "P" : "A";
			}
		});

		return rowData;
	});




	const handlePrint = (data) => {
		setDataforprint(data);
		setOpenprint(!openprint)
	}
	const handleDelete = (data) => {
		const confirm = window.confirm("Are you sure, you want to delete this row", data)

		if (confirm) {
			http.post(BASEURL_JWTAUTH + "Add delete URL once deltign is approved ", data).then(

				(res) => {
					console.log(res)
					if (res.data.errorCode == "0000") {
						notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

					}
					else
						notifyError("Error While Deleting Bill  " + + res.data.errorMessage)
				},
				(err) => {
					notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

				}
			)
		}
	}

	return (
		<div className="row justify-content-left ">
			<div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">Attendance Report Based on Site Visit P:Present, A:Absent ,V: Visit</h4>
				</center>
			</div>

			<Row>


				<Col md={1}>
					<FormGroup className="m-2">
						<Form.Text >
							Month
						</Form.Text>
						<DatePicker className="form-control"
							selected={fromdate}
							// onChange={(date) => {setfromdate(date);setMonthSelected(true)}}
							dateFormat="MM/yyyy"
							showMonthYearPicker
							showFullMonthYearPicker
							onChange={handleFromDateChange}


						/>

					</FormGroup>
				</Col>

				{console.log("dates are", moment(fromdate).format(DATEFORMAT), "and ", moment(addMonths(fromdate, 1)).format(DATEFORMAT))}



				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							<div style={{ display: 'flex', alignItems: 'center' }}>
								Visit From Date

								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 1)); console.log("Date selected is ", fromdate)
								}} > -1Day</div>
								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 10))
								}} > -10Day</div>
								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 30))
								}} > -30</div>
							</div>

						</Form.Text>

						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setfromdate}
							name="vineoCreateLoadBillfromdate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillfromdate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text>To Date</Form.Text>
						<DatePicker
							className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
						/>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup className="m-2">
						<Form.Text>SiteVisit Attendance</Form.Text>
						<Button className="btn btn-primary" disabled={isLoading} onClick={() => { setLoading(true); getdatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) }) }}>
							<FontAwesomeIcon icon={faRotate} />
							Load
						</Button>
					</FormGroup>

				</Col>

			</Row>
			<Row>
				<Col md={1}>
					<FormGroup className="m-2">

						<Form.Text className="text-muted">
							Order
						</Form.Text>
						<Form.Select className="form-control "  >
							<option value="true">ALL</option>
							<option value="true">Yes</option>
							<option value="false">No</option>
						</Form.Select>

					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Salesman Name  Name:-</Form.Text >


						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedSalesman}
								options={Array.from(
									new Set(
										tableData
											?.filter((item) => item?.nameofUser !== null)
											?.map((item) => item?.nameofUser)
									)
								).map((nameofUser) => ({ label: nameofUser }))}
								// options={[]}

								placeholder="Choose Salesman Name..."
								selected={selectedSalesman}
								labelKey={"label"}
							/>
							<Button className="btn btn-primary" onClick={() => { selectedSalesman?.[0]?.label ? setShowmap(true) : notifyWarning("Select Salesman ") }}>
								<FontAwesomeIcon icon={faRotate} />ShoWMap



							</Button>



							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Party Name:-</Form.Text >


						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedParty}
								options={Array.from(
									new Set(
										tableData
											?.filter((item) => item?.partyName !== null)
											?.map((item) => item?.partyName)
									)
								).map((partyName) => ({ label: partyName }))}
								// options={[]}

								placeholder="Choose Party Name..."
								selected={selectedParty}
								labelKey={"label"}
							/>

							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={2}>
					<FormGroup className="m-2">
						<Form.Text className="text-muted">Holiday</Form.Text>
						<Form.Select className="form-control" onChange={handleSelectHolidayChange}>
							<option value="false">No</option>
							<option value="true-sun">Sunday</option>
							<option value="true-mon">Monday</option>
							<option value="true-tue">Tuesday</option>
							<option value="true-wed">Wednesday</option>
							<option value="true-thu">Thursday</option>
							<option value="true-fri">Friday</option>
							<option value="true-sat">Saturday</option>
							<option value="true">SAT-SUN</option>

						</Form.Select>


					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup className="m-2">
						<Form.Text className="text-muted">Export</Form.Text>
						<Button className="btn btn-primary " onClick={onExportClick} value="ExportToCsv">
							Export To CSV
						</Button>

					</FormGroup>

				</Col>

			</Row>


			<div className="ag-theme-alpine" style={{ height: '500px', overflowX: 'auto' }}>
				<div className="ag-theme-alpine" style={{ height: '570px' }}>
					<AgGridReact
						id="AgGridforData"
						rowData={agGridData || []}
						// .filter((item) => item?.nameofUser === null)
						// rowData={ selectedSalesman? tableData?.filter((item)=>{if((item?.nameofUser===selectedSalesman)) {return item}}) :
						// tableData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
						alwaysShowHorizontalScroll={true}

					/>


				</div>
			</div>




			<Modal show={openprint}
				onHide={() => setOpenprint(!openprint)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print Shop Visit Memo </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className='p-4'>
						<ShopVisitprint data={dataforprint} />
					</Card>

				</Modal.Body>
				<Modal.Footer>


				</Modal.Footer>
			</Modal>

			<Modal show={showmap}
				onHide={() => setShowmap(!showmap)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Map of Visit </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className='p-4'>
						<ShopVisitMap dataArray={selectedSalesman?.[0]?.label ?
							tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
							: tableData} />
					</Card>

				</Modal.Body>
				<Modal.Footer>


				</Modal.Footer>
			</Modal>

		</div>
	)
}