import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import http from '../../../constants/AxiosConfig';


import {
	BASEURL_JWTAUTH, VINEO_GETALLCATEGORY_AVAILABLE,
	VINEO_GETALLCOMPANY_AVAILABLE,
	VINEO_GETALLSUBCATEGORY_AVAILABLE,
	VINEO_GETALLUSERS_AVAILABLE, VINEO_GETALLFIRM_AVAILABLE, VINEO_GETALLGROUP_AVAILABLE, VINEO_GETALLSUBGROUP_AVAILABLE,
	VINEO_GETALL_BRAND_AVAILABLE, VINEO_GETALL_GST_NAME_AVAILABLE, VINEO_GETALL_PRODUCT_NAME_AVAILABLE, VINEO_GETALL_AREA_NAME_AVAILABLE,
	VINEO_GETALL_ROUTE_NAME_AVAILABLE, VINEO_GETALL_ACCOUNTTYPE1_NAME_AVAILABLE, VINEO_GETALL_ACCOUNTTYPE2_NAME_AVAILABLE, VINEO_GETALL_ACCOUNTGROUP_NAME_AVAILABLE,
	VINEO_GETALL_CUSTBANK_NAME_AVAILABLE, VINEO_GETALL_SERVICEACCOUNT_NAME_AVAILABLE,
	VINEO_GETALL_SCHEME_NAME_AVAILABLE,
	VINEO_GETALL_GODOWN_NAME_AVAILABLE,
	VINEO_GETALL_VAN_NAME_AVAILABLE,
	VINEO_GETALL_SPECIALGROUP_NAME_AVAILABLE,
	VINEO_GETALL_SUBBRAND_AVAILABLE,
	VINEO_GETALL_SALESMAN_USERDATA,
	VINEO_GETALL_ACCOUNTCUSTACCNTINFO_NAME_AVAILABLE,
	VINEO_GETALL_ACCOUNT_VENDOR_ACCNTINFO_NAME_AVAILABLE,
	VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLE,
	VINEO_GETALL_PURCHASEBILL_AVAILABLE_WITHDATE,
	VINEO_GETALL_DEBITNOTE_AVAILABLEWITHDATE,
	VINEO_GETALL_PRODUCTWITHPOSITIVEQTY_NAME_AVAILABLE,
	VINEO_GETALL_GODOWN_NAME_AVAILABLE_REFEREEDCLIENT_AUTOIMPORT,
	VINEO_GETALLUSEROFMOTHR_CLIENTDATA,
	VINEO_GETALL_CREDITNOTE_AVAILABLEWITHDATE,
	VINEO_GETALL_TRANSPORT_NAME_AVAILABLE,
	VINEO_GET_ALLGRNWITHDATE,
	VINEO_GETALLFIRM_AVAILABLE_FOR_AUTOIMPORT,
	VINEOFORM_UPDATE_SELECTED_LASTBILLNUMBER,
	VINEO_GET__ALL_PRODUCT_NAME_AVAILABLE_FOREDIT,
	VINEO_GETALL_ACCOUNTCUSTACCNTINFO_FOR_PARTY_TRANSFER,
	
} from '../../../constants/Urlforvineo';


// const http = axios.create({

// 	headers: {
// 		"Content-type": "application/json",
// 		"X-uniqueidForVineoclientName": `${JSON.parse(sessionStorage.getItem("uniqueidForVineoclientName"))}`,
// 		"X-vineoRegisteredClientName": `${JSON.parse(sessionStorage.getItem("vineoRegisteredClientName"))}`,
// 		// "Authorization": `Bearer TestValuer }`
// 		"Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`
// 	}
// });
// this code is transferred to global config file if you require any additional header set here 




export const getVineoUsers = createAsyncThunk('vineousers', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLUSERS_AVAILABLE).then(
		(res) => {
			console.log("Called Vineo Users")
			console.log(res)
		
			return res.data;
		},
		(err) => {
			console.log(err)
			
			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
		
			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
		
		
	)

	return response;
}

);

export const getVineoMotherUserswithmotherclient = createAsyncThunk('vineousers', async (reqdata) => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLUSEROFMOTHR_CLIENTDATA,reqdata).then(
		(res) => {
			
			console.log((res.data))
			return res.data;
		},
		(err) => {
			console.log(err)
			
			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
		
			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
		
		
	)

	return response;
}

);

export const getVineoSalesmanUsers = createAsyncThunk('vineosalesmanusers', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_SALESMAN_USERDATA).then(
		(res) => {
			
			console.log(res)
			return res.data;
		},
		(err) => {
			console.log(err)
			
			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
		
			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
		
		
	)

	return response;
}

);
export const getVineoFirmData = createAsyncThunk('vineofirmdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLFIRM_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getAutoImportVineoFirmData = createAsyncThunk('Autoimportvineofirmdata', async (reqdata) => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLFIRM_AVAILABLE_FOR_AUTOIMPORT,reqdata).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getAutoImportVineoPartyData = createAsyncThunk('autoimportvineopartydata', async (reqdata) => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ACCOUNTCUSTACCNTINFO_FOR_PARTY_TRANSFER,reqdata).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getLastBillNumberData = createAsyncThunk('lastBillNumber', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_LASTBILLNUMBER).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoCompanyData = createAsyncThunk('vineocompanydata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLCOMPANY_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoCompanyCategoryData = createAsyncThunk('vineocompanycategorydata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLCATEGORY_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);


export const getVineoCompanySubCategoryData = createAsyncThunk('vineocompanysubcategorydata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLSUBCATEGORY_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);
export const getVineoGroupyData = createAsyncThunk('vineogroupdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLGROUP_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoSubGroupyData = createAsyncThunk('vineosubgroupdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALLSUBGROUP_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoBrandData = createAsyncThunk('vineobranddata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_BRAND_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);


export const getVineoSubBrandData = createAsyncThunk('vineoSubbranddata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_SUBBRAND_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoGstData = createAsyncThunk('vineogstddata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_GST_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoProductData = createAsyncThunk('vineoproductddata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_PRODUCT_NAME_AVAILABLE).then(
		(res) => {
			// console.log(res)
			// console.log("JSON String of Product is On line no 383")
			// console.log(JSON.stringify(res.data))
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoProductDataForEditingProduct = createAsyncThunk('vineoproductddata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GET__ALL_PRODUCT_NAME_AVAILABLE_FOREDIT).then(
		(res) => {
			// console.log(res)
			// console.log("JSON String of Product is On line no 383")
			// console.log(JSON.stringify(res.data))
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);


export const getVineoProductDatawithPositiveQtyinBatch = createAsyncThunk('vineoproductddatawithpositiveqty', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_PRODUCTWITHPOSITIVEQTY_NAME_AVAILABLE).then(
		(res) => {
			// console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAreaData = createAsyncThunk('vineoareaddata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_AREA_NAME_AVAILABLE).then(
		(res) => {
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoRouteData = createAsyncThunk('vineoroutedata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ROUTE_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAccountType1Data = createAsyncThunk('vineoaccounttype1data', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ACCOUNTTYPE1_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)
			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAccountType2Data = createAsyncThunk('vineoaccounttype2data', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ACCOUNTTYPE2_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAccountGroupData = createAsyncThunk('vineoaccountgroupdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ACCOUNTGROUP_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)

			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

// Account and customer and other account 

export const getVineoAccountCustomerMasterMenu = createAsyncThunk('vineoaccountcustomerdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ACCOUNTCUSTACCNTINFO_NAME_AVAILABLE).then(
		(res) => {
			// console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoAccountVendorCustomerMasterMenu = createAsyncThunk('vineoaccountvendorcustomerdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_ACCOUNT_VENDOR_ACCNTINFO_NAME_AVAILABLE).then(
		(res) => {
			console.log("Vendor Data from Server "+res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);


export const getVineoCustomerBankMasterData = createAsyncThunk('vineocustomerbankmasterdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_CUSTBANK_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoSchemaMasterData = createAsyncThunk('vineoschemamasterdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_SCHEME_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoGodownData = createAsyncThunk('vineogodowndata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_GODOWN_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoGodownDataofRefreeredClientforImport = createAsyncThunk('vineogodowndataofAutoImportrefClient', async (reqdata) => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_GODOWN_NAME_AVAILABLE_REFEREEDCLIENT_AUTOIMPORT,reqdata).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoVanMasterData = createAsyncThunk('vineovanmasterdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_VAN_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoTransportMasterData = createAsyncThunk('vineotransportmasterdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_TRANSPORT_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoSpecialGroupMasterData = createAsyncThunk('vineospecialgroupmasterdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_SPECIALGROUP_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoServiceAccountData = createAsyncThunk('vineoserviceaccountdata', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_SERVICEACCOUNT_NAME_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoCreditNotePositiveData = createAsyncThunk('vineocreditnotedataPositive', async () => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLE).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoCreditNoteDataWithDate = createAsyncThunk('vineoCreditnotedatawithDate', async (reqdata) => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_CREDITNOTE_AVAILABLEWITHDATE,reqdata).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);



export const getVineoDebitNoteDataWithDate = createAsyncThunk('vineoDebitnotedatawithDate', async (reqdata) => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GETALL_DEBITNOTE_AVAILABLEWITHDATE,reqdata).then(
		(res) => {
			console.log(res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);

export const getVineoGRNDataWithDate = createAsyncThunk('vineoGRNdatawithDate', async (reqdata) => {

	const response = await http.post(BASEURL_JWTAUTH + VINEO_GET_ALLGRNWITHDATE,reqdata).then(
		(res) => {
			console.log("Response for GRN data",res)
			return res.data;
		},
		(err) => {

			const stored = sessionStorage.getItem('uniqueidForVineoclientName')
			const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
			const stored2 = JSON.parse(sessionStorage.getItem("token"))
			console.log(stored)
			console.log(stored1)
			console.log(stored2)

			console.log("Error From Server with code = " + err.data)
			return err.data;
		}
	)

	return response;
}

);






export const getUsertest = createAsyncThunk('user', async (id) => {
	const response = await axios.get(
		`https://jsonplaceholder.typicode.com/users/${id}`
	);
	return response.data;
});