import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddBillData, getVineoAddBillDatawithdate } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';

const reArrangeBillDataProductWise = (serverData = []) => {
	let productData = []

	if (serverData?.length)
		for (let i = 0; i < serverData.length; i++) {
			const tmpBillObj = {
				date: serverData[i].vineoAddSaleBillDate,
				billType: serverData[i].vineoAddSaleBillType,
				billNoWithPrefix: serverData[i].vineoaddUniqueBillNumberWithPrefix || '',
				partyName: serverData[i].vineoAddSaleBillCustomer.vineoCustomerAccountName,
			}

			const itemList = serverData[i].vineoAddBillItemList || []

			for (let j = 0; j < itemList.length; j++) {
				const tmpProductObj = {
					...tmpBillObj,
					batchQty: itemList[j].vineoAddBillProductBatchQty,
					grossAmount: itemList[j].vineoAddBillItemNetAmount,
					productName: itemList[j].vineoProduct.vineoProductName,
				}

				productData.push(tmpProductObj)
			}
		}

	return productData
}

export default function AllPartywiseSale() {
	const { setValue } = useForm();
    let currentDate = new Date();
	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const vineoaddbilldatawithdate = useSelector((state) => state.vineoData.vineoaddbilldatawithdate);
	const isvineoaddbilldatawithdateLoaded = useSelector((state) => state.vineoData.isvineoaddbilldatawithdateLoaded);
	const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
	const [toDate, setToDate] = useState(new Date());
	let preparedData;
	const [tableData, setTableData] = useState();
	const [isLoading, setLoading] = useState(false);
	const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)


	useEffect(() => {

        console.log("Refreshing on state Change "+refreshcount)
        setLoading(false)
		setTableData(preparedData)
    }, [refreshcount,isvineoaddbilldatawithdateLoaded]);
   
	// Ag grid Work Start 
	const [gridApi, setGridApi] = useState(null)

	const onExportClick = () => {
		gridApi.api.exportDataAsCsv();
	}

	const onGridReady = (params) => {
		setGridApi(params);
	}

	const defaultColDef = {
		sortable: true,
		editable: true,
		flex: 1,
		filter: true,
		floatingFilter: true,
	}

	const onPaginationChange = (pageSize) => {
		gridApi.api.paginationSetPageSize(Number(pageSize))
	}

	function onCellValueChanged(event) {
		console.log(
			'onCellValueChanged: '
		);
	}

	const columnDefs = [
		{ headerName: "Product", field: 'productName' ,resizable: true  },
		{ headerName: "BillNo.", field: "billNoWithPrefix",resizable: true  },
		{ headerName: "Date ", field: "date" ,resizable: true  },
		{ headerName: "Party", field: 'partyName' ,resizable: true  },
		{ headerName: "Type", field: "billType" ,resizable: true  },
		{ headerName: "Qty", field: 'batchQty',resizable: true  },
		{ headerName: "Amount", field: 'grossAmount' ,resizable: true  },
		{
			headerName: "Actions", field: "id", cellRendererFramework: (params) => (
				<div>
					<Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
					<Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
				</div>
			)
		}
	]

	const handleUpdate = (data) => {
		// add corresponsding update API logic over here
		console.log('product updated')
	}

	const handleDelete = (data) => {
		const confirm = window.confirm("Are you sure, you want to delete this row", data)

		if (confirm) {
			console.log('product deleted from the bill')
			// add corresponsding delete API logic over here
		}
	}

	const getbilldatafromserverwithdate = (reqdata) => {
		if (reqdata.startDate && reqdata.endDate) {
			dispatch(getVineoAddBillDatawithdate(reqdata))
		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}

	// needed below useEffect to solve issue which needed Load Bills to be clicked twice for initial page load only
	// re-write this logic to fetch existing data from redux for specified dates on initial load only
	// or it will keep fetching the data irrespective of the date selected on initial load only ( current behavior )
	// Solution: on navigation resetting the isvineoaddbilldatawithdateLoaded state variable in redux might solve the issue
	useEffect(() => {
		if (isvineoaddbilldatawithdateLoaded) {
			 preparedData = reArrangeBillDataProductWise(vineoaddbilldatawithdate)
			setTableData(preparedData)
		}
	}, [isvineoaddbilldatawithdateLoaded, vineoaddbilldatawithdate])

	return (
		<div className="row justify-content-left ">
		 <div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">All Product Wise Sales Report</h4>
				</center>
			</div>

			<Row>
				<Col md={3}>
					<FormGroup className="m-2">
					<Form.Text >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Bills From 
                        
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 3)); console.log("Date selected is ", fromdate)
                        }} > -3Day</div>
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 60))
                        }} > -60Day</div>
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 365))
                        }} > -1Year</div>
                        </div>
                        
                        </Form.Text>

						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setFromdate}
							name="vineoCreateLoadBillFromDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							Bills To Date
						</Form.Text>

						<DatePicker className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillToDate", moment(toDate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col>
					<Button className="btn btn-primary m-2" disabled={isLoading} onClick={() => {setLoading(true);
						getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) })
					}}>
						<FontAwesomeIcon icon={faRotate} />
						&nbsp;Load Bills
					</Button>
					<Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
						Export To CSV
					</Button>
				</Col>
			</Row>

			<div className="ag-theme-alpine" style={{ height: '500px' }}>
				<div className="ag-theme-alpine" style={{ height: '570px' }}>
					<AgGridReact
						id="AgGridforBillData"
						rowData={tableData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
					/>

					<AgGridColumn
						headerName="BQty"
						valueGetter={(params) => {
							return params.data.vineoBatchProductQty;
						}}
						valueSetter={(params) => {
							let newValInt = parseInt(params.newValue);
							let valueChanged = params.data.vineoBatchProductQty !== newValInt;

							if (valueChanged) {
								params.data.vineoBatchProductQty = newValInt;
							}

							return valueChanged;
						}}
					/>
				</div>
			</div>
		</div>
	)
}