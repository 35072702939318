import React from "react";
import ReactToPrint from "react-to-print";
import '../../../printFormat.css';
import PropTypes from "prop-types";
import QRCode from 'qrcode.react';
import { Table, Button, Col, Row, Card } from "react-bootstrap";
var converter = require('number-to-words');
class ComponentToPrint extends React.Component {
    render() {
        return (
            <><div className="">
                <strong>Credit Note</strong>
                <div id='first-row'>
                    <div className='col'>

                        SUPPLIER: <strong>  {this.props.data?.firmName}</strong>
                        <br />
                        Address: {this.props.data?.firmAddress?.substring(0, 80)}
                        <br />
                        City: {this.props.data?.firmAddressCity}
                        <br />
                        Pin:  {this.props.data?.firmAddresspincode}
                        <br />
                        Contact No: {this.props.data?.firmPhone}
                        <br />
                        State Code:  {this.props.data?.firmGSTNumber?.substring(0, 2)}
                        <br />
                        GSTIN:  {this.props.data?.firmGSTNumber}
                    </div>
                    <div className='col'>
                        CUSTOMER:<strong>{this.props.data?.vineoCustomerAccountName}</strong>
                        <br />
                        Address:{this.props.data?.vineoCustomerAccountAddress?.substring(0, 100)}
                        <br />
                        City: {this.props.data?.vineoCustomerAccountCity}
                        <br />
                        Pin:  {this.props.data?.vineoCustomerAccountPincode}
                        <br />
                        Contact No: {this.props.data?.vineoCustomerAccountPhone}
                        <br />
                        State Code:   {this.props.data?.vineoCustomerAccountGSTNumber?.substring(0, 2)}
                        <br />
                        GSTIN:  {this.props.data?.vineoCustomerAccountGSTNumber}
                    </div>

                    <div className='col'>
                        <img src={require("../../../images/dklogofrontpage.png")} height='40' alt='Dorf Ketal logo' style={{ display: 'block' }} />
                        <strong> CRN No:</strong>   {this.props.data?.vineocreditNotePrefix}{this.props.data?.creditNoteNumber}
                        <br />
                        Date: {this.props.data?.vineoCreditNoteDate}
                        <br />
                        Issued On: {this.props.data?.creditNoteIssuedonBillNumberwithprefix}
                        <br />
                        Used On:  {this.props.data?.creditNoteAppliedonBillNumberwithprefix}
                        <br />
                        Credit withPrefix : {this.props.data?.creditNoteNumberwithprefix}
                        <br />
                        E-INV CRN : {this.props.data?.einvoiceStatus}

                    </div>
                </div>
                <table id='main-table'>
                    <thead>
                        <tr>
                            <th rowSpan='2'>Sr No.</th>
                            <th rowSpan='2'>Product Name</th>
                            <th rowSpan='2'>HSN Code</th>
                            <th rowSpan='2'>Batch</th>
                            <th rowSpan='2'>SoldQty</th>
                            <th rowSpan='2'>Unit</th>
                            <th rowSpan='2'>Free</th>
                            <th rowSpan='2'>Damage</th>
                            <th rowSpan='2'>Saleble</th>
                            <th rowSpan='2'>Rate</th>
                            {/* <th rowSpan='2'>Sch Dis</th> */}
                            <th rowSpan='2'>Taxable</th>
                            <th colSpan='2'>SGST</th>
                            <th colSpan='2'>CGST</th>
                            <th rowSpan='2'>Total</th>
                        </tr>
                        <tr>
                            <th scope='col'>SGST%</th>
                            <th scope='col'>SGST</th>
                            <th scope='col'>CGST%</th>
                            <th scope='col'>CGST</th>
                        </tr>
                        <tr>

                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data?.vineoCreditNoteitemsList.map((items, index) => {
                            return (
                                (items?.totalitemamount > 0) ?
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{items?.productName} </td>
                                        <td>{items?.itemHSNcode}</td>
                                        <td>{items?.batchNumber}</td>
                                        <td>{items?.actaulSoldBillqtyFrombill}</td>
                                        <td>{items?.itemunit}</td>
                                        <td>{items?.freeqty}</td>
                                        <td>{items?.damagedproductreturnqtyinpcs}</td>
                                        <td>{items?.sellableproductreturnqtyinpcs}</td>
                                        <td>{items?.itemeffectiveratefrombill}</td>
                                        {/* <td>0.00</td> */}
                                        <td>{items?.totalitemamount}</td>
                                        <td>{items?.totalitemSgstpercentage}</td>
                                        <td>{items?.totalitemSgst}</td>
                                        <td>{items?.totalitemCgstpercentage}</td>
                                        <td>{items?.totalitemCgst}</td>
                                        <td>{items?.totaliteNetAmount}</td>
                                    </tr>
                                    :
                                    <></>

                            )
                        })
                        }
                    </tbody>
                </table>
                <div id='totals'>
                    <span>{converter.toWords(this.props.data?.creditnoteAmount).toUpperCase()}</span>
                    <span>iGST:  {this.props.data?.totalitemIgst} </span>
                    <span>GST:  {this.props.data?.totalGst} </span>

                    <span>BASIC:{this.props.data?.creditnoteAmountwithGST -  this.props.data?.totalGst }</span>
                    <span>NET:{this.props.data?.creditnoteAmount}</span>
                </div>
                <div id='last-row'>

                <div>
                            {/* <img src={`data:image/svg+xml;base64,${btoa(this.props.data?.ewaysignedQRCode)}`}  /> */}
                            {this.props.data?.ewaysignedQRCode? <QRCode value={this.props.data?.ewaysignedQRCode}  size={256}
      level={'L'}
      includeMargin={true}/>:<></>}
                        </div>
                    <div>
                        * GST Summary*
                        <br />
                        <table>
                            <thead>
                                <tr>
                                    {this.props.data?.isigsctbil ? <> <th>iGST AMT</th></> : <>
                                        <th>cGST  </th>
                                        <th>sGST  </th>
                                        <th>GST AMT</th>
                                    </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {this.props.data?.isigsctbil ? <> <th>{this.props.data?.totaligstvalue}</th></> : <>
                                        <th>{this.props.data?.totalCgst}  </th>
                                        <th>{this.props.data?.totalSgst}  </th>
                                        <th>{this.props.data?.totalGst} </th>
                                    </>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        Total Credit Note Amount Used : {this.props.data?.creditnoteamountusedup}
                        <br />
                        Is Credit Note from Loyality : {this.props.data?.iscreditnotefromloyality ? <>YES</> : <>NO</>}
                        <br />
                      
                        <br />

                    </div>


                    <div>
                        Adjust Amt
                        <br />
                        Adjust Amt Extra
                        <br />
                        Total GST
                        <br />
                        Packaging
                        <br />
                        Rounding
                        <br />
                        Credit Amt
                    </div>
                    <div>
                        {this.props.data?.adjustamount}
                        <br />
                        {this.props.data?.adjustamountExtra}
                        <br />
                        {(this.props.data?.isigsctbil > 0) ? <>
                            {this.props.data?.totaligstvalue} </> : <>
                            {this.props.data?.totalCgst + this.props.data?.totalSgst}</>}
                        <br />


                        {this.props.data?.vineoAddSalePackagingCharges}
                        <br /> {this.props.data?.roundingOff}
                        <br />{this.props.data?.creditnoteAmount}

                    </div>

                </div>
                <div id='last-row'>
                        IRN: {this.props.data?.einvoiceIRN}  & EinvAck: {this.props.data?.einvoiceAckNo}</div>
                   

                <div id='footer'>

                    <span>
                        {this.props.data?.vineoAddSaleBillFirm?.vineofirmtermandconstionsforbill}
                        <br />

                    </span>
                    <span>
                        <br />
                        <br />
                        {this.props.data?.firmName}</span>
                </div>
            </div >

            </>
        );
    }
}

class CreditNoteprint extends React.Component {

    constructor() {
        super();
        this.state = {
            count: 0
        };

    }

    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => this.componentRef}
                    pageStyle={`
                    @page {
                      margin: 0.5cm 0.5cm;
                    }
                  `}
                />

                <ComponentToPrint data={this.props.data} ref={el => (this.componentRef = el)} />
            </div>
        );
    }
}

export default CreditNoteprint;