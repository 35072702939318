import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../constants/AuthUser';
import http from '../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import { getVineoFirmData, getVineoGodownData, getVineoVanMasterData } from '../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEOFORM_UPDATE_SELECTED_VAN_NAMEDATA, VINEOFORM_DELETE_SELECTED_VAN_NAMEDDATA, VINEO_IMPORT_PURCHASE_FROMCSV_FILE, VINEO_IMPORT_BATCH_FROMCSV_FILE } from '../../constants/Urlforvineo';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function ImportBatchIntoSystem() {
    useEffect(() => {
        dispatch(getVineoFirmData())
        dispatch(getVineoGodownData());


    }, []);
    
    const dispatch = useDispatch();
    const [selectedFirm, setSelectedFirm] = useState([])
    const [string, setString] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [paymentpaid, setPaymentpaid] = useState(false);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [selectedGodown, setSelectedGodown] = useState([])
    const [usePurchasefile, setUsePurchasefile] = useState(true)
    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const [isLoaded, setIsLoaded] = useState(false);
    const onSubmit = (data) => {

        console.log(data.vineofile[0]);
        // submitForm(data.vineofile[0])
    }
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const vineoGodowndata = useSelector((state) => state.vineoData.vineogodowndata)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    useEffect(() => {
        console.log("Refresh Count Changed")
    }, [refreshcount]);



    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleUpload = (e) => {

        // setIsEnabled(true);
        setIsLoaded(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('vineofile', file);
        formData.append('vineoFirm', (selectedFirm?.[0]?.ultiVineoFirmUniqeId))
        formData.append('ultiVineoGoDownUniqeId', (selectedGodown?.[0]?.ultiVineoGoDownUniqeId))
        formData.append('paymentpaid', paymentpaid) 
        formData.append('importfromcsv', true)
        formData.append('usePurchasefile', usePurchasefile)
        console.log(formData)
        console.log("File Size ", file?.size)
        if (file?.size < 4000000 && file?.size > 0 && selectedFirm?.[0]?.ultiVineoFirmUniqeId && selectedGodown?.[0]?.ultiVineoGoDownUniqeId) {
            http.post(BASEURL_JWTAUTH + VINEO_IMPORT_BATCH_FROMCSV_FILE, formData)
                .then((res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify(' Success:-  Batch Imported with Invoice ID as ' + res.data.additionalInfo)
                        setIsLoaded(false)
                    }
                    else
                    notifyError("Error While Importing Batch Info From Server " + res.data.additionalInfo)
                    //  console.log(res.data)
                    setIsLoaded(false)
                },
                    (err) => {
                        console.log(err)

                        notifyError("Error While Importing Data From Server" + err.data)
                        setIsLoaded(false)
                    })
                .catch((err) => {

                    notifyError("Error While Importing Data" + err)
                    setIsLoaded(false)                });

        }
        else {
            notifyError("Select Firm, Godown and File SIze is more than 4 MB Please Select Lower Size File")
            setIsLoaded(false)
        }

    }


    return (
        <div>
            <h3 className='justify-content-center m-1'>Upload CSV File For Batch</h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={3}>
                        <Form.Text >Select Firm *</Form.Text >
                        <InputGroup>
                            <Typeahead
                                id="FirmNameTypehead"
                                onChange={setSelectedFirm}
                                options={vineofirmdata ? vineofirmdata : []}
                                placeholder="Choose Firm Name..."
                                selected={selectedFirm}
                                labelKey={"vineoFirmFirmName"}
                            />
                            <Button variant="primary"
                                onClick={() => { dispatch(getVineoFirmData()); }} >
                                <FontAwesomeIcon icon={faRotate} />
                            </Button>
                            {console.log(selectedFirm?.[0]?.ultiVineoFirmUniqeId)}
                            {/* {setValue("vineoAddPurchaseFirm", selectedFirm?.[0])} */}
                        </InputGroup>
                    </Col>
                    

                    <Col md={3}>
                        <FormGroup>
                            <Form.Text >
                                Godown *
                            </Form.Text>
                            <InputGroup>
                                <Typeahead

                                    id="VendorGodownTypehead"
                                    onChange={setSelectedGodown}
                                    options={vineoGodowndata ? vineoGodowndata : []}
                                    placeholder="Choose Godown for this Batch"
                                    selected={selectedGodown}
                                    labelKey={"vineoGoDownName"}

                                />
                                <Button variant="primary"
                                    onClick={() => { dispatch(getVineoGodownData()); }} >
                                    <FontAwesomeIcon icon={faRotate} />
                                </Button>
                            </InputGroup>

                            {/* {setValue("vineoBatchSet.vineoGoDown", selectedGodown?.[0])} */}


                        </FormGroup>
                    </Col>
                    <Row>
                    <Col md={3} >
                        <Form.Text>
                            Generate Receipt / PAYMENT PAID
                        </Form.Text>

                        <Form.Select className="form-control" onChange={(e) => { setPaymentpaid(e.target.value) }} required>
                            <option  value="true">Yes</option>
                            <option selected={true} value="false">No</option>
                        </Form.Select>

                    </Col>
                    <Col md={3}>
                        <Form.Text>
                            Use Format Purcahse / Bill CSV
                        </Form.Text>

                        <Form.Select className="form-control" onChange={(e) => { setUsePurchasefile(e.target.value) }} required>
                            <option  selected={true}  value="true">Purchase CSV Format</option>
                            <option value="false">Bill CSV Format</option>
                        </Form.Select>

                    </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div >
                                <Card className='p-4 mt-4'>


                                    <div>
                                        <form onSubmit={handleUpload}>

                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>Select CSV AS per Format * </Form.Label>
                                                <Form.Control type="file" onChange={handleFileChange} />
                                            </Form.Group>

                                            {/* <input type="file" onChange={handleFileChange} /> */}
                                            <Button disabled={isLoaded} type="submit">  {isLoaded ? 'Uploading' : 'Upload'}</Button>
                                            
                                        </form>
                                        <p>{message}</p>
                                    </div>

                                </Card>
                            </div>


                        </Col>
                    </Row>
                    
                </Row>

            </div>
        </div>
    )

}