import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from "../../constants/AuthUser";
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useGetRegisteredUsersByPostMutation } from "../../redux/features/services/postApi";
import { decrement } from "../../redux/features/vineoData/vineoDataSlice";
import { useSelector, useDispatch } from 'react-redux'
import { getVineoUsers, getUser, getVineoCompanyData, getVineoCompanyCategoryData, getVineoFirmData, getVineoRouteData, getVineoAreaData } from "../../redux/features/services/serviceHelper";
import {
    FormGroup, Label, Input, Tab, Table, Container,
    Button, Form, Row, Col, ListGroup, ListGroupItem, FloatingLabel, Card, InputGroup
} from 'react-bootstrap';

import {
    BASEURL_JWTAUTH,
    VINEO_REGISTER_USERINFODATA,
    VINEO_CHECKUSERNAME_AVAILABLE,
    VINEO_DELETE_SELECTEDUSER_USERDATA,
    VINEO_UPDATE_SELECTEDUSER_USERDATA,
    VINEO_USER_FEEDBACKFORM
} from "../../constants/Urlforvineo";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import http from "../../constants/AxiosConfig";

export default function Feedbackform() {
    
    const navigate = useNavigate();
    const { register, handleSubmit, reset, watch, formState: { errors }, setValue } = useForm();
    const { getUser } = AuthUser();
    const [dataofvineoUsers, setDataofVineoUsers] = useState([])
    const [userName, setUserName] = useState({});
    const [usernameAvailable, setUsernameAvailable] = useState(false);
    const [username, setUsername] = useState();
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    const uniquenameString = sessionStorage.getItem('vineoRegisteredClientName');

    const vineoUsers = useSelector((state) => state.vineoData.vineouserswithmotherclient)
    const isvineousersLoaded = useSelector((state) => state.vineoData.isvineouserswithmotherclientLoaded)
   
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const requestBody = "RequestBody"

    const dispatch = useDispatch()
    useEffect(() => {
        console.log("Component Mounted")
      
    }, [refreshcount]);

    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
   
    const submitForm = (requestdata) => {

         {
            http.post(BASEURL_JWTAUTH + VINEO_USER_FEEDBACKFORM, requestdata).then(
                (res) => {
                    console.log(res)
                    notify(res?.data)
                    reset()
                    },
                (err) => {
                    notifyError("Error While Sending Feedback User try with another username or Use Purchase key as test")
                }
            )
        }
       
    }

    return (
        <div >

            <h3 className='justify-content-center m-1'>Please Share Your Valueable Feedback </h3>
            <div className="row justify-content-left ">
                <Row >
                    
                    <Col   > 
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >
                                        <Row>
                                            <Col md={6}>
                                                <Form.Text className="text-muted">
                                                    Name
                                                </Form.Text>
                                                <Form.Control 
                                                className="form-control custom-form-field"
                                              
                                                    placeholder="Name"
                                                    {...register('name')} required

                                                /></Col>
                                            <Col md={6}>
                                                <Form.Text className="text-muted">
                                                    email
                                                </Form.Text>
                                                <Form.Control 
                                                className="form-control custom-form-field"
                                                 {...register("email")}  />

                                            </Col>
                                            {/* <Col md={3}><Button><FontAwesomeIcon icon={faRotate} onClick={checkusername} />Check UserName</Button></Col> */}
                                        </Row>
                                    </FormGroup>

                                    <Row>
                                        <Col> <FormGroup>
                                            <Form.Text className="text-muted">
                                                Phone
                                            </Form.Text>
                                            <Form.Control 
                                            className="form-control custom-form-field"
                                             {...register("phone")} required />
                                        </FormGroup>
                                        </Col>
                                       
                                    </Row>
                                    <Row>

                                        <Col>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    feedback
                                                </Form.Text>
                                                <Form.Control  
                                                className="form-control custom-form-field"
                                                 {...register("feedback")} />

                                            </FormGroup></Col>
                                    
                                        
                                    </Row>
                                
                                  
                                        <input className="btn btn-primary m-2" type="submit" value="Send " />
                                        
                                    <button type="button" onClick={() => { reset()}} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>

                        </div>

                    </Col>
                </Row>

            </div>
        </div >
    )
}