// const.jsx

export const stateOptions = [
    { value: "35", label: "Andaman and Nicobar Islands (AN)" },
    { value: "37", label: "Andhra Pradesh (AP)" },
    { value: "12", label: "Arunachal Pradesh (AR)" },
    { value: "18", label: "Assam (AS)" },
    { value: "10", label: "Bihar (BR)" },
    { value: "4", label: "Chandigarh (CH)" },
    { value: "22", label: "Chhattisgarh (CG)" },
    { value: "26", label: "Dadra and Nagar Haveli (DN)" },
    { value: "25", label: "Daman and Diu (DD)" },
    { value: "7", label: "Delhi (DL)" },
    { value: "30", label: "Goa (GA)" },
    { value: "24", label: "Gujarat (GJ)" },
    { value: "6", label: "Haryana (HR)" },
    { value: "2", label: "Himachal Pradesh (HP)" },
    { value: "1", label: "Jammu and Kashmir (JK)" },
    { value: "20", label: "Jharkhand (JH)" },
    { value: "29", label: "Karnataka (KA)" },
    { value: "32", label: "Kerala (KL)" },
    { value: "38", label: "Ladakh (LA)" },
    { value: "31", label: "Lakshadweep (LD)" },
    { value: "23", label: "Madhya Pradesh (MP)" },
    { value: "27", label: "Maharashtra (MH)" },
    { value: "14", label: "Manipur (MN)" },
    { value: "17", label: "Meghalaya (ML)" },
    { value: "15", label: "Mizoram (MZ)" },
    { value: "13", label: "Nagaland (NL)" },
    { value: "21", label: "Odisha (OR)" },
    { value: "97", label: "Other Territory (OT)" },
    { value: "34", label: "Puducherry (PY)" },
    { value: "3", label: "Punjab (PB)" },
    { value: "8", label: "Rajasthan (RJ)" },
    { value: "11", label: "Sikkim (SK)" },
    { value: "33", label: "Tamil Nadu (TN)" },
    { value: "36", label: "Telangana (TS)" },
    { value: "16", label: "Tripura (TR)" },
    { value: "9", label: "Uttar Pradesh (UP)" },
    { value: "5", label: "Uttarakhand (UA)" },
    { value: "19", label: "West Bengal (WB)" },
    { value: "96", label: "Foreign Country (FC)" },
  ];
  