import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import { increment, decrement } from "../../../redux/features/vineoData/counterSlice";
import { toast } from "react-toastify";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Form,Button, Row, Col, FormGroup, ListGroup, ListGroupItem, Input, Lable, Card } from "react-bootstrap";
import { BASEURL_JWTAUTH,  VINEOFORM_DELETE_SELECTED_ACCOUNTGROUP_NAMEDDATA,  VINEOFORM_UPDATE_SELECTED_ACCOUNTGROUP_NAMEDATA,  VINEOFORM_UPDATE_SELECTED_SPECIALGROUP_NAMEDATA } from "../../../constants/Urlforvineo";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getVineoAccountGroupData } from '../../../redux/features/services/serviceHelper';
export default function AccountGroupMaster() {

    const dispatch=useDispatch();
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{"ultiVineoAccountGroupUniqeId":"Working","vineoAccountGroupName":"Click Refresh To Load"}])
    const isaccountgroupdataLoaded = useSelector((state) => state.vineoData.isaccountgroupdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoaccountgroupdata)
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const {register, handleSubmit,setValue, watch, reset, formState:{errors}}=useForm();
    const onSubmit=(data)=>{
        console.log(data);
        submitForm(data)
    }

    const navigate= useNavigate();
    // const {setToken}= AuthUser();
    const notify=(message)=>toast(message);
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoAccountGroupName',
            text: 'Account Group Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'ultiVineoAccountGroupUniqeId',
            text: 'Unique Id.',
            sort: true
        }, 
    
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };

    const getdatafromserver = () => {

 
        dispatch(getVineoAccountGroupData())
        {
            isaccountgroupdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{"ultiVineoAccountGroupUniqeId":"Working","vineoAccountGroupName":"Click Again Refresh To Load"}])
       
        }
       
        console.log("Called to get Account Group Data ")
    }

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoAccountGroupUniqeId", selectedRow.ultiVineoAccountGroupUniqeId);
            setValue("vineoAccountGroupName", selectedRow.vineoAccountGroupName);
            setValue("vineoAccountGroupUnderName", selectedRow.vineoAccountGroupUnderName);
            setValue("vineoAccountGroupOtherName", selectedRow.vineoAccountGroupOtherName);
             setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
      
           
        }
        console.log("Update Account Group ")
    
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
              var answer = window.confirm("Do You want to Delete Selected Account Group ");
              if (answer) {
                  submitFormForDeleteSelectedRow(selectedRow);
              }
              else {
                  notify("Delete Operation Canceled ")
              }
          }
    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ACCOUNTGROUP_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Account Group  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoAccountGroupName )
                }
                else
                    notify("Error While Deleting FIRM  Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting FIRM Try Again  Or Login as ADMIN")

            }
        )
    }


    const submitForm=(data)=>{
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNTGROUP_NAMEDATA, data).then(
            (res)=>{
                console.log(res)
                if(res.data.errorCode== "0000"){
                notify('Success:- Account Group  Created With Internate Id')
                reset();
                
            }
            else
            notify('Error While Registering Account Group Info From Server')
            //console.log(res.data)
        },
        (err)=>{
            notify('Error While Registering Account Group Info')
        }
        )
    }


return(
    <div>
        <h3 className="justify-content-center m-1">Account Group Master</h3>
        <div className="row justify-content-left ">
        <Row>
        <Col md={4}>
            <ToolkitProvider keyField='ultiVineoAccountGroupUniqeId'
                data={dataofvineoTableData}
                columns={columns}
                search   >
                {
                    props => (
                        <div>
                            <div >
                                <Button variant="primary"
                                    onClick={getdatafromserver} >
                                    <FontAwesomeIcon icon={faRotate} />
                                </Button>
                                <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                <SearchBar className="m-1" {...props.searchProps} />
                            </div>
                                <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                {...props.baseProps}
                                pagination={paginationFactory(options)}
                                selectRow={selectRow}
                            // rowEvents={ rowEvents }
                            />

                        </div>
                    )
                }
            </ToolkitProvider>

        </Col>
                <Col md={8}>
                    <div>
                        <Card className="p-3">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <fieldset disabled>
                                        <Form.Control className="form-control"  placeholder='Account Group Code: This Account roup Code Is Auto Generated'  {...register('ultiVineoAccountGroupUniqeId')}/>
                                    </fieldset>
                                </FormGroup>
                                <FormGroup >

                                        <Form.Text >
                                          Account Group Code
                                        </Form.Text>
                                        <Form.Control  className="form-control "   {...register("vineoAccountGroupCode")} required />

                                    </FormGroup>
                                  
                                <FormGroup>
                                    <Form.Text>
                                        Group Name
                                    </Form.Text>
                                    <Form.Control className="form-control"   {...register("vineoAccountGroupName")} required/>
                                </FormGroup>
                                <FormGroup>
                                    <Form.Text>
                                        Under Group Name
                                    </Form.Text>
                                    <Form.Control className="form-control"   {...register("vineoAccountGroupUnderName")}  required/>
                                </FormGroup>
                                <FormGroup>
                                    <Form.Text>
                                        Other Name
                                    </Form.Text>
                                    <Form.Control className="form.control "  {...register("vineoAccountGroupOtherName")}  required/>
                                </FormGroup>
                                <input className="btn btn-primary m-2" type='submit'  value='Add Account'  />
                                <button type='button' onClick={()=>reset()}  className='btn btn-secondary'><FontAwesomeIcon icon={faRotate}/></button>
                            </form>
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>

    </div>
)
    }  