import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit, faPrint, faMoneyBill, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT, VINEO_DELETE_SELECTED_ADDGON, VINEO_CONVERT_SELECTED_ADDGON_TOINVOICE, BILLCRDITDAYS, VINEOFORM_DELETE_SELECTED__UNAPPROVEDACCOUNTCUSTACCNTINFO, VINEOFORM_CONVERT_SELECTED__UNAPPROVEDACCOUNTCUSTACCNTINFOTOPARTY } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineounapprovedPartydatawithdate } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { getVineoAccountCustomerMasterMenu, getVineoFirmData } from '../../../redux/features/services/serviceHelper';
import Billprint from './billing/Billprint';
import GONprint from './GONprint';
import EditGON from './EditGON'
import PartyPrint from './PartyPrint';



export default function ApproveParty() {
	const { setValue, register } = useForm();
	useEffect(() => {
		console.log("Use Effect called ");
		dispatch(getVineoAccountCustomerMasterMenu());
	}, []);
	let currentDate = new Date();
	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const vineoPartydata = useSelector((state) => state.vineoData.vineounapprovedpartydatawithdate)
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const vineounapprovedpartydatawithdate = useSelector((state) => state.vineoData.vineounapprovedpartydatawithdate);
	const isvineounapprovedpartydatawithdateLoaded = useSelector((state) => state.vineoData.isvineounapprovedpartydatawithdateLoaded);
	const [toDate, setToDate] = useState(new Date());
	const [openprint, setOpenprint] = React.useState(false);
	const [dataforprint, setDataforprint] = useState();
	const [tableData, setTableData] = useState();
	const [billdate, setBilldate] = useState(new Date())
	const [billduedate, setBillduedate] = useState(new Date(currentDate.getTime() + BILLCRDITDAYS * 24 * 60 * 60 * 1000));
	const [selectedCustomer, setSelectedCustomer] = useState([])
	const [selectedsalesman, setSelectedsalesman] = useState([])
	const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 10));
	const notify = (message) => toast.success(message);
	const notifyError = (message) => toast.error(message)
	const notifyWarning = (message) => toast.warning(message)
	const [isLoading, setLoading] = useState(false);
	const [openeditGON, setOpeneditGON] = useState(false);
	const vineofirmdatafromredux = useSelector((state) => state.vineoData.vineofirmdata)
	const [vineofirmdata, setVineofirmdata] = useState()
	const [billprefix, setBillprefix] = useState()
	const [editbillnumberalowed, SetEditbillnumberalowed] = useState(false);
	const [lastBillNumber, setLastBillNumber] = useState()
	const [vineoAddSaleBillType, setVineoAddSaleBillType] = useState("CREDIT")

	let lastBillNumberfromreduxfirm = 0;

	useEffect(() => {
		dispatch(getVineoFirmData())

	}, []);

	useEffect(() => {

		console.log("Refreshing on state Change " + refreshcount)
		setLoading(false)
		setTableData(vineounapprovedpartydatawithdate)
		setVineofirmdata(vineofirmdatafromredux)
		setBillprefix(vineofirmdata?.[0]?.vineoaddBillPrefix)
		SetEditbillnumberalowed(vineofirmdata?.[0]?.allowBillNumberedit);
		lastBillNumberfromreduxfirm = vineofirmdatafromredux?.[0]?.vineoaddBillUserBillNumberStartSetting;
		setLastBillNumber(parseInt(lastBillNumberfromreduxfirm) + 1)

	}, [isvineounapprovedpartydatawithdateLoaded, refreshcount]);

	// Ag grid Work Start 
	const [gridApi, setGridApi] = useState(null)

	const onExportClick = () => {
		gridApi.api.exportDataAsCsv();
	}

	const onGridReady = (params) => {
		setGridApi(params);
	}

	const defaultColDef = {
		sortable: true,
		editable: true,
		flex: 1,
		filter: true,
		floatingFilter: true,
	}

	const onPaginationChange = (pageSize) => {
		gridApi.api.paginationSetPageSize(Number(pageSize))
	}

	function onCellValueChanged(event) {
		console.log(
			'onCellValueChanged: '
		);
	}

	const columnDefs = [
		{ headerName: "ID.", field: "ultiVineoCustomerAccountUniqeId", resizable: true },
		{ headerName: "Party", valueGetter: 'data.vineoCustomerAccountName', resizable: true },
		{ headerName: "Salesman", valueGetter: 'data.registeringUserName', resizable: true },
		{ headerName: "firm", valueGetter: 'data.vineoRegisteredClientDetails.clientfirmname', resizable: true },
		{ headerName: "TS", valueGetter: 'data.createDate', resizable: true },
		{ headerName: "Add", valueGetter: 'data.address', resizable: true },
		{ headerName: "GST", valueGetter: 'data.vineoCustomerGstNumber', resizable: true },
		
		{ headerName: "Date ", field: "vineoCustomerAccountopeningDate", resizable: true },
		{
			headerName: "Approved", field: `partyApproved`,
			cellRenderer: params => {
				if (params.value) {
					return `YES`
				}

				return 'NO';
			}
			, resizable: true
		},
		{
			headerName: "Actions", field: "id", cellRendererFramework: (params) => (
				<div>
					<Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>
					<Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
					<Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>

				</div>
			)
		}
	]

	const handleUpdate = (data) => {
		console.log(data);
		// console.log(JSON.stringify(data));
		// setDataforprint(data);
		// setOpeneditGON(true);
	}


	const handleConvertToParty = (data) => {
		console.log('COnvertign this to Party ', data);


		http.post(BASEURL_JWTAUTH + VINEOFORM_CONVERT_SELECTED__UNAPPROVEDACCOUNTCUSTACCNTINFOTOPARTY, data).then(

			(res) => {
				console.log(res)
				if (res.data.errorCode == "0000") {
					notify('Success:- Party Converted  SUCCESSFULLY ' + res.data.errorMessage);

				}
				else
					notifyError("Error While Converting  Party  " + res.data.errorMessage)
			},
			(err) => {
				notifyError("Error While Converting Party Try Again  Or Login as Billing Manger or Admin")

			}
		)

	}
	const handlePrint = (data) => {
		setDataforprint(data);
		setOpenprint(!openprint)
	}
	const handleDelete = (data) => {
		const confirm = window.confirm("Are you sure, you want to delete this row", data)

		if (confirm) {
			http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED__UNAPPROVEDACCOUNTCUSTACCNTINFO, data).then(

				(res) => {
					console.log(res)
					if (res.data.errorCode == "0000") {
						notify('Success:- U Party   DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

					}
					else
						notifyError("Error While Deleting U Party   " + + res.data.errorMessage)
				},
				(err) => {
					notifyError("Error While Deleting U Party  Try Again  Or Login as ADMIN")

				}
			)
		}
	}

	const getbilldatafromserverwithdate = (reqdata) => {
		if (reqdata.startDate && reqdata.endDate) {
			dispatch(getVineounapprovedPartydatawithdate(reqdata))
		} else {
			notifyError("Please Enter Date for Party Start and End ")
		}
	}

	// needed below useEffect to solve issue which needed Load Bills to be clicked twice for initial page load only
	// re-write this logic to fetch existing data from redux for specified dates on initial load only
	// or it will keep fetching the data irrespective of the date selected on initial load only ( current behavior )
	// Solution: on navigation resetting the isvineounapprovedpartydatawithdateLoaded state variable in redux might solve the issue
	useEffect(() => {
		if (isvineounapprovedpartydatawithdateLoaded) {
			setTableData(vineounapprovedpartydatawithdate)
		}
	}, [isvineounapprovedpartydatawithdateLoaded, vineoPartydata, vineounapprovedpartydatawithdate])

	return (
		<div className="row justify-content-left ">
			<div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">Approve Party from Salesman</h4>
				</center>
			</div>

			<Row>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							<div style={{ display: 'flex', alignItems: 'center' }}>
								Party From Date

								<div onClick={() => {
									setFromdate(currentDate.setDate(currentDate.getDate() - 3)); console.log("Date selected is ", fromdate)
								}} > -3Day</div>
								<div onClick={() => {
									setFromdate(currentDate.setDate(currentDate.getDate() - 60))
								}} > -60Day</div>
								<div onClick={() => {
									setFromdate(currentDate.setDate(currentDate.getDate() - 365))
								}} > -1Year</div>
							</div>

						</Form.Text>
						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setFromdate}
							name="vineoCreateLoadBillFromDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							Party To Date
						</Form.Text>

						<DatePicker className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillToDate", moment(toDate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Party Name* GST:-
						{(selectedCustomer?.[0]?.vineoGst?.vineoGstNumber)}
						</Form.Text >
						<InputGroup>
							<Typeahead
								disabled={false}
								id="PartyNameTypehead"
								onChange={setSelectedCustomer}
								options={vineoPartydata}

								placeholder="Choose Party Name..."
								selected={selectedCustomer}
								labelKey={"vineoCustomerAccountName"}
							/>
							<Button variant="primary"
								onClick={() => { dispatch(getVineoAccountCustomerMasterMenu()); }} >
								<FontAwesomeIcon icon={faRotate} />
							</Button>
							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Salesman Name:-
						{(selectedsalesman?.[0]?.vineoGst?.vineoGstNumber)}
						</Form.Text >
						<InputGroup>
							<Typeahead
								disabled={false}
								id="PartyNameTypehead"
								onChange={setSelectedsalesman}
								options={tableData?.map((item) => ({
									salesmanname: item.registeringUserName
								  })).filter(
									(value, index, self) =>
									  self.findIndex((item) => item.salesmanname === value.salesmanname) === index
								  ) || []}

								placeholder="Choose Salesman Name..."
								selected={selectedsalesman}
								labelKey={"salesmanname"}
							/>
							
							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
			</Row>

			<Row>
				<Col>
					<Button className="btn btn-primary m-2" disabled={isLoading} onClick={() => { setLoading(true); getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) }) }}>
						<FontAwesomeIcon icon={faRotate} />
						&nbsp;Load Party
					</Button>
					<Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
						Export To CSV
					</Button>
				</Col>
			</Row>

			<div className="ag-theme-alpine" style={{ height: '500px' }}>
				<div className="ag-theme-alpine" style={{ height: '570px' }}>
					<AgGridReact
						id="AgGridforBillData"
						// rowData={tableData}
						rowData={
							(selectedCustomer?.[0]?.vineoCustomerAccountName || selectedsalesman?.[0]?.salesmanname)
							  ? tableData?.filter((item) => {
								  return (
									(item?.vineoCustomerAccountName === selectedCustomer?.[0]?.vineoCustomerAccountName) ||
									(item?.registeringUserName === selectedsalesman?.[0]?.salesmanname)
								  );
								})
							  : tableData
						  }
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
					/>

					<AgGridColumn
						headerName="BQty"
						valueGetter={(params) => {
							return params.data.vineoBatchProductQty;
						}}
						valueSetter={(params) => {
							let newValInt = parseInt(params.newValue);
							let valueChanged = params.data.vineoBatchProductQty !== newValInt;

							if (valueChanged) {
								params.data.vineoBatchProductQty = newValInt;
							}

							return valueChanged;
						}}
					/>
				</div>
			</div>

			<Modal show={openprint}
				onHide={() => setOpenprint(!openprint)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print Party Form</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Button variant="warning m-1" onClick={() => handleConvertToParty(dataforprint)}>Convert To Party -  <FontAwesomeIcon icon={faMoneyBillTransfer} /></Button>
					{
						dataforprint?.invoiceIsGenerated ? "Party Already Approved It cant be submitted again " : <></>
					}

					<Card className='p-4'>
						<PartyPrint data={dataforprint} />
					</Card>

				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
			<Modal show={openeditGON}
				onHide={() => setOpeneditGON(!openeditGON)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print Party Form</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					{
						dataforprint?.invoiceIsGenerated ? "GON Already Converted to Invoice It cant be Edited again " : <></>
					}

					<Card className='p-4'>
						<EditGON data={dataforprint} />
					</Card>

				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>


		</div>
	)
}