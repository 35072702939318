
import React, { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup, FormText } from "react-bootstrap";
import { faL, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import VendorToProduct from '../../mapping/VendorToProduct';
import { useSelector, useDispatch } from 'react-redux'
import AddBatch from '../stockadjustment/AddBatch';
import { getVineoProductData } from '../../../../redux/features/services/serviceHelper';
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_EDITPAYMENT_SELECTED_PURCHASEADBILL, VINEOFORM_EDIT_SELECTED_ADDBILL, VINEOFORM_SETTLE_SELECTED_ADDBILL, VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID, VINEO_GETALL_POSITIVE_DEBITNOTE_AVAILABLE } from '../../../../constants/Urlforvineo';
import moment from 'moment';
import { render } from '@testing-library/react';
import http from '../../../../constants/AxiosConfig';

function PurchaseSettle(props) {

  const { data } = props
  const [billpaymentdate, setBillpaymentdate] = useState(new Date())
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  
  const [debitnotedebit, setDebitnotedebit] = useState()
    const [selectedDebitNote, setSelectedDebitNote] = useState([])

  const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

  const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    submitForm(data)
  }

  const getdebitNoteavaialblefromserver = () => {

    console.log("Called in To get Debit Notes with positive Value ")
    http.post(BASEURL_JWTAUTH + VINEO_GETALL_POSITIVE_DEBITNOTE_AVAILABLE).then(
        (res) => {
            console.log(res.data)
            if (res.data?.[0]?.debitnoteAmount > '0') {
                notify('Debit Note Amount is Available - ' + res?.data?.[0]?.debitnoteAmount)
                setDebitnotedebit(res.data);
                console.log(debitnotedebit);
                console.log("DebitNote Above");

            }
            else
            notifyError("Debit Note Amount is Not Available For Selected Customer- ")

        },
        (err) => {


          notifyError("Error While Getting Debit amount Operation From Server ")
        }
    )

}
  const submitForm = (data) => {
    console.log("Connecting Server to Edit Bill")
    // api call
    http.post(BASEURL_JWTAUTH + VINEOFORM_EDITPAYMENT_SELECTED_PURCHASEADBILL, data).then(
      (res) => {
        console.log(res.data)
        if (res.data.errorCode == '0000') {
          notify('Edit Purchase Operation Successful with Message as- ' + res.data.additionalInfo)
          setDisabledSubmit(false)
        }
        else
        notifyError("Error While Edit Purchase Bill Operation From Server with Message as- " + res.data.additionalInfo)
        //  console.log(res.data)
      },
      (err) => {


        notifyError("Error While Edit Purchase Bill Operation From Server ")
      }
    )
  }


  return (
    <>    {
      data.receiptIsGenerated ? <div>Receipt is Generated You Can not edit this bill</div> :

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={2}> PurchaseBillEdit Number:-{data?.vineoaddUniquePurchaseNumberWithPrefix}</Col>
              <Col md={3}> Firm Name:-{data?.vineoAddPurchaseFirm?.vineoFirmFirmName} <br />
                GST:-{data?.vineoAddPurchaseFirm?.vineofirmGst?.vineoGstNumber}
              </Col>
              <Col md={3}>Party Name:-{data?.vineoVendorAccount?.vineoVendorAccountName} <br />
                GST:-{data?.vineoVendorAccount?.vineoVendorGstNumber}
              </Col>
              <Col md={4}>
                <Row>
                  <Col><FormGroup>
                    <Form.Text >
                      Payment  Date
                    </Form.Text>
                    <DatePicker className="form-control"
                      selected={billpaymentdate}
                      onChange={setBillpaymentdate}
                      name="vineoPaymentDate"
                      dateFormat="dd-MM-yyyy"
                      ref={setValue("vineoPayment.vineoPaymentDate", moment(billpaymentdate).format(DATEFORMAT))}
                    />
                  </FormGroup></Col>
                  <Col>
                    <Form.Text >
                      Payment  Mode
                    </Form.Text>
                    <Form.Select className="form-control"
                      {...register("vineoPayment.paymentType")} required>
                      <option value="CASH">Cash</option>
                      <option value="CHEQUE">Cheque</option>
                      <option value="ONLINE">Online</option>
                      <option value="CREDIT">Credit</option>
                      <option value="PARTIAL">Partial</option>
                    </Form.Select></Col>
                </Row>


                {watch(`vineoPayment.paymentType`) == 'CHEQUE' ? <>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Form.Text >
                          CHK Number
                        </Form.Text>
                        <Form.Control size='sm' className="form-control" {...register("vineoPayment.chequeNumber")} required />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Form.Text >
                          BankName
                        </Form.Text>
                        <Form.Control size='sm' className="form-control" {...register("vineoPayment.bankName")} required />
                      </FormGroup></Col>
                  </Row>

                </> : <></>}

                {watch(`vineoPayment.paymentType`) == 'ONLINE' ? <>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Form.Text >
                          UPI ID/ Bank Name
                        </Form.Text>
                        <Form.Control size='sm' className="form-control" {...register("vineoPayment.upiidName")} required />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Form.Text >
                          onlineTransactionNumber
                        </Form.Text>
                        <Form.Control size='sm' className="form-control" {...register("vineoPayment.onlineTransactionNumber")} required />
                      </FormGroup></Col>
                  </Row>

                </> : <></>}
                <FormGroup>
                  <Form.Text >
                    Amount Paid / Total Purchase Bottom Amnt:- {data?.vineoAddPurchaseBottomAmt}
                  </Form.Text>
                  <Form.Control size='sm' className="form-control" {...register("vineoPayment.actualPaymentAmountPaid")} required />
                  <Row className="mt-2">
                  <InputGroup>

<Typeahead
    id="DebitnoteTypehead"
    onChange={setSelectedDebitNote}
    disabled={debitnotedebit ? false : true}
    options={debitnotedebit}
    placeholder="Check DebitNote"
    selected={selectedDebitNote}
    labelKey={debitnotedebit => `${debitnotedebit.debitnoteAmount.toFixed(2)}`}

    renderMenuItemChildren={(option) => (
        <div key={option.id}>
            <div>
                <small>Amount:  {`${option?.debitnoteAmount.toFixed(2)}`}</small>

                <small> -DRN: {`${option?.debitNoteIssuedonPurchaseBillNumber}`}</small>
            </div>
        </div>
    )}
    ref={() => {

        { setValue(`vineoPayment.vineoDeditNote`, selectedDebitNote[0]) }
    }}
/>
<Button variant="primary"
    onClick={() => { getdebitNoteavaialblefromserver() }} >
    <FontAwesomeIcon icon={faRotate} />
</Button>


</InputGroup>

                  </Row>
                </FormGroup>
              </Col>
            </Row>
            {setValue(`ultiVineoAddPurchaseUniqeId`, data?.ultiVineoAddPurchaseUniqeId)}

            <Card className='p-4'>
              <table className="table" >
                <thead className="thead-dark" cellSpacing="0" cellPadding="0">
                  <tr>
                    <th scope="col" width="1%"></th>
                    <th scope="col" width="15%">Item Name </th>
                    <th scope="col" width="2%">Batch</th>
                    <th scope="col" width="6%">Unit</th>
                    <th scope="col" width="6%">D.Rate</th>
                    <th scope="col" width="6%">Actual Qty</th>
                    <th scope="col" width="4%">RetDamage</th>
                    <th scope="col" width="4%">RetSellable</th>
                    <th scope="col" width="3%">GST(C+S)</th>
                    <th scope="col" width="4%">Net</th>

                  </tr>
                </thead>
                <tbody >
                  {data?.vineoAddPurchaseItemList?.map((item, index) => {
                    return (

                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.vineoProduct.vineoProductName}</td>
                        <td>{item.vineoBatch.vineoBatchNumber}</td>
                        {console.log("Unit value is " + item.vineoPurchaseItemUnit)}
                        <td>{item.vineoPurchaseItemUnit}</td>
                        <td>{item.vineoItemEffectiverateAfterDsicount}</td>
                        <td>{item.vineoAddPurchaseProductBatchQty}</td>
                        <td>{item.vineoAddBillProductDamagedReturnedBatchQtyinPCS} </td>
                        <td>  </td>
                        <td>{item.vineoAddPurchaseItemTotalGSTAmount}</td>

                        <td>{item.vineoAddPurchaseItemNetAmount}</td>
                        {setValue(`vineoAddBillItemList[${index}].ultiVineoAddBillItemUniqeId`, item.ultiVineoAddBillItemUniqeId)}
                      </tr>

                    )
                  })
                  }
                </tbody>
              </table>
            </Card>


            <center>
              <input className="btn btn-primary m-2"  disabled={disabledSubmit} type="submit" value="Submit" />
              <input className="btn btn-primary m-2" type="button" value="Print" />
              <Button className="btn btn-danger m-2" type="button" onClick={() => { reset() }} value="Cancle">Cancel</Button>
            </center>
          </form>

          {console.log(data)}
          {console.log(data.receiptIsGenerated)}

         

        </div>
    }
    </>


  )
}

export default PurchaseSettle