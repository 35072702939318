import { useState } from "react";
import '../../sb-admin-2.min.css'
import AuthUser from "../../constants/AuthUser";
import SliderForLoginPage from "./SliderForLoginPage";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  BASEURL_JWTAUTH,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD_GENERATEOTPTOKEN,
  FORGOT_PASSWORD_TOKEN,
  GET_JWT_TOKEN,
  HEALTHCHECK,
} from "../../constants/Urlforvineo";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import backgroundImage from "../../images/dffrontpagebanner1.jpg";
import { FormGroup, Label, Input, Button, Form, Row, Col } from "react-bootstrap";
import { getVineoFirmData } from "../../redux/features/services/serviceHelper";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const { http, setToken } = AuthUser();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [otpDisabled, setOtpDisabled] = useState(false);
  // const { register, handleSubmit } = useForm();
  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const notifyWarning = (message) => toast.warning(message);

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      vineoRegisteredClientName: "",
      password: "",
      username: "",
      //   isDeveloper: true
    },
  });

  const onSubmit = (data) => {
    submitForm(data);
  };

  // const testserverdeployment = () => {
  //   axios.get(BASEURL_JWTAUTH + HEALTHCHECK).then(
  //     (res) => {
  //       console.log(res.data);
  //       notify(res.data);
  //     },
  //     (errr) => {
  //       console.log(errr);
  //       notifyError("Error During login & Error code is - " + errr);
  //     }
  //   );
  // };
  const submitForm = (data) => {
    axios.post(BASEURL_JWTAUTH + CHANGE_PASSWORD, data).then(
      (res) => {
        // console.log(res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName)
        (res?.data?.errorCode === "0000") ?
        notify("Password Change Success ")
        : notifyError("Error During Password Change pls connect with us on 080-62182173")
        (res?.data?.errorCode === "0000") ?  this.props.closeModal(): <></>
      
      },
      (errr) => {
        console.log(errr);
        notifyError("Error During Password Change pls connect with us on 080-62182173")
      }
    );
  };
  const styles = {
    backgroundImage: `url(${backgroundImage})`,
    height: "88vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",


  };
  const forgotPassword = (data) => {

    if (!data || (data?.length < 4)) {
      notifyError("Enter Your Username In Username Field ")
    }
    else {

      axios.post(BASEURL_JWTAUTH + FORGOT_PASSWORD_GENERATEOTPTOKEN + "/" + data).then(
        (res) => {
          // console.log(res.data.vineoRegisteredClientDetails.uniqueidForVineoclientName)
          console.log(res);
          console.log(res?.data?.data?.allowed);
          setOtpDisabled(true);
          (res?.data?.errorCode === "0000") ?
            notify("OTP sent to Registered Phone Number ")
            : notifyError("Error During OTP Reset pls connect with us on 080-62182173")

        },
        (errr) => {
          console.log(errr);
          notifyError("Error During Reset Password Pls connect on 080-62182173");
        }
      );
    }
  }

  return (
    <div>
      {/* <a className="Nav-brand-logo justify-content-end " href="www.google.com">

Instagram

</a> */}


      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row p-5" >

                  <div className="text-center">
                    <img className=" mb-2" width={60} src="img/undraw_rocket.svg" alt="..." /></div>
                  <div className="text-center m-4">
                    <h1 className="h4 text-gray-900 mb-4">Forgot Password</h1>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <FormGroup className="mt-1">
                                                <Form.Control
                                                    className="form-control form-control-user"
                                                    placeholder="Enter Registered Client ID "
                                                    {...register("vineoRegisteredClientName")}
                                                    required
                                                />
                                            </FormGroup> */}
                    <Row>
                      <Col>  <FormGroup className="mt-2">
                        <Form.Control
                          className="form-control form-control-user"
                          placeholder="Enter Your Username / Mobile Number"
                          {...register("username")}
                          required
                        />
                      </FormGroup>
                      </Col>
                      <Col>
                        <Button variant="primary mt-2" disabled = {otpDisabled} onClick={() => forgotPassword(watch("username"))}>Send OTP</Button>

                      </Col>
                    </Row>
                    <Row>
                      <Col><FormGroup className="mt-2">
                        <Form.Text>
                          Enter Verification OTP from You Phone
                        </Form.Text>
                      <Form.Control
                        className="form-control form-control-user"
                        type="number"
                        placeholder="Enter Your Verification OTP"
                        {...register("otp", { minLength: 6, maxLength: 6 })}
                        required
                      />
                    </FormGroup>
                    
                    <FormGroup className="mt-2">
                    <Form.Text>
                          Enter New Password 
                        </Form.Text>
                      <Form.Control
                        className="form-control form-control-user"
                        type="password"
                        placeholder="Enter New Password "
                        {...register("password") }
                        required
                      />
                    </FormGroup></Col>
                    <Col></Col>
                    </Row>

                    <FormGroup className="mt-2 text-center">
                      <input className="btn btn-primary btn-user btn-block" type="submit" name="Submit" />
                    </FormGroup>


                    <FormGroup className="text-center">
                      <br />
                      <img src="bannerlogin.jpg" width={300} alt="logo" />
                    </FormGroup>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Powered By Vineo</span>
          </div>
        </div>
      </footer>


    </div>
  );
}