import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFileText, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { getVineoFirmData, getVineoGodownData, getVineoVanMasterData } from '../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEO_CONVERT_UNIVERSALEINVOICE_FROMCSV, VINEO_IMPORT_PARTY_FROM_CSV_FILE } from '../../constants/Urlforvineo';
import { Typeahead } from 'react-bootstrap-typeahead';
import http from '../../constants/AxiosConfig';

export default function ConvertTOEinvoiceJSONUniversal() {
    useEffect(() => {

    }, []);
    const dispatch = useDispatch();
    const [string, setString] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [selectedGodown, setSelectedGodown] = useState([])
    const [openEwayJsonModal, setOpenEwayJsonModal] = useState(false);
    const [jsonValueforewaybill, setJsonValueforewaybill] = useState([]);
    const [showtextOnly, setShowtextOnly] = useState(false);
    const { setValue, handleSubmit, control, reset, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data.vineofile[0]);
        // submitForm(data.vineofile[0])
    }
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const vineoGodowndata = useSelector((state) => state.vineoData.vineogodowndata)



    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const [defaultPincode, setDefaultPincode] = useState('');
    const [defaultLocation, setDefaultLocation] = useState('');


    const handleFileChange = (e) => {
        console.log("FIles Selected")
        setFile(e.target.files[0]);

    }
    useEffect(() => {
        // Set the initial values for defaultPincode and defaultLocation
        setValue('defaultpincode', defaultPincode);
        setValue('defaultloc', defaultLocation);
    }, [defaultPincode, defaultLocation]);


    const exportToJson = (dataObjToWrite) => {
        const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
        const link = document.createElement("a");

        link.download = "einvoce.json";
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        link.dispatchEvent(evt);
        link.remove()
    };

    const handleUpload = (e) => {
        setIsLoaded(true);

        e.preventDefault();
        const formData = new FormData();
        console.log(defaultLocation);
        console.log(defaultPincode);
        formData.append('vineofile', file);
        formData.append('defaultpincode', defaultPincode);
        formData.append('defaultloc', defaultLocation);


        console.log("formData", formData)
        console.log("file size ", (file?.size < 4000000))

        if (file?.size < 4000000) {
            http.post(BASEURL_JWTAUTH + VINEO_CONVERT_UNIVERSALEINVOICE_FROMCSV, formData)
                .then(

                    (res) => {
                        // console.log(res.data)
                        setIsLoaded(false)
                        // console.log(JSON.stringify(res.data))
                        setJsonValueforewaybill(res.data)
                        setOpenEwayJsonModal(true);


                    },
                    (err) => {
                        notifyError('Error While Generating JSON ')
                        setIsLoaded(false)
                    }
                )
                .catch((err) => {

                    notifyError("Error While Importing Data" + err)

                });

        }
        else {
            notifyError("File Size is more than 4MB Please Select Lower Size File");

        }

    }


    return (
        <div>
            <h3 className='justify-content-center m-1'>Upload CSV File For E-Invoice JSON</h3>
            <div className="row justify-content-left ">
                <Row>

                    <Row>
                        <Col md={6}>
                            <div >
                                <Card className='p-4 mt-4'>


                                    <div>
                                        <form onSubmit={handleUpload}>

                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>Select CSV File AS per Format * </Form.Label>
                                                <Form.Control type="file" onChange={handleFileChange} />
                                            </Form.Group>

                                            <FormGroup>
                                            <Form.Text>
                                            Shiping/Buyer Default Pincode
                                            </Form.Text>

                                            <Form.Control className="form-control" minLength={6} onChange={(e) => { setDefaultPincode(e.target.value) }} required>
                                              
                                            </Form.Control>
                                            </FormGroup>
                                            
                                                {console.log("picode", defaultPincode)}
                                                <FormGroup>
                                            <Form.Text>
                                           Shipping/Buyer Default Location
                                            </Form.Text>

                                            <Form.Control className="form-control" minLength={3} onChange={(e) => { setDefaultLocation(e.target.value) }} required>
                                              
                                            </Form.Control>
                                            </FormGroup>
                                           

                                            {/* <input type="file" onChange={handleFileChange} /> */}
                                            <Button disabled={isLoaded} type="submit">  {isLoaded ? 'Uploading' : 'Upload'}
                                            
                                            
                                            </Button>

                                        </form>
                                        <p>{message}</p>
                                    </div>

                                </Card>
                            </div>



                        </Col>
                    </Row>

                </Row>

            </div>
            <Modal show={openEwayJsonModal}
                onHide={() => { setOpenEwayJsonModal(!openEwayJsonModal) }}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Copy JSON From Here </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Button variant="primary m-1" onClick={() => setShowtextOnly(!showtextOnly)}><FontAwesomeIcon icon={faFileText} /></Button>
                        <Button variant="primary m-1" onClick={() => exportToJson(jsonValueforewaybill)}><FontAwesomeIcon icon={faFileText} />Export To JSON</Button>

                    </div>
                    <Card className='p-4'>
                        {showtextOnly ?
                            <pre>{JSON.stringify(jsonValueforewaybill, null, 2)}</pre> :
                            <>{JSON.stringify(jsonValueforewaybill, null, 2)}</>}
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    )

}