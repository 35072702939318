import React from "react";
import ReactToPrint from "react-to-print";
import '../../../printFormat.css';
import QRCode from 'qrcode.react';
import PropTypes from "prop-types";
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import { IMAGE_RETRIVE } from "../../../constants/Urlforvineo";

var converter = require('number-to-words');

class ComponentToPrint extends React.Component {

  render() {
    return (
      <>
        <div className="">
          <center> Party Form Print </center>
          <br />
          <div id='first-row'>
           
            <div >
            
              CUSTOMER APPROVED:<strong> {this.props.data?.partyApproved? "YES" : "NO"}</strong>
              <br /> 
              CUSTOMER CREDIT APPLICABLE:<strong> {this.props.data?.creditPaymentApplicable? "YES" : "NO"}</strong> 
              <br />
              CUSTOMER:<strong> {this.props.data?.vineoCustomerAccountName}</strong>
              <br />
              AREA:<strong> {this.props.data?.vineoArea?.vineoAreaName}</strong>
              <br />
              Opening Date: {this.props.data?.vineoCustomerAccountopeningDate}
              <br />
              Address: {this.props.data?.vineoCustomerAccountaddress}
              <br />
              Pin:  {this.props.data?.vineoCustomerAccountpincode}
              <br />
              Contact No: {this.props.data?.vineoCustomerAccountmob}<br />
              State Code:   {this.props.data?.vineoGst?.vineoGstNumber.substring(0, 2)}
              <br />
              GSTIN:  {this.props.data?.vineoGst?.vineoGstNumber}
              <br />
              Address From Mobile App: {this.props.data?.address}
              <br />
              Longitude: {this.props.data?.longitude}
              <br />
               latitude: {this.props.data?.latitude}
              <br />
            </div>
           
        
          </div>
          <div>
              Shop Photo URL : {this.props.data?.shopphotourl}

              <img src={IMAGE_RETRIVE+this.props.data?.shopphotourl} height='500' alt='Shop Image' style={{ display: 'block' }} />
            </div>
         
        </div >


      </>
    );
  }
}

class PartyPrint extends React.Component {

  constructor() {
    super();
    this.state = {
      count: 0
    };

  }

 

  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => this.componentRef}
          pageStyle={`
                                @page {
                        margin: 0.5cm 0.5cm;
                    }
                
                `}

        />
        

        <ComponentToPrint data={this.props.data} ref={el => (this.componentRef = el)} />
      </div>
    );
  }
}

export default PartyPrint;