import axios from 'axios';
import { v4 } from 'uuid';
// Next we make an 'instance' of it
const httpwithmultipartdata = axios.create({
// .. where we make our configurations
headers: {
    "Content-type": "multipart/form-data",
    "X-uniqueidForVineoclientName": `${JSON.parse(sessionStorage.getItem("uniqueidForVineoclientName"))}`,
    "X-vineoRegisteredClientName": `${JSON.parse(sessionStorage.getItem("vineoRegisteredClientName"))}`,
    // "Authorization": `Bearer TestValuer }`
    "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
   "x-appCorrelationid": v4()

}

});


// axios.interceptors.request.use((config) => {
//     config.headers['x-appCorrelationid'] = v4();
//     return config;
//   });


httpwithmultipartdata.interceptors.response.use(res => {
    // this will executed on every response 
    return res
  })

export default httpwithmultipartdata;