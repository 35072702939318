import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm, useWatch } from "react-hook-form";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getVineoGodownData, getVineoAccountCustomerMasterMenu, getVineoProductData, getVineoSchemaMasterData, getVineoCompanyData, getVineoFirmData } from '../../../../redux/features/services/serviceHelper';
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import {
    FormGroup, Label, Input, Modal,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Table
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_UPDATE_SELECTED_ADDBILL, VINEOFORM_UPDATE_SELECTED_ADDBILL_WITHAUTOIMPORT } from '../../../../constants/Urlforvineo';
import AreaToSalesman from '../../mapping/AreaToSalesman';
import { AddBillItemAddline } from './AddBillItemAddline';
import http from '../../../../constants/AxiosConfig';


let totalDiscountPercentagevalue = 0;


export default function AddSalesBillingAutoPurcahseImport() {
    useEffect(() => {
        dispatch(getVineoCompanyData());
        dispatch(getVineoGodownData());
        dispatch(getVineoAccountCustomerMasterMenu());
        dispatch(getVineoFirmData())
        // dispatch(getVineoProductData());
    }, []);
    const dispatch = useDispatch()
    const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    // const [open, setOpen] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const [selectedFirm, setSelectedFirm] = useState([])
    const [companySelections, setCompanySelections] = useState([]);
    const [godownSelections, setGodownSelections] = useState([]);
    const [grossBillValue, setGrossBillValue] = useState(0);
    const [billdate, setBilldate] = useState(new Date())
    const [billduedate, setBillduedate] = useState(new Date())
    const [selectedVendor, setSelectedVendor] = useState(new Date())
    const [productdamaged, setProductdamaged] = useState(true);


    const [totalDiscountPercentage, setTotalDiscountPercentage] = useState(totalDiscountPercentagevalue)
    // const count = useSelector((state) => state.vineoData.value)
    const vineogodowndataTypeahead = useSelector((state) => state.vineoData.vineogodowndata)
    const vineocompany = useSelector((state) => state.vineoData.vineocompany)
    const vineoPartydata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();

    const onSubmit = (data) => {
        // console.log(JSON.stringify(data));
        console.log((data));
        submitForm(data)
    }
    const navigate = useNavigate();

    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSchemeUniqeId": "Working", "vineoCompanySchemeNumber": "Click Add To Load Scheme" }])
    const isvineoschemamasterdataLoaded = useSelector((state) => state.vineoData.isvineoschemamasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [openMapAreaToSalesMan, SetOpenMapAreaToSalesMan] = useState(false);
    // useEffect(() => {
    //     setformDetails({ ...formDetails, gender: "F", enabled: "true", accountLocked: "false", role: "admin" })
    //     console.log(formDetails)
    // }, []);
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const getdatafromserver = () => {

        console.log("Called to get  Data ")

        dispatch(getVineoSchemaMasterData())

        if (Object.keys(vineodata).length > 0) {
            isvineoschemamasterdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoSchemeUniqeId": "Working", "vineoSchemeName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }

    const submitForm = (data) => {
        console.log("Connecting Server to Create Bill")
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ADDBILL_WITHAUTOIMPORT, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorMessage == 'SUCCESS') {
                    notify('Add Bill Operation Successful with Deatils=' + res.data.errorCode)

                }
                else
                    notify("Error While Add Bill Operation From Server with Message as " + res.data.errorCode)
                //  console.log(res.data)

            },
            (err) => {


                notify("Error While Add Bill Operation From Server ")
            }
        )
    }


    return (
        <div>

            <div>
                <div className="p-2 mb-2 bg-primary text-white">
                    <center>
                        <h5 className='justify-content-center m-1'>Add Sales Auto Purchase Import- Billing Firm Name :-{(selectedFirm?.[0]?.vineoFirmFirmName)} GST:- {(selectedFirm?.[0]?.vineofirmGst?.vineoGstNumber)} </h5>
                    </center>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <div>
                            <Card className='p-4'>
                                <Row>
                                    <Col md={3}>
                                        <Form.Text >Select Firm</Form.Text >
                                        <InputGroup>
                                            <Typeahead
                                                id="FirmNameTypehead"
                                                onChange={setSelectedFirm}
                                                options={vineofirmdata}
                                                placeholder="Choose Firm Name..."
                                                selected={selectedFirm}

                                                labelKey={"vineoFirmFirmName"}
                                            />
                                            <Button variant="primary"
                                                onClick={() => { dispatch(getVineoFirmData()); }} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillFirm", selectedFirm)}
                                        </InputGroup>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Text >Party Name GST:-</Form.Text >
                                        {(selectedCustomer?.[0]?.vineoGst?.vineoGstNumber)}

                                        <InputGroup>
                                            <Typeahead
                                                disabled={selectedFirm[0] ? false : true}
                                                id="PartyNameTypehead"
                                                onChange={setSelectedCustomer}
                                                options={vineoPartydata}
                                                placeholder="Choose Party Name With ID"
                                                selected={selectedCustomer}
                                                labelKey={"vineoCustomerAccountName"}
                                                renderMenuItemChildren={(option) => (
                                                    <div>
                                                      <div>
                                                        <small>{option.vineoCustomerAccountName}</small>
                                                        <small>:{option?.uniqueidofrefreedClientNameAutoImport}</small>
                                                        </div>
                                                    </div>
                                                  )}
                                            />
                                            <Button variant="primary"
                                                onClick={() => { dispatch(getVineoAccountCustomerMasterMenu()); }} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)}
                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup >
                                            <Form.Text >Company Name</Form.Text >
                                            <Typeahead
                                                id="companyNameTypeahead"
                                                labelKey="companyName"
                                                onChange={setCompanySelections}
                                                // onClick={console.log(selected[0])}
                                                multiple
                                                options={vineocompany}
                                                placeholder="Choose Company Name..."
                                                selected={companySelections}

                                            />
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillcompanyName", companySelections)}
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillSalesman", selectedCustomer[0]?.vineoArea?.salesman)}
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillArea", selectedCustomer[0]?.vineoArea)}
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Form.Text >Godown Name</Form.Text >
                                            <Typeahead
                                                id="godownNameTypeahead"
                                                labelKey="vineoGoDownName"
                                                onChange={setGodownSelections}
                                                multiple
                                                options={vineogodowndataTypeahead}
                                                placeholder="Choose Godown."
                                                selected={godownSelections}
                                            />
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillGoDownName", godownSelections)}
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Narr
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleNarr")} />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <Form.Text>
                                            salesman
                                        </Form.Text>

                                        <InputGroup>
                                            <Form.Control className="form-control" required
                                                defaultValue={selectedCustomer[0]?.vineoArea?.salesman?.nameofUser ? selectedCustomer[0]?.vineoArea?.salesman?.nameofUser : (``)} />
                                            <Button variant="info" onClick={() => { SetOpenMapAreaToSalesMan(true) }}>
                                                +
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Area
                                        </Form.Text>
                                        <Form.Control className="form-control" required
                                            defaultValue={selectedCustomer[0]?.vineoArea?.vineoAreaName ? selectedCustomer[0]?.vineoArea?.vineoAreaName : (``)} />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            LastBillNo-{(selectedFirm?.[0]?.vineoaddBillPrefix)}{
                                                (selectedFirm?.[0]?.vineoaddLastBillUserBillNumber) ?
                                                    (selectedFirm?.[0]?.vineoaddLastBillUserBillNumber) : selectedFirm?.[0]?.vineoaddBillUserBillNumber
                                            }
                                        </Form.Text>
                                        <Form.Control className="form-control" placeholder={"Auto Generated?"} {...register("dataforaddsaleBill[0].vineoAddSaleUserBillNumber")} />

                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Form.Text >
                                                Bill  Date
                                            </Form.Text>
                                            <DatePicker className="form-control"
                                                selected={billdate}
                                                onChange={setBilldate}
                                                name="vineoBillDate"
                                                dateFormat="dd-MM-yyyy"
                                                ref={setValue("dataforaddsaleBill[0].vineoAddSaleBillDate", moment(billdate).format(DATEFORMAT))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Due Date
                                        </Form.Text>

                                        <DatePicker className="form-control"
                                            selected={billduedate}
                                            onChange={setBillduedate}
                                            name="vineoBillDate"
                                            dateFormat="dd-MM-yyyy"
                                            ref={setValue("dataforaddsaleBill[0].vineoAddSaleBillduedate", moment(billduedate).format(DATEFORMAT))}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Bill Type
                                        </Form.Text>
                                        <Form.Select className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillType")} required>
                                            <option value="CREDIT">Credit</option>
                                            <option disabled={true} value="CASH">Cash</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col md={2}>
                                        <Form.Text>
                                            Sale Type
                                        </Form.Text>
                                        <Form.Select className="form-control" onSelect={() =>
                                            watch(`dataforaddsaleBill[0].transactionIsReturnType`) ?
                                                setProductdamaged(true) : setProductdamaged(false)
                                        }
                                            {...register("dataforaddsaleBill[0].transactionIsReturnType")} required>
                                            <option value="false">Sale</option>
                                            <option value="true">Return</option>
                                        </Form.Select>
                                    </Col>


                                    {
                                        productdamaged ?
                                            <Col md={2}>
                                                <Form.Text>
                                                    Product
                                                </Form.Text>
                                                <Form.Select className="form-control" disabled={false} {...register("dataforaddsaleBill[0].ReturnProductsareDamaged")} required>
                                                    <option value="false">Sellable</option>
                                                    <option value="true">Damaged</option>
                                                </Form.Select>
                                            </Col> :
                                            <></>
                                    }

                                </Row> */}

                            </Card>
                        </div>
                        <div>

                        </div>
                    </Row>
                    {/* {setTotalDiscountPercentage(totalDiscountPercentagevalue)} */}

                    {console.log(totalDiscountPercentagevalue + "From Parent ")}
                    <div><AddBillItemAddline
                        totalDiscountPercentage={totalDiscountPercentagevalue

                        }
                        setGrossBillValue={setGrossBillValue} register={register}
                        setValue={setValue} control={control} getValues={getValues}
                        watch={watch} dataforaddsaleBill={watch(`dataforaddsaleBill`)} /></div>
                    <center>
                        <div>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Net Amt
                                        </Form.Text>
                                        <Form.Control disabled={true} value={grossBillValue} size='sm' className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillNetAmt")} required />
                                    </FormGroup>

                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Star Disc
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillStarDiscPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillStarDisc")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillStarDiscPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            cGST Amt
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true} defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillCgstAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Surch
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSurchPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetSurch")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetSurchPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Display
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillDisplayPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillDisplay")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Display%
                                        </Form.Text>
                                        <Form.Control size='sm'
                                            defaultValue={0}
                                            className="form-control" type="Number" {...register("dataforaddsaleBill[0].vineoAddSaleBillDisplayPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            SchmAmt
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillSchemeAmtPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillSchemeAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            SchmAmt%
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillSchemeAmtPercentage")} required />
                                    </FormGroup>
                                </Col>



                            </Row>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Rounding
                                        </Form.Text>
                                        <Form.Control size='sm' defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRoundingAmt")} required />
                                    </FormGroup>
                                </Col>

                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Cash Disc
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCashDiscountPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillCashDiscount")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillCashDiscountPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            sGST Amt
                                        </Form.Text>
                                        <Form.Control disabled={true} size='sm' className="form-control" defaultValue={0} {...register("dataforaddsaleBill[0].vineoAddSaleBillsGSTAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Sch Disc
                                        </Form.Text>
                                        <Form.Control disabled={true} size='sm'
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSchDiscPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetSchDisc")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetSchDiscPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Coupon
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCouponPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillCoupon")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Coupon%
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillCouponPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Form.Text >
                                            Total Discount =
                                        </Form.Text>
                                        <Form.Text >
                                           
                                           {totalDiscountPercentagevalue}

                                        </Form.Text >
                                        <Form.Text >
                                            %
                                        </Form.Text>

                                    </FormGroup>
                                </Col>




                            </Row>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Sys.Adjust
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true} defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillSchemeAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add Other
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddOtherPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillAddOther")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="Number" size='sm' defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillAddOtherPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Sales Return
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            defaultValue={0}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillSalesReturn")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Cd Disc
                                        </Form.Text>
                                        <Form.Control disabled={true} size='sm'
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetCdDiscPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"         {...register("dataforaddsaleBill[0].vineoAddSaleBillRetCdDisc")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="Number" size='sm' defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetCdDiscPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add/Less
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddLessPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillAddLess")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add/Less%
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillAddLessPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button className="btn btn-primary m-2" onClick={() => console.log("Calcualting values ")} value="RE-Calcualte" >
                                        RE-Calculate
                                    </Button>

                                </Col>

                            </Row>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Bottom Amt
                                        </Form.Text>
                                        <Form.Control disabled={true} value={parseFloat(grossBillValue) + parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRoundingAmt`))} size='sm' className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillBottomAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Less Other
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillLessOtherPercentage`) || `0`) * 0.01 * grossBillValue} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillLessOther")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillLessOtherPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Return GST
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true} defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillReturnGst")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            TCS Amt
                                        </Form.Text>
                                        <Form.Control disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTcsAmtPercentage`) || `0`) * 0.01 * grossBillValue}
                                            size='sm'
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTcsAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="Number" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTcsAmtPercentage")} required />
                                    </FormGroup>
                                </Col>

                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            TPR Amt
                                        </Form.Text>
                                        <Form.Control disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTprAmtPercentage`) || `0`) * 0.01 * grossBillValue}
                                            size='sm'
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTprAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %Tpr
                                        </Form.Text>
                                        <Form.Control type="Number" size='sm' defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTprAmtPercentage")} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* calcualting total discount Percentage  */}

                        </div>
                        <center>
                            <input className="btn btn-primary m-2" type="submit" value="Submit" />
                            <input className="btn btn-primary m-2" type="button" value="Print" />
                            <Button className="btn btn-danger m-2" type="button" onClick={() => { reset() }} value="Cancle">Cancel</Button>
                        </center>
                        <Row>
                        <div style={{display: "none"}}>
                        {
                                                totalDiscountPercentagevalue =
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillStarDiscPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCashDiscountPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddOtherPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillLessOtherPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSurchPercentage`)) +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSchDiscPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetCdDiscPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTcsAmtPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillDisplayPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCouponPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddLessPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTprAmtPercentage`))  +
                                                parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillSchemeAmtPercentage`)) 
                                            }
                                            </div>
                                            </Row>
                    </center>

                </form>
            </div>


            <div>
                <Modal show={openMapAreaToSalesMan}
                    onHide={() => { SetOpenMapAreaToSalesMan(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <AreaToSalesman />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { SetOpenMapAreaToSalesMan(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    )

}