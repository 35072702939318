import {
    CardTitle, CardText, Card,
    Button,
    Row,
    Col,
    FormGroup,
    Form,
    Container,
    InputGroup
} from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { Chart } from 'react-google-charts';
import ReactDatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { BASEURL_JWTAUTH, DATEFORMAT, VINEO_GETATTENDANCEBYUSER, VINEO_GET_TOP_FIVEDATAFORDASHBOARD, VINEO_MARKATTENDANCE, VINEO_REMOVEEOD } from '../../constants/Urlforvineo';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from "moment";
import DatePicker from "react-datepicker";
import { faCalendarCheck, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import http from '../../constants/AxiosConfig';
import AuthUser from '../../constants/AuthUser';
import { getVineoAddBillDatawithdate } from '../../redux/features/services/entriesHelperService';


export default function Home() {
    const vineoaddbilldatawithdate = useSelector((state) => state.vineoData.vineoaddbilldatawithdate);
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    let currentDate = new Date();
    const { setValue, reset, watch } = useForm();
    const dispatch = useDispatch()
    const [fromdate, setFromdate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    const [dashboardData, setDashboardData] = useState();
    const [billData, setBillData] = useState();
    const [todate, setTodate] = useState(new Date());
    const [isLoading, SetIsLoading] = useState(false);
    const [customerTotalBottomAmountsdata, setCustomerTotalBottomAmountsdata] = useState([]);
    const [productTotalAmountsArraydata, setProductTotalAmountsArradata] = useState([]);
    const [productTotalqtydata, setProductTotalqtydata] = useState([]);
    const [bottomAmountsdata, setBottomAmountsdata] = useState({});
    const [productTotalAmountsdata, setProductTotalAmountsdata] = useState({});
    const [areaBottomAmountsData, setAreaBottomAmountsData] = useState([]);
    const [areaWiseUniqueItemsData, setAreaWiseUniqueItemsData] = useState([]);

    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const { getToken } = AuthUser();

    useEffect(() => {
        setBillData(vineoaddbilldatawithdate)
    }, [refreshcount]);



    const getDatafromServer = (reqdata) => {
        if (getToken()) {
            {

                http.post(BASEURL_JWTAUTH + VINEO_GET_TOP_FIVEDATAFORDASHBOARD, reqdata).then(
                    (res) => {
                        console.log(res.data)
                        if (true) {
                            dispatch(getVineoAddBillDatawithdate(reqdata));
                            console.log(res.data);
                            setDashboardData(res.data)
                            SetIsLoading(false)

                        }
                        else {
                            notifyError("Empty Data Received from Server ");
                            SetIsLoading(false)
                        }
                    },
                    (err) => {

                        SetIsLoading(false)
                        console.log("Error From Server with code = " + err.data)

                    }
                )

            }
            console.log("Get data from server ", fromdate, todate)

            const bottomAmounts = {};
            const productTotalAmounts = {};
            const productTotalqty = {};
            const areaBottomAmounts = {};

            billData?.forEach((bill) => {
                const customerName = bill.vineoAddSaleBillCustomer.vineoCustomerAccountName;
                const bottomAmt = bill.vineoAddSaleBillBottomAmt;
                const areaname = bill.areaName;

                // Sum of vineoAddSaleBillBottomAmt for all bills
                if (bottomAmounts.hasOwnProperty(customerName)) {
                    bottomAmounts[customerName] += bottomAmt;
                } else {
                    bottomAmounts[customerName] = bottomAmt;
                }

                // Sum of vineoAddBilItembillvalueAftertgst for each product
                bill.vineoAddBillItemList.forEach((item) => {
                    const productName = item.vineoProduct.vineoProductName;
                    const billValueAfterTgst = item.vineoAddBilItembillvalueAftertgst;
                    const batchQty = item.vineoAddBillProductBatchQty;

                    if (productTotalAmounts.hasOwnProperty(productName)) {
                        productTotalAmounts[productName] += billValueAfterTgst;
                        productTotalqty[productName] += batchQty;
                    } else {
                        productTotalAmounts[productName] = billValueAfterTgst;
                        productTotalqty[productName] = batchQty;
                    }
                });
                if (areaBottomAmounts.hasOwnProperty(areaname)) {
                    areaBottomAmounts[areaname] += bottomAmt;
                } else {
                    areaBottomAmounts[areaname] = bottomAmt;
                }
            });

            const customerTotalBottomAmounts = [];
            const productTotalAmountsArray = [];
            const productTotalqtyArray = [];
            const areaBottomAmountsArray = [];
            const areaWiseUniqueItems = {};
            const areaWiseUniqueItemsArray = [];

            // Generate the array of total bottom amounts and corresponding customer names
            for (const [customerName, totalBottomAmt] of Object.entries(bottomAmounts)) {
                const customerTotalBottomAmount = {
                    [customerName]: totalBottomAmt.toFixed(2) // Rounding to 2 decimal places
                };
                customerTotalBottomAmounts.push(customerTotalBottomAmount);
            }

            // Generate the array of total product amounts
            for (const [productName, totalAmount] of Object.entries(productTotalAmounts)) {
                const productTotalAmount = {
                    [productName]: totalAmount.toFixed(2) // Rounding to 2 decimal places
                };
                productTotalAmountsArray.push(productTotalAmount);
            }

            // Generate the array of total product quantities
            for (const [productName, totalQty] of Object.entries(productTotalqty)) {
                const productTotalQty = {
                    [productName]: totalQty.toFixed(2) // Rounding to 2 decimal places
                };
                productTotalqtyArray.push(productTotalQty);
            }
            for (const [areaname, totalBottomAmt] of Object.entries(areaBottomAmounts)) {
                const areaTotalBottomAmount = {
                    [areaname]:
                        totalBottomAmt.toFixed(2) // Rounding to 2 decimal places
                };
                areaBottomAmountsArray.push(areaTotalBottomAmount);
            }

            billData?.forEach((bill) => {
                const areaname = bill.areaName;
                const itemsSold = bill.vineoAddBillItemList.map((item) => item.vineoProduct.vineoProductName);

                // Add itemsSold to the areaWiseUniqueItems object, ensuring unique items for each area
                if (!areaWiseUniqueItems.hasOwnProperty(areaname)) {
                    areaWiseUniqueItems[areaname] = new Set();
                }
                itemsSold.forEach((item) => {
                    areaWiseUniqueItems[areaname].add(item);
                });
            });

            // Convert areaWiseUniqueItems into an array of objects for easier chart rendering

            for (const [areaname, itemsSoldSet] of Object.entries(areaWiseUniqueItems)) {
                const uniqueItems = [...itemsSoldSet];
                const uniqueItemsData = uniqueItems.map((itemName) => ({
                    itemName,
                    quantity: billData.reduce((totalQty, bill) => {
                        return (
                            totalQty +
                            bill.vineoAddBillItemList.filter(
                                (item) => item.vineoProduct.vineoProductName === itemName
                            ).length
                        );
                    }, 0),
                }));

                // Calculate the total quantity for all unique items in this area
                const uniqueItemsCount = uniqueItemsData.reduce(
                    (totalQty, itemData) => totalQty + itemData.quantity,
                    0
                );

                areaWiseUniqueItemsArray.push({
                    areaname,
                    uniqueItems: uniqueItemsData,
                    uniqueItemsCount: uniqueItemsCount, // Sum of quantities for all unique items in this area
                });
            }


            console.log('Total Bottom Amounts:');
            console.log(customerTotalBottomAmounts);
            setCustomerTotalBottomAmountsdata(customerTotalBottomAmounts);

            console.log('Total Product Qty:');
            console.log(productTotalqtyArray);
            setProductTotalqtydata(productTotalqtyArray);

            console.log('Total Product Amounts:');
            console.log(productTotalAmountsArray);
            setProductTotalAmountsArradata(productTotalAmountsArray);

            console.log('Area Bottom Amounts:'); // Log the array of area names and total bottom amounts
            console.log(areaBottomAmountsArray);
            setAreaBottomAmountsData(areaBottomAmountsArray);
            console.log('areaWiseUniqueItemsArray:'); // Log the array of area names and total bottom amounts
            console.log(areaWiseUniqueItemsArray);
            setAreaWiseUniqueItemsData(areaWiseUniqueItemsArray);
            console.log(areaWiseUniqueItemsData);



        }
    }

    useEffect(() => {
        if (getToken) {
            dispatch(getVineoAddBillDatawithdate({
                startDate: moment(fromdate).format(DATEFORMAT),
                endDate: moment(todate).format(DATEFORMAT)
            }));
            getDatafromServer({
                startDate: moment(fromdate).format(DATEFORMAT),
                endDate: moment(todate).format(DATEFORMAT)
            });
        }
    }, []);

    return (<div>

        {!getToken() ?
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Welcome To A State of the Art Data Key -DMS </h6>
                </div>
                <div className="card-body text-center m-4">

                    <Card.Title tag="h5">
                        Vishnu Provision Stores
                    </Card.Title>
                    <Card.Text>
                        Best ERP is At Your Service Click Menu to Continue.
                    </Card.Text>
                    <Card.Text>
                        Made in India , By Indian's , For Indian's

                    </Card.Text>
                    <Card.Text>
                        CALL our Support - 080 62182173

                    </Card.Text>
                    <Button color="primary" as={Link} to={"/login"}>
                        Start
                    </Button>


                </div>
            </div> :


            <>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Welcome To A State of the Art Data Key -DMS</h6>
                    </div>
                    <div className="card-body text-center m-4">

                        <Row className="text-center m-4">
                            <Col md={3}>

                                <FormGroup className="m-2">
                                    <Form.Text >
                                        From Date
                                    </Form.Text>
                                    <DatePicker className="form-control"
                                        selected={fromdate}
                                        onChange={setFromdate}
                                        name="vineoCreateLoadBillFromDate"
                                        dateFormat="dd-MM-yyyy"
                                        ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
                                    >
                                    </DatePicker>
                                </FormGroup>

                            </Col>
                            <Col md={3}>

                                <FormGroup className="m-2">
                                    <Form.Text >
                                        To Date
                                    </Form.Text>
                                    <InputGroup>
                                        <DatePicker className="form-control"
                                            selected={todate}
                                            onChange={setTodate}
                                            name="vineoCreateLoadBillToDate"
                                            dateFormat="dd-MM-yyyy"
                                            ref={setValue("vineoCreateLoadBillToDate", moment(todate).format(DATEFORMAT))}
                                        />
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col>
                                <FormGroup className="m-2">
                                    <Form.Text className="text-muted" style={{ visibility: 'hidden' }}>
                                        Load
                                    </Form.Text>
                                    <InputGroup>
                                        <Button variant="primary" disabled={isLoading}
                                            onClick={() => {
                                                SetIsLoading(true);
                                                getDatafromServer({
                                                    startDate: moment(fromdate).format(DATEFORMAT),
                                                    endDate: moment(todate).format(DATEFORMAT)
                                                })
                                            }} >
                                            <FontAwesomeIcon icon={faRotate} />
                                        </Button></InputGroup>
                                </FormGroup></Col>

                            <Col>
                                <FormGroup className="m-2">
                                    <Form.Text className="text-muted" >
                                        Present
                                    </Form.Text>
                                    <InputGroup>
                                        <Button variant="primary"
                                            onClick={() => {
                                                http.post(BASEURL_JWTAUTH + VINEO_MARKATTENDANCE, { "status": "P" }).then(
                                                    (res) => {
                                                        console.log(res.data)
                                                        notify("Data Received  " + res.data.data.status)
                                                    },
                                                    (err) => {

                                                        console.log("Error From Server with code = " + err.data)

                                                    }
                                                )
                                            }} >
                                            PRESENT
                                        </Button></InputGroup>
                                </FormGroup></Col>
                            <Col>
                                <FormGroup className="m-2">
                                    <Form.Text className="text-muted" >
                                        Leave
                                    </Form.Text>
                                    <InputGroup>
                                        <Button variant="primary"
                                            onClick={() => {
                                                const formData = new FormData();
                                                formData.append('status', 'L');

                                                http.post(BASEURL_JWTAUTH + VINEO_MARKATTENDANCE, formData).then(
                                                    (res) => {
                                                        console.log(res.data)
                                                        notify("Data Received  " + res.data.data.status)
                                                    },
                                                    (err) => {

                                                        console.log("Error From Server with code = " + err.data)

                                                    }
                                                )
                                            }} >
                                            Leave
                                        </Button></InputGroup>
                                </FormGroup></Col>
                                <Col>
                                <FormGroup className="m-2">
                                    <Form.Text className="text-muted" >
                                        EOD
                                    </Form.Text>
                                    <InputGroup>
                                        <Button variant="primary"
                                            onClick={() => {
                                                const formData = new FormData();
                                                formData.append('status', 'EOD');

                                                http.post(BASEURL_JWTAUTH + VINEO_MARKATTENDANCE, formData).then(
                                                    (res) => {
                                                        console.log(res.data)
                                                        notify("Data Received  " + res.data.data.status)
                                                    },
                                                    (err) => {

                                                        console.log("Error From Server with code = " + err.data)

                                                    }
                                                )
                                            }} >
                                            EOD
                                        </Button></InputGroup>
                                </FormGroup></Col>
                                <Col>
                                <FormGroup className="m-2">
                                    <Form.Text className="text-muted" >
                                        XEOD
                                    </Form.Text>
                                    <InputGroup>
                                        <Button variant="primary"
                                            onClick={() => {
                                                const formData = new FormData();
                                                formData.append('status', 'EOD');

                                                http.post(BASEURL_JWTAUTH + VINEO_REMOVEEOD, formData).then(
                                                    (res) => {
                                                        console.log(res.data)
                                                        notify("Data Received  " + res.data.data.status)
                                                    },
                                                    (err) => {

                                                        console.log("Error From Server with code = " + err.data)

                                                    }
                                                )
                                            }} >
                                        XEOD
                                        </Button></InputGroup>
                                </FormGroup></Col>
                            <Col>
                                {/* <FormGroup className="m-2">
                                    <Form.Text className="text-muted" >
                                        Get Attendance
                                    </Form.Text>
                                    <InputGroup>
                                        <Button variant="primary" 
                                            onClick={() => {
                                                http.post(BASEURL_JWTAUTH + VINEO_GETATTENDANCEBYUSER+"/"+101,{
                                                    startDate: moment(fromdate).format(DATEFORMAT),
                                                    endDate: moment(todate).format(DATEFORMAT)
                                                }).then(
                                                    (res) => {
                                                        console.log(res.data)
                                                    },
                                                    (err) => {
                                
                                                        console.log("Error From Server with code = " + err.data)
                                
                                                    }
                                                )
                                            }} >
                                            <FontAwesomeIcon icon={faCalendarCheck} />Get Attendance
                                        </Button></InputGroup>
                                </FormGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col><h3>Total Sale Amount : {billData?.reduce((acc, item) => acc + item.vineoAddSaleBillBottomAmt, 0)}</h3></Col>
                            <Col><h3>Total Purchase Amount : {dashboardData?.totalPurchaseAmount}</h3></Col>
                        </Row>
                        <Row className="text-center m-2">
                            <Col md={6}>
                                <div className="card shadow mb-4">

                                    <div className="card-body text-center m-4">
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['Amount Type', 'Amount'],
                                                ['Total Sale Amount', Number(billData?.reduce((acc, item) => acc + item.vineoAddSaleBillBottomAmt, 0))],
                                                ['Total Purchase Amount', Number(dashboardData?.totalPurchaseAmount)]
                                            ]}
                                            options={{
                                                title: 'Total Sale and Purchase Amount',
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                        />

                                    </div>
                                </div>
                            </Col>
                            {console.log('Total Bottom Amounts1:')}
                            {console.log(customerTotalBottomAmountsdata)}
                            <Col md={6}>
                                <div className="card shadow mb-4">

                                    <div className="card-body text-center m-4">
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="Bar"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['customerName', 'Amount'],
                                                ...customerTotalBottomAmountsdata?.map((entry) => {
                                                    const customerName = Object.keys(entry)[0];
                                                    const totalBottomAmt = parseFloat(Object.values(entry)[0]);
                                                    return [customerName, totalBottomAmt];
                                                })
                                                    .sort((a, b) => b[1] - a[1]) // Sort in descending order based on totalBottomAmt
                                                    .slice(0, 5)
                                            ]}
                                            options={{
                                                chart: {
                                                    title: 'Top Buyers by Amount',
                                                },
                                                annotations: {
                                                    textStyle: {
                                                        fontSize: 12,
                                                        color: '#000',
                                                        auraColor: 'none'
                                                    }
                                                },
                                                bars: 'vertical',
                                                vAxis: {
                                                    format: 'decimal' // Display exact quantity without "K" notation
                                                },
                                                colors: ['#FF5733'],
                                            }}
                                            rootProps={{ 'data-testid': '2' }}
                                        />
                                    </div>
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className="card shadow mb-4">

                                    <div className="card-body text-center m-4">
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="Bar"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['productName', 'Amount'],
                                                ...productTotalAmountsArraydata?.map((entry) => {
                                                    const productName = Object.keys(entry)[0];
                                                    const totalBottomAmt = parseFloat(Object.values(entry)[0]);
                                                    return [productName, totalBottomAmt];
                                                })
                                                    .sort((a, b) => b[1] - a[1]) // Sort in descending order based on totalBottomAmt

                                            ]}
                                            options={{
                                                chart: {
                                                    title: 'Top Selling Items by Amount',
                                                },
                                                annotations: {
                                                    textStyle: {
                                                        fontSize: 12,
                                                        color: '#000',
                                                        auraColor: 'none'
                                                    }
                                                },
                                                bars: 'vertical',
                                                vAxis: {
                                                    format: 'decimal' // Display exact quantity without "K" notation
                                                },
                                                colors: ['#FF5733'],
                                            }}
                                            rootProps={{ 'data-testid': '3' }}
                                        />

                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="card shadow mb-4">
                                    <div className="card-body text-center m-4"></div>
                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="Bar"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['productName', 'Qty'],
                                            ...productTotalqtydata?.map((entry) => {
                                                const productName = Object.keys(entry)[0];
                                                const qty = parseFloat(Object.values(entry)[0]);
                                                return [productName, qty];
                                            })
                                                .sort((a, b) => b[1] - a[1]) // Sort in descending order based on totalBottomAmt

                                        ]}
                                        options={{
                                            chart: {
                                                title: 'Top Items by Quantity',
                                            },
                                            annotations: {
                                                textStyle: {
                                                    fontSize: 12,
                                                    color: '#000',
                                                    auraColor: 'none'
                                                }
                                            },
                                            bars: 'vertical',
                                            vAxis: {
                                                format: 'decimal' // Display exact quantity without "K" notation
                                            }
                                        }}
                                        rootProps={{ 'data-testid': '4' }}
                                    />
                                </div>

                            </Col>


                        </Row>
                        <Row>

                            <Col md={6}>
                                <div className="card shadow mb-4">
                                    <div className="card-body text-center m-4"></div>
                                    <Chart
                                        width={'100%'}
                                        height={'500px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['customerName', 'Amount'],
                                            ...customerTotalBottomAmountsdata?.map((entry) => {
                                                const customerName = Object.keys(entry)[0];
                                                const totalBottomAmt = parseFloat(Object.values(entry)[0]);
                                                return [customerName, totalBottomAmt];
                                            })
                                                .sort((a, b) => b[1] - a[1]) // Sort in descending order based on totalBottomAmt

                                        ]}
                                        options={{
                                            title: 'Total PartyWise Sale',
                                        }}
                                        rootProps={{ 'data-testid': '5' }}
                                    />
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="card shadow mb-4">
                                    <div className="card-body text-center m-4"></div>
                                    <Chart
                                        width={'100%'}
                                        height={'500px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['productName', 'Qty'],
                                            ...productTotalqtydata?.map((entry) => {
                                                const productName = Object.keys(entry)[0];
                                                const qty = parseFloat(Object.values(entry)[0]);
                                                return [productName, qty];
                                            })
                                                .sort((a, b) => b[1] - a[1]) // Sort in descending order based on totalBottomAmt

                                        ]}
                                        options={{
                                            title: 'Total ItemWise Sale',
                                        }}
                                        rootProps={{ 'data-testid': '5' }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>

                            {console.log("value of data here ", areaBottomAmountsData)}
                            {console.log("value of data here ", productTotalqtydata)}
                            <Col md={6}>
                                <div className="card shadow mb-4">
                                    <div className="card-body text-center m-4"></div>
                                    <Chart
                                        width={'100%'}
                                        height={'500px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['AreaName', 'Amount'],
                                            ...areaBottomAmountsData?.map((entry) => {
                                                const areaName = Object.keys(entry)[0];
                                                const Amount = parseFloat(Object.values(entry)[0]);
                                                return [areaName, Amount];
                                            })
                                            // Sort in descending order based on totalBottomAmt

                                        ]}
                                        options={{
                                            title: 'Total AreaWise Sale',
                                        }}
                                        rootProps={{ 'data-testid': '6' }}
                                    />
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="card shadow mb-4">
                                    <div className="card-body text-center m-4"></div>
                                    <Chart
                                        width={'100%'}
                                        height={'500px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['AreaName', 'Unique Items Sold'],
                                            ...areaWiseUniqueItemsData?.map((entry) => {
                                                const areaName = entry.areaname;
                                                const uniqueItemsCount = entry.uniqueItemsCount;
                                                return [areaName + " (" + uniqueItemsCount + ")", uniqueItemsCount];
                                            }),
                                        ]}
                                        options={{
                                            title: 'Total Area & ItemWise Sale',
                                        }}
                                        rootProps={{ 'data-testid': '7' }}
                                    />
                                </div>
                            </Col>
                        </Row>


                    </div>
                </div>
            </>}

    </div >

    )
}