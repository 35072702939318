import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit,faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddBillData, getVineoAddBillDatawithdate } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { getVineoAccountCustomerMasterMenu } from '../../../redux/features/services/serviceHelper';
import Billprint from '../entries/billing/Billprint';

export default function AllPartywiseSale() {
	const { setValue } = useForm();
	useEffect(() => {
		console.log("Use Effect called ");
		dispatch(getVineoAccountCustomerMasterMenu());
	}, []);
    let currentDate = new Date();

	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const vineoPartydata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const vineoaddbilldatawithdate = useSelector((state) => state.vineoData.vineoaddbilldatawithdate);
	const isvineoaddbilldatawithdateLoaded = useSelector((state) => state.vineoData.isvineoaddbilldatawithdateLoaded);
	const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
	const [toDate, setToDate] = useState(new Date());
	const [openprint, setOpenprint] = React.useState(false);
	const [dataforprint, setDataforprint] = useState();
	const [tableData, setTableData] = useState();
	const [selectedCustomer, setSelectedCustomer] = useState([])

	const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
	const [isLoading, setLoading] = useState(false);


	useEffect(() => {

        console.log("Refreshing on state Change "+refreshcount)
        setLoading(false)
		setTableData(vineoaddbilldatawithdate)
    }, [isvineoaddbilldatawithdateLoaded,refreshcount]);
   
	// Ag grid Work Start 
	const [gridApi, setGridApi] = useState(null)

	const onExportClick = () => {
		gridApi.api.exportDataAsCsv();
	}

	const onGridReady = (params) => {
		setGridApi(params);
	}

	const defaultColDef = {
		sortable: true,
		editable: true,
		flex: 1,
		filter: true,
		floatingFilter: true,
	}

	const onPaginationChange = (pageSize) => {
		gridApi.api.paginationSetPageSize(Number(pageSize))
	}

	function onCellValueChanged(event) {
		console.log(
			'onCellValueChanged: '
		);
	}

	const columnDefs = [
		{ headerName: "BillNo.", field: "vineoaddUniqueBillNumberWithPrefix" ,resizable: true  },
		{ headerName: "Party", valueGetter: 'data.vineoAddSaleBillCustomer.vineoCustomerAccountName' ,resizable: true  },
		{ headerName: "Date ", field: "vineoAddSaleBillDate" ,resizable: true  },
		{ headerName: "Type", field: "vineoAddSaleBillType" ,resizable: true  },
		{ headerName: "Amount", field: 'vineoAddSaleBillBottomAmt' ,resizable: true  },
		{
			headerName: "Receipt", field: `receiptIsGenerated`,
			cellRenderer: params => {
				if (params.value) {
					return `YES`
				}

				return `NO`;
			}
			,resizable: true  },
		{
			headerName: "Paid", field: `paymentsubmited`,
			cellRenderer: params => {
				if (params.value) {
					return `YES`
				}

				return `NO`;
			}
			,resizable: true  },
		{
			headerName: "Actions", field: "id", cellRendererFramework: (params) => (
				<div>
                <Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>
					<Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
					<Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>

				</div>
			)
		}
	]

	const handleUpdate = (data) => {
		console.log(data)
	}

	const handlePrint = (data) => {
        setDataforprint(data);
        setOpenprint(!openprint)
    }
	const handleDelete = (data) => {
		const confirm = window.confirm("Are you sure, you want to delete this row", data)

		if (confirm) {
			http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ADDBILL, data).then(

				(res) => {
					console.log(res)
					if (res.data.errorCode == "0000") {
						notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

					}
					else
					notifyError("Error While Deleting Bill  " + + res.data.errorMessage)
				},
				(err) => {
					notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

				}
			)
		}
	}

	const getbilldatafromserverwithdate = (reqdata) => {
		if (reqdata.startDate && reqdata.endDate) {
			dispatch(getVineoAddBillDatawithdate(reqdata))
		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}

	// needed below useEffect to solve issue which needed Load Bills to be clicked twice for initial page load only
	// re-write this logic to fetch existing data from redux for specified dates on initial load only
	// or it will keep fetching the data irrespective of the date selected on initial load only ( current behavior )
	// Solution: on navigation resetting the isvineoaddbilldatawithdateLoaded state variable in redux might solve the issue
	useEffect(() => {
		if (isvineoaddbilldatawithdateLoaded) {
			setTableData(vineoaddbilldatawithdate)
		}
	}, [isvineoaddbilldatawithdateLoaded,vineoPartydata, vineoaddbilldatawithdate])

	return (
		<div className="row justify-content-left ">
			 <div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">All Party Wise Sales Report</h4>
				</center>
			</div>

			<Row>
				<Col md={3}>
					<FormGroup className="m-2">
					<Form.Text >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Bills From 
                        
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 3)); console.log("Date selected is ", fromdate)
                        }} > -3Day</div>
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 60))
                        }} > -60Day</div>
                        <div onClick={() => {
                            setFromdate(currentDate.setDate(currentDate.getDate() - 365))
                        }} > -1Year</div>
                        </div>
                        
                        </Form.Text>
						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setFromdate}
							name="vineoCreateLoadBillFromDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							Bills To Date
						</Form.Text>

						<DatePicker className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillToDate", moment(toDate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup  className="m-2">
					<Form.Text >Party Name* GST:-
					{(selectedCustomer?.[0]?.vineoGst?.vineoGstNumber)}
					</Form.Text >
					<InputGroup>
						<Typeahead
							disabled={ false  }
							id="PartyNameTypehead"
							onChange={setSelectedCustomer}
							options={vineoPartydata}
							 
							placeholder="Choose Party Name..."
							selected={selectedCustomer}
							labelKey={"vineoCustomerAccountName"}
						/>
						<Button variant="primary"
							onClick={() => { dispatch(getVineoAccountCustomerMasterMenu()); }} >
							<FontAwesomeIcon icon={faRotate} />
						</Button>
						{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
					</InputGroup>
					</FormGroup>
					
				</Col>
			</Row>

			<Row>
				<Col>
					<Button className="btn btn-primary m-2" disabled = {isLoading} onClick={() => {setLoading(true);getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) })}}>
						<FontAwesomeIcon icon={faRotate} />
						&nbsp;Load Bills
					</Button>
					<Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
						Export To CSV
					</Button>
				</Col>
			</Row>

			<div className="ag-theme-alpine" style={{ height: '500px' }}>
				<div className="ag-theme-alpine" style={{ height: '570px' }}>
					<AgGridReact
						id="AgGridforBillData"
						// rowData={tableData}
						rowData={ selectedCustomer?.[0]?.vineoCustomerAccountName ? tableData?.filter((item)=>{if((item.vineoAddSaleBillCustomer?.vineoCustomerAccountName===selectedCustomer?.[0]?.vineoCustomerAccountName)) {return item}}) :
						tableData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
					/>
					
					<AgGridColumn
						headerName="BQty"
						valueGetter={(params) => {
							return params.data.vineoBatchProductQty;
						}}
						valueSetter={(params) => {
							let newValInt = parseInt(params.newValue);
							let valueChanged = params.data.vineoBatchProductQty !== newValInt;

							if (valueChanged) {
								params.data.vineoBatchProductQty = newValInt;
							}

							return valueChanged;
						}}
					/>
				</div>
			</div>

			<Modal show={openprint}
                onHide={() => setOpenprint(!openprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <Billprint data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

		</div>
	)
}