import axios from 'axios';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASEURL_JWTAUTH } from './Urlforvineo';
import { useDispatch } from 'react-redux';
import { clearreduxstore } from '../redux/features/vineoData/vineoDataSlice';
import { clear } from '@testing-library/user-event/dist/clear';
import Deleteallreduxstore from '../components/loginRegisterForms/Deleteallreduxstore';

export default function AuthUser() {
    const dispatch=useDispatch();

    const navigate = useNavigate();
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () => {
        const userString = sessionStorage.getItem('username');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }

    const getRole = () => {
        const userRoleString = sessionStorage.getItem('role');
        const user_Roledetail = JSON.parse(userRoleString);
        return user_Roledetail;
    }
    const getUniqueid = () => {
        const uniqueidString = sessionStorage.getItem('uniqueidForVineoclientName');
        const uniqueid = JSON.parse(uniqueidString);
        return uniqueid;
    }
   
    const getLastloginTS = () => {
        const lastloginTSString = sessionStorage.getItem('lastLoginTS');
        const lastloginTS = JSON.parse(lastloginTSString);
        return lastloginTS;
    }
     
    const getUniquename = () => {
        const uniquenameString = sessionStorage.getItem('vineoRegisteredClientName');
        const uniquename = JSON.parse(uniquenameString);
        return uniquename;
    }
    
    

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const [uniqueidvineo, setUniqueidvineo] = useState(getUniqueid());
    const [uniquenamevineo, setUniquenamevineo] = useState(getUniquename())
    
    
   
    const saveToken = (user, token, email, uniqueid, role,lastLoginTS, vineoRegisteredClientName, uniqueidForVineoclientName) => {
        sessionStorage.setItem('token', JSON.stringify(token));
        sessionStorage.setItem('username', JSON.stringify(user));
        sessionStorage.setItem('email', JSON.stringify(email))
        sessionStorage.setItem('uniqueid', JSON.stringify(uniqueid))
        sessionStorage.setItem('lastLoginTS', JSON.stringify(lastLoginTS))
        sessionStorage.setItem('role', JSON.stringify(role))
        sessionStorage.setItem('uniqueidForVineoclientName', JSON.stringify(uniqueidForVineoclientName))
        sessionStorage.setItem('vineoRegisteredClientName', JSON.stringify(vineoRegisteredClientName))
        setToken(token);
        // console.log(token)
        setUser(user);
        setUniqueidvineo(uniqueidForVineoclientName);
        setUniquenamevineo(vineoRegisteredClientName);
        navigate('/dashboard');
    }

    const logout = () => {
        
        sessionStorage.clear();
       
        navigate('/login');
    }

    const http = axios.create({
        baseURL: BASEURL_JWTAUTH,
        headers: {
            "Content-type": "application/json",
            "X-uniqueidForVineoclientName": `${uniqueidvineo}`,
            "X-vineoRegisteredClientName": `${uniquenamevineo}`,
            "Authorization":`Bearer ${token}`
        }
    });
    
    return {
        setToken: saveToken,
        token,
        user,
        getToken,
        http,
        getUniqueid,
        getRole,
        getLastloginTS,
        getUniquename,
        getUser,
        logout
    }
    
}
