import React from 'react'
import { useEffect, useState, useRef } from "react"
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller, useForm, useWatch } from "react-hook-form";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getVineoGodownData, getVineoAccountCustomerMasterMenu, getVineoProductData, getVineoSchemaMasterData, getVineoCompanyData, getVineoFirmData, getVineoProductDatawithPositiveQtyinBatch, getLastBillNumberData } from '../../../../redux/features/services/serviceHelper';
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import {
    FormGroup, Label, Input, Modal,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Table
} from 'react-bootstrap';
import { stateOptions } from '../../../../constants/GstStatewithcode';
import { BASEURL_JWTAUTH, BILLCRDITDAYS, DATEFORMAT, VINEOFORM_UPDATE_SELECTED_ADDBILL, VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID } from '../../../../constants/Urlforvineo';
import AreaToSalesman from '../../mapping/AreaToSalesman';
import { AddBillItemAddline } from './AddBillItemAddline';
import http from '../../../../constants/AxiosConfig';
import PartyToArea from '../../mapping/PartyToArea';
import CustomerAccountInfo from '../../master/CustomerAccountInfo';
import Billprint from './Billprint';
import BillprintFormat2 from './BillprintFormat2';


let totalDiscountPercentagevalue = 0;


export default function AddSalesBilling() {
    let currentDate = new Date();
    const partyinputref = useRef()

    const dispatch = useDispatch()
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)

    let lastBillNumberfromreduxfirm = 0;
    // const [open, setOpen] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const [selectedFirm, setSelectedFirm] = useState([])
    const [companySelections, setCompanySelections] = useState([]);
    const [godownSelections, setGodownSelections] = useState([]);
    const [grossBillValue, setGrossBillValue] = useState(0);
    const [billdate, setBilldate] = useState(new Date())
    const [billduedate, setBillduedate] = useState(new Date(currentDate.getTime() + BILLCRDITDAYS * 24 * 60 * 60 * 1000));
    const [selectedVendor, setSelectedVendor] = useState(new Date())
    const [productdamaged, setProductdamaged] = useState(true);
    const [credittabpressed, setCredittabpressed] = useState(false);
    const [creditnotecredit, setCreditnotecredit] = useState()
    const [selectedCreditNote, setSelectedCreditNote] = useState([])
    const [totalDiscountPercentage, setTotalDiscountPercentage] = useState(totalDiscountPercentagevalue)
    const [readyToSubmit, setReadyToSubmit] = useState(false)
    const [billprefix, setBillprefix] = useState()
    const [lastBillNumber, setLastBillNumber] = useState()
    const [billsubmited, setBillsubmited] = useState(1)
    const [lastBillNumberEdited, setLastBillNumberEdited] = useState()
    const [diffrentShipingaddress, setDiffrentShipingaddress] = useState(false)
    const [exportBill, setExportBill] = useState(false)
    const [diffrentDispatchaddress, setDiffrentDispatchaddress] = useState(false)
    // const count = useSelector((state) => state.vineoData.value)
    const vineogodowndataTypeahead = useSelector((state) => state.vineoData.vineogodowndata)
    const vineocompany = useSelector((state) => state.vineoData.vineocompany)
    const vineoPartydata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)

    const vineofirmdatafromredux = useSelector((state) => state.vineoData.vineofirmdata)
    const [vineofirmdata, setVineofirmdata] = useState()


    const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();


    const navigate = useNavigate();

    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)

    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSchemeUniqeId": "Working", "vineoCompanySchemeNumber": "Click Add To Load Scheme" }])
    const isvineoschemamasterdataLoaded = useSelector((state) => state.vineoData.lastBillNumberisvineoschemamasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const [openMapAreaToSalesMan, SetOpenMapAreaToSalesMan] = useState(false);
    const [openMapAreaToParty, SetOpenMapAreaToParty] = useState(false);
    const [editbillnumberalowed, SetEditbillnumberalowed] = useState(false);
    const [openAddParty, SetOpenAddParty] = useState(false);
    const [dataforprint, setDataforprint] = useState();
    const [openprint, setOpenprint] = React.useState(false);

    useEffect(() => {
        // dispatch(getVineoCompanyData());
        // dispatch(getVineoGodownData());
        dispatch(getVineoAccountCustomerMasterMenu());
        dispatch(getVineoFirmData())
        // dispatch(getLastBillNumberData())
        setVineofirmdata(vineofirmdatafromredux)
        partyinputref.current.focus();
        // dispatch(getVineoProductData());
    }, []);

    useEffect(() => {
        if (vineofirmdata?.[0]) {
            setSelectedFirm([vineofirmdata?.[0]])
        }
        else {
            dispatch(getVineoFirmData())
        }
        setVineofirmdata(vineofirmdatafromredux)
        console.log("selected firm is fro redux is  ", vineofirmdata?.[0])
        SetEditbillnumberalowed(vineofirmdata?.[0]?.allowBillNumberedit);
        lastBillNumberfromreduxfirm = vineofirmdatafromredux?.[0]?.vineoaddBillUserBillNumberStartSetting;
        setBillprefix(vineofirmdata?.[0]?.vineoaddBillPrefix)
        console.log("Last Bill Number is " + lastBillNumberfromreduxfirm)
        setLastBillNumber(parseInt(lastBillNumberfromreduxfirm) + 1)



    }
        , [refreshcount, billsubmited]);


    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const onSubmit = (data) => {
        // console.log(JSON.stringify(data));
        setReadyToSubmit(!readyToSubmit)
        console.log((data));
        if (selectedFirm[0]) {
            if (selectedCustomer[0]?.vineoArea?.salesman?.nameofUser && selectedCustomer[0]?.vineoArea?.vineoAreaName)
            // check for items in bill
            {
                if (data?.vineoDataforAddBillItem?.length > 0) { submitForm(data) }
                else {
                    notifyError("Please Add Items ")
                }

            }
            else {
                notifyError("Error Please Select Salesman or Do the Area to Party to Salesman Mapping ")
            }
        }
        else { notifyError("Please Select Firm ") }
    }


    const getcreditavaialblefromserver = () => {
        if (!selectedCustomer?.[0]) {
            notifyWarning("Please Select Party ")
        }
        else {
            http.post(BASEURL_JWTAUTH + VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID +
                selectedCustomer?.[0]?.ultiVineoCustomerAccountUniqeId).then(
                    (res) => {
                        console.log(res.data)
                        if (res.data?.[0]?.creditnoteAmount > '0') {
                            notify('Credit Amount is Available - ' + res?.data?.[0]?.creditnoteAmount)
                            setCreditnotecredit(res.data);
                            console.log(creditnotecredit);
                            console.log("CreditNote Above");

                        }
                        else
                            notifyError("Credit Note Amount is Not Available For Selected Customer- ")

                    },
                    (err) => {


                        notifyError("Error While Getting Credit amount Operation From Server ")
                    }
                )
        }



    }

    const getdatafromserver = () => {

        console.log("Called to get  Data ")

        dispatch(getVineoSchemaMasterData());
        // setTimeout(500);

        if (Object.keys(vineodata).length > 0) {
            isvineoschemamasterdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoSchemeUniqeId": "Working", "vineoSchemeName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }

    const handlePrint = (data) => {

        setDataforprint(data);
        setOpenprint(!openprint)
    }
    const submitForm = (data) => {
        console.log(data)
        console.log("Connecting Server to Create Bill")
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ADDBILL, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorCode == '0000') {
                    notify('Add Bill Operation Successful with Deatils = ' + res.data.errorMessage)
                    dispatch(getVineoProductDatawithPositiveQtyinBatch())

                    setSelectedCustomer([])
                    reset();
                    partyinputref.current.focus();

                    // dispatch(getLastBillNumberData())
                    dispatch(getVineoFirmData())
                    handlePrint(res?.data?.data)

                }
                else if (res.data.errorCode == '0062') {
                    notifyError('Failed Pls Change Bill Number It Already Exists == Add Bill Operation Failed with Deatils = ' + res.data.errorMessage)

                }
                else
                    notifyError("Error While Add Bill Operation From Server with Message as " + res.data.additionalInfo)
                //  console.log(res.data)

            },
            (err) => {


                notifyError("Error While Add Bill Operation From Server ")
            }
        )
    }


    return (
        <div>

            <div>
                <div className="h5 mb-0 text-gray-800">
                    <center>
                        <h5 className='h5 mb-0 text-gray-800'>Add Sales Billing Firm Name :-{(selectedFirm?.[0]?.vineoFirmFirmName)}</h5>
                    </center>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <div>
                            < >
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Form.Text >
                                                Bill Date
                                            </Form.Text>
                                            <DatePicker className="form-control"
                                                selected={billdate}
                                                onChange={setBilldate}
                                                name="vineoBillDate"
                                                dateFormat="dd-MM-yyyy"
                                                ref={setValue("dataforaddsaleBill[0].vineoAddSaleBillDate", moment(billdate).format(DATEFORMAT))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Text>
                                            Due Date
                                        </Form.Text>

                                        <DatePicker className="form-control"
                                            selected={billduedate}
                                            onChange={setBillduedate}
                                            name="vineoBillDate"
                                            dateFormat="dd-MM-yyyy"
                                            ref={setValue("dataforaddsaleBill[0].vineoAddSaleBillduedate", moment(billduedate).format(DATEFORMAT))}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            salesman
                                        </Form.Text>

                                        <InputGroup>
                                            <Form.Control readOnly className="form-control" required
                                                defaultValue={selectedCustomer[0]?.vineoArea?.salesman?.nameofUser ? selectedCustomer[0]?.vineoArea?.salesman?.nameofUser : (``)} />
                                            <Button variant="info" onClick={() => { SetOpenMapAreaToSalesMan(true) }}>
                                                +
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Area
                                        </Form.Text>
                                        <InputGroup>
                                            <Form.Control readOnly className="form-control" required
                                                defaultValue={selectedCustomer[0]?.vineoArea?.vineoAreaName ? selectedCustomer[0]?.vineoArea?.vineoAreaName : (``)} />
                                            <Button variant="info" onClick={() => { SetOpenMapAreaToParty(true) }}>
                                                +
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Bill Type
                                        </Form.Text>
                                        <Form.Select onKeyDown={(e) => {
                                            if (e.key === "Tab") {
                                                if (e.shiftKey) {
                                                    setCredittabpressed(!credittabpressed)
                                                    console.log(credittabpressed)
                                                } else {
                                                    console.log("Tab Presses")
                                                    setCredittabpressed(!credittabpressed)
                                                }
                                            }
                                        }} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillType")} required>
                                            <option value="CREDIT">Credit</option>
                                            <option value="CASH">Cash</option>
                                        </Form.Select>
                                    </Col>


                                    {/* <Col md={2}>
                                        <FormGroup >
                                            <Form.Text >Company Name</Form.Text >
                                            <Typeahead
                                                id="companyNameTypeahead"
                                                labelKey="companyName"
                                                onChange={setCompanySelections}
                                                // onClick={console.log(selected[0])}
                                                multiple
                                                options={vineocompany? vineocompany:[]}
                                                placeholder="Choose Company Name..."
                                                selected={companySelections}

                                            />
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillcompanyName", companySelections)}
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillSalesman", selectedCustomer[0]?.vineoArea?.salesman)}
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillArea", selectedCustomer[0]?.vineoArea)}
                                        </FormGroup>
                                    </Col> */}
                                    {/* <Col md={2}>
                                        <FormGroup>
                                            <Form.Text >Godown Name</Form.Text >
                                            <Typeahead
                                                id="godownNameTypeahead"
                                                labelKey="vineoGoDownName"
                                                onChange={setGodownSelections}
                                                multiple
                                                options={vineogodowndataTypeahead? vineogodowndataTypeahead:[]}
                                                placeholder="Choose Godown."
                                                selected={godownSelections}
                                            />
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillGoDownName", godownSelections)}
                                        </FormGroup>
                                    </Col> */}

                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <Form.Text >Select Firm *  GST:- {(selectedFirm?.[0]?.vineofirmGst?.vineoGstNumber)}  -\Code :{(selectedFirm?.[0]?.vineofirmGst?.vineoGstCode)}  </Form.Text >
                                        {console.log("vineofirmdata", vineofirmdata?.[0])}
                                        <Controller
                                            control={control}
                                            name={`vineofirmBankArray`}
                                            // rules={{
                                            //     required: "Please, select at least one value"
                                            // }}

                                            render={({ field, fieldState }) => (
                                                <div >
                                                    <InputGroup>
                                                        <Typeahead
                                                            {...field}
                                                            id="FirmNameTypehead"
                                                            size='sm'
                                                            onChange={setSelectedFirm}
                                                            labelKey={"vineoFirmFirmName"}
                                                            selected={selectedFirm}
                                                            // multiple  // for future
                                                            options={vineofirmdata ? vineofirmdata : []}
                                                            className={fieldState.invalid ? "is-invalid" : ""}
                                                            ref={() => {
                                                                selectedFirm ?
                                                                    setValue("dataforaddsaleBill[0].vineoAddSaleBillFirm", selectedFirm) :
                                                                    <></>
                                                            }}
                                                        />

                                                        <p id="typeaheadError" className="invalid-feedback">
                                                            {fieldState.error?.message}
                                                        </p>
                                                        <Button variant="primary"
                                                            onClick={() => { dispatch(getVineoFirmData()); }} >
                                                            <FontAwesomeIcon icon={faRotate} />
                                                        </Button>
                                                    </InputGroup>


                                                </div>
                                            )}
                                        />
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                onChange={(e) => setDiffrentDispatchaddress(!diffrentDispatchaddress)}
                                                label='Different Dispatch Add'
                                                id="Checkbox1"
                                            />
                                        </Form.Group>
                                        {setValue("dataforaddsaleBill[0].diffrentDispatchaddress", diffrentDispatchaddress)}

                                    </Col>
                                    <Col md={3}>
                                        <Form.Text >Party Name* GST:-
                                        {(selectedCustomer?.[0]?.vineoGst?.vineoGstNumber)}</Form.Text >
                                        {/* -\Code :{(selectedCustomer?.[0]?.vineoGst?.vineoGstCode)} */}

                                        <InputGroup>
                                            <Typeahead
                                                // disabled={selectedFirm[0] ? false : true}
                                                id="PartyNameTypehead"
                                                onChange={setSelectedCustomer}
                                                options={vineoPartydata || []}
                                                placeholder="Choose Party Name..."
                                                selected={selectedCustomer}
                                                labelKey={"vineoCustomerAccountName"}
                                                ref={partyinputref}
                                            />
                                            <Button variant="info" onClick={() => { SetOpenAddParty(true) }}>
                                                +
                                            </Button>
                                            <Button variant="primary"
                                                onClick={() => { dispatch(getVineoAccountCustomerMasterMenu()); }} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)}

                                        </InputGroup>
                                        <Form.Group controlId="diffrentbilingaddress" className="mb-3">
                                            <Form.Check
                                                onChange={(e) => setDiffrentShipingaddress(!diffrentShipingaddress)}
                                                label='Different Shiping Add'
                                                id="Checkbox"
                                            />
                                        </Form.Group>
                                        {setValue("dataforaddsaleBill[0].diffrentShipingaddress", diffrentShipingaddress)}
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            LastBillNo-{(billprefix)}-{
                                                (lastBillNumber > selectedFirm?.[0]?.vineoaddBillUserBillNumberStartSetting) ?
                                                    (lastBillNumber - 1) :
                                                    vineofirmdata?.[0]?.vineoaddBillUserBillNumberStartSetting
                                            }
                                        </Form.Text>
                                        {console.log("editbillnumberalowed", editbillnumberalowed)}
                                        <Form.Control readOnly={!editbillnumberalowed} className="form-control" defaultValue={lastBillNumber} onChange={(e) => { lastBillNumberfromreduxfirm = e.target.value; setLastBillNumber(e.target.value); }} />
                                    </Col>
                                    {console.log("Last Bill Number is " + lastBillNumber)}
                                    {setValue(`dataforaddsaleBill[0].vineoAddSaleUserBillNumber`, lastBillNumber)}
                                    <Col md={2}>
                                        <Form.Text>
                                            Apply Credit Note
                                        </Form.Text>
                                        <InputGroup>

                                            <Typeahead
                                                id="CreditnoteTypehead"
                                                onChange={setSelectedCreditNote}
                                                disabled={creditnotecredit ? false : true}
                                                options={creditnotecredit?.filter((item) => {
                                                    if (!item.issuedCCwithoutReceipt) { return item }
                                                }) || []}
                                                placeholder="Check CreditNote"
                                                selected={selectedCreditNote}
                                                labelKey={creditnotecredit => `${creditnotecredit.creditnoteAmount.toFixed(2)}`}

                                                renderMenuItemChildren={(option) => (
                                                    <div key={option.id}>
                                                        <div>
                                                            <small>Amount:  {`${option?.creditnoteAmount.toFixed(2)}`}</small>

                                                            <small> -CRN: {`${option?.creditNoteIssuedonBillNumber}`}</small>
                                                        </div>
                                                    </div>
                                                )}
                                                ref={() => {

                                                    { setValue(`dataforaddsaleBill[0].vineoCreditNoteuniqueid`, selectedCreditNote?.[0]?.ultiVineoCreditNoteUniqeId) }
                                                }}
                                            />
                                            <Button variant="primary"
                                                onClick={() => {
                                                    getcreditavaialblefromserver()
                                                }} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>


                                        </InputGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Text>
                                            Narr
                                        </Form.Text>
                                        <Form.Control className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleNarr")} />
                                        <Form.Group controlId="Export Bill" className="mb-3">
                                            <Form.Check
                                                onChange={(e) => setExportBill(e.target.checked)}
                                                label='Export Bill-No GST'
                                                id="CheckboxExport"
                                            />
                                            {console.log("Value of Export Bill", exportBill)}
                                        </Form.Group>
                                        {setValue("dataforaddsaleBill[0].exportBill", exportBill)}

                                    </Col>




                                </Row>
                                {
                                    diffrentShipingaddress ?
                                        <Row>
                                            <Col md={4}>BillTo:-{(selectedCustomer?.[0]?.vineoCustomerAccountaddress)},{(selectedCustomer?.[0]?.vineoCustomerAccountpincode)}</Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Shiping AddLine1' {...register("dataforaddsaleBill[0].shipingaddline1", { minLength: 3, maxLength: 90 })} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Shiping AddLine2' {...register("dataforaddsaleBill[0].shipingaddline2", { minLength: 3, maxLength: 90 })} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Shiping City' {...register("dataforaddsaleBill[0].shipingaddCity")} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" type="number" placeholder='Shiping AddPinCode' {...register("dataforaddsaleBill[0].shipingaddpincode")} required />
                                            </Col>
                                        </Row> :
                                        <></>
                                }
                                {
                                    diffrentDispatchaddress ?
                                        <Row>
                                            {/* <Col md={2}>BillTo:-{(selectedCustomer?.[0]?.vineoCustomerAccountaddress)},{(selectedCustomer?.[0]?.vineoCustomerAccountpincode)}</Col> */}
                                            <Col md={4}>
                                                <Form.Control className="form-control" placeholder='Disp. AddLine1' {...register("dataforaddsaleBill[0].dispatchaddline1", { minLength: 3, maxLength: 97 })} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Disp. AddLine2' {...register("dataforaddsaleBill[0].dispatchaddline2", { minLength: 3, maxLength: 97 })} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" placeholder='Disp. City' {...register("dataforaddsaleBill[0].dispatchaddCity")} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Control className="form-control" type="number" placeholder='Disp. AddPinCode' {...register("dataforaddsaleBill[0].dispatchaddpincode", { minLength: 6, maxLength: 6 })} required />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Select className="form-control" placeholder='Select State' {...register("dataforaddsaleBill[0].dispatchstatecode")} required>
                                                    {stateOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row> :
                                        <></>
                                }
                                {/* <Row>
                                    <Col md={2}>
                                        <Form.Text>
                                            Sale Type
                                        </Form.Text>
                                        <Form.Select className="form-control" onSelect={() =>
                                            watch(`dataforaddsaleBill[0].transactionIsReturnType`) ?
                                                setProductdamaged(true) : setProductdamaged(false)
                                        }
                                            {...register("dataforaddsaleBill[0].transactionIsReturnType")} required>
                                            <option value="false">Sale</option>
                                            <option value="true">Return</option>
                                        </Form.Select>
                                    </Col>


                                    {
                                        productdamaged ?
                                            <Col md={2}>
                                                <Form.Text>
                                                    Product
                                                </Form.Text>
                                                <Form.Select className="form-control" disabled={false} {...register("dataforaddsaleBill[0].ReturnProductsareDamaged")} required>
                                                    <option value="false">Sellable</option>
                                                    <option value="true">Damaged</option>
                                                </Form.Select>
                                            </Col> :
                                            <></>
                                    }

                                </Row> */}

                            </>
                        </div>
                        <div>

                        </div>
                    </Row>
                    {/* {setTotalDiscountPercentage(totalDiscountPercentagevalue)} */}

                    {console.log(totalDiscountPercentagevalue + "From Parent ")}
                    <div><AddBillItemAddline
                        totalDiscountPercentage={totalDiscountPercentage}
                        credittabpressed={credittabpressed}
                        setGrossBillValue={setGrossBillValue} register={register}
                        setValue={setValue} control={control} getValues={getValues}
                        watch={watch} dataforaddsaleBill={watch(`dataforaddsaleBill`)} /></div>
                    <center>
                        <div>
                            <Row>
                                <center>

                                    <Button className="btn btn-primary m-2" onClick={() => { setTotalDiscountPercentage(totalDiscountPercentagevalue); setReadyToSubmit(true) }} value="Calcualte Discount" >
                                        Calculate
                                    </Button>

                                    <input className="btn btn-primary m-2" disabled={!readyToSubmit} type="submit" value="Submit" />

                                    <Button className="btn btn-danger m-2" type="button" onClick={() => { reset() }} value="Cancle">Cancel</Button>
                                    <Link to="/dashboard/Billing" className="btn btn-warning m-2">Billing</Link>
                                </center>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Net Amt
                                        </Form.Text>
                                        <Form.Control disabled={true} value={grossBillValue} size='sm' className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillNetAmt")} required />
                                    </FormGroup>

                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Star Disc
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillStarDiscPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillStarDisc")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillStarDiscPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            cGST Amt
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true} defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillCgstAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Surch
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSurchPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetSurch")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetSurchPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Display
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillDisplayPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillDisplay")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Display%
                                        </Form.Text>
                                        <Form.Control size='sm'
                                            defaultValue={0}
                                            className="form-control" type="number" min="0" step="0.01"{...register("dataforaddsaleBill[0].vineoAddSaleBillDisplayPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            SchmAmt
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillSchemeAmtPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillSchemeAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            SchmAmt%
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillSchemeAmtPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Less Other
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillLessOtherPercentage`) || `0`) * 0.01 * grossBillValue} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillLessOther")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillLessOtherPercentage")} required />
                                    </FormGroup>
                                </Col>



                            </Row>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Bottom Amt
                                        </Form.Text>
                                        <Form.Control disabled={true} value={parseFloat(parseFloat(grossBillValue) + parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRoundingAmt`))).toFixed(0)} size='sm' className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillBottomAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Rounding
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRoundingAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Total Discount =
                                            {totalDiscountPercentagevalue}   %
                                        </Form.Text >
                                        
                                         
                                      

                                    </FormGroup>
                                </Col>


                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Cash Disc
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCashDiscountPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillCashDiscount")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillCashDiscountPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            sGST Amt
                                        </Form.Text>
                                        <Form.Control disabled={true} size='sm' className="form-control" defaultValue={0} {...register("dataforaddsaleBill[0].vineoAddSaleBillsGSTAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Sch Disc
                                        </Form.Text>
                                        <Form.Control disabled={true} size='sm'
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSchDiscPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetSchDisc")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetSchDiscPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Coupon
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCouponPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillCoupon")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Coupon%
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillCouponPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            TPR Amt
                                        </Form.Text>
                                        <Form.Control disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTprAmtPercentage`) || `0`) * 0.01 * grossBillValue}
                                            size='sm'
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTprAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %Tpr
                                        </Form.Text>
                                        <Form.Control type="number" min="0" step="0.01" size='sm' defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTprAmtPercentage")} required />
                                    </FormGroup>
                                </Col>




                            </Row>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Sys.Adjust
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true} defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTSysAdjust")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add Other
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddOtherPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control" {...register("dataforaddsaleBill[0].vineoAddSaleBillAddOther")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="number" min="0" step="0.01" size='sm' defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillAddOtherPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Sales Return
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            defaultValue={0}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillSalesReturn")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Ret Cd Disc
                                        </Form.Text>
                                        <Form.Control disabled={true} size='sm'
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetCdDiscPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"         {...register("dataforaddsaleBill[0].vineoAddSaleBillRetCdDisc")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control type="number" min="0" step="0.01" size='sm' defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillRetCdDiscPercentage")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add/Less
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddLessPercentage`) || `0`) * 0.01 * grossBillValue}
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillAddLess")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Add/Less%
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillAddLessPercentage")} required />
                                    </FormGroup>
                                </Col>

                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            TCS Amt
                                        </Form.Text>
                                        <Form.Control disabled={true}
                                            value={parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTcsAmtPercentage`) || `0`) * 0.01 * grossBillValue}
                                            size='sm'
                                            className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTcsAmt")} required />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            %
                                        </Form.Text>
                                        <Form.Control size='sm' type="number" min="0" step="0.01" defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillTcsAmtPercentage")} required />
                                    </FormGroup>
                                </Col>

                                <Col md={1}>
                                    <FormGroup>
                                        <Form.Text >
                                            Return GST
                                        </Form.Text>
                                        <Form.Control size='sm' disabled={true} defaultValue={0} className="form-control"  {...register("dataforaddsaleBill[0].vineoAddSaleBillReturnGst")} required />
                                    </FormGroup>
                                </Col>


                            </Row>
                            <Row>
                                {setValue("dataforaddsaleBill[0].longitude", 0)}
                                {setValue("dataforaddsaleBill[0].latitude", 0)}
                                {setValue("dataforaddsaleBill[0].addressFromMobileApp", "sentfromdesktopapp")}



                            </Row>
                            {/* calcualting total discount Percentage  */}

                        </div>

                        <Row>
                            <div style={{ display: "none" }}>
                                {
                                    totalDiscountPercentagevalue =
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillStarDiscPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCashDiscountPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddOtherPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillLessOtherPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSurchPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetSchDiscPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillRetCdDiscPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTcsAmtPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillDisplayPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillCouponPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillAddLessPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillTprAmtPercentage`)) +
                                    parseFloat(watch(`dataforaddsaleBill[0].vineoAddSaleBillSchemeAmtPercentage`))
                                }
                            </div>
                        </Row>
                    </center>

                </form>
            </div>


            <div>
                <Modal show={openMapAreaToSalesMan}
                    onHide={() => { SetOpenMapAreaToSalesMan(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <AreaToSalesman />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { SetOpenMapAreaToSalesMan(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal show={openMapAreaToParty}
                    onHide={() => { SetOpenMapAreaToParty(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <PartyToArea />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { SetOpenMapAreaToSalesMan(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal show={openAddParty}
                    onHide={() => { SetOpenAddParty(false) }}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true}>
                    <Modal.Header closeButton />
                    {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                    <Modal.Body>
                        <CustomerAccountInfo />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary m-2" onClick={() => { SetOpenAddParty(false) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={openprint}
                    onHide={() => setOpenprint(!openprint)}
                    // backdrop="static"
                    fullscreen={true}
                    size="lg"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Print Bill</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        {console.log("PrintForamt is ", vineofirmdata?.[0]?.vineoaddBillFormat)}
                        {(vineofirmdata?.[0]?.vineoaddBillFormat === '1') ?
                            <Card className='p-4'>
                                <Billprint data={dataforprint} />
                            </Card>
                            :
                            <Card className='p-4'>
                                <BillprintFormat2 data={dataforprint} />
                            </Card>
                        }

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    )

}