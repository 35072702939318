import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';

import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import http from "../../../../constants/AxiosConfig";

import { useForm } from "react-hook-form";
import { faCalendarPlus, faCartPlus, faCirclePlus, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_DELETE_SELECTED_SUBBRAND_DATA, VINEOFORM_UPDATE_SELECTED_SUBBRAND_DATA, BASEURL_JWTAUTH, GSTEWAYBILLGETAUTHTOKEN } from "../../../../constants/Urlforvineo";
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup,
    Button, Form, Row, Col, Card, InputGroup, Modal
} from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { getVineoFirmData, getVineoTransportMasterData } from "../../../../redux/features/services/serviceHelper";
import TransportEntry from "../../master/TransportEntry";
import { addgstDeatilsFromUser } from "../../../../redux/features/vineoData/vineoDataSlice";
import axios from "axios";


export default function GetGstUserDetailsforEwayBill() {




    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSubBrandUniqeId": "Working", "vineoSubBrandName": "Click Refresh To Load" }])

    const dispatch = useDispatch()
    const [selected, setSelected] = useState([]);

    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [addtransporter, setAddtransporter] = useState(false);
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [gStApiloginSuccess, setGStApiloginSuccess] = useState(false);
    const count = useSelector((state) => state.vineoData.value)
    
    const [datafortransporttypehead, setDatafortransporttypehead] = useState({});

    const vineoData = useSelector((state) => state.vineoData.vineotransportmasterdata)
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const gstDeatilsFromUser = useSelector((state) => state.vineoData.gstDeatilsFromUser)
    const isDataLoaded = useSelector((state) => state.vineoData.isvineotransportmasterdataLoaded)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)


    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getVineoFirmData());
    }, []);



    const onSubmit = (data) => {
        submitForm(data)

    }
    // const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)


    const getdatafromserver = () => {


        console.log("Called in to get Sub Brand Data")
        dispatch(getVineoTransportMasterData())

        console.log("Empty Data Received form Server")

    }

    const submitForm = (data) => {
        // api call
        axios.post(GSTEWAYBILLGETAUTHTOKEN, JSON.stringify(data), {
            headers: {
              'Content-Type': 'application/json',
             
            }}).then(
            (res) => {
                if (res.data.Status == "1") {
                    setGStApiloginSuccess(true);

          
                    dispatch(addgstDeatilsFromUser({
                        'eInvoiceUserName': data.eInvoiceUserName, 'eInvoicePassword': data.eInvoicePassword, 'datastoredinStatefromgstewaybill': true,
                        'AuthToken': res.data.AuthToken,
                        'Sek': res.data.Sek,
                        'gstin': vineofirmdata?.[0]?.vineoFirmGstNumber,
                        'TokenExpiry': res.data.TokenExpiry
                    }));
                    // comment part after this for prod creds
                    notify('Success:- Log In Success For 1 Hr Pls Click Eway Bill')

                }
                else {
                    console.log("Call Failed ");
                    // dispatch(addgstDeatilsFromUser({'eInvoiceUserName':data.eInvoiceUserName, 'eInvoicePassword':data.eInvoicePassword,'datastoredinStatefromgstewaybill': true, 
                    // 'AuthToken': 'Fa8x1pAOyIWFPfS9NHJqbI8EY',
                    // 'Sek': '/N9/jW1l6FMmASR0VuJJeu7rg3/CNfj8P2i0mLVEV0xVl5cz4fBprTZIh0DA9Vjr',
                    // 'TokenExpiry':'2023-05-11T17:24:52'}));
                    notifyError(res.data?.ErrorMessage)
                }
            },
            (err) => {
                notifyError("Error While Loggin IN GST for E Invoice   ");

                dispatch(addgstDeatilsFromUser({'eInvoiceUserName':data.eInvoiceUserName, 'eInvoicePassword':data.eInvoicePassword,'datastoredinStatefromgstewaybill': true, 
                'AuthToken': 'Fa8x1pAOyIWFPfS9NHJqbI8EY',
                'Sek': '/N9/jW1l6FMmASR0VuJJeu7rg3/CNfj8P2i0mLVEV0xVl5cz4fBprTZIh0DA9Vjr',
                'TokenExpiry':'2023-05-11T17:24:52'}));
            }
        )
    }

    return (
        <div>
            <h3 className='justify-content-center m-1'>Generate Eway Bill </h3>
            <div className="row justify-content-left ">
                <Row>

                    <Col >
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            GST Number : {vineofirmdata?.[0]?.vineoFirmGstNumber}
                                        </Form.Text>
                                    </FormGroup>
                                    {setValue("gstin", vineofirmdata?.[0]?.vineoFirmGstNumber)}
                                    <FormGroup >
                                        <Form.Text className="text-muted">
                                            eInvoiceUserName ={vineofirmdata?.[0]?.vineoFirm_GSTAPIUSERNAME}
                                        </Form.Text>
                                       
                                    </FormGroup>
                                    {setValue("MVApiKey", vineofirmdata?.[0]?.vineoFirm_GSTMVApiKey)}
                                     {setValue("eInvoiceUserName", vineofirmdata?.[0]?.vineoFirm_GSTAPIUSERNAME)} 
                                    {setValue("eInvoicePassword", vineofirmdata?.[0]?.vineoFirm_GSTAPIPASSWORD)}
                                    {setValue("MVSecretKey", vineofirmdata?.[0]?.vineoFirm_GSTMVSecretKey)}

                                    {gStApiloginSuccess?
                                   <><input className="btn btn-success m-2" type="submit" value="Successfull" /></>  :
                                    <><input className="btn btn-primary m-2" type="submit" value="LogIn For Day" /></>
}

                                    <h3>Add MICRO-VISTA TECHNOLOGIES PVT. LTD. And Clayfin Technlogies As your API in Your www.ewaybills.com GST Portal </h3>
                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row >

            </div >



        </div >
    )

}