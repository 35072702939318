import React, { useState, useEffect } from 'react'
import { getVineoAccountCustomerMasterMenu, getVineoAreaData, getVineoCompanyData, getVineoRouteData, getVineoSalesmanUsers, getVineoUsers } from '../../../redux/features/services/serviceHelper';
import { useSelector, useDispatch } from 'react-redux'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';

import {
    FormGroup, Label, Input, InputGroup, Alert, Fragment,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, Table
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { BASEURL_JWTAUTH, VINEO_MAPMULTIPLE_AREATOPARTY } from '../../../constants/Urlforvineo';

function AdvanceMappingSetting() {


    useEffect(() => {
        dispatch(getVineoCompanyData())
        dispatch(getVineoAreaData())
        dispatch(getVineoAccountCustomerMasterMenu())
        dispatch(getVineoSalesmanUsers())
        dispatch(getVineoRouteData())
    }, [])

    const dispatch = useDispatch()
    const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    const vineooldCompnaydata = useSelector((state) => state.vineoData.vineocompany)
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const vineoareadata = useSelector((state) => state.vineoData.vineoareadata)
    const vineousersdata = useSelector((state) => state.vineoData.vineousers)
    const vineocustomeaccountdata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const vineosalesmanusersdata = useSelector((state) => state.vineoData.vineosalesmanusers)
    const vineoroutedata = useSelector((state) => state.vineoData.vineoroutedata)
    const [searchterm, setSearchterm] = useState("");
    const isvineoaccountcustomerdataLoaded = useSelector((state) => state.vineoData.isvineoaccountcustomerdataLoaded)
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    const [AreaSelections, setAreaSelections] = useState([]);
    const [partySelections, setPartySelections] = useState([]);
    const [companySelections, setCompanySelections] = useState([]);
    const [salesamnSelection, setSalesamnSelection] = useState([]);
    const [salesamnRouteSelection, setSalesamnRouteSelection] = useState([]);
    const [deleteoldMapping, setDeleteoldMapping] = useState(false);

    const data = [{ label: 'Delete', deleteoldMapping: true },
    { label: 'NoDelete', deleteoldMapping: false },
    ]

    const getdatafromserverforsalesmanandarea = () => {

        dispatch(getVineoAccountCustomerMasterMenu())


        console.log("Called to get Area and User Data Data ")

    }
    const [dataforserver, setDataforserver] = useState({ "vineoAreaList": "null", "vineoCompanyList": "null", "vineoCustomerAccountList": "null", "ultimateUsersList": null, "deleteoldMapping": null })
    const mapareatoparty = (e) => {
        e.preventDefault();
        console.log(AreaSelections)
        console.log(partySelections)
        console.log(companySelections)
        setDataforserver({ "vineoAreaList": AreaSelections, "vineoRouteList": salesamnRouteSelection, "vineoCompanyList": companySelections, "vineoCustomerAccountList": partySelections, "ultimateUsersList": salesamnSelection, "deleteoldMapping": deleteoldMapping })
        console.log(dataforserver)


        http.post(BASEURL_JWTAUTH + VINEO_MAPMULTIPLE_AREATOPARTY, dataforserver).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Area MApped to Party Succesfully')

                }
                else
                    notify("Error While Mapping Areato Party From Server")
                //  console.log(res.data)

            },
            (err) => {
                console.log(err.data)

                notify("Please Note That Your Doing Advance Mapping , Click Again Add Mapping to Confirm Mapping")
            }
        )

    }

    return (
        <>
            <div className="col-md-12 text-center"><h1>Advance Mapping For Area To Salesman and Party </h1></div>
            <Form>
                <FormGroup>
                    <Form.Label>Select Area</Form.Label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="vineoAreaName"

                        onChange={setAreaSelections}
                        options={vineoareadata}
                        placeholder="Choose Area..."
                        selected={AreaSelections}
                    />
                </FormGroup>
                <FormGroup style={{ marginTop: '20px' }}>
                    <Form.Label>Select Party </Form.Label>
                    <Typeahead
                        id="basic-typeahead-multiple"
                        labelKey="vineoCustomerAccountName"
                        multiple
                        onChange={setPartySelections}
                        options={vineocustomeaccountdata}
                        placeholder="Choose several Customers..."
                        selected={partySelections}
                    />
                </FormGroup>
                <FormGroup style={{ marginTop: '20px' }}>
                    <Form.Label>Select Company</Form.Label>
                    <Typeahead
                        id="basic-typeahead-multipleCompanies"
                        labelKey="companyName"
                        multiple
                        onChange={setCompanySelections}
                        options={vineooldCompnaydata}
                        placeholder="Choose several Comapnies..."
                        selected={companySelections}
                    />
                </FormGroup>
                <FormGroup style={{ marginTop: '20px' }}>
                    <Form.Label>Select Salesman</Form.Label>
                    <Typeahead
                        id="basic-typeahead-multipleUsers"
                        labelKey="nameofUser"
                        // multiple
                        onChange={setSalesamnSelection}
                        options={vineosalesmanusersdata}
                        placeholder="Choose several Salesman..."
                        selected={salesamnSelection}
                    />
                </FormGroup>

                {/* <FormGroup style={{ marginTop: '20px' }}>
                <Form.Label>Select Salesman Route</Form.Label>
                <Typeahead
                    id="basic-typeahead-singelRouteUsers"
                    labelKey="vineoRouteName"
                    // multiple
                    onChange={setSalesamnRouteSelection}
                    options={vineoroutedata}
                    placeholder="Choose Salesman Route..."
                    selected={salesamnRouteSelection}
                />
            </FormGroup> */}

                <FormGroup style={{ marginTop: '20px' }}>
                    <Form.Label>Selecting this Will delete old Mappinga and Will set this Mapping as Default for This Area</Form.Label>
                    <Form.Check

                        onChange={(e) => setDeleteoldMapping(true)}
                        label='DeleteOld Mapping'
                        id="Checkbox"
                    />
                </FormGroup>

                {!isEditDataSelected ?
                    <input className="btn btn-primary m-2" type="submit" onClick={mapareatoparty} value="Add Mapping " />
                    : <input className="btn btn-primary m-2" type="submit" value="Update Mapping" />}
                <button type="button" onClick={() => { setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>
            </Form>
            <div className="col-md-12 text-center">
                <InputGroup>
                    <Form.Control className="m-1" type="text" placeholder="Search ...." onChange={(e) => setSearchterm(e.target.value)} />
                    <Button variant="primary" className="m-1"
                        onClick={getdatafromserverforsalesmanandarea} >
                        <FontAwesomeIcon icon={faRotate} />Load Mapping Data
                    </Button>
                </InputGroup>
                <Table striped bordered hover size="sm"  >
                    <tbody>
                        <tr>
                            <td>Party Name</td>
                            <td>Area</td>
                            <td>Company Name</td>
                            <td>Salesman Name</td>
                            <td>Route Name</td>


                        </tr>


                        {
                            vineocustomeaccountdata?.filter((item)=>{
                                if(searchterm=="")
                                {
                                    return item;
                                }
                                else if(item.vineoCustomerAccountName.toLowerCase().includes(searchterm.toLowerCase())){
                                    
                                    return item;

                                }
                            }).map((item, i) =>
                                <tr key={i}>

                                    <td>{item?.vineoCustomerAccountName}</td>
                                    <td>{item?.vineoArea?.vineoAreaName}</td>

                                    <td>
                                        <>
                                            {

                                                (<Table striped bordered hover size="sm"  >
                                                    <tbody>
                                                        {
                                                            item?.vineoArea?.vineoCompanies?.map((data1, i1) =>

                                                                <tr key={i1}>
                                                                    <td>{data1.companyName}</td>

                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>)
                                            }
                                        </>
                                        {/*  */}
                                    </td>
                                    <td>
                                        
                                    {item?.vineoArea?.salesman?.nameofUser}
                                      
                                    </td>
                                    <td>
                                        { item?.vineoArea?.salesman?.vineoRoute?.vineoRouteName}
                                       
                                    </td>


                                </tr>
                            )
                        }

                    </tbody>
                </Table>
            </div>
        </>

    )
}

export default AdvanceMappingSetting