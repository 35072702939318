import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from "../../constants/AuthUser";
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faCircleCheck, faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useGetRegisteredUsersByPostMutation } from "../../redux/features/services/postApi";
import { decrement } from "../../redux/features/vineoData/vineoDataSlice";
import { useSelector, useDispatch } from 'react-redux'
import { getVineoUsers, getUser, getVineoCompanyData, getVineoCompanyCategoryData, getVineoFirmData, getVineoRouteData, getVineoAreaData, getVineoAccountCustomerMasterMenu } from "../../redux/features/services/serviceHelper";
import {
    FormGroup, Label, Input, Tab, Table, Container,
    Button, Form, Row, Col, ListGroup, ListGroupItem, FloatingLabel, Card, InputGroup
} from 'react-bootstrap';

import {
    BASEURL_JWTAUTH,
    VINEO_REGISTER_USERINFODATA,
    VINEO_CHECKUSERNAME_AVAILABLE,
    VINEO_DELETE_SELECTEDUSER_USERDATA,
    VINEO_UPDATE_SELECTEDUSER_USERDATA,
    VINEO_USER_CHECKMOBILENUMBER_AVAILABLE
} from "../../constants/Urlforvineo";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import http from "../../constants/AxiosConfig";
import { Typeahead } from "react-bootstrap-typeahead";

export default function Register() {
    const count = useSelector((state) => state.vineoData.value)

    const navigate = useNavigate();
    const { register, handleSubmit, reset, watch, formState: { errors }, setValue } = useForm();
    const { getUser } = AuthUser();
    const [dataofvineoUsers, setDataofVineoUsers] = useState([])
    const [userName, setUserName] = useState({});
    const [usernameAvailable, setUsernameAvailable] = useState(false);
    const [username, setUsername] = useState();
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const uniquenameString = sessionStorage.getItem('vineoRegisteredClientName');
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const vineoPartydata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const vineoUsers = useSelector((state) => state.vineoData.vineouserswithmotherclient)
    const isvineousersLoaded = useSelector((state) => state.vineoData.isvineouserswithmotherclientLoaded)
    const vineoroutedata = useSelector((state) => state.vineoData.vineoroutedata)
    const vineoareadata = useSelector((state) => state.vineoData.vineoareadata)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const requestBody = "RequestBody"
  
    const dispatch = useDispatch()
    useEffect(() => {
        console.log("Component Mounted")
        setDataofVineoUsers(vineoUsers)
    }, [refreshcount, isvineousersLoaded, vineoUsers]);

    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }

    useEffect(() => {
        console.log("Use Effect called ");
        dispatch(getVineoAccountCustomerMasterMenu());
    }, []);
    const updateDatainForm = () => {
        console.log(selectedRow);
        setIsEditDataSelected(true);
        setValue("username", selectedRow.username);
        setValue("nameofUser", selectedRow.nameofUser);
        setValue("userCode", selectedRow.userCode);
        setValue("email", selectedRow.email);
        setValue("purchaseKey", selectedRow.purchaseKey);
        setValue("role", selectedRow.role);
        setValue("gender", selectedRow.gender);
        setValue("enabled", selectedRow.enabled);
        setValue("accountLocked", selectedRow.accountLocked);
        setValue("mobilenumber", selectedRow.mobilenumber);
        setValue("pancardnumber", selectedRow.pancardnumber);
        setValue("ultimateUserUniqueid", selectedRow.ultimateUserUniqueid);
        setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
        setValue("allowedCategory", selectedRow.allowedCategory);
        setValue("userRestrictedtocategoty", selectedRow.userRestrictedtocategoty);
        setValue("dailyShopVisitTarget", selectedRow.dailyShopVisitTarget);
        setValue("reportingtoMotherClientsfa", selectedRow.reportingtoMotherClientsfa);
        setValue("dailyOrderTarget", selectedRow.dailyOrderTarget);
        setValue("allowedPartiesUniqueID", selectedRow.allowedPartiesUniqueID);

    }
    const getdatafromserverforcompanyareaandsalesman = () => {
        dispatch(getVineoAreaData())

    }
    const getdatafromserver = () => {
        console.log("Called to get User state ")
        dispatch(getVineoUsers());
    }

    const getUserNameandCodeChedk = () => {
        if (!(watch(`username`))) {
            notifyError("ENTER USERNAME ")
        }
        else {
            submitFormForUsernamecheck();
        }

    }

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'nameofUser',
            text: 'Name',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'username',
            text: 'UserName',
            sort: true
        }, {
            dataField: 'role',
            text: 'Role',
            sort: true
        }, {
            dataField: 'userCode',
            text: 'Code',
            sort: true
        },
        {
            dataField: 'pancardnumber',
            text: 'PAN',
            sort: true
        },
        {
            dataField: 'mobilenumber',
            text: 'Mob/Tel'
        }];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };


    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)


        }

    };


    const getdatafromserverforsalesmanandarea = () => {

        dispatch(getVineoRouteData())

        console.log("Called to get Route Data ")

    }
    const deleteDatainForm = () => {
        console.log(selectedRow);
        console.log(vineoUsers.indexOf(selectedRow))
        if (selectedRow.username == getUser()) {
            notifyError("You cant Delete Current Login User ")
        }
        else {
            var answer = window.confirm("Do You want to Delete User");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notifyError("Delete Operation Canceled ")
            }

        }

    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEO_DELETE_SELECTEDUSER_USERDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('USER DELETED SUCCESSFULLY with Username is = ' + requestdata.username + '  & Name Of User is = ' + requestdata.nameofUser)

                    // try to reduce server hit by removeing arrray from here only 
                }
                else
                    notifyError("Error While Deleting USER Try Again  Or Login as ADMIN")
            },
            (err) => {
                notifyError("Error While Deleting USER Try Again  Or Login as ADMIN")

            }
        )
    }




    const submitFormForUsernamecheck = async () => {
        try {
            const response = await http.post(BASEURL_JWTAUTH + VINEO_CHECKUSERNAME_AVAILABLE, {

                username: watch('username')
            });

            const { username, code } = response.data;
            const isUsernameAvailable = username ? '-OK' : '-Not Available';

            if (username) {
                notify(`Username is ${isUsernameAvailable}`);

            }
            else {
                notifyError(`Username is ${isUsernameAvailable}`);

            }


            setUsernameAvailable(Boolean(response.data));
        } catch (error) {
            notifyError(`Error From Server with code = ${error}`);
        }
    };

    const submitForm = (requestdata) => {

        if (!isEditDataSelected) {
            http.post(BASEURL_JWTAUTH + VINEO_REGISTER_USERINFODATA, requestdata).then(
                (res) => {
                    console.log(res)
                    if (res.data.errorCode == "0010") {
                        notifyError('Please Use Another UserName as User Already Exists  ')

                    }
                    if (res.data.errorCode == "0100") {
                        notifyError('Please Use Another Mobile Number as Mobile Number Already Exists  ')

                    }
                    else
                        if (res.data.errorCode == "0000") {

                            notify('User Created with Internal UniqueId')
                            reset()
                        }
                        else
                            notifyError("Error While registering User try with another username or Use Purchase key as test Or Login as ADMIN")
                },
                (err) => {
                    notifyError("Error While registering User try with another username or Use Purchase key as test")
                }
            )
        }
        else {
            http.post(BASEURL_JWTAUTH + VINEO_UPDATE_SELECTEDUSER_USERDATA, requestdata).then(
                (res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {

                        notify('User Updated with Internal UniqueId ')
                    }
                    else
                        notifyError("Error While Updating  User try with another username or Login as ADMIN")
                },
                (err) => {
                    console.log(err)
                    notifyError("Error While Updating  User try with another username or Login as ADMIN")

                }
            )
        }
    }

    const checkMobileNumber = (mobilenumber) => {
        if (mobilenumber && mobilenumber.trim().length > 9) {
            // Perform the HTTP call only if the mobile number is not empty
            http.post(BASEURL_JWTAUTH + VINEO_USER_CHECKMOBILENUMBER_AVAILABLE + "/" + mobilenumber).then(
                (res) => {
                    console.log(res);
                    if (res.data.errorCode === "0000") {
                        notify('Success: Mobile Number is Available');
                    } else {
                        notifyError("Invalid Mobile Number");
                    }
                },
                (err) => {
                    notifyError("Error -- Try Again or Login as ADMIN");
                }
            );
        } else {
            // Handle the case where the mobile number is empty or has length 0
            notifyError("Invalid Mobile Number: Mobile number is empty or length is not 10 digit");
        }
    };

    return (
        <div >

            <h3 className='justify-content-center m-1'>Add User </h3>
            <div className="row justify-content-left ">
                <Row >
                    <Col md={4}>
                        <ToolkitProvider keyField='username'
                            data={dataofvineoUsers ? dataofvineoUsers : []}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                        <BootstrapTable wrapperClasses="table-responsive masterform-custom-table-class"
                                            rowClasses="table-striped masterform-custom-row-class" bootstrap4 className="p-2"
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                        {/* <BootstrapTable className="p-2" keyField='username'
                            data={vineoUsers}
                            columns={columns}
                           
                            selectRow={selectRow}
                             /> */}

                    </Col>
                    <Col md={8}  >
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >
                                        <Row>
                                            <Col md={6}>
                                                <Form.Text className="text-muted">
                                                    Unique UserName
                                                </Form.Text>
                                                <InputGroup>
                                                    <Form.Control
                                                        className="form-control custom-form-field"
                                                        disabled={isEditDataSelected}
                                                        placeholder="Unique UserName"
                                                        {...register('username')} required

                                                    />
                                                    <Button variant="info" disabled={false}
                                                        onClick={() => { getUserNameandCodeChedk() }} >
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    </Button>
                                                </InputGroup>

                                            </Col>
                                            <Col md={6}>
                                                <Form.Text className="text-muted">
                                                    password
                                                </Form.Text>

                                                <Form.Control
                                                    className="form-control custom-form-field"
                                                    {...register("password")} required />


                                            </Col>
                                            {/* <Col md={3}><Button><FontAwesomeIcon icon={faRotate} onClick={checkusername} />Check UserName</Button></Col> */}
                                        </Row>
                                    </FormGroup>

                                    <Row>
                                        <Col> <FormGroup>
                                            <Form.Text className="text-muted">
                                                Name Of User
                                            </Form.Text>
                                            <Form.Control
                                                className="form-control custom-form-field"
                                                {...register("nameofUser")} required />
                                        </FormGroup>

                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    10 Digit Mobile Number
                                                </Form.Text>
                                                <InputGroup>
                                                    <Form.Control className="form-control custom-form-field"   {...register("mobilenumber", { minLength: 10, maxLength: 10 })} required />
                                                    {errors.mobilenumber && <span>Enter Mobile Number 10 digit </span>}
                                                    <Button variant="info"
                                                        onClick={() => { checkMobileNumber(watch('mobilenumber')) }} >
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    </Button></InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col>
                                            <FormGroup>
                                                <Form.Text className="text-muted">Email</Form.Text>
                                                <Form.Control
                                                    className="form-control custom-form-field"
                                                    {...register("email", {
                                                        required: "Email is required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Invalid email address",
                                                        },
                                                    })}
                                                />
                                                {errors.email && (
                                                    <span className="text-danger">{errors.email.message}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        {/* <Col>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Area Name
                                                </Form.Text>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        as="select"
                                                        validated="true"
                                                        onSelect={e => {
                                                            console.log("ultiVineoAreaUniqeId ", e.target.value);
                                                            setValue("vineoArea.ultiVineoAreaUniqeId", e.target.value);
                                                        }}
                                                    >
                                                        <option>Select Option</option>
                                                        {vineoareadata
                                                            ? vineoareadata
                                                                .filter(area => !area.salesman)
                                                                .map(area => (
                                                                    <option key={area.ultiVineoAreaUniqeId} value={area.ultiVineoAreaUniqeId}>
                                                                        {area.vineoAreaName}
                                                                    </option>
                                                                ))
                                                            : (
                                                                <option disabled>Loading Data...</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Button variant="primary"
                                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                                        <FontAwesomeIcon icon={faRotate} />
                                                    </Button>
                                                </InputGroup>

                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    PurchaseKey*
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field "  {...register("purchaseKey")} required />

                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Gender
                                                </Form.Text>


                                                <Form.Select className="form-control custom-form-field"  {...register("gender")}>
                                                    <option value="F">male</option>
                                                    <option value="M">female</option>

                                                    <option value="other">other</option>
                                                </Form.Select>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    User Role
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field"  {...register("role")}>
                                                    <option value="salesman">Salesman</option>
                                                    <option value="billingmanager">Billing Manager</option>
                                                    {/* <option value="admin">Admin</option> */}
                                                </Form.Select>
                                            </FormGroup>



                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    ADD CategoryRestriction
                                                </Form.Text>

                                                <Form.Select className="form-control custom-form-field"  {...register("userRestrictedtocategoty")}>
                                                    <option value="false">No</option>
                                                    <option value="true">Yes</option>

                                                </Form.Select >
                                            </FormGroup>

                                            <FormGroup className="m-2">
                                                <Form.Text>Allowed Parties: {selectedRow?.allowedPartiesUniqueID}</Form.Text>
                                                <InputGroup>
                                                    {vineoPartydata && vineoPartydata.length > 0 ? (
                                                        <Typeahead
                                                            disabled={!watch(`userRestrictedtocategoty`)}
                                                            id="PartyNameTypehead"
                                                            onChange={setSelectedCustomer}
                                                            options={vineoPartydata}
                                                            multiple
                                                            placeholder="Choose Party Name..."
                                                            selected={selectedCustomer}
                                                            labelKey={(option) => `${option.vineoCustomerAccountName} - ID: ${option.ultiVineoCustomerAccountUniqeId}`}

                                                            renderMenuItemChildren={(option) => (
                                                                <div>
                                                                    <div>
                                                                        <small>Name: {option.vineoCustomerAccountName}</small>
                                                                        <small>- ID: {option.ultiVineoCustomerAccountUniqeId}</small>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    ) : (
                                                        <span>No party data available</span>
                                                    )}
                                                    <Button variant="primary" onClick={() => { dispatch(getVineoAccountCustomerMasterMenu()); }}>
                                                        <FontAwesomeIcon icon={faRotate} />
                                                    </Button>
                                                </InputGroup>
                                            </FormGroup>




                                            {/* Set selected parties as allowedPartiesUniqueID */}
                                            {setValue(
                                                "allowedPartiesUniqueID",
                                                selectedCustomer
                                                    ?.map((customer) => customer?.ultiVineoCustomerAccountUniqeId)
                                                    .filter((id) => id)
                                                    .join(",")
                                            )}

                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Reporting to MotherClient SFA
                                                </Form.Text>

                                                <Form.Select className="form-control custom-form-field"  {...register("reportingtoMotherClient")}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>


                                                </Form.Select >

                                            </FormGroup>




                                        </Col>


                                        <Col>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    PAN CARD Number
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field "   {...register("pancardnumber", { pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/ })} />
                                                {errors.pancardnum && <span>Enter Proper Pan Card Number  </span>}
                                            </FormGroup>

                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    IsEnabled
                                                </Form.Text>

                                                <Form.Select className="form-control custom-form-field"  {...register("enabled")}>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Form.Select >

                                            </FormGroup>

                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    IsAccountLocked
                                                </Form.Text>

                                                <Form.Select className="form-control custom-form-field" placeholder="accountLocked "  {...register("accountLocked")}>
                                                    <option value="false">False</option>
                                                    <option value="true">True</option>
                                                </Form.Select >

                                            </FormGroup>

                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    vineoRegisteredClientDetails
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field" disabled={isEditDataSelected}
                                                    value={JSON.parse(uniquenameString)} {...register("vineoRegisteredClientDetails.vineoRegisteredClientName")} required />

                                            </FormGroup>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Select Allowed Party Category
                                                </Form.Text>
                                                <Form.Select className="form-control custom-form-field"  {...register("allowedCategory")}>
                                                    <option value="ALL">ALL</option>
                                                    <option value="NAYARA">NAYARA</option>
                                                </Form.Select >
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Daily Shop Visit Target
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field"
                                                    {...register("dailyShopVisitTarget")} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Daily Order Target
                                                </Form.Text>
                                                <Form.Control className="form-control custom-form-field"
                                                    {...register("dailyOrderTarget")} required />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                  
                              
                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add User " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update User" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>

                        </div>

                    </Col>
                </Row>

            </div>
        </div >
    )
}