import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export default function Calculator() {
  const [result, setResult] = useState('');

  const handleButtonClick = (value) => {
    setResult(result + value);
  };

  const handleKeyboardInput = (event) => {
    const keyPressed = event.key;
    if (/^[0-9+\-/*=.]$/.test(keyPressed)) {
      setResult(result + keyPressed);
    } else if (keyPressed === 'Enter') {
      calculate();
    }
  };

  const clear = () => {
    setResult('');
  };

  const calculate = () => {
    try {
      setResult(eval(result).toString());
    } catch (error) {
      setResult('Error');
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardInput);
    return () => {
      document.removeEventListener('keydown', handleKeyboardInput);
    };
  });

  return (
    <Container className="calculator" style={calculatorStyle}>
      <Form.Control type="text" value={result} readOnly style={inputStyle} />
      <Row style={keypadRowStyle}>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('7')} style={keypadButtonStyle}>7</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('8')} style={keypadButtonStyle}>8</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('9')} style={keypadButtonStyle}>9</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('/')} style={keypadButtonStyle}>&divide;</Button>
        </Col>
      </Row>
      <Row style={keypadRowStyle}>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('4')} style={keypadButtonStyle}>4</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('5')} style={keypadButtonStyle}>5</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('6')} style={keypadButtonStyle}>6</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('*')} style={keypadButtonStyle}>&times;</Button>
        </Col>
      </Row>
      <Row style={keypadRowStyle}>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('1')} style={keypadButtonStyle}>1</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('2')} style={keypadButtonStyle}>2</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('3')} style={keypadButtonStyle}>3</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('-')} style={keypadButtonStyle}>&minus;</Button>
        </Col>
      </Row>
      <Row style={keypadRowStyle}>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('0')} style={keypadButtonStyle}>0</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('.')} style={keypadButtonStyle}>.</Button>
        </Col>
        <Col>
          <Button variant="danger" onClick={clear} style={keypadButtonStyle}>C</Button>
        </Col>
        <Col>
          <Button variant="light" onClick={() => handleButtonClick('+')} style={keypadButtonStyle}>+</Button>
        </Col>
      </Row>
      <Row style={keypadRowStyle}>
        <Col>
          <Button variant="success" onClick={calculate} style={equalButtonStyle}>=</Button>
        </Col>
      </Row>
    </Container>
  );
}

// CSS styles
const calculatorStyle = {
  width: '300px',
  margin: '0 auto',
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '5px',
};

const inputStyle = {
  marginBottom: '20px',
  textAlign: 'right',
};

const keypadRowStyle = {
  marginBottom: '10px',
};

const keypadButtonStyle = {
  width: '100%',
  height: '50px',
  fontSize: '20px',
};

const equalButtonStyle = {
  width: '100%',
  height: '50px',
  fontSize: '24px',
};
