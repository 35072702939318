import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';

// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import { faClose, faDeleteLeft, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DEACTIVATE_SELECTEDFIRMDATA, VINEOFORM_DEACTIVATE_SELECTEDPARTYATA, VINEOFORM_DELETE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDDATA, VINEOFORM_TRANSFER_SELECTEDPARTYATA, VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_AUTOIMPORT_NAMEDATA, VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_NAMEDATA } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { getAutoImportVineoFirmData, getAutoImportVineoPartyData } from '../../../redux/features/services/serviceHelper';
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import DatePicker from "react-datepicker";

import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, ButtonGroup
} from 'react-bootstrap';
import { useRef } from 'react';

export default function FirmSettingandPartyTransfer() {


    const areainputref = useRef()

    const dispatch = useDispatch()
    const isvineoaccountcustomerdataLoaded = useSelector((state) => state.vineoData.isvineoaccountcustomerdataLoaded)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const vineodata = useSelector((state) => state.vineoData.vineoaccountcustomerdata)
    const isvineoBankdataLoaded = useSelector((state) => state.vineoData.isvineocustomerbankMasterdataLoaded)
    const vineoareadata = useSelector((state) => state.vineoData.vineoareadata)
    const vineofirmdatafromredux = useSelector((state) => state.vineoData.vineofirmdata)
    const [vineofirmdata, setVineofirmdata] = useState();
    const [vineopartydata, setVineopartydata] = useState();

    const vineofirmAutoimportdatafromredux = useSelector((state) => state.vineoData.AutoImportVineoFirmData)
    const autoimportvineopartydata = useSelector((state) => state.vineoData.autoimportvineopartydata)

    const [vineofirmAutoimportdata, setVineofirmAutoimportdata] = useState()
    const vineobankdata = useSelector((state) => state.vineoData.vineocustomerbankMasterdata)
    const vineogstdata = useSelector((state) => state.vineoData.vineogstdata)
    const [openingdate, setOpeningdate] = useState();
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoCustomerAccountUniqeId": "Working", "vineoCustomerAccountName": "Click Refresh To Load" }])
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [selectedArea, setSelectedArea] = useState();
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedFirm, setSelectedFirm] = useState([])
    const [selectedParty, setSelectedParty] = useState([])
    const [firmName, setFirmName] = useState("");
    const [partyName, setPartyName] = useState("");
    const formData = new FormData();
    const [showActiveFirms, setShowActiveFirms] = useState(true);
    const [showActiveParties, setShowActiveParties] = useState(true);
    useEffect(() => {

    }, []);

    useEffect(() => {
        console.log("Refreshing Area")
        setVineofirmdata(vineofirmdatafromredux);
        setVineopartydata(autoimportvineopartydata);
        setVineofirmAutoimportdata(vineofirmAutoimportdatafromredux)
    }, [refreshcount]);


    const { register, handleSubmit, setValue, reset, watch, formState: { errors }, control } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        if (!data?.vineoFirmType) {
            notifyError("Select Firm Type");
        }
        else if (!selectedArea?.[0]?.ultiVineoAreaUniqeId) {
            notifyError("Select Area")
        }
        else {
            submitForm(data)
        }
    }

    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const deactivateFirm = (data) => {
        if (!selectedFirm?.[0]) {
            notifyWarning("Please Select Firm ")
        }
        else {
            http.post(BASEURL_JWTAUTH + VINEOFORM_DEACTIVATE_SELECTEDFIRMDATA, data).then(
                (res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify('Success:- Firm  Account Deactivated' + res.data?.errorMessage)
                        reset()
                    }
                    else {
                        notifyError('Error From Server' + res.data?.errorMessage)
                    } //console.log(res.data)
                },
                (err) => {
                    console.log(err)
                    notifyError('Error While Registering Customer Account  Import')
                }
            )
        }
    }


    const deactivateParty = (data) => {
        if (!selectedParty?.[0]) {
            notifyWarning("Please select Party")
        }
        else {
            http.post(BASEURL_JWTAUTH + VINEOFORM_DEACTIVATE_SELECTEDPARTYATA, data).then(
                (res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify('Success:- Firm  Account Deactivated' + res.data?.errorMessage)
                        reset()
                    }
                    else {
                        notifyError('Error From Server' + res.data?.errorMessage)
                    } //console.log(res.data)
                },
                (err) => {
                    console.log(err)
                    notifyError('Error While Registering Customer Account  Import')
                }
            )
        }
    }

    const transferParty = (data) => {
     
        console.log(!selectedParty)
        console.log(watch(`vineoRegisteredClientName`))
        if (!selectedParty?.[0] && !(watch(`vineoRegisteredClientName`)) ) {
            notifyWarning("Please select Party and Client ID Both are required ")
        }
        else {
            const formData1 = new FormData();
            formData1.append('clientID',watch(`vineoRegisteredClientName`) )
            formData1.append('partyID', selectedParty?.[0]?.ultiVineoCustomerAccountUniqeId )

            http.post(BASEURL_JWTAUTH + VINEOFORM_TRANSFER_SELECTEDPARTYATA, formData1).then(
                (res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify('Success:- Firm  Account Deactivated' + res.data?.additionalInfo)
                        reset()
                    }
                    else {
                        notifyError('Error From Server' + res.data?.additionalInfo)
                    } //console.log(res.data)
                },
                (err) => {
                    console.log(err)
                    notifyError('Error While Registering Customer Account  Import')
                }
            )
        }
    }

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoCustomerAccountUniqeId", selectedRow.ultiVineoCustomerAccountUniqeId);
            setValue("vineoCustomerAccountName", selectedRow.vineoCustomerAccountName);
            setValue("vineoCustomerAccountaddress", selectedRow.vineoCustomerGstNumber);
            setValue("vineoCustomerGstNumber", selectedRow.vineoCustomerAccountaddress);
            setValue("vineoCustomerAccountopeningDate", selectedRow.vineoCustomerAccountopeningDate);
            setValue("vineoCustomerAccountTown", selectedRow.vineoCustomerAccountTown);
            setValue("vineoCustomerAccountphoneNumber", selectedRow.vineoCustomerAccountphoneNumber);
            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);


        }
        console.log("Update Firm ")
    }


    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ACCOUNTCUSTACCNTINFO_AUTOIMPORT_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Customer Account  Imported With Internal Id' + res.data?.errorMessage)
                    reset()
                }
                else {
                    notifyError('Error While Registering Customer Account  Import From Server' + res.data?.errorMessage)
                } //console.log(res.data)
            },
            (err) => {
                console.log(err)
                notifyError('Error While Registering Customer Account  Import')
            }
        )
    }

    const getFirmDataFromServer = () => {
        console.log(firmName)
        if (firmName.length < 3) {
            notifyWarning("Enter More Characters ")
        }
        else {
            formData.append('firmName', firmName)
            dispatch(getAutoImportVineoFirmData(formData));
        }
    }

    const getPartyDataFromServer = () => {
        console.log(partyName)
        if (partyName.length < 3) {
            notifyWarning("Enter More Characters ")
        }
        else {
            formData.append('partyName', partyName)
            dispatch(getAutoImportVineoPartyData(formData));
        }
    }



    return (
        <div>


            <div className="row justify-content-left ">
                <Row>

                    <Col md={12}>
                        <div >
                            <div className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <Card className='p-3'>
                                        <h3 className='justify-content-center m-1'>Activate Deactivate Firm </h3>
                                        <Row>
                                           
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Form.Text>Search Firm With Name -</Form.Text>
                                                        <InputGroup>
                                                            <Form.Control
                                                                className="form-control"
                                                                value={firmName}
                                                                onChange={(e) => { setFirmName(e.target.value); }}
                                                            />
                                                            <Button variant="primary" onClick={getFirmDataFromServer}>
                                                                <FontAwesomeIcon icon={faRotate} />
                                                            </Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Form.Text>Select Firm *  GST:- {(selectedFirm?.[0]?.vineofirmGst?.vineoGstNumber)}  -ClientID :{(selectedFirm?.[0]?.vineoRegisteredClientDetails?.vineoRegisteredClientName)}</Form.Text>
                                                        <Controller
                                                            control={control}
                                                            name={`vineofirmArray`}
                                                            render={({ field, fieldState }) => (
                                                                <div>
                                                                    <InputGroup>
                                                                        <Typeahead
                                                                            {...field}
                                                                            id="FirmNameTypehead"
                                                                            size='sm'
                                                                            onChange={setSelectedFirm}
                                                                            labelKey={"vineoFirmFirmName"}
                                                                            selected={selectedFirm}
                                                                            options={vineofirmAutoimportdata ? vineofirmAutoimportdata.filter((firm) => showActiveFirms === null || firm.active === showActiveFirms) : []}
                                                                            className={fieldState.invalid ? "is-invalid" : ""}
                                                                        />
                                                                        <p id="typeaheadError" className="invalid-feedback">
                                                                            {fieldState.error?.message}
                                                                        </p>
                                                                    </InputGroup>
                                                                </div>
                                                            )}
                                                        />
                                                    </FormGroup>
                                                    <ButtonGroup className="m-1">
                                                        <Button className="m-1"
                                                            variant="secondary"
                                                            onClick={() => setShowActiveFirms(true)}
                                                            active={showActiveFirms === true}
                                                        >
                                                            Show Active Firms
                                                        </Button>
                                                        <Button className="m-1"
                                                            variant="secondary"
                                                            onClick={() => setShowActiveFirms(false)}
                                                            active={showActiveFirms === false}
                                                        >
                                                            Show Inactive Firms
                                                        </Button>
                                                        <Button className="m-1"
                                                            variant="secondary"
                                                            onClick={() => setShowActiveFirms(null)}
                                                            active={showActiveFirms === null}
                                                        >
                                                            Show All Firms
                                                        </Button>
                                                    </ButtonGroup>
                                                </Col>

                                            <Row>
                                                <Col md={2}>
                                                    <Form.Text > {selectedFirm?.[0] ? (selectedFirm?.[0]?.active ? "Firm is Active" : "Firm is Deactive") : <></>}</Form.Text>
                                                    <Button
                                                        variant="danger mt-1"
                                                        onClick={() => {
                                                            const confirmed = window.confirm("Do you really want to Perform This Operation on - " + selectedFirm?.[0]?.vineoFirmFirmName + " Firm?");
                                                            if (confirmed) {
                                                                deactivateFirm(selectedFirm?.[0])
                                                            }
                                                        }}
                                                    >
                                                        {selectedFirm?.[0]?.active ? "Deactivate" : "Re-Activate"}
                                                    </Button>



                                                </Col>
                                            </Row>
                                        </Row>
                                    </Card>
                                    <Row className='mt-4'></Row>

                                    <Card className='p-3'>
                                        <h3 className='justify-content-center m-1'>Activate Deactivate Party / Transfer  </h3>
                                        <Row>
                                            <Col >  <FormGroup >

                                                <Form.Text >
                                                    Search Party With Name -
                                                </Form.Text>
                                                <InputGroup>
                                                    <Form.Control
                                                        className="form-control"
                                                        value={partyName}
                                                        onChange={(e) => { setPartyName(e.target.value); }}
                                                    />
                                                    <Button variant="primary"
                                                        onClick={getPartyDataFromServer
                                                        }>
                                                        <FontAwesomeIcon icon={faRotate} />
                                                    </Button>
                                                </InputGroup>
                                            </FormGroup></Col>


                                            <Col md={6}>
                                                <Row>
                                                   
                                                  
                                                        <FormGroup>
                                                            <Form.Text>Select Party *  GST:- {(selectedParty?.[0]?.vineoCustomerGstNumber)}  -ClientID :{(selectedParty?.[0]?.vineoArea?.salesman?.vineoRegisteredClientDetails?.vineoRegisteredClientName)} -/- {(selectedParty?.[0]?.vineoArea?.salesman?.vineoRegisteredClientDetails?.clientfirmname)} {selectedParty?.[0] ? (selectedParty?.[0]?.active ? "Party is Active" : "Party is Deactive") : <></>} </Form.Text>
                                                            <Controller
                                                                control={control}
                                                                name={`vineopartyArray`}
                                                                render={({ field, fieldState }) => (
                                                                    <div>
                                                                        <InputGroup>
                                                                            <Typeahead
                                                                                {...field}
                                                                                id="PartyNameTypehead"
                                                                                size='sm'
                                                                                onChange={setSelectedParty}
                                                                                labelKey={"vineoCustomerAccountName"}
                                                                                selected={selectedParty}
                                                                                options={vineopartydata ? vineopartydata.filter((party) => showActiveParties === null || party.active === showActiveParties) : []}
                                                                                className={fieldState.invalid ? "is-invalid" : ""}
                                                                            />
                                                                            <p id="typeaheadError" className="invalid-feedback">
                                                                                {fieldState.error?.message}
                                                                            </p>
                                                                        </InputGroup>
                                                                    </div>
                                                                )}
                                                            />
                                                        </FormGroup>
                                                        <ButtonGroup className="m-1">
                                                            <Button className="m-1"
                                                                variant="secondary"
                                                                onClick={() => setShowActiveParties(true)}
                                                                active={showActiveParties === true}
                                                            >
                                                                Show Active Parties
                                                            </Button>
                                                            <Button className="m-1"
                                                                variant="secondary"
                                                                onClick={() => setShowActiveParties(false)}
                                                                active={showActiveParties === false}
                                                            >
                                                                Show Inactive Parties
                                                            </Button>
                                                            <Button className="m-1"
                                                                variant="secondary"
                                                                onClick={() => setShowActiveParties(null)}
                                                                active={showActiveParties === null}
                                                            >
                                                                Show All Parties
                                                            </Button>
                                                        </ButtonGroup>
                                                  
                                                </Row>

                                            </Col>

                                            <Row>
                                                <Row>


                                                    <Col md={6}>
                                                        <Form.Text >
                                                            This Will be Irreversible Operation
                                                        </Form.Text>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => {
                                                                const confirmed = window.confirm("Do you really want to Perform This Operation on - " + selectedParty?.[0]?.vineoCustomerAccountName + " Party?");
                                                                if (confirmed) {
                                                                    deactivateParty(selectedParty?.[0])
                                                                }
                                                            }}
                                                        >
                                                            {selectedParty?.[0]?.active ? "Deactivate" : "Re-Activate"}
                                                        </Button>
                                                    </Col>

                                                </Row>

                                            </Row>
                                            <Col md={6}>
                                                <FormGroup >
                                                    <Form.Text >
                                                        Registered Client Id of Distributor
                                                    </Form.Text>
                                                    <Form.Control type="Number" className="form-control "   {...register("vineoRegisteredClientName")} required />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Text >
                                                    This Will be Irreversible Operation
                                                </Form.Text>
                                                <Button
                                                    variant="warning "
                                                    onClick={() => {
                                                        const confirmed = window.confirm("Do you really want to Perform This Operation on - " + selectedParty?.[0]?.vineoCustomerAccountName + " Party? , Please Note this party will BE Deactivated in Current Firm and then Reactvated in New Frim, All Mapping of Area and Salesman will be Required .");
                                                        if (confirmed) {
                                                            transferParty(selectedParty?.[0])
                                                        }
                                                    }}
                                                >
                                                    {selectedParty?.[0]?.active ? "Transfer" : "Transfer"}
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Card>


                                  {/* <Row>
                                  <input className="btn btn-primary m-2" type="submit" value="Submit" />
                                    <button type="button" onClick={() => reset()} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                  </Row> */}

                                </form>
                            </div>
                        </div>

                    </Col>
                </Row>

            </div>
        </div>
    )

}