import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_UPDATE_SELECTED_SPECIALGROUP_NAMEDATA, VINEOFORM_DELETE_SELECTED_SPECIALGROUP_NAMEDDATA } from '../../../constants/Urlforvineo';
import { getVineoSpecialGroupMasterData } from '../../../redux/features/services/serviceHelper';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';

export default function SpecialGroupMaster() {

    const dispatch = useDispatch()
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{"ultiVineoSpecialGroupUniqeId":"Working","vineoSpecialGroupName":"Click Refresh To Load"}])
    const isvineospecialgroupmasterdataLoaded = useSelector((state) => state.vineoData.isvineospecialgroupmasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineospecialgroupmasterdata)
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});

    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoSpecialGroupName',
            text: 'SpecialGroup Name',
            sort: true,
            formatter: rowClickedfunction,
        }
    
    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };
    const getdatafromserver = () => {

        console.log("Called to get Special Group Data ")

        dispatch(getVineoSpecialGroupMasterData())

        if ((typeof vineodata != 'undefined') && Object.keys(vineodata).length > 0) 
        {
            isvineospecialgroupmasterdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{"ultiVineoSpecialGroupUniqeId":"Working","vineoSpecialGroupName":"Click Again Refresh To Load"}])
       
        }
       
        console.log("Empty Data Received form Server")
    }

    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoSpecialGroupUniqeId", selectedRow.ultiVineoSpecialGroupUniqeId);
            setValue("vineoSpecialGroupName", selectedRow.vineoSpecialGroupName);
            
             setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
      
           
        }
        console.log("Update Special Group ")
    }
    
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
              var answer = window.confirm("Do You want to Delete Selected Special Group ");
              if (answer) {
                  submitFormForDeleteSelectedRow(selectedRow);
              }
              else {
                  notify("Delete Operation Canceled ")
              }
          }
    }
    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_SPECIALGROUP_NAMEDATA, data).then(
            (res)=>{
                console.log(res)
                if(res.data.errorCode== "0000"){
                notify('Success:- Special Group  Created With Internate Id')
                reset()
            }
            else
            notify('Error While Registering Special Group Info From Server')
            //console.log(res.data)
        },
        (err)=>{
            notify('Error While Registering Special Group Info')
        }
        )
    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_SPECIALGROUP_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- SPECIAL GROUP  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoSpecialGroupName )
                }
                else
                    notify("Error While Deleting FIRM  Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting FIRM Try Again  Or Login as ADMIN")

            }
        )
    }
    return (
        <div>
        <h3 className='justify-content-center m-1'>Add Special Group Information </h3>
              <div className="row justify-content-left ">
                  <Row>
                      <Col md={4}>
                          <ToolkitProvider keyField='ultiVineoSpecialGroupUniqeId'
                              data={dataofvineoTableData}
                              columns={columns}
                              search   >
                              {
                                  props => (
                                      <div>
                                          <div >
                                              <Button variant="primary"
                                                  onClick={getdatafromserver} >
                                                  <FontAwesomeIcon icon={faRotate} />
                                              </Button>
                                              <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                              <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                              <SearchBar className="m-1" {...props.searchProps} />
                                          </div>
                                              <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                              {...props.baseProps}
                                              pagination={paginationFactory(options)}
                                              selectRow={selectRow}
                                          // rowEvents={ rowEvents }
                                          />
  
                                      </div>
                                  )
                              }
                          </ToolkitProvider>
  
                      </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                <fieldset disabled>
                                    <Form.Control className='form-control'    placeholder ='Special Group ID: This Special Group Code Is Auto Generated '  {...register('ultiVineoSpecialGroupUniqeId')}/>
                                </fieldset>
                            </FormGroup>
                            <FormGroup >
                            <Form.Text className="text-muted">
                               Special Group Code
                            </Form.Text>
                            <Form.Control  className="form-control "   {...register("vineoSpecialGroupCode")} required />
                        </FormGroup>
                                    <FormGroup >

                                        <Form.Text >
                                            Group Name
                                        </Form.Text>
                                        <Form.Control className="form-control "   {...register("vineoSpecialGroupName")} required />

                                    </FormGroup>

                                    <input className="btn btn-primary m-2" type="submit" value="Save " />
                                    <button type="button" className="btn btn-secondary">Cancel</button>

                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row>

            </div>
        </div>
    )

}
