import React from "react";
import {
    FormGroup, Label, Input, InputGroup, Alert,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, Table
} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { getVineoAccountCustomerMasterMenu, getVineoAccountVendorCustomerMasterMenu, getVineoAreaData, getVineoCompanyData, getVineoProductData, getVineoRouteData, getVineoSalesmanUsers, getVineoUsers } from '../../../redux/features/services/serviceHelper';
import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { BASEURL_JWTAUTH, VINEO_MAP_VENDORTOPRODUCT } from "../../../constants/Urlforvineo";

import { Typeahead } from 'react-bootstrap-typeahead';


export default function VendorToProduct() {
    useEffect(() => {
        // dispatch(getVineoProductData())
        dispatch(getVineoAccountVendorCustomerMasterMenu())
        dispatch(getVineoCompanyData())

    }, []);
    const dispatch = useDispatch()
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    
    const [selectedRow, setSelectedRow] = useState({});
    const [tableData, setTableData] = useState([{ "vineoAreaName": "Click Refresh to Laod ", "vineoAreaName": "Click Refresh to Load " }]);
    const vineoareadataforMappingTable = [{ "vineoAreaName": "Click Refresh to Load ", "companyName": "Loading", "nameofUser": "Click Refresh to Load " }]
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [searchterm, setSearchterm] = useState("");
    const vineoproductdata = useSelector((state) => state.vineoData.vineoproductdata)
    const vineovendordata = useSelector((state) => state.vineoData.vineoaccountvendorcustomerdata)
    const vineocompanydata = useSelector((state) => state.vineoData.vineocompany)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)

    const [selectedProduct, setSelectedProduct] = useState([])
    const [selectedCompany, setSelectedCompany] = useState([])
    const [selectedVendor, setSelectedVendor] = useState([])
    const [deleteoldMapping, setDeleteoldMapping] = useState(false)

    let { AreaName } = [{}];

    const { register, handleSubmit, reset, watch, formState: { errors }, setValue } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        if (!data.vineoVendorAccount) {
            notifyError("Please Select Vendor ")
        }
        else {
            submitForm(data)

        }

    }
    const getdatafromserverforcompanyareaandsalesman = () => {

        dispatch(getVineoAccountVendorCustomerMasterMenu())
        // dispatch(getVineoProductData())
        dispatch(getVineoCompanyData())
        console.log("Called to get Company Data Area Data and Customer Account/party Data ")


    }
    useEffect(() => {

        console.log("Loading on Refresh count" )
       setIsLoading(false)
      

    }, [refreshcount]);

    const getdatafromserverforsalesmanandarea = () => {
        setIsLoading(true)
        dispatch(getVineoAccountVendorCustomerMasterMenu())
        // dispatch(getVineoProductData())
        console.log("Called to get Area and User Data Data ")

    }

    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEO_MAP_VENDORTOPRODUCT, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorCode == "0000") {
                    notify(' Success:-  Vendor MApped to Product Succesfully ')
                    reset();

                }
                else
                {notifyError("Error While Mapping Vendor to Product From Server ")}
                //  console.log(res.data)

            },
            (err) => {
                console.log(err.data)

                notifyError("Error While Mapping Vendor to Product  ")
            }
        )
    }

    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }
    const [areaoptions, setAreaoptions] = useState({ "value": "Workinginsidemap ", "label": "Getting Data" });

    const columns = [{
        dataField: 'vineoAreaName',
        text: 'Area Name',
        sort: true,
        editable: false
    },
    {
        dataField: 'companyName',
        text: 'MappedCompany',
        sort: true,
        formatter: rowClickedfunction,
    },
    {
        dataField: 'nameofUser',
        text: 'Party / Customer Name'

    }
    ];

    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '100', value: 100
        },]
    };
    const { SearchBar } = Search;

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)

        }

    };

    return (
        <div>

            <h1 align="center"> MAP Vendor to Product </h1>

            <Row>
                <form onSubmit={handleSubmit(onSubmit)}>



                    <Row>
                        <Col >

                            <FormGroup >

                                <Form.Text className="text-muted">
                                    Select Vendor*
                                </Form.Text>
                                <InputGroup>
                                    <Typeahead

                                        id="VendorTypehead"
                                        onChange={setSelectedVendor}
                                        options={vineovendordata}
                                        placeholder="Choose Vendor to Map to Product"
                                        selected={selectedVendor}
                                        labelKey={"vineoVendorAccountName"}
                                    />
                                    {setValue("vineoVendorAccount", selectedVendor[0])}
                                    <Button variant="primary"
                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>
                                {/* <Form.Text className="text-muted">
                                    Multiple Products To Map
                                </Form.Text>
                                <InputGroup>
                                    <Typeahead
                                        disabled={true}
                                        id="VendorProdcutTypehead"
                                        onChange={setSelectedProduct}
                                        multiple
                                        options={vineoproductdata}
                                        placeholder="Choose Vendor to Map to Product"
                                        selected={selectedProduct}
                                        labelKey={"vineoProductName"}
                                    />

                                    {setValue("vineoProductList", selectedProduct)}
                                    <Button variant="primary"
                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup> */}

                                <Form.Text className="text-muted">
                                    Multiple Companies To Map*
                                </Form.Text>
                                <InputGroup>
                                    <Typeahead

                                        id="VendorCompanyTypehead"
                                        onChange={setSelectedCompany}
                                        multiple
                                        options={vineocompanydata}
                                        placeholder="Choose Comapany to Map to Product"
                                        selected={selectedCompany}
                                        labelKey={"companyName"}
                                    />
                                    {setValue("vineoCompanies", selectedCompany)}
                                    <Button variant="primary"
                                        onClick={getdatafromserverforcompanyareaandsalesman} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <FormGroup style={{ marginTop: '20px' }}>
                                <Form.Label>Selecting this Will delete old Mappinga and Will set this Mapping as Default for This Area</Form.Label>
                                <Form.Check
                                    onChange={(e) => { setDeleteoldMapping(!deleteoldMapping) }}
                                    label='Delete Old Mapping'
                                    id="Checkbox"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {setValue("deleteoldMapping", deleteoldMapping)}
                    <Button className="btn btn-primary m-2" type="submit" value="Add Batch ">
                        Add mapping
                    </Button>
                </form>


            </Row>
            <div className="mt-5">
                <Card>

                    <InputGroup>
                        <Form.Control className="m-1" type="text" placeholder="Search ...." onChange={(e) => setSearchterm(e.target.value)} />
                        <Button variant="primary" className="m-1" disabled={isLoading}
                            onClick={getdatafromserverforsalesmanandarea} >
                            <FontAwesomeIcon icon={faRotate} />{isLoading? <>Loading</>: <>Load Mapping Data</>}
                        </Button>
                    </InputGroup>

                    <Table striped bordered hover size="sm"  >
                        <tbody>
                            <tr>
                                <td>Vendor Name</td>
                                <td>Product Names</td>
                                <td>Batch Names </td>


                            </tr>


                            {
                                vineovendordata?.filter((item) => {
                                    if (searchterm == "") {
                                        return item;
                                    }
                                    else if (item.vineoVendorAccountName.toLowerCase().includes(searchterm.toLowerCase())) {

                                        return item;

                                    }
                                }).map((item, i) =>
                                    <tr key={i}>

                                        <td>{item?.vineoVendorAccountName}</td>


                                        <td>
                                            <>
                                                {

                                                    (<Table responsive bordered size="sm"  >
                                                        <tbody>
                                                            {
                                                                item?.vineoProductset?.map((data1, i1) =>

                                                                    <tr key={i1}>
                                                                        <td>{data1.vineoProductName}</td>

                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>)
                                                }
                                            </>
                                            {/*  */}
                                        </td>
                                        <td>

                                            <>
                                                {

                                                    (<Table responsive bordered size="sm"   >
                                                        <tbody>
                                                            {
                                                                item?.vineoProductset?.vineoBatchSet?.map((data2, i2) =>

                                                                    <tr key={i2}>
                                                                        <td>{data2.vineoBatchName}</td>

                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>)
                                                }
                                            </>

                                        </td>



                                    </tr>
                                )
                            }

                        </tbody>
                    </Table>


                </Card>
            </div>
        </div>

    )
}