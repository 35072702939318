import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_UPDATE_SELECTEDGROUPDATA, VINEOFORM_DELETE_SELECTEDGROUPDATA, BASEURL_JWTAUTH } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card
} from 'react-bootstrap';
import { getVineoCompanyData, getVineoCompanySubCategoryData, getVineoGodownData, getVineoCompanyCategoryData, getVineoGroupyData } from '../../../redux/features/services/serviceHelper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

export default function GroupMaster() {

    useEffect(() => {

        // dispatch(getVineoCompanyData());
        // dispatch(getVineoCompanyCategoryData());
        dispatch(getVineoCompanySubCategoryData());
    }, []);



    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoGroupUniqeId": "Working", "vineoGroupName": "Click Refresh To Load" }])
    const [selected, setSelected] = useState([]);

    const dispatch = useDispatch()
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const count = useSelector((state) => state.vineoData.value)
    const vineosubcatadata = useSelector((state) => state.vineoData.vineosubcategory)
    const vineocatadata = useSelector((state) => state.vineoData.vineocategory)
    const vineocompdata = useSelector((state) => state.vineoData.vineocompany)
    const dataLoaded = useSelector((state) => state.vineoData.vineogroupdata)
    const isDataLoaded = useSelector((state) => state.vineoData.isvineogroupdataLoaded)


    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        if(selected[0])
        {
            submitForm(data)
        }
        else{
            notify("Pls Select SubCategoty")
        }
      
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)

        }

    };
    const { SearchBar } = Search;
    const columns = [
        {
            dataField: 'vineoGroupName',
            text: 'GRP NAME',
            sort: true,
            formatter: rowClickedfunction,
        },

        {
            dataField: 'vineoSubCategory.companySubCategoryName',
            text: 'SUBCAT.',
            sort: true,

        }, {
            dataField: 'vineoSubCategory.vineoCategory.companyCategoryName',
            text: 'CAT',
            sort: true
        }, {
            dataField: 'vineoSubCategory.vineoCategory.vineoCompany.companyName',
            text: 'COMP',
            sort: true
        },
    ];


    const numbers = [1, 2, 3, 4, 5];
    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const updateDatainForm = () => {
        console.log(selectedRow);
        setIsEditDataSelected(true);
        setValue("vineoGroupName", selectedRow.vineoGroupName);
        setValue("ultiVineoGroupUniqeId", selectedRow.ultiVineoGroupUniqeId);
        setValue("vineoSubCategory.ultiVineoSubCategoryUniqeId", selectedRow.vineoSubCategory.ultiVineoSubCategoryUniqeId);
        setValue("vineoSubCategory.companySubCategoryName", selectedRow.vineoSubCategory.companySubCategoryName);
        setValue("vineoSubCategory.ultiVineoSubCategoryUniqeId", selectedRow.vineoSubCategory.ultiVineoSubCategoryUniqeId);

        // setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
        setValue("companySubCategoryName", selectedRow.companySubCategoryName);

    }


    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Area ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTEDGROUPDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- GROUP DATA  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoGroupName)
                }
                else
                    notify("Error While Deleting GROUP DATA  Or Login as ADMIN")
            },
            (err) => {
                notify("Error While DeletingGROUP DATAT Try Again  Or Login as ADMIN")

            }
        )
    }

    const getdatafromserver = () => {
        console.log(isDataLoaded)
        console.log("Called to get Group Data ")
        dispatch(getVineoGroupyData())
        console.log(isDataLoaded)

        if ((typeof dataLoaded != 'undefined') && Object.keys(dataLoaded).length > 0) {
            isDataLoaded ? setDataofvineoTableData(dataLoaded) : setDataofvineoTableData([{ "ultiVineoGroupUniqeId": "Working", "vineoGroupName": "Click Refresh To Load" }])
        }
        console.log("Empty Data Received form Server")

    }


    const submitForm = (data) => {
        // api call
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTEDGROUPDATA, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorCode == "0000") {
                    notify('Success:- GROUP  Created with Internal Id')
                    console.log(res.data)
                    reset()
                }
                else
                    notify("Error While Creating GROUP From Server ")


            },
            (err) => {
                notify("Error While Creating GROUP  ")
            }
        )
    }

    return (
        <div>
            <h3 className='justify-content-center m-1'>Group Master </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoGroupUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >


                                        <fieldset disabled>
                                            <Form.Control className="form-control "
                                                placeholder="Group Code: This Group Code Will Be Auto Generated"
                                                {...register("GroupCode")} />
                                        </fieldset>
                                    </FormGroup>
                                    <FormGroup >
                                    <Form.Text className="text-muted">
                                        Group Code
                                    </Form.Text>
                                    <Form.Control  className="form-control "   {...register("vineoGroupCode")} required />
                                </FormGroup>
                                    <FormGroup >

                                        <Form.Text >
                                            Group Name
                                        </Form.Text>
                                        <Form.Control className="form-control "   {...register("vineoGroupName")} required />

                                    </FormGroup>
                                    <FormGroup >
                                        <Form.Text >
                                            'SubCategory Name || Category Name || Company Name'
                                        </Form.Text>
                                       

                                    </FormGroup>
                                    <Typeahead
                                            id="SubgroupSlected"
                                            onChange={setSelected}
                                            onClick={console.log(selected)}
                                            options={vineosubcatadata}
                                            placeholder="Choose Category Type Name..."
                                            selected={selected}
                                            labelKey={"companySubCategoryName"}
                                            renderMenuItemChildren={(option) => (
                                                <div>
                                                  <div>
                                                    <small>SubCat:  {option.companySubCategoryName}</small>
                                                    <small>Category: {option.vineoCategory.companyCategoryName}</small>
                                                    <small>Category: {option.vineoCategory.companyCategoryName}</small>
                                                    <small>Comapny: {option.vineoCategory.vineoCompany.companyName}</small>
                                                  </div>
                                                </div>
                                              )}

                                        />
                                        {console.log("Selected" + selected)}
                                        {setValue("vineoSubCategory.ultiVineoSubCategoryUniqeId", selected?.[0]?.ultiVineoSubCategoryUniqeId)}
                                  

                                   
                                    {/*
                                    <Form.Select className="form-control" {...register("vineoSubCategory.ultiVineoSubCategoryUniqeId")} required>

                                            {
                                                vineosubcatadata.map((vineosubcatadata) =>
                                                    <option key={vineosubcatadata.ultiVineoSubCategoryUniqeId} value={vineosubcatadata.ultiVineoSubCategoryUniqeId}>{vineosubcatadata.companySubCategoryName}</option>
                                                )
                                            }
                                        </Form.Select>
                                    <FormGroup >

                                        <Form.Text >
                                            Category Name
                                        </Form.Text>
                                        <Form.Select className="form-control" {...register("vineoSubCategory.vineoCategory.ultiVineoSubCategoryUniqeId")} required>

                                            {
                                                vineocatadata.map((vineocatadata) =>
                                                    <option key={vineocatadata.ultiVineoCategoryUniqeId} value={vineocatadata.ultiVineoCategoryUniqeId}>{vineocatadata.companyCategoryName}</option>
                                                )
                                            }
                                        </Form.Select>
                                    </FormGroup>

                                    <FormGroup >

                                        <Form.Text >
                                            Company Name
                                        </Form.Text>
                                        <Form.Select className="form-control" {...register("vineoSubCategory.vineoCategory.vineoCompany.ultiVineoCompanyUniqeId")} required>

                                            {
                                                vineocompdata.map((vineocompdata) =>
                                                    <option key={vineocompdata.ultiVineoCompanyUniqeId} value={vineocompdata.ultiVineoCompanyUniqeId}>{vineocompdata.companyName}</option>
                                                )
                                            }
                                        </Form.Select>
                                    </FormGroup> */}

                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add Group " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update Group" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>
                        </div>

                    </Col>
                </Row>

            </div>
        </div>
    )

}