import React from "react";
import { Col, Row, Form, FormGroup, Button, InputGroup, Card } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ADDBILL, VINEOFORM_UPDATE_SELECTED_CREATELOADBILL } from '../../../../constants/Urlforvineo';
import moment from "moment";
import { faClose, faRotate, faMoneyCheck, faUserEdit, faEdit, faPrint, faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import { getVineoVanMasterData } from '../../../../redux/features/services/serviceHelper';
import { getVineoAddBillData, getVineoAddBillDatawithdate } from '../../../../redux/features/services/entriesHelperService';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector, useDispatch } from 'react-redux'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from "../../../../constants/AxiosConfig";
import { Link } from "react-router-dom";
import Billprint from "./Billprint";
import VanMaster from "../../master/VanMaster";
import BillSettle from "./BillSettle";
import BillEditPaymentOnly from "./BillEditPaymentOnly";
import BillprintFormat2 from "./BillprintFormat2";



export default function CreateLoad() {
    useEffect(() => {
        dispatch(getVineoVanMasterData());
    }, []);
    const dispatch = useDispatch()

    const [tableData, setTableData] = useState();
    const vineoloadvanData = useSelector((state) => state.vineoData.vineovanmasterdata)
    const vineoaddbilldatawithdate = useSelector((state) => state.vineoData.vineoaddbilldatawithdate);
    const isvineoaddbilldatawithdateLoaded = useSelector((state) => state.vineoData.isvineoaddbilldatawithdateLoaded);
    const [loaddate, setLoaddate] = useState(new Date());
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
    const [todate, setTodate] = useState(new Date());
    const [showLoadcreated, setShowLoadcreated] = useState(false);
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)

    const [vanSelected, setVanSelected] = useState([])
    const [openAddVan, setOpenAddVan] = useState(false)
    const [totalloadBillValue, setTotalloadBillValue] = useState(0)
    const [openeditBillPayment, setOpeneditBillPayment] = useState(false)
    const [numberofloadbillselected, setNumberofloadbillselected] = useState(0)
    const [openprint, setOpenprint] = React.useState(false);
    const [dataforprint, setDataforprint] = useState();
    const [selectAll, setSelectAll] = useState(false);


    let selectedBillsArray = []
    const { register, handleSubmit, setValue, reset, watch, formState: { errors }, control } = useForm();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const [openeditBill, setOpeneditBill] = React.useState(false);

    useEffect(() => {
        console.log("Refreshing on state Change ")
        const timer = setTimeout(() => console.log('Initial timeout!'), 800);
        setTableData(vineoaddbilldatawithdate)
    }, [isvineoaddbilldatawithdateLoaded, refreshcount]);


    const onSubmit = (data) => {
        if (vanSelected[0] && gridApi.api.getSelectedNodes().length > 0) {
            submitForm(data)
            console.log(data);
        }
        else {
            notifyError("Please Select Van And Bills For Load  ")
        }


    }
    const submitForm = (data) => {

        if (vanSelected[0] && gridApi.api.getSelectedNodes().length > 0) {
            http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_CREATELOADBILL, data).then(
                (res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify('Success:-CreateLoad  Created With Internate Id' + res.data.errorMessage)

                    }
                    else
                        notifyError('Error While Registering CreateLoad Info From Server')
                    //console.log(res.data)
                },
                (err) => {
                    notifyError('Error While Registering CreateLoad Info')
                }
            )
        }
        else {
            notifyError("Please Select Van And Bills For Load  ")
        }
    }

    const getbilldatafromserverwithdate = (reqdata) => {
        if (reqdata.startDate && reqdata.endDate) {
            // console.log(reqdata.startDate)
            // console.log(reqdata.endDate)
            // console.log(reqdata)
            var endDate = moment(reqdata.endDate, 'DD-MM-YYYY');
            var startDate = moment(reqdata.startDate, 'DD-MM-YYYY');
            if (endDate.diff(startDate, 'days') < 60) {
                dispatch(getVineoAddBillDatawithdate(reqdata))
            } else {
                notifyError("Number Of Days Range for Bill Should Be Less than 60 ")
            }
        } else {
            notifyError("Please Enter Date for Bill Start and End ")
        }
    }

    const getVandatafromserver = () => {
        console.log("Called to get Van Data ")
        dispatch(getVineoVanMasterData())
        console.log("Empty Data Received form Server")
    }

    const selectdelivery = () => {
        console.log("Select Delivery  ")
    }

    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)

    const onSelectBill = () => {
        selectedBillsArray = [];

        let tempvalue = 0;

        gridApi.api.getSelectedNodes().map((bill, index) => {
            return (
                <>
                    {selectedBillsArray[index] = bill.data}
                    {/* {console.log(bill.data)} */}
                    {tempvalue = tempvalue + parseFloat(bill.data.vineoAddSaleBillBottomAmt)}
                    {/* {console.log(totalloadBillValue)}
                    {console.log(selectedBillsArray.length)} */}
                </>
            )
        })
        { setValue("vineoAddBillSet", selectedBillsArray) }
        { setTotalloadBillValue(tempvalue) }
        { setNumberofloadbillselected(selectedBillsArray.length) }
        { setValue("numberofBillsIncreateLaod", numberofloadbillselected) }
        { setValue("totalLaodValue", totalloadBillValue) }

    }

    const rowStyle = { background: 'white' };

    const getRowStyle = (params) => {
        // console.log("vlaue of ",parseInt(params.data.vineoCustomerAccountcreditDay))
        // console.log(params.data.vineoAddSaleBillDate)
        const bildate = moment(params.data.vineoAddSaleBillDate, 'DD-MM-YYYY');
        const today = moment();
        const diffInDays = today.diff(bildate, 'days');
        // console.log("Date Diffrence is ", diffInDays)
        if (parseInt(diffInDays) > params.data.vineoCustomerAccountcreditDay && !params.data.paymentsubmited)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'orange' };
        }
        if (params.data.paymentsubmited)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: '#90EE90' };
        }

    };

    const selectAllRows = () => {
        {
            if (gridApi) {
                if (selectAll) {
                    gridApi.api.deselectAll();
                } else {
                    gridApi.api.selectAll();
                }
                setSelectAll(!selectAll);
            }
        };
    };

    const onExportClick = () => {
        gridApi.api.exportDataAsCsv();
    }

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);
    }

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        // editable: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged
    }

    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedNodes();
        var selectedRowsString = '';
        var maxToShow = 5;
        console.log("Selected Rows" + selectedRows)
    }

    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }

    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }

    const columnDefs = [
        { headerName: "BillNo.", field: "vineoaddUniqueBillNumberWithPrefix", checkboxSelection: true },
        { headerName: "Party", valueGetter: 'data.vineoAddSaleBillCustomer.vineoCustomerAccountName' },
        { headerName: "Date ", field: "vineoAddSaleBillDate" },
        { headerName: "Type", field: "vineoAddSaleBillType" },
        { headerName: "Amount", field: 'vineoAddSaleBillBottomAmt' },
        { headerName: "Salesman", field: 'salesmanNameActual' },
        { headerName: "Area", field: 'areaName' },
        {
            headerName: "Receipt", field: `receiptIsGenerated`,
            cellRenderer: params => {

                if (params.value) {
                    return `YES`
                }
                return `NO`;
            }
        },
        {
            headerName: "Loaded", field: `createLoadIsGenerated`,
            cellRenderer: params => {

                if (params.value) {
                    return `YES`
                }
                return `NO`;
            }
        },
        {
            headerName: "Paid", field: `paymentsubmited`,
            cellRenderer: params => {

                if (params.value) {
                    return <div>

                        <Button variant="warning m-1" onClick={() => handleeditPayment(params.data)}><FontAwesomeIcon icon={faEdit} /></Button>
                        <>`YES`</></div>
                }
                return `NO`;
            }
        },
        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>

                <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}>
                    <FontAwesomeIcon icon={faMoneyCheck} />
                </Button>
                {/* <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button> */}
            </div>
        }
    ]


    const handleeditPayment = (data) => {
        console.log("Edit Payment", data)
        setDataforprint(data);
        setOpeneditBillPayment(true)
    }
    const handleUpdate = (data) => {
        console.log(data)
        setDataforprint(data);
        setOpeneditBill(!openeditBill)

    }
    //deleting a user


    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ADDBILL, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

                }
                else
                    notify("Error While Deleting Bill  " + + res.data.errorMessage)
            },
            (err) => {
                notify("Error While Deleting Bill Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)
        }
    }

    // AG work ends here 

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handlePrint = (data) => {
        setDataforprint(data);
        setOpenprint(!openprint)
    }

    useEffect(() => {
        if (isvineoaddbilldatawithdateLoaded) {
            setTableData(vineoaddbilldatawithdate)
        }
    }, [isvineoaddbilldatawithdateLoaded, vineoaddbilldatawithdate])

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={3}>
                        <FormGroup >
                            <Form.Text className="text-muted">
                                Load No
                            </Form.Text>
                            <Form.Control disabled={true} className="form-control " placeholder="Auto generate" />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Form.Text >
                                Load Date
                            </Form.Text>
                            <DatePicker className="form-control"
                                selected={loaddate}
                                onChange={setLoaddate}
                                name="vineoCreateLoadDate"
                                dateFormat="dd-MM-yyyy"

                            />
                            {setValue("vineoCreateLoadDate", moment(loaddate).format(DATEFORMAT))}
                            {/* {(gridApi?.api?.getSelectedNodes())?setValue("vineoAddBillSet",(gridApi.api.getSelectedNodes())):<></>} */}



                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <Form.Text>
                            Van
                        </Form.Text>
                        <InputGroup>
                            <Typeahead
                                id="VanNameTypeheadVan"
                                onChange={setVanSelected}
                                // onClick={console.log(vanSelected[0])}
                                labelKey="vineoVanName"
                                // options={vineoloadvanData.vineoVanName ? vineoloadvanData : [{'vineoVanName':'AddVaninVanMaster'}]}
                                options={vineoloadvanData ? vineoloadvanData : []}
                                placeholder="Choose Van Name..."
                                selected={vanSelected}


                            // onClick={console.log(selected[0])}


                            />
                            <Button variant="primary"
                                onClick={getVandatafromserver} >
                                <FontAwesomeIcon icon={faRotate} />
                            </Button>
                            <Button variant="primary"
                                onClick={() => { setOpenAddVan(true) }} >
                                <FontAwesomeIcon icon={faAdd} />
                            </Button>
                            {setValue("vineoCreateLoadVan", vanSelected?.[0])}

                        </InputGroup>
                    </Col>
                    <Col md={3}>

                    </Col>
                </Row>
                <Row>

                    <Col md={3}>
                        <FormGroup >
                            <Form.Text className="text-muted">
                                Delivery Boy Name
                            </Form.Text>
                            <Form.Control disabled={vanSelected[0] ? false : true} className="form-control" {...register("deliveryBoyName")} required >
                            </Form.Control>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup >
                            <Form.Text className="text-muted">
                                Delivery By
                            </Form.Text>
                            <InputGroup>
                                <Form.Select className="form-control "
                                    {...register("deliveryIsByHand")} required >
                                    <option value={true}>BY Hand</option>
                                    <option value={false}>BY Courier</option>

                                    {/* {(watch(`deliveryIsByHand`) == 'false') ? setValue("deliveryIsByCourier", true) : setValue("deliveryIsByCourier", false)} */}
                                </Form.Select>
                                <Button variant="info" onClick={handleClickOpen} >+</Button>
                            </InputGroup>
                        </FormGroup>

                    </Col>

                </Row>
                <div>
                    <center>
                        <Button className="btn btn-primary m-2" type="submit"> CreateLoad</Button>

                        <Link to="/dashboard/DeleteandSettleLoad" className="btn btn-warning m-2">Delete/Settle Load</Link><br />
                        <>Total Load  Bill Value= {totalloadBillValue}</><br />
                        <>Total Bill Number in Load= {numberofloadbillselected}</>



                    </center>
                </div>
            </form>
            <div>
                <Row><Col md={3}>
                    <FormGroup className="m-2">
                        <Form.Text >
                            Bills From Date
                        </Form.Text>
                        <DatePicker className="form-control"
                            selected={fromdate}
                            onChange={setFromdate}
                            name="vineoCreateLoadBillFromDate"
                            dateFormat="dd-MM-yyyy"
                            ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
                        />
                        {/* <Controller
                                name="vineoCreateLoadBillFromDateref"
                                //
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <DatePicker className="form-control"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        dateFormat="dd-MM-yyyy"


                                    />

                                )}
                            />
                            {errors.vineoCreateLoadBillFromDateref && <span>This field is required</span>} */}

                    </FormGroup>
                </Col>
                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Bills To Date
                            </Form.Text>

                            <DatePicker className="form-control"
                                selected={todate}
                                onChange={setTodate}
                                name="vineoCreateLoadBillToDate"
                                dateFormat="dd-MM-yyyy"
                                ref={setValue("vineoCreateLoadBillToDate", moment(todate).format(DATEFORMAT))}
                            />

                        </FormGroup>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <Button className="btn btn-warning m-2" onClick={() => getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(todate).format(DATEFORMAT) })}> <FontAwesomeIcon icon={faRotate} /> Load Bills</Button>

                        {/* <button className="btn btn-primary m-2" type="button" onClick={onSelectBillButtonClick}>
                    SelectBills
                </button> */}
                        <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">Export To CSV
                        </Button>
                        <Button className={showLoadcreated ? "btn btn-info m-2" : "btn btn-primary m-2"} onClick={() => { setShowLoadcreated(!showLoadcreated) }} value="ShowLoadCreated">Show Loaded Bills
                        </Button>
                    </Col>
                </Row>
                <div className="ag-theme-alpine" style={{ height: '400px' }}>
                    <div className="ag-theme-alpine" style={{ height: '700px' }}>
                        <button onClick={selectAllRows}>
                            {selectAll ? 'Deselect All' : 'Select All'}
                        </button>
                        <AgGridReact
                            id="AgGridforBillData"
                            rowSelection="multiple"
                            rowData={showLoadcreated ? tableData?.filter((item) => { if (item.createLoadIsGenerated) { return item } }) :
                                tableData?.filter((item) => { if (!item.createLoadIsGenerated && item.active) { return item } })}
                            columnDefs={columnDefs}
                            onRowSelected={onSelectBill}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            rowStyle={rowStyle}
                            getRowStyle={getRowStyle}
                        />

                    </div>
                </div>
            </div>
            <Modal show={open}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Courier Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Card className='p-4'>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        Courier Name
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("billingCourierName")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        Contact No.
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("billingContactNumber")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        Name
                                    </Form.Text>

                                    <Form.Control className="form-control "  {...register("billingName")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        Receipt No
                                    </Form.Text>

                                    <Form.Control type="number" className="form-control "  {...register("billingReceiptNumber")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        Receipt Date
                                    </Form.Text>

                                    <Form.Control type="date" className="form-control "  {...register("billingReceiptDate")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        Packing By
                                    </Form.Text>

                                    <Form.Control className="form-control "  {...register("billingPackingBy")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Card>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openprint}
                onHide={() => setOpenprint(!openprint)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <BillprintFormat2 data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
            <Modal show={openAddVan}
                onHide={() => setOpenAddVan(false)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add van Here </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <VanMaster />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
            <Modal show={openeditBill}
                onHide={() => setOpeneditBill(!openeditBill)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Settle Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <BillSettle data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal show={openeditBillPayment}
                onHide={() => setOpeneditBillPayment(!openeditBillPayment)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Bill Payment Only</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <BillEditPaymentOnly data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

        </div>

    )
}
