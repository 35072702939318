import React, { useEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useNavigate, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, ModalHeader, ModalBody
} from 'react-bootstrap';

function CounterSales() {

    const { register, handleSubmit } = useForm();
    const [tableData, setTableData] = useState();
    const onSubmit = (data) => {
        console.log(data);
        // submitForm(data)
    }

    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)

    const loaddatafromserver = () => {

    }

    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }


    const onGridReady = (params) => {
        //gridApiexport = params.api;
        setGridApi(params);
    }

    const defaultColDef = {
        sortable: true,
        editable: true,
        flex: 1, filter: true,
        floatingFilter: true,
        
    }

    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }
    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }

    const columnDefs = [
        { headerName: "BillNo.", field: "vineoaddUniqueBillNumberWithPrefix" },
        { headerName: "Party", valueGetter: 'data.vineoAddSaleBillCustomer.vineoCustomerAccountName' },
        { headerName: "Date ", field: "vineoAddSaleBillDate" },
        { headerName: "Type", field: "vineoAddSaleBillType" },
        { headerName: "Amount", field: 'vineoAddSaleBillNetAmt' },
        {
            headerName: "Receipt", field: `receptIsGenerated`,
            cellRenderer: params => {
                console.log(params.value);
                if (params.value) {
                    return `YES`
                }
                return `NO`;
            }
        },
        { headerName: "Party", valueGetter: 'data.vineoAddSaleBillFirm.vineoFirmFirmName' },


        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
            </div>
        }

    ]

    const handleUpdate = (data) => {
        console.log(data)
    }

    const handleDelete = (data) => {

    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handlePrint = () => {
        window.print()
    }

    return (
        <div>
            <div class="p-2 mb-2 bg-primary text-white">
                <center>
                    <h5 className='justify-content-center m-1'>Counter Sales  </h5>
                </center>
            </div>
            <div>
                <center>
                    <Button className="btn btn-primary m-2" onClick={loaddatafromserver} value="Add Batch ">
                        Load Bill Data
                    </Button>
                    <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
                        Export To CSV
                    </Button>
                    <Button className="btn btn-primary m-2" onClick={handleClickOpen}>
                        Record View
                    </Button>
                </center>


                <Link to="/dashboard/AddSalesBilling" className="btn btn-primary m-3">AddBill</Link>
                {/* <a class="btn btn-primary m-3" href="AddSalesBilling">Add</a> */}

                <input className="btn btn-primary m-3" type="button" value="Edit " />
                <input className="btn btn-primary m-3" type="button" value="Delete " />
                <input className="btn btn-primary m-3" type="button" value="View " />
                <button className="btn btn-primary m-3" onClick={handlePrint}> Print</button>
                <input className="btn btn-primary m-3" type="button" value="Create Load " />
                <input className="btn btn-primary m-3" type="button" value="Delete Load " />
                <input className="btn btn-primary m-3" type="button" value="Settle Load " />
                <input className="btn btn-primary m-3" type="button" value="Print Load " />
                <input className="btn btn-primary m-3" type="button" value="Close " />

            </div>
            <div className="ag-theme-alpine" style={{ height: '400px' }}>
                <div className="ag-theme-alpine" style={{ height: '700px' }}>
                    <AgGridReact
                        id="AgGridforBillData"
                        rowData={tableData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                    />
                    <AgGridColumn
                        headerName="BQty"
                        valueGetter={(params) => {
                            return params.data.vineoBatchProductQty;
                        }}
                        valueSetter={(params) => {
                            var newValInt = parseInt(params.newValue);
                            var valueChanged = params.data.vineoBatchProductQty !== newValInt;
                            if (valueChanged) {
                                params.data.vineoBatchProductQty = newValInt;
                            }
                            return valueChanged;
                        }}
                    />
                </div>
            </div>

            <Modal show={open}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>View Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Card className='p-4'>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        Enter Days
                                    </Form.Text>
                                    <Form.Control className="form-control "   {...register("enterDays")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        From Date
                                    </Form.Text>
                                    <Form.Control type="date" className="form-control "   {...register("fromDate")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Col md={12}>

                                    <Form.Text className="text-muted">
                                        TO Date
                                    </Form.Text>

                                    <Form.Control type="date" className="form-control " placeholder="dd-mm-yyyy"  {...register("toDate")} required />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Card>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                        ok
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    );

}
export default CounterSales;

