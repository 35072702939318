import React, { useEffect, useState, useMemo } from 'react'

import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup, FormText } from "react-bootstrap";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector, useDispatch } from 'react-redux'
import { getVineoProductData } from '../../../redux/features/services/serviceHelper';
import { DATEFORMAT } from '../../../constants/Urlforvineo';
import moment from 'moment';
import { render } from '@testing-library/react';
import AddStock from './stockadjustment/AddStock';
import VendorToProduct from '../mapping/VendorToProduct';
import AddBatch from './stockadjustment/AddBatch';


let renderCount = 0;
let nameoffreeproduct;
let discountamouteffectivepercentage;
let itemschemdiscountpercentageis = [];

let dataforproductbatch = []

export const AddPurchasetemAddline = (props) => {

  useEffect(() => {
    console.log("Rendering  =  " + renderCount)
    renderCount++;
    vineoAddPurchaseNetAmt = 0
    dispatch(getVineoProductData());
  }, [])
  let schemeisActive;


  let vineoAddPurchaseNetAmt = 0
  const dispatch = useDispatch()
  let selectedBatch;
  let indexofproduct = 0;
  const [dataforbatch, setDataforbatch] = useState()
  const [productSelected, setProductSelected] = useState([{}])
  const [batchSelected, setBatchSelected] = useState()



  const { register, totalDiscountPercentage, setGrossBillValue, dataforaddsaleBill, setValue, control, getValues, watch } = props
  const vineoproductdata = useSelector((state) => state.vineoData.vineoproductdata)


  // const { register, control, handleSubmit, reset, watch } = useForm({
  //     defaultValues: {
  //         vineoAddPurchaseItemList: [{ firstName: ""}]
  //     }
  //   });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "vineoAddPurchaseItemList"
    }
  );


  // const onSubmit = (data) => console.log("data", data);

  const [openMapVendortoProduct, setOpenMapVendortoProduct] = useState(false);
  const [openAddStock, SetOpenAddStock] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([])
  const [selectedProductBatch, setSelectedProductBatch] = useState([])
  // const [voucherTotalValue, setVoucherTotalValue] = useState(0)

  let purchaseGstTotalValue = 0
  const valueofBatchtname = [{}]

  // if you want to control your fields with watch
  // const watchResult = watch("test");
  // console.log(watchResult);

  // The following is useWatch example
  // console.log(useWatch({ name: "test", control }));


  const inputref = React.createRef([]);

  const addtoinputref = (e) => {
    if (e?.props?.value) {
      dataforproductbatch.push(e?.props?.value)
      console.log(dataforproductbatch)
      console.log(e?.props?.value)

    }
  }

  return (
    <div>
      <div>
        <div>
          <Card className='p-4'>
            <table className="table" >
              <thead className="thead-dark" cellSpacing="0" cellPadding="0">
                <tr>
                  <th scope="col" width="1%"></th>
                  <th scope="col" width="16%">Item Name </th>
                  <th scope="col" width="23%">Batch</th>
                  <th scope="col" width="7%">Unit</th>
                  <th scope="col" width="5%">free</th>
                  <th scope="col" width="5%">Qty</th>
                  <th scope="col" width="4%">Rate (GST)</th>
                  <th scope="col" width="4%">D.Rate (Basic)</th>
                  <th scope="col" width="2%">Basic Rate</th>
                  <th scope="col" width="3%">GST(C)</th>
                  <th scope="col" width="3%">GST(S)</th>
                  <th scope="col" width="4%">Basic</th>
                  <th scope="col" width="4%">Net</th>
                  <th scope="col" width="4%" > </th>
                </tr>
              </thead>
              <tbody >
                {fields.map((item, index) => {
                  return (

                    <tr key={item.id} >
                      <td>{index + 1}</td>
                      <td >
                        <Controller
                          control={control}
                          name={`vineoAddPurchaseItemList[${index}].vineoProduct`}
                          rules={{
                            required: "Please, select at least one value"
                          }}
                          render={({ field, fieldState }) => (
                            <div >
                              <InputGroup >
                                <Typeahead
                                  {...field}
                                  id="selectedproducttypeahead"
                                  disabled={vineoproductdata ? false : true}
                                  className={fieldState.invalid ? "is-invalid" : ""}
                                  labelKey={"vineoProductName"}
                                  options={vineoproductdata ? vineoproductdata : []}
                                  ref={() => {
                                    // const values = getValues();
                                    setProductSelected(...selectedProduct, watch(`vineoAddPurchaseItemList[${index}].vineoProduct`))

                                    // dataforproductbatch.push(singleValue)
                                    // console.log("The Product Bathch is  =" + index + "  index is " + JSON.stringify(singleValue))
                                    // console.log("Full Value is ="+index+"  index is "+JSON.stringify(values) )
                                    //  console.log("Vlaue fof Product is "+ productSelected)
                                  }}
                                />
                                <Button variant="primary"
                                  onClick={() => { dispatch(getVineoProductData()); }} >
                                  <FontAwesomeIcon icon={faRotate} />
                                </Button>
                                <p id="typeaheadproductError" className="invalid-feedback">
                                  {fieldState.error?.message}
                                </p>
                              </InputGroup>
                              <>HSN: {watch(`vineoAddPurchaseItemList[${index}].vineoProduct.vineoProducthsnCod`)}</>
                            </div>
                          )}
                        />

                      </td>

                      <td>
                        <Controller
                          control={control}
                          name={`vineoAddPurchaseItemList[${index}].vineoBatch`}
                          rules={{
                            required: "Please, select at least one value"
                          }}
                          render={({ field, fieldState }) => (
                            <div >
                              <InputGroup>
                                <Typeahead
                                  {...field}
                                  id="VendorBatchTypehead"
                                  className={fieldState.invalid ? "is-invalid" : ""}
                                  disabled={watch(`vineoAddPurchaseItemList[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`)?.length > 0 ? false : true}
                                  labelKey={"vineoBatchName"}
                                  renderMenuItemChildren={(option) => (
                                    <div>
                                      <div>
                                        <small>Name:  {option.vineoBatchName}</small>
                                        <small> Qty: {option.vineoBatchProductQty}</small>
                                      </div>
                                    </div>
                                  )}
                                  options={watch(`vineoAddPurchaseItemList[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`) ? watch(`vineoAddPurchaseItemList[${index}].vineoProduct[${indexofproduct}].vineoBatchSet`)?.filter((item) => {
                                    let tempvalue = (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`))
                                    if (tempvalue) {
                                      valueofBatchtname.push(tempvalue[0]);
                                    }
                                    //  console.log( "Item expired "+item.vineoBatchExpDate >  moment(new Date()).format(DATEFORMAT))
                                    // console.log(moment(item.vineoBatchExpDate) + "Item Expiry Date is after " + moment(item.vineoBatchExpDate).isBefore(new Date()))
                                    //  console.log( new Date())
                                    //  console.log( item.vineoBatchExpDate)
                                    // console.log(valueofBatchtname ? valueofBatchtname[0]?.vineoBatchName : <></>)
                                    //  console.log(item.vineoProductName )
                                    // change if condition here durign prod 
                                    // if (!valueofBatchtname.includes(item) && item.vineoBatchProductQty > 0 && moment(item.vineoBatchExpDate).isBefore(new Date())) { return item; }
                                    if (!valueofBatchtname.includes(item)) { return item; }
                                  }) : []}

                                  ref={() => {
                                    // const values = getValues(); 
                                    setBatchSelected(watch(`vineoAddPurchaseItemList[${index}].vineoBatch`))
                                    // setValue(`vineoAddPurchaseItemList[${index}].vineoAddstockVoucherDate`, dataforaddsaleBill[0].vineoAddstockVoucherDate)
                                    // setValue(`vineoAddPurchaseItemList[${index}].vineoAddstockVoucherNarr`, dataforaddsaleBill[0].vineoAddstockVoucherNarr)
                                    // setValue(`vineoAddPurchaseItemList[${index}].vineoAddstockVoucherNumber`, dataforaddsaleBill[0].vineoAddstockVoucherNumber)
                                    // setValue(`vineoAddPurchaseItemList[${index}].vineoVendorAccount`, dataforaddsaleBill[0].vineoVendorAccount)
                                    // setValue(`vineoAddPurchaseItemList[${index}].vineoAddstockVoucherTotal`, vineoAddPurchaseNetAmt)

                                    // dataforproductbatch.push(singleValue)
                                    // console.log("The Product Bathch is  =" + index + "  index is " + JSON.stringify(singleValue))
                                    // console.log("Full Value is ="+index+"  index is "+JSON.stringify(values) )
                                    //  console.log("Vlaue fof Product is "+ batchSelected)
                                  }}

                                />
                                <Button variant="info" onClick={() => { SetOpenAddStock(true) }}>
                                  +
                                </Button>

                                <p id="typeaheadBatchError" className="invalid-feedback">
                                  {fieldState.error?.message}
                                </p>
                              </InputGroup>
                              B.N.-{(watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)?.[0]?.vineoBatchNumber)}<br />


                            </div>

                          )}
                        />
                      </td>

                      <td>
                        <Form.Select className="form-control" size="sm" {...register(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`)}>
                          <option value="PCS">PCS</option>
                          <option value="BOX">BOX</option>
                        </Form.Select>
                        {watch(`vineoAddPurchaseItemList[${index}].vineoProduct`) ? (watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)[0]?.vineoProductinBoxPacking) : <>NA</>} <>in Box</>
                      </td>

                      <td>
                        <Form.Control type="number" min="0" className="form-control" defaultValue={0}  {...register(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseItemFreeProductQty`)} />


                      </td>
                      <td>
                        <Form.Control type="number" min="1" className="form-control"   {...register(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`, { pattern: /^[0-9\b]+$/, min: 1 })} required />


                      </td>
                      {
                      }

                      {/*  */}
                      <td>
                        {/* Purchase Rate  */}
                        {watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?

                          (watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                            (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRateperPcs)
                            : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRate)
                          :
                          <>NA</>}
                      </td>

                      <td>
                        <Form.Control className="form-control" type="number" min="0" step=".01" defaultValue=
                          {
                            (watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                              (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)?.[0]?.vineoBatchPurchaseRateperPcs) *

                              (1 - (watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage)
                                + (watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage))

                              : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)?.[0]?.vineoBatchPurchaseRate) *

                              (1 - (watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage)
                                + (watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage))
                          }
                          {...register(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`)} required />
                        {console.log("Effective Item Rate = " + watch(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`))}

                      </td>
                      <td>
                        {watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?
                          parseFloat(
                              parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                                (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRateperPcs)
                                : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRate))
                            /
                            parseFloat(1 +
                              (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                            ).toFixed(2)
                          ).toFixed(2)
                                                      
                          : <>-</>}
                      </td>
                     
                      {/* Check if Disocunt price is edited otherwise take purcahse rate from db  */}
                      {watch(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`) > 0 ?
                        <>

                          <td>


                            {/* <Form.Control className="form-control"   {...register(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseItemcGSTAmount`, { pattern: /^[0-9\b]+$/ })} required />
<br /> */}
                            {/* {console.log("Value of cgst % is " + parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))} */}
                            {/* {console.log("Value of sgst % is " + parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage))} */}
                            {/* Calculated cGST from System */}

                            {watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?

                              parseFloat(parseFloat(
                                parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                  parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`))
                                )
                                /
                                parseFloat(1 +
                                  (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                    parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                ).toFixed(2)
                              ).toFixed(2)
                                *
                                (parseFloat((parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage)) * 0.01).toFixed(2))).toFixed(2)
                              : <>-</>}

                          </td>
                          <td>

                            {/* <Form.Control className="form-control"   {...register(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseItemsGSTAmount`, { pattern: /^[0-9\b]+$/ })} required />
<br /> */}
                            {/* Calculated sGST from System */}
                            {watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?

                              parseFloat(parseFloat(
                                parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                  parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`))
                                )
                                /
                                parseFloat(1 +
                                  (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                    parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                ).toFixed(2)
                              ).toFixed(2)
                                *
                                (parseFloat((parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01).toFixed(2))).toFixed(2)
                              : <>-</>}


                          </td>
                          <td>
                            {/* Basic Amount without GST */}
                            {watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?

                              parseFloat(
                                parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                  parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`))
                                )
                                /
                                parseFloat(1 +
                                  (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                    parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                ).toFixed(2)
                              ).toFixed(2)

                              : <>-</>}
                            {/* Basic Amount without GST */}

                            {/* {parseFloat(
  (
    (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) &&
      watch(`vineoAddPurchaseItemList[${index}].vineoProduct`) &&
      !isNaN(parseInt(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`)))) ?

      (parseFloat(parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)?.[0]?.vineoBatchProductSaleRate * (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01))
        * parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`)))
        / (((parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
          + parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage))
        ) * (0.01)) + 1)) : (0)
  )).toFixed(2)
} */}
                          </td>
                          <td >
                            {/* {Net Amount Calcualtion } */}
                            <FormGroup  >
                              {
                                (
                                  (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) && watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)
                                    &&
                                    (parseInt(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`)))) ?
                                    parseFloat(
                                      parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                        parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`))
                                      )).toFixed(2)
                                    :
                                    (0)
                                )
                              }
                            </FormGroup>

                            <div style={{ display: 'none' }}>

                              {
                                vineoAddPurchaseNetAmt = parseFloat(
                                  parseFloat(vineoAddPurchaseNetAmt) + parseFloat(

                                    (
                                      (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) && watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)
                                        &&
                                        (parseInt(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`)))) ?
                                        parseFloat(
                                          parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                            parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`))
                                          )).toFixed(2)
                                        :
                                        (0)
                                    )
                                  )
                                ).toFixed(2)
                              }

                              <Form.Control className="form-control" value={watch(`vineoAddPurchaseNetAmt`)} {...register(`vineoAddPurchaseNetAmt`)} />

                              {
                                purchaseGstTotalValue =
                                parseFloat(parseFloat(purchaseGstTotalValue)
                                  +
                                  watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?
                                  parseFloat(
                                    parseFloat((
                                      parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                        parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoItemEffectiverateAfterDsicount`))
                                      ) /
                                      parseFloat(1 +
                                        (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                          parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                      )
                                      *
                                      parseFloat(
                                        (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                          parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                      )))).toFixed(2)

                                  :
                                  <>0</>
                                ).toFixed(2)
                              }
                            </div>

                          </td>
                        </>
                        :
                        <>
                          {/* as discout Rate is Addded so taking it into calculation  */}

                          <td>


                            {/* <Form.Control className="form-control"   {...register(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseItemcGSTAmount`, { pattern: /^[0-9\b]+$/ })} required />
<br /> */}
                            {/* {console.log("Value of cgst % is " + parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))} */}
                            {/* {console.log("Value of sgst % is " + parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage))} */}
                            {/* Calculated cGST from System */}

                            {watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?

                              parseFloat(parseFloat(
                                parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                  parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                                    (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRateperPcs)
                                    : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRate))
                                )
                                /
                                parseFloat(1 +
                                  (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                    parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                ).toFixed(2)
                              ).toFixed(2)
                                *
                                (parseFloat((parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage)) * 0.01).toFixed(2))).toFixed(2)
                              : <>-</>}

                          </td>
                          <td>

                            {/* <Form.Control className="form-control"   {...register(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseItemsGSTAmount`, { pattern: /^[0-9\b]+$/ })} required />
<br /> */}
                            {/* Calculated sGST from System */}
                            {watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?

                              parseFloat(parseFloat(
                                parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                  parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                                    (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRateperPcs)
                                    : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRate))
                                )
                                /
                                parseFloat(1 +
                                  (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                    parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                ).toFixed(2)
                              ).toFixed(2)
                                *
                                (parseFloat((parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01).toFixed(2))).toFixed(2)
                              : <>-</>}


                          </td>
                          <td>
                            {/* Basic Amount without GST */}
                            {watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?

                              parseFloat(
                                parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                  parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                                    (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRateperPcs)
                                    : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRate))
                                )
                                /
                                parseFloat(1 +
                                  (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                    parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                ).toFixed(2)
                              ).toFixed(2)

                              : <>-</>}
                            {/* Basic Amount without GST */}

                            {/* {parseFloat(
  (
    (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) &&
      watch(`vineoAddPurchaseItemList[${index}].vineoProduct`) &&
      !isNaN(parseInt(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`)))) ?

      (parseFloat(parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)?.[0]?.vineoBatchProductSaleRate * (1 - (totalDiscountPercentage + itemschemdiscountpercentageis[[index]]) * 0.01))
        * parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`)))
        / (((parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage))
          + parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage))
        ) * (0.01)) + 1)) : (0)
  )).toFixed(2)
} */}
                          </td>
                          <td >
                            {/* {Net Amount Calcualtion } */}
                            <FormGroup  >
                              {
                                (
                                  (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) && watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)
                                    &&
                                    (parseInt(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`)))) ?
                                    parseFloat(
                                      parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                        parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                                          (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRateperPcs)
                                          : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRate))
                                      )).toFixed(2)
                                    :
                                    (0)
                                )
                              }
                            </FormGroup>

                            <div style={{ display: 'none' }}>

                              {
                                vineoAddPurchaseNetAmt = parseFloat(
                                  parseFloat(vineoAddPurchaseNetAmt) + parseFloat(

                                    (
                                      (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) && watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)
                                        &&
                                        (parseInt(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`)))) ?
                                        parseFloat(
                                          parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                            parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                                              (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRateperPcs)
                                              : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRate))
                                          )).toFixed(2)
                                        :
                                        (0)
                                    )
                                  )
                                ).toFixed(2)
                              }

                              <Form.Control className="form-control" value={watch(`vineoAddPurchaseNetAmt`)} {...register(`vineoAddPurchaseNetAmt`)} />

                              {
                                purchaseGstTotalValue =
                                parseFloat(parseFloat(purchaseGstTotalValue)
                                  +
                                  watch(`vineoAddPurchaseItemList[${index}].vineoBatch`) ?
                                  parseFloat(
                                    parseFloat((
                                      parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoAddPurchaseProductBatchQty`) *
                                        parseFloat((watch(`vineoAddPurchaseItemList[${index}].vineoPurchaseItemUnit`) === 'PCS') ?
                                          (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRateperPcs)
                                          : (watch(`vineoAddPurchaseItemList[${index}].vineoBatch`)[0]?.vineoBatchPurchaseRate))
                                      ) /
                                      parseFloat(1 +
                                        (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                          parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                      )
                                      *
                                      parseFloat(
                                        (parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductCentralGstPercentage) +
                                          parseFloat(watch(`vineoAddPurchaseItemList[${index}].vineoProduct`)?.[0]?.vineoProductStateGstPercentage)) * 0.01
                                      )))).toFixed(2)

                                  :
                                  <>0</>
                                ).toFixed(2)
                              }
                            </div>

                          </td>
                        </>
                      }


                      <td>
                        <div>
                          <InputGroup>
                            <Button type="button" size="sm" onClick={() => {
                              append({});
                            }} variant="primary">
                              +
                            </Button>
                            <Button type="button" size="sm" onClick={() => { remove(index) }} variant="danger">
                              x
                            </Button>

                          </InputGroup>

                        </div>
                      </td>

                    </tr>

                  );
                })}

                {/* <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                  <td>7</td>
                  <td>8</td>
                  <td>9</td>
                  <td>10</td>
               */}

              </tbody>
            </table>


            <section>
              <Row>


                <Col md={3}>
                  <Button
                    type="button" variant="info"
                    onClick={() => {
                      append({});
                    }}
                  >
                    Add
                  </Button>

                </Col>
                <Col md={1}>
                  Amount
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Form.Control disabled={true} className="form-control" value={purchaseGstTotalValue} />
                  </FormGroup>
                </Col>
                <Col md={1}>
                  GST Total
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Form.Control disabled={true} className="form-control" value={purchaseGstTotalValue} />
                  </FormGroup>
                </Col>
                <Col md={1}>
                  Purchase Bill Total
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Form.Control disabled={true} className="form-control" value={vineoAddPurchaseNetAmt} />
                  </FormGroup>
                  {setGrossBillValue(vineoAddPurchaseNetAmt)}

                </Col>
              </Row>

            </section>

          </Card>


        </div>
      </div>
      <div>
        <Modal show={openMapVendortoProduct}
          onHide={() => { setOpenMapVendortoProduct(false) }}
          backdrop="static"
          keyboard={false}
          fullscreen={true}>
          <Modal.Header closeButton />
          {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}

          <Modal.Body>
            <VendorToProduct />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary m-2" onClick={() => { setOpenMapVendortoProduct(false) }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal show={openAddStock}
          onHide={() => { SetOpenAddStock(false) }}
          backdrop="static"
          keyboard={false}
          fullscreen={true}>
          <Modal.Header closeButton />
          {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
          <Modal.Body>
            {/* <AddBatch /> */}
            <AddBatch />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary m-2" onClick={() => { SetOpenAddStock(false) }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div >



  )
}
