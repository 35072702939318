// import "bootstrap/dist/css/bootstrap.min.css";
import AuthUser from './constants/AuthUser';
import Guest from './navbar/Guest';
import Auth from './navbar/AuthrizedUsers';
import { useEffect } from "react";
import './App.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

  const { getToken } = AuthUser();
  const Zoom = () => {
    useEffect(() => {
      const initialValue = document.body.style.zoom;

      // Change zoom level on mount
      document.body.style.zoom = "83%";

      return () => {
        // Restore default value
        document.body.style.zoom = initialValue;
      };
    }, []);
    return <></>;
  };

  if (!getToken()) {
    return <Guest />
  }
  return (
    <>
      <Zoom />
      <Auth />
    </>
  );
}

export default App;
