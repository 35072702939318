import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../constants/AuthUser';
import http from '../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'
import { getVineoFirmData, getVineoGodownData, getVineoVanMasterData } from '../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector, useDispatch } from 'react-redux'
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, InputGroup
} from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEO_IMPORT_CLIENT, VINEO_IMPORT_USERS, } from '../../constants/Urlforvineo';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function ImportClient() {
    useEffect(() => {
      

    }, []);
    
    const dispatch = useDispatch();
    const [selectedFirm, setSelectedFirm] = useState([])
    const [string, setString] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [paymentpaid, setPaymentpaid] = useState(false);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const {  formState: { errors } } = useForm();
    const [isLoaded, setIsLoaded] = useState(false);
    const onSubmit = (data) => {

        console.log(data.vineofile[0]);
        // submitForm(data.vineofile[0])
    }
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const vineoGodowndata = useSelector((state) => state.vineoData.vineogodowndata)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    useEffect(() => {
        console.log("Refresh Count Changed")
    }, [refreshcount]);



    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleUpload = (e) => {

        // setIsEnabled(true);
        setIsLoaded(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('vineofile', file);
     
        console.log(formData)
        console.log("File Size ", file?.size)
        if (file?.size < 4000000 && file?.size > 0 ) {
            http.post(BASEURL_JWTAUTH + VINEO_IMPORT_CLIENT, formData)
                .then((res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify(' Success:-  Client Import Success ' + res.data.additionalInfo)
                        setIsLoaded(false)
                    }
                    else
                    notifyError("Error While Importing  Info From Server " + res.data.additionalInfo)
                     console.log(res.data)
                    setIsLoaded(false)
                },
                    (err) => {
                        console.log(err)

                        notifyError("Error While Importing Data From Server" + err.data)
                        setIsLoaded(false)
                    })
                .catch((err) => {
                    console.log(err)
                    notifyError("Error While Importing Data" + err)
                    setIsLoaded(false)                });

        }
        else {
            notifyError("File SIze is more than 4 MB Please Select Lower Size File")
            setIsLoaded(false)
        }

    }


    return (
        <div>
            <h3 className='justify-content-center m-1'>Upload CSV File For Client Only SuperAdmin Authority </h3>
            <div className="row justify-content-left ">
                <Row>
                    
                    <Row>
                        <Col md={6}>
                            <div >
                                <Card className='p-4 mt-4'>


                                    <div>
                                        <form onSubmit={handleUpload}>

                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>Select CSV AS per Format * </Form.Label>
                                                <Form.Control type="file" onChange={handleFileChange} />
                                            </Form.Group>

                                            {/* <input type="file" onChange={handleFileChange} /> */}
                                            <Button disabled={isLoaded} type="submit">  {isLoaded ? 'Uploading' : 'Upload'}</Button>
                                            
                                        </form>
                                        <p>{message}</p>
                                    </div>

                                </Card>
                            </div>


                        </Col>
                    </Row>
                    
                </Row>

            </div>
        </div>
    )

}