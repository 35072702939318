import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AuthUser from '../../../constants/AuthUser'
import { BASEURL_JWTAUTH, VINEO_CHECKUSERNAME_AVAILABLE } from '../../../constants/Urlforvineo';
import { getVineoAddBillData, getVineoAddBillDatawithdate, getVineoAddBillDatawithdateForMotherClient, getVineoAddGONDatawithdate, getVineoAddGONDatawithdateMotherCompanyID, getVineoAddIncreaseStockData, getVineoAddPurchasedatawithdate, getVineoBatchData, getVineoCreateLoadDatawithdate, getVineoShopVisitdatawithdate, getVineoShopVisitdatawithdateforMotherclientid, getVineoattendancedatawithdate, getVineounapprovedPartydatawithdate } from '../services/entriesHelperService';
import {getAutoImportVineoFirmData,
  getVineoCompanyData, getVineoSalesmanUsers, getVineoUsers, getVineoCompanyCategoryData, getVineoFirmData, getVineoGroupyData, getVineoCompanySubCategoryData,
  getVineoSubGroupyData, getVineoBrandData, getVineoGstData, getVineoProductData, getVineoAreaData, getVineoRouteData, getVineoAccountType1Data, getVineoAccountType2Data,
  getVineoAccountCustomerMasterMenu, getVineoAccountVendorCustomerMasterMenu, getVineoAccountGroupData, getVineoCustomerBankMasterData, getVineoSchemaMasterData, 
  getVineoGodownData, getVineoVanMasterData, getVineoSpecialGroupMasterData, getVineoServiceAccountData, getVineoSubBrandData, 
  getVineoCreditNotePositiveData, getVineoDebitNoteDataWithDate, getVineoProductDatawithPositiveQtyinBatch,
  getVineoGodownDataofRefreeredClientforImport, getVineoMotherUserswithmotherclient, getVineoCreditNoteDataWithDate, getVineoTransportMasterData, getVineoGRNDataWithDate, getLastBillNumberData, getVineoProductDataForEditingProduct, getAutoImportVineoPartyData
} from '../services/serviceHelper';


export const vineoDataSlice = createSlice({
  name: 'vineoData',
  initialState: {
    vineousers: [],
    isvineousersLoaded: false,
    vineouserswithmotherclient: [],
    isvineouserswithmotherclientLoaded: false,
    vineosalesmanusers: [],
    isvineosalesmanusersLoaded: false,
    vineocompany: [],
    isvineocompanyLoaded: false,
    vineocategory: [],
    isvineocategoryLoaded: false,
    vineosubcategory: [],
    isvineosubcategoryLoaded: false,
    vineofirmdata: [],
    isvineofirmdataLoaded: false,
    AutoImportVineoFirmData: [],
    autoimportvineopartydata: [],
    vineogroupdata: [],
    isvineogroupdataLoaded: false,
    vineosubgroupdata: [],
    isvineosubgroupdataLoaded: false,
    vineobranddata: [],
    isvineobranddataLoaded: false,
    vineosubbranddata: [],
    isvineosubbranddataLoaded: false,
    vineogstdata: [],
    isvineogstdataLoaded: false,
    vineoproductdata: [],
    isvineoproductdataLoaded: false,
    vineoproductddatawithpositiveqty:[],
    isvineoproductddatawithpositiveqtyLoaded:false,
    vineoareadata: [],
    isvineoAreadataLoaded: false,
    vineoroutedata: [],
    isvineoroutedataLoaded: false,
    vineoaccounttype1data: [],
    isaccounttype1dataLoaded: false,
    vineoaccounttype2data: [],
    isaccounttype2dataLoaded: false,
    vineoaccountgroupdata: [],
    isaccountgroupdataLoaded: false,
    vineocustomerbankMasterdata: [],
    isvineocustomerbankMasterdataLoaded: false,
    vineoaccountcustomerdata: [],
    isvineoaccountcustomerdataLoaded: false,
    vineoaccountvendorcustomerdata: [],
    isvineoaccountvendorcustomerdataLoaded: false,
    vineoschemamasterdata: [],
    isvineoschemamasterdataLoaded: false,
    vineogodowndata: [],
    isvineogodowndataLoaded: false,
    vineogodowndataofAutoImportrefClient:[],
    isvineogodowndataofAutoImportrefClientLoaded: false,
    vineovanmasterdata: [],
    isvineovanmasterdataLoaded: false,

    vineotransportmasterdata: [],
    isvineotransportmasterdataLoaded: false,
    vineospecialgroupmasterdata: [],
    isvineospecialgroupmasterdataLoaded: false,

    attendancedatawithdate: [],

    vineoserviceaccountdata: [],
    isvineoserviceaccountdataLoaded: false,

    vineobatchdata: [],
    isvineobatchdataLoaded: false,

    vineoaddincreasestockdata: [],
    isvineoaddincreasestockdataLoaded: false,

    vineoaddbilldata: [],
    isvineoaddbilldataLoaded: false,

    lastBillNumber: 0,

    vineoaddgondatawithdate: [],
    isvineoaddgondatawithdateLoaded: false,

    vineounapprovedpartydatawithdate: [],
    isvineounapprovedpartydatawithdateLoaded: false,

    vineoaddbilldatawithdate: [],
    isvineoaddbilldatawithdateLoaded: false,

    vineocreateloaddatawithdate: [],
    isvineocreateloaddatawithdateLoaded: false,

    vineocreditnotedataPositive: [],
    isvineocreditnotedataPositiveLoaded: false,

    
    vineocreditnotedataWithdate: [],
    isvineocreditnotedataWithdateLoaded: false,
   
    vineoAddPurchasedatawithdate: [],
    isAddPurchasedatawithdateLoaded: false,

    vineoDebitnotedatawithDate: [],
    isvineoDebitnotedatawithDateLoaded: false,

    vineoGRNdatawithDate: [],
    shopvisitdatawithdate: [],

    usersContainer: [],
    loader: true,
    errors: {},
    user: {},
    gstDeatilsFromUser: {
      'MVApiKey': '',
      'MVSecretKey': '',
      'gstin': '',
      'eInvoiceUserName': '',
      'eInvoicePassword': '',
      'datastoredinStatefromgstewaybill': false,
      'AuthTokenCancelEwayBillUpdated': false,
      'Status': '',
      "ErrorMessage":'' ,
      "ErrorCode": '',
      'AuthToken': '',
      'AuthTokenCancelEwayBill': '',
      'Sek': '',
      'TokenExpiry':''
  
    }
    ,
    value: 100,
    refreshcount: 0,
    isUserHaveSuperadminAuthority: false,
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    isUserHaveSuperadminAuthority: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isUserHaveSuperadminAuthority =true;
    },
    decrement: (state, action) => {

      console.log("Inside Decrement Action  ");

      console.log(state.gstDeatilsFromUser);
      state.gstDeatilsFromUser = action.payload;
      console.log(action.payload);
      state.refreshcount++;
    },
    addgstDeatilsFromUser: (state, action) => {

      console.log("Inside addgstDeatilsFromUser Action  ");
      state.gstDeatilsFromUser = action.payload;
      // console.log(action.payload);
      state.refreshcount++;
    },

    clearreduxstore: (state) => {
      console.log("Clearing Redux Data ");
      return [];

    },

  },
  extraReducers: {

    [getVineoUsers.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineousersLoaded = true;
      state.value = 50000;
      state.refreshcount++;
      console.log("Here Data should come in Users ")
      state.vineousers = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Users ")

    },
    [getVineoUsers.pending]: (state, action) => {
      state.isvineousersLoaded = false;
      state.errors = action.payload;
    },
    [getVineoUsers.rejected]: (state, action) => {
      state.isvineousersLoaded = false;

      state.errors = action.payload;
    },

    [getVineoMotherUserswithmotherclient.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineouserswithmotherclientLoaded = true;
      state.refreshcount++;
      console.log("Here Data should come in Userswith motherclient ")
      state.vineouserswithmotherclient = action.payload;
    
      console.log("After Payload should come in Userswith mother clietn  ")

    },
    [getVineoMotherUserswithmotherclient.pending]: (state, action) => {
      state.isvineouserswithmotherclientLoaded = false;
      state.errors = action.payload;
    },
    [getVineoMotherUserswithmotherclient.rejected]: (state, action) => {
      state.isvineouserswithmotherclientLoaded = false;

      state.errors = action.payload;
    },


    [getVineoSalesmanUsers.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineosalesmanusersLoaded = true;
     
      state.refreshcount++;
      console.log("Here Data should come in salesmanUsers ")
      state.vineosalesmanusers = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in salesmanUsers ")

    },
    [getVineoSalesmanUsers.pending]: (state, action) => {
      state.isvineosalesmanusersLoaded = false;
      state.errors = action.payload;
    },
    [getVineoSalesmanUsers.rejected]: (state, action) => {
      state.isvineosalesmanusersLoaded = false;

      state.errors = action.payload;
    },


    [getVineoCompanyData.pending]: (state, action) => {
      state.isvineocompanyLoaded = false;
    },
    [getVineoCompanyData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineocompanyLoaded = true;
      state.value = 70000;
      state.refreshcount++;
      console.log("Here Company Data should come in Company ")
      state.vineocompany = action.payload;
      console.log(action.payload);
      console.log("After Payload should come in Company ")

    },

    [getVineoCompanyData.rejected]: (state, action) => {
      state.isvineocompanyLoaded = false;
      state.errors = action.payload;
    },




    [getVineoCompanyCategoryData.pending]: (state, action) => {
      state.isvineocategoryLoaded = false;
    },
    [getVineoCompanyCategoryData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineocategoryLoaded = true;
      state.value = 70000;
      console.log("Here Category Data should come in Category  ")
      state.vineocategory = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Category ")

    },

    [getVineoCompanyCategoryData.rejected]: (state, action) => {
      state.isvineocategoryLoaded = false;
      console.log("category call is rejected ")
      state.errors = action.payload;
    },




    [getVineoCompanySubCategoryData.pending]: (state, action) => {
      state.isvineosubcategoryLoaded = false;
    },
    [getVineoCompanySubCategoryData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineosubcategoryLoaded = true;
      state.value = 70000;
      console.log("Here Category Data should come in SubCategory  ")
      state.vineosubcategory = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in SubCategory ")

    },

    [getVineoCompanySubCategoryData.rejected]: (state, action) => {
      state.isvineosubcategoryLoaded = false;
      state.errors = action.payload;
    },





    [getVineoFirmData.pending]: (state, action) => {
      state.isvineofirmdataLoaded = false;
    },
    [getVineoFirmData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineofirmdataLoaded = true;
      state.refreshcount++;
      state.value = 80000;
      console.log("Here VineoFirm Data should come in Firm  ")
      state.vineofirmdata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in VineoFirm ")

    },

    [getVineoFirmData.rejected]: (state, action) => {
      state.isvineofirmdataLoaded = false;
      state.errors = action.payload;
    },

    

    
    [getLastBillNumberData.pending]: (state, action) => {
      // state.isvineofirmdataLoaded = false;
    },
    [getLastBillNumberData.fulfilled]: (state, action) => {
     
      state.refreshcount++;
    
      console.log("Here LAstBillNumber Data should come in LastBillNumber  ")
      state.lastBillNumber = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in VineoFirm ")

    },

    [getLastBillNumberData.rejected]: (state, action) => {
    
      state.errors = action.payload;
    },


    [getAutoImportVineoFirmData.pending]: (state, action) => {
      state.isvineofirmdataLoaded = false;
    },
    [getAutoImportVineoFirmData.fulfilled]: (state, action) => {
     
      state.refreshcount++;
    
      console.log("Here VineoFirm Data should come in Firm  ")
      state.AutoImportVineoFirmData = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in VineoFirm ")

    },

    [getAutoImportVineoFirmData.rejected]: (state, action) => {
    
      state.errors = action.payload;
    },

    [getAutoImportVineoPartyData.fulfilled]: (state, action) => {
     
      state.refreshcount++;
    
      console.log("Here Party Data should come in Firm  ")
      state.autoimportvineopartydata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in VineoFirm ")

    },


    [getVineoGroupyData.pending]: (state, action) => {
      state.isvineogroupdataLoaded = false;
    },
    [getVineoGroupyData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineogroupdataLoaded = true;
      console.log("Here VineoFirm Data should come in array   ")
      state.vineogroupdata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in State ")

    },

    [getVineoGroupyData.rejected]: (state, action) => {
      state.isvineogroupdataLoaded = false;
      state.errors = action.payload;
    },



    [getVineoSubGroupyData.pending]: (state, action) => {
      state.isvineosubgroupdataLoaded = false;
    },
    [getVineoSubGroupyData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineosubgroupdataLoaded = true;
      state.value = 12345;
      console.log("Here VineoFirm Data should come in Sub Group Data   ")
      state.vineosubgroupdata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Sub Group Data  ")

    },

    [getVineoSubGroupyData.rejected]: (state, action) => {
      state.isvineosubgroupdataLoaded = false;
      console.log("Sub Group Call Failed ");
      state.errors = action.payload;
    },




    [getVineoBrandData.pending]: (state, action) => {
      state.isvineobranddataLoaded = false;
    },
    [getVineoBrandData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineobranddataLoaded = true;
      state.value = 12345;
      console.log("Here VineoFirm Data should come in Brand Data   ")
      state.vineobranddata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Brand Data  ")

    },

    [getVineoBrandData.rejected]: (state, action) => {
      state.isvineobranddataLoaded = false;
      console.log("Sub Group Call Failed ");
      state.errors = action.payload;
    },


    [getVineoSubBrandData.pending]: (state, action) => {
      state.isvineosubbranddataLoaded = false;
    },
    [getVineoSubBrandData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineosubbranddataLoaded = true;
      state.value = 12345;
      console.log("Here VineoFirm Data should come in SUBBrand Data   ")
      state.vineosubbranddata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in SUBBrand Data  ")

    },

    [getVineoSubBrandData.rejected]: (state, action) => {
      state.isvineosubbranddataLoaded = false;
      console.log("Sub Brand Call Failed ");
      state.errors = action.payload;
    },




    [getVineoGstData.pending]: (state, action) => {
      state.isvineogstdataLoaded = false;
    },
    [getVineoGstData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineogstdataLoaded = true;
      state.value = 12345;
      console.log("Here VineoFirm Data should come in GST Data   ")
      state.vineogstdata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in GST Data  ")

    },

    [getVineoGstData.rejected]: (state, action) => {
      state.isvineogstdataLoaded = false;
      console.log("GST Call Failed ");
      state.errors = action.payload;
    },


    [getVineoProductData.pending]: (state, action) => {
      state.isvineoproductdataLoaded = false;
    },
    [getVineoProductData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineoproductdataLoaded = true;
      state.value = 12345;
      state.refreshcount++;
      console.log("Here VineoFirm Data should come in Product Data   ")
      state.vineoproductdata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Product Data  ")

    },

    [getVineoProductData.rejected]: (state, action) => {
      state.isvineoAreadataLoaded = false;
      console.log("Product Call Failed ");
      state.errors = action.payload;
    },



    
    [getVineoProductDataForEditingProduct.pending]: (state, action) => {
      state.isvineoproductdataLoaded = false;
    },
    [getVineoProductDataForEditingProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineoproductdataLoaded = true;
      state.value = 12345;
      state.refreshcount++;
      console.log("Here VineoFirm Data should come in Product Data   ")
      state.vineoproductdata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Product Data  ")

    },

    [getVineoProductDataForEditingProduct.rejected]: (state, action) => {
      state.isvineoAreadataLoaded = false;
      console.log("Product Call Failed ");
      state.errors = action.payload;
    },



    [getVineoAreaData.pending]: (state, action) => {
      state.isvineoproductdataLoaded = false;
    },
    [getVineoAreaData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineoAreadataLoaded = true;
      state.value = 12345;
      state.refreshcount++;
      console.log("Here Area Data should come in Area Data")
      state.vineoareadata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Area Data")

    },

    [getVineoAreaData.rejected]: (state, action) => {
      state.isvineoAreadataLoaded = false;
      console.log("Area Call Failed ");
      state.errors = action.payload;
    },



    [getVineoRouteData.pending]: (state, action) => {
      state.isvineoroutedataLoaded = false;
    },
    [getVineoRouteData.fulfilled]: (state, action) => {
      state.loader = false;
      state.isvineoroutedataLoaded = true;
      state.value = 12345;
      console.log("Here VineoFirm Data should come in Route Data   ")
      state.vineoroutedata = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Route Data  ")

    },

    [getVineoAccountType1Data.rejected]: (state, action) => {
      state.isvineoroutedataLoaded = false;
      console.log("Route Call Failed ");
      state.errors = action.payload;
    },


    [getVineoAccountType1Data.pending]: (state, action) => {
      state.isvineoroutedataLoaded = false;
    },
    [getVineoAccountType1Data.fulfilled]: (state, action) => {
      state.loader = false;
      state.isaccounttype1dataLoaded = true;
      state.value = 12345;
      console.log("Here VineoFirm Data should come in Account Type 1 Data   ")
      state.vineoaccounttype1data = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Account Type 1 Data  ")

    },

    [getVineoAccountType1Data.rejected]: (state, action) => {
      state.isaccounttype1dataLoaded = false;
      console.log("Account Type 1 Call Failed ");
      state.errors = action.payload;
    },


    [getVineoAccountType1Data.rejected]: (state, action) => {
      state.isvineoroutedataLoaded = false;
      console.log("Route Call Failed ");
      state.errors = action.payload;
    },


    [getVineoAccountType2Data.pending]: (state, action) => {
      state.isvineoroutedataLoaded = false;
    },
    [getVineoAccountType2Data.fulfilled]: (state, action) => {
      state.loader = false;
      state.isaccounttype2dataLoaded = true;
      state.value = 23456;
      console.log("Here VineoFirm Data should come in Account Type 2 Data   ")
      state.vineoaccounttype2data = action.payload;
      // console.log(action.payload);
      console.log("After Payload should come in Account Type 2 Data  ")

    },

    [getVineoAccountType2Data.rejected]: (state, action) => {
      state.isaccounttype2dataLoaded = false;
      console.log("Account Type 2 Call Failed ");
      state.errors = action.payload;
    },

    [getVineoAccountGroupData.pending]: (state, action) => {
      state.isaccountgroupdataLoaded = false;
    },
    [getVineoAccountGroupData.fulfilled]: (state, action) => {

      state.isaccountgroupdataLoaded = true;
      state.vineoaccountgroupdata = action.payload;
      console.log("Here VineoFirm Data should come in Account Group Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in Account Group Data  ")

    },

    [getVineoAccountGroupData.rejected]: (state, action) => {
      state.isaccountgroupdataLoaded = false;
      console.log("Account Group Call Failed ");
      state.errors = action.payload;
    },

    // Master Account and Other Accoutn Calls 


    [getVineoAccountCustomerMasterMenu.pending]: (state, action) => {
      state.isvineoaccountcustomerdataLoaded = false;
    },
    [getVineoAccountCustomerMasterMenu.fulfilled]: (state, action) => {

      state.isvineoaccountcustomerdataLoaded = true;
      state.vineoaccountcustomerdata = action.payload;
      state.refreshcount++;
      console.log("Here MasterMenu Account-> CustomerAccount Data should come in Customer Bank  Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in Bank Customer Data ")

    },

    [getVineoAccountCustomerMasterMenu.rejected]: (state, action) => {
      state.isvineoaccountcustomerdataLoaded = false;
      console.log("MasterMenu Account-> CustomerAccount Data Call Failed ");
      state.errors = action.payload;
    },



    [getVineoAccountVendorCustomerMasterMenu.pending]: (state, action) => {
      state.isvineoaccountvendorcustomerdataLoaded = false;
    },
    [getVineoAccountVendorCustomerMasterMenu.fulfilled]: (state, action) => {

      state.isvineoaccountvendorcustomerdataLoaded = true;
      state.vineoaccountvendorcustomerdata = action.payload;
      state.refreshcount++;
      console.log("Here MasterMenu Account-> Customer OtherAccount Data should come in Customer Bank  Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in Bank Customer Data ")

    },

    [getVineoAccountVendorCustomerMasterMenu.rejected]: (state, action) => {
      state.isvineoaccountvendorcustomerdataLoaded = false;
      console.log("MasterMenu Account-> Customer OtherAccount Data Call Failed ");
      state.errors = action.payload;
    },





    [getVineoCustomerBankMasterData.pending]: (state, action) => {
      state.isvineocustomerbankMasterdataLoaded = false;
    },
    [getVineoCustomerBankMasterData.fulfilled]: (state, action) => {

      state.isvineocustomerbankMasterdataLoaded = true;
      state.vineocustomerbankMasterdata = action.payload;
      state.refreshcount++;
      console.log("Here VineoCustomerBankMasterData Data should come in Customer Bank  Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in Bank Customer Data ")

    },

    [getVineoCustomerBankMasterData.rejected]: (state, action) => {
      state.isvineocustomerbankMasterdataLoaded = false;
      console.log("Customer Bank Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoSchemaMasterData.pending]: (state, action) => {
      state.isvineoschemamasterdataLoaded = false;
    },
    [getVineoSchemaMasterData.fulfilled]: (state, action) => {

      state.isvineoschemamasterdataLoaded = true;
      state.vineoschemamasterdata = action.payload;
      console.log("Here vineoschemamasterdata Data should come in vineoschemamasterdata Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in vineoschemamasterdata Data ")

    },

    [getVineoSchemaMasterData.rejected]: (state, action) => {
      state.isvineoschemamasterdataLoaded = false;
      console.log("vineoschemamasterdata Data Call Failed ");
      state.errors = action.payload;
    },

    [getVineoGodownData.pending]: (state, action) => {
      state.isvineogodowndataLoaded = false;
    },
    [getVineoGodownData.fulfilled]: (state, action) => {

      state.isvineogodowndataLoaded = true;
      state.vineogodowndata = action.payload;
      console.log("Here getVineoGodownData Data should come in getVineoGodownData Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in getVineoGodownData Data ")

    },

    [getVineoGodownData.rejected]: (state, action) => {
      state.isvineogodowndataLoaded = false;
      console.log("getVineoGodownData Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoGodownDataofRefreeredClientforImport.pending]: (state, action) => {
      state.isvineogodowndataofAutoImportrefClientLoaded = false;
    },
    [getVineoGodownDataofRefreeredClientforImport.fulfilled]: (state, action) => {

      state.isvineogodowndataofAutoImportrefClientLoaded = true;
      state.vineogodowndataofAutoImportrefClient = action.payload;
      console.log("Here getVineoGodownData Data should come in getVineoGodownData Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in getVineoGodownData Data ")

    },

    [getVineoGodownDataofRefreeredClientforImport.rejected]: (state, action) => {
      state.isvineogodowndataofAutoImportrefClientLoaded = false;
      console.log("getVineoGodownData Data Call Failed ");
      state.errors = action.payload;
    },



    [getVineoVanMasterData.pending]: (state, action) => {
      state.isvineovanmasterdataLoaded = false;
    },
    [getVineoVanMasterData.fulfilled]: (state, action) => {

      state.isvineovanmasterdataLoaded = true;
      state.vineovanmasterdata = action.payload;
      state.refreshcount++;
      console.log("Here getVineoVanData Data should come in getVineoVanData Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in getVineoVanData Data ")

    },

    [getVineoVanMasterData.rejected]: (state, action) => {
      state.isvineovanmasterdataLoaded = false;
      console.log("getVineoVanData Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoTransportMasterData.pending]: (state, action) => {
      state.isvineotransportmasterdataLoaded = false;
    },
    [getVineoTransportMasterData.fulfilled]: (state, action) => {

      state.isvineovanmasterdataLoaded = true;
      state.vineotransportmasterdata = action.payload;
      state.refreshcount++;
      console.log("Here getVineoTransportData Data should come in getVineoTransportData Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in getVineoTransportData Data ")

    },

    [getVineoTransportMasterData.rejected]: (state, action) => {
      state.isvineotransportmasterdataLoaded = false;
      console.log("getVineoTransportData Data Call Failed ");
      state.errors = action.payload;
    },



    [getVineoSpecialGroupMasterData.pending]: (state, action) => {
      state.isvineospecialgroupmasterdataLoaded = false;
    },
    [getVineoSpecialGroupMasterData.fulfilled]: (state, action) => {

      state.isvineospecialgroupmasterdataLoaded = true;
      state.vineospecialgroupmasterdata = action.payload;
      console.log("Here vineospecialgroupmaster Data should come in vineospecialgroupmaster Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in vineospecialgroupmaster Data ")

    },

    [getVineoSpecialGroupMasterData.rejected]: (state, action) => {
      state.isvineospecialgroupmasterdataLoaded = false;
      console.log("vineospecialgroupmaster Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoServiceAccountData.pending]: (state, action) => {
      state.isvineoserviceaccountdataLoaded = false;
    },
    [getVineoServiceAccountData.fulfilled]: (state, action) => {

      state.isvineoserviceaccountdataLoaded = true;
      state.vineoserviceaccountdata = action.payload;
      console.log("Here vineoserviceaccountdata Data should come in vineoserviceaccountdata Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in vineoserviceaccountdata Data ")

    },

    [getVineoServiceAccountData.rejected]: (state, action) => {
      state.isvineoserviceaccountdataLoaded = false;
      console.log("vineoserviceaccountdata Data Call Failed ");
      state.errors = action.payload;
    },

    // copy upto this line only 

// Batch Data Opration 
   
    [getVineoBatchData.pending]: (state, action) => {
      state.isvineobatchdataLoaded = false;
    },
    [getVineoBatchData.fulfilled]: (state, action) => {

      state.isvineobatchdataLoaded = true;
      state.refreshcount++;
      state.vineobatchdata = action.payload;
      console.log("Here vineobatchdata Data should come in vineobatchdata Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in vineobatchdata Data ")

     

    },

    [getVineoBatchData.rejected]: (state, action) => {
      state.isvineobatchdataLoaded = false;
      console.log("vineobatchdata Data Call Failed ");
      state.errors = action.payload;
    },



    // copy upto this line only 
    
    
    [getVineoAddGONDatawithdate.pending]: (state, action) => {
      state.isvineoaddgondatawithdateLoaded = false;
    },
    [getVineoAddGONDatawithdate.fulfilled]: (state, action) => {
      state.isvineoaddgondatawithdateLoaded = true;
      state.vineoaddgondatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here getVineoAddGONDatawithdate Data should come in getVineoAddGONDatawithdate Data   ")
      // console.log(action.payload);
      state.refreshcount++;
      console.log("After Payload should come in getVineoAddGONDatawithdate Data ")

    },

    [getVineoAddGONDatawithdate.rejected]: (state, action) => {
      state.isvineoaddgondatawithdateLoaded = false;
      console.log("getVineoAddGONDatawithdate Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoAddGONDatawithdateMotherCompanyID.pending]: (state, action) => {
      state.isvineoaddgondatawithdateLoaded = false;
    },
    [getVineoAddGONDatawithdateMotherCompanyID.fulfilled]: (state, action) => {
      state.isvineoaddgondatawithdateLoaded = true;
      state.vineoaddgondatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here getVineoAddGONDatawithdateMotherCompanyID Data should come in vineobatchdatawithdate Data   ")
      // console.log(action.payload);
      state.refreshcount++;
      console.log("After Payload should come in getVineoAddGONDatawithdateMotherCompanyID Data ")

    },

    [getVineoAddGONDatawithdateMotherCompanyID.rejected]: (state, action) => {
      state.isvineoaddgondatawithdateLoaded = false;
      console.log("getVineoAddGONDatawithdateMotherCompanyID Data Call Failed ");
      state.errors = action.payload;
    },

  
    
    [getVineounapprovedPartydatawithdate.pending]: (state, action) => {
      state.isvineounapprovedpartydatawithdateLoaded = false;
    },
    [getVineounapprovedPartydatawithdate.fulfilled]: (state, action) => {
      state.isvineounapprovedpartydatawithdateLoaded = true;
      state.vineounapprovedpartydatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here PARTY Data should come in PARTY Data   ")
      // console.log(action.payload);
      state.refreshcount++;
      console.log("After Payload should come in party Data ")

    },

    [getVineounapprovedPartydatawithdate.rejected]: (state, action) => {
      state.isvineounapprovedpartydatawithdateLoaded = false;
      console.log("unapprove party Data Call Failed ");
      state.errors = action.payload;
    },

    
    
    [getVineoAddBillDatawithdate.pending]: (state, action) => {
      state.isvineoaddbilldatawithdateLoaded = false;
    },
    [getVineoAddBillDatawithdate.fulfilled]: (state, action) => {

      state.isvineoaddbilldatawithdateLoaded = true;
      state.vineoaddbilldatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here getVineoAddBillDatawithdate Data should come in getVineoAddBillDatawithdate Data   ")
      // console.log(action.payload);
      state.refreshcount++;
      console.log("After Payload should come in getVineoAddBillDatawithdate Data ")

    },

    [getVineoAddBillDatawithdate.rejected]: (state, action) => {
      state.isvineoaddbilldatawithdateLoaded = false;
      console.log("getVineoAddBillDatawithdate Data Call Failed ");
      state.errors = action.payload;
    },


    // From here start
    [getVineoAddBillDatawithdateForMotherClient.pending]: (state, action) => {
      state.isvineoaddbilldatawithdateLoaded = false;
    },
    [getVineoAddBillDatawithdateForMotherClient.fulfilled]: (state, action) => {

      state.isvineoaddbilldatawithdateLoaded = true;
      state.vineoaddbilldatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here vineobatchdatawithdate Data should come in vineobatchdatawithdate Data   ")
      // console.log(action.payload);
      state.refreshcount++;
      console.log("After Payload should come in vineobatchdatawithdate Data ")

    },

    [getVineoAddBillDatawithdateForMotherClient.rejected]: (state, action) => {
      state.isvineoaddbilldatawithdateLoaded = false;
      console.log("vineobatchdatawithdate Data Call Failed ");
      state.errors = action.payload;
    },

    //From here Start 

    [getVineoAddBillData.pending]: (state, action) => {
      state.isvineoaddbilldataLoaded = false;
    },
    [getVineoAddBillData.fulfilled]: (state, action) => {

      state.isvineoaddbilldataLoaded = true;
      state.vineoaddbilldata = action.payload;
      console.log("Here VineoADDBill Data should come in VineoADDBill Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in VineoADDBill Data ")

     

    },

    [getVineoAddBillData.rejected]: (state, action) => {
      state.isvineoaddbilldataLoaded = false;
      console.log("VineoADDBill Data Call Failed ");
      state.errors = action.payload;
    },

    
        // copy upto this line only 

        
    [getVineoCreditNotePositiveData.pending]: (state, action) => {
      state.isvineocreditnotedataPositiveLoaded = false;
    },
    [getVineoCreditNotePositiveData.fulfilled]: (state, action) => {

      state.isvineocreditnotedataPositiveLoaded = true;
      state.refreshcount++;
      state.vineocreditnotedataPositive = action.payload;
      console.log("Here CreditNOte Data should come in vineocreditnotedataPositive Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in VineoADDBill Data ")

     

    },

    [getVineoCreditNotePositiveData.rejected]: (state, action) => {
      state.isvineocreditnotedataPositiveLoaded = false;
      state.refreshcount++;
      console.log("getVineoCreditNotePositiveData Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoCreditNoteDataWithDate.pending]: (state, action) => {
      state.isvineocreditnotedataWithdateLoaded = false;
    },
    [getVineoCreditNoteDataWithDate.fulfilled]: (state, action) => {

      state.isvineocreditnotedataWithdateLoaded = true;
      state.refreshcount++;
      state.vineocreditnotedataWithdate = action.payload;
      console.log("Here CreditNOte Data should come in vineocreditnotedataPositive Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in VineoADDBill Data ")

     

    },

    [getVineoCreditNoteDataWithDate.rejected]: (state, action) => {
      state.isvineocreditnotedataWithdateLoaded = false;
      console.log("getVineoCreditNoteWithdateData Data Call Failed ");
      state.errors = action.payload;
    },
        
        
    [getVineoCreateLoadDatawithdate.rejected]: (state, action) => {
      state.isvineocreateloaddatawithdateLoaded = false;
      console.log("vineobatchdatawithdate Data Call Failed ");
      state.errors = action.payload;
    },

    

    [getVineoCreateLoadDatawithdate.pending]: (state, action) => {
      state.isvineocreateloaddatawithdateLoaded = false;
    },
    [getVineoCreateLoadDatawithdate.fulfilled]: (state, action) => {

      state.isvineocreateloaddatawithdateLoaded = true;
      state.vineocreateloaddatawithdate = action.payload;
      console.log("Here VineoADDBill Data should come in VineoADDBill Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in VineoADDBill Data ")


    },

    [getVineoAddBillData.rejected]: (state, action) => {
      state.isvineoaddbilldataLoaded = false;
      console.log("VineoADDBill Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoAddIncreaseStockData.pending]: (state, action) => {
      state.isvineoaddincreasestockdataLoaded = false;
    },
    [getVineoAddIncreaseStockData.fulfilled]: (state, action) => {

      state.isvineoaddincreasestockdataLoaded = true;
      state.vineoaddincreasestockdata = action.payload;
      console.log("Here vineoaddincreasestockdata Data should come in vineoaddincreasestockdata Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in vineoaddincreasestockdata Data ")

     

    },

    [getVineoAddIncreaseStockData.rejected]: (state, action) => {
      state. isvineoaddincreasestockdataLoaded = false;
      console.log("vineoaddincreasestockdata Data Call Failed ");
      state.errors = action.payload;
    },
   
    [getVineoProductDatawithPositiveQtyinBatch.pending]: (state, action) => {
      state.isvineoproductddatawithpositiveqtyLoaded = false;
    },
    [getVineoProductDatawithPositiveQtyinBatch.fulfilled]: (state, action) => {

      state.isvineoproductddatawithpositiveqtyLoaded = true;
      state.vineoproductddatawithpositiveqty = action.payload;
      console.log("Here vineoaddincreasestockdata Data should come in vineoaddincreasestockdata Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in vineoaddincreasestockdata Data ")

     

    },

    [getVineoProductDatawithPositiveQtyinBatch.rejected]: (state, action) => {
      state. isvineoproductddatawithpositiveqtyLoaded = false;
      console.log("vineoaddincreasestockdata Data Call Failed ");
      state.errors = action.payload;
    },

    
    [getVineoAddPurchasedatawithdate.pending]: (state, action) => {
      state.isAddPurchasedatawithdateLoaded = false;
    },
    [getVineoAddPurchasedatawithdate.fulfilled]: (state, action) => {

      state.isAddPurchasedatawithdateLoaded = true;
      state.vineoAddPurchasedatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here VineoPurchase Data should come in VineoPurchase Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in VineoPurchase Data ")

     

    },

    [getVineoAddPurchasedatawithdate.rejected]: (state, action) => {
      state.isAddPurchasedatawithdateLoaded = false;
      console.log("Shop Visit Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoShopVisitdatawithdate.pending]: (state, action) => {
      console.log("Shop Visit Data failed ");
    },
    [getVineoShopVisitdatawithdate.fulfilled]: (state, action) => {

      
      state.shopvisitdatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here Shop Visit Data should come in Shop Visit Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in Shop Visit Data ")
    },

    [getVineoShopVisitdatawithdate.rejected]: (state, action) => {
      console.log("Shop Visit Data failed ");
      console.log("Shop Visit Data Call Failed ");
      state.errors = action.payload;
    },

    

    [getVineoattendancedatawithdate.fulfilled]: (state, action) => {

      state.attendancedatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here Attendace Data should come in Shop Visit Data   ")
      // console.log(action.payload);
    
    },

    [getVineoAddPurchasedatawithdate.rejected]: (state, action) => {
      state.isAddPurchasedatawithdateLoaded = false;
      console.log("Shop Visit Data Call Failed ");
      state.errors = action.payload;
    },


    [getVineoShopVisitdatawithdateforMotherclientid.pending]: (state, action) => {
      console.log("Shop Visit Data failed ");
    },
    [getVineoShopVisitdatawithdateforMotherclientid.fulfilled]: (state, action) => {

      
      state.shopvisitdatawithdate = action.payload;
      state.refreshcount++;
      console.log("Here Shop Visit Data should come in Shop Visit Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in Shop Visit Data ")
    },

    [getVineoShopVisitdatawithdateforMotherclientid.rejected]: (state, action) => {
      console.log("Shop Visit Data failed ");
      console.log("Shop Visit Data Call Failed ");
      state.errors = action.payload;
    },
    
    

    [getVineoDebitNoteDataWithDate.pending]: (state, action) => {
      state.isvineoDebitnotedatawithDateLoaded = false;
      state.vineoDebitnotedatawithDate = [];
    },
    [getVineoDebitNoteDataWithDate.fulfilled]: (state, action) => {

      state.refreshcount++;
      state.isvineoDebitnotedatawithDateLoaded = true;
      state.vineoDebitnotedatawithDate = action.payload;
      console.log("Here VineoPurchase Data should come in VineoPurchase Data   ")
      // console.log(action.payload);
      console.log("After Payload should come in VineoPurchase Data ")

     

    },

    [getVineoDebitNoteDataWithDate.rejected]: (state, action) => {
      state.isvineoDebitnotedatawithDateLoaded = false;
      console.log("VineoPurchase Data Call Failed ");
      state.errors = action.payload;
    },


    

    [getVineoGRNDataWithDate.pending]: (state, action) => {
     
      state.vineoDebitnotedatawithDate = [];
    },
    [getVineoGRNDataWithDate.fulfilled]: (state, action) => {

      state.refreshcount++;
      state.vineoGRNdatawithDate = action.payload;
      console.log("Here GRN Data should come in VineoGRN Data   ")
      // console.log(action.payload);
     
     

    },

    [getVineoGRNDataWithDate.rejected]: (state, action) => {
     
      console.log("GRN Data Call Failed ");
      state.errors = action.payload;
    },
 
    

  },

})



// Action creators are generated for each case reducer function
export const { increment,addgstDeatilsFromUser,isUserHaveSuperadminAuthority, clearreduxstore, decrement, incrementByAmount } = vineoDataSlice.actions

export default vineoDataSlice.reducer