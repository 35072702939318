import React from "react";
import { useEffect, useState } from "react";
import { AgGridReact } from 'ag-grid-react';
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from 'react-redux'
import { getVineoAccountVendorCustomerMasterMenu, getVineoGodownData, getVineoCompanyData, getVineoProductData, getVineoSchemaMasterData } from "../../../../redux/features/services/serviceHelper";
import AddBatch from "./AddBatch";
import * as moment from "moment";
import VendorToProduct from "../../mapping/VendorToProduct";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddStockAddline } from "./AddStockAddline";
import { BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDINCREASESTOCK, VINEOFORM_UPDATE_SELECTED_ADDINCREASESTOCK } from "../../../../constants/Urlforvineo";
import http from "../../../../constants/AxiosConfig";
import { getVineoAddIncreaseStockData } from "../../../../redux/features/services/entriesHelperService";

export default function AddStock() {
    useEffect(() => {
        dispatch(getVineoAccountVendorCustomerMasterMenu())
        dispatch(getVineoAddIncreaseStockData())


    }, []);
    const dispatch = useDispatch()
    const vineovendordata = useSelector((state) => state.vineoData.vineoaccountvendorcustomerdata)
    const vineoAddIncreaseStockdata = useSelector((state) => state.vineoData.vineoaddincreasestockdata)
    const isvineoaddincreasestockdataLoaded = useSelector((state) => state.vineoData.isvineoaddincreasestockdataLoaded)


    const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();
    const [vdate, setVdate] = useState(new Date());
    const [invdate, setInvdate] = useState(new Date());
    const [selectedVendor, setSelectedVendor] = useState([])
    const [numberofRows, setNumberofRows] = useState([])
    const [voucherTotalValue, setVoucherTotalValue] = useState(0)
    const [showgrid, setShowgrid] = useState(false)


    let dataforaddstock = [{}];

    const ref = React.createRef();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)

    const addDatatoStock = () => {
        console.log(dataforaddstock)
        setNumberofRows([...numberofRows, numberofRows.length + 1])
    }


    // Ag grid Work Start 

    const loaddatafromserver = () => {

        dispatch(getVineoAddIncreaseStockData())
        setShowgrid(true)
        isvineoaddincreasestockdataLoaded ? setTableData(vineoAddIncreaseStockdata) : console.log("Empty data from Server for Batch")

    }
    const [gridApi, setGridApi] = useState(null)


    const [tableData, setTableData] = useState(vineoAddIncreaseStockdata)

    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }



    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
        floatingFilter: true,
        editable: true,
        // onCellValueChanged: onCellValueChanged

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }



    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [
        { headerName: "V.No.", field: "vineoAddstockVoucherNumber" },
        { headerName: "CreatedDate", field: "createDate" },
        { headerName: "Vendor Name ", valueGetter: 'data.vineoVendorAccount.vineoVendorAccountName' },
        { headerName: "Voucher Total", field: "vineoAddstockVoucherTotal" },
        { headerName: "No of itmes in Voucher", valueGetter: getlenghtofitems },
        { headerName: "IsActive", field: "addInscreaseStockActive",  
         cellRenderer: params => {

            if (params.value) {
                return `YES`
            }
            return `AUTO`;
        } },


        // { headerName: "SaleRate", field: "vineoBatchProductSaleRate", },
        // { headerName: "Qty", field: "vineoBatchProductQty" },
        // { headerName: "MRP", field: 'vineoBatchProductMRP' },
        // { headerName: "Scheme", field: 'vineoSchemeName' },

        // need to laod schem from DB 



        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
            </div>
        }
    ]

    const handleUpdate = (data) => {
        console.log(data)
        submitForm(data)

    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row, Once You Delete All Batch Associated Will BE set To Available for Next Operation ", data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }
    function getlenghtofitems(params) {
        //    console.log(params.data.vineoDataforAddStock.length)
        return params?.data?.vineoDataforAddStock?.length;
    }



    //deleting a user

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ADDINCREASESTOCK, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Add Stock  DELETED Operation SUCCESSFULL ')

                    dispatch(getVineoAccountVendorCustomerMasterMenu)

                }
                else
               { notifyError("Error While Deleting Add Stock  Or Login as ADMIN")}
            },
            (err) => {
                notifyError("Error While Deleting Add Stock Try Again  Or Login as ADMIN")

            }
        )
    }

    const onSubmit = (data) => {

        ((data.dataforaddstock?.[0]?.vineoVendorAccount?.ultiVineovineoVendorAccountNameUniqeId )) ?
            (
                (
                    (data?.vineoDataforAddStock?.length > 0) ?
                    submitForm(data) : notify("Please Add Items")
                )
            )
        :
        notifyError("Please Select Vendor")

    }


    const submitForm = (data) => {
        // api call
        console.log(data)
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_ADDINCREASESTOCK, data).then(
            (res) => {
                console.log(res.data)
                if (res.data.errorCode == "0000") {
                    notify(' Success:-  Add Increase Stock Operation Successful' +res.data.errorMessage )
                    // reset();
                    dispatch(getVineoAccountVendorCustomerMasterMenu());
                    setSelectedVendor([]);

                }
                else
                {notifyError("Error While dd Increase Stock Operation  From Server ")}
                //  console.log(res.data)

            },
            (err) => {
                console.log(err.data)

                notifyError("Error While Increase Stock Operation  ")
            }
        )
    }

    return (

        <div>
            <div className="p-2 mb-2 bg-primary text-white">
                <center>
                    <h5 className='justify-content-center m-1'>Add Increase Stock Info </h5>
                </center>
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card className='p-4'>

                        <Row>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text >
                                        VDate
                                    </Form.Text>

                                    {/* <Form.Control  type="date"  required /> */}
                                    <DatePicker className="form-control"
                                        selected={vdate}
                                        onChange={setVdate}
                                        name="vDate"
                                        dateFormat="dd-MM-yyyy"
                                        ref={setValue(`dataforaddstock[0].vineoAddstockVoucherDate`, moment(vdate).format("DD-MM-YYYY"))}
                                    // {...register("vDate")}


                                    />

                                </FormGroup>


                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Form.Text>
                                        V No
                                    </Form.Text>
                                    <Form.Control className="form-control" onChange={(e) => setValue(`dataforaddstock[0].vineoAddstockVoucherNumber`, e.target.value)} {...register(`dataforaddstock[0].vineoAddstockVoucherNumber`)} required />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <Form.Text>
                                    Vendor Name
                                </Form.Text>
                                <InputGroup>
                                    <Typeahead
                                        id="vineoVendordataTypehead"
                                        onChange={setSelectedVendor}
                                        // onClick={console.log(selected[0])}
                                        options={vineovendordata ? vineovendordata : []}
                                        placeholder="Choose Party Name..."
                                        selected={selectedVendor}
                                        labelKey={"vineoVendorAccountName"}

                                    />
                                    <Button variant="primary"
                                        onClick={() => { dispatch(getVineoAccountVendorCustomerMasterMenu()); }} >
                                        <FontAwesomeIcon icon={faRotate} />
                                    </Button>
                                </InputGroup>
                                <Form.Text>
                                   GST {selectedVendor?.[0]?.vineoGst?.vineoGstNumber}
                                </Form.Text>

                                {setValue(`dataforaddstock[0].vineoVendorAccount`, selectedVendor[0])}
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Invoice No.
                                    </Form.Text>
                                    <Form.Control className="form-control"  {...register(`dataforaddstock[0].vineoAddstockInvoiceNumber`)} required />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text >
                                        InVoice Date
                                    </Form.Text>

                                    {/* <Form.Control  type="date"  required /> */}
                                    <DatePicker className="form-control"
                                        selected={invdate}
                                        onChange={setInvdate}
                                        name="invDate"
                                        dateFormat="dd-MM-yyyy"
                                        ref={setValue(`dataforaddstock[0].vineoAddstockInvoiceDate`, moment(vdate).format("DD-MM-YYYY"))}
                                    // {...register("vDate")}


                                    />

                                </FormGroup>


                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Form.Text className="text-muted">
                                        Narr
                                    </Form.Text>
                                    <Form.Control className="form-control"  {...register(`dataforaddstock[0].vineoAddstockVoucherNarr`)} />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Card>

                    <AddStockAddline selectedVendor={selectedVendor[0]} register={register} setValue={setValue} control={control} getValues={getValues} watch={watch} dataforaddstock={watch(`dataforaddstock`)?.[0]} />

                    <input className="btn btn-primary m-2" type="submit" value="Add Stock Bill " />
                    <button type="button" onClick={() => { reset() }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                </form>


            </div>

            <Row>
                <Col md={8}>
                    <InputGroup className="justify-content-end mt-5">

                        <Form.Text className="text-muted m-2">
                            Page Size
                        </Form.Text>
                        <Col>
                            <Form.Select className="m-2" onChange={(e) => onPaginationChange(e.target.value)} size="sm">
                                <option value='50'>50</option>
                                <option value="100">100</option>
                                <option value='200'>200</option>
                                <option value='300'>300</option>
                            </Form.Select >
                        </Col>
                        <Button className="btn btn-primary m-2" onClick={() => { setShowgrid(!showgrid) }} value="Add Increase Stock ">
                            Show Table
                        </Button>
                        <Button className="btn btn-primary m-2" onClick={loaddatafromserver} value="Add Increase Stock ">
                            Load Stock Data
                        </Button>
                        <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">
                            Export To CSV
                        </Button>

                    </InputGroup>
                </Col>
                {showgrid ?
                    <div className="ag-theme-alpine" style={{ height: '700px' }}>
                        <AgGridReact
                            id="AgGridforBathcData"
                            rowData={tableData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                        />
                    </div> : <></>}
            </Row>




        </div >



    )



}
