import React, { useEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { v4 } from 'uuid';
import Modal from 'react-bootstrap/Modal';
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";
import { faClose, faRotate, faTrash, faUserEdit, faPrint, faShare, faFileText, faStar, faMoneyCheck, faShippingFast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, ModalHeader, ModalBody, InputGroup
} from 'react-bootstrap';
import { getVineoAddBillData, getVineoAddBillDatawithdate, getVineoAddBillDatawithdateForMotherClient } from "../../../redux/features/services/entriesHelperService";
import { useDispatch, useSelector } from "react-redux";
import { BASEURL_JWTAUTH, DATEFORMAT, GSTEWAYBILLCANCELAUTHTOKEN, GSTEWAYBILLGETAUTHTOKEN, VINEOFORM_DELETE_SELECTED_ADDBILL, VINEOFORM_DELETE_SELECTED_ADDBILLBYSUPERADMIN, VINEOFORM_DELIVER_SELECTED_ADDBILL, VINEOFORM_GETEINVOICE_OF_SELECTED_ADDBILL, VINEOFORM_GETJSON_OF_SELECTED_ADDBILL, VINEO_GETALL_ADDBILL_AVAILABLE, VINEO_GETBILLWITHID_ADDBILL_AVAILABLE, VINEO_IMPORT_PURCHASE_FROMCSV_FILE, VINEO_SEND_GRN_TOPARTY } from "../../../constants/Urlforvineo";
import http from "../../../constants/AxiosConfig";
import Billprint from "./billing/Billprint";
import BillEdit from "./billing/BillEdit";
import { Typeahead } from "react-bootstrap-typeahead";
import { getVineoFirmData, getVineoGodownData, getVineoGodownDataofRefreeredClientforImport } from "../../../redux/features/services/serviceHelper";
import Loadprint from "./billing/Loadprint";
import { counter } from "@fortawesome/fontawesome-svg-core";
import EwaybillGenerate from "./billing/EwaybillGenerate";
import { addgstDeatilsFromUser } from "../../../redux/features/vineoData/vineoDataSlice";
import GetGstUserDetailsforEwayBill from "./billing/GetGstUserDetailsforEwayBill";
import BillprintFormat2 from "./billing/BillprintFormat2";
import axios from "axios";
import BillSettle from "./billing/BillSettle";
import CancelEwaybillandeinv from "./billing/CancelEwaybillandeinv";
import { getRoles } from "@testing-library/react";
import AuthUser from "../../../constants/AuthUser";
function Billing() {
    let totalbillValue = 0;
    let billcounter = 0;
    let currentDate = new Date();
    const dispatch = useDispatch()
    const vineoaddbilldatawithdate = useSelector((state) => state.vineoData.vineoaddbilldatawithdate);
    const isUserHaveSuperadminAuthority = useSelector((state) => state.vineoData.isUserHaveSuperadminAuthority);
    const isvineoaddbilldatawithdateLoaded = useSelector((state) => state.vineoData.isvineoaddbilldatawithdateLoaded);
    const isvineogodowndataofAutoImportrefClientLoaded = useSelector((state) => state.vineoData.isvineogodowndataofAutoImportrefClientLoaded);
    const vineoGodowndataofrefferedclient = useSelector((state) => state.vineoData.vineogodowndataofAutoImportrefClient)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const [gStApiloginSuccess, setGStApiloginSuccess] = useState(false);

    const today = new Date();
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 10));
    const [todate, setTodate] = useState(new Date());
    const [todaysdate, setTodaysdate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState();
    const [totalbillValueState, setTotalbillValueState] = useState(0);
    const [billcounterstate, setBillcounterstate] = useState(0);
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    const [openprint, setOpenprint] = React.useState(false);
    const [opensendBilltoCustomer, setOpensendBilltoCustomer] = React.useState(false);
    const [openeditBill, setOpeneditBill] = React.useState(false);
    const [openeditPayment, setOpeneditPayment] = React.useState(false);
    const [dataforprint, setDataforprint] = useState();
    const [dataforBillimport, setDataforBillimport] = useState();
    const [selectedGodown, setSelectedGodown] = useState([])
    const [clientNameofrefclientforautoimport, setClientNameofrefclientforautoimport] = useState()
    const [isEnabled, setIsEnabled] = useState(false);
    const [showcancledbills, setShowcancledbills] = useState(false);
    const [billnumber, setBillNumber] = useState()
    const [bill, setBill] = useState({})
    const [billforgeteinvoiceJson, setBillforgeteinvoiceJson] = useState({})
    const [loaddetailsforprint, setLoaddetailsforprint] = useState([{}])
    const [loaddetailsUpdatedforprint, setLoaddetailsUpdatedforprint] = useState([{}])
    let tmpAllProductData = {}
    let selectedBillsArray = []
    const [numberofloadbillselected, setNumberofloadbillselected] = useState(0)
    const [totalloadBillValue, setTotalloadBillValue] = useState(0)
    const [summaryofload, setSummaryofload] = useState({})
    const [openloadprint, setOpenloadprint] = React.useState(false);
    const [tableData, setTableData] = useState([]);
    const [searchedbill, setSearchedbill] = useState({})
    const [openEwayJsonModal, setOpenEwayJsonModal] = useState(false);
    const [openEwayBillGenerateJsonModal, setOpenEwayBillGenerateJsonModal] = useState(false);
    const [openEwayBillCancelModal, setOpenEwayBillCancelModal] = useState(false);
    const [jsonValueforewaybill, setJsonValueforewaybill] = useState([]);
    const [showtextOnly, setShowtextOnly] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [openGstEwayBillGetDetails, setOpenGstEwayBillGetDetails] = useState(false);
    const vineofirmdata = useSelector((state) => state.vineoData.vineofirmdata)
    const gstDeatilsFromUserfromRedux = useSelector((state) => state.vineoData.gstDeatilsFromUser)
    const [gstDeatilsFromUser, setGstDeatilsFromUser] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [authTokenCancelEwayBill, setAuthTokenCancelEwayBill] = useState("");

    const dataforAuth = {};



    const { register, handleSubmit, setValue } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        // submitForm(data)

    }
    useEffect(() => {

        dispatch(getVineoFirmData())
        setGstDeatilsFromUser(gstDeatilsFromUserfromRedux)

    }, []);


    useEffect(() => {

        console.log("Refreshing on state Change " + refreshcount)
        setLoading(false)
        setTableData(vineoaddbilldatawithdate)
        setGstDeatilsFromUser(gstDeatilsFromUserfromRedux)

    }, [isvineoaddbilldatawithdateLoaded, tableData, refreshcount]);

    // Ag grid Work Start 
    const [gridApi, setGridApi] = useState(null)

    const getbilldatafromserverwithdate = (reqdata) => {

        if (reqdata.startDate && reqdata.endDate) {
            // console.log(reqdata.startDate)
            // console.log(reqdata.endDate)
            // console.log(reqdata)
            const rolename = getRole("");
            var endDate = moment(reqdata.endDate, 'DD-MM-YYYY');
            var startDate = moment(reqdata.startDate, 'DD-MM-YYYY');
            if (endDate.diff(startDate, 'days') < 380) {
                setLoading(true)
                rolename === 'mothercompanyadmin' ? dispatch(getVineoAddBillDatawithdateForMotherClient(reqdata)) : dispatch(getVineoAddBillDatawithdate(reqdata));
                console.log(isvineoaddbilldatawithdateLoaded)
                isvineoaddbilldatawithdateLoaded ? setTableData(vineoaddbilldatawithdate) : console.log("Empty data from Server for Batch")

                console.log('Before')
                console.log(isvineoaddbilldatawithdateLoaded)

            } else {
                setLoading(false)
                notifyError("Number Of Days Range for Bill Should Be Less than 1 year ")
            }
        } else {
            setLoading(false)
            notifyError("Please Enter Date for Bill Start and End ")
        }
    }



    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }

    const rowStyle = { background: 'white' };
    const { getRole } = AuthUser();
    const getRowStyle = (params) => {
        // console.log("vlaue of ",parseInt(params.data.vineoCustomerAccountcreditDay))
        // console.log(params.data.vineoAddSaleBillDate)
        const bildate = moment(params.data.vineoAddSaleBillDate, 'DD-MM-YYYY');
        const today = moment();
        const diffInDays = today.diff(bildate, 'days');
        // console.log("Date Diffrence is ", diffInDays)
        if (parseInt(diffInDays) > params.data.vineoCustomerAccountcreditDay && !params.data.paymentsubmited)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'orange' };
        }
        if (params.data.paymentsubmited)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: '#90EE90' };
        }

    };

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const selectAllRows = () => {
        {
            if (gridApi) {
                if (selectAll) {
                    gridApi.api.deselectAll();
                } else {
                    gridApi.api.selectAll();
                }
                setSelectAll(!selectAll);
            }
        };
    };

    const onSelectBill = () => {
        selectedBillsArray = [];

        let tempvalue = 0;

        gridApi.api.getSelectedNodes().map((bill, index) => {
            return (
                <>
                    {selectedBillsArray[index] = bill.data}
                    {/* {console.log(bill.data)} */}
                    {tempvalue = tempvalue + parseFloat(bill.data.vineoAddSaleBillBottomAmt)}
                    {/* {console.log(totalloadBillValue)}
                    {console.log(selectedBillsArray.length)} */}
                </>
            )
        })


    }

    const defaultColDef = {
        editable: true,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
        floatingFilter: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }
    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedRows();
        var selectedRowsString = '';
        var maxToShow = 5;
        // console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }


    function onCellValueChanged(event) {
        // console.log(
        //     'onCellValueChanged: '
        // );
    }
    const columnDefs = [

        { headerName: "BillNo.", field: "vineoaddUniqueBillNumberWithPrefix", checkboxSelection: true },
        {
            headerName: "Date ", field: "vineoAddSaleBillDate",
            comparator: function (date1, date2) {
                var date1Obj = new Date(date1.split('-').reverse().join('-'));
                var date2Obj = new Date(date2.split('-').reverse().join('-'));
                if (date1Obj > date2Obj) {
                    return 1;
                } else if (date1Obj < date2Obj) {
                    return -1;
                } else {
                    return 0;
                }
            }
        },
        { headerName: "Party", valueGetter: 'data.vineoAddSaleBillCustomer.vineoCustomerAccountName', resizable: true },

        {
            headerName: "Amount", field: 'vineoAddSaleBillBottomAmt',
            comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                // convert the values to numbers for comparison
                var numberA = parseFloat(valueA);
                var numberB = parseFloat(valueB);

                if (numberA === numberB) {
                    return 0;
                } else if (numberA > numberB) {
                    return 1;
                } else {
                    return -1;
                }
            },  autoHeight: true,
        },
        // {
        //     headerName: "Receipt", field: `receiptIsGenerated`,
        //     cellRenderer: params => {

        //         if (params.value ) {
        //             return `YES`
        //         }
        //         return `NO`;
        //     }
        // }
        // ,
        {
            headerName: "Actions", field: "id", resizable: true, cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" size="sm" onClick={() => handlePayment(params.data)} title="Payment">
                    <FontAwesomeIcon icon={faMoneyCheck} />
                </Button>
                <Button variant="warning m-1" size="sm" onClick={() => handlePrint(params.data)} title="Print">
                    <FontAwesomeIcon icon={faPrint} />
                </Button>
                <Button variant="warning m-1" size="sm" onClick={() => handleUpdate(params.data)} title="Update">
                    <FontAwesomeIcon icon={faUserEdit} />
                </Button>
                <Button variant="info m-1" size="sm" onClick={() => handleSendBill(params.data)} title="Send Bill">
                    <FontAwesomeIcon icon={faShare} />
                </Button> 
                <Button variant="danger m-1" size="sm" onClick={() => handleDelete(params.data)} title="Delete">
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
               {isUserHaveSuperadminAuthority ?
                    <Button variant="danger m-1" size="sm" onClick={() => submitFormForDeleteSelectedRowBySuperadmin(params.data)}> <FontAwesomeIcon icon={faStar} /></Button>
                    :
                    <></>
                }
            </div>, width: 700,
            //   disabled ={ !(params?.value?.vineoAddSaleBillCustomer?.autoPurchaseImportActive)}
        },
        {
            headerName: "Paid", field: `paymentsubmited`,
            cellRenderer: params => {

                if (params.value) {
                    return `YES`
                }
                return `NO`;
            }, resizable: true
        },
        {
            headerName: "GST", valueGetter: `data.vineoAddSaleBillCustomer.vineoGst.vineoGstNumber`,
            cellRenderer: params => {

                if (params.data.vineoAddSaleBillCustomer.vineoGst.vineoGstNumber.length > 10) {
                    return `Y`
                }
                return `N`;
            },
            resizable: true
        },

        {
            headerName: "ID.", field: "ultiVineoAddBillUniqeId", resizable: true,
            width: 20,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB
        }

    ]


    const handleUpdate = (data) => {
        // console.log(data)
        setDataforprint(data);
        if ((data?.iscreditNoteGenerated)) {
            notifyWarning("Credit Note Generated for this bill Delete Credit note First to Edit or Delete ")
        }
        else {
            setOpeneditBill(!openeditBill)

        }

        // console.log("This will editqty by this amount "+updatedProductBatchQty);
        // submitForm(data)

    }

    const createLoadArrayforLoadDetailPrint = (data) => {
        setLoaddetailsforprint([])
        setLoaddetailsUpdatedforprint([])
        setSummaryofload([])
        const serverData = data?.vineoAddBillSet || []
        let newVar = {}
        for (let i = 0; i < serverData.length; i++) {
            const itemList = serverData[i].vineoAddBillItemList || []
            for (let j = 0; j < itemList.length; j++) {
                const productName = itemList[j].vineoProduct.vineoProductName
                const qty = (tmpAllProductData?.[productName]?.qty || 0) + itemList[j].vineoAddBillProductBatchQty
                const freeQty = (tmpAllProductData?.[productName]?.freeQty || 0) + itemList[j].vineoAddBillItemFreeProductQty
                tmpAllProductData[productName] = {
                    qty,
                    freeQty,
                    productName,
                }
                newVar[productName] = {
                    qty,
                    freeQty,
                    productName,
                }
            }
            setSummaryofload(tmpAllProductData)
        }

        // console.log(tmpAllProductData)

    }
    //deleting a user


    const getJsoneinvoicebillString = (data) => {

        http.post(BASEURL_JWTAUTH + VINEOFORM_GETJSON_OF_SELECTED_ADDBILL, data).then(
            (res) => {
                // console.log(res.data)

                // console.log(JSON.stringify(res.data))
                setJsonValueforewaybill(res.data)
                setOpenEwayJsonModal(true);


            },
            (err) => {
                notifyError('Error While Generatign JSON ')

            }
        )
    }

    const generateEInvoiceOnly = () => {
        console.log(gstDeatilsFromUser.datastoredinStatefromgstewaybill)

        // console.log("Toekn Expiry",gstDeatilsFromUser.TokenExpiry);
        console.log("Value of Bill in Einv is ", billforgeteinvoiceJson);
        let tokenExpiry = null;
        let expiryTime = null;
        tokenExpiry = gstDeatilsFromUser.TokenExpiry;
        if (tokenExpiry) {
            expiryTime = new Date(tokenExpiry);
        }
        const now = new Date();
        if (gstDeatilsFromUser.datastoredinStatefromgstewaybill) {
            if (!(expiryTime < now)) {
                console.log("Gst data is set in Redux ");
                console.log("Called to get JSON ", billforgeteinvoiceJson)

                if (billforgeteinvoiceJson?.[0]?.ultiVineoAddBillUniqeId) {

                    if (billforgeteinvoiceJson?.[0]?.einvoiceIsGenerated) {
                        notifyError("einvoiceIsGenerated Already Generated")
                    }
                    else {
                        console.log("Bills are selected ")
                        generateEinvoicefromServer(billforgeteinvoiceJson);
                    }
                }
                else {
                    notifyError("Pls Select Bills")
                }

            }
            else {

                // setOpenGstEwayBillGetDetails(true)
            }
        }
        else {

            console.log("Calling get Auth token")
            dataforAuth.gstin = vineofirmdata?.[0]?.vineoFirmGstNumber;
            dataforAuth.MVApiKey = vineofirmdata?.[0]?.vineoFirm_GSTMVApiKey;
            dataforAuth.eInvoiceUserName = vineofirmdata?.[0]?.vineoFirm_GSTAPIUSERNAME;
            dataforAuth.eInvoicePassword = vineofirmdata?.[0]?.vineoFirm_GSTAPIPASSWORD;
            dataforAuth.MVSecretKey = vineofirmdata?.[0]?.vineoFirm_GSTMVSecretKey;
            // call gst api to get token 
            getGstEayBillToken(dataforAuth)
        }
        // dispatch(addgstDeatilsFromUser({'eInvoiceUserName':'Atik', 'eInvoicePassword':'Shaikh'}));

    }

    const CancelEINVandEwayBill = () => {
        console.log(gstDeatilsFromUser.datastoredinStatefromgstewaybill)

        // console.log("Toekn Expiry",gstDeatilsFromUser.TokenExpiry);
        let tokenExpiry = null;
        let expiryTime = null;
        tokenExpiry = gstDeatilsFromUser.TokenExpiry;
        if (tokenExpiry) {
            expiryTime = new Date(tokenExpiry);
        }
        const now = new Date();
        if (gstDeatilsFromUser.datastoredinStatefromgstewaybill && (authTokenCancelEwayBill?.length > 2)) {
            if (!(expiryTime < now)) {
                console.log("Gst data is set in Redux ");
                console.log("Called to get JSON ", selectedBillsArray)

                if (selectedBillsArray?.[0]?.ultiVineoAddBillUniqeId) {

                    if (selectedBillsArray?.[0]?.ewayBillIsGenerated || selectedBillsArray?.[0]?.einvoiceIsGenerated) {
                        console.log("Bills are selected ")
                        setBill(selectedBillsArray?.[0]);
                        setOpenEwayBillCancelModal(true);
                    }
                    else {
                        notifyError("Eway Bill/ E Invoice Not Generated")
                    }
                }
                else {
                    notifyError("Pls Select Bills")
                }

            }
            else {

                // setOpenGstEwayBillGetDetails(true)
            }
        }
        else {

            console.log("Calling get Auth token")
            dataforAuth.gstin = vineofirmdata?.[0]?.vineoFirmGstNumber;
            dataforAuth.MVApiKey = vineofirmdata?.[0]?.vineoFirm_GSTMVApiKey;
            dataforAuth.eInvoiceUserName = vineofirmdata?.[0]?.vineoFirm_GSTAPIUSERNAME;
            dataforAuth.eInvoicePassword = vineofirmdata?.[0]?.vineoFirm_GSTAPIPASSWORD;
            dataforAuth.MVSecretKey = vineofirmdata?.[0]?.vineoFirm_GSTMVSecretKey;
            // call gst api to get token 
            getGstEayBillToken(dataforAuth)
            getGstEayBillCancelToken(dataforAuth)
        }
        // dispatch(addgstDeatilsFromUser({'eInvoiceUserName':'Atik', 'eInvoicePassword':'Shaikh'}));

    }

    const generateEwayBill = () => {
        console.log(gstDeatilsFromUser.datastoredinStatefromgstewaybill)

        // console.log("Toekn Expiry",gstDeatilsFromUser.TokenExpiry);
        let tokenExpiry = null;
        let expiryTime = null;
        tokenExpiry = gstDeatilsFromUser.TokenExpiry;
        if (tokenExpiry) {
            expiryTime = new Date(tokenExpiry);
        }
        const now = new Date();
        if (gstDeatilsFromUser.datastoredinStatefromgstewaybill) {
            if (!(expiryTime < now)) {
                console.log("Gst data is set in Redux ");
                console.log("Called to get JSON ", selectedBillsArray)

                if (selectedBillsArray?.[0]?.ultiVineoAddBillUniqeId) {

                    if (selectedBillsArray?.[0]?.ewayBillIsGenerated) {
                        notifyError("Eway Bill Already Generated")
                    }
                    else {
                        console.log("Bills are selected ")
                        setBill(selectedBillsArray?.[0]);
                        generateEwayBillfromServer(selectedBillsArray);
                    }
                }
                else {
                    notifyError("Pls Select Bills")
                }

            }
            else {

                // setOpenGstEwayBillGetDetails(true)
            }
        }
        else {

            console.log("Calling get Auth token")
            dataforAuth.gstin = vineofirmdata?.[0]?.vineoFirmGstNumber;
            dataforAuth.MVApiKey = vineofirmdata?.[0]?.vineoFirm_GSTMVApiKey;
            dataforAuth.eInvoiceUserName = vineofirmdata?.[0]?.vineoFirm_GSTAPIUSERNAME;
            dataforAuth.eInvoicePassword = vineofirmdata?.[0]?.vineoFirm_GSTAPIPASSWORD;
            dataforAuth.MVSecretKey = vineofirmdata?.[0]?.vineoFirm_GSTMVSecretKey;
            // call gst api to get token 
            getGstEayBillToken(dataforAuth)
        }
        // dispatch(addgstDeatilsFromUser({'eInvoiceUserName':'Atik', 'eInvoicePassword':'Shaikh'}));

    }


    const getGstEayBillToken = (data) => {
        // console.log(JSON.stringify(data));
        // api call
        axios.post(GSTEWAYBILLGETAUTHTOKEN, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',

            }
        }).then(
            (res) => {
                if (res.data.Status == "1") {
                    setGStApiloginSuccess(true);

                    dispatch(addgstDeatilsFromUser({
                        'eInvoiceUserName': data.eInvoiceUserName, 'eInvoicePassword': data.eInvoicePassword, 'datastoredinStatefromgstewaybill': true,
                        'AuthToken': res.data.AuthToken,
                        'Sek': res.data.Sek,
                        'gstin': vineofirmdata?.[0]?.vineoFirmGstNumber,
                        'TokenExpiry': res.data.TokenExpiry
                    }));
                    // comment part after this for prod creds
                    notify('Success:- Log In Success For Next 5 Hr ')

                }
                else {
                    console.log("Call Failed ");
                    // dispatch(addgstDeatilsFromUser({'eInvoiceUserName':data.eInvoiceUserName, 'eInvoicePassword':data.eInvoicePassword,'datastoredinStatefromgstewaybill': true, 
                    // 'AuthToken': 'Fa8x1pAOyIWFPfS9NHJqbI8EY',
                    // 'Sek': '/N9/jW1l6FMmASR0VuJJeu7rg3/CNfj8P2i0mLVEV0xVl5cz4fBprTZIh0DA9Vjr',
                    // 'TokenExpiry':'2023-05-11T17:24:52'}));
                    notifyError(res.data?.ErrorMessage)
                }
            },
            (err) => {
                notifyError("Error While Loggin IN GST for E Invoice   ");


            }
        )
    }

    const getGstEayBillCancelToken = (data) => {
        // console.log(JSON.stringify(data));
        // api call
        axios.post(GSTEWAYBILLCANCELAUTHTOKEN, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',

            }
        }).then(
            (res) => {
                if (res.data.Status == "1") {
                    setGStApiloginSuccess(true);
                    setAuthTokenCancelEwayBill(res.data.AuthenticationToken)

                    // comment part after this for prod creds
                    notify('Success:- Log In Success For Next 5 Hr ')

                }
                else {
                    console.log("Call Failed ");
                    // dispatch(addgstDeatilsFromUser({'eInvoiceUserName':data.eInvoiceUserName, 'eInvoicePassword':data.eInvoicePassword,'datastoredinStatefromgstewaybill': true, 
                    // 'AuthToken': 'Fa8x1pAOyIWFPfS9NHJqbI8EY',
                    // 'Sek': '/N9/jW1l6FMmASR0VuJJeu7rg3/CNfj8P2i0mLVEV0xVl5cz4fBprTZIh0DA9Vjr',
                    // 'TokenExpiry':'2023-05-11T17:24:52'}));
                    notifyError(res.data?.ErrorMessage)
                }
            },
            (err) => {
                notifyError("Error While Loggin IN GST for E Invoice   ");


            }
        )
    }


    const generateEwayBillfromServer = (data) => {
        setOpenEwayBillGenerateJsonModal(true);
    }

    const generateEinvoicefromServer = (data) => {
        console.log("Calling to create einvocie on server ", data);
        const GSTIN = vineofirmdata?.[0]?.vineoFirmGstNumber;
        const eInvoiceUserName = gstDeatilsFromUser.eInvoiceUserName;
        const eInvoicePassword = gstDeatilsFromUser.eInvoicePassword;
        const authToken = gstDeatilsFromUser.AuthToken;
        const MVApiKey = vineofirmdata?.[0]?.vineoFirm_GSTMVApiKey;
        const MVSecretKey = vineofirmdata?.[0]?.vineoFirm_GSTMVSecretKey;

        // Get the current month and year in the format "MM-yyyy"
        // const currentDate = new Date();
        const monthYear = `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
        const headers = {
            MVApiKey,
            MVSecretKey,
            GSTIN,
            eInvoiceUserName,
            eInvoicePassword,
            authToken,
            MonthYear: monthYear,
            'Content-Type': 'application/json',
            'X-uniqueidForVineoclientName': `${JSON.parse(sessionStorage.getItem("uniqueidForVineoclientName"))}`,
            'X-vineoRegisteredClientName': `${JSON.parse(sessionStorage.getItem("vineoRegisteredClientName"))}`,
            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
            "x-appCorrelationid": v4()
        };

        axios.post(BASEURL_JWTAUTH + VINEOFORM_GETEINVOICE_OF_SELECTED_ADDBILL, data, { headers }).then(
            (res) => {
                console.log(res.data)
                if ((res.data.errorCode == "0000")) {
                    notify('Success:- Einvoice Generated Created with Internal Id')
                    console.log(res.data)
                    // reset()
                }
                else {
                    notifyError("Error While Creating Einvoice Bill From Server " + res.data.additionalInfo)
                }

            },
            (err) => {
                notifyError("Error While Creating Einvoice ")
                console.log(err.data)
            }
        )

    }

    let data = {}


    const exportToJson = (dataObjToWrite) => {
        const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
        const link = document.createElement("a");

        link.download = "einvoce.json";
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        link.dispatchEvent(evt);
        link.remove()
    };

    const geteinvoiceJson = () => {
        // console.log("Called to get JSON ", selectedBillsArray)
        if (selectedBillsArray?.[0]?.ultiVineoAddBillUniqeId) {
            console.log("Bills are selected ")
            getJsoneinvoicebillString(selectedBillsArray);
            setBillforgeteinvoiceJson(selectedBillsArray);
        }
        else {
            notifyError("Pls Select Bills")
        }
    }


    const geteTotalBillValue = () => {
        console.log("Called to get Total Bill Value")
        totalbillValue = 0;
        billcounter = 0;
        if (vineoaddbilldatawithdate) {

            console.log("Bills are selected ")
            vineoaddbilldatawithdate.map((bill, index) => {
                return (
                    <>
                        {/* {console.log(bill)} */}
                        {totalbillValue = totalbillValue + parseFloat(bill?.vineoAddSaleBillBottomAmt || '0')}
                        {billcounter++}

                    </>
                )
            })

            setBillcounterstate(billcounter);
            setTotalbillValueState(totalbillValue);

            notify("Total Bill Value in Table is " + totalbillValue + "And Bills Are" + billcounter);
        }
        else {
            notifyError("Pls Select Bills")
        }
    }

    const printLoad = () => {

        data.bill = selectedBillsArray
        console.log("Data Inside Handle Print ")
        // console.log(selectedBillsArray)

        setDataforprint(data);
        createLoadArrayforLoadDetailPrint(data)
        console.log("Updated List")
        // console.log(loaddetailsUpdatedforprint);
        // console.log(loaddetailsforprint)
        // console.log(dataforprint);
        // setData(data)
        setOpenloadprint(!openloadprint)
    }



    const submitFormForDeleteSelectedRow = (requestdata) => {
        // console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ADDBILL, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

                }
                else { notifyError("Error While Deleting Bill  " + res.data.errorCode + res.data.errorMessage) }
            },
            (err) => {
                notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitFormForDeleteSelectedRowBySuperadmin = (requestdata) => {

        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            console.log(requestdata)
            http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_ADDBILLBYSUPERADMIN, requestdata).then(

                (res) => {
                    // console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

                    }
                    else { notifyError("Error While Deleting Bill  " + + res.data.errorMessage) }
                },
                (err) => {
                    notifyError("Error While Deleting Bill Try Again  Or Login as ADMIN")

                }
            )
        }
    }

    const getBilldatafromServer = (billnumber) => {
        if (!billnumber) {
            notifyError("Please Enter Bill Number")
        }
        else if (billnumber.length > 0) {
            console.log("Calling Server to get data with billnumber = " + billnumber);

            http.post(BASEURL_JWTAUTH + VINEO_GETBILLWITHID_ADDBILL_AVAILABLE, { "vineoaddUniqueBillNumberWithPrefix": billnumber }).then(
                (res) => {

                    if (res.data.ultiVineoAddBillUniqeId) {
                        // console.log(res.data);
                        // setTableData(res?.data,...tableData)
                        setDataforprint(res.data);
                        setOpenprint(!openprint)
                        setSearchedbill(res.data)
                        console.log("123", searchedbill);

                    }
                    else {
                        notifyError("Empty Data Received from Server for Bill NUmber " + billnumber);
                        setBill({});
                    }

                },
                (err) => {
                    const stored = sessionStorage.getItem('uniqueidForVineoclientName')
                    const stored1 = sessionStorage.getItem('vineoRegisteredClientName')
                    const stored2 = JSON.parse(sessionStorage.getItem("token"))
                    console.log("Error From Server with code = " + err.data)
                    notifyError("Empty Data Received from Server for Bill NUmber " + billnumber);
                }
            )

        }
        else {
            notifyError("Please Enter Bill Number It cant be Empty")
        }

    }

    const handleDelete = (data) => {
        if (data?.iscreditNoteGenerated) {
            notifyWarning("Credit Note Generated for this bill Delete Credit note First to  Delete ")
        }
        else {
            const confirm = window.confirm("Are you sure, you want to delete this row", data)
            console.log(data)
            if (confirm) {
                submitFormForDeleteSelectedRow(data)

            }

        }
    }

    // Ag grid Work End

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handlePayment = (data) => {
        console.log(data)
        setDataforprint(data);
        setOpeneditPayment(!openeditPayment)

    }


    const handleDelivery = (requestdata) => {

        const confirm = window.confirm("Are you sure, you want Mark this as Delivered " + requestdata?.vineoaddUniqueBillNumberWithPrefix)
        console.log(requestdata)
        if (confirm) {
            http.post(BASEURL_JWTAUTH + VINEOFORM_DELIVER_SELECTED_ADDBILL + "?billUniqueID=B" + requestdata?.ultiVineoAddBillUniqeId).then(

                (res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify('Success:- Bill Marked delivered ' + res.data.errorMessage)

                    }
                    else { notifyError("Error While Operation  " + res.data.errorCode + res.data.errorMessage) }
                },
                (err) => {
                    notifyError("Error While Updating Bill Try Again  Or Login as ADMIN")

                }
            )

        }
    }


    const handlePrint = (data) => {

        setDataforprint(data);
        setOpenprint(!openprint)
    }
    const handleSendBill = (data) => {
        console.log("Handle send Bill Called with Data")
        // console.log(data)
        if (!data.active) {
            notify("Bill Is Cancelled Cant be Sent ")
        }
        else if (!data.vineoAddSaleBillCustomer.autoPurchaseImportActive) {
            notifyError("Customer Party is Not registered for Auto Import ")
        }
        else {

            setDataforprint(data);

            console.log("Data fro print")
            // console.log(dataforprint)
            setOpensendBilltoCustomer(!opensendBilltoCustomer)
        }
    }
    const sendbiltoCustomerImport = () => {
        console.log("Sendign Bill to Customer as Purchase with data for print")
        console.log(dataforprint?.vineoAddSaleBillCustomer?.uniqueidofrefreedClientNameAutoImport)
        if (true) {

            // call Auto Import CSV endpoint with details of voneoaddbill and gowdowndetails
            const formData = new FormData();
            // formData.append('vineofile', file);
            // formData.append('vineoFirm', (selectedFirm?.[0]?.ultiVineoFirmUniqeId))

            formData.append('vineoAddBilluniqueid', (dataforprint?.ultiVineoAddBillUniqeId))
            formData.append('paymentpaid', false)
            formData.append('clientNameofrefclientforautoimport', dataforprint?.vineoAddSaleBillCustomer?.uniqueidofrefreedClientNameAutoImport)
            console.log(formData)

            http.post(BASEURL_JWTAUTH + VINEO_SEND_GRN_TOPARTY, formData)
                .then((res) => {
                    console.log(res)
                    if (res.data.errorCode == "0000") {
                        notify(' Success:-  GRN Created with Invoice ID as ' + res.data.additionalInfo)
                        setIsEnabled(false)
                    }
                    else { notifyError("Error While Importing Purcahse Info From Server " + res.data.additionalInfo) }
                    //  console.log(res.data)
                    setIsEnabled(false)
                },
                    (err) => {
                        console.log(err)

                        notify("Error While Importing Data" + err.data)
                        setIsEnabled(false)
                    })
                .catch((err) => {

                    notifyError("Error While Importing Data" + err)
                    setIsEnabled(true)
                });



        }
        else {
            notifyError("Pls Select Party Godown")
        }
    }


    return (
        <div>

            <div className='h5 mb-0 text-gray-800'>
                <center>
                    <h3 align="center">Sales Billing , Total Bills = {billcounterstate}, Total Amount ={totalbillValueState}</h3>

                </center>



            </div>
            <div>
                <Row>
                    <Col md={3}>

                        <FormGroup className="m-2">

                            <Form.Text >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Bills From

                                    <div onClick={() => {
                                        setFromdate(currentDate.setDate(currentDate.getDate() - 3)); console.log("Date selected is ", fromdate)
                                    }} > -3Day</div>
                                    <div onClick={() => {
                                        setFromdate(currentDate.setDate(currentDate.getDate() - 60))
                                    }} > -60Day</div>
                                    <div onClick={() => {
                                        setFromdate(currentDate.setDate(currentDate.getDate() - 365))
                                    }} > -1Year</div>
                                </div>

                            </Form.Text>

                            <DatePicker className="form-control"
                                selected={fromdate}
                                onChange={setFromdate}
                                name="vineoCreateLoadBillFromDate"
                                dateFormat="dd-MM-yyyy"
                                ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
                            >
                            </DatePicker>
                        </FormGroup>

                    </Col>
                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Bills To Date
                            </Form.Text>

                            <DatePicker className="form-control"
                                selected={todate}
                                onChange={setTodate}
                                name="vineoCreateLoadBillToDate"
                                dateFormat="dd-MM-yyyy"
                                ref={setValue("vineoCreateLoadBillToDate", moment(todate).format(DATEFORMAT))}
                            />

                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Enter Bill Number To search* -{searchedbill?.vineoaddUniqueBillNumberWithPrefix}
                            </Form.Text>
                            <InputGroup>
                                <Form.Control className="form-control"
                                    onChange={(e) => {
                                        setBillNumber(e.target.value)
                                    }} />

                                <Button size="sm" variant="primary"
                                    onClick={(e) => { getBilldatafromServer(billnumber) }} >
                                    <FontAwesomeIcon icon={faRotate} />
                                </Button>
                                {searchedbill?.ultiVineoAddBillUniqeId ?
                                    <>
                                        <Button variant="success m-1" size="sm" onClick={() => { getBilldatafromServer(billnumber); handlePrint(searchedbill) }} title="Print">
                                            <FontAwesomeIcon icon={faPrint} />
                                        </Button>
                                        <Button variant="warning m-1" size="sm" onClick={() => handleUpdate(searchedbill)} title="Update">
                                            <FontAwesomeIcon icon={faUserEdit} />
                                        </Button>
                                        <Button variant="danger m-1" size="sm" onClick={() => handleDelete(searchedbill)} title="Delete">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                        <Button variant="info m-1" size="sm" onClick={() => handleSendBill(searchedbill)} title="Send Bill">
                                            <FontAwesomeIcon icon={faShare} />
                                        </Button>
                                        <Button variant="warning m-1" size="sm" onClick={() => handlePayment(searchedbill)} title="Payment">
                                            <FontAwesomeIcon icon={faMoneyCheck} />
                                        </Button>
                                        <Button variant="warning m-1" size="sm" onClick={() => handleDelivery(searchedbill)} title="Delivery">
                                            <FontAwesomeIcon icon={faShippingFast} />
                                        </Button>

                                        {isUserHaveSuperadminAuthority ?
                                            <Button variant="danger m-1" size="sm" onClick={() => submitFormForDeleteSelectedRowBySuperadmin(searchedbill)}> <FontAwesomeIcon icon={faStar} /></Button>
                                            :
                                            <></>
                                        }
                                    </> :


                                    <></>}


                            </InputGroup>
                        </FormGroup>
                    </Col>

                </Row>


                <Button className="btn btn-warning m-1" disabled={isLoading}
                    onClick={() => { setLoading(!isLoading); getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(todate).format(DATEFORMAT) }) }}> <FontAwesomeIcon icon={faRotate} /> {isLoading ? 'Loading…' : 'Load Bills'} </Button>

                {/* <button className="btn btn-primary m-2" type="button" onClick={onSelectBillButtonClick}>
                    SelectBills
                </button> */}
                <Button className="btn btn-primary m-1" onClick={onExportClick} value="ExportToCsv">Export To CSV
                </Button>


                <Link to="/dashboard/AddSalesBilling" className="btn btn-primary m-1">AddBill</Link>
                {/* <a class="btn btn-primary m-3" href="AddSalesBilling">Add</a> */}
                {/* <Link to="/dashboard/AddSalesBillingAutoPurcahseImport" className="btn btn-primary m-3">AddBillAutoImport</Link> */}
                <Link to="/dashboard/CreateLoad" className="btn btn-primary m-1">CreateLoad</Link>
                <Link to="/dashboard/DeleteandSettleLoad" className="btn btn-primary m-1">SettleLoad</Link>
                <Button className={showcancledbills ? "btn btn-info m-1" : "btn btn-primary m-1"} onClick={() => { setShowcancledbills(!showcancledbills) }} value="Cancelled Bills">Cancel Bills
                </Button>
                <Button className={openloadprint ? "btn btn-info m-1" : "btn btn-primary m-1"} onClick={() => { printLoad() }} value="Print Bills">PrintBills
                </Button>
                <Button className="btn btn-primary m-1" onClick={() => { geteinvoiceJson() }} value="E-INVjson">E-INVjson
                </Button>


                <Button className={gStApiloginSuccess ? "btn btn-success m-1" : "btn btn-secondary m-1"} onClick={() => { generateEwayBill() }} value="E-WAYBill">E-WAYBill</Button>
                <Button className={gStApiloginSuccess ? "btn btn-success m-1" : "btn btn-secondary m-1"} onClick={() => { CancelEINVandEwayBill() }} value="CancelEINV">CanEINV
                </Button>
                <Button className="btn btn-primary m-1" onClick={() => { geteTotalBillValue() }} value="totalBillValue" >Total Bill Value
                </Button>


            </div>

            <div className="ag-theme-alpine" style={{ height: '400px' }}>

                <div className="ag-theme-alpine" style={{ height: '700px' }}>
                    <button onClick={selectAllRows}>
                        {selectAll ? 'Deselect All' : 'Select All'}
                    </button>
                    <AgGridReact
                        id="AgGridforBillData"
                        rowData={tableData ? tableData?.filter((item) => {

                            if (!showcancledbills ? item?.active : !item?.active) { return item }
                        }) : []}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        onRowSelected={onSelectBill}
                        rowSelection="multiple"
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        alwaysShowHorizontalScroll={true}
                    />

                </div>
            </div>

            <Modal show={openprint}
                onHide={() => setOpenprint(!openprint)}
                // backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {console.log("PrintForamt is ", vineofirmdata?.[0]?.vineoaddBillFormat)}
                    {(vineofirmdata?.[0]?.vineoaddBillFormat === '1') ?
                        <Card className='p-4'>
                            <Billprint data={dataforprint} />
                        </Card>
                        :
                        <Card className='p-4'>
                            <BillprintFormat2 data={dataforprint} />
                        </Card>
                    }

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal show={opensendBilltoCustomer}
                onHide={() => setOpensendBilltoCustomer(!opensendBilltoCustomer)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Row>
                        <Col>
                            <Button className="btn btn-primary m-2" disabled={isEnabled} onClick={sendbiltoCustomerImport} value="SEND BILL">SEND BILL
                            </Button>
                        </Col>
                        <Col md={5}>
                            <>Bill Will BE Send to Client id {dataforprint?.vineoAddSaleBillCustomer?.uniqueidofrefreedClientNameAutoImport} & Name of Party -
                                {dataforprint?.vineoAddSaleBillCustomer?.vineoCustomerAccountName} </>
                        </Col>
                        <Col md={5}>
                            <>This Bill WIll go as GRN to Distributor Subject to Acceptance of GRN </>
                        </Col>
                    </Row>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <BillprintFormat2 data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal show={openeditBill}
                onHide={() => setOpeneditBill(!openeditBill)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>EditBill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <BillEdit data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
            <Modal show={openloadprint}
                onHide={() => { setOpenloadprint(!openloadprint) }}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Print Bills</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <Loadprint data={dataforprint} loaddata={dataforprint} summaryofload={summaryofload} />
                    </Card>
                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
            <Modal show={openEwayJsonModal}
                onHide={() => { setOpenEwayJsonModal(!openEwayJsonModal) }}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Copy JSON From Here </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Button variant="primary m-1" onClick={() => setShowtextOnly(!showtextOnly)}><FontAwesomeIcon icon={faFileText} /></Button>
                        <Button variant="primary m-1" onClick={() => exportToJson(jsonValueforewaybill)}><FontAwesomeIcon icon={faFileText} />Export TO .JSON</Button>
                        <Button className={gStApiloginSuccess ? "btn btn-success m-1" : "btn btn-secondary m-1"} onClick={() => { generateEInvoiceOnly(); console.log("VAlueof Bill is ", bill) }} value="E-INVOICE">Generate E-Invoice</Button>

                    </div>
                    <Card className='p-4'>
                        {showtextOnly ?
                            <pre>{JSON.stringify(jsonValueforewaybill, null, 2)}</pre> :
                            <>{JSON.stringify(jsonValueforewaybill, null, 2)}</>}
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={openEwayBillGenerateJsonModal}
                onHide={() => { setOpenEwayBillGenerateJsonModal(!openEwayBillGenerateJsonModal) }}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Generate Eway Bill </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EwaybillGenerate bill={bill} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={openEwayBillCancelModal}
                onHide={() => { setOpenEwayBillCancelModal(!openEwayBillCancelModal) }}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Eway Bill and E Invoice </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CancelEwaybillandeinv authTokenCancelEwayBill={authTokenCancelEwayBill} bill={bill} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal show={openGstEwayBillGetDetails}
                onHide={() => { setOpenGstEwayBillGetDetails(!openGstEwayBillGetDetails) }}
                backdrop="static"
                // fullscreen={true}
                // size="sm"
                keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Please Add Your GST Details  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GetGstUserDetailsforEwayBill />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal show={openeditPayment}
                onHide={() => setOpeneditPayment(!openeditPayment)}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Settle Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <BillSettle data={dataforprint} />
                    </Card>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
        </div>
    );

}
export default Billing;

