import React from 'react'
import { Component, useEffect, useState, } from "react"
import { useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import { addMonths } from 'date-fns';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from "react-hook-form";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { faClose, faRotate, faTrash, faUserEdit, faPrint, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VINEOFORM_SUBCATEGORYINFO_ADDINFODATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_ADDBILL, DATEFORMAT, VINEO_DELETE_SELECTED_ADDGON, VINEO_CONVERT_SELECTED_ADDGON_TOINVOICE } from '../../../constants/Urlforvineo';
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice'
import moment from "moment";
import DatePicker from "react-datepicker";
import { getVineoAddBillData, getVineoAddBillDatawithdate, getVineoAddGONDatawithdate, getVineoAddGONDatawithdateMotherCompanyID, getVineoShopVisitdatawithdate, getVineoShopVisitdatawithdateforMotherclientid } from "../../../redux/features/services/entriesHelperService";
import {
	FormGroup, Label, Input,
	Button, Form, NavDropdown, Row, Col, ListGroup, ListGroupItem, Card, InputGroup, Modal
} from 'react-bootstrap';
import { getVineoAccountCustomerMasterMenu } from '../../../redux/features/services/serviceHelper';
import Billprint from '../entries/billing/Billprint';
import ShopVisitprint from '../printFormat/ShopVisitprint';
import ShopVisitMap from './ShopVisitMap';
import AuthUser from '../../../constants/AuthUser';
import GONprint from '../entries/GONprint';

export default function ItemWiseGonReport() {
	const { setValue } = useForm();
	useEffect(() => {
		console.log("Use Effect called ");
		dispatch(getVineoShopVisitdatawithdate());
	}, []);

	const { token, logout, getUser, getRole } = AuthUser();
	let currentDate = new Date();
	const count = useSelector((state) => state.vineoData.value)
	const dispatch = useDispatch()
	const vineoShopVisitdata = useSelector((state) => state.vineoData.shopvisitdatawithdate)
	const vineoaddgondatawithdate = useSelector((state) => state.vineoData.vineoaddgondatawithdate);
	const refreshcount = useSelector((state) => state.vineoData.refreshcount)
	const [fromdate, setfromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));
	const [toDate, setToDate] = useState(new Date());
	const [openprint, setOpenprint] = React.useState(false);
	const [tableData, setTableData] = useState();
	const [openBillprint, setOpenBillprint] = React.useState(false);
	const [dataforprint, setDataforprint] = useState();
	const [showmap, setShowmap] = useState(false);
	const [selectedSalesman, setSelectedSalesman] = useState([])
	const [selectedParty, setSelectedParty] = useState([])
	const [satHoliday, setSatHoliday] = useState(false)
	const [sunHoliday, setSunHoliday] = useState(true)
	const [openeditGON, setOpeneditGON] = useState(false);
	const [billdate, setBilldate] = useState(new Date())
	const notify = (message) => toast.success(message);
	const notifyError = (message) => toast.error(message)
	const notifyWarning = (message) => toast.warning(message)
	const [isLoading, setLoading] = useState(false);

	const [selectedFirm, setSelectedFirm] = useState([])

	const filteredTableData = (
		selectedParty?.[0]?.label &&
		selectedSalesman?.[0]?.label &&
		selectedFirm?.[0]?.label
	)
		? tableData
			?.filter(item => item.salesmanName === selectedSalesman?.[0]?.label)
			?.filter(item => item.vineoAddSaleBillCustomer.vineoCustomerAccountName === selectedParty?.[0]?.label)
			?.filter(item => item.vineoAddSaleBillFirm.vineoFirmFirmName === selectedFirm?.[0]?.label)
		: (
			selectedParty?.[0]?.label
				? (selectedSalesman?.[0]?.label
					? tableData?.filter(item => item.salesmanName === selectedSalesman?.[0]?.label)
					: tableData
				)?.filter(item => item.vineoAddSaleBillCustomer.vineoCustomerAccountName === selectedParty?.[0]?.label)
				: (selectedSalesman?.[0]?.label
					? tableData?.filter(item => item.salesmanName === selectedSalesman?.[0]?.label)
					: (selectedFirm?.[0]?.label
						? tableData?.filter(item => item.vineoAddSaleBillFirm.vineoFirmFirmName === selectedFirm?.[0]?.label)
						: tableData
					)
				)
		);

	const totalVisits = filteredTableData?.length;

	const convertedtobill = filteredTableData?.filter(item => item.invoiceIsGenerated).length;

	const pendingforbill = filteredTableData?.filter(item => !item.invoiceIsGenerated).length;

	const totalDailyShopVisitTarget = filteredTableData?.reduce((total, entry) => total + entry.dailyShopVisitTarget, 0);

	const uniqueSalesmen = [...new Set(filteredTableData?.map(item => item.salesmanuniqueid))].length;

	const uniqueParties = [...new Set(filteredTableData?.map(item => item.vineoAddSaleBillCustomer.vineoCustomerAccountName))].length;

	const totalGonValue = filteredTableData?.map(item => Number(item.vineoAddSaleBillBottomAmt)).reduce((total, value) => total + value, 0);


	const totalInvoiceValue = filteredTableData?.filter(item => item.invoiceIsGenerated === true)
		.reduce((total, item) => total + item.vineoAddSaleBillBottomAmt, 0);

	const totalGONpendingvalue = filteredTableData?.filter(item => item.invoiceIsGenerated === false)
		.reduce((total, item) => total + item.vineoAddSaleBillBottomAmt, 0);

	const productSummaryMap = new Map();

	filteredTableData?.forEach(item => {
		item.vineoAddGonItemList.forEach(product => {
		  const productName = product.vineoProduct?.vineoProductName || "Unknown Product";
		  const batchQty = product.vineoAddBillProductBatchQty;
		  const billGenerated = item.invoiceIsGenerated;
		  const bottomAmt = product.vineoAddBillItemNetAmount;
	  
		  if (!productSummaryMap.has(productName)) {
			productSummaryMap.set(productName, {
			  totlaqtyofGON: 0,
			  convertedQty: 0,
			  totalGONamount: 0,
			  totalGonCOnvertedAmount: 0,
			});
		  }
	  
		  const productSummary = productSummaryMap.get(productName);
		  productSummary.totlaqtyofGON += batchQty;
	  
		  if (billGenerated) {
			productSummary.convertedQty += batchQty;
			productSummary.totalGonCOnvertedAmount += bottomAmt;
		  }
	  
		  productSummary.totalGONamount += bottomAmt;
		});
	  });
	  
	  const resultArray = [...productSummaryMap.entries()].map(([productName, summary]) => ({
		nameofprduct: productName,
		totlaqtyofGON: summary.totlaqtyofGON,
		convertedQty: summary.convertedQty,
		totalGONamount: summary.totalGONamount.toFixed(2), // Rounded to 2 decimal places
		totalGonCOnvertedAmount: summary.totalGonCOnvertedAmount.toFixed(2), // Rounded to 2 decimal places
	  }));

	// const numberOfDaysWorked = uniqueVisitDates.length;
	// const nonProductiveVisitDates = [...new Set(filteredTableData?.filter(item => !item.orderRecv).map(item => item.visitDate))].filter(Boolean);
	// const numberOfNonProductiveDays = nonProductiveVisitDates.length;



	useEffect(() => {

		console.log("Refreshing on state Change " + refreshcount)
		setLoading(false)
		setTableData(vineoaddgondatawithdate)

	}, [refreshcount]);


	useEffect(() => {
		// Initialize "From Date" to the current date
		const currentDate = new Date();
		const firstDayofMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

		setfromdate(firstDayofMonth)

		// Initialize "To Date" to the last day of the current month
		const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
		setToDate(lastDayOfMonth);
	}, []);


	function calculateWorkingDays(fromDate, toDate, satHoliday, sunHoliday) {
		const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

		let totalWorkingDays = 0;
		let currentDate = new Date(fromDate);

		while (currentDate <= toDate) {
			const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

			// Check if the current day is a weekend holiday
			if ((dayOfWeek === 0 && sunHoliday) || (dayOfWeek === 6 && satHoliday)) {
				// It's a weekend holiday, do nothing
			} else {
				totalWorkingDays++;
			}

			// Move to the next day
			currentDate = new Date(currentDate.getTime() + oneDay);
		}

		return totalWorkingDays;
	}

	const handleSelectHolidayChange = (event) => {
		const selectedValue = event.target.value;

		// Update satHoliday and sunHoliday based on the selected value
		if (selectedValue === "true") {
			setSatHoliday(true);
			setSunHoliday(true);
		} else if (selectedValue === "false") {
			setSatHoliday(false);
			setSunHoliday(false);
		} else if (selectedValue === "true-sat") {
			setSatHoliday(true);
			setSunHoliday(false);
		} else if (selectedValue === "true-sun") {
			setSatHoliday(false);
			setSunHoliday(true);
		}
	};

	const handleFromDateChange = (date) => {
		setfromdate(date);

		if (true) {
			const newToDate = new Date(date);
			newToDate.setMonth(newToDate.getMonth() + 1);
			newToDate.setDate(0); // Set to last day of previous month
			setToDate(newToDate);
		}
	}

	const getdatafromserverwithdate = (reqdata) => {
		const rolename = getRole("");
		console.log("Role is " + rolename)


		if (reqdata.startDate && reqdata.endDate) {

			rolename === 'mothercompanyadmin' ?
				dispatch(getVineoAddGONDatawithdateMotherCompanyID(reqdata)) :
				dispatch(getVineoAddGONDatawithdate(reqdata))


		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}
	const getAttendanceReport = (reqdata) => {
		const rolename = getRole("");
		console.log("Role is " + rolename)


		if (reqdata.startDate && reqdata.endDate) {

			rolename === 'mothercompanyadmin' ?
				dispatch(getVineoShopVisitdatawithdateforMotherclientid(reqdata)) :
				dispatch(getVineoShopVisitdatawithdate(reqdata))


		} else {
			notifyError("Please Enter Date for Bill Start and End ")
		}
	}



	// Ag grid Work Start 
	const [gridApi, setGridApi] = useState(null)

	const onExportClick = () => {
		gridApi.api.exportDataAsCsv();
	}

	const onGridReady = (params) => {
		setGridApi(params);
	}

	const defaultColDef = {
		sortable: true,
		editable: true,
		flex: 1,
		filter: true,
		floatingFilter: true,
	}

	const onPaginationChange = (pageSize) => {
		gridApi.api.paginationSetPageSize(Number(pageSize))
	}

	function onCellValueChanged(event) {
		console.log(
			'onCellValueChanged: '
		);
	}

	const columnDefs = [

		{ headerName: "Product", field: "nameofprduct", resizable: true },
		{ headerName: "OrderQty", field: "totlaqtyofGON", resizable: true },
		{ headerName: "Suply Qty", field: "convertedQty", resizable: true },
		{ headerName: "OrderAmount ", field: "totalGONamount", resizable: true },
		{ headerName: "SupplyAmount", field: 'totalGonCOnvertedAmount', resizable: true },
		{
			headerName: "FieldRate",
			field: "fieldRate",
			resizable: true,
			valueGetter: (params) => {
			  const { data } = params;
			  const convertedQty = data.convertedQty || 0;
			  const totalQty = data.totlaqtyofGON || 1; // To avoid division by zero
			  const fieldRate = (  convertedQty/totalQty) ; // Calculate the ratio as a percentage
			  return `${fieldRate.toFixed(2)} `;
			},
		  },

	]

	const handleUpdate = (data) => {
		console.log(data)
		setDataforprint(data);
		setOpeneditGON(true);
	}
	const handlePrint = (data) => {
		setDataforprint(data);
		setOpenprint(!openprint)
	}
	const handleDelete = (data) => {
		const confirm = window.confirm("Are you sure, you want to delete this row", data)

		if (confirm) {
			http.post(BASEURL_JWTAUTH + VINEO_DELETE_SELECTED_ADDGON + "1", data).then(

				(res) => {
					console.log(res)
					if (res.data.errorCode == "0000") {
						notify('Success:- GON  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

					}
					else
						notifyError("Error While Deleting GON  " + + res.data.errorMessage)
				},
				(err) => {
					notifyError("Error While Deleting GON Try Again  Or Login as ADMIN")

				}
			)
		}
	}
	const handleConvertToInvocie = (data) => {
		console.log('COnvertign this to invoice ', data);


		http.post(BASEURL_JWTAUTH + VINEO_CONVERT_SELECTED_ADDGON_TOINVOICE + "3", data).then(

			(res) => {
				console.log(res)
				if (res.data.errorCode == "0000") {
					notify('Success:- GON  Converted  SUCCESSFULLY with Bill No as ' + res.data.errorMessage)
					handlePrintBill(res.data.data)

				}
				else
					notifyError("Error While Converting  GON to Invocie  " + + res.data.errorMessage)
			},
			(err) => {
				notifyError("Error While Convertign GON Try Again  Or Login as Billing Manger or Admin")

			}
		)

	}

	const handlePrintBill = (data) => {
		setDataforprint(data);
		setOpenBillprint(!openBillprint)
	}

	return (
		<div className="row justify-content-left ">
			<div className='h5 mb-0 text-gray-800'>
				<center>
					<h4 align="center">Welcome to SFA Dashboard -ItemWise GON Summary and Report</h4>
				</center>
			</div>

			<Row>


				<Col md={1}>
					<FormGroup className="m-2">
						<Form.Text >
							Month
						</Form.Text>
						<DatePicker className="form-control"
							selected={fromdate}
							// onChange={(date) => {setfromdate(date);setMonthSelected(true)}}
							dateFormat="MM/yyyy"
							showMonthYearPicker
							showFullMonthYearPicker
							onChange={handleFromDateChange}


						/>

					</FormGroup>
				</Col>

				{console.log("dates are", moment(fromdate).format(DATEFORMAT), "and ", moment(addMonths(fromdate, 1)).format(DATEFORMAT))}



				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >
							<div style={{ display: 'flex', alignItems: 'center' }}>
								Visit From Date

								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 1)); console.log("Date selected is ", fromdate)
								}} > -1Day</div>
								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 10))
								}} > -10Day</div>
								<div onClick={() => {
									setfromdate(currentDate.setDate(currentDate.getDate() - 30))
								}} > -30</div>
							</div>

						</Form.Text>

						<DatePicker className="form-control"
							selected={fromdate}
							onChange={setfromdate}
							name="vineoCreateLoadBillfromdate"
							dateFormat="dd-MM-yyyy"
							ref={setValue("vineoCreateLoadBillfromdate", moment(fromdate).format(DATEFORMAT))}
						/>
					</FormGroup>
				</Col>

				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text>To Date</Form.Text>
						<DatePicker
							className="form-control"
							selected={toDate}
							onChange={setToDate}
							name="vineoCreateLoadBillToDate"
							dateFormat="dd-MM-yyyy"
						/>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup className="m-2">
						<Form.Text>Load</Form.Text>
						<Button className="btn btn-primary" disabled={isLoading} onClick={() => { setLoading(true); getdatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(toDate).format(DATEFORMAT) }) }}>
							<FontAwesomeIcon icon={faRotate} />
							&nbsp;Load Data
						</Button>
					</FormGroup>



				</Col>
				<Col >
					<FormGroup className="m-2">
						<Form.Text>Export </Form.Text>
						<Button className="btn btn-primary" disabled={(filteredTableData?.length > 0) ? false : true} onClick={onExportClick} value="ExportToCsv">
							Export To CSV
						</Button>
					</FormGroup>



				</Col>



			</Row>
			<Row>
				<Col md={1}>
					<FormGroup className="m-2">

						<Form.Text className="text-muted">
							Order
						</Form.Text>
						<Form.Select className="form-control "  >
							<option value="true">ALL</option>
							<option value="true">Yes</option>
							<option value="false">No</option>
						</Form.Select>

					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Salesman Name:-</Form.Text >


						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedSalesman}
								options={Array.from(
									new Set(
										tableData
											?.filter((item) => item?.salesmanName !== null)
											?.map((item) => item.salesmanName)
									)
								).map((salesmanName) => ({ label: salesmanName }))}
								placeholder="Choose Salesman Name..."
								selected={selectedSalesman}
								labelKey={"label"}
							/>
							<Button className="btn btn-primary" onClick={() => { selectedSalesman?.[0]?.label ? setShowmap(true) : notifyWarning("Select Salesman ") }}>
								<FontAwesomeIcon icon={faRotate} />ShoWMap


								{/* {console.log("Salesmannameis", selectedSalesman?.[0]?.label)} */}
							</Button>


							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text >Party Name:-</Form.Text >


						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedParty}
								options={Array.from(
									new Set(
										tableData
											?.filter(item => item?.vineoAddSaleBillCustomer?.vineoCustomerAccountName !== null)
											?.map(item => item.vineoAddSaleBillCustomer.vineoCustomerAccountName)
									)
								).map((partyName) => ({ label: partyName }))}
								placeholder="Choose Party Name..."
								selected={selectedParty}
								labelKey={"label"}
							/>
							{/* {console.log("Value of selected party is " + selectedParty?.[0]?.label)} */}




							{/* {setValue("dataforaddsaleBill[0].vineoAddSaleBillCustomer", selectedCustomer)} */}
						</InputGroup>
					</FormGroup>

				</Col>
				<Col md={3}>
					<FormGroup className="m-2">
						<Form.Text>Firm Name:</Form.Text>
						<InputGroup>
							<Typeahead
								disabled={false}
								id="label"
								onChange={setSelectedFirm}
								options={Array.from(
									new Set(
										tableData
											?.filter(item => item?.vineoAddSaleBillFirm?.vineoFirmFirmName !== null)
											?.map(item => item.vineoAddSaleBillFirm.vineoFirmFirmName)
									)
								).map((FirmName) => ({ label: FirmName }))}
								placeholder="Choose Firm Name..."
								selected={selectedFirm}
								labelKey={"label"}
							/>
						</InputGroup>
					</FormGroup>

				</Col>


			</Row>


			{/* rowData={selectedParty?.[0]?.label ?
							(selectedSalesman?.[0]?.label ?
								tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
								: tableData)?.filter((item) => item?.partyName === selectedParty?.[0]?.label) :
							(selectedSalesman?.[0]?.label ?
								tableData?.filter((item) => item?.nameofUser === selectedSalesman?.[0]?.label)
								: tableData)} */}


			<div className="row mt-4">
				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										GON (Total)
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{totalVisits}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-calendar fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-success shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
										Invoice
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{convertedtobill}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>



				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-warning shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
										Pending
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{pendingforbill}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Convertion Rate
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{((convertedtobill / totalVisits) * 100).toFixed(2)} %</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row mt-4">
				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Working Salesman
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{uniqueSalesmen}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-calendar fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-success shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
										Total Invocie Value
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{totalInvoiceValue}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>



				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-warning shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
										Total GON value // Pending Value
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{totalGonValue} / {totalGONpendingvalue}</div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6 mb-4">
					<div className="card border-left-primary shadow h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
										Party Ordered On Visit
									</div>
									<div className="h5 mb-0 font-weight-bold text-gray-800">{((uniqueParties)).toFixed(2)} </div>
								</div>
								<div className="col-auto">
									<i className="fas fa-comments fa-2x text-gray-300"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="ag-theme-alpine" style={{ height: '500px' }}>
				<div className="ag-theme-alpine" style={{ height: '570px' }}>
					<AgGridReact
						id="AgGridforBillData"
						// rowData={tableData}
						rowData={resultArray || []}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
					/>


				</div>
			</div>


			<Modal show={openprint}
				onHide={() => setOpenprint(!openprint)}
				backdrop="static"
				fullscreen={true}
				size="lg"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Print GON</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className='p-4'>
						<GONprint data={dataforprint} />
					</Card>

				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>


		</div>
	)
}