
import React, { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup, FormText } from "react-bootstrap";
import { faMoneyBillTransfer, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import VendorToProduct from '../../mapping/VendorToProduct';
import { useSelector, useDispatch } from 'react-redux'
import AddBatch from '../stockadjustment/AddBatch';
import { getVineoProductData } from '../../../../redux/features/services/serviceHelper';
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_EDIT_SELECTED_ADDBILL, VINEOFORM_EDIT_SELECTED_ADDBILLold, VINEOFORM_FEDERALBANK_PAY_ADDBILL, VINEOFORM_PAYTM_PAY_ADDBILL, VINEOFORM_SETTLE_SELECTED_ADDBILL, VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID } from '../../../../constants/Urlforvineo';
import moment from 'moment';
import { render } from '@testing-library/react';
import http from '../../../../constants/AxiosConfig';

function BillSettle(props) {

  const { data } = props
  const [billpaymentdate, setBillpaymentdate] = useState(new Date())
  const [creditnotecredit, setCreditnotecredit] = useState()
  const [datafromPayment, setDatafromPayment] = useState()
  const [selectedCreditNote, setSelectedCreditNote] = useState([])
  const [openPaymentModal, setOpenPaymentModal] = useState(false)

  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message)
  const notifyWarning = (message) => toast.warning(message)

  const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    submitForm(data)
  }

 
  const payviaonlinechannel = (data) => {
    console.log("Doing Payment via Onlien Channel ", data);
    http.get(BASEURL_JWTAUTH + VINEOFORM_PAYTM_PAY_ADDBILL + "?billUniqueID=B" + data?.ultiVineoAddBillUniqeId + "&hashValue=eubfdjkafjanfhengfkda" ).then(
        (res) => {
          console.log(res.data)
          setDatafromPayment(res.data)
          setOpenPaymentModal(true)
        },
        (err) => {


          notifyError("Error While Getting P amount Operation From Server ")
        }
      )
 
  }

  const payviaonlinechannelFederalBank = (data) => {
    console.log("Doing Payment via Onlien Channel ", data);
    http.get(BASEURL_JWTAUTH + VINEOFORM_FEDERALBANK_PAY_ADDBILL + "?billUniqueID=B" + data?.ultiVineoAddBillUniqeId + "&hashValue=eubfdjkafjanfhengfkda" ).then(
        (res) => {
          console.log(res.data)
          setDatafromPayment(res.data)
          setOpenPaymentModal(true)
        },
        (err) => {


          notifyError("Error While Getting P amount Operation From Server ")
        }
      )
 
  }
  const getcreditavaialblefromserver = () => {

    http.post(BASEURL_JWTAUTH + VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID +
      data?.vineoAddSaleBillCustomer?.ultiVineoCustomerAccountUniqeId).then(
        (res) => {
          console.log(res.data)
          if (res.data?.[0]?.creditnoteAmount > '0') {
            notify('Credit Amount is Available - ' + res?.data?.[0]?.creditnoteAmount)
            setCreditnotecredit(res.data);
            console.log(creditnotecredit);
            console.log("CreditNote Above");

          }
          else
            notifyError("Credit Note Amount is Not Available For Selected Customer- ")

        },
        (err) => {


          notifyError("Error While Getting Credit amount Operation From Server ")
        }
      )

  }
  const submitForm = (data) => {
    console.log("Connecting Server to Edit Bill")
    // api call
    http.post(BASEURL_JWTAUTH + VINEOFORM_SETTLE_SELECTED_ADDBILL, data).then(
      (res) => {
        console.log(res.data)
        if (res.data.errorCode == '0000') {

          notify('Edit Bill Operation Successful with Message as- ' + res.data.errorMessage)
        }
        else
          notifyError("Error While Edit Bill Operation From Server with Message as- " + res.data.errorMessage)
        //  console.log(res.data)
      },
      (err) => {


        notifyError("Error While Edit  Bill Operation From Server ")
      }
    )
  }



  return (
    <>    {
      data.receiptIsGenerated ? <div>Receipt is Generated You Can not edit this bill</div> :

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={2}> BillEdit Number:-{data?.vineoaddUniqueBillNumberWithPrefix}</Col>
              <Col md={3}> Firm Name:-{data?.vineoAddSaleBillFirm?.vineoFirmFirmName} <br />
                GST:-{data?.vineoAddSaleBillFirm?.vineofirmGst?.vineoGstNumber}
              </Col>
              <Col md={3}>Party Name:-{data?.vineoAddSaleBillCustomer?.vineoCustomerAccountName} <br />
                GST:-{data?.vineoAddSaleBillCustomer?.vineoCustomerGstNumber}
              </Col>
              <Col md={4}>
                <Row>
                  <Col><FormGroup>
                    <Form.Text >
                      Payment  Date
                    </Form.Text>
                    <DatePicker className="form-control"
                      selected={billpaymentdate}
                      onChange={setBillpaymentdate}
                      name="vineoPaymentDate"
                      dateFormat="dd-MM-yyyy"
                      ref={setValue("vineoPayment.vineoPaymentDate", moment(billpaymentdate).format(DATEFORMAT))}
                    />
                  </FormGroup></Col>
                  <Col>
                    <Form.Text >
                      Payment  Mode
                    </Form.Text>
                    <Form.Select className="form-control"
                      {...register("vineoPayment.paymentType")} required>
                      <option value="CASH">Cash</option>
                      <option value="CHEQUE">Cheque</option>
                      <option value="ONLINE">Online</option>
                      <option value="CREDIT">Credit</option>
                      <option value="PARTIAL">Partial</option>
                    </Form.Select></Col>
                </Row>

                {watch(`vineoPayment.paymentType`) == 'CHEQUE' ? <>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Form.Text >
                          CHK Number
                        </Form.Text>
                        <Form.Control size='sm' className="form-control" {...register("vineoPayment.chequeNumber")} required />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Form.Text >
                          BankName
                        </Form.Text>
                        <Form.Control size='sm' className="form-control" {...register("vineoPayment.bankName")} required />
                      </FormGroup></Col>
                  </Row>

                </> : <></>}

                {watch(`vineoPayment.paymentType`) == 'ONLINE' ? <>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Form.Text >
                          UPI ID/ Bank Name
                        </Form.Text>
                        <Form.Control size='sm' className="form-control" {...register("vineoPayment.upiidName")} required />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Form.Text >
                          onlineTransactionNumber
                        </Form.Text>
                        <Form.Control size='sm' className="form-control" {...register("vineoPayment.onlineTransactionNumber")} required />
                      </FormGroup></Col>
                  </Row>
                  <Row>
                    <Col className="m-2" > <Button variant="primary"
                      onClick={() => { payviaonlinechannel(data) }} >
                      <FontAwesomeIcon icon={faMoneyBillTransfer} />Pay Via PayTM
                    </Button></Col>
                    <Col className="m-2" > <Button variant="primary"
                      onClick={() => { payviaonlinechannelFederalBank(data) }} >
                      <FontAwesomeIcon icon={faMoneyBillTransfer} />Pay FederalBank
                    </Button></Col>
                  </Row>

                </> : <></>}

                <FormGroup>

                  {console.log("value of credit note amount is ", selectedCreditNote?.[0]?.creditnoteAmount)}
                  <Form.Text >
                    Amount Paid / Total Bill Bottom Amnt:- {data?.vineoAddSaleBillBottomAmt} - CR : {data?.vineoPayment?.actualCreditAmountApplied}  = {parseInt(data?.vineoAddSaleBillBottomAmt) - parseInt(data?.vineoPayment?.actualCreditAmountApplied) -
                      parseInt((selectedCreditNote?.[0]?.creditnoteAmount) || '0')}
                  </Form.Text>
                  <Form.Control size='sm'
                    defaultValue={parseInt(data?.vineoAddSaleBillBottomAmt) - parseInt(data?.vineoPayment?.actualCreditAmountApplied) -
                      parseInt((selectedCreditNote?.[0]?.creditnoteAmount) || '0')}
                    className="form-control" {...register("vineoPayment.actualPaymentAmountPaid")} required />
                  {!data?.iscreditnoteappliedinPayment ?
                    <Row className="mt-2">
                      <InputGroup>

                        <Typeahead
                          id="CreditnoteTypehead"
                          onChange={setSelectedCreditNote}
                          disabled={creditnotecredit ? false : true}
                          options={creditnotecredit?.filter((item) => {
                            if (!item.issuedCCwithoutReceipt) { return item }
                          })}
                          placeholder="Check CreditNote"
                          selected={selectedCreditNote}
                          labelKey={creditnotecredit => `${creditnotecredit.creditnoteAmount.toFixed(2)}`}

                          renderMenuItemChildren={(option) => (
                            <div key={option.id}>
                              <div>
                                <small>Amount:  {`${option?.creditnoteAmount.toFixed(2)}`}</small>

                                <small> -CRN: {`${option?.creditNoteIssuedonBillNumber}`}</small>
                              </div>
                            </div>
                          )}
                          ref={() => {

                            { setValue(`vineoPayment.vineoCreditNote`, selectedCreditNote[0]) }
                          }}
                        />
                        <Button variant="primary"
                          onClick={() => { getcreditavaialblefromserver() }} >
                          <FontAwesomeIcon icon={faRotate} />
                        </Button>


                      </InputGroup>

                    </Row>
                    : <></>}

                </FormGroup>
              </Col>
            </Row>
            {setValue(`ultiVineoAddBillUniqeId`, data?.ultiVineoAddBillUniqeId)}

            <Card className='p-4'>
              <table className="table" >
                <thead className="thead-dark" cellSpacing="0" cellPadding="0">
                  <tr>
                    <th scope="col" width="1%"></th>
                    <th scope="col" width="15%">Item Name </th>
                    <th scope="col" width="2%">Batch</th>
                    <th scope="col" width="6%">Unit</th>
                    <th scope="col" width="6%">D.Rate</th>
                    <th scope="col" width="6%">Actual Qty</th>
                    <th scope="col" width="4%">RetDamage</th>
                    <th scope="col" width="4%">RetSellable</th>
                    <th scope="col" width="3%">GST(C+S)</th>
                    <th scope="col" width="4%">Net</th>

                  </tr>
                </thead>
                <tbody >
                  {data?.vineoAddBillItemList?.map((item, index) => {
                    return (

                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.vineoProduct.vineoProductName}</td>
                        <td>{item.vineoBatch.vineoBatchNumber}</td>
                        {console.log("Unit value is " + item.vineoAddBillItemUnit)}
                        <td>{item.vineoAddBillItemUnit}</td>
                        <td>{item.vineoItemEffectiverateAfterDsicount}</td>
                        <td>{item.vineoAddBillProductBatchQty}</td>
                        <td>{item.vineoAddBillProductDamagedReturnedBatchQtyinPCS} </td>
                        <td>{item.vineoAddBillProductSellableReturnedBatchQtyinPCS}   </td>
                        <td>{item.vineoAddBillItemTotalGSTAmount}</td>

                        <td>{item.vineoAddBillItemNetAmount}</td>
                        {setValue(`vineoAddBillItemList[${index}].ultiVineoAddBillItemUniqeId`, item.ultiVineoAddBillItemUniqeId)}
                      </tr>

                    )
                  })
                  }
                </tbody>
              </table>
            </Card>


            <center>
              <input className="btn btn-primary m-2" type="submit" value="Submit" />
              <input className="btn btn-primary m-2" type="button" value="Print" />
              <Button className="btn btn-danger m-2" type="button" onClick={() => { reset() }} value="Cancle">Cancel</Button>
            </center>
          </form>

          {console.log(data)}
          {console.log(data.receiptIsGenerated)}
        </div>
    }

      <Modal show={openPaymentModal}
        onHide={() => setOpenPaymentModal(!openPaymentModal)}
        // backdrop="static"
        fullscreen={true}
        size="lg"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Pay Online</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
        <div dangerouslySetInnerHTML={{ __html: datafromPayment }} />
      </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>


  )
}

export default BillSettle