import React from "react";
import { Col, Row, Form, FormGroup, Button, InputGroup, Modal, Card } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';

import { useState, useEffect } from "react";
import { BASEURL_JWTAUTH, DATEFORMAT, VINEOFORM_DELETE_SELECTED_ADDBILL, VINEOFORM_DELETE_SELECTED_CREATELOADBILL, VINEO_GETALL_SETTLELOADBILL_AVAILABLE_WITHDATE } from '../../../../constants/Urlforvineo';
import moment from "moment";
import { faClose, faPrint, faRotate, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import { getVineoVanMasterData } from '../../../../redux/features/services/serviceHelper';
import { getVineoAddBillData, getVineoAddBillDatawithdate, getVineoCreateLoadDatawithdate } from '../../../../redux/features/services/entriesHelperService';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector, useDispatch } from 'react-redux'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from "../../../../constants/AxiosConfig";
import { Link } from "react-router-dom";
import SettleLoad from "./SettleLoad";
import Loadprint from "./Loadprint";

export default function DeleteandSettleLoad() {
    useEffect(() => {
       
    }, []);
    const dispatch = useDispatch()

    let tmpAllProductData = {}
    const [tableData, setTableData] = useState();
    const loadvan = useSelector((state) => state.vineoData.vineovanmasterdata)
    const vineocreateloaddatawithdate = useSelector((state) => state.vineoData.vineocreateloaddatawithdate);
    const isvineocreateloaddatawithdateLoaded = useSelector((state) => state.vineoData.isvineocreateloaddatawithdateLoaded);
    const [loaddate, setLoaddate] = useState(new Date());
    const [fromdate, setFromdate] = useState((new Date()).setDate((new Date()).getDate() - 3));;
    const [todate, setTodate] = useState((new Date()).setDate((new Date()).getDate()));
    const [vanSelected, setVanSelected] = useState([])
    const [selectedBills, setSelectedBills] = useState([])
    const [totalloadBillValue, setTotalloadBillValue] = useState(0)
    const [openSettleload, setOpenSettleload] = useState(false)
    const [dataforSettleLoad, setDataforSettleLoad] = useState();
    const [dataforprint, setDataforprint] = useState();
    const [openloadprint, setOpenloadprint] = React.useState(false);
    const [loaddetailsforprint, setLoaddetailsforprint] = useState([{}])
    const [loaddetailsUpdatedforprint, setLoaddetailsUpdatedforprint] = useState([{}])
    const [showLoadSetlled, setShowLoadSetlled] = useState(true);
    const [summaryofload, setSummaryofload] = useState({})
    const [numberofloadbillselected, setNumberofloadbillselected] = useState(0)
    let selectedLoadArray = []
    const { register, handleSubmit, setValue, reset, watch, formState: { errors }, control } = useForm();
    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)


    useEffect(() => {
      
        console.log("Refreshing on state Change ")
        const timer = setTimeout(() => console.log('Initial timeout!'), 800);
        setTableData(vineocreateloaddatawithdate)
     }, [isvineocreateloaddatawithdateLoaded]);
    const onSubmit = (data) => {
        // console.log(selectedLoadArray);
        submitForm(selectedLoadArray)
    }
    const submitForm = (data) => {
        console.log(data)
        if (gridApi.api.getSelectedNodes().length > 0) {
            setDataforSettleLoad(data[0])
            setOpenSettleload(!openSettleload)
        }
        else {
            notifyError("Please Select Load To Settle  ")
            setOpenSettleload(false)
        }
    }

    const getbilldatafromserverwithdate = (reqdata) => {
        if (reqdata.startDate && reqdata.endDate) {
            // console.log(reqdata.startDate)
            // console.log(reqdata.endDate)
            // console.log(reqdata)
            var endDate = moment(reqdata.endDate, 'DD-MM-YYYY');
            var startDate = moment(reqdata.startDate, 'DD-MM-YYYY');
            if (endDate.diff(startDate, 'days') < 60) {
                dispatch(getVineoCreateLoadDatawithdate(reqdata))
                isvineocreateloaddatawithdateLoaded ? setTableData(vineocreateloaddatawithdate) : console.log("Empty data from Server for Load")

            } else {
                notifyError("Number Of Days Range for Bill Should Be Less than 60 ")
            }

        }
        else {
            notifyError("Please Enter Date for Bill Start and End ")
        }


    }

    const getdatafromserver = () => {

        console.log("Called to get Van Data ")
        dispatch(getVineoVanMasterData())
        console.log("Empty Data Received form Server")
    }


    // Ag grid Work Start selectedBillsArray
    const [gridApi, setGridApi] = useState(null)

    const onSelectload = () => {
        selectedLoadArray = [];

        let tempvalue = 0;

        gridApi.api.getSelectedNodes().map((load, index) => {
            return (
                <>
                    {selectedLoadArray[index] = load.data}
                    {/* {console.log(bill.data)} */}

                    {/* {console.log(totalloadBillValue)}
                    {console.log(selectedBillsArray.length)} */}
                </>
            )
        })
        // { setValue( selectedLoadArray) }


    }

    const onExportClick = () => {
        gridApi.api.exportDataAsCsv();
    }

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        // editable: true,
        rowSelection: 'single',
        onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }

    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedRows();
        var selectedRowsString = '';
        var maxToShow = 5;
        console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }


    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [
        { headerName: "LoadNo.", field: "vineoaddLoadNumberWithPrefix", checkboxSelection: true },
        { headerName: "Date.", field: "vineoCreateLoadDate" },

        { headerName: "TotalLoadValue", field: "totalLaodValue" },
        { headerName: " NumberOfBills", field: "numberofBillsIncreateLaod" },
        {
            headerName: "Bill Nos. ", field: `vineoAddBillSet`,
            cellRenderer: params => {
                let billnumber = ""
                console.log(
                    params?.value?.map((bill) => {
                        billnumber = billnumber + bill?.vineoaddUniqueBillNumberWithPrefix + ","
                    })
                )
                console.log("Billnumber is " + billnumber)
                return billnumber;
            }
        },
        {
            headerName: "Settled ", field: `loadIssettled`,
            cellRenderer: params => {
                // console.log(params.value)
                if (params.value) {
                    return `YES`
                }
                return `NO`;
            }
        },

        // { headerName: "Bill Numbers", field: "vineoAddSaleBillType", },


        // { headerName: "Party", valueGetter: 'data.vineoAddSaleBillFirm.vineoFirmFirmName' },

        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                <Button variant="warning m-1" onClick={() => printLoad(params.data)}><FontAwesomeIcon icon={faPrint} /></Button>
                <Button variant="warning m-1" onClick={() => handleUpdate(params.data)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
            </div>
        }
    ]


    const createLoadArrayforLoadDetailPrint = (data) => {
        setLoaddetailsforprint([])
        setLoaddetailsUpdatedforprint([])
        setSummaryofload([])

        const serverData = data?.vineoAddBillSet || []
       
      
        let newVar = {}
        for (let i = 0; i < serverData.length; i++) {
            const itemList = serverData[i].vineoAddBillItemList || []
            for (let j = 0; j < itemList.length; j++) {
                const productName = itemList[j].vineoProduct.vineoProductName
                const qty = (tmpAllProductData?.[productName]?.qty || 0) + itemList[j].vineoAddBillProductBatchQty
                const freeQty = (tmpAllProductData?.[productName]?.freeQty || 0) + itemList[j].vineoAddBillItemFreeProductQty
                tmpAllProductData[productName] = {
                    qty,
                    freeQty,
                    productName,
                }
                newVar[productName] = {
                    qty,
                    freeQty,
                    productName,
                }
            }
           setSummaryofload(tmpAllProductData)
        }
          
        console.log(tmpAllProductData )
      
    }
    const printLoad = (data) => {

        console.log("Data Inside Handle Print ")
        // console.log(data)
        setDataforprint(data);
        createLoadArrayforLoadDetailPrint(data)
        console.log("Updated List")
        console.log(loaddetailsUpdatedforprint);
        console.log(loaddetailsforprint)
        // console.log(dataforprint);
        // setData(data)
        setOpenloadprint(!openloadprint)
    }


    const handleUpdate = (data) => {
        console.log(data)

        // console.log("This will editqty by this amount "+updatedProductBatchQty);
        // submitForm(data)

    }
    //deleting a user

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_CREATELOADBILL, requestdata).then(

            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Bill  DELETED SUCCESSFULLY with Bill No as ' + res.data.errorMessage)

                }
                else
                    notify("Error While Deleting Bill  " + res.data.errorMessage)
            },
            (err) => {
                notify("Error While Deleting Bill Try Again  Or Login as ADMIN")

            }
        )
    }

    const handleDelete = (data) => {
        const confirm = window.confirm("Are you sure, you want to delete this row", data)
        console.log(data)
        if (confirm) {
            submitFormForDeleteSelectedRow(data)

        }
    }

    // AG work ends here 

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div>


                    <center>
                        <Link to="/dashboard/CreateLoad" className="btn btn-primary m-2">CreateLoad</Link>
                        <Button className="btn btn-success m-2" type="submit"> Settle Load</Button>
                        {/* <Button className="btn btn-danger m-2" onClick={() => { setOpenSettleload(false) }} > Close</Button> */}

                        {/* <Link to="/dashboard/SettleLoad" className="btn btn-primary m-3">Settle Load</Link>
                        <Link to="/dashboard/SettleLoad" className="btn btn-primary m-3">Settle Load</Link> */}
                        <Button className="btn btn-warning m-2" onClick={() => { 
                               if (gridApi.api.getSelectedNodes().length > 0) {
                              
                                setOpenSettleload(!openSettleload)
                            }
                            else {
                                notifyError("Please Select Load To Settle  ")
                                setOpenSettleload(false)
                            }
                            }}> Delete/Settle Load</Button>

                    </center>
                </div>
            </form>
            {openSettleload ? <SettleLoad data={dataforSettleLoad} /> : <>
                {/* send propos with laod details here  */}
                <div>
                    <Row><Col md={3}>
                        <FormGroup className="m-2">
                            <Form.Text >
                                Bills From Date
                            </Form.Text>
                            <DatePicker className="form-control"
                                selected={fromdate}
                                onChange={setFromdate}
                                name="vineoCreateLoadBillFromDate"
                                dateFormat="dd-MM-yyyy"
                                ref={setValue("vineoCreateLoadBillFromDate", moment(fromdate).format(DATEFORMAT))}
                            />
                            {/* <Controller
                                name="vineoCreateLoadBillFromDateref"
                                //
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <DatePicker className="form-control"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        dateFormat="dd-MM-yyyy"


                                    />

                                )}
                            />
                            {errors.vineoCreateLoadBillFromDateref && <span>This field is required</span>} */}

                        </FormGroup>
                    </Col>
                        <Col md={3}>
                            <FormGroup className="m-2">
                                <Form.Text >
                                    Bills To Date
                                </Form.Text>

                                <DatePicker className="form-control"
                                    selected={todate}
                                    onChange={setTodate}
                                    name="vineoCreateLoadBillToDate"
                                    dateFormat="dd-MM-yyyy"
                                    ref={setValue("vineoCreateLoadBillToDate", moment(todate).format(DATEFORMAT))}
                                />

                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Button className="btn btn-warning m-2" onClick={() => getbilldatafromserverwithdate({ startDate: moment(fromdate).format(DATEFORMAT), endDate: moment(todate).format(DATEFORMAT) })}> <FontAwesomeIcon icon={faRotate} /> Load Loads</Button>

                            {/* <button className="btn btn-primary m-2" type="button" onClick={onSelectloadButtonClick}>
                    SelectBills
                </button> */}
                            <Button className="btn btn-primary m-2" onClick={onExportClick} value="ExportToCsv">Export To CSV
                            </Button>
                            <Button className={showLoadSetlled?"btn btn-primary m-2":"btn btn-info m-2"} onClick={()=> {setShowLoadSetlled(!showLoadSetlled)}} value="setShowLoadSetlled">Show Settled Loads</Button> 

                        </Col>
                    </Row>
                    <div className="ag-theme-alpine" style={{ height: '400px' }}>
                        <div className="ag-theme-alpine" style={{ height: '700px' }}>
                            <AgGridReact
                                id="AgGridforBillData"
                                rowSelection="multiple"
                                rowData={ showLoadSetlled ? tableData?.filter((item)=>{if(!item.loadIssettled) {return item}}) :
                                tableData?.filter((item)=>{if(item.loadIssettled) {return item}})}
                                columnDefs={columnDefs}
                                onRowSelected={onSelectload}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                            />
                        </div>
                    </div>
                </div>
            </>}
            <Modal show={openloadprint}
                onHide={() => {setOpenloadprint(!openloadprint)}}
                backdrop="static"
                fullscreen={true}
                size="lg"
                keyboard={false}>
                    
                <Modal.Header closeButton>
                    <Modal.Title>Print Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='p-4'>
                        <Loadprint data={dataforprint} loaddata={dataforprint} summaryofload={summaryofload} />
                    </Card>
                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

        </div >


    )
}