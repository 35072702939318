import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../constants/AuthUser';
import BatchFormat from '../../csvformat/BatchFormat.csv';
import { useSelector, useDispatch } from 'react-redux'
import {
    getVineoUsers, getVineoSalesmanUsers, getUser, getVineoCompanyData, getVineoCompanyCategoryData, getVineoCompanySubCategoryData, getVineoFirmData, getVineoGroupyData, getVineoSubGroupyData,
    getVineoBrandData, getVineoGstData, getVineoProductData, getVineoAreaData, getVineoRouteData, getVineoAccountType1Data, getVineoAccountType2Data, getVineoAccountCustomerMasterMenu,
    getVineoAccountGroupData, getVineoCustomerBankMasterData, getVineoSchemaMasterData, getVineoVanMasterData, getVineoGodownData, getVineoServiceAccountData, getVineoSpecialGroupMasterData, getVineoSubBrandData, getVineoAccountVendorCustomerMasterMenu
} from "../../redux/features/services/serviceHelper";

import { toast } from 'react-toastify';
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem
} from 'react-bootstrap';
import { CHECK_JWT_TOKEN, GSTVALIDATEAPIURLWITHKEYCHECKBALANCE, RESET_PASSWORD } from '../../constants/Urlforvineo';
import { getVineoAddBillData, getVineoBatchData } from '../../redux/features/services/entriesHelperService';
import Feedbackform from '../loginRegisterForms/Feedbackform';





export default function CheckJwt() {
    const dispatch = useDispatch()


    const navigate = useNavigate();
    const { http, getUser } = AuthUser();


    const [formDetails, setformDetails] = useState({});
    const [choice, setChoice] = useState();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)

    const checkjwttoken = (formDetails) => {
        console.log(formDetails)
        console.log("working form til this point ")
        submitForm(formDetails)
        formDetails.preventDefault();

    }

    const sendpassword = (requestdata) => {
        // api call
        http.post(RESET_PASSWORD+"/mansiadmin").then(
            (res) => {
                if (res.data) {

                    console.log(res)
                    notify((res.data.message))

                    
                }
                else
                    notify("JWT is Not Valid ")
                //  console.log(res.data)

            },
            (err) => {
                notify("JWT is Not Valid ")
            }
        )
    }



    const checkGSTbalance = () => {
        // api call
        http.get(GSTVALIDATEAPIURLWITHKEYCHECKBALANCE).then(
            (res) => {

                notify(("GST API Calls Remaining:- " + res.data.data));
                console.log(res)

            },
            (err) => {
                notify("Error")
            }
        )
    }





    useEffect(() => {
        setformDetails({ ...formDetails, gender: "F", enabled: "true", accountLocked: "false", role: "admin" })
        //console.log(formDetails)
        console.log("Use Effect called ")
    }, []);

    const loaddatinredux = () => {

        notify("Data Loaded pls check Redux");

    }

    const submitForm = (requestdata) => {
        // api call
        http.post(CHECK_JWT_TOKEN).then(
            (res) => {
                if (res.data) {


                    notify((res.data.message))

                    console.log()
                }
                else
                    notify("JWT is Not Valid ")
                //  console.log(res.data)

            },
            (err) => {
                notify("JWT is Not Valid ")
            }
        )
    }
    const fileNames = ['BatchFormat.csv', 'billformat.csv', 'clientdataImport.csv', 'einvocieUniversalformat.csv', 'formatofParty.csv', 'Productformat.csv', 'PurcahseFormat.csv', 'userdataImport.csv'];
    const folderPath = '/src/csvformat/';

    const downloadFiles = () => {
        fileNames.forEach((fileName) => {
            const filePath = folderPath + fileName;
            const link = document.createElement('a');
            link.href = filePath;
            link.download = fileName;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };


    return (
        <div>
            <h2>VINEO TECH PVT. LTD. </h2>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ListGroup>
                            <ListGroupItem>
                                Visit Us At:-
                                {/* <Col className='block-example border-left border-dark'>
                                       
                                    </Col>
                                */}

                            </ListGroupItem>
                            <ListGroupItem>
                                122 , Mahindra M-lifeSpace,
                            </ListGroupItem>
                            <ListGroupItem>
                                Pimpri, Pune -18
                            </ListGroupItem>
                            <ListGroupItem>
                                www.vineotech.com
                            </ListGroupItem>
                            <ListGroupItem>
                                info@vineotech.com
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col md={8}>
                        <div >
                            <div className="card p-3">

                                <Form className="p-3" onSubmit={checkjwttoken}>

                                    <Button type="submit" className="btn btn-primary">Check My JWT Token</Button>

                                </Form>
                                <Form className="p-3" onSubmit={sendpassword}>

                                    <Button type="submit" className="btn btn-primary">Send Password </Button>

                                </Form>
                                <Form className="p-3" >

                                    <Button onClick={checkGSTbalance} className="btn btn-primary">Check GST API Balance</Button>

                                </Form>
                                <div className="p-3">
                                    <Button type="submit" className="btn btn-primary" onClick={loaddatinredux}>Load Data in Redux</Button>

                                </div>
                                <div className="p-3">
                                    <Button className="btn btn-primary" onClick={downloadFiles}>Download Template</Button>
                                </div>


                            </div >
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Feedbackform />
                </Row>

            </div>
        </div>
    )

}