
import React, { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { Form, FormGroup, Col, Row, Card, Button, Modal, InputGroup, FormText } from "react-bootstrap";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import VendorToProduct from '../../mapping/VendorToProduct';
import { useSelector, useDispatch } from 'react-redux'
import AddBatch from '../stockadjustment/AddBatch';
import { getVineoProductData } from '../../../../redux/features/services/serviceHelper';
import { BASEURL_JWTAUTH, DATEFORMAT, DEFAULT_UNIT_OPTIONS, VINEOFORM_EDIT_SELECTED_ADDBILL, VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID } from '../../../../constants/Urlforvineo';
import moment from 'moment';
import { render } from '@testing-library/react';
import http from '../../../../constants/AxiosConfig';

function BillEdit(props) {
  const dispatch = useDispatch()
  const { data } = props
  const [billpaymentdate, setBillpaymentdate] = useState(new Date())
  const [creditnotecredit, setCreditnotecredit] = useState()
  const [selectedCreditNote, setSelectedCreditNote] = useState([])
  const [billdate, setBilldate] = useState(new Date())
  const [updateEntity, setUpdateEntity] = useState(false)
  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message)
  const notifyWarning = (message) => toast.warning(message)

  const { control, register, handleSubmit, setValue, reset, getValues, watch } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    submitForm(data)
  }

  const getcreditavaialblefromserver = () => {

    http.post(BASEURL_JWTAUTH + VINEO_GETALL_POSITIVE_CREDITNOTE_AVAILABLEBYID +
      data?.vineoAddSaleBillCustomer?.ultiVineoCustomerAccountUniqeId).then(
        (res) => {
          console.log(res.data)
          if (res.data?.[0]?.creditnoteAmount > '0') {
            notify('Credit Amount is Available - ' + res?.data?.[0]?.creditnoteAmount)
            setCreditnotecredit(res.data);
            console.log(creditnotecredit);
            console.log("CreditNote Above");

          }
          else
            notifyError("Credit Note Amount is Not Available For Selected Customer- ")

        },
        (err) => {


          notifyError("Error While Getting Credit amount Operation From Server ")
        }
      )

  }
  const submitForm = (data) => {
    console.log("Connecting Server to Edit Bill")
    // api call
    http.post(BASEURL_JWTAUTH + VINEOFORM_EDIT_SELECTED_ADDBILL, data).then(
      (res) => {
        console.log(res.data)
        if (res.data.errorCode == '0000') {

          notify('Edit Bill Operation Successful with Message as- ' + res.data.errorMessage)

        }
        else
          notifyError("Error While Edit Bill Operation From Server with Message as- " + res.data.additionalInfo)
        //  console.log(res.data)
      },
      (err) => {


        notifyError("Error While Edit  Bill Operation From Server ")
      }
    )
  }



  return (
    <>    {
      data.receiptIsGenerated ? <div>Receipt is Generated You Can not edit this bill</div> :

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={2}> Bill Number:-{data?.vineoaddUniqueBillNumberWithPrefix}

                <Form.Control size='sm' minLength={1} defaultValue={data?.vineoaddUniqueBillNumberWithPrefix} className="form-control" {...register(`vineoaddUniqueBillNumberWithPrefix`)} />
              </Col>
              <Col md={3}> Firm Name:-{data?.vineoAddSaleBillFirm?.vineoFirmFirmName} <br />
                GST:-{data?.vineoAddSaleBillFirm?.vineoFirmGstNumber}
              </Col>
              <Col md={3}>Party Name:-{data?.vineoAddSaleBillCustomer?.vineoCustomerAccountName} <br />
                GST:-{data?.vineoAddSaleBillCustomer?.vineoCustomerGstNumber}
              </Col>
              <Col md={3}>
                <FormGroup>

                  <Form.Text >
                    Bill Date -{data?.vineoAddSaleBillDate}
                  </Form.Text>
                  <Form.Group controlId="updateDate" className="mb-3">
                    <Form.Check
                      onChange={(e) => setUpdateEntity(!updateEntity)}
                      label='Update Date'
                      id="Checkbox"
                    />
                  </Form.Group>

                  {/* {setBilldate(moment(data?.vineoAddSaleBillDate, 'DD-MM-YYYY').toDate())} */}
                  {updateEntity ?
                    <DatePicker className="form-control"
                      selected={billdate}
                      onChange={setBilldate}
                      name="vineoBillDate"
                      dateFormat="dd-MM-yyyy"

                    /> : <></>}
                  {updateEntity ? setValue("vineoAddSaleBillDate", moment(billdate).format(DATEFORMAT)) :
                    setValue("vineoAddSaleBillDate", data?.vineoAddSaleBillDate)}
                </FormGroup>
              </Col>

            </Row>
            {setValue(`ultiVineoAddBillUniqeId`, data?.ultiVineoAddBillUniqeId)}

            <Card className='p-4'>
              <table className="table" >
                <thead className="thead-dark" cellSpacing="0" cellPadding="0">
                  <tr>
                    <th scope="col" width="1%"></th>
                    <th scope="col" width="15%">Item Name </th>
                    <th scope="col" width="2%">Batch</th>
                    <th scope="col" width="6%">Unit</th>
                    <th scope="col" width="6%">D.Rate</th>
                    <th scope="col" width="6%">Actual Qty</th>
                    <th scope="col" width="6%">Add Qty</th>
                    <th scope="col" width="4%">RetDamage</th>
                    <th scope="col" width="4%">RetSellable</th>
                    <th scope="col" width="3%">GST(C+S)</th>
                    <th scope="col" width="4%">Basic</th>
                    <th scope="col" width="4%">Net</th>

                  </tr>
                </thead>
                <tbody >
                  {data?.vineoAddBillItemList?.map((item, index) => {
                    return (

                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.vineoProduct.vineoProductName}</td>
                        <td>{item.vineoBatch.vineoBatchNumber}</td>
                        {console.log("Unit value is " + item.vineoAddBillItemUnit)}
                        <td>
                          <Form.Select className="form-control" defaultValue={item.vineoAddBillItemUnit} size="sm" {...register(`vineoAddBillItemList[${index}].vineoAddBillItemUnit`)} required>
                          {DEFAULT_UNIT_OPTIONS.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}


                          </Form.Select>

                        </td>
                        <td>{item.vineoItemEffectiverateAfterDsicount}</td>
                        <td>{item.vineoAddBillProductBatchQty}</td>
                        <td> <Form.Control size='sm' type="number" min="0" defaultValue={0} placeholder={"Add Extra"} className="form-control" {...register(`vineoAddBillItemList[${index}].vineoAddBillProductAddExtraBatchQty`)} required />
                        </td>
                        <td> <Form.Control size='sm' type="number" min="0" defaultValue={0} placeholder={"Damaged"} className="form-control" {...register(`vineoAddBillItemList[${index}].vineoAddBillProductDamagedReturnedBatchQty`)} required />
                        </td>
                        <td> <Form.Control size='sm' type="number" min="0" defaultValue={0} placeholder={"Sellable"} className="form-control" {...register(`vineoAddBillItemList[${index}].vineoAddBillProductSellableReturnedBatchQty`)} required />
                        </td>
                        <td>{item.vineoAddBillItemTotalGSTAmount}</td>
                        <td>{parseFloat(parseFloat(item.vineoAddBillItemNetAmount) - parseFloat(item.vineoAddBillItemTotalGSTAmount)).toFixed(2)}</td>
                        <td>{item.vineoAddBillItemNetAmount}</td>
                        {setValue(`vineoAddBillItemList[${index}].ultiVineoAddBillItemUniqeId`, item.ultiVineoAddBillItemUniqeId)}
                      </tr>

                    )
                  })
                  }
                </tbody>
              </table>
            </Card>
            <Row>
              <Col md={2}></Col>
              <Col md={3}>
                <FormText>
                  Packaging Charges ({watch(`vineoAddSalePackagingCharges`)}) + Bottom Amount ({data?.vineoAddSaleBillBottomAmt}) New Total= {parseInt(watch(`vineoAddSalePackagingCharges`) || `0`) + parseInt(data?.vineoAddSaleBillBottomAmt)}
                </FormText>
                <Form.Control size='sm' type="number" min="0" defaultValue={0} placeholder={"Packaging Charges"} className="form-control"
                  {...register(`vineoAddSalePackagingCharges`)} required />
              </Col>
            </Row>



            <center>
              <input className="btn btn-primary m-2" type="submit" value="Submit" />
              <input className="btn btn-primary m-2" type="button" value="Print" />
              <Button className="btn btn-danger m-2" type="button" onClick={() => { reset() }} value="Cancel">Cancel</Button>
            </center>
          </form>

          {console.log(data)}
          {console.log(data.receiptIsGenerated)}
        </div>
    }
    </>


  )
}

export default BillEdit