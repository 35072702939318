import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import '../../../../printFormat.css';
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import BillprintFormat2 from "./BillprintFormat2";

let totalItemcount = 0;
let listofBillNumbers="";
var converter = require('number-to-words');
class ComponentToPrint extends React.Component {

    render() {
        return (
            <>
                <div className="">
                <strong> Load Details</strong>
                    <div id='first-row'>
                        <div className='col'>

                            <strong> load No : {this.props.loaddata?.ultiVineoCreateLoadUniqeId}</strong>
                            <br />
                            Date: {this.props.loaddata?.vineoCreateLoadDate}
                            <br />
                            Van Name : {this.props.loaddata?.vineoCreateLoadVan?.vineoVanName}
                            <br />
                            Van Number : {this.props.loaddata?.vineoCreateLoadVan?.vineoVanRtoNumber}
                            <br />
                            Bill Number : {listofBillNumbers}
                            <br />
                        </div>
                  
                </div >
                <table id='main-table'>
                    <thead>
                        <tr>
                            <th rowSpan='2'>Sr No.</th>
                            <th rowSpan='2'>Product Name</th>
                            <th rowSpan='2'>Qty</th>
                            <th rowSpan='2'>Free</th>
                            <th rowSpan='2'>Total</th>
                            <th rowSpan='2'>Stage Count</th>

                        </tr>

                        <tr></tr>
                    </thead>
                    <tbody>

                        {Object.values(this.props?.summaryofload).map((items, index) => {
                            return (
                                <tr key={items.id}>
                                    <td className="p-2">{index + 1}</td>
                                    <td >{items?.productName} </td>
                                    <td className="p-2">  {items?.qty}</td>
                                    <td className="p-2"> {items?.freeQty}</td>
                                    <td className="p-2"> {parseInt(parseInt(items?.qty) + parseInt(items.freeQty))}</td>
                                    <td className="p-2">  {totalItemcount = totalItemcount + parseInt(parseInt(items?.qty) + parseInt(items.freeQty))}</td>

                                </tr>
                            )
                        })}

                    </tbody>
                </table>
                <div id='totals'>
                    <span>Total Item Qty: {converter.toWords(totalItemcount).toUpperCase()}</span>
                    <strong> Total Item Qty: {totalItemcount}</strong>

                </div>
                <Row className="mt-3">
                    <Row><center>END OF Load Details</center></Row>

                    {this.props.data?.map((billitem, index) => {
                        return (
                            <>
                            {listofBillNumbers=listofBillNumbers+billitem?.vineoaddUniqueBillNumberWithPrefix+"," }
                                <p style={{ pageBreakAfter: "always" }}></p>

                                <BillprintFormat2 data={billitem} />
                                <center>END OF INVOICE</center>

                            </>

                        )
                    })}

                </Row>
                </div>

            </>
        );
    }
}
class Loadprint extends React.Component {

    constructor() {
        super();
        this.state = {
            count: 0
        };

    }

    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => <Button>Print All</Button>}
                    content={() => this.componentRef}
                    pageStyle={`
                    @page {
                      margin: 0.5cm 0.5cm;
                    }
                  `}
                />
                <ComponentToPrint data={this.props.data.vineoAddBillSet} loaddata={this.props.data} summaryofload={this.props.summaryofload} ref={el => (this.componentRef = el)} />
            </div>
        );
    }
}

export default Loadprint;