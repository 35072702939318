import { useEffect, useState } from 'react';
import { Routes, Route, Link, Router } from 'react-router-dom';
import { toast } from "react-toastify";
import Home from './Home'
import Login from '../loginRegisterForms/Login';
import CheckJwt from '../vineoForms/CheckJwt';
import TemplateHookForm from '../vineoForms/TemplateHookForm';
import AddFirmInfo from '../vineoForms/master/AddFirmInfo';
import SubCategoryInfo from '../vineoForms/master/SubCategoryInfo';
import Register from '../loginRegisterForms/Register';
import CustomerBankInfo from '../vineoForms/master/CustomerBankInfo';
import ProductMasterInfo from '../vineoForms/master/ProductMasterInfo';
import SalesmanMasterInfo from '../vineoForms/master/SalesmanMasterInfo';
import CustomerAccountInfo from '../vineoForms/master/CustomerAccountInfo';
import TypeOneInfo from '../vineoForms/master/TypeOneInfo';
import TypeTwoInfo from '../vineoForms/master/TypeTwoInfo';
import CompanyMaster from '../vineoForms/master/CompanyMaster';
import BrandMaster from '../vineoForms/master/BrandMaster';
import GoDownMaster from '../vineoForms/master/GoDownMaster';
import VanMaster from '../vineoForms/master/VanMaster';
import CategoryMaster from '../vineoForms/master/CategoryMaster';
import ServiceMaster from '../vineoForms/master/ServiceMaster';
import SpecialGroupMaster from '../vineoForms/master/SpecialGroupMaster';
import ChildProductScheme from '../vineoForms/master/ChildProductScheme';
import CounterSales from '../vineoForms/entries/CounterSales';
import SubGroupMaster from '../vineoForms/master/SubGroupMaster';
import GroupMaster from '../vineoForms/master/GroupMaster';
import GstMaster from '../vineoForms/master/GstMaster';
import AreaMaster from '../vineoForms/master/AreaMaster';
import RouteMaster from '../vineoForms/master/RouteMaster';
import AccountGroupMaster from '../vineoForms/master/AccountGroupMaster';
import SubBrandMaster from '../vineoForms/master/SubBrandMaster';
import SubBrandMasterAdvance from '../vineoForms/master/SubBrandMasterAdvance';
import Billing from '../vineoForms/entries/Billing';
import AddGrn from '../vineoForms/entries/AddGrn';
import Purchases from '../vineoForms/entries/Purchases';
import AreaToSalesman from '../vineoForms/mapping/AreaToSalesman';
import AdvanceMappingSetting from '../vineoForms/mapping/AdvanceMappingSetting';
import AddStock from '../vineoForms/entries/stockadjustment/AddStock';
import PartyToArea from '../vineoForms/mapping/PartyToArea';
import SchemeMaster from '../vineoForms/master/SchemeMaster';
import VendorAccount from '../vineoForms/master/VendorAccount';
import AddBatch from '../vineoForms/entries/stockadjustment/AddBatch';
import VendorToProduct from '../vineoForms/mapping/VendorToProduct';
import AddCountersale from '../vineoForms/entries/AddCountersale';
import PurchaseService from '../vineoForms/entries/PurchaseService';
import AddSalesBilling from '../vineoForms/entries/billing/AddSalesBilling';
import CreateLoad from '../vineoForms/entries/billing/CreateLoad';
import AllproductwiseSale from '../vineoForms/salesReport/AllProductwiseSale';
import DeleteandSettleLoad from '../vineoForms/entries/billing/DeleteandSettleLoad';
import AddPurchase from '../vineoForms/entries/AddPurchase';
import SettleLoad from '../vineoForms/entries/billing/SettleLoad';
import Billprint from '../vineoForms/entries/billing/Billprint';
import CustomerAccountInfowithAutoimport from '../vineoForms/master/CustomerAccountInfowithAutoimport';
import AddSalesBillingAutoPurcahseImport from '../vineoForms/entries/billing/AddSalesBillingAutoPurcahseImport';
import AddCreditNote from '../vineoForms/entries/AddCreditNote';
import AllPartywiseSale from '../vineoForms/salesReport/AllPartywiseSale';
import Einvoice from '../vineoForms/entries/billing/Einvoice';
import AddDebitNote from '../vineoForms/entries/AddDebitNote';
import RegisterClient from '../loginRegisterForms/RegisterClient';
import RegisterAdmin from '../loginRegisterForms/RegisterAdmin';
import ImportPurchaseIntoSystem from '../vineoForms/entries/ImportPurchaseIntoSystem';
import ImportProductIntoSystem from '../loginRegisterForms/ImportProductIntoSystem';
import ImportVendorIntoSystem from '../loginRegisterForms/ImportVendorIntoSystem';
import PasswordChangeRegisterClient from '../loginRegisterForms/PasswordChangeRegisterClient';
import ImportProductViaJSON from '../loginRegisterForms/ImportProductViaJSON';
import ImportFirmIntoSystem from '../loginRegisterForms/ImportFirmIntoSystem';
import StockReport from '../vineoForms/salesReport/StockReport';
import AllPartyOutstandingReport from '../vineoForms/salesReport/AllPartyOutstandingReport';
import ImportPartyAreaIntoSystem from '../importTools/ImportPartyAreaIntoSystem';
import ImportBatchIntoSystem from '../importTools/ImportBatchIntoSystem';
import ImportBillIntoSystem from '../importTools/ImportBillIntoSystem';
import DelteCanceledBill from '../loginRegisterForms/DelteCanceledBill';
import LogingetSuperadminAuthority from '../loginRegisterForms/LogingetSuperadminAuthority';
import Gstr1report from '../vineoForms/salesReport/Gstr1report';
import TrasportEntry from '../vineoForms/master/TransportEntry';
import ImportProductIntoSystemForUsers from '../importTools/ImportProductIntoSystemForUsers';
import AddSalesGON from '../vineoForms/entries/billing/AddSalesGON';
import ApproveGON from '../vineoForms/entries/ApproveGON';
import ConvertTOEinvoiceJSONUniversal from '../importTools/ConvertTOEinvoiceJSONUniversal';
import SalesmanPartyAccount from '../vineoForms/master/SalesmanPartyAccount';
import ApproveParty from '../vineoForms/entries/ApproveParty';
import Calculator from '../vineoForms/master/Calculator';

import SchemeMasterApply from '../vineoForms/master/SchemeMasterApply';
import AuthUser from '../../constants/AuthUser';
import { clearreduxstore } from '../../redux/features/vineoData/vineoDataSlice';
import { useDispatch } from 'react-redux';
import Feedbackform from '../loginRegisterForms/Feedbackform';
import ShopVisitiReport from '../vineoForms/sfa/ShopVisitiReport';
import VisitSummary from '../vineoForms/sfa/VisitSummary';
import Attendance from '../vineoForms/sfa/Attendance';
import VisitReport from '../vineoForms/sfa/VisitReport';
import GonSummary from '../vineoForms/sfa/GonSummary';
import GonReport from '../vineoForms/sfa/GonReport';
import ItemWiseGonReport from '../vineoForms/sfa/ItemWiseGonReport';
import DatewiseVisitReport from '../vineoForms/sfa/DatewiseVisitReport';
import ImportUsers from '../importTools/ImportUsers';
import ImportClient from '../importTools/ImportClient';
import BillingReport from '../vineoForms/salesReport/BillingReport';
import PurchasesReport from '../vineoForms/salesReport/PurchasesReport';
import ItemWisePurchasesReport from '../vineoForms/salesReport/ItemWisePurchasesReport';
import MonthlyTarget from '../vineoForms/sfa/MonthlyTarget';
import FirmSettingandPartyTransfer from '../vineoForms/sfa/FirmSettingandPartyTransfer';
import AttendancefromUser from '../vineoForms/sfa/AttendancefromUser';





// import AuthUser from '../../constants/AuthUser';


export default function Dashboard() {
    // const { http } = AuthUser();
    const dispatch = useDispatch();
    const notify = (message) => toast.success(message);

    const [userdetail, setUserdetail] = useState({ username: "", emailid: "", lastloginTS: "" });
    const userString = sessionStorage.getItem('username');
    const email = sessionStorage.getItem('email')
    const lastloginTS = sessionStorage.getItem('lastLoginTS')
    const { token, logout } = AuthUser();
    const logoutUser = async () => {
        try {
            if (token != undefined) {
                logout();
            }
            await dispatch(clearreduxstore());

        } catch (error) {
            console.log("Error During clearing Data")
        }
    };

    useEffect(() => {


        setUserdetail({ username: userString, emailid: email, lastloginTS: lastloginTS })
        const user_detail = JSON.parse(userString);
        const lastloginTS_detail = JSON.parse(lastloginTS);
        notify("You Last Logged in on \n" + lastloginTS_detail.substring(0, 20))
        return user_detail;
    }, []);



    function renderElement() {
        if (userdetail) {
            return (
                <div>
                    {/* old data is placed Beloe in comment  */}
                    {/* <p>Your A Logged in as {userdetail.username}</p> */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                            <div className="input-group">
                                <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button">
                                        <i className="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <ul className="navbar-nav ml-auto">
                            {/* Nav Item - Search Dropdown (Visible Only XS) */}
                            <li className="nav-item dropdown no-arrow d-sm-none">
                                <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-search fa-fw"></i>
                                </a>
                                {/* Dropdown - Messages */}
                                <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                    aria-labelledby="searchDropdown">
                                    <form className="form-inline mr-auto w-100 navbar-search">
                                        <div className="input-group">
                                            <input type="text" className="form-control bg-light border-0 small"
                                                placeholder="Search for..." aria-label="Search"
                                                aria-describedby="basic-addon2" />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button">
                                                    <i className="fas fa-search fa-sm"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </li>

                            {/* Nav Item - Alerts */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-bell fa-fw"></i>
                                    {/* Counter - Alerts */}
                                    <span className="badge badge-danger badge-counter">3+</span>
                                </a>
                                {/* Dropdown - Alerts */}
                                <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="alertsDropdown">
                                    <h6 className="dropdown-header">
                                        Alerts Center
                                    </h6>
                                    {/* alert block from a to a  */}
                                    <a className="dropdown-item custom-dropdown-item d-flex align-items-center" href="#">
                                        <div className="mr-3">
                                            <div className="icon-circle bg-success">
                                                <i className="fas fa-donate text-white"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="small text-gray-500">August 7, 2023</div>
                                            Here You'll Receive Latest Alerts from Dorf Ketal
                                        </div>
                                    </a>
                                </div>
                            </li>

                            {/* Nav Item - Messages */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-envelope fa-fw"></i>
                                    {/* Counter - Messages */}
                                    <span className="badge badge-danger badge-counter">7</span>
                                </a>
                                {/* Dropdown - Messages */}
                                <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="alertsDropdown">
                                    <h6 className="dropdown-header">
                                        Messages Center
                                    </h6>
                                    <a className="dropdown-item custom-dropdown-item d-flex align-items-center" href="#">
                                        <div className="mr-3">
                                            <div className="icon-circle bg-primary">
                                                <i className="fas fa-file-alt text-white"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="small text-gray-500">Aug 22, 2023</div>
                                            <span className="font-weight-bold">  Here You'll Receive Latest Messages from Dorf Ketal</span>
                                        </div>
                                    </a>

                                    <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                                </div>
                            </li>

                            <div className="topbar-divider d-none d-sm-block"></div>

                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">Hi, {userString}</span>

                                </a>
                                {/* Dropdown - User Information */}

                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                <Link to="/dashboard/register" className="dropdown-item">
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Profile
                                    </Link>
                                    <a className="dropdown-item" href="#">
                                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Settings
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Activity Log
                                    </a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" data-toggle="modal"  onClick={logoutUser}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Logout
                                    </a>
                                </div>
                            </li>
                        </ul>

                    </nav>
                    <div className='container-fluid px-5 p-1'>
                        <Routes>

                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/login" element={<Login />} />
                            <Route exact path="/getsuperadminAuthoritylogin" element={<LogingetSuperadminAuthority />} />

                            <Route exact path="/clientregister" element={<RegisterClient />} />
                            <Route exact path="/registerclientadmin" element={<RegisterAdmin />} />
                            <Route exact path="/addfirminfo" element={<AddFirmInfo />} />
                            <Route exact path="/template" element={<Home />} />
                            <Route exact path="/checkjwt" element={<CheckJwt />} />
                            <Route exact path="/Calculator" element={<Calculator />} />
                            <Route exact path="/reacthookform" element={<TemplateHookForm />} />
                            <Route exact path="/subcategoryinfo" element={<SubCategoryInfo />} />
                            <Route exact path="/register" element={<Register />} />
                            <Route exact path="/ProductMasterInfo" element={<ProductMasterInfo />} />
                            <Route exact path="/SalesmanMasterInfo" element={<SalesmanMasterInfo />} />
                            <Route exact path="/CustomerBankInfo" element={<CustomerBankInfo />} />
                            <Route exact path="/VendorAccount" element={<VendorAccount />} />
                            <Route exact path="/CustomerAccountInfo" element={<CustomerAccountInfo />} />
                            <Route exact path="/SalesmanPartyAccountInfo" element={<SalesmanPartyAccount />} />
                            <Route exact path="/AutoImport" element={<CustomerAccountInfowithAutoimport />} />

                            <Route exact path="/TypeOneInfo" element={<TypeOneInfo />} />
                            <Route exact path="/TypeTwoInfo" element={<TypeTwoInfo />} />
                            <Route exact path="/CompanyMaster" element={<CompanyMaster />} />
                            <Route exact path="/BrandMaster" element={<BrandMaster />} />
                            <Route exact path="/GoDownMaster" element={<GoDownMaster />} />
                            <Route exact path="/VanMaster" element={<VanMaster />} />
                            <Route exact path="/CategoryMaster" element={<CategoryMaster />} />
                            <Route exact path="/ServiceMaster" element={<ServiceMaster />} />
                            <Route exact path="/SpecialGroupMaster" element={<SpecialGroupMaster />} />
                            <Route exact path="/ChildProductScheme" element={<ChildProductScheme />} />
                            <Route exact path="/Billing" element={<Billing />} />
                            <Route exact path="/addcreditNote" element={<AddCreditNote />} />
                            <Route exact path="/PrintBill" element={<Billprint />} />
                            <Route exact path="/AddSalesBilling" element={<AddSalesBilling />} />
                            <Route exact path="/AddSalesBillingAutoPurcahseImport" element={<AddSalesBillingAutoPurcahseImport />} />
                            <Route exact path="/CounterSales" element={<CounterSales />} />
                            <Route exact path="/SubGroupMaster" element={<SubGroupMaster />} />
                            <Route exact path="/GroupMaster" element={<GroupMaster />} />
                            <Route exact path="/GstMaster" element={<GstMaster />} />
                            <Route exact path="/Transport" element={<TrasportEntry />} />
                            <Route exact path="/AreaMaster" element={<AreaMaster />} />
                            <Route exact path="/RouteMaster" element={<RouteMaster />} />
                            <Route exact path="/AccountGroupMaster" element={<AccountGroupMaster />} />
                            <Route exact path="/SubBrandMaster" element={<SubBrandMaster />} />
                            <Route exact path="/SubBrandMasterAdvance" element={<SubBrandMasterAdvance />} />
                            <Route exact path="/Purchases" element={<Purchases />} />
                            <Route exact path="/addgrnote" element={<AddGrn />} />
                            <Route exact path="/addgonote" element={<AddSalesGON />} />
                            <Route exact path="/approvegonote" element={<ApproveGON />} />
                            <Route exact path="/approveparty" element={<ApproveParty />} />


                            <Route exact path="/Areatosalesman" element={<AreaToSalesman />} />
                            <Route exact path="/PartytoArea" element={< PartyToArea />} />
                            <Route exact path="/VendorToProduct" element={< VendorToProduct />} />
                            <Route exact path="/PurchaseService" element={<PurchaseService />} />

                            <Route exact path="/AdvanceMappingSetting" element={<AdvanceMappingSetting />} />
                            <Route exact path="/AddStock" element={<AddStock />} />
                            <Route exact path="/AddCountersale" element={<AddCountersale />} />
                            <Route exact path="/AddBatch" element={<AddBatch />} />
                            <Route exact path="/SchemeMaster" element={<SchemeMaster />} />
                            <Route exact path="/ApplyScheme" element={<SchemeMasterApply />} />
                            <Route exact path="/BillingReport" element={<BillingReport />} />
                            <Route exact path="/PurchaseReport" element={<PurchasesReport />} />
                            <Route exact path="/ItemWisePurchase" element={<ItemWisePurchasesReport />} />
                            <Route exact path="/AllproductwiseSale" element={<AllproductwiseSale />} />
                            <Route exact path="/stockReport" element={<StockReport />} />
                            <Route exact path="/AllPartyOutstandingReport" element={<AllPartyOutstandingReport />} />
                            <Route exact path="/gstr1report" element={<Gstr1report />} />
                            <Route exact path="/shopvisitreport" element={<ShopVisitiReport />} />
                            <Route exact path="/monthlyTarget" element={<MonthlyTarget />} />
                            <Route exact path="/firmPartyTransfer" element={<FirmSettingandPartyTransfer />} />
                            <Route exact path="/visitsummary" element={<VisitSummary />} />
                            <Route exact path="/attendance" element={<Attendance />} />
                            <Route exact path="/userAttendance" element={<AttendancefromUser />} />
                            <Route exact path="/visitReport" element={<VisitReport />} />
                            <Route exact path="/datewisevisitReport" element={<DatewiseVisitReport />} />
                            <Route exact path="/gonsummary" element={<GonSummary />} />
                            <Route exact path="/gonreport" element={<GonReport />} />
                            <Route exact path="/itemgonreport" element={<ItemWiseGonReport />} />

                            <Route exact path="/CreateLoad" element={<CreateLoad />} />
                            <Route exact path="/DeleteandSettleLoad" element={<DeleteandSettleLoad />} />
                            <Route exact path="/AddPurchase" element={<AddPurchase />} />
                            <Route exact path="/SettleLoad" element={<SettleLoad />} />
                            <Route exact path="/AddCreditNote" element={<AddCreditNote />} />
                            <Route exact path="/AddDebitNote" element={<AddDebitNote />} />
                            <Route exact path="/Einvoice" element={<Einvoice />} />
                            <Route exact path="/AllPartywiseSale" element={<AllPartywiseSale />} />
                            <Route exact path="/ImportPurchaseIntoSystem" element={<ImportPurchaseIntoSystem />} />
                            <Route exact path="/ImportBatchIntoSystem" element={<ImportBatchIntoSystem />} />
                            <Route exact path="/ImportUsersIntoSystem" element={<ImportUsers />} />
                            <Route exact path="/ImportClientIntoSystem" element={<ImportClient />} />
                            <Route exact path="/converteinvoicejsonuniversal" element={<ConvertTOEinvoiceJSONUniversal />} />

                            <Route exact path="/importproduct" element={<ImportProductIntoSystem />} />
                            <Route exact path="/importproductForUser" element={<ImportProductIntoSystemForUsers />} />
                            <Route exact path="/importproductviajson" element={<ImportProductViaJSON />} />
                            <Route exact path="/importVendor" element={<ImportVendorIntoSystem />} />
                            <Route exact path="/importFirm" element={<ImportFirmIntoSystem />} />
                            <Route exact path="/importParty" element={<ImportPartyAreaIntoSystem />} />
                            <Route exact path="/importBills" element={<ImportBillIntoSystem />} />
                            <Route exact path="/deleteBills" element={<DelteCanceledBill />} />


                            <Route exact path="/changepasswordofadminclient" element={<PasswordChangeRegisterClient />} />
                            <Route exact path="/feedbackform" element={<Feedbackform />} />

                        </Routes>

                    </div>
                    <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Powered By Vishnu Provision Stores </span>
                    </div>
                </div>
            </footer>


                </div>
            )
        } else {
            return <p>Loading.....</p>
        }

    }

    return (
        <div>
            {renderElement()}
        </div>
    )
}

