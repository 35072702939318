import React from 'react'
import { useEffect, useState } from "react"
import { Router, Routes, Route, useNavigate } from 'react-router-dom';
// import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useForm } from "react-hook-form";
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from 'react-redux'
//import { decrement, increment } from '../../../redux/features/vineoData/counterSlice'

// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import {  getVineoCompanyData, getVineoCompanyCategoryData, getVineoSubBrandData, getVineoProductDataForEditingProduct } from '../../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
    FormGroup, Label, Input,
    Button, Form, Row, Col, ListGroup, ListGroupItem, Card, ModalHeader, ModalBody
} from 'react-bootstrap';
import { Modal } from 'bootstrap';
import { BASEURL_JWTAUTH, DEFAULT_UNIT_OPTIONS, VINEOFORM_DELETE_SELECTED_PRODUCT_NAMEDDATA, VINEOFORM_UPDATE_SELECTED_PRODUCT_NAMEDATA } from '../../../constants/Urlforvineo';
import { AgGridReact } from 'ag-grid-react';

export default function ProductMasterInfo() {
    useEffect(() => {
        dispatch(getVineoSubBrandData())
        // dispatch(getVineoCompanyData());
        // dispatch(getVineoCompanyCategoryData())
    }, []);
    const [selected, setSelected] = useState([]);

    const isvineocompanyLoaded = useSelector((state) => state.vineoData.isvineocompanyLoaded)
    const vineooldCompnaydata = useSelector((state) => state.vineoData.vineocompany)
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    const [tableData, setTableData] = useState([]);
    const vineooldCategorydata = useSelector((state) => state.vineoData.vineocategory)
    const vineooldSubcategorydata = useSelector((state) => state.vineoData.vineosubcategory)
    const dataLoaded = useSelector((state) => state.vineoData.vineosubbranddata)
    const dispatch = useDispatch()
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoProductUniqeId": "Working", "vineoProductName": "Click Refresh To Load" }])
    const isvineoproductdataLoaded = useSelector((state) => state.vineoData.isvineoproductdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoproductdata)
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [shomoreinfo, setShomoreinfo] = useState(false);
    const { register, handleSubmit, setValue, handleModal, reset, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }
    const navigate = useNavigate();
    // const { http, setToken } = AuthUser();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }



    useEffect(() => {
        console.log("Refreshing on state Change " + refreshcount)
        setDataofvineoTableData(vineodata)
        setTableData(vineodata)
        setIsLoading(false)
    }, [refreshcount]);

 

    const [gridApi, setGridApi] = useState(null)


    const onExportClick = () => {

        gridApi.api.exportDataAsCsv();
    }

    const rowStyle = { background: 'white' };

    const getRowStyle = (params) => {
        // console.log("vlaue of ",parseInt(params.data.vineoCustomerAccountcreditDay))
        // console.log(params.data.vineoAddSaleBillDate)

        // console.log("Date Diffrence is ", diffInDays)
        if (false)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'orange' };
        }
        if (false)
        // if (parseInt(diffInDays) > 10)
        {
            return { background: 'gray' };
        }

    };

    const onGridReady = (params) => {
        // gridApiexport = params.api;
        setGridApi(params);


    }


    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        editable: true,
        rowSelection: 'single',
        // onSelectionChanged: onSelectionChanged
        //   onCellValueChanged: onCellValueChanged

    }
    function onSelectionChanged() {
        var selectedRows = gridApi.api.getSelectedRows();
        var selectedRowsString = '';
        var maxToShow = 5;
        console.log("Selected Rows" + selectedRows)

    }


    const onPaginationChange = (pageSize) => {
        gridApi.api.paginationSetPageSize(Number(pageSize))
    }



    function onCellValueChanged(event) {
        console.log(
            'onCellValueChanged: '
        );
    }
    const columnDefs = [

        { headerName: "Product Name.", field: "vineoProductName",resize: true },
        // { headerName: "Code", field: 'vineoCustomerAccountName' },
        { headerName: "Unit ", field: "vineoProductdefaultUnit" },
        { headerName: "HSN Code", field: "vineoProducthsnCod" },
        { headerName: "cGST%", field: 'vineoProductCentralGstPercentage' },
        { headerName: "sGST", field: 'vineoProductStateGstPercentage' },
        // { headerName: "Pin", field: 'vineoCustomerAccountpincode' },
        // { headerName: "Mob", field: 'vineoCustomerAccountmob' },
        {
            headerName: "Actions", field: "id", cellRendererFramework: (params) => <div>
                {/* <Button variant="warning m-1" onClick={() => handlePrint(params.data)}><FontAwesomeIcon icon={faPrint} /></Button> */}
                <Button variant="warning m-1" onClick={() =>  {setSelectedRow(params.data);updateDatainForm()}}><FontAwesomeIcon icon={faUserEdit} /></Button>
                <Button variant="danger m-1" onClick={() => handleDelete(params.data)}> <FontAwesomeIcon icon={faTrash} /></Button>
            </div>
        }
    ]

    //

    const handleUpdate = (data) => {
        console.log(data)

        // console.log("This will editqty by this amount "+updatedProductBatchQty);
        // submitForm(data)

    }
    const handleDelete = () => {
        console.log("handle Update called ")
    }

    //ag grid end 

    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoProductName',
            text: 'Product Name',
            sort: true,
            formatter: rowClickedfunction,
        },
        {
            dataField: 'vineoProductCode',
            text: 'Product Code'
        }

    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '20', value: 20
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };
    const getdatafromserver = () => {
        setIsLoading(true);

        console.log("Called to get Product Data ")

        dispatch(getVineoProductDataForEditingProduct())
       
        console.log("calling to get data from server ")


    }
    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("vineoProductCode", selectedRow.vineoProductCode);
            setValue("ultiVineoProductUniqeId", selectedRow.ultiVineoProductUniqeId);
            setValue("vineoProductName", selectedRow.vineoProductName);
            setValue("vineoProductSubBrand.ultiVineoSubBrandUniqeId", selectedRow?.vineoProductSubBrand?.ultiVineoSubBrandUniqeId);
            setValue("subBrandUniqeId", selectedRow.subBrandUniqeId);
            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);
            setValue("vineoLocalProductName", selectedRow.vineoLocalProductName);
            setValue("companyproduct", selectedRow.companyproduct);
            setValue("vineoProductGst", selectedRow.vineoProductGst);
            setValue("vineoProductMarginType", selectedRow.vineoProductMarginType);
            setValue("vineoProductmarginValue", selectedRow.vineoProductmarginValue);
            setValue("vineoProductbasicUnit", selectedRow.vineoProductbasicUnit);
            setValue("vineoProductinBoxPacking", selectedRow.vineoProductinBoxPacking);
            setValue("vineoProductinBoxName", selectedRow.vineoProductinBoxName);
            setValue("vineoProductdefaultUnit", selectedRow.vineoProductdefaultUnit);
            setValue("vineoProductboxPacking", selectedRow.vineoProductboxPacking);
            setValue("vineoProductboxName", selectedRow.vineoProductboxName);
            setValue("retailerMargin", selectedRow.retailerMargin);
            setValue("superStockistMargin", selectedRow.superStockistMargin);
            setValue("distMargin", selectedRow.distMargin);

            setValue("vineoProductratePerUnit", selectedRow.vineoProductratePerUnit);
            setValue("vineoProductweightGmLtr", selectedRow.vineoProductweightGmLtr);
            setValue("vineoProductproductType", selectedRow.vineoProductproductType);
            setValue("vineoProductminStockHolding", selectedRow.vineoProductminStockHolding);
            setValue("vineoProductactive", selectedRow.vineoProductactive);
            setValue("vineoProductlocked", selectedRow.vineoProductlocked);
            setValue("vineoProductDrug", selectedRow.vineoProductDrug);
            setValue("vineoProductshortCodePer", selectedRow.vineoProductshortCodePer);
            setValue("vineoProductlbtPer", selectedRow.vineoProductlbtPer);
            setValue("vineoProductdamage", selectedRow.vineoProductdamage);
            setValue("vineoProductcomodityCode", selectedRow.vineoProductcomodityCode);
            setValue("vineoProductcomodityCategory", selectedRow.vineoProductcomodityCategory);
            setValue("vineoProductreorderLevelDay", selectedRow.vineoProductreorderLevelDay);
            setValue("vineoProducthsnCod", selectedRow.vineoProducthsnCod);
            setValue("vineoProductshortName", selectedRow.vineoProductshortName); <Form.Control className="form-control "   {...register("vineoProducteanNo")} required />
            setValue("vineoProducteanNo", selectedRow.vineoProducteanNo);
            setValue("vineoProductlbtCause", selectedRow.vineoProductlbtCause);
            setValue("vineoProductprimaryFreeQty", selectedRow.vineoProductprimaryFreeQty);
            setValue("date", selectedRow.date);
            setValue("primaryDiscOne", selectedRow.primaryDiscOne);
            setValue("primaryDiscTwo", selectedRow.primaryDiscTwo);
            setValue("vineoProductsRate", selectedRow.vineoProductsRate);
            setValue("vineoProductpRate", selectedRow.vineoProductpRate);
            setValue("vineoProductprimaryDiscAmt", selectedRow.vineoProductprimaryDiscAmt);
            setValue("sRateCalculation", selectedRow.sRateCalculation);
            setValue("vineoProductfieldOne", selectedRow.vineoProductfieldOne);
            setValue("vineoProductfieldTwo", selectedRow.vineoProductfieldTwo);
            setValue("vineoProductaddOneDisc", selectedRow.vineoProductaddOneDisc);
            setValue("vineoProductspecGroup", selectedRow.vineoProductspecGroup);
            setValue("vineoProductallowQtyInFraction", selectedRow.vineoProductallowQtyInFraction);
            setValue("vineoProductcessPerPcs", selectedRow.vineoProductcessPerPcs);
            setValue("vineoProductcessPer", selectedRow.vineoProductcessPer);
            setValue("vineoProductcRate", selectedRow.vineoProductcRate);
            setValue("vineoProductapmcPer", selectedRow.vineoProductapmcPer);
            setValue("vineoProducthmaPerPcs", selectedRow.vineoProducthmaPerPcs);
            setValue("vineoProductcrateSize", selectedRow.vineoProductcrateSize);
            setValue("vineoProductshelfLife", selectedRow.vineoProductshelfLife);
            setValue("vineoProductminQtyInSale", selectedRow.vineoProductminQtyInSale);
            setValue("vineoProductStateGstPercentage", selectedRow.vineoProductStateGstPercentage);
            setValue("vineoProductCentralGstPercentage", selectedRow.vineoProductCentralGstPercentage);
            setValue("vineoProductbasicUnit", selectedRow.vineoProductbasicUnit);
        }
        console.log("Update Product ")
    }

    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Area ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }
    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_PRODUCT_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Product  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoProductName)
                }
                else
                    notify("Error While Deleting FIRM  Or Login as ADMIN")
            },
            (err) => {
                notify("Error While Deleting FIRM Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitForm = (data) => {
        console.log((data))
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_PRODUCT_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Product  Created With Internate Id')
                    reset();

                }
                else
                    notify('Error While Registering Product Info From Server')
                //  console.log(res.data)
            },
            (err) => {
                notify('Error While Registering Product Info')
            }
        )
    }

    const handleClickOpen = () => {
        setShomoreinfo(!shomoreinfo)
    };

    const handleClose = () => {
        setShomoreinfo(true)
    };

    return (
        <div>

            <h3 className='justify-content-center m-1'>Add Product Master Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoProductUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary" disabled={isLoading}
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />{isLoading? <>Loading</>: <></>}
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className='p-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    Product Code
                                                </Form.Text>
                                                <Form.Control size="sm" className="form-control "   {...register("vineoProductCode", {
                                                    required: true,
                                                    pattern: /^[^\s]+$/,
                                                })} required />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup >

                                                <Form.Text className="text-muted">
                                                    Product Name
                                                </Form.Text>
                                                <Form.Control size="sm" className="form-control "   {...register("vineoProductName")} required />

                                            </FormGroup>
                                        </Col>

                                        <FormGroup >
                                            <Form.Text >
                                                SUBBrand || Brand || GRP || SUB GRP || SUB CAT||CAT Name||Company Name'
                                            </Form.Text>
                                        </FormGroup>
                                        <Typeahead
                                            size="sm"
                                            id="basic-example"
                                            onChange={setSelected}
                                            onClick={console.log(selected)}
                                            options={dataLoaded}
                                            placeholder="Choose SubGroup  Name..."
                                            selected={selected}
                                            labelKey={"vineoSubBrandName"}
                                            renderMenuItemChildren={(option) => (
                                                <div>
                                                    <div>
                                                        <small>SubBrand:  {option.vineoSubBrandName}</small>
                                                        <small>Brand:  {option.vineoBrand.vineoBrandName}</small>
                                                        <small> SubGroup: {option.vineoBrand.vineoSubGroup.vineoSubGroupName}</small>
                                                        <small> Group: {option.vineoBrand.vineoSubGroup.vineoGroup.vineoGroupName}</small>
                                                        <small> SubCat: {option.vineoBrand.vineoSubGroup.vineoGroup.vineoSubCategory.companySubCategoryName}</small>
                                                        <small> Cat: {option.vineoBrand.vineoSubGroup.vineoGroup.vineoSubCategory.vineoCategory.companyCategoryName}</small>
                                                        <small> Company: {option.vineoBrand.vineoSubGroup.vineoGroup.vineoSubCategory.vineoCategory.vineoCompany.companyName}</small>
                                                    </div>
                                                </div>
                                            )}

                                        />
                                        {console.log("Selected" + selected)}
                                        {setValue("vineoProductSubBrand.ultiVineoSubBrandUniqeId", selected?.[0]?.ultiVineoSubBrandUniqeId)}
                                        {selected?.[0]?.ultiVineoSubBrandUniqeId ? setValue("subBrandUniqeId", selected?.[0]?.ultiVineoSubBrandUniqeId) : <></>}


                                        <Col md={2}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    SGST %
                                                </Form.Text>
                                                {/* 5 12 18 28  */}
                                                <Form.Select size="sm" className="form-control" {...register("vineoProductStateGstPercentage")} required>
                                                    <option value="2.5">2.5</option>
                                                    <option value="6">6</option>
                                                    <option value="9">9</option>
                                                    <option value="14">14</option>
                                                </Form.Select>

                                                {/* <Form.Control className="form-control " size="sm"  {...register("vineoProductStateGstPercentage")} required /> */}

                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup >
                                                <Form.Text className="text-muted">
                                                    CGST %
                                                </Form.Text>

                                                <Form.Select size="sm" className="form-control" {...register("vineoProductCentralGstPercentage")} required>
                                                    <option value="2.5">2.5</option>
                                                    <option value="6">6</option>
                                                    <option value="9">9</option>
                                                    <option value="14">14</option>
                                                </Form.Select>
                                                {/* <Form.Control className="form-control " size="sm"  {...register("vineoProductCentralGstPercentage")} required /> */}

                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Margin Type
                                                </Form.Text>
                                                <Form.Select size="sm" className="form-control"  {...register("vineoProductMarginType")}>
                                                    <option value="Margintype1">Diff. of Sales & Pur.Rates</option>
                                                    <option value="Margintype2">Diff. of Sales & Pur.Rates</option>
                                                    <option value="Margintype1">U3</option>
                                                </Form.Select>

                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>

                                                <Form.Text className="text-muted">
                                                    Margin value
                                                </Form.Text>
                                                <Form.Control type="text" size="sm" className="form-controll" defaultValue="0.00" {...register("vineoProductmarginValue")} required />

                                            </FormGroup>
                                        </Col>

                                        <FormGroup >
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Text className="text-muted">
                                                        Default Unit
                                                    </Form.Text>
                                                    <Form.Select className="form-control" size="sm" {...register("vineoProductdefaultUnit")}>
                                                        {DEFAULT_UNIT_OPTIONS.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {/* <Form.Control type="text" className="form-controll" size="sm"  {...register("vineoProductdefaultUni")} required /> */}


                                                </Col>
                                                <Col md={2}>
                                                    <Form.Text className="text-muted">
                                                        Basic Unit
                                                    </Form.Text>
                                                    <Form.Control className="form-control" size="sm" {...register("vineoProductbasicUnit")}>


                                                    </Form.Control>
                                                    {/* <Form.Control type="text" className="form-controll" size="sm"  {...register("vineoProductdefaultUni")} required /> */}


                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Pcs InBox Packing
                                                    </Form.Text>
                                                    <Form.Control type="text" size="sm" className="form-controll" defaultValue="1" {...register("vineoProductinBoxPacking")} required />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        InBox Name
                                                    </Form.Text>
                                                    <Form.Control type="text" size="sm" className="form-controll" defaultValue="InBox" {...register("vineoProductinBoxName")} required />
                                                </Col>
                                            </Row>
                                        </FormGroup>


                                        <FormGroup >
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Rate Per Unit (BOX)
                                                    </Form.Text >
                                                    <Form.Control type="text" size="sm" className="form-controll" defaultValue="1" {...register("vineoProductratePerUnit")} required />
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Rate Per Unit (PCS)
                                                    </Form.Text >
                                                    <Form.Control type="text" size="sm" className="form-controll" defaultValue="1" {...register("vineoProductratePerUnitInPCS")} required />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        HSN Code *
                                                    </Form.Text >
                                                    <Form.Control type="text" size="sm" className="form-controll"  {...register("vineoProducthsnCod")} required />
                                                </Col>

                                                <Col md={4}>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row>
                                                <Col md={3}>
                                                    <Form.Text className="text-muted">
                                                        Retailer Margin %
                                                    </Form.Text>
                                                    <Form.Control type="Number" step=".01" size="sm" className="form-controll" defaultValue="0" {...register("retailerMargin")} required />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Text className="text-muted">
                                                        Dist Margin %
                                                    </Form.Text>
                                                    <Form.Control type="Number" step=".01" size="sm" className="form-controll" defaultValue="0" {...register("distMargin")} required />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Text className="text-muted">
                                                        SUPERSTOCKIST Margin %
                                                    </Form.Text>
                                                    <Form.Control type="Number" step=".01" size="sm" className="form-controll" defaultValue="0" {...register("superStockistMargin")} required />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Text className="text-muted">
                                                        Rate Per Unit *
                                                    </Form.Text >
                                                    <Form.Control type="text" size="sm" className="form-controll" defaultValue="1" {...register("vineoProductratePerUnit")} />
                                                </Col>

                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        weight[Gms/Ltr]
                                                    </Form.Text>
                                                    <Form.Control type="text" size="sm" className="form-controll" defaultValue="1" {...register("vineoProductweightGmLtr")} />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Product Type
                                                    </Form.Text>

                                                    <Form.Select size="sm" className="form-control"  {...register("vineoProductproductType")}>
                                                        <option value="vineoProductproductType1">FMCG</option>
                                                        <option value="vineoProductproductType2">FMCG</option>
                                                        <option value="vineoProductproductType3">FMCG</option>
                                                    </Form.Select>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Min Stock Holding
                                                    </Form.Text>
                                                    <Form.Control size="sm" type="text" className="form-controll" defaultValue="0" {...register("vineoProductminStockHolding")} required />

                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup >
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Active[Y/N]
                                                    </Form.Text>
                                                    <Form.Select className="form-control" size="sm" {...register("vineoProductactive", {})}>
                                                        <option value="true">true</option>
                                                        <option value="false">false</option>
                                                    </Form.Select>

                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Locked[Y/N]
                                                    </Form.Text>
                                                    <Form.Select className="form-control" size="sm" {...register("vineoProductlocked", {})}>
                                                        <option value="true">true</option>
                                                        <option value="false">false</option>
                                                    </Form.Select>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Drug[Y/N]
                                                    </Form.Text>
                                                    <Form.Select className="form-control" size="sm" {...register("vineoProductDrug")}>
                                                        <option value="Drug">YES</option>
                                                        <option value="Drug">NO</option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Short Code %
                                                    </Form.Text>
                                                    <Form.Control type="text" className="form-controll" size="sm"  {...register("vineoProductshortCodePer")} />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        LBT %
                                                    </Form.Text>
                                                    <Form.Control size="sm" type="text" className="form-controll" defaultValue="0.00" {...register("vineoProductlbtPer")} />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Text className="text-muted">
                                                        Damage %
                                                    </Form.Text >
                                                    <Form.Control size="sm" type="text" className="form-controll" defaultValue="0.00" {...register("vineoProductdamage")} />
                                                </Col>

                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row>
                                                <Col md={3}>
                                                    <Form.Text className="text-muted">
                                                        Comodity Code
                                                    </Form.Text>
                                                    <Form.Control size="sm" type="text" className="form-controll"  {...register("vineoProductcomodityCode")} />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Text className="text-muted">
                                                        Comodity Category
                                                    </Form.Text>
                                                    <Form.Control size="sm" type="text" className="form-controll"  {...register("vineoProductcomodityCategory")} />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Text className="text-muted">
                                                        Reorder Level Day
                                                    </Form.Text >
                                                    <Form.Control size="sm" type="text" className="form-controll" defaultValue="0.00" {...register("vineoProductreorderLevelDay")} />
                                                </Col>

                                            </Row>
                                        </FormGroup>


                                        {
                                            (
                                                <div>
                                                    {shomoreinfo ? (
                                                        <>
                                                            <Row>
                                                                <FormGroup>
                                                                    <Col md={12}>

                                                                        <Form.Text className="text-muted">
                                                                            Short Name
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm" {...register("vineoProductshortName")} required />
                                                                    </Col>
                                                                </FormGroup>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            EAN No
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProducteanNo")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            LBT Cause
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm" {...register("vineoProductlbtCause")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Primary Free Qty
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm" {...register("vineoProductprimaryFreeQty")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Form.Text className="text-muted">
                                                                            Date
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control" size="sm"  {...register("date")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Primary Disc 1 (%)
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm" {...register("vineoProductprimaryDiscOne")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Primary Disc 2 (%)
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProductprimaryDiscTwo")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            SRate
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm" {...register("vineoProductsRate")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            PRate
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProductpRate")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Primary Disc Amt
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProductprimaryDiscAmt")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            SRate Calculation On
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProductsRateCalculOn")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Field 1
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProductfieldOne")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Field 2
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control "   {...register("vineoProductfieldTwo")} required />
                                                                    </FormGroup>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Add 1 Disc
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control "   {...register("vineoProductaddOneDisc")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Spe. Group
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProductspecGroup")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Allow Qty in Fraction
                                                                        </Form.Text>
                                                                        <Form.Select className="form-control " size="sm"  {...register("vineoProductallowQtyInFraction")} required >
                                                                            <option value="allowType">YES</option>
                                                                            <option value="allowType">No</option>
                                                                        </Form.Select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Cess Per PCS
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProductcessPerPcs")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Cess %
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm" {...register("vineoProductcessPer")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Crate
                                                                        </Form.Text>
                                                                        <Form.Select className="form-control " size="sm"  {...register("vineoProductcRate")} required >
                                                                            <option value="crateType">N</option>
                                                                        </Form.Select>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            APMC %
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"   {...register("vineoProductapmcPer")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            HMA Per PCS
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProducthmaPerPcs")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Crate Size
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm"  {...register("vineoProductcrateSize")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Shelf Life
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm" {...register("vineoProductshelfLife")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <FormGroup >
                                                                        <Form.Text className="text-muted">
                                                                            Min Qty in Sale
                                                                        </Form.Text>
                                                                        <Form.Control className="form-control " size="sm" {...register("vineoProductminQtyInSale")} required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ) : (
                                                        <>Click MoreInfo for More Attribute</>
                                                    )}
                                                </div>
                                            )
                                        }



                                    </Row>
                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add Product Info " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update Product Info" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>
                                    <Button className="btn btn-primary m-2" onClick={handleClickOpen}>
                                        More Info
                                    </Button>
                                </form>
                            </Card>

                        </div>

                    </Col>
                </Row>
                <Col md = {2}>  <Button className="btn btn-primary m-1" onClick={onExportClick} value="ExportToCsv">Export To CSV
                </Button></Col>
                <Col md = {2}>  Total Number of Product : {tableData ? tableData.length : 0}{console.log("Type of table data",tableData ? tableData.length : 0)}</Col>
              
                <div className="ag-theme-alpine" style={{ height: '400px' }}>
                <div className="ag-theme-alpine" style={{ height: '700px' }}>
                    <AgGridReact
                        id="AgGridforPartyData"
                        rowData={tableData ? tableData : []}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        // onRowSelected={onSelectBill}
                        rowSelection="multiple"
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                    />

                </div>
            </div>
            </div>
            <div>

                {/* <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"More Info"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSubmit(onSubmit)} autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </div>


        </div >


    )

}