import React from 'react';
import { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { useForm ,Controller} from 'react-hook-form';
import { faClose, faRotate, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
//import { useGetRegisteredUsersByPostMutation} from "../../redux/features/services/postApi";
import { decrement, increment } from '../../../redux/features/vineoData/vineoDataSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getVineoProductData, getVineoSchemaMasterData } from '../../../redux/features/services/serviceHelper';
import { FormGroup, Lable, Input, Tab, Table, Col, Row, Button, Form, ListGroup, ListGroupItem, Container, FloatingLable, Card, FormControl } from 'react-bootstrap';
import { BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_SCHEME_NAMEDDATA, VINEOFORM_UPDATE_SELECTED_SCHEME_NAMEDATA, DATEFORMAT } from '../../../constants/Urlforvineo';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import http from '../../../constants/AxiosConfig';
import moment from "moment";
import { getVineoBatchData } from '../../../redux/features/services/entriesHelperService';
//import BootstrapTable from 'react-bootstrap-table-next/lib/src/bootstrap-table';

export default function SchemeMaster() {

    useEffect(() => {
        dispatch(getVineoBatchData())
    }, []);

    const dispatch = useDispatch()
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoSchemeUniqeId": "Working", "vineoCompanySchemeNumber": "Click Refresh To Load" }])
    const isvineoschemamasterdataLoaded = useSelector((state) => state.vineoData.isvineoschemamasterdataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoschemamasterdata)
    const vineoproductbatchdata = useSelector((state) => state.vineoData.vineobatchdata)
    const { register, watch, setValue, reset, handleSubmit, formState: { errors },control } = useForm()
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [toDate, setTodate] = useState();
    const [fromDate, setFromdate] = useState();
    const [selectedProductBatch, setSelectedProductBatch] = useState([])


    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }

    const notify = (message) => toast.success(message);
    const notifyError= (message) => toast.error(message)
    const notifyWarning= (message) => toast.warning(message)
    // const {http,setToken}=AuthUser();
    const updateDatainForm = () => {
        {
            console.log(selectedRow);
            setIsEditDataSelected(true);
            setValue("ultiVineoSchemeUniqeId", selectedRow.ultiVineoSchemeUniqeId);
            setValue("vineoSchemeType", selectedRow.vineoSchemeType);
            setValue("vineoSchemeName", selectedRow.vineoSchemeName);
            setValue("schemeLevel", selectedRow.schemeLevel);
            setValue("selectionAll", selectedRow.selectionAll);
            setValue("slabOn", selectedRow.gender);
            setValue("active", selectedRow.active);
            setValue("vineoSchemelockOnFree", selectedRow.vineoSchemelockOnFree);
            setValue("schemeApplicableonAccountType", selectedRow.schemeApplicableonAccountType);
        }
        console.log("Update Scheme ")
    }


    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }

    // const data = vineoUsers;
    const { SearchBar } = Search;
    const columns = [

        // {
        //      dataField: 'ultiVineoSchemeUniqeId',
        //     text: 'Scheme No',
        //     sort: true

        // },
        {
            dataField: 'vineoSchemeCode',
            text: 'Code',
            sort: true,
            formatter: rowClickedfunction,
        }, {
            dataField: 'vineoSchemeName',
            text: 'Scheme name',
            sort: true

        },
        {
            dataField: 'vineoSchemeValidTillDate',
            text: 'ValidTill',
            sort: true

        },
        {
            dataField: 'vineoSchemeFreeQtyLimitExceed',
            text: 'QTYLimit',
            sort: true

        },
        {
            dataField: 'vineoSchemeFreeAmountLimitExceed',
            text: 'AmntLimit',
            sort: true

        },
        {
            dataField: 'vineoSchemeDiscountPercentage',
            text: 'Discount%',
            sort: true

        },
        {
            dataField: 'vineoSchemeDiscountPercentageBudget',
            text: 'Budget',
            sort: true

        }
    ];

    // const rowEvents = {
    //     onClick: (e, row, rowIndex) => {
    //       console.log(`clicked on row with index: ${row}`);
    //       return rowIndex;

    //     }
    //   };

    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };


    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)


        }

    };

    const getdatafromserver = () => {

        console.log("Called to get Scheme Data ")

        dispatch(getVineoSchemaMasterData())

        if (Object.keys(vineodata).length > 0) {
            isvineoschemamasterdataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoSchemeUniqeId": "Working", "vineoSchemeName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }


    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Scheme ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }

    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_SCHEME_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Scheme  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoCompanySchemeNumber)
                }
                else
               { notifyError("Error While Deleting Scheme  Or Login as ADMIN")}
            },
            (err) => {
                notifyError("Error While Deleting Scheme Try Again  Or Login as ADMIN")

            }
        )
    }



    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_SCHEME_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:-Scheme  Created With Internate Id')
                    reset()
                }
                else
                {notifyError('Error While Registering Scheme Info From Server')
                //console.log(res.data)
            }},
            (err) => {
                notifyError('Error While Registering Scheme Info')
            }
        )
    }


    return (
        <div>

            <h3 className='justify-content-center m-1'>Scheme Master  </h3>
            <div className="row justify-content-left ">
                <Row >
                    <Col md={4}>
                        <ToolkitProvider keyField='ultiVineoSchemeUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search
                        >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={8}>
                        <div >
                            <Card className="p-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup >
                                        <FormGroup >
                                            <Form.Text className="text-muted">
                                                Scheme Code
                                            </Form.Text>
                                            <Form.Control  className="form-control "   {...register("vineoSchemeCode")} required />
                                        </FormGroup>

                                        <Row>
                                            <Col md={6}>
                                                <Form.Text className="text-muted">
                                                    Scheme Number
                                                </Form.Text>
                                                <Form.Control
                                                    {...register('vineoSchemeNumber')}

                                                /></Col>
                                            <Col md={6}>
                                                <Form.Text className="text-muted">
                                                    Scheme Type
                                                </Form.Text>
                                                <Form.Select className="form-control"  {...register("vineoSchemeType")}>
                                                    <option value="AMT"> On Amount </option>
                                                    <option value="QTY">On Quantity </option>
                                                    <option disabled={true} value="BOTH">On BOTH </option>


                                                </Form.Select>

                                            </Col>
                                            {/* <Col md={3}><Button><FontAwesomeIcon icon={faRotate} onClick={checkusername} />Check UserName</Button></Col> */}
                                        </Row>
                                    </FormGroup>
<Col>
                                    <FormGroup>
                                        <Form.Text className="text-muted">
                                            Scheme Name
                                        </Form.Text>
                                        <Form.Control  {...register("vineoSchemeName")} required />
                                    </FormGroup>
                                    </Col>
                                    <Col >
                                            <FormGroup>
                                                <Form.Text className="text-muted">
                                                    Account type Applicable On  
                                                </Form.Text>
                                                <Form.Select className="form-control" size='sm'  {...register("schemeApplicableonAccountType")}>
                                                    <option value="RETAILER">RETAILER</option>
                                                    <option value="DISTRIBUTOR">DISTRIBUTOR</option>
                                                    <option value="NORMAL">NORMAL</option>
                                                </Form.Select>
                                            </FormGroup>
                                        </Col>

                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Form.Text className='text-muted'>
                                                    Active:
                                                </Form.Text>
                                                <Form.Select className="form-control"  {...register("vineoSchemeactive")}>
                                                    <option value="true ">Yes</option>
                                                    <option value="false">No</option>
                                                </Form.Select>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Text className='text-muted'>
                                                    Company Scheme No:
                                                </Form.Text>
                                                <Form.Control {...register("vineoCompanySchemeNumber")} required />
                                            </FormGroup>

                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Form.Text className='text-muted'>
                                                    From Date:
                                                </Form.Text>
                                                <Controller
                                                    name="fromDateTime"
                                                    //
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <DatePicker className="form-control"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        dateFormat="dd-MM-yyyy"
                                                        ref={setValue("vineoSchemefromDate", moment(field.value).format(DATEFORMAT))}
                                                       
                                                        />

                                                    )}
                                                />
                                                {errors.fromDateTime && <span>This field is required</span>}
                                                
                                            </FormGroup>


                                            <FormGroup>
                                                <Form.Text className='text-muted'>
                                                    To Date:
                                                </Form.Text>
                                                <Controller
                                                    name="fromtoDateTime"
                                                    //
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <DatePicker className="form-control"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        dateFormat="dd-MM-yyyy"
                                                        ref={setValue("vineoSchemeValidTillDate", moment(field.value).format(DATEFORMAT))}
                                                       
                                                        />

                                                    )}
                                                />
                                                {errors.fromtoDateTime && <span>This field is required</span>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* {(watch(`vineoSchemeType`) == "QTY") ? */}
                                        <Row>
                                            <Form.Text className='text-muted'> IF Quantity Exceed</Form.Text>
                                            <FormGroup>
                                                <Form.Control {...register("vineoSchemeFreeQtyLimitExceed")} required />
                                            </FormGroup>


                                        </Row>


                                         <Row>
                                            <Form.Text className='text-muted'>IF Total Product Net Amount Exceed</Form.Text>
                                            <FormGroup>
                                                <Form.Control  type="Number" defaultValue={0} {...register("vineoSchemeFreeAmountLimitExceed")} required />
                                            </FormGroup>


                                        </Row>
                                    
                                    <Form.Text className='text-muted'>then Add Free Quantity in PCS</Form.Text>

                                    <FormGroup>
                                        <Form.Control  type="Number" defaultValue={0} {...register("vineoSchemeFreeQty")} required />
                                    </FormGroup>
                                    <Form.Text className='text-muted' defaultValue={0}>then Add % Discount to Product Selling Rate</Form.Text>
                                    <FormGroup>
                                        <Form.Control  type="Number" defaultValue={0} {...register("vineoSchemeDiscountPercentage")} required />
                                    </FormGroup>

                                    <Form.Text className='text-muted'>Add Free Product</Form.Text>
                                    <FormGroup>
                                        <Typeahead
                                            id="ProductTypehead"
                                            onChange={setSelectedProductBatch}
                                            // multiple  // in future 
                                            options={vineoproductbatchdata}
                                            placeholder="Choose Product to Add as free in Scheme"
                                            selected={selectedProductBatch}
                                            labelKey={"vineoBatchName"}
                                            ref={setValue("vineoBatchSet", selectedProductBatch[0])}
                                        />
                                    </FormGroup>
                                    <Form.Text className='text-muted'>
                                        Name Of Free Product
                                    </Form.Text>
                                    <FormGroup>
                                        <Form.Control value={selectedProductBatch?.[0]?.vineoproductNameinBatch} required />
                                    </FormGroup>
                                    {setValue("nameofFreeProduct", selectedProductBatch?.[0]?.vineoproductNameinBatch)}
                                    {setValue("uniqueidofProduct", selectedProductBatch?.[0]?.productinBatchProductUniqueid)}


                                    <FormGroup>
                                        <Form.Text className='text-muted'>
                                            Bill Type:
                                        </Form.Text>
                                        <Form.Select className="form-control"  {...register("vineoSchemebillType")}>
                                            <option value="CREDIT">CREDIT</option>
                                            <option value="CASH">CASH</option>
                                        </Form.Select>
                                    </FormGroup>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Form.Text className='text-muted'>
                                                    Available Free Qty To Give:
                                                </Form.Text>
                                                <Form.Control  type="Number" defaultValue={0} className="form-control"  {...register("vineoSchemeFreeQtyLimitProductAllowed")}>

                                                </Form.Control>
                                            </FormGroup></Col>
                                        <Col><FormGroup>
                                            <Form.Text className='text-muted'>
                                                Discount Budget :
                                            </Form.Text>
                                            <Form.Control className="form-control"  type="Number" defaultValue={0}  {...register("vineoSchemeDiscountPercentageBudget")}>

                                            </Form.Control>
                                        </FormGroup></Col>
                                    </Row>




                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add Scheme " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update User" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </form>
                            </Card>

                        </div>

                    </Col>
                </Row>

            </div>
        </div >
    )
}