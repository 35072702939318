import React from 'react'
import { useState, useEffect } from 'react'
import { faClose, faRotate, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import AuthUser from '../../../constants/AuthUser';
import http from '../../../constants/AxiosConfig';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Col, Row, FormGroup, ListGroupItem, Card, Lable, Form, Modal, InputGroup } from 'react-bootstrap';
import { VINEOFORM_UPDATE_SELECTED_AREA_NAMEDATA, BASEURL_JWTAUTH, VINEOFORM_DELETE_SELECTED_AREA_NAMEDDATA } from '../../../constants/Urlforvineo';
import { getVineoAreaData, getVineoCompanyData, getVineoSalesmanUsers } from '../../../redux/features/services/serviceHelper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Register from '../../loginRegisterForms/Register';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function AreaMaster() {
    const refreshcount = useSelector((state) => state.vineoData.refreshcount)
    useEffect(() => {
        dispatch(getVineoSalesmanUsers())
        dispatch(getVineoCompanyData())
        // dispatch(getVineoCompanyCategoryData())
    }, []);

    const dispatch = useDispatch()
    const [dataofvineoTableData, setDataofvineoTableData] = useState([{ "ultiVineoAreaUniqeId": "Working", "vineoAreaName": "Click Refresh To Load" }])
    const isvineoAreadataLoaded = useSelector((state) => state.vineoData.isvineoAreadataLoaded)
    const vineodata = useSelector((state) => state.vineoData.vineoareadata)
    const { register, watch, setValue, reset, handleSubmit, control, formState: { errors } } = useForm()
    const [isEditDataSelected, setIsEditDataSelected] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    const [openAddSalesman, setOpenAddSalesman] = useState(false);
    const [openAddComapny, setOpenAddComapny] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedSalesman, setSelectedSalesman] = useState([]);
    const [selectedcompany, setSelectedcompany] = useState([]);
    const vineosalesmanusersdata = useSelector((state) => state.vineoData.vineosalesmanusers);
    const vineoCompnaydata = useSelector((state) => state.vineoData.vineocompany)


    const onSubmit = (data) => {
        console.log(data);
        submitForm(data)
    }

    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message)
    const notifyWarning = (message) => toast.warning(message)
    // const {http,setToken}=AuthUser();

    useEffect(() => {

    }, [refreshcount]);

    const rowClickedfunction = (data, row) => {
        return <span onClick={() => console.log(row)}>{data}</span>
    }


    const { SearchBar } = Search;
    const columns = [

        {
            dataField: 'vineoAreaName',
            text: 'Area Name',
            sort: true,
            formatter: rowClickedfunction,
        },
        {
            dataField: 'vineoAreaCode',
            text: 'Area Code',
            sort: true
        }, {
            dataField: 'vineoAreabitSize',
            text: 'Area Bit Size',
            sort: true
        },

    ];



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },]
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#0D6EFD',
        classes: (row, rowIndex) => {
            setIsRowSelected(false);
            setSelectedRow(row)
        }
    };

    const getdatafromserver = () => {

        console.log("Called to get Area Data ")

        dispatch(getVineoAreaData())

        if (Object.keys(vineodata).length > 0) {
            isvineoAreadataLoaded ? setDataofvineoTableData(vineodata) : setDataofvineoTableData([{ "ultiVineoAreaUniqeId": "Working", "vineoAreaName": "Click Again Refresh To Load" }])

        }

        console.log("Empty Data Received form Server")
    }


    const updateDatainForm = () => {
        {
            console.log(JSON.stringify(selectedRow));
            setIsEditDataSelected(true);
            setValue("ultiVineoAreaUniqeId", selectedRow.ultiVineoAreaUniqeId);
            setValue("vineoAreaName", selectedRow.vineoAreaName);
            setValue("vineoAreaCode", selectedRow.vineoAreaCode);
            setValue("vineoAreabitSize", selectedRow.vineoAreabitSize);
            setValue("vineoRegisteredClientDetails", selectedRow.vineoRegisteredClientDetails);


        }
        console.log("Update Firm ")
    }
    const deleteDatainForm = (requestdata) => {
        console.log(selectedRow);
        {
            var answer = window.confirm("Do You want to Delete Selected Area ");
            if (answer) {
                submitFormForDeleteSelectedRow(selectedRow);
            }
            else {
                notify("Delete Operation Canceled ")
            }
        }

    }
    const submitFormForDeleteSelectedRow = (requestdata) => {
        console.log(requestdata)
        http.post(BASEURL_JWTAUTH + VINEOFORM_DELETE_SELECTED_AREA_NAMEDDATA, requestdata).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Area  DELETED SUCCESSFULLY with NAME is = ' + requestdata.vineoAreaName)
                }
                else { notifyError("Error While Deleting Area  Or Login as ADMIN") }
            },
            (err) => {
                notifyError("Error While Deleting Area Try Again  Or Login as ADMIN")

            }
        )
    }

    const submitForm = (data) => {
        http.post(BASEURL_JWTAUTH + VINEOFORM_UPDATE_SELECTED_AREA_NAMEDATA, data).then(
            (res) => {
                console.log(res)
                if (res.data.errorCode == "0000") {
                    notify('Success:- Area  Created With Internate Id')
                    reset()
                }
                else { notifyError('Error While Registering Area Info From Server') }
                //console.log(res.data)
            },
            (err) => {
                notifyError('Error While Registering Area Info')
            }
        )
    }

    return (
        <div>
            <h3 className='justify-content-center m-1'>Add Area Information </h3>
            <div className="row justify-content-left ">
                <Row>
                    <Col md={5}>
                        <ToolkitProvider keyField='ultiVineoAreaUniqeId'
                            data={dataofvineoTableData}
                            columns={columns}
                            search   >
                            {
                                props => (
                                    <div>
                                        <div >
                                            <Button variant="primary"
                                                onClick={getdatafromserver} >
                                                <FontAwesomeIcon icon={faRotate} />
                                            </Button>
                                            <Button variant="warning m-1" disabled={isRowSelected} onClick={updateDatainForm}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                            <Button variant="danger m-1" disabled={isRowSelected} onClick={deleteDatainForm}> <FontAwesomeIcon icon={faTrash} /></Button>
                                            <SearchBar className="m-1" {...props.searchProps} />
                                        </div>
                                            <BootstrapTable wrapperClasses="masterform-custom-table-class"
                                                    rowClasses="table-striped masterform-custom-row-class" bootstrap4
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            selectRow={selectRow}
                                        // rowEvents={ rowEvents }
                                        />

                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    </Col>
                    <Col md={7}>
                        <div>
                            <Card className='p-3'>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <fieldset disabled>
                                            <Form.Control className='form-control' placeholder='Area ID: This Area Code Is Auto Generated '  {...register('ultiVineoAreaUniqeId')} />
                                        </fieldset>
                                    </FormGroup>
                                    {/* <FormGroup >
                                        <Form.Text className="text-muted">
                                            Area Code*
                                        </Form.Text>
                                        <Form.Control className="form-control  custom-form-field"   {...register("vineoAreaCode")} />
                                    </FormGroup> */}
                                    <FormGroup>
                                        <Form.Text>
                                            Area Name
                                        </Form.Text>
                                        <Form.Control className='form-control custom-form-field' minLength={3}  {...register('vineoAreaName')} required />
                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Text>
                                            Area Bit Size
                                        </Form.Text>
                                        <Form.Control value={0} defaultValue={0} className='form-control custom-form-field' {...register('vineoAreabitSize', {
                                            pattern: {
                                                value: /^[0-99]+$/,
                                                message: 'Please enter a number',
                                            },
                                        })} />
                                        {errors.vineoAreabitSize && <span>Please enter a number Only </span>}
                                    </FormGroup>

                                    <FormGroup >

                                        <InputGroup>
                                            <Form.Text className="text-muted">
                                                SalesMan
                                            </Form.Text>

                                        </InputGroup>
                                        {
                                            selectedSalesman ?
                                                setValue("salesman", selectedSalesman?.[0]) :
                                                <></>
                                        }

                                        <Controller
                                            control={control}
                                            name={`salesman`}
                                            rules={{
                                                required: "Please, select at least one value"
                                            }}

                                            render={({ field, fieldState }) => (
                                                <div >
                                                    <InputGroup>
                                                        <Typeahead
                                                            {...field}
                                                            id="selectedsalesmantypeahead"
                                                            size='sm'
                                                            onChange={setSelectedSalesman}
                                                            labelKey={"username"}
                                                            selected={selectedSalesman}
                                                            // multiple  // for future

                                                            options={vineosalesmanusersdata ? vineosalesmanusersdata : []}
                                                            renderMenuItemChildren={(option) => (
                                                                <div>
                                                                  <div>
                                                                    <small> {option.nameofUser}</small>
                                                                    {/* <small>-Username- {option.username}</small> */}
                                                                  </div>
                                                                </div>
                                                              )}
                                                            className={fieldState.invalid ? "is-invalid" : ""}

                                                        />
                                                        <p id="typeaheadError" className="invalid-feedback">
                                                            {fieldState.error?.message}
                                                        </p>
                                                        <Button variant="info"
                                                            onClick={() => { dispatch(getVineoSalesmanUsers()) }} >
                                                            <FontAwesomeIcon icon={faRotate} />
                                                        </Button>
                                                        <Button variant="primary"
                                                            onClick={() => { setOpenAddSalesman(true) }}>
                                                            +
                                                        </Button>
                                                    </InputGroup>

                                                </div>
                                            )}
                                        />


                                    </FormGroup>

                                    <FormGroup >

                                        <InputGroup>
                                            <Form.Text className="text-muted">
                                                Company
                                            </Form.Text>

                                        </InputGroup>
                                        {
                                            selectedcompany ?
                                                setValue("vineoCompanies", selectedcompany) :
                                                <></>
                                        }

                                        <Controller
                                            control={control}
                                            name={`vineoCompanies`}
                                            rules={{
                                                required: "Please, select at least one value"
                                            }}

                                            render={({ field, fieldState }) => (
                                                <div >
                                                    <InputGroup>
                                                        <Typeahead
                                                            {...field}
                                                            id="selectedcompanytypeahead"
                                                            size='sm'
                                                            onChange={setSelectedcompany}
                                                            labelKey={"companyName"}
                                                            selected={selectedcompany}
                                                        //    multiple 
                                                            options={vineoCompnaydata ? vineoCompnaydata : []}
                                                            className={fieldState.invalid ? "is-invalid" : ""}

                                                        />
                                                        <p id="typeaheadError" className="invalid-feedback">
                                                            {fieldState.error?.message}
                                                        </p>
                                                        <Button variant="info"
                                                            onClick={() => { dispatch(getVineoCompanyData()) }} >
                                                            <FontAwesomeIcon icon={faRotate} />
                                                        </Button>
                                                        {/* <Button variant="primary"
                                                            onClick={() => { setOpenAddComapny(true) }}>
                                                            +
                                                        </Button> */}

                                                    </InputGroup>

                                                </div>
                                            )}
                                        />
                                    </FormGroup>

                                    {!isEditDataSelected ?
                                        <input className="btn btn-primary m-2" type="submit" value="Add Area " />
                                        : <input className="btn btn-primary m-2" type="submit" value="Update Area" />}
                                    <button type="button" onClick={() => { reset(); setIsEditDataSelected(false) }} className="btn btn-secondary"><FontAwesomeIcon icon={faRotate} />Reset</button>

                                </Form>

                            </Card>
                        </div>
                    </Col>
                </Row>

            </div>
            <Modal show={openAddSalesman}
                onHide={() => { setOpenAddSalesman(false) }}
                backdrop="static"
                keyboard={false}
                fullscreen={true}>
                <Modal.Header closeButton />
                {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                <Modal.Body>
                    <Register />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary m-2" onClick={() => { setOpenAddSalesman(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openAddComapny}
                onHide={() => { setOpenAddComapny(false) }}
                backdrop="static"
                keyboard={false}
                fullscreen={true}>
                <Modal.Header closeButton />
                {/* <Modal.Title>Map Vendor to Product</Modal.Title> */}
                <Modal.Body>
                    <Register />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary m-2" onClick={() => { setOpenAddComapny(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}